import { Button, CardContent, Dialog, FormControlLabel, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react'
import TeamManagementTabs from './teamManagementTab';
import { getTeamPropertyAllotments, postTeamManagersInvite, getManagerTeamManagers, postTeamManagerInvite, deleteTeamManagers, patchPropertyAllotments } from '../../../services';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GroupIcon from '@mui/icons-material/Group';

const TeamManagers = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [teamManagers, setTeamManagers] = useState([]);
    const [properties, setProperties] = useState([]);
    const [managers, setManagers] = useState([]);

    const [addManagerModal, setAddManagerModal] = useState(false);
    const [editManagerModal, setEditManagerModal] = useState(false);

    useEffect(() => {
        pageLoad();
    }, []);

    const pageLoad = () => {
        let man = [...managers];
        man.length = 0;
        let data1 = [];
        getTeamPropertyAllotments().then((res) => {
            let data = res.data.data;
            let getDetails = { ...data }
            getDetails.properties.map((e, i) => {
                e.propertyManagers.map((h, j) => {
                    data1.push({
                        "address": e.address.line1 + "," + e.address.line2 + "," + e.address.unitNumber + "," + e.address.city + "," + e.address.state + "-" + e.address.zipCode,
                        "id": e.id,
                        "owner": e.owner,
                        "manager": e.owner !== h.manager ? h.manager : undefined,
                    })
                })
            })

            const uniqueArray = [...new Map(data1.map(o => [o.id, o])).values()]
            setProperties(uniqueArray);
            getDetails.managers.map((e, i) => {
                man.push({
                    "fullName": e.user.name.first + " " + e.user.name.last,
                    "manager": e.user.manager,
                })
            })
            setManagers(man);
        })
    }

    const pageLoad1 = () => {
        let team = [...teamManagers];
        team.length = 0;
        getManagerTeamManagers().then((res) => {
            let data = res.data;
            let getDetails = { ...data }
            getDetails.data.map((e, i) => {
                team.push({
                    "fullName": e.name.first + " " + e.name.last,
                    "email": e.email.id,
                    "phone": e.phone !== undefined ? e.phone.code + " " + e.phone.number : "",
                    "verified": e.verified,
                    "id": e.id,
                })
            })
            setTeamManagers(team);
        }).catch((err) => console.log(`Error${err}`));
    }

    const inviteClick = () => {
        let data = {
            email: email,
            firstName: firstName,
            lastName: lastName
        }
        postTeamManagersInvite(data).then((res) => {
            pageLoad();
            setAddManagerModal(false);
            toast.success(`Manager Invite to ${email} Successfully`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }
    const openAddManagerModal = () => {
        setAddManagerModal(true);
        setEmail("");
        setFirstName("");
        setLastName("");
    }

    const openEditManagerModal = () => {
        setEditManagerModal(true);
        pageLoad1();
    }
    const reInviteClick = (id) => {
        postTeamManagerInvite(id).then((res) => {
            toast.success("Resent Invitaion to Manager Successfully");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }
    const removeClick = (id) => {
        deleteTeamManagers(id).then((res) => {
            pageLoad1();
            pageLoad();
            setEditManagerModal(false);
        })
    }

    const radioChange = (e, id) => {
        let data = e.target.value;
        let array = [];
        array.push({
            managerId: id,
            propertyId: data,
        })
        let datas = {
            allotments: array,
        }
        patchPropertyAllotments(datas).then((res) => {
            pageLoad();
        })
    }
    return (
        <>
            <CardContent>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                <div style={{ display: "flex", margin: "10px", alignItems: "center", flexWrap: "wrap" }}>
                    <GroupIcon sx={{ fontSize: 25, fontWeight: "bolder", width: '28px', height: "28px", backgroundColor: "#4a90e2", color: "white", borderRadius: "5px", padding: "5px" }} />
                    <p style={{ fontSize: 22, textAlign: "center", fontWeight: "bolder", marginLeft: "10px" }}>Team Management</p>
                </div>
            </div>
            </CardContent>
            <CardContent>
                <TeamManagementTabs active={"MANAGERS"} />
            </CardContent>
            <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                <Button sx={{ fontSize: 15, fontWeight: 500, marginRight: "30px" }} type="submit" color="primary" onClick={openEditManagerModal}>EDIT MANAGERS</Button>
                <Button sx={{ fontSize: 15, fontWeight: 500, borderRadius: 5 }} variant="contained" type="submit" color="success" onClick={openAddManagerModal}>ADD MANAGER</Button>
            </CardContent>
            <CardContent sx={{ py: 0 }}>
                <TextField
                    id="standard-basic"
                    fullWidth={true}
                    variant="standard"
                    label="Search..."
                    type="text"
                />
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ color: "grey" }}>
                                <TableCell className='tableCell-maxWidth' sx={{ padding: "20px", color: "grey" }}>Owned Properties</TableCell>
                                {managers.map((h, j) => (
                                    <TableCell className='tableCell-maxWidth' sx={{ padding: "0px", color: "grey" }} key={j} align="center">{h.fullName}</TableCell>
                                ))}
                                <TableCell className='tableCell-maxWidth' sx={{ padding: "0px", color: "grey" }} align="center">Owner</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {properties.map((f, i) => (
                                <TableRow key={i}>
                                    <TableCell className='tableCell-maxWidth' component="th" stylescope="row" sx={{ padding: "0px 20px" }}>{f.address}</TableCell>
                                    {managers.map((h, j) => (
                                        <TableCell className='tableCell-maxWidth' sx={{ padding: "0px" }} key={j} align="center">
                                            <FormControlLabel
                                                control={<Radio color='success' />}
                                                value={f.id}
                                                checked={f.id && f.manager === h.manager}
                                                onChange={(e) => radioChange(e, h.manager)}
                                            /></TableCell>))}
                                    <TableCell className='tableCell-maxWidth' sx={{ padding: "0px" }} align="center">
                                        <FormControlLabel
                                            control={<Radio color='success' />}
                                            value={f.id}
                                            checked={f.id && f.manager === undefined}
                                            onChange={(e) => radioChange(e, f.owner)}
                                        /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={addManagerModal}
                onClose={() => setAddManagerModal(false)}>
                <CardContent>
                    <p className='paragraph'>Add Manager</p>
                </CardContent>
                <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px", alignItems: "stretch" }}>
                    <div style={{ flex: 1 }}>
                        <TextField
                            label="First Name"
                            margin="dense"
                            variant="outlined"
                            fullWidth={true}
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value) }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField
                            label="Last Name"
                            margin="dense"
                            variant="outlined"
                            fullWidth={true}
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value) }}
                        />
                    </div>
                </CardContent>
                <CardContent sx={{ pt: 0 }}>
                    <TextField
                        label="Email"
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                </CardContent>
                <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                    <Button sx={{ fontSize: "small", fontWeight: 500 }} variant="contained" type="submit" color="success" onClick={inviteClick}>INVITE</Button>
                    <Button sx={{ fontSize: "small", fontWeight: 500 }} type="submit" color="error" onClick={() => setAddManagerModal(false)}>CANCEL</Button>
                </CardContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={editManagerModal}
                onClose={() => setEditManagerModal(false)}
            >
                <CardContent >
                    <p className='paragraph'>Team Managers</p>
                </CardContent>
                <CardContent>
                    <table className="team-edit-managers-table">
                        <thead>
                            <tr>
                                <th className="team-edit-managers-table-head">Name</th>
                                <th className="team-edit-managers-table-head">Email</th>
                                <th className="team-edit-managers-table-head">Phone</th>
                                <th className="team-edit-managers-table-head"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {teamManagers.map((e, i) => (
                                <tr key={i}>
                                    <td className="team-edit-managers-table-body">{e.fullName}</td>
                                    <td className="team-edit-managers-table-body">{e.email}</td>
                                    <td className="team-edit-managers-table-body">{e.phone}</td>
                                    <td className="team-edit-managers-table-body">
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div>
                                                <Tooltip title={e.verified === true ? <span>Verified</span> : <span>Unverified</span>} placement="top">
                                                    {e.verified === true ? <CheckCircleIcon color='success' /> : <ErrorOutlineIcon color='error' />}
                                                </Tooltip>
                                            </div>
                                            <div>
                                                {e.verified === false ? <Tooltip title="Cancel" placement="bottom"><Button sx={{ fontSize: "small", fontWeight: 500, mr: 4 }} type="submit" color="primary" onClick={() => { reInviteClick(e.id) }}>REINVITE</Button></Tooltip> : ""}
                                                <Tooltip title="Cancel" placement="bottom">
                                                    <Button sx={{ fontSize: "small", fontWeight: 500 }} type="submit" color="error" onClick={() => { removeClick(e.id) }}>REMOVE</Button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </td>
                                </tr>))}
                        </tbody>
                    </table>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}

export default TeamManagers;