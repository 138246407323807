import { Button, CardContent, Dialog, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { useState, useEffect } from 'react'
import PropertiesActiveInactiveTabs from './propertiesTab';
import { getManagerPropertiesOverview ,postInActive,getTeamPropertyAllotments} from "../../../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ActiveProperties = () => {
  const [properties, setProperties] = useState([]);
  const [activPropertyeModal, setActivPropertyeModal] = useState(false);
  const [propId, setPropId] = useState("");

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = () => {
    let prop = [...properties];
    prop.length = 0;
    getTeamPropertyAllotments().then((res) => {
      let data = res.data.data;
      let getDetails = { ...data };
      getDetails.properties.map((e, i) => {
        prop.push({
          "address": e.address.line1 + "," + e.address.line2 + "," + e.address.unitNumber + "," + e.address.city + "," + e.address.state + "-" + e.address.zipCode,
          "id": e.id,
          "active":e.active,
        })
      })
      setProperties(prop);
    })
  }

  const inactiveClick = (id, status) => {
    if (status === "modelOpen") {
      setActivPropertyeModal(true);
      setPropId(id);
    }
    if (status === "activeProperty") {
      console.log("id", propId);
      postInActive(propId).then((res)=> {
        pageLoad();
        setActivPropertyeModal(false);
        toast.success(`Property Inactive`);
      }).catch((err) => { toast.error(err.response.data.error.message) });
    }
    if (status === "closeModel") {
      setActivPropertyeModal(false);
      setPropId("");
    }
  }
  return (
    <div>
      <CardContent>
        <p className='paragraph'>Active Properties</p>
      </CardContent>
      <CardContent>
        <PropertiesActiveInactiveTabs active={"property"} />
      </CardContent>
      <CardContent sx={{ py: 7 }}>
        <TextField
          id="standard-basic"
          fullWidth={true}
          variant="standard"
          label="Search..."
          type="text"
        />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ color: "grey" }}>
                <TableCell className='tableCell-maxWidth' sx={{ padding: "20px", color: "grey" }}>PROPERTIES</TableCell>
                <TableCell className='tableCell-maxWidth' sx={{ padding: "0px", color: "grey" }} align="center">ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            {properties.map((e, i) => (e.active===true?
              <TableBody key={i}>
                <TableRow>
                  <TableCell className='tableCell-maxWidth' component="th" stylescope="row">{e.address}</TableCell>
                  <TableCell className='tableCell-maxWidth' sx={{ padding: "0px" }} align="center">
                    <Button color='primary' sx={{fontSize:"15",fontWeight:"bold"}} onClick={() => inactiveClick(e.id, "modelOpen")}>INACTIVE</Button>
                  </TableCell>
                </TableRow>
              </TableBody>:""))}
          </Table>
        </TableContainer>
      </CardContent>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={activPropertyeModal}
        onClose={() => setActivPropertyeModal(false)}
      >
        <CardContent>
          <Typography variant="h5" component="h2">
            Are you sure you want to active the property?
          </Typography>
        </CardContent>
        <CardContent sx={{ display: "flex", justifyContent: "right" }}>
          <div>
            <Button variant="contained" color='success' onClick={() => inactiveClick("", "activeProperty")}>INACTIVE</Button>
            <Button color='error' onClick={() => inactiveClick("", "closeModel")}>CANCEL</Button>
          </div>
        </CardContent>
      </Dialog>
      <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
    </div>
  )
}

export default ActiveProperties;
