import { Button, Dialog, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUpdateNoticeTemplates, patchUpdateNoticeTemplates, deleteUpdateNoticeTemplates } from "../../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateNoticeTemplates() {
    const params = useParams();
    const navigate = useNavigate();
    const { quill, quillRef } = useQuill();

    const [desc, setDesc] = useState("");
    const [name, setName] = useState("");
    const [salvation, setSalvation] = useState("");
    const [subject, setSubject] = useState("");

    const [deleteIconClick, setDeleteIconClick] = useState(false);

    const [descError, setDescError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [salvationError, setSalvationError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);

    const pageLoad = () => {
        getUpdateNoticeTemplates(params.noticeTemplateId)
            .then((res) => {
                const getDetails = res?.data?.data;
                setName(getDetails?.name);
                setDesc(getDetails?.description);
                setSalvation(getDetails?.salutation);
                setSubject(getDetails?.subject);
                quill.clipboard.dangerouslyPasteHTML(getDetails?.body ?? "");
            })
            .catch((err) => console.log(`Error${err}`));
    };

    useEffect(() => {
        pageLoad();
    }, [quill]);

    const validateInput = (value, setErrorState) => {
        const isValid = value.trim() !== "";
        setErrorState(!isValid);
        return isValid;
    };

    const handleInputChange = (value, setState, setErrorState) => {
        validateInput(value, setErrorState);
        setState(value);
    };

    const saveBtnClick = () => {
        const isValid =
            validateInput(name, setNameError) &&
            validateInput(desc, setDescError) &&
            validateInput(subject, setSubjectError) &&
            validateInput(salvation, setSalvationError);

        if (!isValid) return;

        const data = {
            body: quillRef.current.firstChild.innerHTML,
            description: desc,
            name,
            salutation: salvation,
            subject,
        };

        patchUpdateNoticeTemplates(params.noticeTemplateId, data)
            .then(() => {
                toast.success("Template Saved Successfully")
                navigate('/manager/notice-templates')
            })
            .catch((err) => toast.error(err.response.data.error.message));
    };

    const deleteClick = (id) => {
        deleteUpdateNoticeTemplates(id)
            .then(() => {
                toast.info("Deleted Template");
                navigate(`/manager/notice-templates`);
            }).catch((err) => toast.error(err.response.data.error.message));
    };
    return (
        <div style={{ padding: '30px' }}>
            <p className="update-notice-templates-heading">Update Template</p>
            <p className="update-notice-templates-link"><Link to="/lease-applications/NoticeTemplates">TEMPLATE NOTICES</Link> / {name}</p>
            <p className="update-notice-templates-heading-child">Edit template</p>
            <div>
                <TextField
                    error={nameError}
                    label="Template Name"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder="Eg 60 Days Notice Template"
                    value={name}
                    onChange={(e) => handleInputChange(e.target.value, setName, setNameError)}
                />
                <TextField
                    error={descError}
                    label="Template Description"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Description about template"
                    rows={2}
                    multiline
                    value={desc}
                    onChange={(e) => handleInputChange(e.target.value, setDesc, setDescError)}
                />
                <TextField
                    error={subjectError}
                    label="Notice Subject"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder="Eg 60 Days Notice Alert"
                    value={subject}
                    onChange={(e) => handleInputChange(e.target.value, setSubject, setSubjectError)}
                />
                <TextField
                    error={salvationError}
                    label="Notice Salutation"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder="Eg Dear[Tenants]"
                    value={salvation}
                    onChange={(e) => handleInputChange(e.target.value, setSalvation, setSalvationError)}
                />
                <div className="update-notice-templates-quill-editor">
                    <div ref={quillRef} />
                </div>
            </div>
            <div className="update-notice-templates-button-flex-1">
                <div>
                    <Button
                        type="submit"
                        color="error"
                        variant="text"
                        onClick={() => setDeleteIconClick(true)}
                        style={{ fontSize: 15 }}
                    ><DeleteIcon /> DELETE TEMPLATE</Button>
                </div>
                <div className="update-notice-templates-button-flex-2">
                    <Button
                        type="submit"
                        color="error"
                        variant="text"
                        style={{ fontSize: 15 }}
                        onClick={() => navigate(`/lease-applications/NoticeTemplates`)}
                    >DISCARD</Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{ fontSize: 15 }}
                        onClick={saveBtnClick}
                    >SAVE</Button>
                </div>
            </div>
            <Dialog
                open={deleteIconClick}
                onClose={() => setDeleteIconClick(false)}>
                <div style={{ padding: '20px' }}>
                    <p style={{ fontSize: 18, textAlign: "center" }}>Confirm delete template?</p>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            sx={{ fontSize: 15 }}
                            onClick={() => { deleteClick(params.noticeTemplateId) }}
                        >DELETE</Button>
                        <Button
                            color="error"
                            type="submit"
                            sx={{ fontSize: 15 }}
                            onClick={() => setDeleteIconClick(false)}
                        >CANCEL</Button>
                    </div>
                </div>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default UpdateNoticeTemplates;