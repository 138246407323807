import { Tabs, Tab, Box } from "@mui/material";
import React from "react";
import { useLocation, Link } from 'react-router-dom';

function TeamManagementTabs() {
  const location = useLocation();
  const currentTab = location.pathname;

  return (
    <Box
      sx={{ borderBottom: 1, borderColor: "divider" }}
    >
      <Tabs
        variant="scrollable"
        aria-label="basic tabs example"
        value={currentTab}
      >
        <Tab
          label={<h5 style={{ margin: "0px 30px" }}>MANAGERS</h5>}
          value={`/manager/team/managers`}
          to={`/manager/team/managers`}
          component={Link}
        />
        {currentTab === "/manager/team/active-contractors" || "/manager/team/managers" ?
          currentTab !== "/manager/team/Inactive-Contractors" &&
          <Tab
            label={<h5 style={{ margin: "0px" }}>ACTIVE CONTRACTORS</h5>}
            value={`/manager/team/active-contractors`}
            to={`/manager/team/active-contractors`}
            component={Link}
          />
          : ""}
        {currentTab === "/manager/team/Inactive-Contractors" ?
          <Tab
            label={<h5 style={{ margin: "0px" }}>INACTIVE CONTRACTORS</h5>}
            value={`/manager/team/Inactive-Contractors`}
            to={`/manager/team/Inactive-Contractors`}
            component={Link}
          />
          : ""}
      </Tabs>
    </Box>
  );
}

export default TeamManagementTabs;