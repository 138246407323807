import React, { useState, useEffect, useRef } from "react"
import { Box, Button, CardContent, CircularProgress } from "@mui/material";
import axios from 'axios';
import SignaturePad from "react-signature-canvas";
import { useNavigate, useParams } from "react-router-dom";
import { getLeasemanageragreementview, getLeaseappAgreement, managerfinalsign, managerLeaseAgreementreject, getApiBaseURL } from "../../services";
import { ToastContainer, toast } from "react-toastify";

const LeasemanagerSign = () => {
    const navigate = useNavigate();

    const [view, setView] = useState(null);
    let sigPad = useRef(null);

    const clearSig = (e) => {
        e.preventDefault();
        sigPad.clear();
        setIsSigned(false)
    }
    const [signImg, setsignImg] = useState();
    const [ip, setIP] = useState('');
    const [timeGet, settimeGet] = useState('');
    const [tenantsName, settenantsName] = useState("");
    const [identity, setidentity] = useState("")
    const [manname, setmanName] = useState("");
    const [manidentity, setmanidentity] = useState("")
    const params = useParams();
    const [isSigned, setIsSigned] = useState(false);
    const [fileView, setFileview] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4);
    }
    useEffect(() => {
        var today = new Date();
        var str = today.toGMTString();
        settimeGet(str)
        getData()
        pageLoad();
    }, [])

    const pageLoad = () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            },
        };
        getLeasemanageragreementview(params.applicationId).then((response) => {
            const responseData = response.data.data;
            setView(responseData.html)
        }).finally(() => {
            setLoading(false);
        });
        var fileatt = [...fileView]
        fileatt.length = 0;
        getLeaseappAgreement(params.applicationId).then((response) => {
            let responseData3 = response?.data?.data;
            let details = { ...responseData3 }
            if (details?.tenantSignees != 0) {
                details?.tenantSignees.map((r) => {
                    setidentity("Tenant")
                    fetch(`${getApiBaseURL()}/manager/lease-applications/${params.applicationId}/lease-agreement/tenant-signees/${r?.user}/signature/file`, config
                    ).then(res => {
                        return res.blob();
                    }).then(blob => {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(blob);
                        reader.onload = function () {
                            var imageDataUrl = reader.result;
                            setsignImg(imageDataUrl)
                        }
                    }).catch((err) => console.log(`Error${err}`));
                    settenantsName(r?.fullName);
                })
                setmanName(responseData3?.managerSignees[0]?.fullName);
                setmanidentity("Manager");
                details?.attachments?.map((e, i) => {
                    fileatt.push({
                        "fileName": e.fileName,
                        "id": e.id
                    })
                })
                setFileview(fileatt);
            }
        })
    }

    const downloadFile = (id) => {
        var config = "";
        const authToken = sessionStorage.getItem("authToken");
        if (authToken != undefined) {
            config = {
                headers: { Authorization: `Bearer ${authToken}` },
            };
        }
        fetch(`${getApiBaseURL()}/manager/lease-applications/${params.applicationId}/lease-agreement/attachments/${id}`,
            config
        ).then((res) => {
            return res.blob();
        })
            .then((blob) => {
                var reader = new window.FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function () {
                    var imageDataUrl = reader.result;
                    const link = document.createElement("a");
                    link.href = imageDataUrl;
                    if (blob.type == "application/pdf") {
                        link.setAttribute("Download", "Attachment.pdf");
                        document.body.appendChild(link);
                        link.click();
                    }
                };
            })
    }

    const btnreviseClick = () => {
        managerLeaseAgreementreject(params.applicationId).then((res) => {
            navigate(`/manager/lease-applications/${params.applicationId}`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const btnsignClick = async (e) => {
        let applicantDetails = {
            "ipAddress": ip,
            "signature": sigPad.getTrimmedCanvas().toDataURL('image/png'),
        }
        managerfinalsign(applicantDetails, params.applicationId).then((response) => {
            navigate(`/manager/lease-applications/${params.applicationId}`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div >
            <Box sx={{ m: 4 }} >
                <div dangerouslySetInnerHTML={{ __html: view }} />
                <Box >
                    <table>
                        <tbody>
                            {fileView.map((e, r) => (
                                <tr key={r}>
                                    <td >
                                        {r + 1}. <a style={{ color: "#4a90e2", cursor: "pointer", fontSize: "12pt" }} onClick={() => downloadFile(e.id)}>{e.fileName}</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <img style={{ border: "1px solid #dee2e6", padding: "10px" }} src={signImg} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingTop: '3rem' }}>
                                    <h2>{tenantsName}</h2>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingTop: '3rem' }}>
                                    <h4>{identity}</h4>
                                    <h4>IP address:{ip}</h4>
                                    <h4> {timeGet} </h4>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ cursor: "pointer" }} className="signbox">
                                        <SignaturePad penColor='#4285f4'
                                            onEnd={() => { setIsSigned(true) }}
                                            canvasProps={{ width: 300, height: 200, className: 'sig-canvas curSig' }}
                                            ref={(ref) => { sigPad = ref }} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Button sx={{ float: 'right' }} onClick={(e) => clearSig(e)}>Clear</Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: '3rem' }}>
                                    <h2>{manname}</h2>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: '3rem' }}>
                                    <h4>{manidentity}</h4>
                                    <h4> IP Address:{ip}</h4>
                                    <h4>{timeGet}</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table></div>
                <div >
                    <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: 10 }}>
                        <Button variant="contained" color="error" sx={{ fontSize: 15 }}
                            onClick={() => btnreviseClick()}
                        >Reject</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            variant="contained"
                            disabled={isSigned ? false : true}
                            color="warning"
                            onClick={(e) => btnsignClick(e)}
                        >
                            Sign and Setup Lease
                        </Button>
                    </CardContent>
                </div>
            </Box>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );

};
export default LeasemanagerSign;