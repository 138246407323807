export const formNames = {
    occupantsForm: 0,
    residenceForm: 1,
    employmentForm: 2,
    verificationForm: 3,
    historyForm: 4,
    agreementForm: 5,
};

export const usStates =
    ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];




/**
 * Group an array using the given property
 * @param {Array} collection array to group
 * @param {*} property to group
 * @returns 
 */
export const groupBy = (collection, property) => {
    var i = 0, val, index,
        values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1)
            result[index].push(collection[i]);
        else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
}

export const states = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "DC", label: "District Of Columbia" },
    { value: "FM", label: "Federated States Of Micronesia" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "GU", label: "Guam" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PW", label: "Palau" },
    { value: "PA", label: "Pennsylvania" },
    { value: "PR", label: "Puerto Rico" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VI", label: "Virgin Islands" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
];

export const Expenses = [
    {
        value: "OTHER_EXPENSE",
        label: "Other Expense",
    },
    {
        value: "INSURANCE_EXPENSE",
        label: "Insurance Expense",
    },
    {
        value: "ADVERTISING_EXPENSE",
        label: "Advertising Expense",
    },
    {
        value: "CLEANING_AND_MAINTENANCE_EXPENSE",
        label: "Cleaning and Maintenance Expense",
    },
    {
        value: "LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE",
        label: "Legal and Professional Services Expense",
    },
    {
        value: "MANAGEMENT_EXPENSE",
        label: "Management Expense",
    },
    {
        value: "MEALS_EXPENSE",
        label: "Meals Expense",
    },
    {
        value: "REPAIRS_EXPENSE",
        label: "Repairs Expense",
    },
    {
        value: "SUPPLIES_EXPENSE",
        label: "Supplies Expense",
    },
    {
        value: "TRAVEL_EXPENSE",
        label: "Travel Expense",
    },
    {
        value: "UTILITIES_EXPENSE",
        label: "Utilities Expense",
    },
    {
        value: "CAPITAL_EXPENSE",
        label: 'Capital Expense',
    },
    {
        value: "MORTGAGES_AND_LOANS_EXPENSE",
        label: 'Mortgages and Loans Expense',
    },
];

export const Incomes = [
    {
        value: "RENTAL_INCOME",
        label: "Rental Income",
    },
    {
        value: "OTHER_INCOME",
        label: "Other Income",
    },
    {
        value: "PARKING_INCOME",
        label: "Parking Income"
    },
    {
        value: "LAUNDRY_SERVICE_INCOME",
        label: "Laundry Service Income"
    },
    {
        value: "SHORT_TERM_RENTS_INCOME",
        label: "Short Term Rents Income"
    },
    {
        value: "LATE_FEES_INCOME",
        label: "Late Fees Income"
    },
    {
        value: "STORAGE_INCOME",
        label: "Storage Income"
    },
    {
        value: "MISC_INTEREST_INCOME",
        label: "Misc Interest Income"
    },
];
