import React, { useEffect, useState } from 'react'
import TabMenu from '../properties/edit/tabMenu'
import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { MenuProps } from '../../../components/MaterialUiSelectMenuStyle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { deletePropertyReOccuringServiceRequest, getPropertyReOccuringServiceRequest, getPropertyReOccuringServiceRequestMembers, getSingleProperty, patchPropertyReOccuringServiceRequest, postPropertyReOccuringServiceRequest } from '../../../services';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import ToolTip from '../../../components/ToolTip';

function ReOccuringServiceRequest() {
    const params = useParams();

    const [property, setProperty] = useState(null);
    const [reOccuring, setReOccuring] = useState(null);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [howOften, setHowOften] = useState("");
    const [everyDay, setEveryDay] = useState(null);
    const [assignedTo, setAssignedTo] = useState(null);
    const [days, setDays] = useState([]);
    const [firstStartDate, setFirstStartDate] = useState(null);
    const [contractorDialog, setContractorDialog] = useState(false);

    const [errors, setErrors] = useState({});

    const [editStatus, setEditStatus] = useState(false);
    const [editReoccuringId, setEditReoccuringId] = useState("");

    const [availableAssignees, setAvailableAssignees] = useState([]);
    const [availabeContractorAssigness, setAvailabeContractorAssigness] = useState([]);

    const onFirstStartDateChange = (date) => {
        date.setHours(9, 0, 0, 0);
        setFirstStartDate(date);
    }

    const getData = async () => {
        await getSingleProperty(params.propertyId).then((res) => {
            let data = res?.data?.data;
            setProperty(data);
            return getPropertyReOccuringServiceRequest(params.propertyId);
        }).then((res) => {
            let data = res?.data?.data;
            setReOccuring(data);
        })
    }

    const getReoccuranceServiceRequestAssignees = async () => {
        await getPropertyReOccuringServiceRequestMembers(params.propertyId).then((res) => {
            let members = res?.data?.data;
            let newManagerMembers = members.filter(mem => mem?.type == "Manager" || mem?.type == "Tenant"); // Filtering the members with type Manager
            setAvailableAssignees(newManagerMembers)
            let newContractorMembers = members.filter(mem => mem?.type == "Contractor"); // Filtering the members with type Contractor
            setAvailabeContractorAssigness(newContractorMembers);
        })
    }

    // Select day populate
    const populateDays = () => {
        let newDays = [...days];
        newDays.length = 0;
        let dayss = [
            "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen",
            "Eighteen", "Nineteen", "Twenty", "Twenty One", "Twenty Two", "Twenty Three",
            "Twenty Four", "Twenty Five", "Twenty Six", "Twenty Seven", "Twenty Eight",
            "Twenty Nine", "Thirty", "Thirty One"
        ];

        for (let i = 0; i < 31; i++) {
            newDays.push({ value: i + 1, text: dayss[i] });
        }
        setDays(newDays);
    }

    const contractorDialogOpen = () => {
        getReoccuranceServiceRequestAssignees()
        setContractorDialog(true);
    }

    const contractorDialogClose = () => {
        setContractorDialog(false);
    }

    const onSelectContractor = (newItem) => {
        contractorDialogClose();
        if (newItem) {
            let assi = [...availableAssignees]
            assi.push(newItem);
            setAvailableAssignees(assi)
            setAssignedTo(newItem?.id + ',' + newItem?.type);
        } else {
            setAssignedTo(null);
        }
    }

    // Validation
    const validate = () => {
        const newErrors = {};
        if (title === "") newErrors.title = true;
        if (description === "") newErrors.description = true;
        if (howOften === "") newErrors.howOften = true;
        if (everyDay === null) newErrors.everyDay = true;
        if (assignedTo === null) newErrors.assignedTo = true;
        if (firstStartDate === null) newErrors.firstStartDate = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Create
    const onCreateButtonClick = async () => {

        if (!validate()) return;

        let newAssignee = {
            assignee: null,
            type: null
        };
        if (assignedTo) {
            let parts = assignedTo.split(',');
            newAssignee.assignee = parts[0];
            newAssignee.type = parts[1];
        }
        let data = {
            propertyId: params.propertyId,
            title: title,
            description: description,
            frequency: howOften,
            nextOccurance: firstStartDate,
            every: everyDay,
            assignee: newAssignee
        }
        await postPropertyReOccuringServiceRequest(params.propertyId, data).then((res) => {
            getData();
            getReoccuranceServiceRequestAssignees();
            setTitle("");
            setDescription("");
            setHowOften("");
            setFirstStartDate(null);
            setEveryDay(null);
            setAssignedTo(null);
        })
    }

    // Update
    const editReoccuringSr = (editData) => {
        setEditReoccuringId(editData?._id);
        setTitle(editData?.title);
        setDescription(editData?.description);
        setHowOften(editData?.frequency);
        setFirstStartDate(editData?.nextOccurance);
        setEveryDay(editData?.every);
        if (editData?.assignee?.type == "Contractor") {
            let contractorAssignee = availabeContractorAssigness.find(mem => mem?.id == editData?.assignee?.assignee)
            if (contractorAssignee) {
                let assi = [...availableAssignees]
                assi.push(contractorAssignee);
                setAvailableAssignees(assi)
            }
        }
        if (editData?.assignee?.type != "Contractor") {
            getReoccuranceServiceRequestAssignees();
        }
        setAssignedTo(editData?.assignee?.assignee + ',' + editData?.assignee?.type);
        setEditStatus(true);
    }

    const onUpdateButtonClick = async () => {

        if (!validate()) return;

        let newAssignee = {
            assignee: null,
            type: null,
        };
        if (assignedTo) {
            let parts = assignedTo.split(',');
            newAssignee.assignee = parts[0];
            newAssignee.type = parts[1];
        }
        let data = {
            propertyId: params.propertyId,
            title: title,
            description: description,
            frequency: howOften,
            nextOccurance: firstStartDate,
            every: everyDay,
            assignee: newAssignee
        }
        await patchPropertyReOccuringServiceRequest(params.propertyId, editReoccuringId, data).then((res) => {
            getData();
            getReoccuranceServiceRequestAssignees();
            setTitle("");
            setDescription("");
            setHowOften("");
            setFirstStartDate(null);
            setEveryDay(null);
            setAssignedTo(null);
        })
    }

    // Delete
    const deleteReoccuringSr = async (id) => {
        await deletePropertyReOccuringServiceRequest(params.propertyId, id).then((res) => {
            getData();
        })
    }

    useEffect(() => {
        getData();
        getReoccuranceServiceRequestAssignees();
        populateDays();
    }, []);

    const getFrequency = (frequency, every) => {
        let freq = "";
        let dayss = [
            "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen",
            "Eighteen", "Nineteen", "Twenty", "Twenty One", "Twenty Two", "Twenty Three",
            "Twenty Four", "Twenty Five", "Twenty Six", "Twenty Seven", "Twenty Eight",
            "Twenty Nine", "Thirty", "Thirty One"
        ];
        let day = every;
        if (frequency) {
            if (frequency === "Daily") {
                freq = `Every ${dayss[day - 1]} day`;
            }
            if (frequency === "Weekly") {
                freq = `Every ${dayss[day - 1]} week`;
            }
            if (frequency === "Monthly") {
                freq = `Every ${dayss[day - 1]} month`;
            }
            if (frequency === "Yearly") {
                freq = `Every ${dayss[day - 1]} year`;
            }
        }
        return freq;
    }

    return (
        <div style={{ padding: "30px" }}>
            <p style={{ fontSize: 25, textAlign: "center", fontWeight: "bold" }}>{property?.address?.line1 + "," + property?.address?.line2 + " " + property?.address?.unitNumber}</p>
            <div>
                <div>
                    <TabMenu />
                </div>
                <div style={{ marginTop: '30px' }}>
                    <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Create Re-occuring Service Request</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <TextField
                                error={errors.title ? true : false}
                                label={"Title"}
                                margin="dense"
                                variant="outlined"
                                fullWidth={true}
                                required={true}
                                value={title}
                                onChange={e => { setTitle(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <TextField
                                error={errors.description ? true : false}
                                label={"Description"}
                                margin="dense"
                                variant="outlined"
                                multiline
                                rows={7}
                                fullWidth={true}
                                required={true}
                                value={description}
                                onChange={e => { setDescription(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container spacing={2} alignItems={'flex-end'}>
                                <Grid item xs={12} sm={12} lg={2} md={12}>
                                    <FormControl
                                        fullWidth={true}
                                        error={errors.assignedTo ? true : false}
                                        sx={{ width: 1 }}
                                        margin="dense"
                                        required={true}>
                                        <InputLabel id="demo-simple-select-label">Assigned To</InputLabel>
                                        <Select
                                            label={"Assigned To"}
                                            fullWidth={true}
                                            MenuProps={MenuProps}
                                            variant="outlined"
                                            onChange={e => { setAssignedTo(e.target.value) }}
                                            value={assignedTo}
                                            input={<OutlinedInput label="Assigned To" />}
                                        >
                                            <MenuItem value={null}>Unassigned</MenuItem>
                                            {availableAssignees.map((item, index) => (
                                                <MenuItem key={index} value={`${item?.id},${item.type}`}>{item?.name}</MenuItem>
                                            ))}
                                            <MenuItem onClick={() => { contractorDialogOpen() }}>Contractors</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2} md={12}>
                                    <FormControl
                                        fullWidth={true}
                                        error={errors.howOften ? true : false}
                                        sx={{ width: 1 }}
                                        margin="dense"
                                        required={true}>
                                        <InputLabel id="demo-simple-select-label">How Often</InputLabel>
                                        <Select
                                            label={"How Often"}
                                            fullWidth={true}
                                            MenuProps={MenuProps}
                                            variant="outlined"
                                            onChange={e => { setHowOften(e.target.value) }}
                                            value={howOften}
                                            input={<OutlinedInput label="How Often" />}
                                        >
                                            <MenuItem value={'Daily'}>Daily</MenuItem>
                                            <MenuItem value={'Weekly'}>Weekly</MenuItem>
                                            <MenuItem value={'Monthly'}>Monthly</MenuItem>
                                            <MenuItem value={'Yearly'}>Yearly</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2} md={12}>
                                    <FormControl
                                        fullWidth={true}
                                        error={errors.everyDay ? true : false}
                                        sx={{ width: 1 }}
                                        margin="dense"
                                        required={true}>
                                        <InputLabel id="demo-simple-select-label">Every</InputLabel>
                                        <Select
                                            label={"Every"}
                                            fullWidth={true}
                                            MenuProps={MenuProps}
                                            variant="outlined"
                                            onChange={e => { setEveryDay(e.target.value) }}
                                            value={everyDay}
                                            input={<OutlinedInput label="Every" />}
                                        >
                                            {days.map((day, index) => (
                                                <MenuItem key={index} value={day?.value}>{day?.text}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2} md={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            views={["day"]}
                                            label="First Start Date"
                                            value={firstStartDate}
                                            onChange={(newValue) => { onFirstStartDateChange(newValue) }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true} required={true} margin='dense' error={errors.firstStartDate ? true : false} />} />

                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} md={12}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {editStatus ? (
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                style={{ fontSize: 15, background: '#0ed145' }}
                                                onClick={() => { onUpdateButtonClick() }}
                                            >UPDATE RE-OCCURING SERVICE REQUEST</Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                style={{ fontSize: 15, background: '#0ed145' }}
                                                onClick={() => { onCreateButtonClick() }}
                                            >ADD RE-OCCURING SERVICE REQUEST</Button>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid >
                    <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold", margin: '20px' }}>Current Scheduled Service Requests</p>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Frequency</TableCell>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Title</TableCell>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Next Occurance</TableCell>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reOccuring && reOccuring.map((occur, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{getFrequency(occur?.frequency, occur?.every)}</TableCell>
                                        <TableCell className='tableCell-maxWidth'>{occur?.title}</TableCell>
                                        <TableCell className='tableCell-maxWidth'>{moment(occur?.nextOccurance).format('MMM DD, yyyy')}</TableCell>
                                        <TableCell className='tableCell-maxWidth'><EditIcon color='primary' onClick={() => { editReoccuringSr(occur) }} /> <DeleteIcon color='error' onClick={() => { deleteReoccuringSr(occur?._id) }} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div >
            </div >
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={contractorDialog}
                onClose={() => { contractorDialogClose() }}
            >
                <DialogTitle textAlign={"center"}>Contractors</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Name</TableCell>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {availabeContractorAssigness.map((contractor) => (
                                    <TableRow>
                                        <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{contractor.name}</TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', flexWrap: 'wrap' }}>
                                                <ToolTip phone={contractor?.phone} email={contractor?.email} />
                                                <Button color="primary" sx={{ fontSize: 15 }} variant="outlined" size="small" onClick={() => onSelectContractor(contractor)}>SELECT</Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default ReOccuringServiceRequest;