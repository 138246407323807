import React, { useState, useEffect } from 'react'
import { TextField, Button, FormControl, InputLabel, MenuItem, Select, Box, CircularProgress } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import {
  getManagerLeases, getPropertyFinal, managerSchedulemoveout,
  managerMoveoutForwardEmail, managerCancelMoveout
} from "../../../services";
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolTip from '../../../components/ToolTip';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

function ScheduleMoveOut() {
  const params = useParams();
  const navigate = useNavigate();

  const [property, setProperty] = useState({});
  const [tenant, setTenant] = useState([]);
  const [lease, setLease] = useState(null);

  const [date, setDate] = useState(null);
  const [reason, setReason] = useState("");

  const [name, setName] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  // loader
  const [loading, setLoading] = useState(true);

  const today = new Date();

  const getProperty = async () => {
    await getManagerLeases(params.applicationId)
      .then((res) => {
        const getProp = res?.data?.data;
        setProperty(getProp);
      }).catch((err) => console.log(`Error${err}`));
  }

  const getLease = () => {
    setLoading(true);
    getPropertyFinal(params.applicationId).then((res) => {
      const lease = res?.data?.data;
      setLease(lease);

      if (lease.leaseTenants.length != 0) {
        setTenant(lease?.leaseTenants);
      }
      setDate(lease?.moveOutDetails?.moveOutDate);
      setReason(lease?.moveOutDetails?.reason ?? "");
      setCity(lease?.moveOutDetails?.mailForwardAddress?.city ?? "");
      setName(lease?.moveOutDetails?.mailForwardAddress?.name ?? "");
      setLine1(lease?.moveOutDetails?.mailForwardAddress?.line1 ?? "");
      setLine2(lease?.moveOutDetails?.mailForwardAddress?.line2 ?? "");
      setState(lease?.moveOutDetails?.mailForwardAddress?.state ?? "");
      setPincode(lease?.moveOutDetails?.mailForwardAddress?.zipCode ?? "");
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getProperty();
    getLease();
  }, []);

  const schedulemoveoutClick = () => {
    let data = {
      moveOutDate: date,
      reason: reason,
    }
    managerSchedulemoveout(params.applicationId, data).then((res) => {
      toast.success("Scheduled Move Out Successfully");
      getLease();
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  const cancelClick = () => {
    managerCancelMoveout(params.applicationId).then((res) => {
      toast.success("Cancelled Move Out Successfully");
      setReason("")
      getLease();
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  const saveClick = () => {
    let data = {
      forwardAddressCity: city,
      forwardAddressLine1: line1,
      forwardAddressLine2: line2,
      forwardAddressName: name,
      forwardAddressState: state,
      forwardAddressZipCode: pincode,
    }
    managerMoveoutForwardEmail(params.applicationId, data).then((res) => {
      toast.success("Updated Mail Forwarding Address Successfully");
      getLease();
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  const status = (moveOut) => {
    let hasMoveOutInitiated = [2, 3, 4, 5];
    if (moveOut?.moveOutDetails) {
      if (hasMoveOutInitiated.includes(moveOut?.moveOutDetails?.status)) {
        return true;
      } else {
        return false;
      }
    }
  }

  // Loader
  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ textAlign: "center", textTransform: "uppercase", paddingBottom: '50px' }}>
        <p style={{ fontSize: 17 }}>
          <Link to="/manager/home"> DASHBOARD </Link> / <Link to={`/manager/properties/${property?.id}/edit`}> {property?.address?.line1} </Link> / <Link to={`/manager/properties/${property?.id}/lease`}> LEASE </Link> /  MOVE OUT FROM </p>
      </div>
      <p className="preview-notice-heading">Lease Move Out Form</p>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: 'wrap', gap: 10 }}>
        <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap' }}>
          <span style={{ fontSize: 20, fontWeight: "bold", paddingBottom: '20px' }}>Tenant</span>
          <span style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 10, alignItems: 'center' }}>
            <span style={{ fontSize: 16, fontWeight: "bold" }}>{tenant[0]?.name?.first + " " + tenant[0]?.name?.last}</span>
            <ToolTip phone={tenant[0]?.phone?.code + " " + tenant[0]?.phone?.number} email={tenant[0]?.email?.id} />
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap', textAlign: 'right' }}>
          <span style={{ fontSize: 20, fontWeight: "bold", paddingBottom: '20px' }}>Property</span>
          <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.line1}</span>
          <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.line2} {property.unitNumber}</span>
          <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.city}</span>
          <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.state} - {property?.address?.zipCode}</span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <p style={{ fontWeight: "bold", paddingTop: '20px' }}>When would you like your tenants to move out?</p>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["day", "month", "year"]}
            disabled={status(lease)}
            label="Move out Date"
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            minDate={today}
            renderInput={(params) => <TextField {...params} fullWidth={true} style={{ width: '50%' }} />} />
        </LocalizationProvider>
        <TextField
          label="Reason"
          disabled={status(lease)}
          id="standard-basic"
          variant="outlined"
          style={{ width: '50%' }}
          type="text"
          multiline
          rows={5}
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }} />
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "right", flexWrap: 'wrap', gap: 10, paddingTop: "20px" }}>
        {!status(lease) ?
          <Button variant="contained"
            type="submit"
            color='warning'
            onClick={(e) => schedulemoveoutClick(e)}
            sx={{ fontSize: 15 }}>
            SCHEDULE MOVE OUT
          </Button>
          : ""}
        {status(lease) ?
          <Button variant="contained"
            type="submit"
            color="warning"
            sx={{ fontSize: 15 }}
            onClick={(e) => cancelClick(e)}>
            CANCEL MOVE OUT
          </Button>
          : ""}
        {status(lease) ?
          <Button variant="contained"
            type="submit"
            sx={{ fontSize: 15 }}
            onClick={() => { navigate(`/manager/leases/${params.applicationId}/move-out-inspection`) }}>
            VIEW MOVE OUT CHECKLIST
          </Button>
          : ""}
      </div>

      {status(lease) ?
        <div>
          <div style={{ padding: '20px 0px' }}>
            <hr />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <p style={{ fontWeight: "bold" }}>Tenant Mail Forwarding Address</p>
            <TextField
              label="Name"
              id="standard-basic"
              variant="outlined"
              style={{ width: '50%' }}
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }} />
            <TextField
              label="Address Line 1"
              id="standard-basic"
              variant="outlined"
              style={{ width: '50%' }}
              type="text"
              value={line1}
              onChange={(e) => {
                setLine1(e.target.value);
              }} />
            <TextField
              label="Address Line 2"
              id="standard-basic"
              variant="outlined"
              style={{ width: '50%' }}
              type="text"
              value={line2}
              onChange={(e) => {
                setLine2(e.target.value);
              }} />
            <TextField
              label="City"
              id="standard-basic"
              variant="outlined"
              style={{ width: '50%' }}
              type="text"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }} />
            <FormControl style={{ width: '50%' }}>
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="State"
                sx={{ width: 1 }}
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}>
                <MenuItem value="-Select-">-Select-</MenuItem>
                <MenuItem value="AL">Alabama</MenuItem>
                <MenuItem value="AK">Alaska</MenuItem>
                <MenuItem value="AS">American Samoa</MenuItem>
                <MenuItem value="AZ">Arizona</MenuItem>
                <MenuItem value="AR">Arkansas</MenuItem>
                <MenuItem value="CA">California</MenuItem>
                <MenuItem value="CO">Colorado</MenuItem>
                <MenuItem value="CT">Connecticut</MenuItem>
                <MenuItem value="DE">Delaware</MenuItem>
                <MenuItem value="DC">District Of Columbia</MenuItem>
                <MenuItem value="FM">Federated States Of Micronesia</MenuItem>
                <MenuItem value="FL">Florida</MenuItem>
                <MenuItem value="GA">Georgia</MenuItem>
                <MenuItem value="GU">Guam</MenuItem>
                <MenuItem value="HI">Hawaii</MenuItem>
                <MenuItem value="ID">Idaho</MenuItem>
                <MenuItem value="IL">Illinois</MenuItem>
                <MenuItem value="IN">Indiana</MenuItem>
                <MenuItem value="IA">Iowa</MenuItem>
                <MenuItem value="KS">Kansas</MenuItem>
                <MenuItem value="KY">Kentucky</MenuItem>
                <MenuItem value="LA">Louisiana</MenuItem>
                <MenuItem value="ME">Maine</MenuItem>
                <MenuItem value="MH">Marshall Islands</MenuItem>
                <MenuItem value="MD">Maryland</MenuItem>
                <MenuItem value="MA">Massachusetts</MenuItem>
                <MenuItem value="MI">Michigan</MenuItem>
                <MenuItem value="MN">MInnesota</MenuItem>
                <MenuItem value="MS">Mississippi</MenuItem>
                <MenuItem value="MO">Missouri</MenuItem>
                <MenuItem value="MT">Montana</MenuItem>
                <MenuItem value="NE">Nebraska</MenuItem>
                <MenuItem value="NV">Nevada</MenuItem>
                <MenuItem value="NH">New Hampshire</MenuItem>
                <MenuItem value="NJ">New Jersey</MenuItem>
                <MenuItem value="NM">New Mexico</MenuItem>
                <MenuItem value="NY">New York</MenuItem>
                <MenuItem value="NC">North Carolina</MenuItem>
                <MenuItem value="ND">Noth Dekota</MenuItem>
                <MenuItem value="MP">Northern Mariana Islands</MenuItem>
                <MenuItem value="OH">Ohio</MenuItem>
                <MenuItem value="OK">Oklahoma</MenuItem>
                <MenuItem value="OR">Oregon</MenuItem>
                <MenuItem value="PW">Palau</MenuItem>
                <MenuItem value="PA">Pennsylvania</MenuItem>
                <MenuItem value="PR">Puerto Rico</MenuItem>
                <MenuItem value="RI">Rhode Island</MenuItem>
                <MenuItem value="SC">South Carolina</MenuItem>
                <MenuItem value="SD">South Dakota</MenuItem>
                <MenuItem value="TN">Tennessee</MenuItem>
                <MenuItem value="TX">Texas</MenuItem>
                <MenuItem value="UT">Utah</MenuItem>
                <MenuItem value="VT">Vermont</MenuItem>
                <MenuItem value="VI">Virgin Islands</MenuItem>
                <MenuItem value="VA">Virginia</MenuItem>
                <MenuItem value="WA">Washington</MenuItem>
                <MenuItem value="WV">West Virginia</MenuItem>
                <MenuItem value="WI">Wisconsin</MenuItem>
                <MenuItem value="WY">Wyoming</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Pincode"
              id="standard-basic"
              variant="outlined"
              style={{ width: "50%" }}
              type="text"
              value={pincode}
              onChange={(e) => {
                setPincode(e.target.value);
              }} />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="contained"
                type="submit"
                color="success"
                disabled={!status(lease)}
                style={{ fontSize: 15 }}
                onClick={(e) => saveClick(e)}>
                SAVE
              </Button>
            </div>
          </div>
        </div>
        : ""}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  )

}
export default ScheduleMoveOut;