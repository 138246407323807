import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function AppFooter() {
  const currentYear = new Date().getFullYear();
  const user = useSelector((state) => state.user.value);

  return (
    <div className="app-footer">
      <div className="app-footer-container">
        {/* <Typography variant="subtitle2">More</Typography> */}
        <div className="no-print" style={{ display: "flex", justifyContent: "space-evenly" }}>
          {(!sessionStorage.getItem("authToken") && !user?.manager) || (!sessionStorage.getItem("authToken") && !user?.tenant) ? (
            <Link to="/register" className="link">
              Register as Manager
            </Link>
          ) : null}
          <Link to="/" className="link">
            Terms and conditions
          </Link>
          <Link to="/privacy-policy" className="link">
            Privacy Policy
          </Link>
        </div>
        <Typography variant="body2" className="footerMargin">&copy; Rainbow &nbsp; {currentYear}</Typography>
      </div>

    </div>
  );
}

export default AppFooter;
