import { getApiBaseURL } from "../services";

const imageClick = (e, url) => {
    var config = "";
    e.preventDefault();
    const authToken = sessionStorage.getItem("authToken");
    if (authToken != undefined) {
        config = {
            headers: { Authorization: `Bearer ${authToken}` },
        };
    }
    fetch(
        `${getApiBaseURL()}/manager/${url}`,
        config
    )
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            var reader = new window.FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function () {
                var imageDataUrl = reader.result;

                const link = document.createElement("a");
                link.href = imageDataUrl;
                if (blob.type == "image/png" || blob.type == "image/jpeg") {
                    const newTab = window.open();
                    newTab?.document.write(
                        `<!DOCTYPE html><head><title>preview</title></head><body style="background: #0e0e0e; height:'100vh';"><div style="display:flex;justify-content:center;align-item:center"><img align="center "height='600rem' src="${imageDataUrl}"  ><div></body></html>`
                    );
                    newTab?.document.close();
                }
            };
        });
};

export default imageClick;

export const tenantImageClick = (e, url) => {
    var config = "";
    e.preventDefault();
    const authToken = sessionStorage.getItem("authToken");
    if (authToken != undefined) {
        config = {
            headers: { Authorization: `Bearer ${authToken}` },
        };
    }
    fetch(
        `${getApiBaseURL()}/tenant/${url}`,
        config
    )
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            var reader = new window.FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function () {
                var imageDataUrl = reader.result;

                const link = document.createElement("a");
                link.href = imageDataUrl;
                if (blob.type == "image/png" || blob.type == "image/jpeg") {
                    const newTab = window.open();
                    newTab?.document.write(
                        `<!DOCTYPE html><head><title>preview</title></head><body style="background: #0e0e0e; height:'100vh';"><div style="display:flex;justify-content:center;align-item:center"><img align="center "height='600rem' src="${imageDataUrl}"  ><div></body></html>`
                    );
                    newTab?.document.close();
                }
            };
        });
};

// export default tenantImageClick;