import { Box, Button, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usStates } from "../../../../helpers";
import { addManagerPropertyImage, editManagerProperty, getManagerProperty, removeManagerPropertyImage, inviteLeaseApplication, postArchiveProperty, postUnArchiveProperty, getCanArchive, getManagerPropertyGroups, getApiBaseURL } from "../../../../services";
import LinkIcon from '@mui/icons-material/Link';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-tabs/style/react-tabs.css';
import TabMenu from "./tabMenu";
import { MenuProps } from "../../../../components/MaterialUiSelectMenuStyle";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ManagerPropertiesEditPageLatest = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [property, setProperty] = useState(null);

    const [images, setImages] = useState([]);
    const [propertyType, setPropertyType] = useState("private");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine1Error, setAddressLine1Error] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [addressLine2Error,] = useState("");
    const [unitNumber, setUnitNumber] = useState("");
    const [unitNumberError,] = useState("");
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");
    const [state, setState] = useState("Alabama");
    const [stateError,] = useState("");
    const [zip, setZip] = useState("");
    const [zipError, setZipError] = useState("");
    const [bedRoomCount, setBedRoomCount] = useState("0");
    const [bedRoomCountError,] = useState("");
    const [bathRoomCount, setBathRoomCount] = useState("0");
    const [bathRoomCountError,] = useState("");
    const [parkingSpaceCount, setParkingSpaceCount] = useState("0");
    const [parkingSpaceCountError,] = useState("");
    const [rent, setRent] = useState("0");
    const [rentError,] = useState("");
    const [securityDeposit, setSecurityDeposit] = useState("0");
    const [securityDepositError,] = useState("");
    const [area, setArea] = useState("0");
    const [areaError,] = useState("");
    const [trashSchedule, setTrashSchedule] = useState("");
    const [trashScheduleError, setTrashScheduleError] = useState("");
    const [recyclingSchedule, setRecyclingSchedule] = useState("");
    const [recyclingScheduleError, setRecyclingScheduleError] = useState("");
    const [assignedParkingSpace, setAssignedParkingSpace] = useState("");
    const [assignedParkingSpaceError, setAssignedParkingSpaceError] = useState("");
    const [rentControlled, setRentControlled] = useState(false);
    const [furnished, setFurnished] = useState(false);
    const [laundry, setLaundry] = useState("NONE");
    const [vehicleCode, setVehicleCode] = useState("");
    const [civilCode, setCivilCode] = useState("");
    const [archivePropertyGroup, setArchivePropertyGroup] = useState(false);
    const [canArchive, setCanArchive] = useState("");
    const [propertyGroups, setPropertyGroups] = useState([]);
    const [archived, setArchived] = useState(false);
    const [archiveProperty, setArchiveProperty] = useState(false);
    const [unarchiveProperty, setUnarchiveProperty] = useState(false);

    // Invite Lease Application
    const [inviteFirstName, setinviteFirstName] = useState("");
    const [inviteLastName, setinviteLastName] = useState("");
    const [inviteEmail, setInviteEmail] = useState("");

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    // Loader
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            let grps = [...propertyGroups];
            grps.length = 0;
            await getManagerProperty(params.propertyId).then(response => {
                let data = response?.data?.data;
                setProperty(data);

                setArchived(data.archived);
                setImages(data.images?.images ?? []);
                setPropertyType(data.isPublic ? "public" : "private");
                setDescription(data.description ?? "");

                // Address
                let address = data?.address;
                setAddressLine1(address?.line1 ?? "");
                setAddressLine2(address?.line2 ?? "");
                setUnitNumber(address?.unitNumber ?? "");
                setCity(address?.city ?? "");
                setState(address?.state ?? "Alabama");
                setZip(address?.zipCode ?? "");

                setBedRoomCount(data.numberOfBedrooms ?? "0");
                setBathRoomCount(data.numberOfBathrooms ?? "0");
                setParkingSpaceCount(data.numberOfParkingSpaces ?? "0");
                setRent(data.rentAmount ?? "0");
                setSecurityDeposit(data.securityDepositAmount ?? "0");
                setArea(data.area ?? "0");
                setTrashSchedule(data.trashSchedule ?? "");
                setRecyclingSchedule(data.recyclingSchedule ?? "");
                setAssignedParkingSpace(data.parkingSpacesDetails ?? "");
                setRentControlled(data.isRentControlled);
                setFurnished(data.isFurnished);
                setLaundry(data.laundryFacility);
                setVehicleCode(data?.vehicleCode);
                setCivilCode(data?.civilCode);

                return getManagerPropertyGroups();
            }).then((res) => {
                let data = res?.data?.data;
                setPropertyGroups(data);

                return getCanArchive(params.propertyId);
            }).then((res) => {
                setCanArchive(res?.data?.data);
            })
        } catch (err) {
            setLoading(false);
            console.log(`Error${err}`);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const removeImage = (index) => {
        let toDelete;
        const newImages = images.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });

        removeManagerPropertyImage(params.propertyId, toDelete.id).then(response => {
            setImages(newImages);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };

    const renderImages = () => {
        return images.map((item, index) => {
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={`${getApiBaseURL()}/${item.url}`} loading="lazy" alt="Property" style={{ height: "110px", width: "175px", marginTop: "20px", cursor: "pointer" }} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer", marginBottom: '5px' }} onClick={() => { removeImage(index) }}>
                            Remove
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const handleImagesAdd = (e) => {
        let form = new FormData();
        form.append("image", e.target.files[0]);
        addManagerPropertyImage(params.propertyId, form).then(response => {
            const newImages = [...images];
            newImages.push(response.data.data);
            setImages(newImages);
        }).catch(err => { console.log(err) });
    };

    const renderStateOptions = () => {
        return usStates.map((item) => {
            return <MenuItem key={item} value={item}>{item}</MenuItem>
        })
    };

    const SendInvite = (e) => {
        let datas = {
            inviteeFirstName: inviteFirstName,
            inviteeLastName: inviteLastName,
            inviteeEmail: inviteEmail,
            invitationLinkBaseUrl: `${window.location.origin}`
        }
        inviteLeaseApplication(params.propertyId, datas)
            .then((res) => {
                console.log('hello', res)
                if (res.data) {
                    setOpen(false)
                    toast.success("Invite Email sent successfully");
                } else {
                    toast.error("Sorry please check your email id or login");
                }
            });
    }

    const copyLink = (e) => {
        navigator.clipboard.writeText(
            `${window.location.origin}/lease-applications/apply?property=${params.propertyId}`)
        toast.info("Copied Lease Application Link to Clipboard")
    }

    const handleSaveClick = (e) => {
        e.preventDefault();

        let error = false;

        if (description === "") {
            setDescriptionError("Description cannot be empty");
            error = true;
        } else {
            setDescriptionError("");
        }
        if (addressLine1 === "") {
            setAddressLine1Error("Please fill this field");
            error = true;
        } else {
            setAddressLine1Error("");
        }
        if (city === "") {
            setCityError("City cannot be empty");
            error = true;
        } else {
            setCityError("");
        }
        if (zip === "") {
            setZipError("Zip code cannot be empty");
            error = true;
        } else if (zip && !zip.match(/(^\d{5}$)/g)) {
            setZipError("Enter a valid US Zipcode")
        } else {
            setZipError("");
        }
        if (trashSchedule === "") {
            setTrashScheduleError("Please fill this field");
            error = true;
        } else {
            setTrashScheduleError("");
        }
        if (recyclingSchedule === "") {
            setRecyclingScheduleError("Please fille this field");
            error = true;
        } else {
            setRecyclingScheduleError("");
        }
        if (assignedParkingSpace === "") {
            setAssignedParkingSpaceError("Please fill in this field");
            error = true;
        } else {
            setAssignedParkingSpaceError("");
        }

        if (error) return;

        let data = {
            isPublic: propertyType === "public",
            decription: description,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            unitNumber: unitNumber,
            city: city,
            state: state,
            zipCode: zip,
            numberOfBedrooms: bedRoomCount,
            numberOfBathrooms: bathRoomCount,
            numberOfParkingSpaces: parkingSpaceCount,
            vehicleCode: vehicleCode,
            civilCode: civilCode,
            rentAmount: rent,
            securityDepositAmount: securityDeposit,
            area: area,
            trashSchedule: trashSchedule,
            recyclingSchedule: recyclingSchedule,
            parkingSpacesDetails: assignedParkingSpace,
            isFurnished: furnished,
            isRentControlled: rentControlled,
            laundryFacility: laundry,
        };

        editManagerProperty(params.propertyId, data).then(response => {
            toast.success("Property Updated Successfully.");
            navigate("/manager/home");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };

    const archiveDialogOpen = () => {
        propertyGroups.forEach((group) => {
            if (group.properties.includes(params.propertyId)) {
                setArchiveProperty(false);
                setArchivePropertyGroup(true);
            }
            if (!group.properties.includes(params.propertyId)) {
                if (canArchive === true) {
                    setArchiveProperty(true);
                }
            }
        })
    }

    const unarchiveDialogOpen = () => {
        setUnarchiveProperty(true);
    }

    const unarchiveDialogCancel = () => {
        setUnarchiveProperty(false);
    }

    const archiveDialogCancel = () => {
        setArchivePropertyGroup(false);
    }
    const archDialogCancel = () => {
        setArchiveProperty(false);
    }

    const unarchiveokClick = () => {
        postUnArchiveProperty(params.propertyId).then((res) => {
            navigate("/manager/property-grouping");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }
    const archiveokClick = () => {
        postArchiveProperty(params.propertyId).then((res) => {
            navigate("/manager/home");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const archiveGroupokClick = () => {
        navigate("/manager/property-grouping");
    }

    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: '30px' }}>
            <p style={{ fontSize: 25, textAlign: "center", fontWeight: "bold" }}>{property?.address?.line1 + "," + property?.address?.line2 + " " + property?.address?.unitNumber}</p>
            <div className="property-tab-wrp">
                <TabMenu />
                <div className="tabContentWrp">
                    <div style={{ display: 'flex', justifyContent: 'right', margin: '20px 0px 20px 0px' }}>
                        <Button
                            sx={{
                                fontSize: 15,
                                borderRadius: 5,
                            }}
                            onClick={handleOpen}
                            variant="contained"
                            type="submit"
                            color="warning">INVITE LEASE APPLICATION</Button>
                    </div>

                    <div className="imgUploadSec">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap', alignItems: 'center' }}>
                            <h3 className="subHeader">Add Images</h3>
                            <label htmlFor="uploadBtn" className='property-edit-add-image-icon'>
                                <CloudUploadIcon color='primary' sx={{ cursor: 'pointer', height: 25, width: 25 }} />
                            </label>
                        </div>
                        <div className="img-container">
                            {renderImages()}
                            <div className="uploadBtn">
                                <input type="file" id="uploadBtn" accept="image/*"
                                    onChange={handleImagesAdd}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="propDetails">
                        <h3 className="subHeader">Make your property Public or Private (Public properties will appear in public search results).</h3>
                        <form>
                            <div>
                                <RadioGroup row>
                                    <FormControlLabel
                                        value={"public"}
                                        control={<Radio color="success" />}
                                        label="Public"
                                        onChange={e => { setPropertyType('public') }}
                                        checked={propertyType === 'public'}
                                    />
                                    <FormControlLabel
                                        value={"private"}
                                        control={<Radio color="success" />}
                                        label="Private"
                                        onChange={e => { setPropertyType('private') }}
                                        checked={propertyType === 'private'}
                                    />
                                </RadioGroup>
                            </div>
                            <div className="form-item">
                                <h3 className="subHeader">Description</h3>
                                <TextField
                                    helperText={descriptionError && descriptionError}
                                    error={descriptionError ? true : false}
                                    placeholder={`Describe the property accurately. Your real estate listing description should be accurate and attractive. Here are some tips:\n
 1. Choose the right adjectives.\n 2. Highlight unique features.\n 3. Leave out super basic info.`}
                                    margin="dense"
                                    variant="outlined"
                                    multiline
                                    rows={7}
                                    value={description}
                                    onChange={e => { setDescription(e.target.value) }}
                                />
                            </div>
                        </form>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} md={6}>
                            <h3 className="subHeader">Address</h3>
                            <TextField
                                helperText={addressLine1Error && addressLine1Error}
                                error={addressLine1Error ? true : false}
                                margin="dense"
                                variant="outlined"
                                placeholder="Enter address line 1"
                                sx={{ width: 1 }}
                                value={addressLine1}
                                label="Address Line 1"
                                onChange={e => { setAddressLine1(e.target.value) }}
                            />
                            <TextField
                                helperText={addressLine2Error && addressLine2Error}
                                error={addressLine2Error ? true : false}
                                margin="dense"
                                variant="outlined"
                                label="Address Line 2"
                                placeholder="Enter address line 2"
                                sx={{ width: 1 }}
                                value={addressLine2}
                                onChange={e => { setAddressLine2(e.target.value) }}
                            />
                            <TextField
                                helperText={unitNumberError && unitNumberError}
                                error={unitNumberError ? true : false}
                                margin="dense"
                                variant="outlined"
                                label="Unit Number"
                                placeholder="Enter unit number"
                                sx={{ width: 1 }}
                                value={unitNumber}
                                onChange={e => { setUnitNumber(e.target.value) }}
                            />
                            <TextField
                                helperText={cityError && cityError}
                                error={cityError ? true : false}
                                margin="dense"
                                variant="outlined"
                                value={city}
                                label="City"
                                placeholder="Enter City"
                                sx={{ width: 1 }}
                                onChange={e => { setCity(e.target.value) }}
                            />
                            <FormControl
                                fullWidth={true}
                                helperText={stateError && stateError}
                                error={stateError ? true : false}
                                sx={{ width: 1 }}
                                margin="dense">
                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                <Select
                                    label={"State"}
                                    fullWidth={true}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                    onChange={e => { setState(e.target.value) }}
                                    value={state}
                                    input={<OutlinedInput label="State" />}
                                >
                                    {renderStateOptions()}
                                </Select>
                            </FormControl>
                            <TextField
                                helperText={zipError && zipError}
                                error={zipError ? true : false}
                                margin="dense"
                                variant="outlined"
                                value={zip}
                                label="Zip Code"
                                placeholder="Enter ZIP Code"
                                sx={{ width: 1 }}
                                onChange={e => {
                                    if (e.target.value.length == 6) return false;
                                    setZip(e.target.value)
                                }}
                            />
                            <TextField
                                margin="dense"
                                variant="outlined"
                                value={vehicleCode}
                                label="Vehicle Code"
                                placeholder="Enter Vehicle Code"
                                sx={{ width: 1 }}
                                onChange={e => {
                                    setVehicleCode(e.target.value)
                                }}
                            />
                            <TextField
                                margin="dense"
                                variant="outlined"
                                value={civilCode}
                                label="Civil Code"
                                placeholder="Enter Civil Code"
                                sx={{ width: 1 }}
                                onChange={e => {
                                    setCivilCode(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <h3 className="subHeader">Basic Details</h3>
                            <TextField
                                helperText={bedRoomCountError && bedRoomCountError}
                                error={bedRoomCountError ? true : false}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                sx={{ width: 1 }}
                                value={bedRoomCount}
                                label="Bedroom Count"
                                onChange={e => { setBedRoomCount(e.target.value) }}
                                inputProps={{ min: "0" }}
                            />
                            <TextField
                                helperText={bathRoomCountError && bathRoomCountError}
                                error={bathRoomCountError ? true : false}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={bathRoomCount}
                                label="Bathroom Count"
                                onChange={e => { setBathRoomCount(e.target.value) }}
                                inputProps={{ min: "0" }}
                                sx={{ width: 1 }}
                            />
                            <TextField
                                helperText={parkingSpaceCountError && parkingSpaceCountError}
                                error={parkingSpaceCountError ? true : false}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                label="Parking Space Count"
                                value={parkingSpaceCount}
                                onChange={e => { setParkingSpaceCount(e.target.value) }}
                                inputProps={{ min: "0" }}
                                sx={{ width: 1 }}
                            />
                            <TextField
                                fullWidth={true}
                                helperText={rentError && rentError}
                                error={rentError ? true : false}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={rent}
                                label="Rent"
                                sx={{ width: 1 }}
                                onChange={e => { setRent(e.target.value) }}
                                inputProps={{ min: "0" }}
                                InputProps={{ startAdornment: <InputAdornment position="start"><Icon style={{ color: "black" }}>$</Icon></InputAdornment> }}
                            />
                            <TextField
                                fullWidth={true}
                                helperText={securityDepositError && securityDepositError}
                                error={securityDepositError ? true : false}
                                variant="outlined"
                                type="number"
                                label="Security Deposit"
                                margin="dense"
                                sx={{ width: 1 }}
                                value={securityDeposit}
                                onChange={e => { setSecurityDeposit(e.target.value) }}
                                inputProps={{ min: "0" }}
                                InputProps={{ startAdornment: <InputAdornment position="start"><Icon style={{ color: "black" }}>$</Icon></InputAdornment> }}
                            />
                            <TextField
                                helperText={areaError && areaError}
                                error={areaError ? true : false}
                                margin="dense"
                                variant="outlined"
                                sx={{ width: 1 }}
                                type="number"
                                value={area}
                                label="Area"
                                onChange={e => { setArea(e.target.value) }}
                                inputProps={{ min: "0" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} md={6}>
                            <h3 className="subHeader">Other Details</h3>
                            <TextField
                                helperText={trashScheduleError && trashScheduleError}
                                error={trashScheduleError ? true : false}
                                margin="dense"
                                variant="outlined"
                                value={trashSchedule}
                                label="Trash Schedule"
                                placeholder='Eg Monday'
                                onChange={e => { setTrashSchedule(e.target.value) }}
                                inputProps={{ min: "0" }}
                                sx={{ width: 1 }}
                            />
                            <TextField
                                helperText={recyclingScheduleError && recyclingScheduleError}
                                error={recyclingScheduleError ? true : false}
                                margin="dense"
                                variant="outlined"
                                label="Recycling Schedule"
                                placeholder='Eg Monday'
                                sx={{ width: 1 }}
                                value={recyclingSchedule}
                                onChange={e => { setRecyclingSchedule(e.target.value) }}
                            />
                            <TextField
                                helperText={assignedParkingSpaceError && assignedParkingSpaceError}
                                error={assignedParkingSpaceError ? true : false}
                                margin="dense"
                                variant="outlined"
                                label="Assigned Parking Space"
                                placeholder="Enter details of Assigned Parking Space"
                                sx={{ width: 1 }}
                                value={assignedParkingSpace}
                                onChange={e => { setAssignedParkingSpace(e.target.value) }}
                            />

                            <div className="btnSecWrp">
                                <div className="optionBoxSec">
                                    <div
                                        className="optionBox"
                                        style={{
                                            fontWeight: 600,
                                            flexDirection: "row"
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            id="rentcontrol"
                                            checked={rentControlled}
                                            onChange={() => { setRentControlled(!rentControlled) }}
                                        />
                                        <label for="rentcontrol" style={{ cursor: 'pointer' }} htmlFor="rentcontrol">Rent Controlled</label>
                                    </div>
                                    <div className="radioSecWrp">
                                        <RadioGroup row>
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio color="success" />}
                                                label="Unfurnished"
                                                checked={furnished === false}
                                                onChange={e => { setFurnished(false) }}
                                            />
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio color="success" />}
                                                label=" Furnished"
                                                checked={furnished === true}
                                                onChange={e => { setFurnished(true) }}
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                                <br></br>
                                <div className="optionBoxSec">
                                    <h3 className="subHeader">Laundry Availablity</h3>
                                    <div className="radioSecWrp">
                                        <RadioGroup row>
                                            <FormControlLabel
                                                value={'NONE'}
                                                control={<Radio color="success" />}
                                                label="NONE"
                                                checked={laundry === 'NONE'}
                                                onChange={e => { setLaundry('NONE') }}
                                            />
                                            <FormControlLabel
                                                value={'IN_UNIT'}
                                                control={<Radio color="success" />}
                                                label="IN_UNIT"
                                                checked={laundry === 'IN_UNIT'}
                                                onChange={e => { setLaundry('IN_UNIT') }}
                                            />
                                            <FormControlLabel
                                                value={'IN_COMPLEX'}
                                                control={<Radio color="success" />}
                                                label="IN_COMPLEX"
                                                checked={laundry === 'IN_COMPLEX'}
                                                onChange={e => { setLaundry('IN_COMPLEX') }}
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>
                            {archived == false ? (
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{ fontSize: 15 }}
                                    onClick={archiveDialogOpen}
                                >
                                    Archive Property
                                </Button>) :
                                (<Button
                                    variant="contained"
                                    color="error"
                                    sx={{ fontSize: 15 }}
                                    onClick={unarchiveDialogOpen}
                                >
                                    UnArchive Property
                                </Button>)}
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="warning"
                                sx={{ fontSize: 15 }}
                                onClick={handleSaveClick}
                            >
                                Save Property
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={open}
                onClose={() => setOpen(false)}>
                <DialogTitle textAlign="center">
                    Invite Lease Application
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <TextField
                            variant="outlined"
                            label="First Name *"
                            margin="dense"
                            type="text"
                            onChange={(e) => { setinviteFirstName(e.target.value) }}>
                        </TextField>
                        <TextField
                            variant="outlined"
                            label="Last Name"
                            margin="dense"
                            type="text"
                            onChange={(e) => { setinviteLastName(e.target.value) }}>
                        </TextField>
                    </div>
                    <TextField
                        label="Email *"
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        type="email"
                        onChange={(e) => { setInviteEmail(e.target.value) }}>
                    </TextField>
                </DialogContent>
                <div style={{ display: 'flex', justifyContent: "space-between", margin: '20px' }}>
                    <Button
                        sx={{ fontSize: 15 }}
                        size="small"
                        type="submit"
                        endIcon={<LinkIcon />}
                        onClick={() => copyLink()}>Copy Link</Button><br></br>
                    <Button
                        sx={{ fontSize: 15 }}
                        size="small"
                        type="submit"
                        color='error'
                        onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        sx={{ fontSize: 15 }}
                        size="small"
                        variant="contained"
                        type="submit"
                        color="warning"
                        onClick={() => SendInvite()}>Send Invite</Button>
                </div>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={archivePropertyGroup}
                onClose={() => setArchivePropertyGroup(false)}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        This property is part of a complex. Sending you to the complex page to Archive the complex
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", gap: 10 }}>
                    <div>
                        <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={archiveGroupokClick}>Ok</Button>
                        <Button color='error' sx={{ fontSize: 15 }} onClick={archiveDialogCancel} >CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={archiveProperty}
                onClose={() => setArchiveProperty(false)}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Are you sure you want to archive this property.
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", gap: 10 }}>
                    <div>
                        <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={archiveokClick}>Ok</Button>
                        <Button color='error' sx={{ fontSize: 15 }} onClick={archDialogCancel} >CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={unarchiveProperty}
                onClose={() => setUnarchiveProperty(false)}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Are you sure to unarchive the property.
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", gap: 10 }}>
                    <div>
                        <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={unarchiveokClick}>Ok</Button>
                        <Button color='error' sx={{ fontSize: 15 }} onClick={unarchiveDialogCancel} >CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div >
    );
}

export default ManagerPropertiesEditPageLatest;