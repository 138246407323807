import { Tabs, Tab, Box } from "@mui/material";
import React from "react";
import { useParams, useLocation, Link } from 'react-router-dom';

function TabMenu({ serviceRequestId }) {
  const params = useParams();
  const location = useLocation();
  const currentTab = location.pathname;

  return (
    <Box
      sx={{ borderBottom: 1, borderColor: "divider" }}
    >
      <Tabs
        variant="scrollable"
        aria-label="basic tabs example"
        value={currentTab}
      >
        <Tab
          label={<h4 style={{ margin: "0px" }}>Edit Property</h4>}
          value={`/manager/properties/${params.propertyId}/edit`}
          to={`/manager/properties/${params.propertyId}/edit`}
          component={Link}
        />
        {currentTab === `/manager/properties/${params.propertyId}/service-requests` ?
          <Tab
            label={<h4 style={{ margin: "0px" }}>Service Requests</h4>}
            value={`/manager/properties/${params.propertyId}/service-requests`}
            to={`/manager/properties/${params.propertyId}/service-requests`}
            component={Link}
          /> : ""}
        {currentTab === `/manager/properties/${params.propertyId}/service-requests/re-occuring` ?
          <Tab
            label={<h4 style={{ margin: "0px" }}>Service Requests</h4>}
            value={`/manager/properties/${params.propertyId}/service-requests/re-occuring`}
            to={`/manager/properties/${params.propertyId}/service-requests`}
            component={Link}
          /> : ""}
        {currentTab === `/manager/properties/${params.propertyId}/edit` ||
          currentTab === `/manager/properties/${params.propertyId}/finance` ||
          currentTab === `/manager/properties/${params.propertyId}/lease` ||
          currentTab === `/manager/properties/${params.propertyId}/overview` ?
          <Tab
            label={<h4 style={{ margin: "0px" }}>Service Requests</h4>}
            value={`/manager/properties/${params.propertyId}/service-requests`}
            to={`/manager/properties/${params.propertyId}/service-requests`}
            component={Link}
          /> : ""}
        {currentTab === `/manager/properties/${params.propertyId}/service-requests/new` ?
          <Tab
            label={<h4 style={{ margin: "0px" }}>Service Requests</h4>}
            value={`/manager/properties/${params.propertyId}/service-requests/new`}
            to={`/manager/properties/${params.propertyId}/service-requests`}
            component={Link}
          /> : ""}
        {currentTab === `/manager/service-requests/${serviceRequestId}` ?
          <Tab
            label={<h4 style={{ margin: "0px" }}>Service Requests</h4>}
            value={`/manager/service-requests/${serviceRequestId}`}
            to={`/manager/properties/${params.propertyId}/service-requests`}
            component={Link}
          /> : ""}
        <Tab
          label={<h4 style={{ margin: "0px" }}>Financial</h4>}
          value={`/manager/properties/${params.propertyId}/finance`}
          to={`/manager/properties/${params.propertyId}/finance`}
          component={Link}
        />
        <Tab
          label={<h4 style={{ margin: "0px" }}>Lease</h4>}
          value={`/manager/properties/${params.propertyId}/lease`}
          to={`/manager/properties/${params.propertyId}/lease`}
          component={Link}
        />
        <Tab
          label={<h4 style={{ margin: "0px" }}>Lease Applications</h4>}
          value={`/manager/properties/${params.propertyId}/overview`}
          to={`/manager/properties/${params.propertyId}/overview`}
          component={Link}
        />
      </Tabs>
    </Box>
  );
}

export default TabMenu;