import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, Checkbox, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, Grid, Icon, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Stack } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    getManagerServiceRequestSuggestedTags, getManagerServiceRequestMembers, patchManagerServiceRequestAssignee,
    getManagerServiceRequest, patchManagerServiceRequestState, postManagerServiceRequestAttachments,
    deleteManagerServiceRequestAttachments, patchManagerServiceRequest, postManagerServiceRequestComments,
    deleteManagerServiceRequestComments, patchManagerServiceRequestComments, postManagerServiceRequestCommentsAttachment,
    deleteManagerServiceRequestCommentsAttachment, postContractorAdd, getContractor, getContractorTags, appointContractor,
    unAppointContractor, postContractorStarRating, getContractorStarRating, postManagerServiceRequestFinanceAll,
    deleteManagerServiceRequestFinanceAll, patchManagerServiceRequestFinanceAll, getApiBaseURL, getSingleProperty,
    postManagerServiceRequestMaterialsAttachments, deleteManagerServiceRequestMaterialsAttachments,
    patchManagerServiceRequestMaterialsAttachments, deleteManagerServiceRequestContractorFinanceAttachment,
    postManagerServiceRequestContractorFinance, patchManagerServiceRequestContractorFinance,
    deleteManagerServiceRequestContractorFinance,
    patchManagerServiceRequestHtmlDescCheckBox
} from "../../../services";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Moment from "moment";
import { extendMoment } from "moment-range";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Rating from '@mui/material/Rating';
import { Popup } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import copy from "copy-to-clipboard";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FinanceCategoriesUppercase from "../../../components/financeCategoriesUppercase";
import imageClick from "../../../components/imageView";
import { states } from "../../../helpers";
import { formatPhoneNumber } from "../../../components/phoneNumberFormat";
import TablePaginationActions from "../../../components/TablePagination/tablePagination";
import { MenuProps } from "../../../components/MaterialUiSelectMenuStyle";
import TabMenu from "../properties/edit/tabMenu";

const expense = [
    {
        value: "OTHER_EXPENSE",
        label: "Other Expense",
    },
    {
        value: "INSURANCE_EXPENSE",
        label: "Insurance Expense",
    },
    {
        value: "ADVERTISING_EXPENSE",
        label: "Advertising Expense",
    },
    {
        value: "CLEANING_AND_MAINTENANCE_EXPENSE",
        label: "Cleaning and Maintenance Expense",
    },
    {
        value: "LEGAL_AND_PROFESSIONAL_SERVICE_EXPENSE",
        label: "Legal and Professional Services Expense",
    },
    {
        value: "MEALS_EXPENSE",
        label: "Meals Expense",
    },
    {
        value: "REPAIRS_EXPENSE",
        label: "Repairs Expense",
    },
    {
        value: "SUPPLIES_EXPENSE",
        label: "Supplies Expense",
    },
    {
        value: "TRAVEL_EXPENSE",
        label: "Travel Expense",
    },
    {
        value: "UTILITIES_EXPENSE",
        label: "Utilities Expense",
    },
    {
        value: "CAPITAL_EXPENSE",
        label: 'Capital Expense',
    },
    {
        value: "MORTGAGES_AND_LOANS_EXPENSE",
        label: 'Mortgages and Loans Expense',
    }
];

const priority = [
    {
        value: "0",
        label: "Low",
    },
    {
        value: "1",
        label: "Medium",
    },
    {
        value: "2",
        label: "High",
    },
];

function ServiceRequests() {
    const params = useParams();
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const hiddenFileInput = useRef();

    const [propertyById, setProperyById] = useState(null);
    const [serviceRequestById, setServiceRequestById] = useState(null);
    const [appointedcontractor, setAppointedContractor] = useState(null);

    // Finance
    const [financeEntryDate, setFinanceEntryDate] = useState(null);
    const [totalLabourCharge, setTotalLabourCharge] = useState("0");
    const [totalMaterialsCost, setTotalMaterialsCost] = useState("0");
    const [espenseType, setEspenseType] = useState("");
    const [totalLabourHoursWorked, setTotalLabourHoursWorked] = useState(0);
    const [managerDistanceTravelled, setManagerDistanceTravelled] = useState(0);
    const [travelEntryDate, setTravelEntryDate] = useState(null);

    // Service Request Fields
    const [nextFollowupDate, setNextFollowupDate] = useState(null);
    const [suggestedTags, setSuggestedTags] = useState(null);
    const [skillsRequired, setSkillsRequired] = useState([]);
    const [assignee, setAssignee] = useState([]);
    const [chooseAssi, setChooseAssi] = useState("");
    const [prior, setPrior] = useState(priority);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [managerNotes, setManagerNotes] = useState("");
    const [attachFiles, setAttachFiles] = useState([]);
    const [comment, setComment] = useState([]);
    const [commentFiles, setCommentFiles] = useState([]);
    const [commonMapview, setcommonMapview] = useState([]);
    const [content, setContent] = useState("");
    const [fileId, setFileId] = useState(0);
    const [images, setImages] = useState([]);

    // Contractor Add
    const [contractor, setContractor] = useState([])
    const [contractFirstName, setContractFirstName] = useState("");
    const [contractLastName, setContractLastName] = useState("");
    const [contractEmail, setContractEmail] = useState("");
    const [contractPhoneNo, setContractPhoneNo] = useState("");
    const [contractCity, setContractCity] = useState("");
    const [contractState, setContractState] = useState("");
    const [contractSkills, setContractSkills] = useState([]);
    const [contractDesc, setContractDesc] = useState("");
    const [contractorTags, setContractorTages] = useState([]);
    const [rating, setRating] = useState(0);

    // Contractor Finance History
    const [contractorTotalLabourCharge, setContractorTotalLabourCharge] = useState("0");
    const [contractorTotalMaterialCost, setContractorTotalMaterialCost] = useState("0");
    const [contractorTotalLabourHours, setContractorTotalLabourHours] = useState(0);
    const [contractorFinanceAttachment, setContractorFinanceAttachment] = useState(null);

    // Conditions
    const [name, setName] = useState(false);
    const [desc, setDesc] = useState(false);
    const [contratorCondition, setContractorCondition] = useState(false);
    const [commentCondition, setcommentCondition] = useState({});

    // Dialogs
    const [addContractorDialog, setAddContractorDialog] = useState(false);
    const [contractorViewModel, setContractorViewModel] = useState(false);

    // loading
    const [loading, setLoading] = useState(true);

    // AllFinances
    const [financeAll, setFinanceAll] = useState([]);

    // Pagination
    const [financeAllPage, setfinanceAllPage] = useState(0);
    const financeAllrowsPerPage = 5;
    const handleChangefinanceAllPage = (event, newPage) => {
        setfinanceAllPage(newPage);
    };
    const handleChangefinanceAllRowsPerPage = (event) => {
        setfinanceAllPage(parseInt(event.target.value, 10));
        setfinanceAllPage(0);
    };

    // Activities comments expand and hide 
    const toggle = id => {
        setcommentCondition(prevcommentCondition => ({ [id]: !prevcommentCondition[id] }));
    }

    // Contractor panel expand or not
    const [ContractorPanelExpanded, setContractorPanelExpanded] = useState(false);

    const contractorAddClick = () => {
        let data = {
            "firstName": contractFirstName,
            "lastName": contractLastName,
            "email": contractEmail,
            "phoneCode": "01",
            "phoneNumber": contractPhoneNo,
            "city": contractCity,
            "state": contractState,
            "skills": contractSkills,
            "starRatings": 0,
            "description": contractDesc,
        }
        postContractorAdd(data).then((res) => {
            setAddContractorDialog(false);
            AppointContractorClick();
            toast.success("Contractor Added Successfully")
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleChangeContractSkills = (event) => {
        const {
            target: { value },
        } = event;
        setContractSkills(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    var config = "";

    const pageLoad = async () => {
        setLoading(true);
        let files = [...attachFiles];
        files.length = 0;
        let cmtFiles = [...commentFiles];
        cmtFiles.length = 0;
        let cmnt = [...comment];
        cmnt.length = 0;
        await getManagerServiceRequest(params.serviceId).then((res) => {
            let data = res?.data?.data;
            let getDetails = { ...data }
            setServiceRequestById(data);
            setTitle(getDetails?.title);
            setDescription(getDetails?.description);
            setNextFollowupDate(getDetails?.nextFollowUpDate ? moment(getDetails?.nextFollowUpDate).format("MMMM DD, YYYY") : null);
            setManagerNotes(getDetails?.managerNotes);
            setPrior(getDetails?.priority);
            setSkillsRequired(getDetails?.tags);
            setFinanceAll(getDetails?.financeAll);
            // Contractor Finance History
            setContractorTotalLabourCharge(getDetails?.totalContractorLaborCost);
            setContractorTotalLabourHours(getDetails?.totalContractorLaborHours);

            if (getDetails?.property) {
                getSingleProperty(getDetails?.property).then((response) => {
                    let data1 = response?.data?.data
                    setProperyById(data1);
                })
            }

            if (getDetails?.contractor !== null) {
                setContractorCondition(true);
                getContractorStarRating(getDetails.contractor.contractor).then((res) => {
                    let data = res?.data?.data?.contractor;
                    setAppointedContractor(data);
                    if (data?.starRatings === 0.2) {
                        setRating(1);
                    }
                    if (data?.starRatings === 0.4) {
                        setRating(2);
                    }
                    if (data?.starRatings === 0.6) {
                        setRating(3);
                    }
                    if (data?.starRatings === 0.8) {
                        setRating(4);
                    }
                    if (data?.starRatings === 1) {
                        setRating(5);
                    }
                })
            }
            if (getDetails?.attachments.length != 0) {
                getDetails?.attachments.map((g) => {
                    const authToken = sessionStorage.getItem("authToken");
                    config = {
                        headers: { Authorization: `Bearer ${authToken}` },
                    };
                    fetch(
                        `${getApiBaseURL()}/manager/service-requests/${params.serviceId}/attachments/${g?.id}`, config
                    ).then(res => {
                        return res.blob();
                    }).then(blob => {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(blob);
                        reader.onload = function () {
                            var imageDataUrl = reader.result;
                            const link = document.createElement('a');
                            link.href = imageDataUrl;
                            if (blob.type == "image/png"
                                || blob.type == "image/gif"
                                || blob.type == "image/jpeg"
                                || blob.type == "image/svg"
                                || blob.type == "image/jpg"
                            ) {
                                files.push({
                                    "files": imageDataUrl,
                                    "id": g.id,
                                    "url": g.url,
                                })
                            }
                            if (blob.type == "application/pdf") {
                            }
                            setAttachFiles(files.flat(Infinity))
                        }
                    })
                })
            }
            if (getDetails?.comments.length != 0) {
                getDetails?.comments.map((e) => {
                    e?.attachments.map((h) => {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },

                        };
                        fetch(
                            `${getApiBaseURL()}/manager/${h?.url}`, config
                        ).then(res => {
                            return res.blob();
                        }).then(blob => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement('a');
                                link.href = imageDataUrl;
                                if (blob.type == "image/png"
                                    || blob.type == "image/gif"
                                    || blob.type == "image/jpeg"
                                    || blob.type == "image/svg"
                                    || blob.type == "image/jpg"
                                ) {
                                    cmtFiles.push({
                                        "files": imageDataUrl,
                                        "id": h.id,
                                        "url": h.url,
                                    })
                                }
                                if (blob.type == "application/pdf") {
                                }
                                setCommentFiles(cmtFiles.flat(Infinity))
                            }
                        })
                    })
                    cmnt.push({
                        "attachment": e?.attachments,
                        "content": e?.content,
                        "createdAt": e?.createdAt ? moment(e.createdAt).format('MMM D, YYYY, h:mm:ss a') : "",
                        "creator": e?.creator,
                        "id": e?.id,
                        "updatedAt": e?.updatedAt,
                    })
                    setComment(cmnt);
                })
            }
            if (getDetails?.events) {
                let groupedPeople = groupBy(getDetails?.events, "id");
                setcommonMapview(groupedPeople);
            }
            // Members
            getManagerServiceRequestMembers(params.serviceId).then((res) => {
                let data = res?.data?.data;
                setAssignee(data);
                data.forEach((e) => {
                    if (getDetails?.assignee?.assignee == e?.id) {
                        setChooseAssi(e?.name);
                    }
                })
            }).catch((err) => console.log(`Error${err}`));
            //Tags
            getManagerServiceRequestSuggestedTags(params.serviceId).then((res) => {
                let data = res?.data?.data;
                setSuggestedTags(data);
            }).catch((err) => console.log(`Error${err}`));
        }).finally(() => {
            setLoading(false);
        });
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        pageLoad();
    }, []);

    // Update
    const UpdateTitleClick = () => {
        let data = {
            title: title,
        }
        patchManagerServiceRequest(params.serviceId, data).then((res) => {
            toast.success("Service Request Title Updated");
            pageLoad();
            setName(false);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const UpdateDescriptionClick = () => {
        let data = {
            description: description,
        }
        patchManagerServiceRequest(params.serviceId, data).then((res) => {
            toast.success("Service Request Description Updated");
            pageLoad();
            setDesc(false);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const descriptionCheckbox = async (event) => {
        const target = event.target;
        if (target.tagName.toLowerCase() === 'input' && target.getAttribute('type') === 'checkbox') {
            // Toggle the checked state of the checkbox
            const isChecked = target.hasAttribute('checked');
            if (isChecked) {
                target.removeAttribute('checked');
            } else {
                target.setAttribute('checked', '');
            }
            // Retrieve all checkbox elements
            const checkboxes = document.querySelectorAll('.manager-service-request-html-description-checkbox');
            let newdata = "";
            Array.from(checkboxes)
                .map((checkbox) => {
                    newdata = checkbox.innerHTML
                })

            let data = { newHtmlDesc: newdata }
            await patchManagerServiceRequestHtmlDescCheckBox(params.serviceId, data).then((res) => {
                toast.success("Service Request Description Updated");
                pageLoad();
            }).catch((err) => { toast.error(err.response.data.error.message) });
        }
    }

    const assigneeUpdate = (e, name, id) => {
        let data = {
            assignee: id,
            type: name,
        }
        patchManagerServiceRequestAssignee(params.serviceId, data).then((res) => {
            toast.success("Assignee Updated");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const UpdatePriorityClick = (prior) => {
        let data = {
            priority: prior,
        }
        patchManagerServiceRequest(params.serviceId, data).then((res) => {
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const dateUpdateClick = (date) => {
        let data = {
            nextFollowUpDate: date,
        }
        patchManagerServiceRequest(params.serviceId, data).then((res) => {
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const tagUpdateClick = (tag) => {
        let data = {
            tags: tag,
        }
        patchManagerServiceRequest(params.serviceId, data).then((res) => {
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSkillsRequired(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        tagUpdateClick(value);
    };

    const managerNoteClick = () => {
        let data = {
            managerNotes: managerNotes,
        }
        patchManagerServiceRequest(params.serviceId, data).then((res) => {
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const reopenCloseClick = (e, stat) => {
        let data = {
            state: stat,
        }
        patchManagerServiceRequestState(params.serviceId, data).then((res) => {
            if (res.data.data.state === 1) {
                toast.success("Service Request Closed");
                pageLoad();
            }
            if (res.data.data.state === 0) {
                toast.success("Service Request Opened");
                pageLoad();
            }
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const addAttachment = (e) => {
        let formData = new FormData();
        formData.append("attachment", e.target.files[0]);
        postManagerServiceRequestAttachments(params.serviceId, formData)
            .then((res) => {
                toast.info("Image Added");
                pageLoad();
            }).catch((err) => { toast.error(err.response.data.error.message) });

    }

    const removeImage = (id) => {
        deleteManagerServiceRequestAttachments(params.serviceId, id).then((res) => {
            toast.info("Image Removed");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const renderImages = () => {
        return attachFiles.map((item, index) => {
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={item.files} loading="lazy" alt="images" style={{ height: "110px", width: "175px", marginTop: "20px", cursor: "pointer" }} onClick={(e) => imageClick(e, item.url)} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={() => { removeImage(item.id) }}>
                            <DeleteIcon color="error" />
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const postClick = () => {
        let formData = new FormData();
        formData.append("content", content);
        Array.from(images).forEach(item => { formData.append("attachments", item) });
        postManagerServiceRequestComments(params.serviceId, formData).then((res) => {
            toast.success("Added Comment Successfully");
            pageLoad();
            setContent("");
            setImages([]);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const deleteClick = (e, id) => {
        deleteManagerServiceRequestComments(params.serviceId, id).then((res) => {
            pageLoad();
            toast.info("Comment Deleted");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleCommentContentChange = (e, index, comment) => {
        const newData = [...comment];
        newData[index].content = e.target.value;
        setComment(newData);
    }

    const commentSaveClick = (e, id, cat, content) => {
        let data = {
            content: content,
        }
        patchManagerServiceRequestComments(params.serviceId, id, data).then((res) => {
            setcommentCondition(prevcommentCondition => ({ ...prevcommentCondition, [cat]: !prevcommentCondition[cat] }));
            toast.info("Updated Comment Successfully");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleClick = (e, id) => {
        e.preventDefault();
        setFileId(id);
        hiddenFileInput.current.click();
    };

    const addCommentAttachment = (e) => {
        let formData = new FormData();
        formData.append("attachment", e.target.files[0]);
        postManagerServiceRequestCommentsAttachment(params.serviceId, fileId, formData).then((res) => {
            toast.info("Comment Attachment Added");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const removeCommentImage = (e, id, idd) => {
        deleteManagerServiceRequestCommentsAttachment(params.serviceId, id, idd).then((res) => {
            toast.info("Comment Attachment Removed");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const attachImages = () => {
        return Array.from(images).map((item, index) => {
            const url = URL.createObjectURL(item)
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={url} alt="images" style={{ height: "110px", width: "175px", marginTop: "20px" }} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={() => { removeAttachImage(index) }}>
                            <DeleteIcon color="error" />
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const handleImagesAdd = (e) => {
        setImages([...images, ...e.target.files]);
    }

    const removeAttachImage = (index) => {
        let toDelete;
        const newImages = images.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });
        setImages(newImages);
    };

    const copyPhone = (phone) => {
        copy(phone);
        toast.success("Phone Number is copied to Clipboard")
    }

    const copyEmail = (email) => {
        copy(email);
        toast.success("Email is copied to Clipboard")
    }

    const addContractorClick = () => {
        getContractorTags().then((res) => {
            setContractorTages(res.data.data);
        })
        setAddContractorDialog(true);
        setContractFirstName("")
        setContractLastName("");
        setContractEmail("");
        setContractPhoneNo("");
        setContractCity("");
        setContractState("");
        setContractSkills([]);
        setContractDesc("");
    }

    const AppointContractorClick = () => {
        getContractor().then((res) => {
            let responseData = res?.data?.data;
            setContractor(responseData);
        })
        setContractorViewModel(true)
    }
    const appointClick = (contId) => {
        let data = { "contractorId": contId, }
        appointContractor(params.serviceId, data).then((res) => {
            toast.success("Contractor Appointed");
            setContractorViewModel(false);
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const unAppointClick = () => {
        unAppointContractor(params.serviceId).then((res) => {
            toast.success("Contractor Unappointed");
            pageLoad();
            setContractorCondition(false);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const getLinkClick = (e, id) => {
        navigator.clipboard.writeText(
            `${window.location.origin}/contractor/${id}/service-requests/${params.serviceId}`)
        toast.info("Copied Lease Application Link to Clipboard")
    }

    // Phone Format
    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setContractPhoneNo(formattedPhoneNumber);
    };

    const onChangeHandler = (e) => {
        const contractPhoneNo = e.target.value
        setContractPhoneNo(contractPhoneNo)
    };

    const StarClick = (id, rate) => {
        let data = {
            "starRating": rate
        }
        postContractorStarRating(id, params.serviceId, data).then((res) => {

        })
    }

    const [addFinanceExpenseDetailsDialog, setAddFinanceExpenseDetailsDialog] = useState(false);
    const [addFinanceExpenseDetailsCheckbox, setAddFinanceExpenseDetailsCheckbox] = useState("");
    const handleOpenAddFinanceExpenseDetailsDialog = () => {
        setAddFinanceExpenseDetailsDialog(true);
    }
    const handleCloseAddFinanceExpenseDetailsDialog = () => {
        setAddFinanceExpenseDetailsDialog(false);
        setAddFinanceExpenseDetailsCheckbox("");
        setEspenseType("");
    }

    const handleCheckBoxChange = (e) => {
        if (addFinanceExpenseDetailsCheckbox == "") {
            setAddFinanceExpenseDetailsCheckbox(e.target.value);
        } else {
            setAddFinanceExpenseDetailsCheckbox("");
        }
        if (e.target.value == "Travel") {
            setFinanceEntryDate(null);
            setTravelEntryDate(null);
            setTotalLabourCharge("0");
            setTotalLabourHoursWorked(0);
            setManagerDistanceTravelled(0);
            setTotalMaterialsCost("0");
            setEspenseType("TRAVEL_MILES");
        }
        if (e.target.value == "Labor") {
            setFinanceEntryDate(null);
            setTotalLabourCharge("0");
            setTotalLabourHoursWorked(0);
            setEspenseType("REPAIRS_EXPENSE");
        }
        if (e.target.value == "Materials") {
            setFinanceEntryDate(null);
            setTotalMaterialsCost("0");
            setEspenseType("SUPPLIES_EXPENSE");
        }
    }

    const getFinanceTotal = (data, key) => {
        return data.reduce((total, item) => total + item[key], 0);
    };

    const saveFinanceAllClick = () => {
        let date = financeEntryDate == null ? new Date() : financeEntryDate;
        let data = {
            financeEntryDate: date,
            financeEntryTransactionType: espenseType,
            managerTravelledDate: travelEntryDate,
            managerTravelledDistance: managerDistanceTravelled,
            totalLaborCost: Number(totalLabourCharge),
            totalLaborHours: totalLabourHoursWorked,
            totalMaterialsCost: Number(totalMaterialsCost),
        };
        let errorMessage = "";
        switch (addFinanceExpenseDetailsCheckbox) {
            case "Labor":
                if (financeEntryDate === null || Number(totalLabourCharge) === 0 || totalLabourHoursWorked === 0) {
                    errorMessage = "The fields are blank. They are required!";
                }
                break;
            case "Materials":
                if (financeEntryDate === null || Number(totalMaterialsCost) === 0) {
                    errorMessage = "The fields are blank. They are required!";
                }
                break;
            case "Travel":
                if (managerDistanceTravelled === 0 || travelEntryDate === null) {
                    errorMessage = "The fields are blank. They are required!";
                }
                break;
            default:
                errorMessage = "Invalid expense type.";
        }
        if (errorMessage) {
            toast.error(errorMessage);
        } else {
            postManagerServiceRequestFinanceAll(params.serviceId, data)
                .then((res) => {
                    toast.success("Service Request finance saved successfully.");
                    window.location.reload();
                    handleCloseAddFinanceExpenseDetailsDialog();
                })
                .catch((err) => {
                    toast.error(err.response.data.error.message);
                });
        }
    };

    const [financeId, setFinanceId] = useState("");
    const [deleteFinanceDialog, setDeleteFinanceDialog] = useState(false);
    const [transId, setTransId] = useState(null);
    const [attachId, setAttachId] = useState(null);

    const FinancedeletedDialogOpen = (id, tranId, materialAttach, uniqueFinId) => {
        setFinanceId(id);
        setTransId(tranId);
        materialAttach.forEach((attach) => {
            if (attach.uniqueFinanceId == uniqueFinId) {
                setAttachId(attach.id);
            }
        })
        setDeleteFinanceDialog(true);
    }

    const FinancedeletedDialogClose = () => {
        setFinanceId("");
        setTransId(null);
        setAttachId(null);
        setDeleteFinanceDialog(false);
    }

    const deleteFinanceAllById = (id) => {
        deleteManagerServiceRequestFinanceAll(params.serviceId, id, transId, attachId).then((res) => {
            toast.success("Service Request finance deleted successfully.");
            pageLoad();
            FinancedeletedDialogClose();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const [updateFinanceExpenseDetailsDialog, setupdateFinanceExpenseDetailsDialog] = useState(false);
    const [uniqueFinId, setUniqueFinId] = useState(null);
    const [materialId, setMaterialId] = useState("");

    const handleOpenupdateFinanceExpenseDetailsDialog = (finance, serviceRequest) => {
        setFinanceId(finance?.id);
        setFinanceEntryDate(finance?.financeEntryDate);
        setEspenseType(finance?.financeEntryTransactionType);
        setTravelEntryDate(finance?.managerTravelledDate);
        setManagerDistanceTravelled(finance?.managerTravelledDistance);
        setTotalLabourCharge(finance?.totalLaborCost);
        setTotalLabourHoursWorked(finance?.totalLaborHours);
        setTotalMaterialsCost(finance?.totalMaterialsCost);
        setUniqueFinId(finance?.uniqueFinanceId);
        setTransId(finance?.transactionId);
        serviceRequest.forEach((attach) => {
            if (finance.uniqueFinanceId == attach.uniqueFinanceId) {
                setMaterialId(attach?.id);
            }
        })
        setupdateFinanceExpenseDetailsDialog(true);
    }

    const handleCloseupdateFinanceExpenseDetailsDialog = () => {
        setFinanceId("");
        setFinanceEntryDate(null);
        setEspenseType(expense);
        setTravelEntryDate(null);
        setManagerDistanceTravelled(0);
        setTotalLabourCharge(0);
        setTotalLabourHoursWorked(0);
        setTotalMaterialsCost("0");
        setUniqueFinId(null);
        setTransId(null);
        setMaterialId("");
        setupdateFinanceExpenseDetailsDialog(false);
    }

    const updateFinanceAllClick = (id) => {
        let data = {
            financeEntryDate: financeEntryDate,
            financeEntryTransactionType: espenseType,
            managerTravelledDate: travelEntryDate,
            managerTravelledDistance: managerDistanceTravelled,
            totalLaborCost: Number(totalLabourCharge),
            totalLaborHours: totalLabourHoursWorked,
            totalMaterialsCost: Number(totalMaterialsCost),
            uniqueFinanceId: uniqueFinId,
        }
        patchManagerServiceRequestFinanceAll(params.serviceId, id, transId, data).then((res) => {
            toast.success(" Service Request finance updated successfully");
            window.location.reload();
            handleCloseupdateFinanceExpenseDetailsDialog();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleFinanceAllSelect = (e) => {
        let formData = new FormData();
        formData.append("finance-attachments", e.target.files[0]);
        postManagerServiceRequestMaterialsAttachments(params.serviceId, formData).then((res) => {
            pageLoad();
            toast.info("Image Added");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const removeImageFinanceAll = (id) => {
        deleteManagerServiceRequestMaterialsAttachments(params.serviceId, id).then((res) => {
            pageLoad();
            toast.info("Image Removed");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleFinanceAllMaterialImage = (e) => {
        let formData = new FormData();
        formData.append("finance-attachments", e.target.files[0]);
        patchManagerServiceRequestMaterialsAttachments(params.serviceId, materialId, uniqueFinId, formData).then((res) => {
            pageLoad();
            toast.info("Image Updated");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    // Contractor Finance History Add

    const [contractorRecieptDialog, setContractorRecieptDialog] = useState(false);

    const handleContractorFinanceAttachmentChange = (e) => {
        setContractorFinanceAttachment(e.target.files[0]);
    }

    const contractorFinanceAddedImageClick = (e, file) => {
        e.preventDefault();
        let url = URL.createObjectURL(file);
        let newTab = window.open();
        newTab?.document.write(
            `<!DOCTYPE html><head><title>preview</title></head><body style="background: #0e0e0e; height:'100vh';"><div style="display:flex;justify-content:center;align-item:center"><img align="center "height='600rem' src="${url}"  ><div></body></html>`
        );
        newTab?.document.close();
    }

    const contractorFinanceSaveClick = () => {
        if (!Number(contractorTotalLabourCharge) === 0 || !Number(contractorTotalMaterialCost) === 0 || !contractorTotalLabourHours) {
            toast.error("The fields is blank. It should be required !");
        } else if (!contractorFinanceAttachment) {
            setContractorRecieptDialog(true);
        } else {
            const currentDate = new Date();
            let formData = new FormData();
            formData.append("financeEntryDate", currentDate);
            formData.append("financeEntryTransactionType", "REPAIRS_EXPENSE")
            formData.append("contractorTotalLabourCharge", Number(contractorTotalLabourCharge));
            formData.append("contractorTotalMaterialCost", Number(contractorTotalMaterialCost));
            formData.append("contractorTotalLabourHours", contractorTotalLabourHours);
            formData.append("contractor-finance-attachment", contractorFinanceAttachment);

            postManagerServiceRequestContractorFinance(params.serviceId, formData, `?status=${"totalMaterialCost"}`).then((res) => {
                toast.success("Contractor finance saved successfully.");
                pageLoad();
                removecontractorFinaceAddAtachment();
                setContractorTotalMaterialCost("0");
            }).catch((err) => { toast.error(err.response.data.error.message) });
        }
    }

    const removecontractorFinaceAddAtachment = () => {
        setContractorFinanceAttachment(null);
    }

    // Contractor Finance History Update

    const [contractorFinanceDeleteDialog, setContractorFinanceDeleteDialog] = useState(false);
    const [contractorFinanceDeleteFileDialog, setContractorFinanceDeleteFileDialog] = useState(false);
    const [contractorFinanceId, setContractorFinanceId] = useState("");
    const [contractorFinanceAttachId, setContractorFinanceAttachId] = useState("");
    const [contractorFinanceEditIndex, setContractorFinanceEditIndex] = useState(null);

    const contractorFinanceEditIconClick = (index) => {
        setContractorFinanceEditIndex(index);
    }

    const handleContractorFinanceAttachmentEditChange = (e, financeId) => {
        let formData = new FormData();
        formData.append("contractor-finance-attachment", e.target.files[0]);
        patchManagerServiceRequestContractorFinance(params.serviceId, financeId, formData).then((res) => {
            toast.success("Image Updated.");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleEditTotalMaterialsCostChange = (e, index, serviceRequest) => {
        const newData = { ...serviceRequest };
        newData.contractorFinance[index].totalMaterialsCost = e.target.value;
        setServiceRequestById(newData);
    }

    const contractorFinanceUpdateClick = (contractorFinance) => {
        if (!contractorFinance?.contractorAttachment) {
            setContractorRecieptDialog(true);
        } else {
            const currentDate = new Date();
            let formData = new FormData();
            formData.append("financeEntryDate", currentDate);
            formData.append("financeEntryTransactionType", "REPAIRS_EXPENSE")
            formData.append("contractorTotalLabourCharge", Number(contractorTotalLabourCharge));
            formData.append("contractorTotalMaterialCost", Number(contractorFinance?.totalMaterialsCost));
            formData.append("contractorTotalLabourHours", contractorTotalLabourHours);
            patchManagerServiceRequestContractorFinance(params.serviceId, contractorFinance?.id, formData).then((res) => {
                setContractorFinanceEditIndex(null);
                toast.success("Contractor finance updated successfully.");
                pageLoad();
            }).catch((err) => { toast.error(err.response.data.error.message) });
        }
    }

    const contractorFinanceDeleteFileDialogOpen = (contractorFinance) => {
        setContractorFinanceId(contractorFinance?.id);
        setContractorFinanceAttachId(contractorFinance?.contractorAttachment?.id);
        setContractorFinanceDeleteFileDialog(true);
    }

    const contractorFinanceDeleteFileDialogClose = () => {
        setContractorFinanceId("");
        setContractorFinanceAttachId("");
        setContractorFinanceDeleteFileDialog(false);
    }

    const removecontractorFinaceUpdateAtachment = (contracFinId, contracAttachId) => {
        deleteManagerServiceRequestContractorFinanceAttachment(params.serviceId, contracFinId, contracAttachId).then((res) => {
            contractorFinanceDeleteFileDialogClose()
            toast.success("Image Removed.");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const contractorFinanceDeleteDialogOpen = (contractorFinance) => {
        setContractorFinanceId(contractorFinance?.id);
        setContractorFinanceDeleteDialog(true);
    }

    const contractorFinanceDeleteDialogClose = () => {
        setContractorFinanceId("");
        setContractorFinanceDeleteDialog(false);
    }

    const deleteContractFinance = (contractorFinId) => {
        deleteManagerServiceRequestContractorFinance(params.serviceId, contractorFinId).then((res) => {
            contractorFinanceDeleteDialogClose();
            toast.success("Contractor finance deleted successfully.");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const contractorTotalLabourChargeUpdateClick = () => {
        let formData = new FormData();
        formData.append("contractorTotalLabourCharge", Number(contractorTotalLabourCharge));
        postManagerServiceRequestContractorFinance(params.serviceId, formData, `?status=${"totalLabourCharge"}`).then((res) => {
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const contractorTotalLabourHoursUpdateClick = () => {
        let formData = new FormData();
        formData.append("contractorTotalLabourHours", contractorTotalLabourHours);
        postManagerServiceRequestContractorFinance(params.serviceId, formData, `?status=${"totalLabourHours"}`).then((res) => {
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const navigateToMoveInMoveOut = (move) => {
        if (move?.type === "MoveIn") {
            navigate(`/manager/leases/${move?.leaseId}/move-in-inspection`);
        }
        if (move?.type === "MoveOut") {
            navigate(`/manager/leases/${move?.leaseId}/move-out-inspection`)
        }
    }

    const TruncateText = (text) => {
        const truncatedText = text.length > 10 ? `${text.slice(0, 10)}...` : text;
        return <span title={text}>{truncatedText}</span>;
    };

    const getcontractorFinanceLabourRate = (labourCharge, labourHours) => {
        let cost = 0;
        if (labourCharge || labourHours) cost = Number(labourCharge) / Number(labourHours);
        return "Labor Rate: " + "$" + cost.toFixed(2) + " " + "/ hours";
    }

    const getContractorFinanceTotalCost = (contractorFinance, contractorTotalMaterialCost) => {
        let cost = 0;
        let materialCost = 0;
        contractorFinance.forEach((fin) => {
            cost += Number(fin?.totalMaterialsCost);
        })
        materialCost = cost + Number(contractorTotalMaterialCost);
        return "Total Cost for Contractor Service Request:" + " " + materialCost.toFixed(2);
    }

    const activityCreatedBy = (service, assi) => {
        let name = ""
        assi && assi.forEach((e) => {
            if (service?.creator?.creator == e?.id && service?.creator?.type == e?.type) {
                name = `Created by` + ` ` + e?.name + ` ` + `on` + ` ` + moment(service?.createdAt).format('MMM D, YYYY, h:mm:ss A');
            }
        })
        return name;
    }

    const commentBoxName = (service, assi) => {
        let name = ""
        assi && assi.forEach((e) => {
            if (service?.creator?.creator == e?.id && service?.creator?.type == e?.type) {
                name = e.name;
            }
        })
        return name;
    }

    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "30px" }}>
            <p style={{ fontSize: 25, fontWeight: "bold", textAlign: "center" }}>Service Request #{serviceRequestById?.numberId}</p>
            <p style={{ fontSize: 17, fontWeight: "bold", textAlign: "center" }}>{propertyById?.address?.line1}{", "}{propertyById?.address?.line1} {propertyById?.address?.unitNumber}</p>
            <TabMenu serviceRequestId={params.serviceId} />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: 'wrap', gap: 5, marginTop: '30px' }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: 'wrap', gap: 5 }}>
                        <div>
                            <h3 style={{ fontSize: "1.17rem", fontWeight: "bold", marginRight: "8px" }}>{"#" + " " + serviceRequestById?.numberId}</h3>
                        </div>
                        <div>
                            <h3 style={{ fontSize: "1.17rem", fontWeight: "bold", marginRight: "15px" }}>
                                {serviceRequestById?.priority === 0 ? <ReportProblemIcon style={{ color: "#F4C94C" }} /> : ""}
                                {serviceRequestById?.priority === 1 ? <ReportProblemIcon style={{ color: "#F4C94C" }} /> : ""}
                                {serviceRequestById?.priority === 2 ? <ErrorIcon style={{ color: "#DC3545" }} /> : ""}
                            </h3>
                        </div>
                        <div>
                            {name === false ? (
                                <h3 style={{ fontSize: "1.17rem", fontWeight: "bold", marginRight: "20px" }}>{serviceRequestById?.title}</h3>) : (
                                <div style={{ display: "flex" }}>
                                    <TextField id="outlined-basic" variant="outlined"
                                        sx={{ width: "350px" }}
                                        value={title}
                                        onChange={e => { setTitle(e.target.value) }} />
                                    <Button color="success"
                                        type="submit"
                                        sx={{ fontSize: 15 }}
                                        onClick={UpdateTitleClick}
                                    >SAVE</Button>
                                    <Button color="error"
                                        type="submit"
                                        sx={{ fontSize: 15 }}
                                        onClick={() => setName(false)}
                                    >CANCEL</Button>
                                </div>
                            )}
                        </div>
                        {name === false ?
                            <div>
                                <EditIcon style={{ color: "#2196F3", cursor: 'pointer' }} onClick={() => setName(true)} />
                            </div> : ""}
                        {serviceRequestById && serviceRequestById?.lease == null ?
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, flexWrap: 'wrap', alignItems: 'center', color: "#6c757d" }}>
                                <span>
                                    <AdminPanelSettingsIcon sx={{ color: "#6c757d" }} />
                                </span>
                                <span>
                                    Private
                                </span>
                            </div>
                            : ""}
                    </div>
                </div>

                <div>
                    {serviceRequestById?.state === 1 ?
                        <Button color="success"
                            type="submit"
                            style={{ fontSize: 15 }}
                            onClick={(e) => reopenCloseClick(e, 0)}
                        >REOPEN</Button> : ""}
                    {serviceRequestById?.state === 0 ?
                        <Button color="success"
                            type="submit"
                            style={{ fontSize: 15 }}
                            onClick={(e) => reopenCloseClick(e, 1)}
                        >CLOSE</Button> : ""}
                </div>
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <CardContent style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "0px", paddingLeft: '0px' }}>
                            <div>
                                <h3 style={{ fontSize: "1.17rem", fontWeight: "bold", marginRight: "8px" }}>Description</h3>
                            </div>
                            <div>
                                <span style={{ fontSize: "1em", color: "#6c757d" }}>
                                    <small style={{ fontSize: "1em" }}>Created by </small>
                                    {assignee && assignee ? assignee.map((details, index) => details.type === "Manager" ? (
                                        <small style={{ fontSize: "1em" }} key={index}>
                                            <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                        </small>) : "") : ""}
                                    <small style={{ fontSize: "1em" }}>on {serviceRequestById?.createdAt ? moment(serviceRequestById?.createdAt).format('MMM D, YYYY, h:mm:ss A') : ""}</small>
                                </span>
                            </div>
                            {desc === false ?
                                <div>
                                    <EditIcon style={{ color: "#2196F3", cursor: 'pointer' }} onClick={() => setDesc(true)} />
                                </div> : ""}
                        </CardContent>
                        <CardContent sx={{ pl: 0 }}>
                            {desc === false ? (
                                <div className="manager-service-request-html-description-checkbox" style={{ fontSize: "1.17rem", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: serviceRequestById?.newHtmlDesc }} onClick={(e) => { descriptionCheckbox(e) }} />
                            ) : (
                                <div>
                                    <TextField
                                        id="outlined-basic"
                                        label="Notes"
                                        variant="outlined"
                                        fullWidth={true}
                                        rows={5}
                                        multiline
                                        placeholder="Description"
                                        value={description}
                                        onChange={e => { setDescription(e.target.value) }} />
                                    <div style={{ display: "flex", justifyContent: "right" }}>
                                        <Button color="success"
                                            type="submit"
                                            sx={{ fontSize: 15 }}
                                            onClick={UpdateDescriptionClick}
                                        >SAVE</Button>
                                        <Button color="error"
                                            type="submit"
                                            sx={{ fontSize: 15 }}
                                            onClick={() => setDesc(false)}
                                        >CANCEL</Button>
                                    </div>
                                </div>)}
                        </CardContent>
                        <CardContent>
                            <div className="img-container">
                                {renderImages()}
                                {attachFiles?.length < 5 ?
                                    <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
                                        <input type="file" id="uploadBtn" accept="image/*"
                                            onChange={(e) => addAttachment(e)}
                                        />
                                        <label htmlFor="uploadBtn" style={{ fontSize: 13 }}>
                                            Drop images / click to Upload
                                        </label>
                                    </div> : ""}
                            </div>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CardContent sx={{ pb: 0 }}>
                            <FormControl sx={{ width: 1 }}>
                                <TextField
                                    sx={{ width: 1 }}
                                    label="Choose Assignee"
                                    select
                                    margin="dense"
                                    variant="outlined"
                                    value={chooseAssi}
                                    onChange={e => { setChooseAssi(e.target.value) }}
                                >
                                    {assignee && assignee ? assignee.map((details, index) => details.available === true ? (
                                        <MenuItem key={index} value={details.name} onClick={(e) => assigneeUpdate(e, details.type, details.id)}>
                                            {details.name}
                                        </MenuItem>
                                    ) : "") : ""}
                                </TextField>
                            </FormControl>
                        </CardContent>
                        <CardContent sx={{ pb: 0 }}>
                            <FormControl sx={{ width: 1 }}>
                                <TextField
                                    sx={{ width: 1 }}
                                    label="Choose Priority level"
                                    select
                                    margin="dense"
                                    variant="outlined"
                                    value={prior}
                                    onChange={(e) => {
                                        setPrior(parseInt(e.target.value));
                                        UpdatePriorityClick(parseInt(e.target.value));
                                    }}
                                >
                                    {priority.map((pri) => (
                                        <MenuItem key={pri.value} value={pri.value}>
                                            {pri.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </CardContent>
                        <CardContent sx={{ pb: 0 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day"]}
                                    label="Next Follow Up Date"
                                    value={nextFollowupDate}
                                    onChange={(newValue) => {
                                        setNextFollowupDate(newValue);
                                        dateUpdateClick(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth={true} />} />

                            </LocalizationProvider>
                        </CardContent>
                        <CardContent>
                            <FormControl sx={{ width: 1 }}>
                                <InputLabel id="demo-multiple-chip-label">Skills Required</InputLabel>
                                <Select
                                    sx={{ width: 1 }}
                                    variant="standard"
                                    label="Skills Required"
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    MenuProps={MenuProps}
                                    value={skillsRequired}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {suggestedTags && suggestedTags ? suggestedTags.map((e, i) => (
                                        <MenuItem key={i} value={e}>
                                            {e}
                                        </MenuItem>
                                    )) : undefined}
                                </Select>
                            </FormControl>
                            <p style={{ fontSize: 13, color: "grey" }}>Click Enter or add " , " after entering tag</p>
                        </CardContent>
                    </Grid>
                </Grid>
            </Box>
            <CardContent>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p style={{ fontSize: 16, fontWeight: "bold" }}>Finance</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Typography
                                variant="h5"
                                gutterBottom
                                textAlign="center"
                            >
                                Service Request Finance History
                            </Typography>
                        </div>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='tableCell-maxWidth'>#</TableCell>
                                        <TableCell className='tableCell-maxWidth'>#Date</TableCell>
                                        <TableCell className='tableCell-maxWidth'>Labor Hours Worked</TableCell>
                                        <TableCell className='tableCell-maxWidth'>Labor Charge</TableCell>
                                        <TableCell className='tableCell-maxWidth'>Expense Type</TableCell>
                                        <TableCell className='tableCell-maxWidth'>Materials Cost</TableCell>
                                        <TableCell className='tableCell-maxWidth'>Manager Distance Travelled</TableCell>
                                        <TableCell className='tableCell-maxWidth'>Materials Cost Receipts</TableCell>
                                        <TableCell className='tableCell-maxWidth'>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {financeAll.slice(financeAllPage * financeAllrowsPerPage, financeAllPage * financeAllrowsPerPage + financeAllrowsPerPage).map((finance, index) => (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            key={index}
                                        >
                                            <TableCell className='tableCell-maxWidth' component="th" scope="row">{finance?.uniqueFinanceId}</TableCell>
                                            <TableCell className='tableCell-maxWidth'>{finance.financeEntryDate ? moment(finance.financeEntryDate).format('MMM D, YYYY') : ""}</TableCell>
                                            <TableCell className='tableCell-maxWidth'>{finance.totalLaborHours != 0 ? finance.totalLaborHours : ""}</TableCell>
                                            <TableCell className='tableCell-maxWidth'>{finance.totalLaborCost != 0 ? `$` + finance.totalLaborCost.toFixed(2) : ""}</TableCell>
                                            <TableCell className='tableCell-maxWidth'>{FinanceCategoriesUppercase(finance.financeEntryTransactionType)}</TableCell>
                                            <TableCell className='tableCell-maxWidth'>{finance.totalMaterialsCost != 0 ? `$` + finance.totalMaterialsCost.toFixed(2) : ""}</TableCell>
                                            <TableCell className='tableCell-maxWidth'>{finance.managerTravelledDistance != 0 ? finance.managerTravelledDistance : ""}</TableCell>
                                            <TableCell className='tableCell-maxWidth'>
                                                {serviceRequestById && serviceRequestById.materialCostReceipts.map((attach, index) => (
                                                    <span key={index}>
                                                        {finance.uniqueFinanceId == attach.uniqueFinanceId ?
                                                            <RequestQuoteIcon sx={{ color: 'rgb(0, 191, 255)', cursor: 'pointer' }} onClick={(e) => { imageClick(e, attach?.url) }} />
                                                            : ""}
                                                    </span>
                                                ))}
                                            </TableCell>
                                            <TableCell className='tableCell-maxWidth'>
                                                <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                                                    <span>
                                                        <EditIcon color="primary" onClick={() => { handleOpenupdateFinanceExpenseDetailsDialog(finance, serviceRequestById && serviceRequestById.materialCostReceipts) }} />
                                                    </span>
                                                    <span>
                                                        <DeleteIcon color="error" onClick={() => { FinancedeletedDialogOpen(finance.id, finance.transactionId, serviceRequestById && serviceRequestById.materialCostReceipts, finance.uniqueFinanceId) }} />
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell className='tableCell-maxWidth' component="th" scope="row">
                                            <span style={{ fontWeight: 'bold' }}>
                                                Total
                                            </span>
                                        </TableCell>
                                        <TableCell className='tableCell-maxWidth'></TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            <span style={{ fontWeight: 'bold' }}>
                                                {getFinanceTotal(financeAll, 'totalLaborHours')} hours
                                            </span>
                                        </TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            <span style={{ fontWeight: 'bold' }}>
                                                $ {(getFinanceTotal(financeAll, 'totalLaborCost')).toFixed(2)}
                                            </span>
                                        </TableCell>
                                        <TableCell className='tableCell-maxWidth'></TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            <span style={{ fontWeight: 'bold' }}>
                                                $ {(getFinanceTotal(financeAll, 'totalMaterialsCost')).toFixed(2)}
                                            </span>
                                        </TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            <span style={{ fontWeight: 'bold' }}>
                                                {getFinanceTotal(financeAll, 'managerTravelledDistance')} miles
                                            </span>
                                        </TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            <span style={{ fontWeight: 'bold' }}>
                                                {serviceRequestById.materialCostReceipts.length} files
                                            </span>
                                        </TableCell>
                                        <TableCell className='tableCell-maxWidth'></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <TablePagination
                                    sx={{
                                        '.css-pdct74-MuiTablePagination-selectLabel': {
                                            marginBottom: "0px",
                                            color: "#4a90e2"
                                        },
                                        '.css-levciy-MuiTablePagination-displayedRows': {
                                            marginBottom: "0px",
                                            color: "#4a90e2"
                                        }
                                    }}
                                    component="div"
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    count={financeAll.length}
                                    rowsPerPage={financeAllrowsPerPage}
                                    page={financeAllPage}
                                    onPageChange={handleChangefinanceAllPage}
                                    onRowsPerPageChange={handleChangefinanceAllRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </div>
                        </TableContainer>
                        <div style={{ paddingTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                            <Typography
                                variant="h8"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Labor Rate: ${isNaN(getFinanceTotal(financeAll, 'totalLaborCost') / getFinanceTotal(financeAll, 'totalLaborHours')) ? 0 : (getFinanceTotal(financeAll, 'totalLaborCost') / getFinanceTotal(financeAll, 'totalLaborHours')).toFixed(2)}/hour
                            </Typography>
                            <Fab
                                size="large"
                                color="primary"
                                aria-label="add"
                                style={{ transform: 'scale(0.8)', backgroundColor: "rgb(11, 119, 11)", color: "white" }}
                                onClick={() => { handleOpenAddFinanceExpenseDetailsDialog() }}
                            >
                                <AddIcon sx={{ fontSize: 40 }} />
                            </Fab>
                        </div>
                        <div style={{ paddingTop: 30 }}>
                            <Typography
                                variant="h6"
                                textAlign="center"
                            >
                                Total Cost for Service Request: $ {(getFinanceTotal(financeAll, 'totalLaborCost') + getFinanceTotal(financeAll, 'totalMaterialsCost')).toFixed(2)}
                            </Typography>
                        </div>
                        <div style={{ paddingTop: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Checkbox color="success" />
                            <Typography
                                variant="h8"
                                sx={{ fontWeight: 'bold', color: '#DC3545' }}
                            >
                                Tenant Responsible for issue. Add charges to tenant account.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </CardContent>
            <CardContent>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p style={{ fontSize: 16, fontWeight: "bold" }}>Manager Only</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CardContent>
                            <TextField id="outlined-basic" label="Notes" variant="outlined"
                                onBlur={managerNoteClick}
                                fullWidth={true}
                                rows={5}
                                multiline
                                placeholder="Notes"
                                value={managerNotes}
                                onChange={(e) => {
                                    setManagerNotes(e.target.value);
                                }} />
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            </CardContent>
            <CardContent>
                <Accordion expanded={ContractorPanelExpanded} onChange={() => { setContractorPanelExpanded(!ContractorPanelExpanded) }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <span style={{ flex: 1 }}>
                            <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10, alignItems: 'center' }}>
                                <span style={{ fontSize: 16, fontWeight: "bold" }}>Contractor</span>
                                {!ContractorPanelExpanded ?
                                    <span>
                                        {contratorCondition === false ? (
                                            <span>
                                                <span> No Contractor Appointed </span>
                                            </span>
                                        ) : (
                                            <span>
                                                {appointedcontractor?.name?.first + " " + appointedcontractor?.name?.last}
                                            </span>
                                        )}
                                    </span> : ""}
                            </span>
                        </span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {contratorCondition === false ? (
                            <span>
                                <div style={{ textAlign: "center" }}> No Contractor Appointed </div>
                            </span>) : (
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginBottom: "10px" }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <p style={{ letterSpacing: 2, color: "grey" }}>Name:</p><p style={{ marginLeft: "20px" }}>{appointedcontractor?.name.first + " " + appointedcontractor?.name?.last}</p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "10px" }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <p style={{ letterSpacing: 2, color: "grey" }}>Email:</p><p style={{ marginLeft: "20px" }}>{appointedcontractor?.email?.id}</p>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "30px" }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <p style={{ letterSpacing: 2, color: "grey" }}>Phone:</p><p style={{ marginLeft: "20px" }}>{appointedcontractor?.phone?.code + " " + appointedcontractor?.phone?.number}</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ marginRight: "30px" }}>
                                        <div style={{ textAlign: "center" }}>
                                            <p style={{ fontSize: 11, fontWeight: "bolder", marginBottom: "10px" }}>Rate Contractor</p>
                                        </div>
                                        <Rating
                                            name="simple-controlled"
                                            value={rating}
                                            onChange={(event, newValue) => {
                                                if (newValue === 1) {
                                                    setRating(0.2);
                                                    StarClick(appointedcontractor?._id, 0.2);
                                                }
                                                if (newValue === 2) {
                                                    setRating(0.4);
                                                    StarClick(appointedcontractor?._id, 0.4);
                                                }
                                                if (newValue === 3) {
                                                    setRating(0.6);
                                                    StarClick(appointedcontractor?._id, 0.6);
                                                }
                                                if (newValue === 4) {
                                                    setRating(0.8);
                                                    StarClick(appointedcontractor?._id, 0.8);
                                                }
                                                if (newValue === 5) {
                                                    setRating(1);
                                                    StarClick(appointedcontractor?._id, 1);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            {contratorCondition === false ?
                                <div></div> : ""}
                            {contratorCondition === true ?
                                <div>
                                    {assignee && assignee ? assignee.map((f, i) => (
                                        <div key={i}>
                                            {f.type === "Contractor" && f.available === true ?
                                                <Button color="primary"
                                                    type="submit"
                                                    style={{ border: " 1px solid #ddd", }}
                                                    onClick={(e) => getLinkClick(e, f.id)}
                                                >Get Link</Button> : ""}
                                        </div>)) : ""}
                                </div> : ""}
                            <div>
                                {contratorCondition === false ?
                                    <Button variant="contained" color="primary"
                                        type="submit"
                                        onClick={AppointContractorClick}
                                    >APPOINT CONTRACTOR</Button> :
                                    <Button variant="contained" color="error"
                                        type="submit"
                                        onClick={unAppointClick}
                                    >UNAPPOINT CONTRACTOR</Button>}
                            </div>
                        </div>
                        <div>
                            <Typography
                                variant="h5"
                                gutterBottom
                                textAlign="center"
                                sx={{ py: 5 }}
                            >
                                Service Request Contractor Finance History
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', boxSizing: 'border-box', flexFlow: 'row wrap', placeContent: 'flex-start space-between', alignItems: "flex-start" }}>
                            <div style={{ display: 'flex', flex: '1 1 48%', maxWidth: '48%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                <FormControl fullWidth sx={{ my: 1 }}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Total Labour Charge</InputLabel>
                                    <OutlinedInput
                                        onBlur={() => { contractorTotalLabourChargeUpdateClick() }}
                                        variant="outlined"
                                        value={contractorTotalLabourCharge}
                                        onChange={(e) => {
                                            const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                            setContractorTotalLabourCharge(numericValue);
                                        }}
                                        inputProps={{
                                            inputMode: 'numeric', // Restrict input to numeric characters
                                            pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                        }}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Total Labor Charge"
                                    />
                                </FormControl>
                            </div>
                            <div style={{ display: 'flex', flex: '1 1 48%', maxWidth: '48%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                <FormControl fullWidth sx={{ my: 1 }}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Total Labour Hours</InputLabel>
                                    <OutlinedInput
                                        onBlur={() => { contractorTotalLabourHoursUpdateClick() }}
                                        type="number"
                                        variant="outlined"
                                        value={contractorTotalLabourHours}
                                        onChange={(e) => {
                                            setContractorTotalLabourHours(parseInt(e.target.value));
                                        }}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Total Label Hours"
                                    />
                                </FormControl>
                            </div>
                        </div>
                        {serviceRequestById?.contractorFinance.map((contractorFin, index) => (
                            <div style={{ display: 'flex', boxSizing: 'border-box', flexFlow: 'row wrap', placeContent: 'flex-start space-between', alignItems: "flex-end" }} key={index}>
                                <div style={{ display: 'flex', flex: '1 1 40%', maxWidth: '40%', boxSizing: 'border-box' }}>
                                    <FormControl fullWidth sx={{ my: 1 }}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Total Materials Cost</InputLabel>
                                        <OutlinedInput
                                            disabled={contractorFinanceEditIndex === index ? false : true}
                                            variant="outlined"
                                            value={contractorFin?.totalMaterialsCost}
                                            onChange={(e) => handleEditTotalMaterialsCostChange(e, index, serviceRequestById)}
                                            inputProps={{
                                                inputMode: 'numeric', // Restrict input to numeric characters
                                                pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                            }}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            label="Total Material Cost"
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', flex: '1 1 40%', maxWidth: '40%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: '8px', marginBottom: '8px' }}>
                                        {contractorFinanceEditIndex === index ?
                                            <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                                <span>Replace Receipt</span>
                                                <span>
                                                    <input type="file" id="uploadBtnContractorFinanceUpdate" accept="image/*"
                                                        style={{ display: "none" }}
                                                        onChange={(e) => { handleContractorFinanceAttachmentEditChange(e, contractorFin?.id) }}
                                                    />
                                                    <label htmlFor="uploadBtnContractorFinanceUpdate" title="Upload Reciept">
                                                        <CloudUploadIcon color="primary" sx={{ cursor: "pointer" }} />
                                                    </label>
                                                </span>
                                            </span>
                                            : ""}
                                        <Card sx={{ border: '1px solid grey', padding: '10px', boxShadow: 'none' }}>
                                            <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                <span>
                                                    {contractorFin?.contractorAttachment ?
                                                        <span className="hover-blue-text" style={{ cursor: 'pointer' }} onClick={(e) => { imageClick(e, contractorFin?.contractorAttachment?.url) }}>
                                                            {TruncateText(contractorFin?.contractorAttachment?.fileName)}
                                                        </span>
                                                        :
                                                        <span>
                                                            No Receipts added.
                                                        </span>
                                                    }
                                                </span>
                                                {contractorFin?.contractorAttachment && contractorFinanceEditIndex === index ?
                                                    <span title="Delete Reciept">
                                                        <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { contractorFinanceDeleteFileDialogOpen(contractorFin) }} />
                                                    </span>
                                                    : ""}
                                            </span>
                                        </Card>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flex: '1 1 16%', maxWidth: '16%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <span>
                                            {contractorFinanceEditIndex == index ?
                                                <Button variant="contained" color="success"
                                                    type="submit"
                                                    sx={{ fontSize: 15, marginBottom: "8px" }}
                                                    onClick={() => { contractorFinanceUpdateClick(contractorFin) }}>
                                                    SAVE
                                                </Button>
                                                :
                                                <span title="Edit">
                                                    <EditIcon sx={{ cursor: 'pointer' }} color="primary" onClick={() => { contractorFinanceEditIconClick(index) }} />
                                                </span>
                                            }
                                        </span>
                                        {contractorFinanceEditIndex != index ?
                                            <span title="Delete">
                                                <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { contractorFinanceDeleteDialogOpen(contractorFin) }} />
                                            </span>
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        ))}
                        {contractorFinanceEditIndex == null ?
                            <div style={{ display: 'flex', boxSizing: 'border-box', flexFlow: 'row wrap', placeContent: 'flex-start space-between', alignItems: "flex-end" }}>
                                <div style={{ display: 'flex', flex: '1 1 40%', maxWidth: '40%', boxSizing: 'border-box' }}>
                                    <FormControl fullWidth sx={{ my: 1 }}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Total Materials Cost</InputLabel>
                                        <OutlinedInput
                                            variant="outlined"
                                            value={contractorTotalMaterialCost}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                                setContractorTotalMaterialCost(numericValue);
                                            }}
                                            inputProps={{
                                                inputMode: 'numeric', // Restrict input to numeric characters
                                                pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                            }}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            label="Total Material Cost"
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', flex: '1 1 40%', maxWidth: '40%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: '8px', marginBottom: '8px' }}>
                                        <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                            <span>Upload Receipt</span>
                                            <span>
                                                <input type="file" id="uploadBtnContractorFinanceAdd" accept="image/*"
                                                    style={{ display: "none" }}
                                                    onChange={handleContractorFinanceAttachmentChange}
                                                />
                                                <label htmlFor="uploadBtnContractorFinanceAdd" title="Upload Reciept">
                                                    <CloudUploadIcon color="primary" sx={{ cursor: "pointer" }} />
                                                </label>
                                            </span>
                                        </span>
                                        <Card sx={{ border: '1px solid grey', padding: '10px', boxShadow: 'none' }}>
                                            <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                <span>
                                                    {contractorFinanceAttachment ?
                                                        <span className="hover-blue-text" style={{ cursor: 'pointer' }} onClick={(e) => { contractorFinanceAddedImageClick(e, contractorFinanceAttachment) }}>
                                                            {contractorFinanceAttachment?.name}
                                                        </span>
                                                        :
                                                        <span>
                                                            No Receipts added.
                                                        </span>
                                                    }
                                                </span>
                                                {contractorFinanceAttachment ?
                                                    <span title="Delete Reciept">
                                                        <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { removecontractorFinaceAddAtachment() }} />
                                                    </span>
                                                    : ""}
                                            </span>
                                        </Card>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flex: '1 1 16%', maxWidth: '16%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                    <Button variant="contained" color="success"
                                        type="submit"
                                        sx={{ fontSize: 15, marginTop: "8px", marginBottom: '8px' }}
                                        onClick={contractorFinanceSaveClick}
                                    >SAVE</Button>
                                </div>
                            </div>
                            : ""}
                        <CardContent style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ paddingTop: 30 }}>
                                <h3 style={{ fontSize: "12px", fontWeight: "bolder" }}>{getcontractorFinanceLabourRate(contractorTotalLabourCharge, contractorTotalLabourHours)}</h3>
                            </div>
                            <div style={{ paddingTop: 30 }}>
                                <Typography variant="h6" textAlign="center">{getContractorFinanceTotalCost(serviceRequestById?.contractorFinance, contractorTotalMaterialCost)}</Typography>
                            </div>
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            </CardContent>
            <div style={{ marginTop: "10px" }}>
                <span style={{ fontSize: "1.17rem", fontWeight: "bold", marginRight: "8px" }}>Activity</span>
                <span style={{ color: '#6c757d' }}>{activityCreatedBy(serviceRequestById && serviceRequestById, assignee)}</span>
            </div>
            {serviceRequestById?.moveInMoveOutInfo?.isCreated ?
                <div style={{ marginTop: "10px" }}>
                    <Button variant="outlined" color="success" sx={{ fontSize: 15 }} onClick={() => { navigateToMoveInMoveOut(serviceRequestById?.moveInMoveOutInfo) }}>
                        View MoveIn/MoveOut Checklist
                    </Button>
                </div>
                : ""}
            <CardContent style={{ display: "flex", flexDirection: "column", boxSizing: "border-box" }}>
                <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box", width: "75%", marginLeft: "auto", marginRight: "auto" }}>
                    {Object.keys(commonMapview).map((cat, index) => (
                        <div key={index}>
                            {commonMapview[cat].map((f, t) => (
                                <div key={t}>
                                    {f.type !== 0 && f.type !== 8 && f.type !== 9 && f.type !== 7 ?
                                        <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
                                            {assignee && assignee ? assignee.map((details, i) => (
                                                <div key={i}>
                                                    {details.id === commonMapview[cat][t].details.openerId ?
                                                        <span style={{ fontSize: "1em" }}>
                                                            <small style={{ fontSize: "1em" }}>
                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                            </small>
                                                            <small style={{ fontSize: "1em" }}>reopened on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span> : ""}
                                                    {details.id === commonMapview[cat][t].details.closerId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            <small style={{ fontSize: "1em" }}>
                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                            </small>
                                                            <small style={{ fontSize: "1em" }}>closed on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {commonMapview[cat][t].details.newAssigneeId === null && commonMapview[cat][t].details.unAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.unAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>unassigned </small>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        {details.id === commonMapview[cat][t].details.changerId ?
                                                                            <strong style={{ fontSize: "1em" }}>{details.name} </strong> : ""}
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ("")}
                                                        </span> : ""}
                                                    {commonMapview[cat][t].details.unAssigneeId === null && commonMapview[cat][t].details.newAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>assigned to </small>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        {details.id === commonMapview[cat][t].details.changerId ? (
                                                                            <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ("")}
                                                        </span> : ""}
                                                    {details.id === commonMapview[cat][t].details.appointeeContractorId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            {assignee && assignee ? assignee.map((details, index) => (
                                                                <small style={{ fontSize: "1em" }} key={index}>
                                                                    {details.id === commonMapview[cat][t].details.changerId ? (
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                </small>)) : undefined}
                                                            <small style={{ fontSize: "1em" }}>appoined <strong>{details.name}</strong> as contractor on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {details.id === commonMapview[cat][t].details.unappointeeContractorId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            {assignee && assignee ? assignee.map((details, index) => (
                                                                <small style={{ fontSize: "1em" }} key={index}>
                                                                    {details.id === commonMapview[cat][t].details.changerId ? (
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                </small>)) : undefined}
                                                            <small style={{ fontSize: "1em" }}>unappoined <strong>{details.name}</strong> as contractor on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {commonMapview[cat][t].details.unAssigneeId !== null && commonMapview[cat][t].details.newAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    {assignee && assignee ? assignee.map((details, index) => (
                                                                        <small style={{ fontSize: "1em" }} key={index}>
                                                                            {details.id === commonMapview[cat][t].details.changerId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>assigned to </small>
                                                                    {assignee && assignee ? assignee.map((details, index) => (
                                                                        <small style={{ fontSize: "1em" }} key={index}>
                                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>and unassigned </small>
                                                                    {assignee && assignee ? assignee.map((details, index) => (
                                                                        <small style={{ fontSize: "1em" }} key={index}>
                                                                            {details.id === commonMapview[cat][t].details.unAssigneeId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ""}
                                                        </span> : ""}
                                                </div>)) : undefined}
                                        </div> : ""}
                                    {f.type !== 0 && f.type !== 8 && f.type !== 9 ?
                                        <div>
                                            {commonMapview[cat][t].details !== undefined ?
                                                <div>
                                                    {comment.map((k, ki) => (
                                                        <div key={ki}>
                                                            {commonMapview[cat][t].details.commentId === k.id ?
                                                                <div>
                                                                    <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "1rem", paddingTop: "1rem" }}>
                                                                        <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "flex-start" }}>
                                                                            <div>
                                                                                <Avatar src="/broken-image.jpg" style={{ backgroundColor: "whitesmoke", color: "#FF805D", marginTop: "0.5rem", marginRight: "1rem" }} />
                                                                            </div>
                                                                            <div style={{ display: 'flex', boxSizing: "border-box", flexDirection: "column", flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                                                                                <div style={{ marginBottom: "10px" }}>
                                                                                    {assignee && assignee ? assignee.map((details, index) => (
                                                                                        <span style={{ fontSize: "1em" }} key={index}>
                                                                                            {k.id == commonMapview[cat][t].details.commentId ? (
                                                                                                <small style={{ fontSize: "1em" }}>
                                                                                                    {k.creator.creator == details.id ?
                                                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                                                                        : ""}
                                                                                                </small>) : ("")}
                                                                                        </span>)) : null}
                                                                                    <small style={{ fontSize: "1em" }}>commented on {k.createdAt ? moment(k.createdAt).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                                </div>
                                                                                <div>
                                                                                    {k.attachment !== undefined ?
                                                                                        <div className="img-container">
                                                                                            {k.attachment.map((j, k) => (
                                                                                                <div key={k}>
                                                                                                    {commentFiles.map((g, h) => (
                                                                                                        <div>
                                                                                                            {commentFiles[h].id == j.id ? (
                                                                                                                <Paper variant="outlined" square sx={{ height: 160 }}>
                                                                                                                    <img
                                                                                                                        style={{
                                                                                                                            height: "110px",
                                                                                                                            width: "175px",
                                                                                                                            marginTop: "20px",
                                                                                                                            cursor: "pointer"
                                                                                                                        }}
                                                                                                                        loading="lazy"
                                                                                                                        src={commentFiles[h].files}
                                                                                                                        onClick={(e) => imageClick(e, j.url)}
                                                                                                                    />
                                                                                                                    {commentCondition[cat] ?
                                                                                                                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={(e) => { removeCommentImage(e, commonMapview[cat][t].details.commentId, j.id) }}
                                                                                                                        ><DeleteIcon color="error" /></p> : ""}
                                                                                                                </Paper>
                                                                                                            ) : (
                                                                                                                null
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            ))}
                                                                                        </div> : ""}
                                                                                </div>
                                                                                {!commentCondition[cat] ?
                                                                                    <div style={{ paddingTop: "1rem" }}>
                                                                                        {k.content}
                                                                                    </div> : ""}
                                                                                {commentCondition[cat] ?
                                                                                    <TextField id="outlined-basic" label="Notes" variant="outlined"
                                                                                        fullWidth={true}
                                                                                        rows={3}
                                                                                        sx={{ mb: 5, mt: 3 }}
                                                                                        multiline
                                                                                        placeholder="Write a Comment"
                                                                                        value={k.content}
                                                                                        onChange={(e) => { handleCommentContentChange(e, ki, comment) }}
                                                                                    /> : null}
                                                                                {commentCondition[cat] ?
                                                                                    <CardContent style={{ display: "flex", justifyContent: "right", padding: "0px" }}>
                                                                                        <label htmlFor="uploadBtn">
                                                                                            <AttachFileIcon
                                                                                                style={{
                                                                                                    fontSize: "25px",
                                                                                                    cursor: "pointer",
                                                                                                    marginTop: "3px",
                                                                                                }}
                                                                                                onClick={(e) => handleClick(e, k.id)}
                                                                                            />
                                                                                        </label>
                                                                                        <div>
                                                                                            <label>
                                                                                                <input
                                                                                                    ref={hiddenFileInput}
                                                                                                    type="file"
                                                                                                    id="uploadBtn"
                                                                                                    className="inputfile"
                                                                                                    onChange={(e) => addCommentAttachment(e, fileId)}
                                                                                                />
                                                                                            </label>
                                                                                        </div>
                                                                                        <Button color="error"
                                                                                            type="submit"
                                                                                            sx={{ fontWeight: "bold" }}
                                                                                            onClick={() => { setcommentCondition(prevcommentCondition => ({ ...prevcommentCondition, [cat]: !prevcommentCondition[cat] })) }}>
                                                                                            CANCEL
                                                                                        </Button>
                                                                                        <Button color="success"
                                                                                            variant="contained"
                                                                                            type="submit"
                                                                                            sx={{ fontWeight: "bold" }}
                                                                                            onClick={(e) => commentSaveClick(e, k.id, cat, k.content)}>
                                                                                            Save
                                                                                        </Button>
                                                                                    </CardContent> : ""}
                                                                                {k?.creator?.type === "Manager" ?
                                                                                    <div>
                                                                                        {!commentCondition[cat] ?
                                                                                            <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-end", alignContent: "flex-end", justifyContent: "flex-end", marginTop: '1rem' }}>
                                                                                                <Button color="primary"
                                                                                                    type="submit"
                                                                                                    size="small"
                                                                                                    sx={{ fontWeight: "bold" }}
                                                                                                    onClick={() => {
                                                                                                        toggle(cat);
                                                                                                    }}>
                                                                                                    Edit
                                                                                                </Button>
                                                                                                <Button color="error"
                                                                                                    type="submit"
                                                                                                    size="small"
                                                                                                    sx={{ fontWeight: "bold" }}
                                                                                                    onClick={(e) => deleteClick(e, k.id)}>
                                                                                                    Delete
                                                                                                </Button>
                                                                                            </div> : null}
                                                                                    </div>
                                                                                    : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""}
                                                        </div>))}
                                                </div> : ""}
                                        </div> : ""}
                                </div>))}
                        </div>))}
                </div>
                <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box", width: "75%", marginLeft: "auto", marginRight: "auto" }}>
                    <div style={{ paddingBottom: "1rem", paddingBottom: "1.5rem", borderBottom: "1px solid #dee2e6", marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box" }}>
                            <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "flex-start" }}>
                                <div>
                                    <Avatar src="/broken-image.jpg" style={{ backgroundColor: "whitesmoke", color: "#FF805D", marginTop: "0.5rem", marginRight: "1rem" }} />
                                </div>
                                <div style={{ display: 'flex', boxSizing: "border-box", flexDirection: "column", flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                                    <span style={{ fontSize: "1em", marginTop: "10px" }}>
                                        <small style={{ fontSize: "1em" }}>
                                            <strong style={{ fontSize: "1em" }}>{commentBoxName(serviceRequestById && serviceRequestById, assignee)}</strong>
                                        </small>
                                    </span>
                                    <div className="img-container">
                                        {attachImages()}
                                    </div>
                                    <TextField id="outlined-basic" variant="outlined"
                                        fullWidth={true}
                                        sx={{ pb: 5 }}
                                        rows={3}
                                        multiline
                                        placeholder="Write a comment"
                                        value={content}
                                        onChange={(e) => {
                                            setContent(e.target.value);
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", boxSizing: 'border-box', alignItems: "center", alignContent: "center", justifyContent: "flex-end" }}>
                            <div>
                                <input type="file" id="uploadButton" accept="image/*"
                                    onChange={handleImagesAdd}
                                    className="inputfile"
                                    multiple
                                />
                                <label htmlFor="uploadButton">
                                    <AttachFileIcon style={{ fontSize: 30, marginRight: "20px" }} /></label>
                            </div>
                            <div>
                                <Button variant="contained" color="success"
                                    type="submit"
                                    style={{ fontSize: 15 }}
                                    onClick={postClick}
                                >POST</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
            <Dialog
                open={addContractorDialog}
                onClose={() => setAddContractorDialog(false)}>
                <CardContent>
                    <Typography id="modal-modal-title" textAlign='center' variant="h6" component="h2">
                        Add Contractor
                    </Typography>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { my: 1, width: '100%' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField fullWidth={true} label="First Name" id="outlined-size-small" size="small"
                            value={contractFirstName}
                            onChange={(e) => { setContractFirstName(e.target.value) }} />
                        <TextField label="Last Name" id="outlined-size-small" size="small"
                            value={contractLastName}
                            onChange={(e) => { setContractLastName(e.target.value) }} />

                    </Box>

                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { my: 1, width: '100%' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Email" id="outlined-size-small" size="small"
                            value={contractEmail}
                            onChange={(e) => { setContractEmail(e.target.value) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { my: 1, width: '100%' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Phone No" id="outlined-size-small" size="small"
                            value={contractPhoneNo}
                            onChange={(e) => { onChangeHandler(e); handleInput(e) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { my: 1, width: '100%' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="City" id="outlined-size-small" size="small"
                            value={contractCity}
                            onChange={(e) => { setContractCity(e.target.value) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { my: 1, width: '100%' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Select State</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                label="Select State"
                                value={contractState}
                                onChange={(e) => {
                                    setContractState(e.target.value);
                                }}
                            >
                                <MenuItem value="-Select-">-Select-</MenuItem>
                                {states.map((state, index) => (
                                    <MenuItem key={index} value={state?.value}>{state?.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { my: 1, width: '100%' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl sx={{ width: 1 }}>
                            <InputLabel id="demo-multiple-chip-label">Skills Required</InputLabel>
                            <Select
                                sx={{ width: 1 }}
                                size="small"
                                variant="standard"
                                label="Skills Required"
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                input={<OutlinedInput label="Skills Required" />}
                                value={contractSkills}
                                onChange={handleChangeContractSkills}
                                renderValue={(ContractSelected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {ContractSelected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {contractorTags && contractorTags ? contractorTags.map((e, i) => (
                                    <MenuItem key={i} value={e}>
                                        {e}
                                    </MenuItem>
                                )) : undefined}
                            </Select>
                        </FormControl>
                        <p style={{ fontSize: 11, marginTop: "0px", marginBottom: "0px" }}>Press Enter or type","after entering a skill</p>

                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { my: 1, width: '100%' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextareaAutosize
                            fullWidth={true}
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Description"
                            value={contractDesc}
                            onChange={(e) => { setContractDesc(e.target.value) }}
                        />
                    </Box>
                </CardContent>
                <CardContent style={{ display: "flex", flexDirection: "row", justifyContent: "right", marginTop: "-29px" }}>
                    <Button variant="contained" color="success"
                        type="submit"
                        sx={{ fontWeight: "bold" }}
                        size="small"
                        onClick={contractorAddClick}
                    >Add</Button>
                    <Button color="error"
                        type="submit"
                        size="small"
                        sx={{ fontWeight: "bold" }}
                        onClick={(e) => { setAddContractorDialog(false) }}
                    >
                        CANCEL
                    </Button>
                </CardContent>
            </Dialog>
            <Dialog
                open={contractorViewModel}
                onClose={() => setContractorViewModel(false)}
                fullWidth={true}
                maxWidth="xl">
                <CardContent>
                    <CardContent>
                        <Typography id="modal-modal-title" textAlign='center' variant="h6" component="h2">
                            Appoint Contractor
                        </Typography>
                    </CardContent>
                    <TextField
                        id="standard-basic"
                        fullWidth={true}
                        variant="standard"
                        label="Search..."
                        type="text"
                    ></TextField>
                    <TableContainer sx={{ height: 500 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ paddingTop: "20px", paddingBottom: "20px", paddingLeft: "10px", paddingRight: "10px" }}>Name</TableCell>
                                    <TableCell sx={{ paddingTop: "20px", paddingBottom: "20px", paddingLeft: "10px", paddingRight: "10px" }}>Rating</TableCell>
                                    <TableCell sx={{ paddingTop: "20px", paddingBottom: "20px", paddingLeft: "10px", paddingRight: "10px" }}>Skills</TableCell>
                                    <TableCell sx={{ paddingTop: "20px", paddingBottom: "20px", paddingLeft: "10px", paddingRight: "10px" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contractor && contractor ? contractor.map((e, i) => (e.archive === false &&
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row" sx={{ padding: "10px" }}>{e.name.first} {e.name.last}</TableCell>
                                        <TableCell sx={{ padding: "10px" }}>{e.starRatings === 0 ? <p>Not Rated</p> : (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}><Rating name="size-small" defaultValue={1} max={1} size="small" /> {e.starRatings === 0.2 ? "1.0" : ""}{e.starRatings === 0.4 ? "2.0" : ""}{e.starRatings === 0.6 ? "3.0" : ""}{e.starRatings === 0.8 ? "4.0" : ""}{e.starRatings === 1 ? "5.0" : ""} / 5 (1 Ratings)</div>)}</TableCell>
                                        <TableCell sx={{ padding: "10px" }}>
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                {e.skills.map((h, i) => (
                                                    <div style={{ marginRight: "10px" }} key={i}>
                                                        <Button
                                                            style={{
                                                                borderRadius: 35,
                                                                marginLeft: "-5px",
                                                                backgroundColor: "#e0e0e0",
                                                                color: "black",
                                                                textTransform: "lowercase"
                                                            }}
                                                            size="small">{h}
                                                        </Button>
                                                    </div>
                                                ))}
                                            </div>
                                        </TableCell>

                                        <TableCell sx={{ padding: "10px" }}> <Stack direction="row" spacing={2}>
                                            <Popup
                                                trigger={<div style={{ cursor: "pointer" }} onClick={() => copyPhone(e.phone.number)}><PhoneIcon style={{ color: "#2196F3", marginTop: "5px" }} /></div>}
                                                position="bottom center"
                                            >
                                                <Popup.Content>
                                                    <p>Copy {e.phone.number}</p>
                                                </Popup.Content>
                                            </Popup>
                                            <Popup
                                                trigger={<div style={{ cursor: "pointer" }} onClick={() => copyEmail(e.email.id)}><EmailIcon style={{ color: "#2196F3", marginTop: "5px" }} /></div>}
                                                position="bottom center"
                                            >
                                                <Popup.Content>
                                                    <p>Copy {e.email.id}</p>
                                                </Popup.Content>
                                            </Popup>
                                            <Button style={{ borderColor: "#e0e0e0" }} variant="outlined" size="small" onClick={() => appointClick(e.id)}>APPOINT</Button>
                                        </Stack>
                                        </TableCell>
                                    </TableRow>
                                )) : undefined}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardContent style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                    <Button style={{
                        backgroundColor: "#9c27b0",
                        borderColor: '#9c27b0', color: "white", borderRadius: 35
                    }} variant="outlined" startIcon={<AddIcon />}
                        onClick={addContractorClick}>
                        CONTRACTOR</Button>
                    <Button style={{ border: "none" }} variant="outlined" color="error"
                        onClick={() => setContractorViewModel(false)}>
                        CANCEL
                    </Button>
                </CardContent>
            </Dialog>

            <Dialog onClose={handleCloseAddFinanceExpenseDetailsDialog} open={addFinanceExpenseDetailsDialog}>
                <DialogTitle textAlign={"center"}>Add Finance Expense Details</DialogTitle>
                <DialogContent>
                    <div style={{ paddingBottom: 20, paddingTop: 30 }}>
                        <Typography
                            variant="h8"
                            gutterBottom
                            sx={{ fontWeight: 'bold' }}
                        >
                            Expense
                        </Typography>
                    </div>
                    <div style={{ paddingBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: "space-between", flexWrap: 'wrap' }}>
                        <FormControlLabel
                            value={"Labor"}
                            label="Labor"
                            onChange={(e) => { handleCheckBoxChange(e) }}
                            control={<Checkbox />}
                            disabled={addFinanceExpenseDetailsCheckbox == "Materials" || addFinanceExpenseDetailsCheckbox == "Travel" ? true : false}
                        />
                        <FormControlLabel
                            value={"Materials"}
                            label="Materials"
                            onChange={(e) => { handleCheckBoxChange(e) }}
                            control={<Checkbox />}
                            disabled={addFinanceExpenseDetailsCheckbox == "Labor" || addFinanceExpenseDetailsCheckbox == "Travel" ? true : false}
                        />
                        <FormControlLabel
                            value={"Travel"}
                            label="Travel"
                            onChange={(e) => { handleCheckBoxChange(e) }}
                            control={<Checkbox />}
                            disabled={addFinanceExpenseDetailsCheckbox == "Materials" || addFinanceExpenseDetailsCheckbox == "Labor" ? true : false}
                        />
                    </div>
                    {addFinanceExpenseDetailsCheckbox === "Labor" ?
                        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            views={["day"]}
                                            label="Entry Date"
                                            value={financeEntryDate}
                                            onChange={(newValue) => {
                                                setFinanceEntryDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true} />} />

                                    </LocalizationProvider>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Total Labour Charge</InputLabel>
                                        <OutlinedInput
                                            variant="outlined"
                                            value={totalLabourCharge}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                                setTotalLabourCharge(numericValue);
                                            }}
                                            inputProps={{
                                                inputMode: 'numeric', // Restrict input to numeric characters
                                                pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                            }}
                                            startAdornment={<InputAdornment position="start"><Icon style={{ color: 'black' }}>$</Icon></InputAdornment>}
                                            label="Total Labor Charge"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">Total Label Hours Worked</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            variant="outlined"
                                            value={totalLabourHoursWorked}
                                            onChange={(e) => {
                                                setTotalLabourHoursWorked(parseInt(e.target.value));
                                            }}
                                            label="Total Label Hours Worked"
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth={true}>
                                        <TextField
                                            fullWidth={true}
                                            label="Expense Type"
                                            select
                                            variant="outlined"
                                            value={espenseType}
                                            onChange={(e) => {
                                                setEspenseType(e.target.value);
                                            }}
                                        >
                                            {expense.map((ex) => (
                                                <MenuItem key={ex.value} value={ex.value}>
                                                    {ex.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        : ""}
                    {addFinanceExpenseDetailsCheckbox === "Materials" ?
                        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            views={["day"]}
                                            label="Entry Date"
                                            value={financeEntryDate}
                                            onChange={(newValue) => {
                                                setFinanceEntryDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true} />} />

                                    </LocalizationProvider>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth sx={{ m: 1 }}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Total Materials Cost</InputLabel>
                                        <OutlinedInput
                                            variant="outlined"
                                            value={totalMaterialsCost}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                                setTotalMaterialsCost(numericValue);
                                            }}
                                            inputProps={{
                                                inputMode: 'numeric', // Restrict input to numeric characters
                                                pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                            }}
                                            startAdornment={<InputAdornment position="start"><Icon style={{ color: 'black' }}>$</Icon></InputAdornment>}
                                            label="Total Material Cost"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                            <span>Minutes/Receipts</span>
                                            <span>
                                                <input type="file" id="uploadBtnfinance" accept="image/*"
                                                    style={{ display: "none" }}
                                                    onChange={handleFinanceAllSelect}
                                                />
                                                <label htmlFor="uploadBtnfinance">
                                                    <CloudUploadIcon color="primary" />
                                                </label>
                                            </span>
                                        </span>
                                        <Card sx={{ minHeight: 50, maxWidth: 250, border: '1px solid grey', padding: '5px' }}>
                                            {serviceRequestById.materialCostReceipts.map((material) => (serviceRequestById.uniqueFinanceId < material.uniqueFinanceId ?
                                                <span>
                                                    <span className="hover-blue-text" style={{ cursor: 'pointer' }} onClick={(e) => { imageClick(e, material.url) }}>
                                                        {material.fileName}
                                                    </span>
                                                    <span>
                                                        <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { removeImageFinanceAll(material.id) }} />
                                                    </span>
                                                </span>
                                                : ""))}
                                        </Card>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth={true} style={{ paddingTop: 15 }}>
                                        <TextField
                                            fullWidth={true}
                                            label="Expense Type"
                                            select
                                            variant="outlined"
                                            value={espenseType}
                                            onChange={(e) => {
                                                setEspenseType(e.target.value);
                                            }}
                                        >
                                            {expense.map((ex) => (
                                                <MenuItem key={ex.value} value={ex.value}>
                                                    {ex.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </div>
                            </div>
                        </div> : ""}
                    {addFinanceExpenseDetailsCheckbox === "Travel" ?
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                            <div style={{ flex: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        views={["day"]}
                                        label="Travel Entry Date"
                                        value={travelEntryDate}
                                        onChange={(newValue) => {
                                            setTravelEntryDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth={true} />} />

                                </LocalizationProvider>
                            </div>
                            <div style={{ flex: 1 }}>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Manager Distance Travelled</InputLabel>
                                    <OutlinedInput
                                        type="number"
                                        variant="outlined"
                                        value={managerDistanceTravelled}
                                        onChange={(e) => {
                                            setManagerDistanceTravelled(parseInt(e.target.value));
                                        }}
                                        endAdornment={<InputAdornment position="start">Miles</InputAdornment>}
                                        label="Manager Distance Travelled"
                                    />
                                </FormControl>
                            </div>
                        </div> : ""}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', gap: 10, flexWrap: 'wrap', paddingTop: 30 }}>
                        {addFinanceExpenseDetailsCheckbox !== "" ?
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={{ fontSize: 15 }}
                                onClick={saveFinanceAllClick}
                            >
                                SAVE
                            </Button>
                            : ""}
                        <Button color="error"
                            type="submit"
                            sx={{ fontSize: 15 }}
                            onClick={() => handleCloseAddFinanceExpenseDetailsDialog()}
                        >
                            CANCEL
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog onClose={handleCloseupdateFinanceExpenseDetailsDialog} open={updateFinanceExpenseDetailsDialog}>
                <DialogTitle textAlign={"center"}>Update Finance Expense Details</DialogTitle>
                <DialogContent>
                    <div style={{ paddingBottom: 20, paddingTop: 30 }}>
                        <Typography
                            variant="h8"
                            gutterBottom
                            sx={{ fontWeight: 'bold' }}
                        >
                            Expense
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 20 }}>
                        {financeEntryDate !== null && totalLabourCharge !== "0" && totalLabourHoursWorked !== 0 && travelEntryDate == null && managerDistanceTravelled == 0 && totalMaterialsCost == "0" && totalLabourCharge != "0" ?
                            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                                    <div style={{ flex: 1 }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                views={["day"]}
                                                label="Entry Date"
                                                value={financeEntryDate}
                                                onChange={(newValue) => {
                                                    setFinanceEntryDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} fullWidth={true} />} />

                                        </LocalizationProvider>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-amount">Total Labour Charge</InputLabel>
                                            <OutlinedInput
                                                variant="outlined"
                                                value={totalLabourCharge}
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                                    setTotalLabourCharge(numericValue);
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric', // Restrict input to numeric characters
                                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                                }}
                                                startAdornment={<InputAdornment position="start"><Icon style={{ color: 'black' }}>$</Icon></InputAdornment>}
                                                label="Total Labor Charge"
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                                    <div style={{ flex: 1 }}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-amount">Total Label Hours Worked</InputLabel>
                                            <OutlinedInput
                                                type="number"
                                                variant="outlined"
                                                value={totalLabourHoursWorked}
                                                onChange={(e) => {
                                                    setTotalLabourHoursWorked(parseInt(e.target.value));
                                                }}
                                                label="Total Label Hours Worked"
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth={true}
                                                label="Expense Type"
                                                select
                                                variant="outlined"
                                                value={espenseType}
                                                onChange={(e) => {
                                                    setEspenseType(e.target.value);
                                                }}
                                            >
                                                {expense.map((ex) => (
                                                    <MenuItem key={ex.value} value={ex.value}>
                                                        {ex.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            : ""}
                        {financeEntryDate !== null && totalMaterialsCost !== "0" && totalLabourCharge == "0" && travelEntryDate == null && managerDistanceTravelled == 0 ?
                            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                                    <div style={{ flex: 1 }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                views={["day"]}
                                                label="Entry Date"
                                                value={financeEntryDate}
                                                onChange={(newValue) => {
                                                    setFinanceEntryDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} fullWidth={true} />} />

                                        </LocalizationProvider>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <FormControl fullWidth sx={{ m: 1 }}>
                                            <InputLabel htmlFor="outlined-adornment-amount">Total Materials Cost</InputLabel>
                                            <OutlinedInput
                                                variant="outlined"
                                                value={totalMaterialsCost}
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                                    setTotalMaterialsCost(parseInt(numericValue));
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric', // Restrict input to numeric characters
                                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                                }}
                                                startAdornment={<InputAdornment position="start"><Icon style={{ color: 'black' }}>$</Icon></InputAdornment>}
                                                label="Total Material Cost"
                                            /></FormControl>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flexWrap: 'wrap', gap: 20 }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                                <span>Minutes/Receipts</span>
                                                <span>
                                                    <input type="file" id="uploadBtnfinanceUpdate" accept="image/*"
                                                        style={{ display: "none" }}
                                                        onChange={handleFinanceAllMaterialImage}
                                                    />
                                                    <label htmlFor="uploadBtnfinanceUpdate">
                                                        <CloudUploadIcon color="primary" sx={{ cursor: 'pointer' }} />
                                                    </label>
                                                </span>
                                            </span>
                                            <Card sx={{ minHeight: 50, maxWidth: 250, border: '1px solid grey', padding: '5px' }}>
                                                {serviceRequestById.materialCostReceipts.map((material) => (material.uniqueFinanceId == uniqueFinId ?
                                                    <span>
                                                        <span className="hover-blue-text" style={{ cursor: 'pointer' }} onClick={(e) => { imageClick(e, material.url) }}>
                                                            {material.fileName}
                                                        </span>
                                                        <span>
                                                            <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { removeImageFinanceAll(material.id) }} />
                                                        </span>
                                                    </span>
                                                    : ""))}
                                            </Card>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <FormControl fullWidth={true} style={{ paddingTop: 15 }}>
                                            <TextField
                                                fullWidth={true}
                                                label="Expense Type"
                                                select
                                                variant="outlined"
                                                value={espenseType}
                                                onChange={(e) => {
                                                    setEspenseType(e.target.value);
                                                }}
                                            >
                                                {expense.map((ex) => (
                                                    <MenuItem key={ex.value} value={ex.value}>
                                                        {ex.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            : ""}
                        {travelEntryDate !== null && managerDistanceTravelled !== 0 && espenseType == "TRAVEL_MILES" ?
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            views={["day"]}
                                            label="Travel Entry Date"
                                            value={travelEntryDate}
                                            onChange={(newValue) => {
                                                setTravelEntryDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true} />} />

                                    </LocalizationProvider>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth sx={{ m: 1 }}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Manager Distance Travelled</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            variant="outlined"
                                            value={managerDistanceTravelled}
                                            onChange={(e) => {
                                                setManagerDistanceTravelled(parseInt(e.target.value));
                                            }}
                                            endAdornment={<InputAdornment position="start">Miles</InputAdornment>}
                                            label="Manager Distance Travelled"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            : ""}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', gap: 10, flexWrap: 'wrap', paddingTop: 30 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{ fontSize: 15 }}
                            onClick={() => { updateFinanceAllClick(financeId) }}
                        >
                            UPDATE
                        </Button>
                        <Button color="error"
                            type="submit"
                            sx={{ fontSize: 15 }}
                            onClick={() => handleCloseupdateFinanceExpenseDetailsDialog()}
                        >
                            CANCEL
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={deleteFinanceDialog}
                onClose={FinancedeletedDialogClose}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Are you sure you want delete this finance details?
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                    <div>
                        <Button variant="contained" color='primary' onClick={() => deleteFinanceAllById(financeId)}>Yes</Button>
                        <Button color='error' onClick={FinancedeletedDialogClose}>CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>

            {/* Contractor Finance Dialogs */}

            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={contractorRecieptDialog}
                onClose={() => { setContractorRecieptDialog(false) }}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        No receipt uploaded, changes will be lost .
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                    <div>
                        <Button variant="contained" color='primary' onClick={() => { setContractorRecieptDialog(false) }}>Yes</Button>
                        <Button color='error' onClick={() => { setContractorRecieptDialog(false) }}>NO</Button>
                    </div>
                </CardContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={contractorFinanceDeleteFileDialog}
                onClose={contractorFinanceDeleteFileDialogClose}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Are you sure you want to delete this file?
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                    <div>
                        <Button variant="contained" color='primary' onClick={() => { removecontractorFinaceUpdateAtachment(contractorFinanceId, contractorFinanceAttachId) }}>Ok</Button>
                        <Button color='error' onClick={() => { contractorFinanceDeleteFileDialogClose() }}>CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={contractorFinanceDeleteDialog}
                onClose={contractorFinanceDeleteDialogClose}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Are you sure you want delete this finance details?
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                    <div>
                        <Button variant="contained" color='primary' onClick={() => { deleteContractFinance(contractorFinanceId) }}>Yes</Button>
                        <Button color='error' onClick={() => { contractorFinanceDeleteDialogClose() }}>CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default ServiceRequests;