import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formNames } from "../../../../helpers";
import { FaStepBackward, FaStepForward } from "react-icons/fa";
import {
    editTenantLeaseApplicantInfo, getTenantLeaseApplication,
    withDrawApplication
} from "../../../../services";
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TenantLeaseApplicationEditPage from ".";

function HistoryForm() {
    let params = useParams();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.value);

    const [evicted, setEvicted] = useState(false);
    const [evictionReason, setEvictionReason] = useState("");
    const [evictionReasonError, setEvictionReasonError] = useState("");
    const [convicted, setConvicted] = useState(false);
    const [convictionReason, setConvictionReason] = useState("");
    const [convictionReasonError, setConvictionReasonError] = useState("");

    const [FieldsDisabled, setFieldsDisabled] = useState(false);
    const [SubmittedStatus, setSubmittedStatus] = useState(false);
    const [Modify, setModify] = useState(false);

    const pageLoad = async () => {
        await getTenantLeaseApplication(params.applicationId).then((res) => {
            let application = res?.data?.data;
            if (application) {
                // Variables for current employment and previous employment.
                let applicant;
                // If there two applicants, we need to find the currently logged in user from the array.
                application.applicants.forEach((item, index) => {
                    if (item.user === user.id) {
                        applicant = application.applicants[index];
                    }
                });

                setEvicted(applicant.hasEvictionHistory ?? false);
                setEvictionReason(applicant.evictionHistoryDetails ?? "");
                setConvicted(applicant.hasCriminalHistory ?? false);
                setConvictionReason(applicant.criminalHistoryDetails ?? "");
            }
            if (application?.status == "APPLICANT_SUBMITTED") {
                setFieldsDisabled(true);
                setSubmittedStatus(true);
            }
            if (application?.status == "APPLICANT_WITHDRAWN") {
                setFieldsDisabled(false);
                setModify(true);
            }
        }).catch(err => { console.log(err) });
    }

    useEffect(() => {
        pageLoad();
    }, []);

    const handleModify = () => {
        const res = withDrawApplication(params.applicationId).then((response) => {
            window.location.reload();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };
    const handleHistorySubmit = () => {
        let error = false;
        if (evicted && evictionReason === "") {
            setEvictionReasonError("Please fill in this field");
            error = true;
        } else {
            setEvictionReasonError("");
        }
        if (convicted && convictionReason === "") {
            setConvictionReasonError("Please fill in this field");
            error = true;
        } else {
            setConvictionReasonError("");
        }
        if (error) return;

        let data = {
            criminalHistoryDetails: convictionReason,
            evictionHistoryDetails: evictionReason,
            hasCriminalHistory: convicted,
            hasEvictionHistory: evicted,
        }

        editTenantLeaseApplicantInfo(params.applicationId, data).then(response => {
            toast.success("Updated History details");
            pageLoad();
            navigate(`/tenant/lease-applications/${params.applicationId}/agreement`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }
    // return (
    //     <div className="lease-form-item history-form">
    //         <h1 className="form-header">History</h1>
    //         <div className="form-body">
    //             <div className="form-section">
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         <span className="form-item-label">
    //                             Have you ever been evicted or a defendant in an eviction scene?
    //                         </span>
    //                         <label>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="radio"
    //                                 checked={evicted === true}
    //                                 onChange={() => {
    //                                     setEvicted(!evicted);
    //                                 }}
    //                                 disabled={FieldsDisabled}
    //                             />Yes
    //                         </label>
    //                         <label>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="radio"
    //                                 checked={evicted === false}
    //                                 onChange={() => {
    //                                     setEvicted(!evicted);
    //                                 }}
    //                                 disabled={FieldsDisabled}
    //                             />No
    //                         </label>
    //                     </div>
    //                 </div>
    //                 {evicted &&
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Reason for eviction</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={evictionReason}
    //                                 onChange={e => { setEvictionReason(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {evictionReasonError &&
    //                                 <span className="form-item-error">
    //                                     {evictionReasonError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                 }
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         <span className="form-item-label">
    //                             Have you ever been convicted of a misdemeanor or felony in any state?
    //                         </span>
    //                         <label>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="radio"
    //                                 checked={convicted === true}
    //                                 onChange={() => {
    //                                     setConvicted(!convicted);
    //                                 }}
    //                                 disabled={FieldsDisabled}
    //                             />Yes
    //                         </label>
    //                         <label>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="radio"
    //                                 checked={convicted === false}
    //                                 onChange={() => {
    //                                     setConvicted(!convicted);
    //                                 }}
    //                                 disabled={FieldsDisabled}
    //                             />No
    //                         </label>
    //                     </div>
    //                 </div>
    //                 {convicted &&
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Reason for conviction</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={convictionReason}
    //                                 onChange={e => { setConvictionReason(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {convictionReasonError &&
    //                                 <span className="form-item-error">
    //                                     {convictionReasonError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                 }
    //             </div>
    //         </div>
    //         <div className="form-footer">
    //             {Modify === true ? (
    //                 <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         startIcon={<FaStepBackward />}
    //                         onClick={() => { setCurrentForm(formNames.verificationForm) }}
    //                     >VERIFICATION</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="success"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleHistorySubmit}
    //                     >SAVE</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleHistorySubmit}
    //                         endIcon={<FaStepForward />}
    //                     >AGREEMENT</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="info"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={() => { setCurrentForm(formNames.agreementForm) }}
    //                         endIcon={<FaStepForward />}
    //                     >GO TO SUBMIT</Button>
    //                 </div>
    //             ) : (
    //                 <>
    //                     {SubmittedStatus === true ? (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 startIcon={<FaStepBackward />}
    //                                 onClick={() => { setCurrentForm(formNames.verificationForm) }}
    //                             >VERIFICATION</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleHistorySubmit}
    //                                 endIcon={<FaStepForward />}
    //                             >AGREEMENT</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={(e) => handleModify(e)}
    //                             >MODIFY</Button>
    //                         </div>
    //                     ) : (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 startIcon={<FaStepBackward />}
    //                                 onClick={() => { setCurrentForm(formNames.verificationForm) }}
    //                             >VERIFICATION</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="success"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleHistorySubmit}
    //                             >SAVE</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleHistorySubmit}
    //                                 endIcon={<FaStepForward />}
    //                             >AGREEMENT</Button>
    //                         </div>
    //                     )}
    //                 </>
    //             )}
    //         </div>
    //         <ToastContainer
    //             position="top-center"
    //             autoClose={2000}
    //             hideProgressBar={false}
    //             newestOnTop={false}
    //             closeOnClick
    //             rtl={false}
    //             pauseOnFocusLoss
    //             draggable
    //             pauseOnHover
    //             theme="colored"
    //         />
    //     </div>
    // );
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: "100%", maxWidth: "1350px", }}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TenantLeaseApplicationEditPage />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h3 className="subHeader">Have you ever been evicted or a defendant in an eviction scene?</h3>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => { setEvicted(e.target.value === 'true') }}
                            >
                                <FormControlLabel value="true" checked={evicted === true} control={<Radio />} label="Yes" />
                                <FormControlLabel value="false" checked={evicted === false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {evicted &&
                            <TextField
                                error={evictionReasonError ? true : false}
                                helperText={evictionReasonError && evictionReasonError}
                                placeholder="Enter Reason"
                                margin="dense"
                                multiline
                                rows={4}
                                required={true}
                                variant="outlined"
                                fullWidth={true}
                                value={evictionReason}
                                onChange={e => { setEvictionReason(e.target.value) }}
                                disabled={FieldsDisabled}
                            />
                        }
                        <h3 className="subHeader">Have you ever been convicted of a misdemeanor or felony in any state?</h3>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => { setConvicted(e.target.value === 'true') }}
                            >
                                <FormControlLabel value="true" checked={convicted === true} control={<Radio />} label="Yes" />
                                <FormControlLabel value="false" checked={convicted === false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {convicted &&
                            <TextField
                                error={convictionReasonError ? true : false}
                                helperText={convictionReasonError && convictionReasonError}
                                placeholder="Enter Reason"
                                margin="dense"
                                multiline
                                rows={4}
                                required={true}
                                variant="outlined"
                                fullWidth={true}
                                value={convictionReason}
                                onChange={e => { setConvictionReason(e.target.value) }}
                                disabled={FieldsDisabled}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {Modify === true ? (
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    sx={{ fontSize: 15 }}
                                    startIcon={<FaStepBackward />}
                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/verification`); }}
                                >VERIFICATION</Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{ fontSize: 15 }}
                                    onClick={handleHistorySubmit}
                                >SAVE</Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    sx={{ fontSize: 15 }}
                                    onClick={handleHistorySubmit}
                                    endIcon={<FaStepForward />}
                                >AGREEMENT</Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    sx={{ fontSize: 15 }}
                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/agreement`); }}
                                    endIcon={<FaStepForward />}
                                >GO TO SUBMIT</Button>
                            </div>
                        ) : (
                            <>
                                {SubmittedStatus === true ? (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            startIcon={<FaStepBackward />}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/verification`); }}
                                        >VERIFICATION</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleHistorySubmit}
                                            endIcon={<FaStepForward />}
                                        >AGREEMENT</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={(e) => handleModify(e)}
                                        >MODIFY</Button>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            startIcon={<FaStepBackward />}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/verification`); }}
                                        >VERIFICATION</Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleHistorySubmit}
                                        >SAVE</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleHistorySubmit}
                                            endIcon={<FaStepForward />}
                                        >AGREEMENT</Button>
                                    </div>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default HistoryForm;