import { Box, Card, Grid, Icon, InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Button, CardContent } from 'semantic-ui-react';
import { FaBath, FaBed } from "react-icons/fa";
import { getTenantsLeaseInvitation, getSingleProperty, postTenantsLeaseInvitation, getApiBaseURL } from '../../../../services';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const LeaseUpdateContactDetails = () => {
    // Query Params
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const navigate = useNavigate();

    const [property, setProperty] = useState({})
    const [tenantFirstname, setTenantFirstname] = useState("");
    const [tenantLastname, setTenantLastname] = useState("");
    const [tenantEmail, setTenantEmail] = useState("");
    const [tenantphone, setTenantPhone] = useState("");
    const [tenantpassword, setTenantPassword] = useState("");
    const [error, setError] = useState(false);

    // Password Show & Hide
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    useEffect(() => {
        getTenantsLeaseInvitation(token).then((res) => {
            let data = res.data.data;
            setTenantFirstname(data?.inviteeFirstName);
            setTenantLastname(data?.inviteeLastName);
            setTenantEmail(data?.inviteeEmail);
            setTenantPhone(data?.inviteePhoneNumber);
            getSingleProperty(data?.propertyId).then((res) => {
                let data = res.data.data;
                setProperty(data);
            }).catch((err) => console.log(`Error${err}`));
        }).catch((err) => {
            console.log(`Error${err}`);
            if (err.response.data.error.message === "Invitation Not Found") {
                setError(true);
            }
        });
    }, []);

    const buttonenable = () => {
        if (tenantFirstname === "" || tenantLastname === "" || tenantEmail === "" || tenantphone == "" || tenantpassword === "")
            return true
    }

    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )
            } - ${phoneNumber.slice(6, 10)}`;
    }

    const updateClick = () => {
        let data = {
            firstName: tenantFirstname,
            lastName: tenantLastname,
            phoneNumber: tenantphone,
            phoneCountryCode: `+1`,
            email: tenantEmail,
            password: tenantpassword,
            response: `ACCEPT`
        }
        postTenantsLeaseInvitation(token, data).then((res) => {
            if (res) {
                toast.success(`Lease Invitation Accepted`)
                navigate(`/login`);
            }
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    return (
        <div>
            {error === false ?
                (<div>
                    <CardContent style={{ marginTop: "30px" }}>
                        <p className='update-contact-email-paragraph'>Update Contact Details</p>
                    </CardContent>
                    <Box style={{ margin: "3%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Card variant='outlined' sx={{ height: "100%" }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={8}>
                                            <img
                                                src={property?.images?.defaultImageId
                                                    ? `${getApiBaseURL()}/properties/${property.id}/images/default`
                                                    : "/images/default-property-picture.jpeg"}
                                                alt="Set of buildings"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ textAlign: "center", margin: "auto" }}>
                                            <CardContent>
                                                <p className='update-contact-details-address1'>{property?.address?.line1}</p>
                                                <p className='update-contact-details-address2'>{property?.address?.city}, {property?.address?.state}</p>
                                                <p className='update-contact-details-amount'>$ {property?.rentAmount?.toLocaleString('en')}/month</p>
                                                <p className='update-contact-bath'><FaBed style={{ color: "#4a90e2" }} /> {property?.numberOfBedrooms} <FaBath style={{ color: "#4a90e2" }} /> {property?.numberOfBathrooms}</p>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card variant='outlined' sx={{ height: "100%" }}>
                                    <CardContent style={{ margin: "20px" }}>
                                        <TextField
                                            fullWidth={true}
                                            label="Email*"
                                            variant='standard'
                                            value={tenantEmail}
                                            onChange={e => { setTenantEmail(e.target.value) }}
                                        />
                                    </CardContent>
                                    <CardContent style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px", margin: "20px" }}>
                                        <CardContent style={{ flexGrow: 1 }}>
                                            <TextField
                                                fullWidth={true}
                                                label="First Name*"
                                                variant='standard'
                                                value={tenantFirstname}
                                                onChange={e => { setTenantFirstname(e.target.value) }}
                                            />
                                        </CardContent>
                                        <CardContent style={{ flexGrow: 1 }}>
                                            <TextField
                                                fullWidth={true}
                                                label="Last Name*"
                                                variant='standard'
                                                value={tenantLastname}
                                                onChange={e => { setTenantLastname(e.target.value) }}
                                            />
                                        </CardContent>
                                    </CardContent>
                                    <CardContent style={{ margin: "20px" }}>
                                        <TextField
                                            fullWidth={true}
                                            label="Phone*"
                                            variant='standard'
                                            value={tenantphone}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon sx={{ color:"black" }}>
                                                            +1
                                                        </Icon>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={e => { setTenantPhone(formatPhoneNumber(e.target.value)) }}
                                        />
                                    </CardContent>
                                    <CardContent style={{ margin: "20px" }}>
                                        <TextField
                                            fullWidth={true}
                                            label="Password*"
                                            variant='standard'
                                            value={tenantpassword}
                                            onChange={e => { setTenantPassword(e.target.value) }}
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={handleClickShowPassword}>
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </Icon>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </CardContent>
                                    <CardContent style={{ margin: "20px" }}>
                                        <Button disabled={buttonenable()} variant="contained" color="blue" style={{ width: "100%" }} onClick={updateClick}>UPDATE CONTACT DETAILS</Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </div>) : (
                    <CardContent className="lease-invitation-update-error-center">
                        <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Invalid Invitation</p>
                        <p style={{ fontSize: 17, textAlign: "center", color: "grey" }}>Sorry, this invitation is no longer valid!</p>
                    </CardContent>)}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default LeaseUpdateContactDetails;
