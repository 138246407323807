import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
    getSingleProperty,
    getTenantLeaseApplication
} from "../../../../services";
// import EmploymentForm from "./employmentForm";
// import OccupantsForm from "./occupantsForm";
// import ResidenceForm from "./residenceForm";
// import VerificationForm from "./verificationForm";
// import HistoryForm from "./historyForm";
// import AgreementForm from "./agreementForm";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { Tabs, Tab, Box, Grid } from "@mui/material";

function TenantLeaseApplicationEditPage() {
    let params = useParams();

    const location = useLocation();
    const currentTab = location.pathname;

    // const formNames = {
    //     occupantsForm: 0,
    //     residenceForm: 1,
    //     employmentForm: 2,
    //     verificationForm: 3,
    //     historyForm: 4,
    //     agreementForm: 5,
    //     statusForm: 6,
    // };

    // const [application, setApplication] = useState();
    const [property, setProperty] = useState(null);
    // const [currentForm, setCurrentForm] = useState(formNames.occupantsForm);

    const getData = async () => {
        await getTenantLeaseApplication(params.applicationId).then(response => {
            // handleApplicationData(response.data.data);
            return getSingleProperty(response?.data?.data?.property);
        }).then(response => {
            setProperty(response.data.data);
        }).catch(err => { console.log(err) });
    }

    useEffect(() => {
        getData();
    }, [params]);

    // const handleApplicationData = (data) => {
    //     setApplication(data);
    // }

    // const tabsUrlClick = (data, index) => {
    //     setCurrentForm(index);
    // }

    return (
        // <div className="page tenant-lease-application-edit-page">
        //     <div className="page-container">
        //         <h1>Lease Application</h1>
        //         {property &&
        //             <div className="property-details" id="property-details">
        //                 <div className="property-address">
        //                     <div className="property-address-line-one">{property.address.line1}</div>
        //                     <div className="property-address-line-two">{`${property.address.city}, ${property.address.state}`}</div>
        //                 </div>
        //                 <div className="property-manager">
        //                     <div>Questions?</div>
        //                     <div>
        //                         {`Manager: ${property.owner.user.name.first} ${property.owner.user.name.last}`}
        //                     </div>
        //                     <a href={`tel:${property.owner.user.phone.code}${property.owner.user.phone.number}`}>
        //                         {`${property.owner.user.phone.code}${property.owner.user.phone.number}`}
        //                     </a>
        //                 </div>
        //             </div>
        //         }
        //         <div className="form-navigation">
        //             <div onClick={(e)=>{tabsUrlClick(e,formNames.occupantsForm)}} className={`form-navigation-item occupants ${currentForm >= 0 ? "complete" : ""}`}>
        //                 1. Occupants
        //             </div>
        //             <div onClick={(e)=>{tabsUrlClick(e,formNames.residenceForm)}} className={`form-navigation-item ${currentForm >= 1 ? "complete" : ""}`}>
        //                 2. Residence
        //             </div>
        //             <div onClick={(e)=>{tabsUrlClick(e,formNames.employmentForm)}} className={`form-navigation-item ${currentForm >= 2 ? "complete" : ""}`}>
        //                 3. Employment and Income
        //             </div>
        //             <div onClick={(e)=>{tabsUrlClick(e,formNames.verificationForm)}} className={`form-navigation-item ${currentForm >= 3 ? "complete" : ""}`}>
        //                 4. Verification
        //             </div>
        //             <div onClick={(e)=>{tabsUrlClick(e,formNames.historyForm)}} className={`form-navigation-item ${currentForm >= 4 ? "complete" : ""}`}>
        //                 5. History
        //             </div>
        //             <div onClick={(e)=>{tabsUrlClick(e,formNames.agreementForm)}}className={`form-navigation-item ${currentForm >= 5 ? "complete" : ""}`}>
        //                 6. Agreement
        //             </div>
        //             <div onClick={(e)=>{tabsUrlClick(e,formNames.statusForm)}}className={`form-navigation-item ${currentForm >= 6 ? "complete" : ""}`}>
        //                 7. Status
        //             </div>
        //         </div>
        //         <div className="lease-forms-list">
        //             {currentForm === formNames.occupantsForm &&
        //                 <OccupantsForm
        //                     application={application}
        //                     setApplication={setApplication}
        //                     setCurrentForm={setCurrentForm}
        //                 />
        //             }
        //             {currentForm === formNames.residenceForm &&
        //                 <ResidenceForm
        //                     application={application}
        //                     setApplication={setApplication}
        //                     setCurrentForm={setCurrentForm}
        //                 />
        //             }
        //             {currentForm === formNames.employmentForm &&
        //                 <EmploymentForm
        //                     application={application}
        //                     setApplication={setApplication}
        //                     setCurrentForm={setCurrentForm}
        //                 />
        //             }
        //             {currentForm === formNames.verificationForm &&
        //                 <VerificationForm
        //                     application={application}
        //                     setApplication={setApplication}
        //                     setCurrentForm={setCurrentForm}
        //                 />
        //             }
        //             {currentForm === formNames.historyForm &&
        //                 <HistoryForm
        //                     application={application}
        //                     setApplication={setApplication}
        //                     setCurrentForm={setCurrentForm}
        //                 />
        //             }
        //             {currentForm === formNames.agreementForm &&
        //                 <AgreementForm
        //                     application={application}
        //                     setApplication={setApplication}
        //                     setCurrentForm={setCurrentForm}
        //                 />
        //             }
        //             {currentForm === formNames.statusForm &&
        //                 <RentalStatus
        //                     application={application}
        //                     setApplication={setApplication}
        //                     setCurrentForm={setCurrentForm}
        //                 />
        //             }
        //         </div>
        //     </div>
        // </div>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <h2 className="subHeader" style={{ textAlign: 'center' }}>Rental Application</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {property &&
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            <span style={{ fontSize: '1.17em', fontWeight: 'bold' }}>{property.address.line1}</span>
                            <span style={{ fontSize: '1.17em', fontWeight: 'bold' }}>{`${property.address.city}, ${property.address.state}`}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'flex-end', opacity: 0.5 }}>
                            <span style={{ fontSize: '1.17em' }}>Questions?</span>
                            <span><span style={{ fontSize: '1.17em' }}>Manager:{" "}</span><span style={{ fontSize: '1.17em', fontWeight: 'bold' }}>${property.owner.user.name.first} ${property.owner.user.name.last}</span></span>
                            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                <PhoneIcon color="inherit" /> <a style={{ fontSize: '1.17em', fontWeight: 'bold' }} href={`tel:${property.owner.user.phone.code}${property.owner.user.phone.number}`}>
                                    {`${property.owner.user.phone.code}${property.owner.user.phone.number}`}
                                </a>
                            </div>
                        </div>
                    </div>
                }
            </Grid>
            {currentTab === `/tenant/lease-applications/${params.applicationId}/occupant` &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h3 className="subHeader" style={{ textAlign: 'center' }}>OCCUPANTS</h3>
                </Grid>
            }
            {currentTab === `/tenant/lease-applications/${params.applicationId}/residence` &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h3 className="subHeader" style={{ textAlign: 'center' }}>RESIDENCE</h3>
                </Grid>
            }
            {currentTab === `/tenant/lease-applications/${params.applicationId}/employment` &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h3 className="subHeader" style={{ textAlign: 'center' }}>EMPLOYMENT AND INCOME</h3>
                </Grid>
            }
            {currentTab === `/tenant/lease-applications/${params.applicationId}/verification` &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h3 className="subHeader" style={{ textAlign: 'center' }}>VERIFICATION</h3>
                </Grid>
            }
            {currentTab === `/tenant/lease-applications/${params.applicationId}/history` &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h3 className="subHeader" style={{ textAlign: 'center' }}>HISTORY</h3>
                </Grid>
            }
            {currentTab === `/tenant/lease-applications/${params.applicationId}/agreement` &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h3 className="subHeader" style={{ textAlign: 'center' }}>AGREEMENT</h3>
                </Grid>
            }
            {currentTab === `/tenant/lease-applications/${params.applicationId}/status` &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h3 className="subHeader" style={{ textAlign: 'center' }}>STATUS</h3>
                </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                    style={{ borderBottom: '3px solid #bbdefb', borderColor: "divider" }}
                >
                    <Tabs
                        variant="scrollable"
                        aria-label="basic tabs example"
                        value={currentTab}
                        sx={{
                            '& .css-heg063-MuiTabs-flexContainer': {
                                display: 'flex', justifyContent: 'space-between'
                            },
                            '& .css-k008qs': {
                                display: 'flex', 
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            },
                        }}
                    >
                        <Tab
                            label={<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}><span>Occupants</span>
                                {/* <CheckCircleOutlineIcon color="success" /> */}
                            </span>}
                            value={`/tenant/lease-applications/${params.applicationId}/occupant`}
                            to={`/tenant/lease-applications/${params.applicationId}/occupant`}
                            component={Link}
                        />
                        <Tab
                            label={<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}><span>Residence</span>
                                {/* <CheckCircleOutlineIcon color="success" /> */}
                            </span>}
                            value={`/tenant/lease-applications/${params.applicationId}/residence`}
                            to={`/tenant/lease-applications/${params.applicationId}/residence`}
                            component={Link}
                        />
                        <Tab
                            label={<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}><span>Employment and Income</span>
                                {/* <CheckCircleOutlineIcon color="success" /> */}
                            </span>}
                            value={`/tenant/lease-applications/${params.applicationId}/employment`}
                            to={`/tenant/lease-applications/${params.applicationId}/employment`}
                            component={Link}
                        />
                        <Tab
                            label={<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}><span>Verification</span>
                                {/* <CheckCircleOutlineIcon color="success" /> */}
                            </span>}
                            value={`/tenant/lease-applications/${params.applicationId}/verification`}
                            to={`/tenant/lease-applications/${params.applicationId}/verification`}
                            component={Link}
                        />
                        <Tab
                            label={<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}><span>History</span>
                                {/* <CheckCircleOutlineIcon color="success" /> */}
                            </span>}
                            value={`/tenant/lease-applications/${params.applicationId}/history`}
                            to={`/tenant/lease-applications/${params.applicationId}/history`}
                            component={Link}
                        />
                        <Tab
                            label={<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}><span>Agreement</span>
                                {/* <CheckCircleOutlineIcon color="success" /> */}
                            </span>}
                            value={`/tenant/lease-applications/${params.applicationId}/agreement`}
                            to={`/tenant/lease-applications/${params.applicationId}/agreement`}
                            component={Link}
                        />
                        <Tab
                            label={<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}><span>Status</span>
                                {/* <CheckCircleOutlineIcon color="success" /> */}
                            </span>}
                            value={`/tenant/lease-applications/${params.applicationId}/status`}
                            to={`/tenant/lease-applications/${params.applicationId}/status`}
                            component={Link}
                        />
                    </Tabs>
                </Box>
            </Grid>
        </Grid>
    );
}

export default TenantLeaseApplicationEditPage;