import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { setUser } from "../../redux/slices/userSlice";
import { unsetUser } from "../../redux/slices/userSlice";
import { getCurrentUser, login } from "../../services";
import { Button, TextField, Typography, InputAdornment, Icon, Grid } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import { Requestemailverification } from "../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LoginPage({ loadProfilePicture }) {
  let [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");

  // Password Show & Hide
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError(!validateEmail(emailValue));
    setEmailHelperText(validateEmail(emailValue) ? "" : "Please enter the valid email address");
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setPasswordError(!validatePassword(passwordValue));
    setPasswordHelperText(validatePassword(passwordValue) ? "" : "Password must be at least 8 characters long");
  };

  const validateEmail = (email) => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    // Password validation logic, e.g., minimum length
    return password.length >= 8;
  };

  var config = "";
  const handleLogin = async (e) => {
    e.preventDefault();
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("super-user");

    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailHelperText("Please enter the valid email address");
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError(true);
      setPasswordHelperText("Password must be at least 8 characters long");
      return;
    }

    await login(email, password).then(response => {
      sessionStorage.setItem("authToken", response?.data?.data?.token);
      return getCurrentUser();
    }).then(response => {
      dispatch(setUser(response?.data?.data));
      let responseData = response?.data?.data;
      if (
        responseData?.manager !== undefined &&
        responseData?.email?.verified === true
      ) {
        setTimeout(() => {
          if (searchParams.get("redirectUrl")) {
            navigate(`${searchParams.get("redirectUrl")}`);
            loadProfilePicture();
          }
          else {
            navigate(`/manager/home`);
            loadProfilePicture();
          }
        }, 2000);
        toast.success("Login Successful")
      } else if (
        responseData?.manager === undefined &&
        responseData?.email?.verified === true
      ) {
        setTimeout(() => {
          navigate("/tenant/dashboard");
          loadProfilePicture();
        }, 2000);
        toast.success("Login Successful");
      } else {
        const authToken = sessionStorage.getItem("authToken");
        if (authToken !== undefined) {
          config = {
            headers: { Authorization: `Bearer ${authToken}` },
          };
          Requestemailverification({}, config).then((response) => {
            setTimeout(() => {
              navigate(`/request-email-verification`);
              loadProfilePicture();
            }, 1000);
            toast.success("Login Successful")
          });
        };
      };
    }).catch(err => {
      toast.error("Invalid email or Password")
    });
  }

  const btnDisbleStatus = (mail, pass) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(mail) && pass.length >= 8) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={6} lg={3} sx={{ boxShadow: { xs: 'none', sm: 'none', md: 'none', lg: '0 10px 20px rgba(53, 60, 115, 0.15)' }, borderRadius: '10px', padding: '30px 40px 30px 40px', }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography variant="h4" className="subTitle" sx={{ fontFamily: "Times New Roman", }}>
            Login
          </Typography>
          <TextField
            error={emailError}
            helperText={emailHelperText}
            value={email}
            onChange={(e) => {
              handleEmailChange(e);
            }}
            label="Email"
            placeholder="you@domain.com"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>
                    <EmailIcon />
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          <TextField
            error={passwordError}
            helperText={passwordHelperText}
            value={password}
            onChange={(e) => {
              handlePasswordChange(e);
            }}
            label="password"
            placeholder="Password"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    sx={{ cursor: 'pointer' }}
                    onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            color="warning"
            sx={{ fontSize: 15 }}
            variant="contained"
            fullWidth={true}
            onClick={(e) => { handleLogin(e) }}
            disabled={btnDisbleStatus(email, password)}
          >
            Login
          </Button>
          <div style={{ textAlign: 'center' }}>
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
        </div>
      </Grid>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Grid>
  );
}

export default LoginPage;
