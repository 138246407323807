import { Alert, AlertTitle, Button, TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, updatePassword } from "../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function ResetPassword() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // Query Params
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  // Password Show & Hide
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  // popup message
  const [popupTextMessage, setpopupTextMessage] = useState({
    message: "",
    isSuccess: false
  });

  const handleLogin = (e) => {
    e.preventDefault();

    let error = false;

    if (password === "") {
      setPasswordError("Password cannot be empty");
      error = true;
    } else {
      setPasswordError("");
    }

    if (error) return;

    updatePassword(email, password, token)
      .then((response) => {
        if (response.data) {
          toast.success("Your password has been changed successfully.")
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          setpopupTextMessage({ isSuccess: false });
        }
      }).catch((err) => {
        toast.error(err.response.data.error.message)
      });
  }
  return (
    <div>
      <div className="page login-page">
        <form
          className="login-form"
          onSubmit={handleLogin}
        >
          <Typography variant="h4" className="subTitle">
            Reset Password
          </Typography>
          <TextField
            value={email}
            disabled={true}
            label="Email"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <EmailIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <div className="form-row">
            <div className="form-item">
              <TextField
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                label="password"
                margin="dense"
                variant="outlined"
                fullWidth={true}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {passwordError && <span className="form-item-error">{passwordError}</span>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-item">
              <Button
                type="submit"
                style={{ background: "#ff805d" }}
                variant="contained"
                fullWidth={true}
              >
                Update Password
              </Button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default ResetPassword;
