import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Paper, TextField, Tooltip } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignaturePad from "react-signature-canvas";
import {
    getManagerLeases,
    getPropertyFinal,
    managerMovinspectionitem,
    deletemanagerMovinspectionitem,
    postmanagerMovinspectionitemfile,
    patchManagerMovinspectionitem,
    postManagerMoveinSubmit,
    managerMovinTenanRemarks,
    deleteManagerMoveInImg,
    getApiBaseURL,
    patchManagerMovinspectionArea,
    postManagerMovinspectionArea,
    postManagerServiceRequest,
    updateManagerMoveInInspectionItemServiceRequestInfo
} from "../../../services";
import axios from 'axios';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolTip from "../../../components/ToolTip";
import imageClick from "../../../components/imageView";

function ManagerMoveInLease() {
    const params = useParams();
    const navigate = useNavigate();
    const hiddenFileInput = useRef();
    let sigPad = useRef({});

    const [ip, setIP] = useState('');
    const [timeGet, settimeGet] = useState('');

    const [property, setProperty] = useState(null);
    const [lease, setLease] = useState(null)

    const [tenantsRemarks, settenantsRemarks] = useState("");
    const [managersRemarks, setmanagerRemarks] = useState("");

    const [tenantsignImg, settenantsignImg] = useState("")
    const [mansignImg, setmansignImg] = useState("")

    const [commonMapview, setcommonMapview] = useState([]);

    const [area, setArea] = useState("")

    const [isSigned, setIsSigned] = useState(false);

    const [addAreaName, setAddAreaName] = useState("");
    const [addAreaDesc, setAddAreaDesc] = useState("");
    const [addAreaRemindMovein, setaddAreaRemindMovein] = useState(false);
    const [aarea, setAarea] = useState("")
    const [editid, setEditId] = useState("")

    const [addItemDialog, setaddItemDialog] = useState(false);
    const [editItemDialog, seteditItemDialog] = useState(false);
    const [sendManagerClick, setSendManagerClick] = useState(false);
    const [editAreaDialog, setEditareaDialog] = useState(false);

    const [open2, setOpen2] = useState(false);

    const [fileId, setfileId] = useState(0);

    // loading
    const [loading, setLoading] = useState(true);

    const handleOpen2 = () => setOpen2(true);

    // Common click for add
    const commonAddClick = (e, name) => {
        setaddItemDialog(true);
        setAarea(name);
    }

    const commonAddCloseClick = () => {
        setAarea("");
        setAddAreaName("");
        setAddAreaDesc("");
        setaddAreaRemindMovein(false);
        setaddItemDialog(false);
        pageLoad1();
    }

    const commonAddSaveClick = () => {
        let data = {
            "area": aarea,
            "managerRemarks": addAreaDesc,
            "name": addAreaName,
            "remindOnMoveOut": addAreaRemindMovein,
        }
        managerMovinspectionitem(params.leaseId, data).then((res) => {
            commonAddCloseClick();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Edit
    const editClick = (e, area, id, name, tenremark, manremark, remind) => {
        setEditId(id);
        setAarea(area);
        setAddAreaDesc(manremark);
        setAddAreaName(name);
        setaddAreaRemindMovein(remind);
        seteditItemDialog(true);
    }

    const editCloseClick = () => {
        setAarea("");
        setAddAreaDesc("");
        setAddAreaName("");
        setaddAreaRemindMovein("");
        seteditItemDialog(false);
        pageLoad1();
    }

    const editSaveClick = () => {
        let datas = {
            "area": aarea,
            "managerRemarks": addAreaDesc,
            "name": addAreaName,
            "remindOnMoveOut": addAreaRemindMovein,
        }
        patchManagerMovinspectionitem(params.leaseId, editid, datas).then((res) => {
            editCloseClick();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Delete All Inspection Items
    const [removeAreaDialog, setRemoveAreaDialog] = useState(false);
    const [removeAllIds, setRemoveAllIds] = useState(null);
    const removeAreaDialogOpen = (e, name, ids) => {
        let items = commonMapview[name];
        // check if items exist that are added by Tenant
        let tenantItemExists = items.some(item => item.tenantRemarks && item.tenantFiles?.length);
        if (tenantItemExists) {
            toast.error('Cannot change the area entered by the Tenant.')
            return;
        }
        setRemoveAreaDialog(true);
        setRemoveAllIds(ids);
    }
    const removeAreaDialogClose = () => {
        setRemoveAreaDialog(false);
        setRemoveAllIds(null);
    }
    const commonRemoveAreaClick = async (e) => {
        try {
            for (let id of removeAllIds) {
                await deletemanagerMovinspectionitem(params.leaseId, id).then((res) => { })
            }
            window.location.reload()
        } catch (err) {
            toast.error(err.response.data.error.message);
        }
    }

    // Submit
    const sendDialogOpen = () => {
        setSendManagerClick(true);
    }
    const sendDialogClose = () => {
        setSendManagerClick(false);
    }
    const submitClick = () => {
        let detail = {
            "managerRemarks": managersRemarks,
            "signature": sigPad.getTrimmedCanvas().toDataURL('image/png'),
        }
        postManagerMoveinSubmit(params.leaseId, detail).then((res) => {
            sendDialogClose();
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Delete Move-in Inspection
    const [removeInspectionDialog, setRemoveInspectionDialog] = useState(false);
    const [inspectionId, setInspectionId] = useState("");
    const removeInspectionDialogOpen = (e, id) => {
        setRemoveInspectionDialog(true);
        setInspectionId(id);
    }
    const removeInspectionDialogClose = () => {
        setRemoveInspectionDialog(false);
        setInspectionId("");
    }
    const deleteiconClick = (e) => {
        deletemanagerMovinspectionitem(params.leaseId, inspectionId).then((res) => {
            removeInspectionDialogClose();
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // File Upload
    const handleClick = (e, id) => {
        e.preventDefault()
        setfileId(id)
        hiddenFileInput.current.click();
    };

    const fileUploadHandler = (e, id) => {
        var file = e.target.files[0] || undefined,
            supportedFormats = [
                "image/jpg",
                "image/gif",
                "image/png",
                "image/jpeg",
                "application/pdf",
            ];
        if (file && file.type) {
            if (0 > supportedFormats.indexOf(file.type)) {
            } else {
                let formData = new FormData();
                formData.append("file", e.target.files[0]);
                postmanagerMovinspectionitemfile(params.leaseId, id, formData).then((res) => {
                    pageLoad1();
                }).catch((err) => { toast.error(err.response.data.error.message) })
            }
        }
    }

    // Remove file upload
    const removeImage = (e, fileId, imgId) => {
        deleteManagerMoveInImg(params.leaseId, fileId, imgId).then((res) => {
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    const saveButtonClick = async () => {
        let data = { "managerRemarks": managersRemarks }
        await managerMovinTenanRemarks(params.leaseId, data).then((res) => {
            pageLoad1();
        })
    }

    // Sign Clear
    const clearSig = (e) => {
        e.preventDefault();
        sigPad.clear();
        setIsSigned(false);
    }

    // Add area
    const handleAddArea = () => {
        if (!area) return;
        let existingArea = commonMapview[area];
        if (existingArea) {
            toast.error('There is already an area with that name. Please use another name');
            return;
        }
        let data = {
            area: area
        }
        postManagerMovinspectionArea(params.leaseId, data).then((res) => {
            pageLoad1();
            setArea("");
            setOpen2(false);
        })
    }

    // Edit area
    const [itemId, setItemId] = useState(null);
    const editareaClick = (e, name, id) => {
        let items = commonMapview[name];
        // check if items exist that are added by Tenant
        let tenantItemExists = items.some(item => item.tenantRemarks && item.tenantFiles?.length);
        if (tenantItemExists) {
            toast.error('Cannot change the area entered by the Tenant.')
            return;
        }
        setEditareaDialog(true);
        setAarea(name);
        setItemId(id);
    }

    const editareaCloseClick = () => {
        setEditareaDialog(false);
        setAarea("");
        setItemId(null);
    }

    const editareaSaveClick = async () => {
        try {
            if (!aarea) return;
            let existingArea = commonMapview[aarea];
            if (existingArea) {
                toast.error('There is already an area with that name. Please use another name');
                return;
            }
            for (let id of itemId) {
                let data = { area: aarea }
                await patchManagerMovinspectionArea(params.leaseId, id.id, data).then((res) => { })
            }
            window.location.reload();
        } catch (err) {
            toast.error(err.response.data.error.message);
        }
    }

    var config = "";
    const pageLoad1 = async () => {
        try {
            setLoading(true);

            let det = [];
            det.length = 0;

            await getManagerLeases(params.leaseId).then((res) => {
                let getProp = res?.data?.data;
                setProperty(getProp);
            })

            await getPropertyFinal(params.leaseId).then((res) => {
                let leas = res?.data?.data;

                setLease(leas);

                settenantsRemarks(leas?.moveInDetails?.tenantRemarks);

                setmanagerRemarks(leas?.moveInDetails?.managerRemarks);

                if (leas.moveInDetails.tenantSignature != undefined) {
                    if (leas.moveInDetails.tenantSignature.signatureFile != undefined) {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },
                        };
                        fetch(
                            `${getApiBaseURL()}/manager/leases/${params.leaseId}/move-in/tenant-signature`,
                            config
                        ).then((res) => {
                            return res.blob();
                        }).then((blob) => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement("a");
                                link.href = imageDataUrl;
                                if (
                                    blob.type == "image/png" ||
                                    blob.type == "image/gif" ||
                                    blob.type == "image/jpeg" ||
                                    blob.type == "image/svg" ||
                                    blob.type == "image/jpg"
                                ) {
                                    settenantsignImg(imageDataUrl);
                                }
                            };
                        })
                    }
                }
                if (leas.moveInDetails.managerSignature != undefined) {
                    if (leas.moveInDetails.managerSignature.signatureFile != undefined) {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },
                        };
                        fetch(
                            `${getApiBaseURL()}/manager/leases/${params.leaseId}/move-in/manager-signature`,
                            config
                        ).then((res) => {
                            return res.blob();
                        }).then((blob) => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement("a");
                                link.href = imageDataUrl;
                                if (
                                    blob.type == "image/png" ||
                                    blob.type == "image/gif" ||
                                    blob.type == "image/jpeg" ||
                                    blob.type == "image/svg" ||
                                    blob.type == "image/jpg"
                                ) {
                                    setmansignImg(imageDataUrl);
                                }
                            };
                        })
                    }
                }

                if (leas.moveInDetails.inspectionItems.length != 0) {
                    leas.moveInDetails.inspectionItems.map((e, i) => {
                        det.push({
                            area: e.area,
                            id: e.id,
                            name: e.name,
                            remindOnMoveOut: e.remindOnMoveOut,
                            settlementAmount: e.settlementAmount,
                            tenantRemarks: e.tenantRemarks,
                            managerRemarks: e.managerRemarks,
                            managerFiles: e.managerFiles,
                            settlementFiles: e.settlementFiles,
                            tenantFiles: e.tenantFiles,
                            serviceRequestInfo: e.serviceRequestInfo,
                            status: leas.moveInDetails.status,
                        });
                    });
                    det.sort((a, b) => a.area.localeCompare(b.area));
                    let groupedPeople = groupBy(det, "area");
                    setcommonMapview(groupedPeople);
                }
            })
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        setIP(res?.data?.IPv4);
    }

    useEffect(() => {
        var today = new Date();
        var str = today.toGMTString();
        settimeGet(str)
        getData();
        pageLoad1();
    }, []);

    const createServiceRequest = async (inspectionItem) => {
        if (!inspectionItem?.tenantRemarks && !inspectionItem?.managerRemarks) {
            toast.error("Please Enter Issue Description");
            return;
        }
        let moveInInfo = {
            isCreated: true,
            leaseId: params.leaseId,
            type: 'MoveIn',
            inspectionItemId: inspectionItem?.id,
        }
        let formData = new FormData();
        formData.append("propertyId", property?.id);
        formData.append("title", inspectionItem?.name);
        formData.append("description", inspectionItem?.tenantRemarks || inspectionItem?.managerRemarks || 'None');
        formData.append("priority", 2);
        formData.append("moveInMoveOutInfo", JSON.stringify(moveInInfo));
        await postManagerServiceRequest(formData, `?groupStatus=${"serviceRequest"}`).then((res) => {
            let data = res?.data?.data;
            let serviceRequestInfo = {
                requested: true,
                serviceRequestId: data?.id,
            }
            return updateManagerMoveInInspectionItemServiceRequestInfo(params.leaseId, inspectionItem?.id, serviceRequestInfo);
        }).then((res) => {
            toast.success("Service Request Created For The Issue");
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    const navigateToServiceRequest = (servId) => {
        navigate(`/manager/service-requests/${servId}`);
    }

    const tenantHasSubmitted = (status) => {
        let stat = false;
        let states = [4, 5, 6, 7]
        if (states.includes(status)) return stat = true;
        return stat = false;
    }

    const managerHasSubmitted = (status) => {
        let stat = true;
        let states = [6, 7];
        if (states.includes(status)) return stat = true;
        return stat = false;
    }

    const managerCanEditAreaName = (areaName) => {
        let managerItemExists
        let items = commonMapview[areaName];
        // check if items exist that are added by Tenant
        if (items != undefined)
            managerItemExists = items.some(item => item.tenantRemarks || item.tenantFiles?.length);
        return !managerItemExists
    }

    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "20px" }}>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
                    <p style={{ fontSize: 17 }}>
                        <Link to="/manager/home"> PROPERTIES </Link> / <Link to={`/manager/properties/${property?.id}/edit`}> {property?.address?.line1} {property?.address?.line2} {property?.address?.unitNumber} </Link> / <Link to={`/manager/properties/${property?.id}/lease`}> LEASE </Link> / MOVE IN CHECKLIST
                    </p>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
                    <p style={{ fontSize: 25, fontWeight: "bold" }}>Lease Move In Checklist</p>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <p style={{ fontSize: 21, fontWeight: "bold" }}>Tenant</p>
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{lease?.leaseTenants[0]?.name?.first + " " + lease?.leaseTenants[0]?.name?.last}</p>
                                <ToolTip
                                    phone={lease?.leaseTenants[0]?.email?.id}
                                    email={lease?.leaseTenants[0]?.phone?.code + " " + lease?.leaseTenants[0]?.phone?.number}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6} textAlign={"right"}>
                            <p style={{ fontSize: 21, fontWeight: "bold" }}>Property</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.line1} {property?.address?.line2}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.unitNumber}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.city}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.state} - {property?.address?.zipCode}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <p style={{ fontSize: 21, fontWeight: "bold" }}>Move In Checklist</p>
            </div>

            <div>
                {Object.keys(commonMapview).map((cat, index) => (
                    <Accordion sx={{ mt: 2 }} key={index} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{ flexGrow: 1 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10 }}>
                                    <div>
                                        <p style={{ fontSize: 18, fontWeight: "bold" }}>{cat}</p>
                                    </div>
                                    {tenantHasSubmitted(lease?.moveInDetails?.status) && !managerHasSubmitted(lease?.moveInDetails?.status) ?
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30 }}>
                                            {managerCanEditAreaName(cat) ?
                                                <EditIcon color="primary"
                                                    onClick={(e) => editareaClick(e, cat, commonMapview[cat])} />
                                                : ""}
                                            {managerCanEditAreaName(cat) ?
                                                <DeleteIcon color="error"
                                                    onClick={(e) => removeAreaDialogOpen(e, cat, commonMapview[cat].map(element => element.id))} />
                                                : ""}
                                        </div>
                                        : ""}
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {commonMapview[cat].map((item, i) => (
                                <div style={{ display: 'flex', flexDirection: 'column', borderTop: "1px solid rgba(0,0,0,.12)", paddingTop: "10px", gap: '10px' }} key={i}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <div style={{ flex: '1 1 50%', display: 'flex', flexDirection: 'column' }}>
                                            {item.tenantRemarks || item.tenantFiles.length || item.managerRemarks || item.managerFiles.length ?
                                                <h4>#Issue : {item.name}</h4>
                                                : ""}
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {item.tenantRemarks || item.tenantFiles.length || item.managerRemarks || item.managerFiles.length ?
                                                    <h4 style={{ color: 'green' }}>Tenant Description</h4>
                                                    : ""}
                                                <span>
                                                    {item.tenantRemarks || ""}
                                                </span>
                                            </div>
                                            {item?.tenantFiles.length != 0 ? (
                                                <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                    {item?.tenantFiles.map((j, k) => (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                            <span className="tenant-move-in-attachment-hover-text" onClick={(e) => imageClick(e, `leases/${params.leaseId}/move-in/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                {j?.fileName}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div style={{ flex: '1 1 50%', display: 'flex', flexDirection: 'column' }}>
                                            {tenantHasSubmitted(lease?.moveInDetails?.status) ?
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    {item.tenantRemarks || item.tenantFiles.length || item.managerRemarks || item.managerFiles.length ?
                                                        <h4 className="warnig-move-in-desc">Manager Description</h4>
                                                        : ""}
                                                    <span>
                                                        {item.managerRemarks || ''}
                                                    </span>
                                                    <div style={{ display: "flex", flexFlow: "wrap", boxSizing: "border-box", placeContent: "flex-start", alignItems: "flex-start", gap: "15px", paddingBottom: "10px" }}>
                                                        {item?.managerFiles.length != 0 ? (
                                                            <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                                {item?.managerFiles.map((j, k) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                                        <span className="tenant-move-in-attachment-hover-text" onClick={(e) => imageClick(e, `leases/${params.leaseId}/move-in/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                            {j?.fileName}
                                                                        </span>
                                                                        {!managerHasSubmitted(lease?.moveInDetails?.status) ?
                                                                            <div title="Delete">
                                                                                <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={(e) => { removeImage(e, item?.id, j?.id) }} />
                                                                            </div>
                                                                            : ""}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {item.remindOnMoveOut ?
                                                            <div>
                                                                <h5>This item will be reminded on move out</h5>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                    {item.tenantRemarks || item.tenantFiles.length || item.managerRemarks || item.managerFiles.length ?
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                            {!managerHasSubmitted(lease?.moveInDetails?.status) ?
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px', width: '50%' }}>
                                                    {tenantHasSubmitted(lease?.moveInDetails?.status) ?
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                                                            <EditIcon color="primary"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={(e) =>
                                                                    editClick(
                                                                        e,
                                                                        cat,
                                                                        item?.id,
                                                                        item?.name,
                                                                        item?.tenantRemarks,
                                                                        item?.managerRemarks,
                                                                        item?.remindOnMoveOut
                                                                    )} />
                                                            <label htmlFor="uploadBtn">
                                                                <AttachFileIcon
                                                                    style={{ cursor: "pointer" }}
                                                                    color="primary"
                                                                    onClick={(e) => handleClick(e, item?.id)}
                                                                />
                                                            </label>
                                                            <div>
                                                                <label>
                                                                    <input
                                                                        ref={hiddenFileInput}
                                                                        type="file"
                                                                        id="uploadBtn"
                                                                        className="inputfile"
                                                                        onChange={(e) => fileUploadHandler(e, fileId)}
                                                                    />
                                                                </label>
                                                            </div>
                                                            {!item?.serviceRequestInfo?.requested ?
                                                                <Tooltip title="Create Service Request">
                                                                    <BuildIcon color="primary" sx={{ cursor: 'pointer' }} onClick={() => { createServiceRequest(item) }} />
                                                                </Tooltip>
                                                                : ""}
                                                            {item?.serviceRequestInfo?.requested ?
                                                                <BuildIcon color="success" sx={{ cursor: 'pointer' }} onClick={() => { navigateToServiceRequest(item?.serviceRequestInfo?.serviceRequestId) }} />
                                                                : ""}
                                                        </div>
                                                        : ""}
                                                    {!item.tenantRemarks && !item.tenantFiles.length ?
                                                        <DeleteIcon style={{ cursor: "pointer" }} color="error" onClick={(e) => removeInspectionDialogOpen(e, item?.id)} />
                                                        : ""}
                                                </div>
                                                : ""}
                                        </div>
                                        : ""}
                                </div>
                            ))}
                            {!commonMapview[cat].length ?
                                <div style={{ textAlign: 'center' }}>
                                    There are no issues here
                                </div>
                                : ""}
                        </AccordionDetails>
                        {!managerHasSubmitted(lease?.moveInDetails?.status) ?
                            <div style={{ borderTop: "1px solid rgba(0,0,0,.12)" }}>
                                <Button
                                    color="primary"
                                    onClick={(e) => commonAddClick(e, cat)}
                                    disabled={!tenantHasSubmitted(lease?.moveInDetails?.status)}
                                    sx={{ fontSize: 15, marginLeft: "20px", marginTop: "10px", marginBottom: "10px" }}
                                >
                                    Add Issue
                                </Button>
                            </div>
                            : ""}
                    </Accordion >
                ))}
            </div>

            <div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                {!managerHasSubmitted(lease?.moveInDetails?.status) ?
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ fontSize: 15 }}
                        onClick={handleOpen2}
                        disabled={!tenantHasSubmitted(lease?.moveInDetails?.status)}
                    >ADD AREA
                    </Button>
                    : ""}
            </div >

            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 20, marginTop: 20, marginBottom: 20, alignItems: 'flex-end' }}>
                {lease?.moveInDetails ?
                    <div style={{ flexGrow: 1 }}>
                        <p style={{ fontSize: 18, fontWeight: "bold" }}>Tenant Remarks</p>
                        <TextField
                            disabled={true}
                            id="standard-basic"
                            label="Mention for future records"
                            variant="standard"
                            sx={{ width: 1 }}
                            value={tenantsRemarks}
                            onChange={(e) => { settenantsRemarks(e.target.value) }}
                        />
                    </div>
                    : ""}
                {lease?.moveInDetails && tenantHasSubmitted(lease?.moveInDetails?.status) ?
                    <div style={{ flexGrow: 1 }}>
                        <p style={{ fontSize: 18, fontWeight: "bold" }}>Manager Remarks</p>
                        <TextField
                            id="standard-basic"
                            label="Mention for future records(include keys,remotes and other items that were handed over during move-in)."
                            placeholder="You can mention items handed over to tenant list keys etc.."
                            variant="standard"
                            disabled={managerHasSubmitted(lease?.moveInDetails?.status)}
                            sx={{ width: 1 }}
                            value={managersRemarks}
                            onChange={e => { setmanagerRemarks(e.target.value) }} />
                    </div>
                    : ""}
                {!managerHasSubmitted(lease?.moveInDetails?.status) ?
                    <Button
                        variant="contained"
                        type="submit"
                        color="success"
                        sx={{ fontSize: 15 }}
                        disabled={!tenantHasSubmitted(lease?.moveInDetails?.status)}
                        onClick={(e) => saveButtonClick(e)}
                    >SAVE</Button>
                    : ""}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10, marginTop: 20, marginBottom: 20 }}>
                {lease?.moveInDetails?.tenantSignature ?
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {lease?.moveInDetails?.tenantSignature?.ipAddress != "System" ?
                            <div style={{ marginBottom: '1em' }}>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{lease?.moveInDetails?.tenantSignature?.name}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Tenant</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {lease?.moveInDetails?.tenantSignature?.ipAddress}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{lease?.moveInDetails?.tenantSignature?.time}</p>
                            </div>
                            : ""}
                        {lease?.moveInDetails.tenantSignature ?
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                {tenantsignImg != "" ?
                                    <div className="signboxx">
                                        <img src={tenantsignImg} />
                                    </div>
                                    : ""}
                            </div>
                            : ""}
                        {lease?.moveInDetails?.tenantSignature?.ipAddress == "System" ?
                            <div>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{lease?.leaseTenants[0]?.name?.first + " " + lease?.leaseTenants[0]?.name?.last}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Tenant</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {lease?.moveInDetails?.tenantSignature?.ipAddress}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{lease?.moveInDetails?.tenantSignature?.time}</p>
                            </div>
                            : ""}

                    </div>
                    : ""}
                {lease?.moveInDetails && tenantHasSubmitted(lease?.moveInDetails?.status) ?
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ fontSize: 21, fontWeight: "bold" }}>{lease?.moveInDetails?.managerSignature?.name ? lease?.moveInDetails?.managerSignature?.name : property?.propertyManagers[0]?.manager?.user?.name?.first + " " + property?.propertyManagers[0]?.manager?.user?.name?.last}</p>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>Manager</p>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {lease?.moveInDetails?.managerSignature?.ipAddress ? lease?.moveInDetails?.managerSignature?.ipAddress : ip}</p>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>{lease?.moveInDetails?.managerSignature?.time ? lease?.moveInDetails?.managerSignature?.time : timeGet}</p>
                        {!lease?.moveInDetails?.managerSignature ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ cursor: "pointer" }} className="signboxx">
                                    <SignaturePad penColor='#4285f4'
                                        onEnd={() => { setIsSigned(true) }}
                                        canvasProps={{ width: 300, height: 200, className: 'sig-canvas curSig' }}
                                        ref={(ref) => { sigPad = ref }} /></div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={(e) => clearSig(e)}>Clear</Button>
                                </div >
                            </div>
                            : ""}
                        {lease?.moveInDetails?.managerSignature ?
                            <div>
                                {mansignImg != "" ?
                                    <div className="signboxx">
                                        <img src={mansignImg} />
                                    </div>
                                    : ""}
                            </div>
                            : ""}
                    </div>
                    : ""}
            </div>

            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, justifyContent: "flex-end" }}>
                {!managerHasSubmitted(lease?.moveInDetails?.status) ?
                    <Button
                        variant="contained"
                        color="warning"
                        type="submit"
                        sx={{ fontSize: 15 }}
                        onClick={(e) => sendDialogOpen(e)}
                        disabled={isSigned == true ? false : true}>SUBMIT</Button>
                    : ''}
            </div>

            <Dialog
                maxWidth={"xs"}
                open={addItemDialog}
                onClose={(e) => commonAddCloseClick()}>
                <DialogTitle>
                    Add Inspection Item
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={aarea}
                        disabled={true}
                        onChange={e => { setAarea(e.target.value) }}
                    >
                    </TextField>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        sx={{ mb: 5 }}
                        label="Name"
                        type="text"
                        fullWidth={true}
                        value={addAreaName}
                        onChange={e => { setAddAreaName(e.target.value) }}
                    >
                    </TextField>
                    <TextField
                        label="Description"
                        id="standard-basic"
                        sx={{ mb: 5 }}
                        variant="standard"
                        fullWidth={true}
                        type="text"
                        multiline
                        value={addAreaDesc}
                        onChange={e => { setAddAreaDesc(e.target.value) }}
                    >
                    </TextField>
                    <FormGroup sx={{ mb: 3 }}>
                        <FormControlLabel control={<Checkbox />}
                            checked={addAreaRemindMovein}
                            onChange={() => {
                                setaddAreaRemindMovein(!addAreaRemindMovein)
                            }}
                            label="Remind on Move Out" />
                    </FormGroup>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, flexWrap: 'wrap' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            onClick={(e) => commonAddCloseClick()}
                        >CANCEL</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            onClick={(e) => commonAddSaveClick()}
                        >ADD</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth={"xs"}
                open={editItemDialog}
                onClose={(e) => editCloseClick()}
            >
                <DialogTitle>
                    Edit Inspection Item
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={aarea}
                        disabled={true}
                        onChange={e => { setAarea(e.target.value) }}
                    >
                    </TextField>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={addAreaName}
                        disabled={lease?.moveInDetails?.status === 4 ? true : false}
                        onChange={e => { setAddAreaName(e.target.value) }}
                    >
                    </TextField>
                    <TextField
                        label="Description"
                        id="standard-basic"
                        variant="standard"
                        fullWidth={true}
                        type="text"
                        sx={{ mb: 5 }}
                        multiline
                        rows={3}
                        value={addAreaDesc}
                        onChange={e => { setAddAreaDesc(e.target.value) }}
                    >
                    </TextField>
                    <FormGroup sx={{ mb: 3 }}>
                        <FormControlLabel control={<Checkbox />}
                            checked={addAreaRemindMovein}
                            onChange={() => {
                                setaddAreaRemindMovein(!addAreaRemindMovein)
                            }}
                            label="Remind on Move Out" />
                    </FormGroup>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, flexWrap: 'wrap' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            color="primary"
                            type="submit"
                            onClick={(e) => editCloseClick()}
                        >CANCEL</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            onClick={(e) => editSaveClick()}
                        >ADD</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={open2}
                onClose={() => setOpen2(false)}>
                <DialogTitle>
                    Add Area
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        sx={{ mb: 5 }}
                        type="text"
                        value={area}
                        onChange={e => { setArea(e.target.value) }}
                    >
                    </TextField>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            variant="contained"
                            onClick={handleAddArea}
                        >SAVE AREA
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={editAreaDialog}
                onClose={(e) => editareaCloseClick()}>
                <DialogTitle>
                    Edit Area
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        sx={{ mb: 5 }}
                        type="text"
                        value={aarea}
                        onChange={e => { setAarea(e.target.value) }}
                    >
                    </TextField>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            variant="contained"
                            onClick={(e) => editareaSaveClick()}>
                            SAVE AREA</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={sendManagerClick}
                onClose={(e) => sendDialogClose()}>
                <DialogTitle>
                    You will not be able to modify this after submission! Are you sure you want to submit?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 10 }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={(e) => submitClick(e)}
                        >YES</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="info"
                            onClick={sendDialogClose}
                        >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeAreaDialog}
                onClose={(e) => removeAreaDialogClose()}>
                <DialogTitle>
                    Are you sure you want to remove this area and all its items?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 10 }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={(e) => commonRemoveAreaClick(e)}
                        >YES</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="info"
                            onClick={removeAreaDialogClose}
                        >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeInspectionDialog}
                onClose={(e) => removeInspectionDialogClose()}>
                <DialogTitle>
                    Are you sure you want to remove this item?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 10 }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={(e) => deleteiconClick(e)}
                        >YES</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="info"
                            onClick={removeInspectionDialogClose}
                        >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div >
    );
}

export default ManagerMoveInLease;