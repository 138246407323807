import React, { useState, useEffect } from 'react'
import { CardContent, TextField, Button, FormControl, InputLabel, MenuItem, Select, Box, CircularProgress } from '@mui/material';
import { Link } from "react-router-dom";
import { getLeases, tenantSchedulemoveout, tenantMoveoutForwardEmail, tenantCancelMoveout } from "../../../services";
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { states } from '../../../helpers';


function TenantScheduleMoveOut() {
    const params = useParams();

    const [property, setProperty] = useState([]);
    const [lease, setLease] = useState(null);

    const [date, setDate] = useState(null);
    const [reason, setReason] = useState("");

    const [name, setName] = useState("");
    const [line1, setLine1] = useState("");
    const [line2, setLine2] = useState("");
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [expiry, setexpiry] = useState(null);

    // loader
    const [loading, setLoading] = useState(true);

    const pageLoad = () => {
        setLoading(true);
        getLeases(params.applicationId).then((res) => {
            const leas = res?.data?.data;
            setProperty(leas?.property);
            setLease(leas);
            setexpiry(leas?.currentLeaseRecord?.expiryDate);
            if (leas?.moveOutDetails.length != 0) {
                setDate(leas?.moveOutDetails?.moveOutDate);
                setReason(leas?.moveOutDetails?.reason);
            }
            if (leas?.moveOutDetails?.mailForwardAddress.length != 0) {
                setCity(leas?.moveOutDetails?.mailForwardAddress?.city);
                setName(leas?.moveOutDetails?.mailForwardAddress?.name);
                setLine1(leas?.moveOutDetails?.mailForwardAddress?.line1);
                setLine2(leas?.moveOutDetails?.mailForwardAddress?.line2);
                setState(leas?.moveOutDetails?.mailForwardAddress?.state);
                setPincode(leas?.moveOutDetails?.mailForwardAddress?.zipCode);
            }
            setLoading(false);
        }).catch((err) => {
            console.log(`Error${err}`);
            setLoading(false);
        });
    }

    useEffect(() => {
        pageLoad();
    }, []);

    const schedulemoveoutClick = () => {
        let data = {
            moveOutDate: date,
            reason: reason,
        }
        tenantSchedulemoveout(params.applicationId, data).then((res) => {
            toast.success("Scheduled Move Out Successfully")
            pageLoad();
        }).catch((err) => {
            toast.error(err.response.data.error.message)
        });
    }
    const cancelClick = () => {
        tenantCancelMoveout(params.applicationId).then((res) => {
            toast.success("Cancelled Move Out Successfully")
            setReason("")
            pageLoad();
        }).catch((err) => {
            toast.error(err.response.data.error.message)
        });
    }

    const saveClick = async () => {
        let data = {
            forwardAddressCity: city,
            forwardAddressLine1: line1,
            forwardAddressLine2: line2,
            forwardAddressName: name,
            forwardAddressState: state,
            forwardAddressZipCode: pincode,
        }
        await tenantMoveoutForwardEmail(params.applicationId, data).then((res) => {
            toast.success("Updated Mail Forwarding Address Successfully")
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const status = (moveOut) => {
        let hasMoveOutInitiated = [2, 3, 4, 5];
        if (moveOut?.moveOutDetails) {
            if (hasMoveOutInitiated.includes(moveOut?.moveOutDetails?.status)) {
                return true;
            } else {
                return false;
            }
        }
    }

    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "20px" }}>
            <CardContent style={{ textAlign: "center" }}>
                <p style={{ fontSize: 17 }}>
                    <Link to="/tenant/dashboard"> DASHBOARD </Link> / <Link to={`/tenant/lease-applications/${params.applicationId}/leaseview`}> LEASE </Link> /  MOVE OUT FROM </p>
            </CardContent>
            <CardContent style={{ textAlign: "center", paddingBottom: "0px" }}>
                <h2>Lease Move Out Form</h2>
            </CardContent>
            <CardContent>
                <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap', textAlign: 'right' }}>
                    <span style={{ fontSize: 20, fontWeight: "bold", paddingBottom: '20px' }}>Property</span>
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.line1}</span>
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.line2} {property.unitNumber}</span>
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.city}</span>
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.state} - {property?.address?.zipCode}</span>
                </div>
            </CardContent>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: "200px" }}>
                <CardContent>
                    <p style={{ fontWeight: "bold" }}>When would you like your tenants to move out?</p>
                </CardContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        views={["day", "month", "year"]}
                        disabled={status(lease)}
                        label="Move out Date"
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                        min={expiry}
                        renderInput={(params) => <TextField {...params} fullWidth={true} style={{ width: '50%' }} />} />
                </LocalizationProvider>
                <TextField
                    label="Reason"
                    disabled={status(lease)}
                    id="standard-basic"
                    variant="outlined"
                    style={{ width: '50%' }}
                    type="text"
                    multiline
                    rows={5}
                    value={reason}
                    onChange={(e) => {
                        setReason(e.target.value);
                    }} />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "right", gap: 10, marginTop: 20 }}>
                {!status(lease) ?
                    <Button variant="contained"
                        type="submit"
                        onClick={(e) => schedulemoveoutClick(e)}
                        style={{ fontSize: 14 }}>
                        SCHEDULE MOVE OUT
                    </Button>
                    : ""}
                {status(lease) ?
                    <Button variant="contained"
                        type="submit"
                        color="warning"
                        style={{ fontSize: 14 }}
                        onClick={(e) => cancelClick(e)}
                    >
                        CANCEL MOVE OUT
                    </Button>
                    : ""}
            </div>
            {status(lease) ?
                <div>
                    <div style={{ padding: '20px 0px' }}>
                        <hr />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <p style={{ fontWeight: "bold" }}>Tenant Mail Forwarding Address</p>
                        <TextField
                            label="Name"
                            id="standard-basic"
                            variant="outlined"
                            style={{ width: '50%' }}
                            type="text"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }} />
                        <TextField
                            label="Address Line 1"
                            id="standard-basic"
                            variant="outlined"
                            style={{ width: '50%' }}
                            type="text"
                            value={line1}
                            onChange={(e) => {
                                setLine1(e.target.value);
                            }} />
                        <TextField
                            label="Address Line 2"
                            id="standard-basic"
                            variant="outlined"
                            style={{ width: '50%' }}
                            type="text"
                            value={line2}
                            onChange={(e) => {
                                setLine2(e.target.value);
                            }} />
                        <TextField
                            label="City"
                            id="standard-basic"
                            variant="outlined"
                            style={{ width: '50%' }}
                            type="text"
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value);
                            }} />
                        <FormControl style={{ width: '50%' }}>
                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="State"
                                sx={{ width: 1 }}
                                value={state}
                                onChange={(e) => {
                                    setState(e.target.value);
                                }}>
                                <MenuItem value="-Select-">-Select-</MenuItem>
                                {states.map((state, index) => (
                                    <MenuItem key={index} value={state?.value}>{state?.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Pincode"
                            id="standard-basic"
                            variant="outlined"
                            style={{ width: "50%" }}
                            type="text"
                            value={pincode}
                            onChange={(e) => {
                                setPincode(e.target.value);
                            }} />
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button variant="contained"
                                type="submit"
                                color="success"
                                style={{ fontSize: 15 }}
                                onClick={(e) => saveClick(e)}>
                                SAVE
                            </Button>
                        </div>
                    </div>
                </div>
                : ""}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )

}
export default TenantScheduleMoveOut;