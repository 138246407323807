import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleProperty, overViewtenants } from "../../services";
import TabMenu from "../manager/properties/edit/tabMenu";
import "semantic-ui-css/semantic.min.css";
import { Popup } from "semantic-ui-react";
import { CardContent, TextField, Typography, Rating, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, CircularProgress } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from '@mui/icons-material/Error';

function LeaseApplicationView() {
    const params = useParams();
    const navigate = useNavigate();

    const [leaseView, setleaseView] = useState([]);
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);

    const getProperty = async () => {
        const property = await getSingleProperty(params.propertyId).then((response) => {
            setProperty(response?.data?.data);
        })
    }
    const pageLoad = async () => {
        setLoading(true);
        const overview = await overViewtenants(params.propertyId).then((response) => {
            const responseData = response?.data?.data;
            setleaseView(responseData);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        getProperty();
        pageLoad();
    }, []);

    const creditScoreCalculate = (incomeDetails) => {
        const score = incomeDetails?.creditScore || 0
        if (!incomeDetails || !incomeDetails.creditScore) {
            return <span>Unavailable</span>;
        }
        else if (score > 0 && score < 580) {
            return (
                <Popup
                    trigger={
                        <div style={{ cursor: "pointer", width: 70 }}>
                            <Rating
                                name="creditScoreRating"
                                readOnly
                                style={{ color: "#DC3545" }}
                                value={1}
                                max={3}
                            />
                        </div>}
                    position="top center"
                >
                    <Popup.Content>
                        <Typography sx={{ px: 0, pt: 2 }}>Supplied Credit Rating:</Typography>
                        <Typography sx={{ px: 0, py: 0, fontWeight: 'bold' }}>{score}</Typography>
                        <Typography sx={{ px: 0, py: 2 }}>
                            Note: Please confirm on the credit report. Most of the time,
                            this rating system is correct. However, when the score is a
                            VantageScore, the upper range is 990, so the rating system may
                            overestimate the quality of the score.
                            <br />
                            <br />
                            3 stars = 681+
                            <br />
                            2 stars = 580 to 680
                            <br />
                            1 star = under 580
                        </Typography>
                    </Popup.Content>
                </Popup>
            );
        }
        else if (score >= 580 && score <= 680) {
            return (
                <Popup
                    trigger={
                        <div style={{ cursor: "pointer", width: 70 }}>
                            <Rating
                                name="creditScoreRating"
                                readOnly
                                style={{ color: "#F96B25" }}
                                value={2}
                                max={3}
                            /></div>}
                    position="top center"
                >
                    <Popup.Content>
                        <Typography sx={{ px: 0, pt: 2 }}>Supplied Credit Rating:</Typography>
                        <Typography sx={{ px: 0, py: 0, fontWeight: 'bold' }}>{score}</Typography>
                        <Typography sx={{ px: 0, py: 2 }}>
                            Note: Please confirm on the credit report. Most of the time,
                            this rating system is correct. However, when the score is a
                            VantageScore, the upper range is 990, so the rating system may
                            overestimate the quality of the score.
                            <br />
                            <br />
                            3 stars = 681+
                            <br />
                            2 stars = 580 to 680
                            <br />
                            1 star = under 580
                        </Typography>
                    </Popup.Content>
                </Popup>
            );
        }
        else if (score > 681) {
            return (
                <Popup trigger={
                    <div style={{ cursor: "pointer", width: 70 }}>
                        <Rating
                            name="creditScoreRating"
                            readOnly
                            style={{ color: "#8CC659" }}
                            value={3}
                            max={3}
                        />
                    </div>}
                    position="top center"
                >
                    <Popup.Content>
                        <Typography sx={{ px: 0, pt: 2 }}>Supplied Credit Rating:</Typography>
                        <Typography sx={{ px: 0, py: 0, fontWeight: 'bold' }}>{score}</Typography>
                        <Typography sx={{ px: 0, py: 2 }}>
                            Note: Please confirm on the credit report. Most of the time,
                            this rating system is correct. However, when the score is a
                            VantageScore, the upper range is 990, so the rating system may
                            overestimate the quality of the score.
                            <br />
                            <br />
                            3 stars = 681+
                            <br />
                            2 stars = 580 to 680
                            <br />
                            1 star = under 580
                        </Typography>
                    </Popup.Content>
                </Popup>
            );
        }
    };

    const apllicationOverviewClick = (id) => {
        navigate(`/manager/lease-applications/${id}`)
    }

    const incomeScoreCalcultion = (applicant = {}) => {
        const rentAmount = property?.rentAmount;
        let applicantTotalIncome = 0;
        let incomeScore = 0;
        let combinedApplicantsTotalIncome = 0;
        applicantTotalIncome = applicant.incomeDetails?.monthlyIncome || 0;
        if (applicant.incomeDetails?.hasOtherIncome) {
            applicantTotalIncome =
                applicantTotalIncome +
                applicant.incomeDetails?.otherMonthlyIncome;
        }
        combinedApplicantsTotalIncome += applicantTotalIncome;

        incomeScore = rentAmount / combinedApplicantsTotalIncome;
        if (0.25 >= incomeScore) {
            return (
                <Popup trigger={
                    <div style={{ cursor: "pointer", width: 70 }}>
                        <Rating
                            name="incomeScoreRating"
                            style={{ color: "#8CC659" }}
                            readOnly
                            value={3}
                            max={3}
                        />
                    </div>}
                    position="top center"
                >
                    <Popup.Content>
                        <h2>Income rating</h2>
                        Supplied Income Rating:{" "}
                        <b>{combinedApplicantsTotalIncome.toFixed(2)}</b>
                        <br />
                        A basic rule is that the monthly rent should not be greater than
                        1/3 of the total income.
                        <br />
                        <br />
                        Let's add up the primary applicant and co-applicant monthly
                        gross income and divide it by 3. Then let's compare it to the
                        monthly rent, or:
                        <br />
                        <br />
                        (Primary Applicant Income + Primary Applicant Other Income +
                        Co-Applicant Income + Co-Applicant Other Income) / 3 vs. Monthly
                        Rent
                        <br />
                        <br />
                        <strong
                        >($2,000.00 + $1,000.00 + $0.00 + $0.00)/3 $1,000.00</strong>
                    </Popup.Content>
                </Popup>
            );
        } else if (incomeScore > 0.25 && 0.33 >= incomeScore) {
            return (
                <Popup trigger={
                    <div style={{ cursor: "pointer", width: 70 }}>
                        <Rating
                            name="incomeScoreRating"
                            style={{ color: "#F96B25" }}
                            readOnly
                            value={2}
                            max={3}
                        />
                    </div>}
                    position="top center"
                >
                    <Popup.Content>
                        <h2>Income rating</h2>
                        Supplied Income Rating:{" "}
                        <b>{combinedApplicantsTotalIncome.toFixed(2)}</b>
                        <br />
                        A basic rule is that the monthly rent should not be greater than
                        1/3 of the total income.
                        <br />
                        <br />
                        Let's add up the primary applicant and co-applicant monthly
                        gross income and divide it by 3. Then let's compare it to the
                        monthly rent, or:
                        <br />
                        <br />
                        (Primary Applicant Income + Primary Applicant Other Income +
                        Co-Applicant Income + Co-Applicant Other Income) / 3 vs. Monthly
                        Rent
                        <br />
                        <br />
                        <strong
                        >($2,000.00 + $1,000.00 + $0.00 + $0.00)/3 $1,000.00</strong>
                    </Popup.Content>
                </Popup>
            );
        } else if (incomeScore > 0.33) {
            return (
                <Popup trigger={
                    <div style={{ cursor: "pointer", width: 70 }}>
                        <Rating
                            name="incomeScoreRating"
                            style={{ color: "#DC3545" }}
                            readOnly
                            value={1}
                            max={3}
                        />
                    </div>}
                    position="top center"
                >
                    <Popup.Content>
                        <h2>Income rating</h2>
                        Supplied Income Rating:{" "}
                        <b>{isFinite(incomeScore) ? incomeScore.toFixed(2) : 0}</b>
                        <br />
                        A basic rule is that the monthly rent should not be greater than
                        1/3 of the total income.
                        <br />
                        <br />
                        Let's add up the primary applicant and co-applicant monthly
                        gross income and divide it by 3. Then let's compare it to the
                        monthly rent, or:
                        <br />
                        <br />
                        (Primary Applicant Income + Primary Applicant Other Income +
                        Co-Applicant Income + Co-Applicant Other Income) / 3 vs. Monthly
                        Rent
                        <br />
                        <br />
                        <strong
                        >($2,000.00 + $1,000.00 + $0.00 + $0.00)/3 $1,000.00</strong>
                    </Popup.Content>
                </Popup>
            );
        } else if (!incomeScore) {
            return <span>Unavailable</span>;
        }
    };

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: '30px' }}>
            <p style={{ fontSize: 25, textAlign: "center", fontWeight: "bold" }}>{property?.address?.line1 + ", " + property?.address?.line2 + " " + property?.address?.unitNumber}</p>
            <TabMenu />
            <div style={{ marginTop: '30px' }}>
                <TextField
                    id="standard-basic"
                    sx={{ width: 1 }}
                    variant="standard"
                    label="Search..."
                    type="text"
                />
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell-maxWidth'>No.</TableCell>
                                <TableCell className='tableCell-maxWidth'>Applicants</TableCell>
                                <TableCell className='tableCell-maxWidth'>Income Score</TableCell>
                                <TableCell className='tableCell-maxWidth'>Credit Score</TableCell>
                                <TableCell className='tableCell-maxWidth'>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leaseView.map((lease, index) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    key={index}
                                >
                                    <TableCell className='tableCell-maxWidth'>{index + 1}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>
                                        {lease.applicants.map((applicant, i) => (
                                            <span style={{ display: "flex", flexDirection: 'column' }} key={i}>
                                                <span style={{ display: "flex", flexDirection: 'row', gap: 5, color: '#4a90e2', cursor: 'pointer' }} onClick={() => apllicationOverviewClick(lease.id)}>
                                                    <span>
                                                        {i + 1}{". "}
                                                    </span>
                                                    <span>
                                                        {applicant.firstName + " " + applicant.lastName}
                                                    </span>
                                                    {i == 0 && leaseView && lease.applicants.length > 1 &&
                                                        <span style={{ color: '#6c757d' }}>*Primary applicant</span>
                                                    }
                                                </span>
                                            </span>
                                        ))}
                                    </TableCell>
                                    <TableCell className='tableCell-maxWidth'>
                                        {lease.applicants.map((applicant, i) => (
                                            <div key={i}>
                                                {incomeScoreCalcultion(applicant)}
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell className='tableCell-maxWidth'>
                                        {lease.applicants.map((applicant, i) => (
                                            <div style={{ display: 'flex', flexDirection: "column", gap: 5 }} key={i}>
                                                {creditScoreCalculate(applicant?.incomeDetails)}
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell className='tableCell-maxWidth'>
                                        {lease.applicants.map((applicant, i) => (
                                            <div style={{ display: 'flex', flexDirection: "column", gap: 5 }} key={i}>
                                                {applicant.signature &&
                                                    <span style={{ display: "flex", flexDirection: 'row', gap: 5, alignItems: 'center', color: "#8CC659" }}>
                                                        <span>
                                                            <CheckCircleIcon
                                                                style={{ color: "#8CC659" }}
                                                            />
                                                        </span>
                                                        <span>
                                                            Tenant sign submitted
                                                        </span>
                                                    </span>
                                                }
                                                {!applicant.signature &&
                                                    <span style={{ display: "flex", flexDirection: 'row', gap: 5, alignItems: 'center', color: "#F96B25" }}>
                                                        <span>
                                                            <WarningIcon
                                                                style={{ color: "#F96B25" }}
                                                            />
                                                        </span>
                                                        <span>
                                                            Needs Applicant Signature
                                                        </span>
                                                    </span>
                                                }
                                                {applicant.hasEvictionHistory ? (
                                                    <span style={{ display: "flex", flexDirection: 'row', gap: 5, alignItems: 'center', color: "#DC3545" }}>
                                                        <span>
                                                            <ErrorIcon style={{ color: "#DC3545" }} />
                                                        </span>
                                                        <span>
                                                            Reports of eviction
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <span style={{ display: "flex", flexDirection: 'row', gap: 5, alignItems: 'center', color: "#8CC659" }}>
                                                        <span>
                                                            <CheckCircleIcon style={{ color: "#8CC659" }} />
                                                        </span>
                                                        <span>
                                                            No reports of eviction
                                                        </span>
                                                    </span>
                                                )}
                                                {applicant.hasCriminalHistory ? (
                                                    <span style={{ display: "flex", flexDirection: 'row', gap: 5, alignItems: 'center', color: "#DC3545" }}>
                                                        <span>
                                                            <ErrorIcon style={{ color: "#DC3545" }} />
                                                        </span>
                                                        <span>
                                                            Criminal Charges Reported
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <span style={{ display: "flex", flexDirection: 'row', gap: 5, alignItems: 'center', color: "#8CC659" }}>
                                                        <span>
                                                            <CheckCircleIcon style={{ color: "#8CC659" }} />
                                                        </span>
                                                        <span>
                                                            No Criminal Charges Reported By  Applicant
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody >
                    </Table >
                </TableContainer>
            </div>
        </div >
    );
}

export default LeaseApplicationView