import { Button, CardContent, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import ComplexServiceRequestTabs from "./complexserviceRequestTab";
import { postManagerServiceRequest, getGroupServiceRequestTags, getGroupServiceRequestMembers } from "../../../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from '@mui/icons-material/Add';

const priority = [
    {
        value: "0",
        label: "Low",
    },
    {
        value: "1",
        label: "Medium",
    },
    {
        value: "2",
        label: "High",
    },
];
const ComplexServiceRequests = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [name, setName] = useState("");
    const [prior, setPrior] = useState();
    const [type, setType] = useState();
    const [assi, setAssi] = useState();
    const [images, setImages] = useState([]);

    const [assignee, setAssignee] = useState([]);
    const [suggestedTags, setSuggestedTags] = useState();

    const [skillsRequired, setSkillsRequired] = useState([]);

    useEffect(() => {
        pageLoad();
    }, []);

    const pageLoad = () => {
        getGroupServiceRequestMembers().then((res) => {
            let data = res.data.data;
            let details = [...data];
            let assign = [...assignee];
            assign.length = 0;
            details.map((e, i) => {
                assign.push({
                    "name": e.name,
                    "type": e.type,
                    "id": e.id,
                })
                setAssignee(assign);
            })
        });
        getGroupServiceRequestTags(params.groupId).then((res) => {
            let data = res.data.data;
            setSuggestedTags(data);
        })

    }

    const handleImagesAdd = (e) => {
        setImages([...images, ...e.target.files]);
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSkillsRequired(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const createServiceRequestClick = () => {
        let formData = new FormData();
        formData.append("propertyId", params.groupId);
        formData.append("title", title);
        formData.append("description", desc);
        formData.append("priority", prior);
        formData.append("assignee", JSON.stringify({ "type": type, assignee: assi }));
        formData.append("tags", skillsRequired);
        Array.from(images).forEach(item => { formData.append("attachments", item) });

        postManagerServiceRequest(formData, `?groupStatus=${"PropertyGroupServiceRequest"}&groupId=${params.groupId}`).then((res) => {
            setImages([]);
            navigate(`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group`)
            toast.success(`Complex service request created Successfully`)
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const renderImages = () => {
        return Array.from(images).map((item, index) => {
            const url = URL.createObjectURL(item)
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={url} alt="images" style={{ height: "110px", width: "175px", marginTop: "20px" }} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }}>
                            <DeleteIcon color="error" />
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const selectClick = (e, name, id) => {
        setType(name);
        setAssi(id);
    }
    return (
        <>
            <CardContent>
                <CardContent sx={{ pt: 0 }}>
                    <ComplexServiceRequestTabs active={"property"} />
                </CardContent>
                <CardContent sx={{display:'flex',justifyContent:'flex-end'}}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ fontSize: 15, borderRadius: 20, background: '#3f48cc' }}
                        onClick={() => { navigate(`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group/re-occuring`) }}
                    ><AddIcon />{" "}RE-OCCURING SERVICE REQUEST</Button>
                </CardContent>
                <CardContent>
                    <p style={{ fontSize: 19, fontWeight: "bold" }}>Create Complex Service Request</p>
                </CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} sx={{ borderRadius: '5px' }}>

                            <CardContent>
                                <TextField
                                    fullWidth={true}
                                    variant="outlined"
                                    label="Title *"
                                    type="text"
                                    value={title}
                                    onChange={e => { setTitle(e.target.value) }}
                                />
                            </CardContent>
                            <CardContent>
                                <TextField
                                    fullWidth={true}
                                    variant="outlined"
                                    label="Details *"
                                    type="text"
                                    rows={5}
                                    multiline
                                    value={desc}
                                    onChange={e => { setDesc(e.target.value) }}
                                />
                            </CardContent>
                            <CardContent>
                                <div className="container">
                                    {renderImages()}
                                    <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
                                        <input type="file" id="uploadBtn" accept="image/*"
                                            onChange={handleImagesAdd}
                                            multiple
                                        />
                                        <label htmlFor="uploadBtn" style={{ fontSize: 13 }}>
                                            Drop images / click to Upload</label>
                                    </div>
                                </div>
                            </CardContent>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ borderRadius: '5px' }}>
                            <CardContent>
                                <FormControl sx={{ width: 1 }}>
                                    <TextField
                                        sx={{ width: 1 }}
                                        label="Choose Assignee"
                                        select
                                        margin="dense"
                                        variant="outlined"
                                        value={name}
                                        onChange={e => { setName(e.target.value) }}
                                    >
                                        {assignee.map((details, i) => (
                                            <MenuItem key={i} value={details.name} onClick={(e) => selectClick(e, details.type, details.id)}>
                                                {details.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </CardContent>
                            <CardContent>
                                <FormControl sx={{ width: 1 }}>
                                    <TextField
                                        sx={{ width: 1 }}
                                        label="Chosse Priority level"
                                        select
                                        margin="dense"
                                        variant="outlined"
                                        value={prior}
                                        onChange={(e) => {
                                            setPrior(e.target.value);
                                        }}
                                    >
                                        {priority.map((pri) => (
                                            <MenuItem key={pri.value} value={pri.value}>
                                                {pri.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </CardContent>
                            <FormControl sx={{ width: 1 }}>
                                <InputLabel id="demo-multiple-chip-label">Skills Required</InputLabel>
                                <Select
                                    sx={{ width: 1 }}
                                    variant="standard"
                                    label="Skills Required"
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={skillsRequired}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {suggestedTags && suggestedTags ? suggestedTags.map((e, i) => (
                                        <MenuItem key={i} value={e}>
                                            {e}
                                        </MenuItem>
                                    )) : undefined}
                                </Select>
                            </FormControl>
                            <p style={{ fontSize: 13, color: "grey" }}>Click Enter or add " , " after entering tag</p>
                        </Grid>
                    </Grid>
                </Box>
                <CardContent style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <CardContent></CardContent>
                    <CardContent>
                        <Button variant="contained" color="success"
                            type="submit"
                            style={{ fontSize: 15 }}
                            onClick={createServiceRequestClick}
                        >CREATE COMPLEX SERVICE REQUEST</Button>
                    </CardContent>
                </CardContent>
            </CardContent>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}

export default ComplexServiceRequests;
