import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CircularProgress } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllSrAndCsr, getContractorById } from "../../../services";
import ToolTip from "../../../components/ToolTip";
import moment from "moment";
import HomeIcon from "@mui/icons-material/Home";

function ContractorServiceRequestDashboard() {
  const params = useParams();
  const navigate = useNavigate();
  const date = new Date();

  const [csrAndSr, setCsrAndSr] = useState(null);
  const [commonMapview, setcommonMapview] = useState([]);
  const [contractor, setContractor] = useState(null);

  // loading
  const [loading, setLoading] = useState(true);

  const getAllServiceRequests = async () => {
    try {
      setLoading(true);
      await getContractorById(params.ContractorId, params.serviceId).then((res) => {
        let data = res.data.data;
        setContractor(data);
      })
      await getAllSrAndCsr(params.ContractorId, params.serviceId).then((res) => {
        let data = res.data.data;
        setCsrAndSr(data);
        let groupedPeople = groupBy(data?.contractorAssigned_GSR, "groupId");
        setcommonMapview(groupedPeople);
      })
    } finally {
      setLoading(false);
    }
  }

  function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  useEffect(() => {
    getAllServiceRequests();
  }, []);

  const getNoOfServiceRequests = (serviceRequest) => {
    let srLength = 0;
    let csrLength = 0;
    let total = 0;
    if (serviceRequest?.contractorAssigned_SR) {
      srLength = serviceRequest?.contractorAssigned_SR.length;
    }
    if (serviceRequest?.contractorAssigned_GSR) {
      csrLength = serviceRequest?.contractorAssigned_GSR.length;
    }
    total = srLength + csrLength;
    return total;
  }

  const getServiceRequestTitleColor = (status) => {
    if (status === 0) {
      return 'rgb(11, 119, 11)';
    }
    if (status === 1) {
      return '#F2994A';
    }
    if (status === 2) {
      return '#DC3545';
    }
  }

  const complexServiceRequestsClick = (manId, grpId, id) => {
    navigate(`/manager/${manId}/property-group/${grpId}/service-requests/${id}`)
  }

  const serviceRequestsClick = (id) => {
    navigate(`/manager/service-requests/${id}`)
  }

  // Loader
  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <CardContent style={{ textAlign: "center" }}>
        <span style={{ fontSize: 17, fontWeight: "bold" }}>Welcome! {contractor?.name?.first + " " + contractor?.name?.last}</span>
      </CardContent>
      <CardContent style={{ textAlign: "center" }}>
        <span style={{ fontSize: 18, fontWeight: "bold" }}>You have</span> <span style={{ fontSize: 25, fontWeight: "bold", color: "#DC3545" }}>{getNoOfServiceRequests(csrAndSr)}</span> <span style={{ fontSize: 18, fontWeight: "bold" }}>Assigned Service Requests</span>
      </CardContent>
      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", paddingTop: "0px" }}>
        <CardContent style={{ width: "100%", maxWidth: "1350px", p: 0, flexGrow: 1, paddingTop: "0px" }}>
          {Object.keys(commonMapview).map((cat, index) => (
            <CardContent key={index}>
              {commonMapview[cat].map((group, i) => (
                <CardContent sx={{ outline: "3px solid #DC3545", borderRadius: 1, backgroundColor: "#FFF", padding: " 40px 15px 15px" }} key={i}>
                  <Accordion style={{ backgroundColor: "#FFF", padding: " 30px 10px 10px", boxShadow: "0 0 10px #D3D3D3" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: 10 }}>
                      <CardContent sx={{ flexGrow: 1, p: 0 }}>
                        <p className="property-head-paragraph">
                          Complex
                        </p>
                        <p className="property-address">{group.groupName}</p>
                      </CardContent>
                      <CardContent sx={{ flexGrow: 1, p: 0 }}>
                        <p className="property-head-paragraph">
                          Manager
                        </p>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 10 }}>
                          <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{group?.groupManagerName}</p>
                          <ToolTip phone={group?.groupManagerPhone} email={group?.groupManagerEmail} />
                        </div>
                      </CardContent>
                      <CardContent sx={{ flexGrow: 1, p: 0 }}>
                        <p className="property-head-paragraph">
                          Complex Service Request
                        </p>
                        <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                          {group.csrDetails &&
                            <div>
                              {group?.csrDetails.map((serv, i) => (
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}
                                  onClick={() => { complexServiceRequestsClick(group?.groupManagerId, group?.groupId, serv?.srId) }}
                                  key={i}>
                                  <div style={{ color: getServiceRequestTitleColor(serv.priority), cursor: "pointer", fontWeight: "bold" }}>{i + 1}.{" "}{`(CSR#${serv?.numberId})`}{serv?.title}</div>
                                  {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 15 &&
                                    <div className="property-service-date-badge-cond-green" title={Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) + " " + "days old"}>{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                  {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 30 && Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 15 &&
                                    <div className="property-service-date-badge-cond-yellow" title={Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) + " " + "days old"}>{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                  {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 31 &&
                                    <div className="property-service-date-badge-cond-red" title={Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) + " " + "days old"}>{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                </div>
                              ))}
                            </div>
                          }
                        </div>
                      </CardContent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Card>
                        {csrAndSr?.contractorAssigned_SR.map((item, index) => (
                          <CardContent key={index}>
                            {cat == item?.groupId ?
                              <CardContent sx={{ boxShadow: "0 0 10px #888888", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", outline: "3px solid #DC3545", borderRadius: 1 }}>
                                <CardContent sx={{ borderRight: "1px solid black", flexGrow: 1, backgroundColor: 'rgb(245, 245, 245)' }}>
                                  <p className="property-paragraph">Property</p>
                                  <p className="property-address">{item?.propertyAddress?.line1 + ` ` + item?.propertyAddress?.line2 + ` ` + item?.propertyAddress?.unitNumber}</p>
                                  <p className="property-address">{item?.propertyAddress?.city}</p>
                                  <p className="property-address">{item?.propertyAddress?.state + ` ` + item?.propertyAddress?.zipCode}</p>
                                </CardContent>
                                <CardContent sx={{ borderRight: "1px solid black", flexGrow: 1, backgroundColor: 'rgb(245, 245, 245)' }}>
                                  <p className="property-paragraph">Tenants</p>
                                  {item?.leaseTenants ? (
                                    item?.leaseTenants.map((lease, i) => (
                                      <div style={{ display: "flex", flexDirection: "row", gap: 10, flexWrap: 'wrap' }} key={i}>
                                        <span style={{ marginBottom: "0px", fontWeight: "bold", display: "flex", flexDirection: "row", flexWrap: "wrap", cursor: 'pointer' }}>
                                          <span><HomeIcon style={{ color: "#28A745", fontSize: "1.5rem", marginRight: 10 }} /></span>
                                          <span>{lease?.name?.first}{" "}{lease?.name?.last}</span>
                                        </span>
                                        <ToolTip phone={lease?.phone?.code + " " + lease?.phone?.number} email={lease?.email?.id} />
                                      </div>))
                                  ) : (
                                    <div>
                                      <p style={{ color: "#F2994A", fontWeight: "bold" }}>No Active Tenants</p>
                                    </div>)}
                                </CardContent>
                                <CardContent sx={{ flexGrow: 1, backgroundColor: 'rgb(245, 245, 245)' }}>
                                  <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                                      <p className="property-paragraph">Service Requests</p>
                                    </div>
                                  </div>
                                  {item?.srDetails.map((serv, i) => (
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }} key={i} onClick={() => { serviceRequestsClick(serv?.srId) }}>
                                      <div style={{ color: getServiceRequestTitleColor(serv.priority), cursor: "pointer", fontWeight: "bold" }}>{i + 1}.{" "}{`(SR#${serv?.numberId})`}{serv?.title}</div>
                                      {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 15 &&
                                        <div className="property-service-date-badge-cond-green" title={Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) + " " + "days old"}>{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                      {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 30 && Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 15 &&
                                        <div className="property-service-date-badge-cond-yellow" title={Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) + " " + "days old"}>{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                      {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 31 &&
                                        <div className="property-service-date-badge-cond-red" title={Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) + " " + "days old"}>{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                    </div>
                                  ))}
                                </CardContent>
                              </CardContent>
                              : ""}
                          </CardContent>
                        ))}
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
              ))}
            </CardContent>
          ))}
        </CardContent>
      </div>
    </div>
  )
}

export default ContractorServiceRequestDashboard