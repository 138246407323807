import { Tabs, Tab, Box, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams } from 'react-router-dom';
import { getgroupedPropertyGroups } from "../../../../services";

function ComplexServiceRequestTabs({ serviceRequestId }) {
    const params = useParams();
    const location = useLocation();
    const currentTab = location.pathname;
    const [propertyGroups, setPropertyGroups] = useState([]);

    useEffect(() => {
        pageLoad();
    }, []);

    const pageLoad = () => {
        let grps = [...propertyGroups];
        grps.length = 0;
        getgroupedPropertyGroups(params.groupId).then((res) => {
            let data = res.data;
            let getDetails = { ...data }
            getDetails.data.map((group) => {
                if (group?.id === params.groupId) {
                    grps.push({
                        "id": group?.id,
                        "name": group?.name,
                        "properties": group?.properties,
                    })
                }
                setPropertyGroups(grps);
            })
        })
    }

    return (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {propertyGroups.map((group, index) => (
                <CardContent sx={{ pt: 0 }} key={index}>
                    <p className='paragraph'>{group?.name}</p>
                </CardContent>))}
            <Tabs
                variant="scrollable"
                aria-label="basic tabs example"
                value={currentTab}
            >
                <Tab
                    label={<h5 style={{ margin: "0px 30px" }}>EDIT COMPLEX</h5>}
                    value={`/manager/${params.managerId}/property-group/${params.groupId}/edit`}
                    to={`/manager/${params.managerId}/property-group/${params.groupId}/edit`}
                    component={Link}
                />
                {currentTab === `/manager/${params.managerId}/property-group/${params.groupId}/service-requests/${serviceRequestId}` ?
                    <Tab
                        label={<h5 style={{ margin: "0px" }}>COMPLEX SERVICE REQUESTS</h5>}
                        value={`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/${serviceRequestId}`}
                        to={`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group`}
                        component={Link}
                    />
                    : ""}
                {currentTab === `/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group/re-occuring` ?
                    <Tab
                        label={<h5 style={{ margin: "0px" }}>COMPLEX SERVICE REQUESTS</h5>}
                        value={`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group/re-occuring`}
                        to={`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group`}
                        component={Link}
                    />
                    : ""}
                {currentTab === `/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group` ?
                    <Tab
                        label={<h5 style={{ margin: "0px" }}>COMPLEX SERVICE REQUESTS</h5>}
                        value={`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group`}
                        to={`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group`}
                        component={Link}
                    />
                    : ""}
                {currentTab === `/manager/${params.managerId}/property-group/${params.groupId}/serviceRequest/group/new` ||
                    currentTab === `/manager/${params.managerId}/property-group/${params.groupId}/edit` ||
                    currentTab === `/manager/${params.managerId}/property-group/${params.groupId}/group-finances` ?
                    <Tab
                        label={<h5 style={{ margin: "0px" }}>COMPLEX SERVICE REQUESTS</h5>}
                        value={`/manager/${params.managerId}/property-group/${params.groupId}/serviceRequest/group/new`}
                        to={`/manager/${params.managerId}/property-group/${params.groupId}/service-requests/group`}
                        component={Link}
                    />
                    : ""}
                <Tab
                    label={<h5 style={{ margin: "0px" }}>COMPLEX FINANCIALS</h5>}
                    value={`/manager/${params.managerId}/property-group/${params.groupId}/group-finances`}
                    to={`/manager/${params.managerId}/property-group/${params.groupId}/group-finances`}
                    component={Link}
                />
            </Tabs>
        </Box>
    );
}

export default ComplexServiceRequestTabs;