import { Button, Card, CardContent, Dialog, FormControl, Icon, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postuserEmailStatus, postManagerLeases, getSingleProperty } from '../../../../services';


const timePeriod = [
    {
        value: "86400",
        label: "Per Day",
    },
    {
        value: "604800",
        label: "Per Week",
    },
    {
        value: "2592000",
        label: "Per Month",
    },
];

const LeaseAdd = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [property, setProperty] = useState(null);
    // const [miscellaneousRent, setMiscellaneousRent] = useState("");
    const [attachment, setAttachment] = useState(null);
    const [condition, setCondition] = useState(false);
    const [emailStatusCondition, setEmailStatusCondition] = useState(false);

    const [formValues, setFormValues] = useState({
        securityDeposit: "",
        securityDepositError: false,
        rentAmount: "",
        rentAmountError: false,
        lateFees: "",
        lateFeesError: false,
        lateFeesTimePeriod: "",
        lateFeesTimePeriodError: false,
        gracePeriod: "",
        gracePeriodError: false,
        tenant1First: "",
        tenant1FirstError: false,
        tenant1Last: "",
        tenant1LastError: false,
        tenant1Phone: "",
        tenant1PhoneError: "",
        tenant1Email: "",
        tenant1EmailError: "",
        tenant2First: "",
        tenant2FirstError: false,
        tenant2Last: "",
        tenant2LastError: false,
        tenant2Phone: "",
        tenant2PhoneError: "",
        tenant2Email: "",
        tenant2EmailError: "",
    })

    const [dateValues, setDateValues] = useState({
        startDate: null,
        startDateError: false,
        expiryDate: null,
        expiryDateError: false,
    })

    const handleDateChange = (e, name) => {
        let error = "";
        switch (name) {
            case "startDate":
                error = e == null ? true : false;
                break;
            case "expiryDate":
                error = e == null ? true : false;
                break;
            default:
                break;
        }
        setDateValues({
            ...dateValues,
            [name]: e,
            [`${name}Error`]: error,
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let error = "";
        switch (name) {
            case "securityDeposit":
                error = value == "" ? true : false;
                break;
            case "rentAmount":
                error = value == "" ? true : false;
                break;
            case "lateFees":
                error = value == "" ? true : false;
                break;
            case "lateFeesTimePeriod":
                error = value == "" ? true : false;
                break;
            case "gracePeriod":
                error = value == "" ? true : false;
                break;
            case "tenant1First":
                error = value == "" ? true : false;
                break;
            case "tenant1Last":
                error = value == "" ? true : false;
                break;
            case "tenant1Phone":
                error = value.length < 14 ? "Please enter valid phone number" : "";
                break;
            case "tenant1Email":
                error = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Please enter a valid Email" : "";
                break;
            case "tenant2First":
                error = value == "" ? true : false;
                break;
            case "tenant2Last":
                error = value == "" ? true : false;
                break;
            case "tenant2Phone":
                error = value.length < 14 ? "Please enter valid phone number" : "";
                break;
            case "tenant2Email":
                error = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Please enter a valid Email" : "";
                break;
            default:
                break;
        }
        setFormValues({
            ...formValues,
            [name]: name == "tenant1Phone" || name == "tenant2Phone" ? formatPhoneNumber(value) : value,
            [`${name}Error`]: error,
        })
    }

    useEffect(() => {
        pageLoad();
    }, []);

    const pageLoad = () => {
        getSingleProperty(params.propertyId).then((res) => {
            let data = res.data.data;
            setProperty(data);
        })
    }
    const addAttachment = (e) => {
        setAttachment(e.target.files[0]);
    }

    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )
            } - ${phoneNumber.slice(6, 10)}`;
    }

    const addLeaseClick = () => {
        let data = {
            emailId: formValues.tenant1Email,
        }
        postuserEmailStatus(data).then((res) => {
            let data = res.data.data;

            if (data === true) {
                setEmailStatusCondition(true);
            }
            if (data === false) {
                let formData = new FormData();
                formData.append("propertyId", params.propertyId);
                formData.append("beginDate", dateValues.startDate);
                formData.append("expiryDate", dateValues.expiryDate);
                formData.append("tenant1FirstName", formValues.tenant1First);
                formData.append("tenant1LastName", formValues.tenant1Last);
                formData.append("tenant1PhoneCode", "+1");
                formData.append("tenant1PhoneNumber", formValues.tenant1Phone);
                formData.append("tenant1Email", formValues.tenant1Email);
                formData.append("agreementFile", attachment);
                formData.append("securityDepositAmount", parseInt(formValues.securityDeposit));
                formData.append("rentAmount", parseInt(formValues.rentAmount));
                formData.append("rentLateFeesRateAmount", parseInt(formValues.lateFees));
                formData.append("rentLateFeesDuration", parseInt(formValues.lateFeesTimePeriod));
                formData.append("rentLateFeesGraceDuration", parseInt(formValues.gracePeriod) * 86400);
                if (condition) {
                    formData.append("tenant2FirstName", formValues.tenant2First);
                    formData.append("tenant2LastName", formValues.tenant2Last);
                    formData.append("tenant2PhoneCode", "+1");
                    formData.append("tenant2PhoneNumber", formValues.tenant2Phone);
                    formData.append("tenant2Email", formValues.tenant2Email);
                }
                postManagerLeases(formData).then((res) => {
                    navigate(`/manager/properties/${params.propertyId}/lease`);
                }).catch((err) => console.log(`Error${err}`));
            }
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const isLeaseButtonDisabled = (dateValues, formValues, attachment) => {
        if (!condition) {
            // For single tenant condition
            return !(dateValues.startDate !== null &&
                dateValues.expiryDate !== null &&
                formValues.securityDeposit !== "" &&
                formValues.rentAmount !== "" &&
                formValues.lateFees !== "" &&
                formValues.lateFeesTimePeriod !== "" &&
                formValues.gracePeriod !== "" &&
                formValues.tenant1First !== "" &&
                formValues.tenant1Last !== "" &&
                formValues.tenant1Phone !== "" &&
                formValues.tenant1Email !== "" &&
                attachment !== null);
        } else {
            // For multiple tenants condition
            return !(dateValues.startDate !== null &&
                dateValues.expiryDate !== null &&
                formValues.securityDeposit !== "" &&
                formValues.rentAmount !== "" &&
                formValues.lateFees !== "" &&
                formValues.lateFeesTimePeriod !== "" &&
                formValues.gracePeriod !== "" &&
                formValues.tenant1First !== "" &&
                formValues.tenant1Last !== "" &&
                formValues.tenant1Phone !== "" &&
                formValues.tenant1Email !== "" &&
                formValues.tenant2First !== "" &&
                formValues.tenant2Last !== "" &&
                formValues.tenant2Phone !== "" &&
                formValues.tenant2Email !== "" &&
                attachment !== null);
        }
    }    

    const addCoTenant = () => {
        setCondition(!condition);
        setFormValues(prevState => ({
            ...prevState,
            tenant2First: "",
            tenant2FirstError: false,
            tenant2Last: "",
            tenant2LastError: false,
            tenant2Phone: "",
            tenant2PhoneError: "",
            tenant2Email: "",
            tenant2EmailError: "",
        }));
    }
    return (
        <div>
            <CardContent>
                <p style={{ fontSize: 22, textAlign: "center", fontWeight: "bolder" }}>{property && property.address.line1 ? property.address.line1 : null}</p>
            </CardContent>
            <CardContent sx={{ pb: 0 }}>
                <p style={{ fontSize: 16, fontWeight: "bolder" }}>Term</p>
            </CardContent>
            <CardContent sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Date *"
                            value={dateValues.startDate}
                            onChange={(newValue) => {
                                handleDateChange(newValue, "startDate");
                            }}
                            renderInput={(params) => <TextField  {...params}
                                error={dateValues.startDateError && dateValues.startDateError} />} />
                    </LocalizationProvider>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Expiry Date *"
                            value={dateValues.expiryDate}
                            onChange={(newValue) => {
                                handleDateChange(newValue, "expiryDate");
                            }}
                            renderInput={(params) => <TextField {...params}
                                error={dateValues.expiryDateError && dateValues.expiryDateError}
                            />} />
                    </LocalizationProvider>
                </div>
            </CardContent>
            <CardContent sx={{ pb: 0 }}>
                <p style={{ fontSize: 16, fontWeight: "bolder" }}>Finance</p>
            </CardContent>
            <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px", alignItems: "stretch" }}>
                <div>
                    <TextField
                        label="Security Deposit *"
                        placeholder='USD'
                        name="securityDeposit"
                        type="number"
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        value={formValues.securityDeposit}
                        error={formValues.securityDepositError && formValues.securityDepositError}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon sx={{ color: "black" }}>
                                        $
                                    </Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div>
                    <TextField
                        label="Rent Amount *"
                        placeholder='USD'
                        name="rentAmount"
                        type="number"
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        value={formValues.rentAmount}
                        error={formValues.rentAmountError && formValues.rentAmountError}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon sx={{ color: "black" }}>
                                        $
                                    </Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                {/* <div>
                    <TextField
                        label="Miscelleneous Rent *"
                        placeholder='USD'
                        type="number"
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        value={miscellaneousRent}
                        error={miscellaneousRent === ""}
                        onChange={(e) => { setMiscellaneousRent(e.target.value) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon sx={{ color: "black" }}>
                                        $
                                    </Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div> */}
            </CardContent>
            <CardContent sx={{ pb: 0 }}>
                <p style={{ fontSize: 16, fontWeight: "bolder" }}>Rent Late Fees</p>
            </CardContent>
            <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px" }}>
                <div>
                    <TextField
                        label="Late Fees *"
                        name="lateFees"
                        placeholder='USD'
                        type="number"
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        value={formValues.lateFees}
                        onChange={handleChange}
                        error={formValues.lateFeesError && formValues.lateFeesError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon sx={{ color: "black" }}>
                                        $
                                    </Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div style={{ flexGrow: 0.5, marginRight: "10px" }}>
                    <FormControl fullWidth={true} sx={{ mx: 1 }}>
                        <TextField
                            fullWidth={true}
                            label="Late Fees Time Period *"
                            name="lateFeesTimePeriod"
                            select
                            margin="dense"
                            variant="outlined"
                            error={formValues.lateFeesTimePeriodError && formValues.lateFeesTimePeriodError}
                            value={formValues.lateFeesTimePeriod}
                            onChange={handleChange}
                        >
                            {timePeriod.map((type) => (
                                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>))}
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <TextField
                        label="Grace Period *"
                        type="number"
                        name="gracePeriod"
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        value={formValues.gracePeriod}
                        error={formValues.gracePeriodError && formValues.gracePeriodError}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon sx={{ color: "black" }}>
                                        $
                                    </Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </CardContent>
            <CardContent sx={{ pb: 0 }}>
                <p style={{ fontSize: 16, fontWeight: "bolder" }}>Tenants</p>
            </CardContent>
            <CardContent>
                <Card sx={{ maxWidth: "400px" }}>
                    <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                            <div>
                                <TextField
                                    label="First Name *"
                                    name="tenant1First"
                                    margin="dense"
                                    variant="standard"
                                    fullWidth={true}
                                    value={formValues.tenant1First}
                                    onChange={handleChange}
                                    error={formValues.tenant1FirstError && formValues.tenant1FirstError}
                                />
                            </div>
                            <div>
                                <TextField
                                    label="Last Name *"
                                    margin="dense"
                                    name="tenant1Last"
                                    variant="standard"
                                    fullWidth={true}
                                    value={formValues.tenant1Last}
                                    onChange={handleChange}
                                    error={formValues.tenant1LastError && formValues.tenant1LastError}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                            <div>
                                <TextField
                                    label="Phone Number *"
                                    margin="dense"
                                    name="tenant1Phone"
                                    variant="standard"
                                    fullWidth={true}
                                    value={formValues.tenant1Phone}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon sx={{ color: "black", fontSize: 15 }}>
                                                    +1
                                                </Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={formValues.tenant1PhoneError && formValues.tenant1PhoneError}
                                    helperText={formValues.tenant1PhoneError && formValues.tenant1PhoneError}
                                />
                            </div>
                            <div>
                                <TextField
                                    label="Email *"
                                    margin="dense"
                                    name="tenant1Email"
                                    variant="standard"
                                    fullWidth={true}
                                    value={formValues.tenant1Email}
                                    onChange={handleChange}
                                    error={formValues.tenant1EmailError && formValues.tenant1EmailError}
                                    helperText={formValues.tenant1EmailError && formValues.tenant1EmailError}
                                />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </CardContent>
            {condition ?
                <CardContent>
                    <Card sx={{ maxWidth: "400px" }}>
                        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                                <div>
                                    <TextField
                                        label="First Name *"
                                        name="tenant2First"
                                        margin="dense"
                                        variant="standard"
                                        fullWidth={true}
                                        value={formValues.tenant2First}
                                        onChange={handleChange}
                                        error={formValues.tenant2FirstError && formValues.tenant2FirstError}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="Last Name *"
                                        name="tenant2Last"
                                        margin="dense"
                                        variant="standard"
                                        fullWidth={true}
                                        value={formValues.tenant2Last}
                                        onChange={handleChange}
                                        error={formValues.tenant2LastError && formValues.tenant2LastError}
                                    />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                                <div>
                                    <TextField
                                        label="Phone Number *"
                                        name="tenant2Phone"
                                        margin="dense"
                                        variant="standard"
                                        fullWidth={true}
                                        value={formValues.tenant2Phone}
                                        onChange={handleChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon sx={{ color: "black", fontSize: 15 }}>
                                                        +1
                                                    </Icon>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={formValues.tenant2PhoneError && formValues.tenant2PhoneError}
                                        helperText={formValues.tenant2PhoneError && formValues.tenant2PhoneError}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="Email *"
                                        margin="dense"
                                        name="tenant2Email"
                                        variant="standard"
                                        fullWidth={true}
                                        value={formValues.tenant2Email}
                                        onChange={handleChange}
                                        error={formValues.tenant2EmailError && formValues.tenant2EmailError}
                                        helperText={formValues.tenant2EmailError && formValues.tenant2EmailError}
                                    />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </CardContent> : ""}
            <CardContent>
                {!condition ?
                    <Button color='primary' variant='contained' type='submit' sx={{ fontSize: 15 }} onClick={() => { addCoTenant() }}>
                        ADD CO-TENANT
                    </Button> :
                    <Button color='error' variant='contained' type='submit' sx={{ fontSize: 15 }} onClick={() => { addCoTenant() }}>
                        REMOVE CO-TENANT
                    </Button>}
            </CardContent>
            <CardContent sx={{ pb: 0 }}>
                <p style={{ fontSize: 16, fontWeight: "bolder" }}>Lease Agreement</p>
            </CardContent>
            <CardContent>
                <div className="img-container">
                    <div className="uploadBtn" style={{ width: "195px", height: "180px" }}>
                        <input type="file" id="uploadBtn" accept="application/pdf"
                            onChange={(e) => addAttachment(e)}
                        />
                        {attachment == null ?
                            <label htmlFor="uploadBtn" style={{ fontSize: 13, color: "grey", textAlign: "center" }}>
                                Drop Files / click to Upload
                            </label> :
                            <label htmlFor="uploadBtn" style={{ fontSize: 13, color: "grey", textAlign: "center" }}>
                                {attachment.name}
                            </label>}
                    </div>
                </div>
            </CardContent>
            <CardContent sx={{ display: "flex", justifyContent: "right" }}>
                <Button color='primary' variant='contained' type='submit' onClick={addLeaseClick}
                    sx={{ fontSize: 15 }}
                    disabled={isLeaseButtonDisabled(dateValues, formValues, attachment)}
                >
                    ADD LEASE
                </Button>

            </CardContent>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={emailStatusCondition}
                onClose={() => { setEmailStatusCondition(false) }}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        ({formValues.tenant1Email}) Email already in system. Can't proceed with existing email. Contact Administrator
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                    <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { setEmailStatusCondition(false) }}>Ok</Button>
                    <Button color='error' sx={{ fontSize: 15 }} onClick={() => { setEmailStatusCondition(false) }} >CANCEL</Button>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default LeaseAdd;
