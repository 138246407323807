import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formNames, usStates } from "../../../../helpers";
import { FaStepBackward, FaStepForward } from "react-icons/fa";
import { editTenantLeaseApplicantInfo, getTenantLeaseApplication, withDrawApplication } from "../../../../services";
import { Button, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TenantLeaseApplicationEditPage from ".";
import { MenuProps } from "../../../../components/MaterialUiSelectMenuStyle";
import { formatPhoneNumber } from "../../../../components/phoneNumberFormat";

function ResidenceForm() {
    let params = useParams();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.value);

    const [residenceStreet, setResidenceStreet] = useState("");
    const [residenceStreetError, setResidenceStreetError] = useState("");
    const [residenceBuilding, setResidenceBuilding] = useState("");
    const [
        residenceBuildingError,
        // setResidenceBuildingError,
    ] = useState("");
    const [residenceCity, setResidenceCity] = useState("");
    const [residenceCityError, setResidenceCityError] = useState("");
    const [residenceState, setResidenceState] = useState("");
    const [residenceStateError, setResidenceStateError] = useState("");
    const [residenceZip, setResidenceZip] = useState("");
    const [residenceZipError, setResidenceZipError] = useState("");
    const [residenceRent, setResidenceRent] = useState("");
    const [residenceRentError, setResidenceRentError] = useState("");
    const [residenceYears, setResidenceYears] = useState("1");
    const [
        residenceYearsError,
        // setResidenceYearsError,
    ] = useState("");
    const [residenceReason, setResidenceReason] = useState("");
    const [residenceReasonError, setResidenceReasonError] = useState("");
    const [residenceManagerName, setResidenceManagerName] = useState("");
    const [residenceManagerNameError, setResidenceManagerNameError] = useState("");

    const [residenceManagerPhone, setResidenceManagerPhone] = useState("");
    const [residenceManagerPhoneError, setResidenceManagerPhoneError] = useState("");

    const [previousStreet, setPreviousStreet] = useState("");
    const [previousStreetError, setPreviousStreetError] = useState("");
    const [previousBuilding, setPreviousBuilding] = useState("");
    const [
        previousBuildingError,
        // setPreviousBuildingError,
    ] = useState("");
    const [previousCity, setPreviousCity] = useState("");
    const [previousCityError, setPreviousCityError] = useState("");
    const [previousState, setPreviousState] = useState("Alabama");
    const [previousStateError, setPreviousStateError] = useState("");
    const [previousZip, setPreviousZip] = useState("");
    const [previousZipError, setPreviousZipError] = useState("");
    const [previousRent, setPreviousRent] = useState("");
    const [previousRentError, setPreviousRentError] = useState("");
    // const [previousYears, setPreviousYears] = useState("");
    // const [previousYearsError, setPreviousYearsError] = useState("");
    const [previousReason, setPreviousReason] = useState("");
    const [previousReasonError, setPreviousReasonError] = useState("");
    const [previousManagerName, setPreviousManagerName] = useState("");
    const [previousManagerNameError, setPreviousManagerNameError] = useState("");
    const [previousManagerPhone, setPreviousManagerPhone] = useState("");
    const [previousManagerPhoneError, setPreviousManagerPhoneError] = useState("");

    const [FieldsDisabled, setFieldsDisabled] = useState(false);
    const [SubmittedStatus, setSubmittedStatus] = useState(false);
    const [Modify, setModify] = useState(false);

    const pageLoad = async () => {
        await getTenantLeaseApplication(params.applicationId).then((res) => {
            let application = res?.data?.data;
            if (application) {
                // Variables for current residence and previous residence.
                let cr, pr;
                // If there two applicants, we need to find the currently logged in user from the array.
                application.applicants.forEach((item, index) => {
                    if (item.user === user.id) {
                        cr = application.applicants[index].currentResidence;
                        pr = application.applicants[index].previousResidence;
                    }
                });

                setResidenceStreet(cr?.address?.line1 ?? "");
                setResidenceBuilding(cr?.address?.line2 ?? "");
                setResidenceCity(cr?.address?.city ?? "");
                setResidenceState(cr?.address?.state ?? "");
                setResidenceZip(cr?.address?.zipCode ?? "");
                setResidenceManagerName(cr?.managerName ?? "");
                setResidenceManagerPhone(cr?.managerPhone ?? "");
                setResidenceRent(cr?.monthlyRent ?? "");
                setResidenceYears(cr?.yearsOfResidence ?? "");
                setResidenceReason(cr?.reasonForLeaving ?? "");

                setPreviousStreet(pr?.address?.line1 ?? "");
                setPreviousBuilding(pr?.address?.line2 ?? "");
                setPreviousCity(pr?.address?.city ?? "");
                setPreviousState(pr?.address?.state ?? "");
                setPreviousZip(pr?.address?.zipCode ?? "");
                setPreviousManagerName(pr?.managerName ?? "");
                setPreviousManagerPhone(pr?.managerPhone ?? "");
                setPreviousRent(pr?.monthlyRent ?? "");
                setPreviousReason(pr?.reasonForLeaving ?? "");
            }
            if (application?.status == "APPLICANT_SUBMITTED") {
                setFieldsDisabled(true);
                setSubmittedStatus(true);
            }
            if (application?.status == "APPLICANT_WITHDRAWN") {
                setFieldsDisabled(false);
                setModify(true);
            }
        }).catch(err => { console.log(err) });
    }

    useEffect(() => {
        pageLoad();
    }, []);

    // Phone Format
    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setResidenceManagerPhone(formattedPhoneNumber);
    };
    const handleInput1 = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setPreviousManagerPhone(formattedPhoneNumber);
    };

    const onChangeHandler = (event) => {
        const residenceManagerPhone = event.target.value
        setResidenceManagerPhone(residenceManagerPhone)
    };
    const onChangeHandler1 = (e) => {
        const previousManagerPhone = e.target.value
        setPreviousManagerPhone(previousManagerPhone)
    };

    const renderStateOptions = () => {
        return (
            usStates.map((item) => (
                // <>
                //     <option value={""} key="select">Select</option>
                //     {usStates.map((item) => (
                //         <option key={item} value={item}>
                //             {item}
                //         </option>
                //     ))}
                // </>
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))

        );
    };

    const handleModify = () => {
        const res = withDrawApplication(params.applicationId).then((response) => {
            window.location.reload();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };

    const handleResidenceSubmit = () => {
        let error = false;

        if (residenceStreet === "") {
            setResidenceStreetError("Street cannot be empty");
            error = true;
        } else {
            setResidenceStreetError("");
        }
        if (residenceCity === "") {
            setResidenceCityError("City cannot be empty");
            error = true;
        } else {
            setResidenceCityError("");
        }
        if (residenceState === "") {
            setResidenceStateError("State cannot be empty");
            error = true;
        } else {
            setResidenceStateError("");
        }
        if (residenceZip === "") {
            setResidenceZipError("Zip code cannot be empty");
            error = true;
        } else if (residenceZip && !residenceZip.match(/(^\d{5}$)/g)) {
            setResidenceZipError("Enter a valid US Zipcode")
        } else {
            setResidenceZipError("");
        }
        if (residenceManagerName === "") {
            setResidenceManagerNameError("Manager's name cannot be empty");
            error = true;
        } else {
            setResidenceManagerNameError("");
        }
        if (residenceManagerPhone === "") {
            setResidenceManagerPhoneError("Manager's phone cannot be empty");
            error = true;
        } else {
            setResidenceManagerPhoneError("");
        }
        if (residenceRent === "") {
            setResidenceRentError("Rent cannot be empty");
            error = true;
        } else {
            setResidenceRentError("");
        }
        if (residenceReason === "") {
            setResidenceReasonError("Reason cannot be empty");
            error = true;
        } else {
            setResidenceReasonError("");
        }

        if (Number(residenceYears) < 3) {
            if (previousStreet === "") {
                setPreviousStreetError("Street cannot be empty");
                error = true;
            } else {
                setPreviousStreetError("");
            }
            if (previousCity === "") {
                setPreviousCityError("City cannot be empty");
                error = true;
            } else {
                setPreviousCityError("");
            }
            if (previousState === "") {
                setPreviousStateError("State cannot be empty");
                error = true;
            } else {
                setPreviousStateError("");
            }
            if (previousZip === "") {
                setPreviousZipError("Zip code cannot be empty");
                error = true;
            } else if (previousZip && !previousZip.match(/(^\d{5}$)/g)) {
                setPreviousZipError("Enter a valid US Zipcode")
            } else {
                setPreviousZipError("");
            }
            if (previousManagerName === "") {
                setPreviousManagerNameError("Manager's name cannot be empty");
                error = true;
            } else {
                setPreviousManagerNameError("");
            }
            if (previousManagerPhone === "") {
                setPreviousManagerPhoneError("Manager's phone cannot be empty");
                error = true;
            } else {
                setPreviousManagerPhoneError("");
            }
            if (previousRent === "") {
                setPreviousRentError("Rent cannot be empty");
                error = true;
            } else {
                setPreviousRentError("");
            }
            if (previousReason === "") {
                setPreviousReasonError("Reason cannot be empty");
                error = true;
            } else {
                setPreviousReasonError("");
            }
        }

        if (error) return;

        let data = {
            currentResidenceLine1: residenceStreet,
            currentResidenceLine2: residenceBuilding,
            currentResidenceCity: residenceCity,
            currentResidenceState: residenceState,
            currentResidenceZipCode: residenceZip,
            currentResidenceManagerName: residenceManagerName,
            currentResidenceManagerPhoneNumber: residenceManagerPhone,
            currentResidenceMonthlyRent: residenceRent,
            currentResidenceYears: residenceYears,
            currentResidenceReasonForLeaving: residenceReason,
        };

        if (Number(residenceYears) < 3) {
            data.previousResidenceLine1 = previousStreet;
            data.previousResidenceLine2 = previousBuilding;
            data.previousResidenceCity = previousCity;
            data.previousResidenceState = previousState;
            data.previousResidenceZipCode = previousZip;
            data.previousResidenceManagerName = previousManagerName;
            data.previousResidenceManagerPhoneNumber = previousManagerPhone;
            data.previousResidenceMonthlyRent = previousRent;
            data.previousResidenceReasonForLeaving = previousReason;
        }

        editTenantLeaseApplicantInfo(params.applicationId, data).then(response => {
            toast.success("Updated Residence details");
            pageLoad();
            navigate(`/tenant/lease-applications/${params.applicationId}/employment`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    // return (
    //     <div className="lease-form-item residence-form">
    //         <h1 className="form-header">Residence form</h1>
    //         <div className="form-body">
    //             <div className="form-section">
    //                 <h2 className="form-section-header">Current Residence Details</h2>
    //                 <div>
    //                     <h3>Address</h3>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Street</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={residenceStreet}
    //                                 onChange={e => { setResidenceStreet(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceStreetError &&
    //                                 <span className="form-item-error">
    //                                     {residenceStreetError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Building</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={residenceBuilding}
    //                                 onChange={e => { setResidenceBuilding(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceBuildingError &&
    //                                 <span className="form-item-error">
    //                                     {residenceBuildingError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">City</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={residenceCity}
    //                                 onChange={e => { setResidenceCity(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceCityError &&
    //                                 <span className="form-item-error">
    //                                     {residenceCityError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">State</span>
    //                             <select
    //                                 className="form-item-input"
    //                                 onChange={e => { setResidenceState(e.target.value) }}
    //                                 value={residenceState}
    //                                 disabled={FieldsDisabled}
    //                             >
    //                                 {renderStateOptions()}
    //                             </select>
    //                             {residenceStateError &&
    //                                 <span className="form-item-error">
    //                                     {residenceStateError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Zip Code</span>
    //                             <input
    //                                 maxLength={5}
    //                                 className="form-item-input"
    //                                 type="number"
    //                                 value={residenceZip}
    //                                 onChange={e => {
    //                                     if (e.target.value.length == 6) return false;
    //                                     setResidenceZip(e.target.value)
    //                                 }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceZipError &&
    //                                 <span className="form-item-error">
    //                                     {residenceZipError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <h3>Manager's details</h3>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Manager Name</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={residenceManagerName}
    //                                 onChange={e => { setResidenceManagerName(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceManagerNameError &&
    //                                 <span className="form-item-error">
    //                                     {residenceManagerNameError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Manager Phone</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={residenceManagerPhone}
    //                                 onChange={(event) => { onChangeHandler(event); handleInput(event) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceManagerPhoneError &&
    //                                 <span className="form-item-error">
    //                                     {residenceManagerPhoneError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <h3>Other information</h3>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Current Rent</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="number"
    //                                 value={residenceRent}
    //                                 onChange={e => { setResidenceRent(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceRentError &&
    //                                 <span className="form-item-error">
    //                                     {residenceRentError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Duration (Year)</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="number"
    //                                 min={1}
    //                                 value={residenceYears}
    //                                 onChange={e => { setResidenceYears(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceYearsError &&
    //                                 <span className="form-item-error">
    //                                     {residenceYearsError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Reason for leaving</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={residenceReason}
    //                                 onChange={e => { setResidenceReason(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {residenceReasonError &&
    //                                 <span className="form-item-error">
    //                                     {residenceReasonError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             {Number(residenceYears) < 3 &&
    //                 <div className="form-section">
    //                     <h2 className="form-section-header">Previous Residence Details</h2>
    //                     <div>
    //                         <h3>Address</h3>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Street</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousStreet}
    //                                     onChange={e => { setPreviousStreet(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousStreetError &&
    //                                     <span className="form-item-error">
    //                                         {previousStreetError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Building</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousBuilding}
    //                                     onChange={e => { setPreviousBuilding(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousBuildingError &&
    //                                     <span className="form-item-error">
    //                                         {previousBuildingError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">City</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousCity}
    //                                     onChange={e => { setPreviousCity(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousCityError &&
    //                                     <span className="form-item-error">
    //                                         {previousCityError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">State</span>
    //                                 <select
    //                                     className="form-item-input"
    //                                     onChange={e => { setPreviousState(e.target.value) }}
    //                                     value={previousState}
    //                                     disabled={FieldsDisabled}
    //                                 >
    //                                     {renderStateOptions()}
    //                                 </select>
    //                                 {previousStateError &&
    //                                     <span className="form-item-error">
    //                                         {previousStateError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Zip Code</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     maxLength={5}
    //                                     type="number"
    //                                     value={previousZip}
    //                                     onChange={e => {
    //                                         if (e.target.value.length == 6) return false;
    //                                         setPreviousZip(e.target.value)
    //                                     }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousZipError &&
    //                                     <span className="form-item-error">
    //                                         {previousZipError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <h3>Manager's details</h3>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Manager Name</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousManagerName}
    //                                     onChange={e => { setPreviousManagerName(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousManagerNameError &&
    //                                     <span className="form-item-error">
    //                                         {previousManagerNameError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Manager Phone</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousManagerPhone}
    //                                     onChange={(e) => { onChangeHandler1(e); handleInput1(e) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousManagerPhoneError &&
    //                                     <span className="form-item-error">
    //                                         {previousManagerPhoneError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <h3>Other information</h3>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Previous Rent</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="number"
    //                                     value={previousRent}
    //                                     onChange={e => { setPreviousRent(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousRentError &&
    //                                     <span className="form-item-error">
    //                                         {previousRentError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Reason for leaving</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousReason}
    //                                     onChange={e => { setPreviousReason(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousReasonError &&
    //                                     <span className="form-item-error">
    //                                         {previousReasonError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             }
    //         </div>
    //         <div className="form-footer">
    //             {Modify === true ? (
    //                 <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         startIcon={<FaStepBackward />}
    //                         onClick={() => { setCurrentForm(formNames.occupantsForm) }}
    //                     >OCCUPANTS</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="success"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleResidenceSubmit}
    //                     >SAVE</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleResidenceSubmit}
    //                         endIcon={<FaStepForward />}
    //                     >EMPLOYMENT AND iNCOME</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="info"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={() => { setCurrentForm(formNames.agreementForm) }}
    //                         endIcon={<FaStepForward />}
    //                     >GO TO SUBMIT</Button>
    //                 </div>
    //             ) : (
    //                 <>
    //                     {SubmittedStatus === true ? (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 startIcon={<FaStepBackward />}
    //                                 onClick={() => { setCurrentForm(formNames.occupantsForm) }}
    //                             >OCCUPANTS</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleResidenceSubmit}
    //                                 endIcon={<FaStepForward />}
    //                             >EMPLOYMENT AND iNCOME</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={(e) => handleModify(e)}
    //                             >MODIFY</Button>
    //                         </div>
    //                     ) : (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 startIcon={<FaStepBackward />}
    //                                 onClick={() => { setCurrentForm(formNames.occupantsForm) }}
    //                             >OCCUPANTS</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="success"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleResidenceSubmit}
    //                             >SAVE</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleResidenceSubmit}
    //                                 endIcon={<FaStepForward />}
    //                             >EMPLOYMENT AND iNCOME</Button>
    //                         </div>
    //                     )}
    //                 </>
    //             )}
    //         </div>
    //         <ToastContainer
    //             position="top-center"
    //             autoClose={2000}
    //             hideProgressBar={false}
    //             newestOnTop={false}
    //             closeOnClick
    //             rtl={false}
    //             pauseOnFocusLoss
    //             draggable
    //             pauseOnHover
    //             theme="colored"
    //         />
    //     </div>
    // );

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: "100%", maxWidth: "1350px", }}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TenantLeaseApplicationEditPage />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h3 className="subHeader">Current Residence Details</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <h4 className="subHeader">Address</h4>
                                <TextField
                                    error={residenceStreetError ? true : false}
                                    helperText={residenceStreetError && residenceStreetError}
                                    label="Street"
                                    placeholder="Current Street"
                                    required={true}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={residenceStreet}
                                    onChange={e => { setResidenceStreet(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={residenceBuildingError ? true : false}
                                    helperText={residenceBuildingError && residenceBuildingError}
                                    label="Building"
                                    placeholder="Building"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={residenceBuilding}
                                    onChange={e => { setResidenceBuilding(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={residenceCityError ? true : false}
                                    helperText={residenceCityError && residenceCityError}
                                    label="City"
                                    placeholder="Enter Current City"
                                    margin="dense"
                                    variant="outlined"
                                    required={true}
                                    fullWidth={true}
                                    value={residenceCity}
                                    onChange={e => { setResidenceCity(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <FormControl
                                    fullWidth={true}
                                    error={residenceStateError ? true : false}
                                    margin="dense"
                                    disabled={FieldsDisabled}
                                    required={true}>
                                    <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                                    <Select
                                        label={"Select State"}
                                        fullWidth={true}
                                        MenuProps={MenuProps}
                                        variant="outlined"
                                        onChange={e => { setResidenceState(e.target.value) }}
                                        value={residenceState}
                                    >
                                        <MenuItem value={""}>Select</MenuItem>
                                        {renderStateOptions()}
                                    </Select>
                                    {residenceStateError && <FormHelperText>{residenceStateError}</FormHelperText>}
                                </FormControl>
                                <TextField
                                    error={residenceZipError ? true : false}
                                    helperText={residenceZipError && residenceZipError}
                                    label="Zip Code"
                                    placeholder="Enter Zipcode"
                                    type="number"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    value={residenceZip}
                                    onChange={e => {
                                        if (e.target.value.length == 6) return false;
                                        setResidenceZip(e.target.value)
                                    }}
                                    disabled={FieldsDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <h4 className="subHeader">Manager's details</h4>
                                <TextField
                                    error={residenceManagerNameError ? true : false}
                                    helperText={residenceManagerNameError && residenceManagerNameError}
                                    label="Manager Name"
                                    placeholder="Current Property Manager Name"
                                    margin="dense"
                                    required={true}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={residenceManagerName}
                                    onChange={e => { setResidenceManagerName(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={residenceManagerPhoneError ? true : false}
                                    helperText={residenceManagerPhoneError && residenceManagerPhoneError}
                                    label="Manager Phone"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    value={residenceManagerPhone}
                                    onChange={(event) => { onChangeHandler(event); handleInput(event) }}
                                    disabled={FieldsDisabled}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                    // }}
                                />
                                <TextField
                                    error={residenceRentError ? true : false}
                                    helperText={residenceRentError && residenceRentError}
                                    label="Current Rent"
                                    placeholder="Enter rent"
                                    type="number"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    value={residenceRent}
                                    onChange={e => { setResidenceRent(e.target.value) }}
                                    disabled={FieldsDisabled}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <TextField
                                    error={residenceYearsError ? true : false}
                                    helperText={residenceYearsError && residenceYearsError}
                                    label="Duration (Year)"
                                    type="number"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={residenceYears}
                                    required={true}
                                    onChange={e => { setResidenceYears(e.target.value) }}
                                    inputProps={{
                                        min: "1",
                                    }}
                                    startAdornment={<InputAdornment position="end">$</InputAdornment>}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={residenceReasonError ? true : false}
                                    helperText={residenceReasonError && residenceReasonError}
                                    label="Reason for Leaving"
                                    placeholder="Enter Reason for leaving this Residence"
                                    margin="dense"
                                    fullWidth={true}
                                    required={true}
                                    variant="outlined"
                                    value={residenceReason}
                                    onChange={e => { setResidenceReason(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                            </Grid>
                            {Number(residenceYears) < 3 &&
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h3 className="subHeader">Previous Residence Details{" "} (3 years)</h3>
                                </Grid>
                            }
                            {Number(residenceYears) < 3 &&
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <h4 className="subHeader">Address</h4>
                                    <TextField
                                        error={previousStreetError ? true : false}
                                        helperText={previousStreetError && previousStreetError}
                                        label="Street"
                                        placeholder="Previous Street"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        required={true}
                                        value={previousStreet}
                                        onChange={e => { setPreviousStreet(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <TextField
                                        error={previousBuildingError ? true : false}
                                        helperText={previousBuildingError && previousBuildingError}
                                        label="Building"
                                        placeholder="Enter Building"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousBuilding}
                                        onChange={e => { setPreviousBuilding(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <TextField
                                        error={previousCityError ? true : false}
                                        helperText={previousCityError && previousCityError}
                                        label="City"
                                        placeholder="Enter Previous City"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        required={true}
                                        value={previousCity}
                                        onChange={e => { setPreviousCity(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <FormControl
                                        fullWidth={true}
                                        error={previousStateError ? true : false}
                                        margin="dense"
                                        disabled={FieldsDisabled}
                                        required={true}>
                                        <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                                        <Select
                                            label={"Select State"}
                                            fullWidth={true}
                                            MenuProps={MenuProps}
                                            variant="outlined"
                                            onChange={e => { setPreviousState(e.target.value) }}
                                            value={previousState}
                                        >
                                            <MenuItem value={""}>Select</MenuItem>
                                            {renderStateOptions()}
                                        </Select>
                                        {previousStateError && <FormHelperText>{previousStateError}</FormHelperText>}
                                    </FormControl>
                                    <TextField
                                        error={previousZipError ? true : false}
                                        helperText={previousZipError && previousZipError}
                                        label="Zip Code"
                                        placeholder="Enter Zipcode"
                                        type="number"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        required={true}
                                        value={previousZip}
                                        onChange={e => {
                                            if (e.target.value.length == 6) return false;
                                            setPreviousZip(e.target.value)
                                        }}
                                        disabled={FieldsDisabled}
                                    />
                                </Grid>
                            }
                            {Number(residenceYears) < 3 &&
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <h4 className="subHeader">Manager's details</h4>
                                    <TextField
                                        error={previousManagerNameError ? true : false}
                                        helperText={previousManagerNameError && previousManagerNameError}
                                        label="Manager Name"
                                        placeholder="Previous Property Manager Name"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        required={true}
                                        value={previousManagerName}
                                        onChange={e => { setPreviousManagerName(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <TextField
                                        error={previousManagerPhoneError ? true : false}
                                        helperText={previousManagerPhoneError && previousManagerPhoneError}
                                        label="Manager Phone"
                                        placeholder="Eg (123) 123-1234"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        required={true}
                                        value={previousManagerPhone}
                                        onChange={(e) => { onChangeHandler1(e); handleInput1(e) }}
                                        disabled={FieldsDisabled}
                                        // InputProps={{
                                        //     startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                        // }}
                                    />
                                    <TextField
                                        error={previousRentError ? true : false}
                                        helperText={previousRentError && previousRentError}
                                        label="Previous Rent"
                                        placeholder="Enter rent"
                                        type="number"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        required={true}
                                        value={previousRent}
                                        onChange={e => { setPreviousRent(e.target.value) }}
                                        disabled={FieldsDisabled}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />
                                    <TextField
                                        error={previousReasonError ? true : false}
                                        helperText={previousReasonError && previousReasonError}
                                        label="Reason for Leaving"
                                        placeholder="Enter Reason for leaving"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        required={true}
                                        value={previousReason}
                                        onChange={e => { setPreviousReason(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                {Modify === true ? (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            startIcon={<FaStepBackward />}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/occupant`); }}
                                        >OCCUPANTS</Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleResidenceSubmit}
                                        >SAVE</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleResidenceSubmit}
                                            endIcon={<FaStepForward />}
                                        >EMPLOYMENT AND iNCOME</Button>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            sx={{ fontSize: 15 }}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/agreement`); }}
                                            endIcon={<FaStepForward />}
                                        >GO TO SUBMIT</Button>
                                    </div>
                                ) : (
                                    <>
                                        {SubmittedStatus === true ? (
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    startIcon={<FaStepBackward />}
                                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/occupant`); }}
                                                >OCCUPANTS</Button>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleResidenceSubmit}
                                                    endIcon={<FaStepForward />}
                                                >EMPLOYMENT AND iNCOME</Button>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={(e) => handleModify(e)}
                                                >MODIFY</Button>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    startIcon={<FaStepBackward />}
                                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/occupant`); }}
                                                >OCCUPANTS</Button>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleResidenceSubmit}
                                                >SAVE</Button>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleResidenceSubmit}
                                                    endIcon={<FaStepForward />}
                                                >EMPLOYMENT AND iNCOME</Button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default ResidenceForm;