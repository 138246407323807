import { Button, CardContent, Modal, TextField, Paper, Grid, Dialog, DialogTitle, DialogContent, CircularProgress, Box, Tooltip } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignaturePad from "react-signature-canvas";
import {
    getLeases, getTenantMovein, getTenantLeasesProperty, postTenantMovinspectionitemfile, deleteTenantMovinspectionitem, patchTenantMovinspectionitem,
    tenantMovinspectionitem, tenantMovinTenanRemarks, postTenantMoveinSubmit, deleteTenantMoveInImg, getApiBaseURL, postTenantMovinspectionArea, patchTenantMovinspectionArea, serviceRequest, updateMoveInInspectionItemServiceRequestInfo
} from "../../../../services";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildIcon from '@mui/icons-material/Build';
import axios from 'axios';
import ToolTip from "../../../../components/ToolTip";
import { tenantImageClick } from "../../../../components/imageView";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../../../Styles/tenant/tenantMoveIn.css'

function MoveInLease() {
    let sigPad = useRef({});
    const params = useParams();
    const navigate = useNavigate();
    const hiddenFileInput = useRef();

    const [ip, setIP] = useState('');
    const [timeGet, settimeGet] = useState('');

    const [property, setProperty] = useState(null);
    const [lease, setLease] = useState(null);
    const [moveInDetails, setMoveInDetails] = useState(null);

    const [tenantsRemarks, settenantsRemarks] = useState("");
    const [managersRemarks, setmanagerRemarks] = useState("");

    const [tenantsignImg, settenantsignImg] = useState("")
    const [mansignImg, setmansignImg] = useState("");

    const [commonMapview, setcommonMapview] = useState([]);

    const [addItemDialog, setaddItemDialog] = useState(false);
    const [editItemDialog, seteditItemDialog] = useState(false);
    const [addAreaDialog, setAddareaDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [sendManagerClick, setSendManagerClick] = useState(false);

    const [aarea, setAarea] = useState("");
    const [addAreaName, setAddAreaName] = useState("");
    const [addAreaDesc, setAddAreaDesc] = useState("");

    const [idd, setIdd] = useState("");

    const [area, setArea] = useState("");

    // loading
    const [loading, setLoading] = useState(true);

    const [fileId, setfileId] = useState(0);
    const [isSigned, setIsSigned] = useState(false);

    const clearSig = (e) => {
        e.preventDefault();
        sigPad.clear();
        setIsSigned(false);
    }

    // Add Inspection Items
    const commonAddClick = (e, name) => {
        setaddItemDialog(true);
        setAarea(name);
    }

    const commonAddCloseClick = () => {
        setaddItemDialog(false)
        setAarea("");
        setAddAreaDesc("");
        setAddAreaName("");
    }

    const commonAddSaveClick = () => {
        let data = {
            "area": aarea,
            "tenantRemarks": addAreaDesc,
            "name": addAreaName,
        }
        tenantMovinspectionitem(params.applicationId, data).then((res) => {
            commonAddCloseClick();
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Edit Inspection Items
    const editClick = (e, area, id, name, tenremark, manremark, remind) => {
        seteditItemDialog(true);
        setIdd(id);
        setAarea(area);
        setAddAreaDesc(tenremark);
        setAddAreaName(name);
    }

    const editCloseClick = () => {
        seteditItemDialog(false);
        setIdd("");
        setAarea("");
        setAddAreaDesc("");
        setAddAreaName("");
    }

    const editSaveClick = () => {
        let datas = {
            "area": aarea,
            "tenantRemarks": addAreaDesc,
            "name": addAreaName,
        }
        patchTenantMovinspectionitem(params.applicationId, idd, datas).then((res) => {
            editCloseClick();
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Delete Inspection Items
    const [removeInspectionDialog, setRemoveInspectionDialog] = useState(false);
    const [inspectionId, setInspectionId] = useState("");
    const removeInspectionDialogOpen = (e, id) => {
        setRemoveInspectionDialog(true);
        setInspectionId(id);
    }
    const removeInspectionDialogClose = () => {
        setRemoveInspectionDialog(false);
        setInspectionId("");
    }
    const deleteiconClick = (e) => {
        deleteTenantMovinspectionitem(params.applicationId, inspectionId).then((res) => {
            removeInspectionDialogClose();
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Delete All Inspection Items
    const [removeAreaDialog, setRemoveAreaDialog] = useState(false);
    const [removeAllIds, setRemoveAllIds] = useState(null);
    const removeAreaDialogOpen = (e, ids) => {
        setRemoveAreaDialog(true);
        setRemoveAllIds(ids);
    }
    const removeAreaDialogClose = () => {
        setRemoveAreaDialog(false);
        setRemoveAllIds(null);
    }
    const commonRemoveAreaClick = async (e) => {
        try {
            for (let id of removeAllIds) {
                await deleteTenantMovinspectionitem(params.applicationId, id).then((res) => { })
            }
            window.location.reload();
        } catch (err) {
            toast.error(err.response.data.error.message);
        }
    }

    // Add area
    const handleAddArea = () => {
        if (!area) return;
        let existingArea = commonMapview[area];
        if (existingArea) {
            toast.error('There is already an area with that name. Please use another name');
            return;
        }
        let data = {
            area: area
        }
        postTenantMovinspectionArea(params.applicationId, data).then((res) => {
            pageLoad1();
            setArea("");
            setAddareaDialog(false);
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Edit area
    const [itemId, setItemId] = useState(null);
    const editareaClick = (e, name, id) => {
        setEditDialog(true);
        setAarea(name);
        setItemId(id);
    }

    const editareaCloseClick = () => {
        setEditDialog(false);
        setAarea("");
        setItemId(null);
    }

    const editareaSaveClick = async () => {
        try {
            if (!aarea) return;
            let existingArea = commonMapview[aarea];
            if (existingArea) {
                toast.error('There is already an area with that name. Please use another name');
                return;
            }
            for (let id of itemId) {
                let data = { area: aarea }
                await patchTenantMovinspectionArea(params.applicationId, id.id, data).then((res) => { })
            }
            window.location.reload();
        } catch (err) {
            toast.error(err.response.data.error.message);
        }
    }

    // Inspection File Add
    const handleClick = (e, id) => {
        e.preventDefault();
        setfileId(id);
        hiddenFileInput.current.click();
    };

    const fileUploadHandler = (e, id) => {
        var file = e.target.files[0] || undefined,
            supportedFormats = [
                "image/jpg",
                "image/gif",
                "image/png",
                "image/jpeg",
                "application/pdf",
            ];
        if (file && file.type) {
            if (0 > supportedFormats.indexOf(file.type)) {
            } else {
                let formData = new FormData();
                formData.append("file", e.target.files[0]);
                postTenantMovinspectionitemfile(params.applicationId, id, formData).then((res) => {
                    pageLoad1();
                }).catch((err) => { toast.error(err.response.data.error.message) })
            }
        }
    }

    // Remove File
    const removeImage = (e, fileId, imgId) => {
        deleteTenantMoveInImg(params.applicationId, fileId, imgId).then((res) => {
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Add TenantRemarks
    const saveButtonClick = () => {
        let data = { "tenantRemarks": tenantsRemarks }
        tenantMovinTenanRemarks(params.applicationId, data).then((res) => {
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Send to Manager
    const sendDialogOpen = () => {
        setSendManagerClick(true);
    }
    const sendDialogClose = () => {
        setSendManagerClick(false);
    }
    const sendtoManagerClick = () => {
        let detail = {
            "tenantRemarks": tenantsRemarks,
            "signature": sigPad.getTrimmedCanvas().toDataURL('image/png'),
        }
        postTenantMoveinSubmit(params.applicationId, detail).then((res) => {
            sendDialogClose();
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    var config = "";
    const pageLoad1 = async () => {
        try {
            setLoading(true);
            let det = [];
            det.length = 0;
            await getTenantLeasesProperty(params.applicationId).then((res) => {
                const getProp = res?.data?.data;
                setProperty(getProp);
            });

            await getLeases(params.applicationId).then((res) => {
                let responseData = res?.data?.data;
                setLease(responseData);
            });

            await getTenantMovein(params.applicationId).then((res) => {
                let getDetails = res?.data?.data;
                setMoveInDetails(getDetails);
                if (getDetails.tenantRemarks !== undefined) {
                    settenantsRemarks(getDetails.tenantRemarks)
                }

                if (getDetails.managerRemarks !== undefined) {
                    setmanagerRemarks(getDetails.managerRemarks)
                }

                if (getDetails.tenantSignature !== undefined) {
                    if (getDetails.tenantSignature.signatureFile !== undefined) {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },
                        };
                        fetch(
                            `${getApiBaseURL()}/tenant/leases/${params.applicationId}/move-in/tenant-signature`, config
                        ).then(res => {
                            return res.blob();
                        }).then(blob => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement('a');
                                link.href = imageDataUrl;
                                if (blob.type === "image/png"
                                    || blob.type === "image/gif"
                                    || blob.type === "image/jpeg"
                                    || blob.type === "image/svg"
                                    || blob.type === "image/jpg"
                                ) {
                                    settenantsignImg(imageDataUrl)
                                }
                            }
                        })
                    }
                }
                if (getDetails.managerSignature !== undefined) {
                    if (getDetails.managerSignature.signatureFile !== undefined) {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },
                        };
                        fetch(
                            `${getApiBaseURL()}/tenant/leases/${params.applicationId}/move-in/manager-signature`, config
                        ).then(res => {
                            return res.blob();
                        }).then(blob => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement('a');
                                link.href = imageDataUrl;
                                if (blob.type === "image/png"
                                    || blob.type === "image/gif"
                                    || blob.type === "image/jpeg"
                                    || blob.type === "image/svg"
                                    || blob.type === "image/jpg"
                                ) {
                                    setmansignImg(imageDataUrl)
                                }
                            }
                        })
                    }
                }
                if (getDetails.inspectionItems.length !== 0) {
                    getDetails.inspectionItems.map((e, i) => {
                        det.push({
                            "area": e.area,
                            "id": e.id,
                            "name": e.name,
                            "remindOnMoveOut": e.remindOnMoveOut,
                            "settlementAmount": e.settlementAmount,
                            "tenantRemarks": e.tenantRemarks,
                            "managerRemarks": e.managerRemarks,
                            "managerFiles": e.managerFiles,
                            "settlementFiles": e.settlementFiles,
                            "tenantFiles": e.tenantFiles,
                            "serviceRequestInfo": e.serviceRequestInfo,
                            "status": getDetails.status
                        });
                    })
                    det.sort((a, b) => a.area.localeCompare(b.area));
                    let groupedPeople = groupBy(det, "area");
                    setcommonMapview(groupedPeople);
                }
            })
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        var today = new Date();
        var str = today.toGMTString();
        settimeGet(str);
        getData();
        pageLoad1();
    }, []);

    const createServiceRequest = async (inspectionItem) => {
        if (!inspectionItem?.tenantRemarks) {
            toast.error("Please Enter Issue Description");
            return;
        }
        let moveInInfo = {
            isCreated: true,
            leaseId: params.applicationId,
            type: 'MoveIn',
            inspectionItemId: inspectionItem?.id,
        }
        let formData = new FormData();
        formData.append("leaseId", params.applicationId);
        formData.append("title", inspectionItem?.name);
        formData.append("description", inspectionItem?.tenantRemarks || 'None');
        formData.append("moveInMoveOutInfo", JSON.stringify(moveInInfo));
        await serviceRequest(params.applicationId, formData).then((res) => {
            let data = res?.data?.data;
            let serviceRequestInfo = {
                requested: true,
                serviceRequestId: data?.id,
            }
            return updateMoveInInspectionItemServiceRequestInfo(params.applicationId, inspectionItem?.id, serviceRequestInfo);
        }).then((res) => {
            toast.success("Service Request Created For The Issue");
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    const navigateToServiceRequest = (servId) => {
        navigate(`/tenant/service-requests/${servId}`);
    }

    const tenantHasSubmitted = (status) => {
        let stat = true;
        let states = [4, 5, 6, 7]
        if (states.includes(status)) return stat = true;
        return stat = false;
    }

    const tenantCanSubmit = (status, leaseStatus) => {
        let stat = true;
        let states = [3]
        if (states.includes(status) && [2].includes(leaseStatus)) return stat = true;
        return stat = false;
    }


    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
                    <p style={{ fontSize: 17 }}>
                        <Link to="/tenant/dashboard">DASHBOARD</Link> / <Link to={`/tenant/lease-applications/${params.applicationId}/leaseview`}>LEASE</Link> / MOVE IN CHECKLIST
                    </p>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
                    <p style={{ fontSize: 25, fontWeight: "bold" }}>Lease Move In Checklist</p>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <p style={{ fontSize: 21, fontWeight: "bold" }}>Manager</p>
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{property?.propertyManagers[0]?.manager?.user?.name?.first + " " + property?.propertyManagers[0]?.manager?.user?.name?.last}</p>
                                <ToolTip
                                    phone={property?.propertyManagers[0]?.manager?.user?.email?.id}
                                    email={property?.propertyManagers[0]?.manager?.user?.phone?.code + " " + property?.propertyManagers[0]?.manager?.user?.phone?.number}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6} textAlign={"right"}>
                            <p style={{ fontSize: 21, fontWeight: "bold" }}>Property</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.line1} {property?.address?.line2}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.unitNumber}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.city}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.state} - {property?.address?.zipCode}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <p style={{ fontSize: 21, fontWeight: "bold" }}>Move In Checklist</p>
            </div>

            {Object.keys(commonMapview).map((cat, index) => (
                <Accordion sx={{ mt: 2 }} key={index} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10 }}>
                                <div>
                                    <p style={{ fontSize: 18, fontWeight: "bold" }}>{cat}</p>
                                </div>
                                {tenantCanSubmit(moveInDetails?.status, lease?.status) ?
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30 }}>
                                        <EditIcon color="primary"
                                            onClick={(e) => editareaClick(e, cat, commonMapview[cat])} />
                                        <DeleteIcon color="error"
                                            onClick={(e) => removeAreaDialogOpen(e, commonMapview[cat].map(element => element.id))} />
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {commonMapview[cat].map((item, i) => (
                            <div style={{ display: 'flex', flexDirection: 'column', borderTop: "1px solid rgba(0,0,0,.12)", paddingTop: "10px", gap: '10px' }}>
                                {item.tenantRemarks || item.managerRemarks ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <div style={{ flex: '1 1 50%', display: 'flex', flexDirection: 'column' }}>
                                            <h4>{item.name}</h4>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                <h4 style={{ color: 'green' }}>Tenant Description</h4>
                                                <span>
                                                    {item.tenantRemarks || ""}
                                                </span>
                                            </div>
                                            {item?.tenantFiles.length != 0 ? (
                                                <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                    {item?.tenantFiles.map((j, k) => (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                            <span className="tenant-move-in-attachment-hover-text" onClick={(e) => tenantImageClick(e, `leases/${params.applicationId}/move-in/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                {j?.fileName}
                                                            </span>
                                                            {tenantCanSubmit(moveInDetails?.status, lease?.status) ?
                                                                <div title="Delete">
                                                                    <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={(e) => { removeImage(e, item?.id, j?.id) }} />
                                                                </div>
                                                                : ""}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div style={{ flex: '1 1 50%', display: 'flex', flexDirection: 'column' }}>
                                            {tenantHasSubmitted(moveInDetails?.status) ?
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    <h4 className="warnig-move-in-desc">Manager Description</h4>
                                                    <span>
                                                        {item.managerRemarks || ''}
                                                    </span>
                                                    {item?.managerFiles.length != 0 ? (
                                                        <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                            {item?.managerFiles.map((j, k) => (
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                                    <span className="tenant-move-in-attachment-hover-text" onClick={(e) => tenantImageClick(e, `leases/${params.applicationId}/move-in/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                        {j?.fileName}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                    : ""}
                                {item.tenantRemarks ?
                                    <div>
                                        {tenantCanSubmit(moveInDetails?.status, lease?.status) ?
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px', width: '50%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                                                    <EditIcon color="primary"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={(e) =>
                                                            editClick(
                                                                e,
                                                                cat,
                                                                item?.id,
                                                                item?.name,
                                                                item?.tenantRemarks,
                                                                item?.managerRemarks,
                                                                item?.remindOnMoveOut
                                                            )} />
                                                    <label htmlFor="uploadBtn">
                                                        <AttachFileIcon
                                                            color="primary"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={(e) => handleClick(e, item?.id)}
                                                        />
                                                    </label>
                                                    <label>
                                                        <input
                                                            ref={hiddenFileInput}
                                                            type="file"
                                                            id="uploadBtn"
                                                            className="inputfile"
                                                            onChange={(e) => fileUploadHandler(e, fileId)}
                                                        />
                                                    </label>
                                                    {!item?.serviceRequestInfo?.requested ?
                                                        <Tooltip title="Create Service Request">
                                                            <BuildIcon color="primary" sx={{ cursor: 'pointer' }} onClick={() => { createServiceRequest(item) }} />
                                                        </Tooltip>
                                                        : ""}
                                                    {item?.serviceRequestInfo?.requested ?
                                                        <BuildIcon color="success" sx={{ cursor: 'pointer' }} onClick={() => { navigateToServiceRequest(item?.serviceRequestInfo?.serviceRequestId) }} />
                                                        : ""}
                                                </div>
                                                <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={(e) => removeInspectionDialogOpen(e, item?.id)} />
                                            </div>
                                            : ""}
                                    </div>
                                    : ""}
                            </div>
                        ))}
                    </AccordionDetails>
                    {tenantCanSubmit(moveInDetails?.status, lease?.status) ?
                        <div style={{ borderTop: "1px solid rgba(0,0,0,.12)" }}>
                            <Button
                                color="primary"
                                onClick={(e) => commonAddClick(e, cat)}
                                sx={{ fontSize: 15, marginLeft: "20px", marginTop: "10px", marginBottom: "10px" }}
                            >
                                Add Issue
                            </Button>
                        </div>
                        : ""}
                </Accordion>
            ))}

            <div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                {tenantCanSubmit(moveInDetails?.status, lease?.status) ?
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => { setAddareaDialog(true) }}
                    >ADD AREA
                    </Button>
                    : ""}
            </div>

            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 20, marginTop: 20, marginBottom: 20, alignItems: 'flex-end' }}>
                {moveInDetails != null ?
                    <div style={{ flexGrow: 1 }}>
                        <p style={{ fontSize: 18, fontWeight: "bold" }}>Tenant Remarks</p>
                        <TextField
                            value={tenantsRemarks}
                            id="standard-basic"
                            label="Mention for future records"
                            variant="standard"
                            sx={{ width: 1 }}
                            disabled={!tenantCanSubmit(moveInDetails?.status, lease?.status)}
                            onChange={(e) => { settenantsRemarks(e.target.value) }}
                            multiline
                        />
                    </div>
                    : ""}
                {(moveInDetails != null && tenantHasSubmitted(moveInDetails?.status)) ?
                    <div style={{ flexGrow: 1 }}>
                        <p style={{ fontSize: 18, fontWeight: "bold" }}>Manager Remarks</p>
                        <TextField
                            id="standard-basic"
                            label="Mention for future records(include keys,remotes and other items that were handed over during move-in)."
                            placeholder="You can mention items handed over to tenant list keys etc.."
                            variant="standard"
                            sx={{ width: 1 }}
                            value={managersRemarks}
                            multiline
                            disabled={true} />
                    </div>
                    : ""}
                {tenantCanSubmit(moveInDetails?.status, lease?.status) ?
                    <div>
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ fontSize: 15 }}
                            onClick={(e) => saveButtonClick(e)}
                        >SAVE
                        </Button>
                    </div>
                    : ""}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10, marginTop: 20, marginBottom: 20 }}>
                {moveInDetails != null ?
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {moveInDetails?.tenantSignature?.ipAddress != "System" ?
                            <div style={{ marginBottom: '1em' }}>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{lease?.leaseTenants[0]?.name?.first + " " + lease?.leaseTenants[0]?.name?.last}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Tenant</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address:{ip}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{timeGet}</p>
                            </div>
                            : ""}

                        {!moveInDetails.tenantSignature && tenantCanSubmit(moveInDetails?.status, lease?.status) ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ cursor: "pointer" }} className="signboxx">
                                    <SignaturePad penColor='#4285f4'
                                        onEnd={() => { setIsSigned(true) }}
                                        canvasProps={{ width: 300, height: 200, className: 'sig-canvas curSig' }}
                                        ref={(ref) => { sigPad = ref }} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={(e) => clearSig(e)}>Clear</Button>
                                </div >
                            </div >
                            : ""}

                        {moveInDetails?.tenantSignature ?
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
                                {tenantsignImg != "" ?
                                    <div className="signboxx">
                                        <img src={tenantsignImg} />
                                    </div>
                                    : ""}
                            </div >
                            : ""}

                        {moveInDetails?.tenantSignature?.ipAddress == "System" ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{lease?.leaseTenants[0]?.name?.first + " " + lease?.leaseTenants[0]?.name?.last}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Tenant</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address:{moveInDetails?.tenantSignature?.ipAddress}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{moveInDetails?.tenantSignature?.time}</p>
                            </div >
                            : ""}
                    </div >
                    : ""}
                {moveInDetails?.managerSignature ?
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ fontSize: 21, fontWeight: "bold" }}>{moveInDetails?.managerSignature?.name}</p>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>Manager</p>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {moveInDetails?.managerSignature?.ipAddress}</p>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>{moveInDetails?.managerSignature?.time}</p>
                        {mansignImg != "" ?
                            <div className="signboxx">
                                <img src={mansignImg} />
                            </div>
                            : ""}
                    </div>
                    : ""}
            </div>


            {tenantCanSubmit(moveInDetails?.status, lease?.status) ?
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, justifyContent: "flex-end" }}>
                    <Button
                        variant="contained"
                        color="warning"
                        sx={{ fontSize: 15 }}
                        disabled={isSigned == true ? false : true}
                        onClick={(e) => sendDialogOpen(e)}
                    >SEND TO MANAGER
                    </Button>
                </div>
                : ""
            }

            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={addItemDialog}
                onClose={(e) => commonAddCloseClick()}>
                <DialogTitle>
                    Add Inspection Item
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={aarea}
                        disabled={true}
                        onChange={e => { setAarea(e.target.value) }}
                    >
                    </TextField>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={addAreaName}
                        onChange={e => { setAddAreaName(e.target.value) }}
                    >
                    </TextField>
                    <TextField
                        label="Description"
                        id="standard-basic"
                        variant="standard"
                        fullWidth={true}
                        type="text"
                        sx={{ mb: 5 }}
                        multiline
                        value={addAreaDesc}
                        onChange={e => { setAddAreaDesc(e.target.value) }}
                    >
                    </TextField>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, flexWrap: 'wrap' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            onClick={(e) => commonAddCloseClick()}
                        >CANCEL</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            onClick={(e) => commonAddSaveClick()}
                        >ADD</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={editItemDialog}
                onClose={(e) => editCloseClick()}>
                <DialogTitle>Edit Inspection Item</DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={aarea}
                        disabled={true}
                        onChange={e => { setAarea(e.target.value) }}
                    >
                    </TextField>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={addAreaName}
                        onChange={e => { setAddAreaName(e.target.value) }}
                    >
                    </TextField>
                    <TextField
                        label="Description"
                        id="standard-basic"
                        variant="standard"
                        fullWidth={true}
                        type="text"
                        sx={{ mb: 5 }}
                        multiline
                        value={addAreaDesc}
                        onChange={e => { setAddAreaDesc(e.target.value) }}
                    >
                    </TextField>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, flexWrap: 'wrap' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            color="primary"
                            type="submit"
                            onClick={(e) => editCloseClick()}
                        >CANCEL</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            onClick={(e) => editSaveClick()}
                        >EDIT</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={editDialog}
                onClose={(e) => editareaCloseClick()}>
                <DialogTitle>
                    Edit Area
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        sx={{ mb: 5 }}
                        type="text"
                        value={aarea}
                        onChange={e => { setAarea(e.target.value) }}
                    >
                    </TextField>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            variant="contained"
                            onClick={(e) => editareaSaveClick()}>
                            SAVE AREA</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={addAreaDialog}
                onClose={() => setAddareaDialog(false)}>
                <DialogTitle>
                    Add Area
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        type="text"
                        sx={{ mb: 5 }}
                        value={area}
                        onChange={e => { setArea(e.target.value) }}
                    >
                    </TextField>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            variant="contained"
                            onClick={handleAddArea}
                        >ADD AREA</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={sendManagerClick}
                onClose={(e) => sendDialogClose()}>
                <DialogTitle>
                    You will not be able to modify this after submission! Are you sure you want to submit?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 10 }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={(e) => sendtoManagerClick(e)}
                        >YES</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="info"
                            onClick={sendDialogClose}
                        >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeAreaDialog}
                onClose={(e) => removeAreaDialogClose()}>
                <DialogTitle>
                    Are you sure you want to remove this area and all its items?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 10 }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={(e) => commonRemoveAreaClick(e)}
                        >YES</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="info"
                            onClick={removeAreaDialogClose}
                        >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeInspectionDialog}
                onClose={(e) => removeInspectionDialogClose()}>
                <DialogTitle>
                    Are you sure you want to remove this item?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 10 }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={(e) => deleteiconClick(e)}
                        >YES</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="info"
                            onClick={removeInspectionDialogClose}
                        >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div >
    );
}

export default MoveInLease;