import { Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Dialog, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextareaAutosize, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react'
import GroupIcon from '@mui/icons-material/Group';
import { getManagerPropertiesOverview, postTeamManagersInvite, postContractorAdd, getContractorTags, getManagerHomeIncomeExpenseFinances } from '../../../services';
import AddIcon from '@mui/icons-material/Add';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';

const Home = () => {
    const navigate = useNavigate();

    const [properties, setProperties] = useState([]);
    const [commonMapview, setcommonMapview] = useState([]);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [addManagerModal, setAddManagerModal] = useState(false);

    const [contractorTags, setContractorTages] = useState([]);
    const [contractFirstName, setContractFirstName] = useState("");
    const [contractLastName, setContractLastName] = useState("");
    const [contractEmail, setContractEmail] = useState("");
    const [contractPhoneNo, setContractPhoneNo] = useState("");
    const [contractCity, setContractCity] = useState("");
    const [contractState, setContractState] = useState("");
    const [contractSkills, setContractSkills] = useState([]);
    const [contractDesc, setContractDesc] = useState("");
    const [addContractorDialog, setAddContractorDialog] = useState(false);
    const [incomeExpense, setIncomeExpense] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        pageLoad();
    }, []);

    const pageLoad = () => {
        setLoading(true);
        let startDate = Date.parse(new Date("2023-01-01"));
        let endDate = null;
        let pro = [];
        pro.length = 0;
        let prop = [...properties];
        prop.length = 0;
        let grpProp = [];
        grpProp.length = 0;
        let inEx = [...incomeExpense];
        inEx.length = 0;
        getManagerPropertiesOverview().then((res) => {
            let getDetails = res?.data;
            getManagerHomeIncomeExpenseFinances(getDetails?.data[0]?.owner?.id, `?timeBegin=${startDate}&timeEnd=${endDate}`).then((response) => {
                let getDetails = response?.data?.data;
                getDetails.data.map((finance) => {
                    inEx.push({
                        "managerId": finance.managerId,
                        "income": finance.income,
                        "expense": finance.expense
                    })
                })
                setIncomeExpense(inEx);
            }).catch((err) => { console.log(err); });
            getDetails.data.map((e) => {
                e.propertyManagers.map((f) => {
                    if (f?.permissions?.main && f?.permissions?.owner) {
                        pro.push({
                            "id": f?.manager.id,
                            "rentAmount": e?.rentAmount,
                            "dueAmount": e?.dueAmount,
                            "serviceRequest": e?.serviceRequests.length,
                            "leaseApplications": e?.leaseApplications.length,
                            "leases": e?.leases.length,
                            "propLength": 1,
                        })
                    }
                    if (f?.permissions?.main && !f?.permissions?.owner) {
                        grpProp.push({
                            "id": f?.manager.id,
                            "name": f?.manager.user.name.first + " " + f?.manager.user.name.last,
                            "rentAmount": e?.rentAmount,
                            "dueAmount": e?.dueAmount,
                            "serviceRequest": e?.serviceRequests.length,
                            "leaseApplications": e?.leaseApplications.length,
                            "leases": e?.leases.length,
                            "propLength": 1,
                        })
                    }
                })
            })
            let prop = pro.reduce((accu, r) => {
                let { id, rentAmount, dueAmount, serviceRequest, leaseApplications, leases, propLength } = r;
                accu[id] = accu[id] || { rentAmount: 0, dueAmount: 0, serviceRequest: 0, leaseApplications: 0, leases: 0, id, propLength: 0, name: "You" };
                accu[id].rentAmount += rentAmount;
                accu[id].dueAmount += dueAmount;
                accu[id].propLength += propLength;
                accu[id].serviceRequest += serviceRequest;
                accu[id].leaseApplications += leaseApplications;
                accu[id].leases += leases;
                return accu;
            }, {});
            let proper = Object.values(prop);
            setProperties(proper);
            let groupProperty = grpProp.reduce((accu, r) => {
                let { id, name, rentAmount, dueAmount, serviceRequest, leaseApplications, leases, propLength } = r;
                accu[id] = accu[id] || { rentAmount: 0, dueAmount: 0, serviceRequest: 0, leaseApplications: 0, propLength: 0, leases: 0, id, name };
                accu[id].rentAmount += rentAmount;
                accu[id].dueAmount += dueAmount;
                accu[id].propLength += propLength;
                accu[id].serviceRequest += serviceRequest;
                accu[id].leaseApplications += leaseApplications;
                accu[id].leases += leases;
                return accu;
            }, {});
            let manager = Object.values(groupProperty);
            let groupedPeople = groupBy(manager, "id");
            setcommonMapview(groupedPeople);
        }).finally(() => {
            setLoading(false);
        });
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    const handleChangeContractSkills = (event) => {
        const {
            target: { value },
        } = event;
        setContractSkills(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const openAddManagerModal = () => {
        setAddManagerModal(true);
        setEmail("");
        setFirstName("");
        setLastName("");
    }

    const handleOpenAdd = () => {
        setAddContractorDialog(true);
        getContractorTags().then((res) => {
            setContractorTages(res.data.data);
        }).catch((err) => console.log(`Error${err}`));
    }

    const inviteClick = () => {
        let data = {
            email: email,
            firstName: firstName,
            lastName: lastName
        }
        postTeamManagersInvite(data).then((res) => {
            pageLoad();
            setAddManagerModal(false);
            toast.success(`Manager Invite to ${email} Successfully`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const contractorAddClick = () => {
        let data = {
            "firstName": contractFirstName,
            "lastName": contractLastName,
            "email": contractEmail,
            "phoneCode": "01",
            "phoneNumber": contractPhoneNo,
            "city": contractCity,
            "state": contractState,
            "skills": contractSkills,
            "starRatings": 0,
            "description": contractDesc,
        }
        postContractorAdd(data).then((res) => {
            setAddContractorDialog(false);
            pageLoad();
            toast.success("Contractor Added Successfully");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setContractPhoneNo(formattedPhoneNumber);
    };
    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }
    const onChangeHandler = (e) => {
        const contractPhoneNo = e.target.value
        setContractPhoneNo(contractPhoneNo)
    }

    const propertyClick = (id) => {
        navigate(`/manager/properties/${id}`)
    }

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "1.5rem" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", flexGrow: 1 }}>
                    <div style={{ width: "100%", maxWidth: "1250px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                            <div style={{ display: "flex", margin: "10px", alignItems: "center", flexWrap: "wrap" }}>
                                <DashboardIcon sx={{ fontSize: 25, fontWeight: "bolder", width: '28px', height: "28px", backgroundColor: "#4a90e2", color: "white", borderRadius: "5px", padding: "5px" }} />
                                <p style={{ fontSize: 22, textAlign: "center", fontWeight: "bolder", marginLeft: "10px" }}>Dashboard</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                            {properties.map((e, i) => (
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", width: "100%" }} key={i}>
                                    <Card variant="outlined" style={{ display: "flex", flexDirection: "column", maxWidth: "400px", borderRadius: "10px", margin: "10px", width: "100%", alignItems: "center", boxShadow: "5px 5px 5px #D3D3D3" }} onClick={() => propertyClick(e.id)}>
                                        <div style={{ padding: "10px", textAlign: "center" }}>
                                            <div style={{ paddingBottom: "2px", paddingTop: "12px", paddingRight: "12px", paddingLeft: "12px", fontSize: "20px", fontWeight: "bold" }}>
                                                {e.name}
                                            </div>
                                            <div style={{ fontSize: "18px" }}>
                                                Managing {e.propLength} Properties
                                            </div>
                                            {incomeExpense.map((finance, index) => (e.id === finance.managerId ?
                                                <div style={{ fontSize: "16px" }} key={index}>
                                                    Income: <span style={{ fontWeight: "bold", color: "green" }}>${finance.income.toFixed(2)}</span> Expense: <span style={{ fontWeight: "bold", color: "red" }}>${finance.expense.toFixed(2)}</span>
                                                </div>
                                                : ""))}
                                            <div style={{ display: "flex", justifyContent: "center", width: "100%", flexWrap: "wrap" }}>
                                                <div style={{ padding: "15px" }}>
                                                    <Avatar src="/broken-image.jpg" style={{ fontSize: "125px", height: '125px', width: "125px", borderRadius: "100%", padding: "10px", backgroundColor: "black" }} />
                                                </div>
                                                <div>
                                                    <div>
                                                        <div style={{ textAlign: "left", fontSize: "16px", fontWeight: 500, marginTop: "10px", marginBottom: "5px" }}>
                                                            <span style={{ textAlign: "left", fontSize: "20px", fontWeight: 500, color: "red" }}>
                                                                {e.dueAmount === 0 ? <span style={{ color: "green" }}>All  <span style={{ fontSize: "16px", color: "black" }}>Rent Collected</span></span> : e.dueAmount.toLocaleString('en')}{e.dueAmount !== 0 ? <span style={{ fontSize: "16px", color: "black" }}> Post Due</span> : ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ textAlign: "left", fontSize: "16px", fontWeight: 500, marginTop: "10px", marginBottom: "5px" }}>
                                                            <span style={{ textAlign: "left", fontSize: "20px", fontWeight: 500, color: "red" }}>{e.serviceRequest === 0 ? <span style={{ color: "green" }}>No</span> : e.serviceRequest} </span>
                                                            Service Requests
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ textAlign: "left", fontSize: "16px", fontWeight: 500, marginTop: "10px", marginBottom: "5px" }}>
                                                            <span style={{ textAlign: "left", fontSize: "20px", fontWeight: 500, color: "red" }}>{e.leases === 0 ? <span style={{ color: "green" }}>No</span> : e.leases} </span>
                                                            Lease Pending
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ textAlign: "left", fontSize: "16px", fontWeight: 500, marginTop: "10px", marginBottom: "5px" }}>
                                                            <span style={{ textAlign: "left", fontSize: "20px", fontWeight: 500, color: "red" }}>{e.leaseApplications === 0 ? <span style={{ color: "green" }}>No</span> : e.leaseApplications} </span>
                                                            Applications
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>))}
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            {Object.keys(commonMapview).map((cat, i) => (commonMapview[cat].map((f) => (
                                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }} key={i}>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", width: "100%" }}>
                                        <Card variant="outlined" style={{ display: "flex", flexDirection: "column", borderRadius: "10px", maxWidth: "450px", margin: "10px", width: "100%", alignItems: "center", boxShadow: "5px 5px 5px #D3D3D3" }} onClick={() => propertyClick(cat)}>
                                            <div style={{ padding: "10px", textAlign: "center" }}>
                                                <div style={{ paddingBottom: "2px", paddingTop: "12px", paddingRight: "12px", paddingLeft: "12px", fontSize: "20px", fontWeight: "bold" }}>
                                                    {f.name}
                                                </div>
                                                <div style={{ fontSize: "18px" }}>
                                                    Managing {f.propLength} Properties
                                                </div>
                                                {incomeExpense.map((finance) => (f.id === finance.managerId ?
                                                    <div style={{ fontSize: "16px" }}>
                                                        Income: <span style={{ fontWeight: "bold", color: "green" }}>${finance.income.toFixed(2)}</span> Expense: <span style={{ fontWeight: "bold", color: "red" }}>${finance.expense.toFixed(2)}</span>
                                                    </div>
                                                    : ""))}
                                                <div style={{ display: "flex", justifyContent: "center", width: "100%", flexWrap: "wrap" }}>
                                                    <div style={{ padding: "15px" }}>
                                                        <Avatar src="/broken-image.jpg" style={{ fontSize: "125px", height: '125px', width: "125px", borderRadius: "100%", padding: "10px", backgroundColor: "black" }} />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <div style={{ textAlign: "left", fontSize: "16px", fontWeight: 500, marginTop: "10px", marginBottom: "5px" }}>
                                                                <span style={{ textAlign: "left", fontSize: "20px", fontWeight: 500, color: "red" }}>{f.dueAmount === 0 ? <span style={{ color: "green" }}>All  <span style={{ fontSize: "16px", color: "black" }}>Rent Collected</span></span> : f.dueAmount.toLocaleString('en')} {f.dueAmount !== 0 ? <span style={{ fontSize: "16px", color: "black" }}>Post Due</span> : ""} </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{ textAlign: "left", fontSize: "16px", fontWeight: 500, marginTop: "10px", marginBottom: "5px" }}>
                                                                <span style={{ textAlign: "left", fontSize: "20px", fontWeight: 500, color: "red" }}>
                                                                    {f.serviceRequest === 0 ? <span style={{ color: "green" }}>No </span> : f.serviceRequest} </span>
                                                                Service Requests
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{ textAlign: "left", fontSize: "16px", fontWeight: 500, marginTop: "10px", marginBottom: "5px" }}>
                                                                <span style={{ textAlign: "left", fontSize: "20px", fontWeight: 500, color: "red" }}>{f.leases === 0 ? <span style={{ color: "green" }}>No </span> : f.leases} </span>
                                                                Lease Pending
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{ textAlign: "left", fontSize: "16px", fontWeight: 500, marginTop: "10px", marginBottom: "5px" }}>
                                                                <span style={{ textAlign: "left", fontSize: "20px", fontWeight: 500, color: "red" }}>{f.leaseApplications === 0 ? <span style={{ color: "green" }}>No </span> : f.leaseApplications} </span>
                                                                Applications
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>))))}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirecion: "row", justifyContent: "center", flexWrap: "wrap", gap: 50 }}>
                <div>
                    <Button variant='contained' color='success' sx={{ fontSize: 17, borderRadius: 5 }} onClick={openAddManagerModal}><AddIcon /> PROPERTY MANAGER</Button>
                </div>
                <div>
                    <Button variant='contained' color='secondary' sx={{ fontSize: 17, borderRadius: 5 }} onClick={handleOpenAdd}><AddIcon /> CONTRACTOR</Button>
                </div>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={addManagerModal}
                onClose={() => setAddManagerModal(false)}>
                <CardContent>
                    <p className='paragraph'>Add Manager</p>
                </CardContent>
                <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px", alignItems: "stretch" }}>
                    <div style={{ flex: 1 }}>
                        <TextField
                            label="First Name"
                            margin="dense"
                            variant="outlined"
                            fullWidth={true}
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value) }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField
                            label="Last Name"
                            margin="dense"
                            variant="outlined"
                            fullWidth={true}
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value) }}
                        />
                    </div>
                </CardContent>
                <CardContent sx={{ pt: 0 }}>
                    <TextField
                        label="Email"
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                </CardContent>
                <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                    <Button sx={{ fontSize: "small", fontWeight: 500 }} variant="contained" type="submit" color="success" onClick={inviteClick}>INVITE</Button>
                    <Button sx={{ fontSize: "small", fontWeight: 500 }} type="submit" color="error" onClick={() => setAddManagerModal(false)}>CANCEL</Button>
                </CardContent>
            </Dialog>
            <Dialog
                open={addContractorDialog}
                onClose={() => setAddContractorDialog(false)}>

                <CardContent>
                    <Typography id="modal-modal-title" textAlign='center' variant="h6" component="h2">
                        Add Contractor
                    </Typography>
                </CardContent>

                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '18ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="First Name" id="outlined-size-small" size="small"
                            value={contractFirstName}
                            onChange={(e) => { setContractFirstName(e.target.value) }} />
                        <TextField label="Last Name" id="outlined-size-small" size="small"
                            value={contractLastName}
                            onChange={(e) => { setContractLastName(e.target.value) }} />

                    </Box>

                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Email" id="outlined-size-small" size="small"
                            value={contractEmail}
                            onChange={(e) => { setContractEmail(e.target.value) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Phone No" id="outlined-size-small" size="small"
                            value={contractPhoneNo}
                            onChange={(e) => { onChangeHandler(e); handleInput(e) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="City" id="outlined-size-small" size="small"
                            value={contractCity}
                            onChange={(e) => { setContractCity(e.target.value) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Select State</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                label="Select State"
                                value={contractState}
                                onChange={(e) => {
                                    setContractState(e.target.value);
                                }}
                            >
                                <MenuItem value="-Select-">-Select-</MenuItem>
                                <MenuItem value="CA">California</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl sx={{ width: 1 }}>
                            <InputLabel id="demo-multiple-chip-label">Skills Required</InputLabel>
                            <Select
                                sx={{ width: 1 }}
                                size="small"
                                variant="standard"
                                label="Skills Required"
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                input={<OutlinedInput label="Skills Required" />}
                                value={contractSkills}
                                onChange={handleChangeContractSkills}
                                renderValue={(ContractSelected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {ContractSelected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {contractorTags && contractorTags ? contractorTags.map((e, i) => (
                                    <MenuItem key={i} value={e}>
                                        {e}
                                    </MenuItem>
                                )) : undefined}
                            </Select>
                        </FormControl>
                        <p style={{ fontSize: 11, marginTop: "0px", marginBottom: "0px" }}>Press Enter or type","after entering a skill</p>

                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Description"
                            style={{ width: 310 }}
                            value={contractDesc}
                            onChange={(e) => { setContractDesc(e.target.value) }}
                        />
                    </Box>
                </CardContent>
                <CardContent style={{ display: "flex", flexDirection: "row", justifyContent: "right", marginTop: "-29px" }}>
                    <Button variant="contained" color="success"
                        type="submit"
                        sx={{ fontWeight: "bold" }}
                        size="small"
                        onClick={contractorAddClick}
                    >Add</Button>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}
export default Home;
