const { Link } = require("react-router-dom");

function ManagerBar() {
    return (
        <div className="manager-bar">
            <Link
                className="manager-bar-item"
                to="/manager/properties"
            >
                Properties
            </Link>
            <Link
                className="manager-bar-item"
                to="/manager/properties"
            >
                Financials
            </Link>
            <Link
                className="manager-bar-item"
                to="/manager/properties"
            >
                Team
            </Link>
        </div>
    );
}

export default ManagerBar;
