import { Button, CardContent, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import TabMenu from "../properties/edit/tabMenu";
import { getServiceRequestMembers, getServiceRequestSuggestedTags, postManagerServiceRequest, getSingleProperty } from "../../../services";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const priority = [
    {
        value: "0",
        label: "Low",
    },
    {
        value: "1",
        label: "Medium",
    },
    {
        value: "2",
        label: "High",
    },
];
function NewServiceRequest() {
    const params = useParams();
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [name, setName] = useState("");
    const [privateMan, setPrivateMan] = useState(false);
    const [prior, setPrior] = useState();
    const [type, setType] = useState();
    const [assi, setAssi] = useState();
    const [propertyId, setPropertyId] = useState("");
    const [propertyAddress, setPropertyAddress] = useState({});
    const [images, setImages] = useState([]);

    const [assignee, setAssignee] = useState([]);
    const [suggestedTags, setSuggestedTags] = useState();

    const [skillsRequired, setSkillsRequired] = useState([]);

    useEffect(() => {
        pageLoad();
    }, []);

    const pageLoad = () => {
        getSingleProperty(params.propertyId).then((res) => {
            let data = res.data.data;
            setPropertyId(data.id);
            setPropertyAddress(data.address)
        })
        getServiceRequestMembers(params.propertyId).then((res) => {
            let data = res.data.data;
            let details = [...data];
            let assign = [...assignee];
            assign.length = 0;
            details.map((e, i) => {
                assign.push({
                    "name": e.name,
                    "type": e.type,
                    "id": e.id,
                })
                setAssignee(assign);
            })
        });
        getServiceRequestSuggestedTags(params.propertyId).then((res) => {
            let data = res.data.data;
            setSuggestedTags(data);
        })
    }

    const createServiceRequestClick = () => {
        let formData = new FormData();
        formData.append("propertyId", propertyId);
        formData.append("title", title);
        formData.append("description", desc);
        formData.append("privateToManagers", privateMan);
        formData.append("priority", prior);
        formData.append("assignee", JSON.stringify({ "type": type, assignee: assi }));
        formData.append("tags", skillsRequired);
        Array.from(images).forEach(item => { formData.append("attachments", item) });

        postManagerServiceRequest(formData, `?groupStatus=${"serviceRequest"}`).then((res) => {
            setImages([]);
            navigate(`/manager/properties/${params.propertyId}/service-requests`);

        })
    }

    const selectClick = (e, name, id) => {
        console.log("select", name, id)
        setType(name);
        setAssi(id);
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSkillsRequired(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleImagesAdd = (e) => {
        setImages([...images, ...e.target.files]);
    }

    const removeImage = (index) => {
        let toDelete;
        const newImages = images.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });
        setImages(newImages);
    };

    const renderImages = () => {
        return Array.from(images).map((item, index) => {
            const url = URL.createObjectURL(item)
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={url} alt="images" style={{ height: "110px", width: "175px", marginTop: "20px" }} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={() => { removeImage(index) }}>
                            <DeleteIcon color="error" />
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    return (
        <>
            <CardContent>
                <CardContent>
                    <p style={{ fontSize: 19, fontWeight: "bold", textAlign: "center" }}>{propertyAddress.line1 + " " + propertyAddress.line2 + " " + propertyAddress.unitNumber + " " + propertyAddress.city + " " + propertyAddress.state + "-" + propertyAddress.zipCode}</p>
                </CardContent>
                <CardContent>
                    <TabMenu />
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ fontSize: 15, borderRadius: 20, background: '#3f48cc' }}
                        onClick={() => { navigate(`/manager/properties/${params.propertyId}/service-requests/re-occuring`) }}
                    ><AddIcon />{" "}RE-OCCURING SERVICE REQUEST</Button>
                </CardContent>
                <CardContent>
                    <p style={{ fontSize: 19, fontWeight: "bold" }}>Create Service Request</p>
                </CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} sx={{ borderRadius: '5px' }}>

                            <CardContent>
                                <TextField
                                    fullWidth={true}
                                    variant="outlined"
                                    label="Title *"
                                    type="text"
                                    value={title}
                                    onChange={e => { setTitle(e.target.value) }} />
                            </CardContent>
                            <CardContent>
                                <TextField
                                    fullWidth={true}
                                    variant="outlined"
                                    label="Details *"
                                    type="text"
                                    rows={5}
                                    multiline
                                    value={desc}
                                    onChange={e => { setDesc(e.target.value) }} />
                            </CardContent>
                            <CardContent>
                                <div className="container">
                                    {renderImages()}
                                    <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
                                        <input type="file" id="uploadBtn" accept="image/*"
                                            onChange={handleImagesAdd}
                                            multiple
                                        />
                                        <label htmlFor="uploadBtn" style={{ fontSize: 13 }}>
                                            Drop images / click to Upload</label>
                                    </div>
                                </div>
                            </CardContent>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ borderRadius: '5px' }}>
                            <CardContent>
                                <FormControl sx={{ width: 1 }}>
                                    <TextField
                                        sx={{ width: 1 }}
                                        label="Choose Assignee"
                                        select
                                        margin="dense"
                                        variant="outlined"
                                        value={name}
                                        onChange={e => { setName(e.target.value) }}
                                    >
                                        {assignee.map((details, i) => (
                                            <MenuItem key={i} value={details.name} onClick={(e) => selectClick(e, details.type, details.id)}>
                                                {details.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </CardContent>
                            <CardContent>
                                <FormControl sx={{ width: 1 }}>
                                    <TextField
                                        sx={{ width: 1 }}
                                        label="Chosse Priority level"
                                        select
                                        margin="dense"
                                        variant="outlined"
                                        value={prior}
                                        onChange={(e) => {
                                            setPrior(e.target.value);
                                        }}
                                    >
                                        {priority.map((pri) => (
                                            <MenuItem key={pri.value} value={pri.value}>
                                                {pri.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </CardContent>
                            <CardContent>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox />}
                                        checked={privateMan}
                                        onChange={() => { setPrivateMan(!privateMan) }}
                                        label="Private to Managers" />
                                </FormGroup>
                                <p style={{ fontSize: 13, color: "grey" }}>Hide Service Request from Tenants</p>
                            </CardContent>
                            <FormControl sx={{ width: 1 }}>
                                <InputLabel id="demo-multiple-chip-label">Skills Required</InputLabel>
                                <Select
                                    sx={{ width: 1 }}
                                    variant="standard"
                                    label="Skills Required"
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={skillsRequired}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {suggestedTags && suggestedTags ? suggestedTags.map((e, i) => (
                                        <MenuItem key={i} value={e}>
                                            {e}
                                        </MenuItem>
                                    )) : undefined}
                                </Select>
                            </FormControl>
                            <p style={{ fontSize: 13, color: "grey" }}>Click Enter or add " , " after entering tag</p>
                        </Grid>
                    </Grid>
                </Box>
                <CardContent style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <CardContent></CardContent>
                    <CardContent>
                        <Button variant="contained" color="success"
                            type="submit"
                            style={{ fontSize: 15 }}
                            onClick={createServiceRequestClick}
                        >CREATE SERVICE REQUEST</Button>
                    </CardContent>
                </CardContent>
            </CardContent>
        </>
    );
}

export default NewServiceRequest;