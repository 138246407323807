import { Button, CardContent } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import axios from 'axios'
import { managerNoticeSend } from "../../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolTip from "../../../components/ToolTip";


function NoticePreview() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { preview, property, tenant, notic } = state;

    let sigPad = useRef(null);
    const [isSigned, setIsSigned] = useState(false);
    const [timeGet, settimeGet] = useState('');
    const [ip, setIP] = useState('');

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        setIP(res.data.IPv4);
    }

    useEffect(() => {
        var today = new Date();
        var str = today.toGMTString();
        settimeGet(str)
        //passing getData method to the lifecycle method
        getData();
    }, []);

    const clearSig = (e) => {
        e.preventDefault();
        sigPad.clear();
        setIsSigned(false)
    }

    const sendClick = () => {
        let data = {
            "leaseId": notic.leaseId,
            "from": notic.from,
            "to": notic.to,
            "subject": notic.subject,
            "salutation": notic.salutation,
            "body": notic.body,
            "signature": sigPad.getTrimmedCanvas().toDataURL('image/png'),

        }
        managerNoticeSend(data).then((res) => {
            toast.success("Lease Renewal is Scheduled Successfully");
            setTimeout(() => {
                navigate(`/manager/properties/${property?.id}/lease`)
            }, 2000);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const continueClick = (e, id) => {
        navigate(`/manager/notice/prepare/${id}`);
    }

    return (
        <div style={{ padding: '30px' }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: 'wrap', gap: 10 }}>
                <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap' }}>
                    <span style={{ fontSize: 20, fontWeight: "bold", paddingBottom: '20px' }}>Tenant</span>
                    <span style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 10, alignItems: 'center' }}>
                        <span style={{ fontSize: 16, fontWeight: "bold" }}>{tenant?.leaseTenants[0]?.name?.first + " " + tenant?.leaseTenants[0]?.name?.last}</span>
                        <ToolTip phone={tenant?.leaseTenants[0]?.phone?.code + " " + tenant?.leaseTenants[0]?.phone?.number} email={tenant?.leaseTenants[0]?.email?.id} />
                    </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap', textAlign: 'right' }}>
                    <span style={{ fontSize: 20, fontWeight: "bold", paddingBottom: '20px' }}>Property</span>
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.line1}</span>
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.line2} {property.unitNumber}</span>
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.city}</span>
                    <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.state} - {property?.address?.zipCode}</span>
                </div>
            </div>
            <p className="preview-notice-heading">send Notice</p>
            <div dangerouslySetInnerHTML={{ __html: preview }} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ cursor: "pointer" }} className="notice-preview-signbox">
                    <SignaturePad penColor='#4285f4'
                        onEnd={() => { setIsSigned(true) }}
                        canvasProps={{ width: 300, height: 200, className: 'sig-canvas curSig' }}
                        ref={(ref) => { sigPad = ref }} />
                    <span style={{ display: 'flex', justifyContent: 'right' }}>
                        <Button onClick={(e) => clearSig(e)}>Clear</Button>
                    </span>
                </div>
                <div>
                    <p style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>{property?.propertyManagers[0]?.manager?.user?.name?.first + " " + property?.propertyManagers[0]?.manager?.user?.name?.last}</p>
                </div>
                <div>
                    <p style={{ fontSize: 16, fontWeight: "bold" }}>Manager</p>
                </div>
                <div>
                    <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {ip}</p>
                </div>
                <div>
                    <p style={{ fontSize: 14, fontWeight: "bold" }}>{timeGet}</p>
                </div>
            </div>
            <CardContent style={{ display: 'flex', justifyContent: 'right', flexWrap: "wrap", gap: 10 }}>
                <Button
                    variant="contained"
                    style={{ fontSize: 15 }}
                    color="success"
                    onClick={(e) => continueClick(e, tenant?.id)}>
                    CONTINUE EDITING
                </Button>
                <Button
                    variant="contained"
                    style={{ fontSize: 15 }}
                    color="warning"
                    disabled={isSigned ? false : true}
                    onClick={(e) => sendClick(e)}>
                    SEND NOTICE
                </Button>
            </CardContent>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default NoticePreview;