import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react'
import { CardContent } from 'semantic-ui-react';
import { getmanagerInvite, postmanagerInvite } from '../../../services';
import PhoneIcon from '@mui/icons-material/Phone';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";
import { useNavigate } from 'react-router-dom';

const ManagerInvite = () => {
    // Query Params
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [phone, setPhone] = useState("");
    const [resData, setResData] = useState({});
    const [err,setErr]=useState("");

    useEffect(() => {
        getmanagerInvite(`?token=${token}`).then((res) => {
            let response = res.data.data;
            setResData(response);
            setEmail(response.inviteeEmail);
            setFirst(response.inviterFirstName);
            setLast(response.inviteeLastName);
        }).catch((err) => {setErr(err.response.data.error.message);console.log(`Error${err}`)});
    }, []);

    const btnClick = () => {
        let data = {
            email: email,
            firstName: first,
            lastName: last,
            phoneCountryCode: "+1",
            phoneNumber: phone,
            response: "ACCEPT",
        }
        postmanagerInvite(`?token=${token}`, data).then((res) => {
            toast.success(`Manager Invited Successfully`);
            navigate(`/login`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const copyPhone = (phone) => {
        copy(phone);
        toast.info(`Phone Number is copied to Clipboard`)
    }

    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setPhone(formattedPhoneNumber);
    };
    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }
    const onChangeHandler = (e) => {
        const phoneNo = e.target.value
        setPhone(phoneNo)
    };
    return (
        <>
            <div className="page login-page">
                <form className="login-form">
                    {err !=="Invitation Not Found"?
                    <CardContent sx={{ p: 0 }}>
                        <CardContent>
                            <p style={{ fontSize: 25, textAlign: "center", fontWeight: "bolder", color: "#4a90e2", marginBottom: "25px" }}>Lease Invitation</p>
                        </CardContent>
                        <CardContent>
                            <p style={{ fontSize: 17, fontWeight: "bold", marginBottom: "20px" }}>Dear {resData.inviteeFirstName} {resData.inviteeLastName}</p>
                        </CardContent>
                        <CardContent>
                            <p style={{ fontSize: 17, fontWeight: "bold", marginBottom: "0px" }}>{resData.inviterFirstName} {resData.inviterLastName} has invited you to manage property with</p>
                            <p style={{ fontSize: 17, fontWeight: "bold", marginBottom: "20px" }}>Rent.unityon.</p>
                        </CardContent>
                        <CardContent>
                            <p style={{ fontSize: 17, fontWeight: "bold", marginBottom: "20px" }}>Contact call <span style={{ color: "#4a90e2", cursor: "pointer" }} onClick={() => copyPhone(resData.inviterPhoneNumber)}><PhoneIcon /> {resData.inviterPhoneCode} {resData.inviterPhoneNumber}</span> for more info</p>
                        </CardContent>
                        <CardContent style={{ marginBottom: "15px" }}>
                            <TextField label="Email" variant="standard"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                fullWidth={true}
                                disabled={true}
                            />
                        </CardContent>
                        <CardContent style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "15px",
                            gap: 10,
                            flexWrap:'wrap'
                        }}
                        >
                            <CardContent>
                                <TextField label="First Name *" variant="standard"
                                    value={first}
                                    onChange={(e) => { setFirst(e.target.value) }}
                                    fullWidth={true}
                                />
                            </CardContent>
                            <CardContent>
                                <TextField label="Last Name" variant="standard"
                                    value={last}
                                    onChange={(e) => { setLast(e.target.value) }}
                                    fullWidth={true}
                                />
                            </CardContent>
                        </CardContent>
                        <CardContent style={{ marginBottom: "15px" }}>
                            <TextField label="Phone No*" variant="standard"
                                value={phone}
                                onChange={(e) => { onChangeHandler(e); handleInput(e) }}
                                fullWidth={true}
                            />
                        </CardContent>
                        <CardContent style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                            <Button variant="contained" color="primary" fullWidth={true}
                                disabled={first !== "" && last !== "" && phone !== "" ? false : true} onClick={btnClick}>ACCEPT INVITATION</Button>
                        </CardContent>
                    </CardContent>:
                    <CardContent>
                        <p style={{ fontSize: 17, textAlign: "center", fontWeight: "bold" }}>Invalid Invitation</p>
                        <p style={{ fontSize: 15, textAlign: "center",color:"grey" }}>Ask your Manager to resend your Inivtation</p>
                        </CardContent>}
                </form>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
        </>
    )
}

export default ManagerInvite;