import React, { useEffect, useRef, useState } from 'react'
import { Box, Breadcrumbs, Button, CardContent, CircularProgress, FormControlLabel, Modal, Radio, RadioGroup, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useNavigate, useParams } from 'react-router-dom';
import { acceptReview, addManagerLeaseApplicationAttachment, getApiBaseURL, getManagerLeaseApplication, getProperties, getSingleProperty, managerLeaseAgreementRevise, postConsider, postDismiss, postReview, removeManagerLeaseApplicationAttachment, reviewReject, reviewUnreject } from '../../../../services';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import moment from 'moment';
import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from "@mui/icons-material/Print";
import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import { Popup } from 'semantic-ui-react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LeaseAppNew() {
  const params = useParams();
  const navigate = useNavigate();
  const fileRef = useRef();

  const [application, setApplication] = useState(null);
  const [property, setProperty] = useState(null);
  const [sign, setSign] = useState("");
  const [statuss, setStatuss] = useState("");
  const [dismissStatus, setdismissStatus] = useState();

  // remarks and Notes with error field
  const [remarksError, setremarksError] = useState("");
  const [notesError, setnotesError] = useState("");
  const [managerReview, setManagerReview] = useState("");
  const [managerNotes, setManagerNotes] = useState("");

  // Dialog Box
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  // Loading
  const [loading, setLoading] = useState(true);

  const applicantSignature = (leaseID, applicant = {}) => {
    if (applicant?.signature) {
      var config = "";
      var imageDataUrl = "";
      const authToken = sessionStorage.getItem("authToken");
      if (authToken != undefined) {
        config = {
          headers: { Authorization: `Bearer ${authToken}` },
        };
      }
      fetch(
        `${getApiBaseURL()}/manager/lease-applications/${leaseID}/applicants/${applicant.id}/signature/file`,
        config
      ).then((res) => {
        return res.blob();
      }).then((blob) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          imageDataUrl = reader.result;
          setSign(imageDataUrl);
        };
      });
    }
  };

  const getApplication = async () => {
    setLoading(true);
    await getManagerLeaseApplication(params.applicationId).then((res) => {
      setApplication(res?.data?.data);
      applicantSignature(res?.data?.data?.id, res?.data?.data?.applicants ? res?.data?.data?.applicants[0] : null);
      setManagerReview(res?.data?.data?.review?.remarks);
      setManagerNotes(res?.data?.data?.review?.notes);
      setStatuss(res?.data?.data?.status);
      setdismissStatus(res.data.data.review.dismissed);
      if (res?.data?.data?.property) {
        return getSingleProperty(res?.data?.data?.property);
      }
    }).then((response) => {
      setProperty(response?.data?.data);
      setLoading(false);
    }).catch(err => { setLoading(false); });
  }

  useEffect(() => {
    getApplication();
  }, []);

  const statusValidate = (status) => {
    if (["APPLICANT_SUBMITTED", "MANAGER_SET_UP_LEASE_SUCCESSFULLY", "APPLICANT_LEASE_AGREEMENT_SIGNED"].includes(status)) {
      return (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <CheckCircleIcon
            className="iconImage"
            color="success"
          />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Application Submitted</span>
        </span>
      );
    }
    else {
      return (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <WarningIcon className="iconImage" color="warning" />{" "}
          <span style={{ fontWeight: 'bold', fontSize: 15 }}>Application in Progress</span>
        </span>
      );
    }
  };

  const incomeScoreCalculation = (applicant = {}) => {
    const rentAmount = property?.rentAmount;
    let applicantsTotalIncome = 0;
    let incomeScore = 0;
    let combinedApplicantsTotalIncome = 0;
    applicantsTotalIncome = applicant.incomeDetails?.monthlyIncome || 0;
    if (applicant.incomeDetails?.hasOtherIncome) {
      applicantsTotalIncome =
        applicantsTotalIncome + applicant.incomeDetails?.otherMonthlyIncome;
    }
    combinedApplicantsTotalIncome += applicantsTotalIncome;
    incomeScore = rentAmount / combinedApplicantsTotalIncome;
    if (0.25 >= incomeScore) {
      return (
        <Popup trigger={
          <div style={{ cursor: "pointer", width: 70 }}>
            <Rating
              name="incomeScoreRating"
              style={{ color: "#8CC659" }}
              readOnly
              value={3}
              max={3}
            />
          </div>}
          position="top center"
        >
          <Popup.Content>
            {combinedApplicantsTotalIncome}
          </Popup.Content>
        </Popup>
      );
    } else if (incomeScore > 0.25 && 0.33 >= incomeScore) {
      return (
        <Popup trigger={
          <div style={{ cursor: "pointer", width: 70 }}>
            <Rating
              name="incomeScoreRating"
              style={{ color: "#F96B25" }}
              readOnly
              value={2}
              max={3}
            />
          </div>}
          position="top center"
        >
          <Popup.Content>
            {combinedApplicantsTotalIncome}
          </Popup.Content>
        </Popup>
      );
    } else if (incomeScore > 0.33) {
      return (
        <Popup trigger={
          <div style={{ cursor: "pointer", width: 70 }}>
            <Rating
              name="incomeScoreRating"
              style={{ color: "#DC3545" }}
              readOnly
              value={1}
              max={3}
            />
          </div>}
          position="top center"
        >
          <Popup.Content>
            {combinedApplicantsTotalIncome}
          </Popup.Content>
        </Popup>
      );
    }
  };

  const creditScoreCalculate = (score = 0) => {
    if (score === 0) {
      return null;
    }
    else if (score > 0 && score < 580) {
      return (
        <Popup
          trigger={
            <div style={{ cursor: "pointer", width: 70 }}>
              <Rating
                name="creditScoreRating"
                readOnly
                style={{ color: "#DC3545" }}
                value={1}
                max={3}
              />
            </div>}
          position="top center"
        >
          <Popup.Content>
            {score}
          </Popup.Content>
        </Popup>
      );
    }
    else if (score >= 580 && score <= 680) {
      return (
        <Popup
          trigger={
            <div style={{ cursor: "pointer", width: 70 }}>
              <Rating
                name="creditScoreRating"
                readOnly
                style={{ color: "#F96B25" }}
                value={2}
                max={3}
              /></div>}
          position="top center"
        >
          <Popup.Content>
            {score}
          </Popup.Content>
        </Popup>
      );
    }
    else if (score > 681) {
      return (
        <Popup trigger={
          <div style={{ cursor: "pointer", width: 70 }}>
            <Rating
              name="creditScoreRating"
              readOnly
              style={{ color: "#8CC659" }}
              value={3}
              max={3}
            />
          </div>}
          position="top center"
        >
          <Popup.Content>
            {score}
          </Popup.Content>
        </Popup>
      );
    }
  };
  const evictionCheck = (isEviction) =>
    isEviction ? (
      <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
        <WarningIcon
          className="iconImage"
          color="warning"
        />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Eviction Reported</span>
      </span>
    ) : (
      <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
        <CheckCircleIcon
          className="iconImage"
          color="success"
        />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>No reports of eviction</span>
      </span>
    );

  const criminalCheck = (isCriminal) =>
    isCriminal ? (
      <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
        <WarningIcon
          color="warning"
          className="iconImage"
        />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Criminal charges/felony</span>
      </span>
    ) : (
      <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
        <CheckCircleIcon
          className="iconImage"
          color="success"
        />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>No criminal charges/felony</span>
      </span>
    );

  const checkEmployerContact = (review = {}, pos = 0) => {
    const data = review.leaseApplicantsReview[pos];
    if (data?.currentEmployerContacted) {
      return data?.currentResidenceManagerTestimony != "Bad" ? (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <CheckCircleIcon className="iconImage" color="success" />
          {" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Employer Contacted</span>
        </span>
      ) : (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <WarningIcon className="iconImage" color="warning" />
          {" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Employer Contacted</span>
        </span>
      );
    } else {
      return (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <WarningIcon
            className="iconImage"
            color="warning"
          />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Employer Not contacted</span>
        </span>
      );
    }
  };

  const checkManagerContact = (review = {}, pos = 0) => {
    const data = review.leaseApplicantsReview[pos];
    if (data?.currentResidenceManagerContacted) {
      return data?.currentResidenceManagerTestimony != "Bad" ? (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <CheckCircleIcon
            className="iconImage"
            color="success"
          />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Good standing with Residence Manager</span>
        </span>
      ) : (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <WarningIcon
            className="iconImage"
            color="warning"
          />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Not good standing with Manager</span>
        </span>
      );
    } else {
      return (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <WarningIcon
            className="iconImage"
            color="warning"
          />{" "}<span style={{ fontWeight: 'bold', fontSize: 15 }}>Manager Not contacted</span>
        </span>
      );
    }
  };

  const onClickRadioButton = (isSelected, field, applicantId, index) => {
    const newData = { ...application };
    if (newData.review && newData.review.leaseApplicantsReview && newData.review.leaseApplicantsReview[index]) {
      newData.review.leaseApplicantsReview[index][field] = isSelected;
      setApplication(newData);
    } else {
      let data = newData.review && newData.review.leaseApplicantsReview;
      if (!data) {
        data = [];
        newData.review = newData.review || {};
        newData.review.leaseApplicantsReview = data;
      }
      data[index] = {
        applicantId: applicantId,
        [field]: isSelected
      };
      setApplication(newData);
    }
  };

  const paystubClick = async (id, leaseID, url) => {
    var config = "";
    const authToken = sessionStorage.getItem("authToken");
    if (authToken != undefined) {
      config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
    }
    try {
      fetch(
        `${getApiBaseURL()}/manager/lease-applications/${leaseID}/applicants/${id}/verification-files/${url}`,
        config
      )
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(blob);
          reader.onload = function () {
            var imageDataUrl = reader.result;
            const link = document.createElement("a");
            link.href = imageDataUrl;
            if (
              blob.type == "image/png" ||
              blob.type == "image/gif" ||
              blob.type == "image/jpeg" ||
              blob.type == "image/svg" ||
              blob.type == "image/jpg"
            ) {
              const newTab = window.open();
              newTab?.document.write(
                `<!DOCTYPE html><head><title>Document preview</title></head><body style="margin: auto; background: #0e0e0e; height: 100%;"><img align="center"  src="${imageDataUrl}"  ></body></html>`
              );

              newTab?.document.close();
            }
            if (blob.type == "application/pdf") {
              link.setAttribute("download", "paystub.pdf");
              document.body.appendChild(link);
              link.click();
            }
          };
        })
    } catch (err) {
      toast.error(err.response.data.error.message);
    }
  };

  const Attachment = (e) => {
    var file = e.target.files[0] || undefined,
      supportedFormats = ["application/pdf", "image/png", "image/jpeg"];
    if (file && file.type) {
      if (0 > supportedFormats.indexOf(file.type)) { 
      } else {
        let form = new FormData();
        form.append("file", e.target.files[0]);
        addManagerLeaseApplicationAttachment(params.applicationId, form).then((res) => {
          getApplication();
        }).catch((err) => {
          toast.error(err.response.data.error.message)
        });
      }
    }
  };

  const attachmentClick = (e, id) => {
    var config = "";
    e.preventDefault();
    const authToken = sessionStorage.getItem("authToken");
    if (authToken != undefined) {
      config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
    }
    fetch(
      `${getApiBaseURL()}/manager/lease-applications/${params.applicationId}/review/files/${id}`,
      config
    )
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          var imageDataUrl = reader.result;

          const link = document.createElement("a");
          link.href = imageDataUrl;
          if (blob.type == "image/png" || blob.type == "image/jpeg") {
            const newTab = window.open();
            newTab?.document.write(
              `<!DOCTYPE html><head><title>Document preview</title></head><body style="margin: auto; background: #0e0e0e; height:'100%';"><img align="center "height='600rem' src="${imageDataUrl}"  ></body></html>`
            );
            newTab?.document.close();
          }
          if (blob.type == "application/pdf") {
            link.setAttribute("Download", "Attachment.pdf");
            document.body.appendChild(link);
            link.click();
          }
        };
      });
  };

  const onRemove = (e, id) => {
    e.preventDefault();
    removeManagerLeaseApplicationAttachment(params.applicationId, id).then((res) => {
      getApplication();
    }).catch((err) => {
      toast.error(err.response.data.error.message)
    });
  };

  const leaseButtonLabel = (data) => {
    var vrnt = "contained";
    if (data && data.applicants ? data.applicants[0].emailVerified == "false" : null) vrnt = "disabled";
    switch (data && data.status ? data.status : null) {
      case "APPLICANT_LEASE_AGREEMENT_SIGNED": {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyItem: "center",
              alignItems: "center",
              flexWrap: 'wrap',
              gap: 20
            }}
          >
            <div>
              <Button
                variant="contained"
                color={dismissStatus === false ? "warning" : "success"}
                sx={{ fontSize: 15 }}
                onClick={handleOpen}
              >
                {" "}
                {dismissStatus === false ? "Dismiss" : "Consider"}
              </Button>
            </div>
            <div>
              <Button
                fullWidth={true}
                variant={vrnt}
                sx={{ fontSize: 15 }}
                onClick={(e) => btnsign(e)}
              >
                SIGN AND SET UP LEASE
              </Button>
            </div>
            <div>
              <Button
                fullWidth={true}
                color="warning"
                variant={vrnt}
                sx={{ fontSize: 15 }}
                onClick={(e) => btnRevise(e)}
              >
                REVISE LEASE AGreement
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="error"
                sx={{ fontSize: 15 }}
                onClick={handleOpen1}
              >
                DECLINE APPLICATION

              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={(e) => btnSave(e)}
              >
                save
              </Button>
            </div>
          </div>
        );
      }
      case "MANAGER_ACCEPTED_AND_PREPARING_LEASE_AGREEMENT": {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyItem: "center",
              alignItems: "center",
              flexWrap: 'wrap',
              gap: 20
            }}
          >
            <div>
              <Button variant="contained"
                color={dismissStatus === false ? "warning" : "success"}
                sx={{ fontSize: 15 }}
                onClick={handleOpen}>
                {dismissStatus === false ? "Dismiss" : "Consider"}
              </Button>
            </div>
            <div>
              <Button
                variant={vrnt}
                sx={{ fontSize: 15 }}
                onClick={(e) => btnAccept(application?.id)}
              >
                SET UP LEASE
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="error"
                sx={{ fontSize: 15 }}
                onClick={handleOpen1}
              >
                DECLINE APPLICATION
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={(e) => btnSave(e)}
              >
                save
              </Button>
            </div>
          </div>
        );
      }
      case "MANAGER_SENT_LEASE_AGREEMENT": {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyItem: "center",
              alignItems: "center",
              flexWrap: 'wrap',
              gap: 20
            }}
          >
            <div>

              <Button variant="contained"
                color={dismissStatus === false ? "warning" : "success"}
                sx={{ fontSize: 15 }}
                onClick={handleOpen}
              >
                {dismissStatus === false ? "Dismiss" : "Consider"}
              </Button>
            </div>
            <div>
              <Button
                color="warning"
                variant={vrnt}
                sx={{ fontSize: 15 }}
                onClick={(e) => btnRevise(e)}
              >
                REVISE LEASE Agreement
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="error"
                sx={{ fontSize: 15 }}
                onClick={handleOpen1}
              >
                DECLINE APPLICATION
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={(e) => btnSave(e)}
              >
                save
              </Button>
            </div>
          </div>
        );
      }
      case "MANAGER_SET_UP_LEASE_SUCCESSFULLY": {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyItem: "center",
              alignItems: "center",
              flexWrap: 'wrap',
              gap: 20
            }}
          >
            <div>
              <Button
                variant="contained"
                color={dismissStatus === false ? "warning" : "success"}
                sx={{ fontSize: 15 }}
                id="printPageButton"
                onClick={handleOpen}
              >
                {dismissStatus === false ? "Dismiss" : "Consider"}
              </Button>
            </div>
            <div>
              <Button
                id="printPageButton"
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={(e) => btnSave(e)}
              >
                save
              </Button>
            </div>
          </div>
        );
      }
      case "MANAGER_REJECTED": {
        return (
          <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 20 }}>
            <div>
              <Button
                variant="contained"
                color={dismissStatus === false ? "warning" : "success"}
                sx={{ fontSize: 15 }}
                id="printPageButton"
                onClick={handleOpen}
              >
                {dismissStatus === false ? "Dismiss" : "Consider"}
              </Button>
            </div>
            <div>
              <Button
                id="printPageButton"
                variant="contained"
                color="error"
                sx={{ fontSize: 15 }}
                onClick={handleOpen1}
              >
                UNREJECT
              </Button>
            </div>
            <div>
              <Button
                id="printPageButton"
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={(e) => btnSave(e)}
              >
                save
              </Button>
            </div>
          </div>
        );
      }
      case "APPLICANT_STARTED": {
        return (
          <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 20 }}>
            <div>
              <Button
                variant="contained"
                color={dismissStatus === false ? "warning" : "success"}
                sx={{ fontSize: 15 }}
                id="printPageButton"
                onClick={handleOpen}
              >
                {dismissStatus === false ? "Dismiss" : "Consider"}
              </Button>
            </div>
            <div>
              <Button
                id="printPageButton"
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={(e) => btnSave(e)}
              >
                save
              </Button>
            </div>
          </div>
        );
      }
      default: {
        return (
          <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 20 }}>
            <div>
              <Button
                variant="contained"
                color={dismissStatus === false ? "warning" : "success"}
                sx={{ fontSize: 15 }}
                id="printPageButton"
                onClick={handleOpen}
              >
                {dismissStatus === false ? "dismiss" : "Consider"}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                sx={{ fontSize: 15 }}
                onClick={(e) => btnAccept(application?.id)}
              >
                ACCEPT AND SET UP LEASE
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="error"
                sx={{ fontSize: 15 }}
                onClick={handleOpen1}
              >
                DECLINE APPLICATION
              </Button>
            </div>
            <div>
              <Button
                id="printPageButton"
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={(e) => btnSave(e)}
              >
                save
              </Button>
            </div>
          </div>
        );
      }
    }
  };

  const btnsign = async (e) => {
    navigate(`/manager/managerSignleaseagreemenet/${application?.id}`);
  };

  const btnRevise = () => {
    managerLeaseAgreementRevise(params.applicationId).then((res) => {
      navigate(`/manager/createlease/${application?.id}`);
    }).catch((err) => {
      toast.error(err.response.data.error.message)
    });
  };

  const btnSave = () => {
    let error = false;
    if (managerReview === "") {
      setremarksError("Remarks cannot be empty");
      error = true;
    } else {
      setremarksError("");
    }
    if (managerNotes === "") {
      setnotesError("Notes cannot be empty");
      error = true;
    } else {
      setnotesError("");
    }
    if (error) return;

    let radioValue = application.review && application.review.leaseApplicantsReview && application.review.leaseApplicantsReview;

    let savdat = {
      applicantsReview: radioValue,
      remarks: managerReview,
      notes: managerNotes,
    };
    postReview(params.applicationId, savdat).then((response) => {
      getApplication();
    }).catch((err) => {
      toast.error(err.response.data.error.message)
    });
  };

  const btnAccept = (applicationId) => {
    postReview(params.applicationId).then((response) => {
      return acceptReview(params.applicationId);
    }).then((res) => {
      navigate(`/manager/createlease/${applicationId}`);
    }).catch((err) => {
      toast.error(err.response.data.error.message)
    });
  };

  const btnDismiss = (e) => {
    e.preventDefault();
    postDismiss(params.applicationId).then((response) => {
      getApplication();
      setOpen(false);
    }).catch((err) => {
      toast.error(err.response.data.error.message)
    });
  };

  const btnConsider = (e) => {
    e.preventDefault();
    postConsider(params.applicationId).then((res) => {
      getApplication();
      setOpen(false);
    }).catch((err) => {
      toast.error(err.response.data.error.message)
    });
  }

  const btnDecline = () => {
    reviewReject(params.applicationId).then((res) => {
      getApplication();
      setOpen1(false);
    }).catch((err) => {
      toast.error(err.response.data.error.message)
    });
  }

  const btnUnreject = () => {
    reviewUnreject(params.applicationId).then((res) => {
      setOpen1(false);
      getApplication();
    }).catch((err) => {
      toast.error(err.response.data.error.message)
    });
  }

  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div style={{ padding: '30px' }}>
      <div className="leaseApp-Column-Flex">
        <div className="textAlign-center">
          <h2>Application Review</h2>
          <div className="leaseApp-Column-Flex-child">
            <div className='leaseApp-Column-Flex-child-1'>
              <h3>{`${property?.address?.line1}, ${property?.address?.line2} ${property?.address?.unitNumber}`}</h3>
            </div>
          </div>
          <p style={{ fontSize: 18 }}>Started on: {moment(application?.createdAt).format('MMMM DD, YYYY [at] h:mm:ss A [GMT]Z')}</p>
          <p style={{ fontSize: 18 }}>Last Modified: {moment(application?.updatedAt).format('MMMM DD, YYYY [at] h:mm:ss A [GMT]Z')}</p>
          <p style={{ fontSize: 18 }}>Submitted on: {moment(application?.submittedAt).format('MMMM DD, YYYY [at] h:mm:ss A [GMT]Z')}</p>
          <div role="presentation" className='breedCrump'>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                style={{ color: '#4a90e2', fontSize: 18 }}
                underline="hover"
                color="inherit"
                href={`/manager/properties/${property?.id}/edit`}
              >
                {`${property?.address?.line1}, ${property?.address?.line2}`}
              </Link>
              <Link
                style={{ fontSize: 18 }}
                underline="hover"
                color="inherit"
              >
                TENANT APPLICATION REVIEW
              </Link>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Lease Application Overview</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Application Submitted</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>{statusValidate(item?.status)}</TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Income Score</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>{incomeScoreCalculation(item) || "-"}</TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Credit Score</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>{creditScoreCalculate(item?.incomeDetails?.creditScore || 0)}</TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Charges of Eviction</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>{evictionCheck(item?.hasEvictionHistory)}</TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Criminal Charge</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>{criminalCheck(item?.hasCriminalHistory)}</TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Is Employer Contacted</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>{checkEmployerContact(application?.review || {}, index)}</TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Is Landlord Contacted</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>{checkManagerContact(application?.review || {}, index)}</TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Desired Move-In Date</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.moveInDate}</span></TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Basic Info</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>First Name</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.firstName}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Last Name</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.lastName}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Email</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.email}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Phone No</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.phoneNumber}</span></TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Current Residence Address</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Street</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.address?.line1 || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Building / Appartment</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.address?.line2 || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>City</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.address?.city || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>State</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.address?.state || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>ZipCode</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.address?.zipCode || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Residence Manager</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.managerName || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Contact No</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.managerPhone || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Duration</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.yearsOfResidence || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Reason for Leaving</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.reasonForLeaving || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Monthly Rent</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentResidence?.monthlyRent || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Landlord Contacted</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    <RadioGroup row>
                      <FormControlLabel
                        value={true}
                        checked={application?.review?.leaseApplicantsReview[index]?.currentResidenceManagerContacted}
                        control={<Radio color="success" />}
                        label="Yes"
                        onChange={(e) => {
                          onClickRadioButton(
                            true,
                            "currentResidenceManagerContacted",
                            item.id, index
                          );
                        }}
                      />
                      <FormControlLabel
                        value={false}
                        checked={application?.review?.leaseApplicantsReview[index]?.currentResidenceManagerContacted == false}
                        control={<Radio color="success" />}
                        label="No"
                        onChange={(e) => {
                          onClickRadioButton(
                            false,
                            "currentResidenceManagerContacted",
                            item.id, index
                          );
                        }}
                      />
                    </RadioGroup>
                  </TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Good standing with Landlord</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    <RadioGroup row>
                      <FormControlLabel
                        value="Good"
                        checked={application?.review?.leaseApplicantsReview[index]?.currentResidenceManagerTestimony == "Good"}
                        control={<Radio color="success" />}
                        label="Yes"
                        onChange={(e) => {
                          onClickRadioButton(
                            "Good",
                            "currentResidenceManagerTestimony",
                            item.id, index
                          );
                        }}
                      />
                      <FormControlLabel
                        value="Bad"
                        checked={application?.review?.leaseApplicantsReview[index]?.currentResidenceManagerTestimony == "Bad"}
                        control={<Radio color="success" />}
                        label="No"
                        onChange={(e) => {
                          onClickRadioButton(
                            "Bad",
                            "currentResidenceManagerTestimony",
                            item.id, index
                          );
                        }}
                      />
                    </RadioGroup>
                  </TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Previous Residence Address</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Street</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.address?.line1 || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Building / Appartment</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.address?.line2 || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>City</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.address?.city || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>State</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.address?.state || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>ZipCode</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.address?.zipCode || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Residence Manager</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.managerName || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Contact No</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.managerPhone || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Duration</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.yearsOfResidence || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Reason for Leaving</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.reasonForLeaving || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Monthly Rent</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousResidence?.monthlyRent || "-"}</span></TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Current Employer</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer Name</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.employerName || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Position/Title</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.role || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Manager</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.managerName || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer Contact No</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.managerPhone || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer Street</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.employerAddress?.line1 || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer Building</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.employerAddress?.line2 || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer City</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.employerAddress?.city || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer State</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.employerAddress?.state || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer ZipCode</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.employerAddress?.zipCode || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employment Duration</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.currentEmployment?.role || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer Contacted?</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    <RadioGroup row>
                      <FormControlLabel
                        value={true}
                        checked={application?.review?.leaseApplicantsReview[index]?.currentEmployerContacted}
                        control={<Radio color="success" />}
                        label="Yes"
                        onChange={(e) => {
                          onClickRadioButton(
                            true,
                            "currentEmployerContacted",
                            item.id, index
                          );
                        }}
                      />
                      <FormControlLabel
                        value={false}
                        checked={application?.review?.leaseApplicantsReview[index]?.currentEmployerContacted == false}
                        control={<Radio color="success" />}
                        label="No"
                        onChange={(e) => {
                          onClickRadioButton(
                            false,
                            "currentEmployerContacted",
                            item.id, index
                          );
                        }}
                      />
                    </RadioGroup>
                  </TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Good standing with Employer?</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    <RadioGroup row>
                      <FormControlLabel
                        value="Good"
                        checked={application?.review?.leaseApplicantsReview[index]?.currentEmployerTestimony == "Good"}
                        control={<Radio color="success" />}
                        label="Yes"
                        onChange={(e) => {
                          onClickRadioButton(
                            "Good",
                            "currentEmployerTestimony",
                            item.id, index
                          );
                        }}
                      />
                      <FormControlLabel
                        value="Bad"
                        checked={application?.review?.leaseApplicantsReview[index]?.currentEmployerTestimony == "Bad"}
                        control={<Radio color="success" />}
                        label="No"
                        onChange={(e) => {
                          onClickRadioButton(
                            "Bad",
                            "currentEmployerTestimony",
                            item.id, index
                          );
                        }}
                      />
                    </RadioGroup>
                  </TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Previous Employment</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Position/Title</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousEmployment?.role || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer Contact No</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousEmployment?.managerPhone || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer Street</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousEmployment?.employerAddress?.line1 || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer Building</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousEmployment?.employerAddress?.line2 || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer City</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousEmployment?.employerAddress?.city || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer State</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousEmployment?.employerAddress?.state || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Employer ZipCode</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.previousEmployment?.employerAddress?.zipCode || "-"}</span></TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Income Details</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Monthly Income</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.incomeDetails?.monthlyIncome || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Monthly Income Pay Stub</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    {item?.incomeDetails?.incomeVerificationFile ? (
                      <span style={{ fontWeight: 'bold', color: '#4183c4' }} onClick={() => paystubClick(item.id, application && application.id ? application.id : null, "income-verification")}>
                        Download Payment Stub
                      </span>
                    ) : (
                      <span style={{ fontWeight: 'bold', fontSize: 15 }}>
                        Missing Pay Stub
                      </span>
                    )}
                  </TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Other Income</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.incomeDetails?.otherMonthlyIncome || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Other Income Source</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.incomeDetails?.otherIncomeSource || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Other Income Verification File</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    {item?.incomeDetails?.otherIncomeVerificationFile ? (
                      <span style={{ fontWeight: 'bold', color: '#4183c4' }} onClick={() => paystubClick(item.id, application && application.id ? application.id : null, "other-income-verification")}>
                        Download Payment Stub
                      </span>
                    ) : (
                      <span style={{ fontWeight: 'bold', fontSize: 15 }}>
                        Missing Pay Stub
                      </span>
                    )}
                  </TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Other Income Verification Contact</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.incomeDetails?.otherIncomeVerificationContactName || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Other Income Verification Contact No.</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.incomeDetails?.otherIncomeVerificationContactPhone || "-"}</span></TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Verification</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Date of Birth</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item.dateOfBirth ? moment(item.dateOfBirth).format('MMM D, YYYY') : "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Social Security Number</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.verificationDetails?.socialSecurityNumber || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Social Security Number</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.verificationDetails?.driversLicense?.number || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Driving License State</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.verificationDetails?.driversLicense?.issuerState || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Driving License Expiry</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{moment(item?.verificationDetails?.driversLicense?.expiryDate).format('MMM D, YYYY') || "-"}</span></TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Bank Statement</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Bank Statement</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    {item?.verificationDetails?.bankStatemantFile ? (
                      <span style={{ fontWeight: 'bold', color: '#4183c4' }} onClick={() => paystubClick(item.id, application && application.id ? application.id : null, "bank-statement")}>
                        (Download Bank Statement)
                      </span>
                    ) : (
                      <span style={{ fontWeight: 'bold', color: '#dc3545' }}>
                        Missing Bank Statement File
                      </span>
                    )}
                  </TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Credit Score</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Credit Score</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.incomeDetails?.creditScore || "-"}</span></TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Credit Report</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    {item?.verificationDetails?.creditReportFile ? (
                      <span style={{ fontWeight: 'bold', color: '#4183c4' }} onClick={() => paystubClick(item.id, application && application.id ? application.id : null, "credit-report")}>
                        Download Credit Report
                      </span>
                    ) : (
                      <span style={{ fontWeight: 'bold', color: '#dc3545' }}>
                        Missing Credit Report
                      </span>
                    )}
                  </TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Government Id</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row" style={{ fontSize: 15 }}>Government Identification</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    {item?.verificationDetails?.governmentIdFile ? (
                      <span style={{ fontWeight: 'bold', color: '#4183c4' }} onClick={() => paystubClick(item.id, application && application.id ? application.id : null, "government-id")}>
                        Download Government ID
                      </span>
                    ) : (
                      <span style={{ fontWeight: 'bold', color: '#dc3545' }}>
                        Missing Government ID
                      </span>
                    )}
                  </TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Auto{" "}{application && application.automobiles ? application.automobiles.length == 0 ? `(No Automobiles)` : "" : null}</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              {application && application.automobiles ? application.automobiles.map((item, index) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                  <TableCell className='tableCell-maxWidth-1' component="th" scope="row"><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item.license || "-"}</span></TableCell>
                  <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item.year || "-"}</span></TableCell>
                  <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item.manufacturer || "-"}</span></TableCell>
                  <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item.model || "-"}</span></TableCell>
                  <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item.state || "-"}</span></TableCell>
                </TableRow>
              )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Pets{" "}{application && application?.occupants?.pets ? application?.occupants?.pets.length == 0 ? `(No Pets)` : "" : null}</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              {application && application?.occupants?.pets ? application?.occupants?.pets.map((item, index) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                  <TableCell className='tableCell-maxWidth-1' component="th" scope="row"><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.name || "-"}</span></TableCell>
                  <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.description || "-"}</span></TableCell>
                </TableRow>
              )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Minors{" "}{application && application?.occupants?.minors ? application?.occupants?.minors.length == 0 ? `(No Minors)` : "" : null}</h4>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              {application && application?.occupants?.minors ? application?.occupants?.minors.map((item, index) => (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                  <TableCell className='tableCell-maxWidth-1' component="th" scope="row"><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.name || "-"}</span></TableCell>
                  <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.age || "-"}</span></TableCell>
                  <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{moment(item?.dateOfBirth).format('MMM DD, yyyy') || "-"}</span></TableCell>
                </TableRow>
              )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='leaseApp-Column-Flex'>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Occupants{" "}{application && application?.occupants?.adults ? application?.occupants?.adults.length == 0 ? `(No Occupantss)` : "" : null}</h4>
        {application && application?.occupants?.adults ? application?.occupants?.adults.length == 0 ? "" :
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableCell className='tableCell-maxWidth-1'>Name</TableCell>
                <TableCell className='tableCell-maxWidth-1'>Relation</TableCell>
                {application && application.applicants ? application.applicants.length == 2 ? (
                  <TableCell className='tableCell-maxWidth-1'></TableCell>) : ("") : null}
              </TableHead>
              <TableBody>
                {application && application?.occupants?.adults ? application?.occupants?.adults.map((item, index) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                    <TableCell className='tableCell-maxWidth-1' component="th" scope="row"><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.name || "-"}</span></TableCell>
                    <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}>{item?.relationship || "-"}</span></TableCell>
                    {application && application.applicants ? application.applicants.length == 2 ? (
                      <TableCell className='tableCell-maxWidth-1'><span style={{ fontWeight: 'bold', fontSize: 15 }}></span></TableCell>) : ("") : null}
                  </TableRow>
                )) : null}
              </TableBody>
            </Table>
          </TableContainer>
          : null}
      </div>

      <div style={{ marginBottom: 5 }}>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Tenant agreed to the following statement</h4>
        <p style={{ textAlign: 'center', fontSize: 15 }}>"I authorize, the landlord and it's agents the release of information from previous or current landlords, employers, and bank representatives, and any other entities mentioned. This investgation is for resident screening purposes only, and is strictly confidential. Data collected may be used in aggregate for statistical and marketing purposes"</p>
      </div>
      <div className='leaseApp-sign-flex-Image'>
        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {application && application?.occupants?.adults ? application?.occupants?.adults.map((item, index) => (
              <div className='leaseApp-sign-column-flex-Image' key={index}>
                <h3 style={{ marginBottom: 0 }}>{item?.name || "-"}</h3>
                <h4>{item?.relationship || "-"}</h4>
              </div>
            )) : null}
          </div>
          <h5 style={{ marginBottom: 0 }}>IP Address: {application && application.applicants ? application.applicants[0]?.signature?.ipAddress || "" : null}</h5>
          <h5>{application && application.createdAt ? moment(application.createdAt).format('MMMM DD, YYYY [at] h:mm:ss A [GMT]Z') : null}</h5>
          <div>
            <div>
              <img
                style={{
                  border: "1px solid #dee2e6",
                  padding: "3px",
                  width: 300,
                  height: 200,
                }}
                src={`${sign}`}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='leaseApp-Column-Flex'>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row">Print Application</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}>
                    <div>
                      <Button
                        vaiant="text"
                        onClick={window.print}
                        startIcon={<PrintIcon />}
                      >
                        Print {item.firstName}'s Application
                      </Button>
                    </div>
                  </TableCell>
                )) : null}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row">Email Verification</TableCell>
                {application && application.applicants ? application.applicants.map((item, index) => (
                  <TableCell className='tableCell-maxWidth-1' key={index}><span style={{ fontWeight: 'bold', fontSize: 15 }}>
                    <div>
                      {item?.emailVerified ? (
                        <Button disabled startIcon={<EmailIcon />}>
                          {item.firstName}'s Email Verified
                        </Button>
                      ) : (
                        <Button vaiant="text" startIcon={<EmailIcon />}>
                          Request {item.firstName}'s Email verification
                        </Button>
                      )}
                    </div>
                  </span>
                  </TableCell>
                )) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Manager Review</h4>
        <TextField
          variant="outlined"
          label="Manager Remarks"
          margin="normal"
          required
          type="text"
          fullWidth={true}
          value={managerReview}
          onChange={(e) => {
            setManagerReview(e.target.value);
          }}
        />
        {remarksError && (
          <span className="form-item-error">{remarksError} </span>
        )}
        <TextField
          label="Manager Notes"
          margin="dense"
          variant="outlined"
          fullWidth={true}
          multiline
          rows={6}
          value={managerNotes}
          onChange={(e) => {
            setManagerNotes(e.target.value);
          }}
        />
        {notesError && <span className="form-item-error">{notesError} </span>}
      </div>
      <div>
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
          <span>
            <h4 style={{ paddingTop: 20, paddingBottom: 20, fontSize: 20 }}>Attachments</h4>
          </span>
          <span>
            <AttachmentIcon />
          </span>
        </span>
        <p style={{ fontSize: 17, paddingBottom: 20 }}>These are additional documents that can be added for future reference. You can attach extra information regarding the applicants.</p>
        <table>
          {application?.review?.files.map((r, t) => (
            <tbody key={t}>
              <tr>
                <td>
                  <Button
                    onClick={(e) => attachmentClick(e, r.id)}
                    sx={{ cursor: "pointer" }}
                  >
                    {r.fileName}
                  </Button>
                </td>
                <td>
                  <DeleteIcon
                    onClick={(e) => onRemove(e, r.id)}
                    sx={{ color: "#F44336", cursor: "pointer" }}
                  />
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <input
          ref={fileRef}
          type="file"
          name="fileOut"
          id="upAtt"
          className="inputfile"
          accept="application/pdf,image/png,image/jpeg"
          onChange={(e) => Attachment(e)}
          hidden
        />
        <div style={{ display: "flex" }}>
          <label
            style={{
              color: "white",
              borderRadius: "5px",
              width: "12rem",
              marginTop: "20px",
              background: "#1976D2",
              fontWeight: "500",
              fontSize: "15px",
              marginRight: "10px",
              border: "1px solid #1976D2",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              padding: "5px",
            }}
            htmlFor="upAtt"
          >
            ADD ATTACHMENT
          </label>
        </div>
      </div>
      <div className="no-print">
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            margin: "2% auto",
          }}
        >
          {leaseButtonLabel(application)}
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "white",
            borderRadius: "5px",
            p: 1,
          }}
        >
          <CardContent>
            {dismissStatus === false ?
              <Typography variant="h5" component="h2">
                Are you sure you want to dismiss this application from your
                Dashboard?
              </Typography>
              : <Typography variant="h5" component="h2">
                Are you sure you want to show this application on your Dashboard?
              </Typography>}
          </CardContent>

          <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: 'wrap' }}>
            {dismissStatus === false ?
              <Button
                variant="contained"
                sx={{ fontSize: 15 }}
                type="submit"
                color="warning"
                onClick={(e) => btnDismiss(e)}
              >
                DISMISS APPLICATION
              </Button> :
              <Button
                variant="contained"
                sx={{ fontSize: 15 }}
                type="submit"
                color="warning"
                onClick={(e) => btnConsider(e)}
              >
                Consider Application
              </Button>}
            <Button
              sx={{ fontSize: 15 }}
              type="submit"
              onClick={() => setOpen(false)}
            >
              CANCEL
            </Button>
          </CardContent>
        </Box>
      </Modal>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "white",
            borderRadius: "5px",
            p: 1,
          }}
        >
          <CardContent>
            {dismissStatus === false ?
              <Typography variant="h5" component="h2">
                Are you sure you want to dismiss this application from your
                Dashboard?
              </Typography>
              : <Typography variant="h5" component="h2">
                Are you sure you want to show this application on your Dashboard?
              </Typography>}
          </CardContent>

          <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: 'wrap' }}>
            {dismissStatus === false ?
              <Button
                variant="contained"
                sx={{ fontSize: 15 }}
                type="submit"
                color="warning"
                onClick={(e) => btnDismiss(e)}
              >
                DISMISS APPLICATION
              </Button> :
              <Button
                variant="contained"
                sx={{ fontSize: 15 }}
                type="submit"
                color="warning"
                onClick={(e) => btnConsider(e)}
              >
                Consider Application
              </Button>}
            <Button
              sx={{ fontSize: 15 }}
              type="submit"
              onClick={() => setOpen(false)}
            >
              CANCEL
            </Button>
          </CardContent>
        </Box>
      </Modal>
      <Modal open={open1} onClose={() => setOpen1(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "white",
            borderRadius: "5px",
            p: 1,
          }}
        >
          <CardContent>
            {statuss !== "MANAGER_REJECTED" ?
              <Typography variant="h5" component="h2">
                Are you sure you want to reject this application?
              </Typography>
              : <Typography variant="h5" component="h2">
                Are you sure you want to consider this application?
              </Typography>}
          </CardContent>
          <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: 'wrap' }}>
            {statuss !== "MANAGER_REJECTED" ?
              <Button
                variant="contained"
                sx={{ fontSize: 15 }}
                type="submit"
                color="warning"
                onClick={(e) => btnDecline(e)}
              >
                REJECT APPLICATION
              </Button> :
              <Button
                variant="contained"
                sx={{ fontSize: 15 }}
                type="submit"
                color="success"
                onClick={(e) => btnUnreject(e)}
              >
                UNREJECT
              </Button>}
            <Button
              sx={{ fontSize: 15 }}
              type="submit"
              onClick={() => setOpen1(false)}
            >
              CANCEL
            </Button>
          </CardContent>
        </Box>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  )
}

export default LeaseAppNew;