import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formNames, usStates } from "../../../../helpers";
import { FaStepBackward, FaStepForward } from "react-icons/fa";
import {
    addTenantBankStatement, addTenantCreditReport, addTenantGovernmentId,
    withDrawApplication,
    editTenantLeaseApplicantInfo, removeTenantBankStatement,
    removeTenantCreditReport, removeTenantGovernmentId,
    getTenantLeaseApplication
} from "../../../../services";
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TenantLeaseApplicationEditPage from ".";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MenuProps } from "../../../../components/MaterialUiSelectMenuStyle";
import { truncateAddress } from "../../../../components/textTruncate/truncate";

function VerificationForm() {
    let params = useParams();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.value);

    const [dob, setDob] = useState(null);
    const [dobError, setDobError] = useState("");
    const [creditScore, setCreditScore] = useState("");
    const [creditScoreError, setCreditScoreError] = useState("");
    const [ssn, setSsn] = useState("");
    const [ssnError, setSsnError] = useState("");
    const [license, setLicense] = useState("");
    const [licenseError, setLicenseError] = useState("");
    const [state, setState] = useState("");
    const [stateError, setStateError] = useState("");
    const [expiryDate, setExpiryDate] = useState(null);
    const [expiryDateError, setExpiryDateError] = useState("");

    const [bankStatement, setBankStatement] = useState(null);
    const [bankStatementError, setBankStatementError] = useState("");
    const [uploading, setUploading] = useState(false);
    const [creditReport, setCreditReport] = useState(null);
    const [creditReportError, setCreditReportError] = useState("");
    const [creditReportUploading, setCreditReportUploading] = useState(false);
    const [govId, setGovId] = useState(null);
    const [govIdError, setGovIdError] = useState("");
    const [GovtIdUploading, setGovtIdUploading] = useState(false);

    const [FieldsDisabled, setFieldsDisabled] = useState(false);
    const [SubmittedStatus, setSubmittedStatus] = useState(false);
    const [Modify, setModify] = useState(false);

    const pageLoad = async () => {
        await getTenantLeaseApplication(params.applicationId).then((res) => {
            let application = res?.data?.data;
            if (application) {
                let applicant;
                // If there two applicants, we need to find the currently logged in user from the array.
                application.applicants.forEach((item, index) => {
                    if (item.user === user.id) {
                        applicant = application.applicants[index];
                    }
                });

                setDob(applicant.dateOfBirth ?? null);
                // if (applicant.dateOfBirth) {
                //     setDob(DateTime.fromISO(applicant.dateOfBirth).toFormat("yyyy-MM-dd"));
                // }
                setCreditScore(applicant.incomeDetails?.creditScore ?? "");
                setSsn(applicant.verificationDetails?.socialSecurityNumber ?? "");
                setLicense(applicant.verificationDetails?.driversLicense?.number ?? "");
                setState(applicant.verificationDetails?.driversLicense?.issuerState ?? "");
                if (applicant.verificationDetails?.driversLicense?.expiryDate) {
                    setExpiryDate(applicant.verificationDetails?.driversLicense?.expiryDate);
                }
                if (applicant.verificationDetails?.bankStatemantFile) {
                    setBankStatement(applicant.verificationDetails?.bankStatemantFile);
                }
                if (applicant.verificationDetails?.creditReportFile) {
                    setCreditReport(applicant.verificationDetails?.creditReportFile);
                }
                if (applicant.verificationDetails?.governmentIdFile) {
                    setGovId(applicant.verificationDetails?.governmentIdFile);
                }
            }
            if (application?.status == "APPLICANT_SUBMITTED") {
                setFieldsDisabled(true);
                setSubmittedStatus(true);
            }
            if (application?.status == "APPLICANT_WITHDRAWN") {
                setFieldsDisabled(false);
                setModify(true);
            }
        }).catch(err => { console.log(err) });
    }

    useEffect(() => {
        pageLoad();
    }, []);

    // Phone Format
    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedSSN = formatSSN(e.target.value);
        // we'll set the input value using our setInputValue
        setSsn(formattedSSN);
    };
    function formatSSN(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const ssn = value.replace(/[^\d]/g, '');

        // ssnLength is used to know when to apply our formatting for the ssn
        const ssnLength = ssn.length;

        // we need to return the value with no formatting if its less then four digits
        if (ssnLength < 4) return ssn;

        // if ssnLength is greater than 4 and less the 6 we start to return
        // the formatted number
        if (ssnLength < 6) {
            return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
        }
        // finally, if the ssnLength is greater then 6, we add the last
        // bit of formatting and return it.
        return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
    }
    const onChangeHandler = (event) => {
        const ssn = event.target.value
        setSsn(ssn)
    };

    const renderStateOptions = () => {
        return (
            usStates.map((item) => (
                // <>
                //     <option value={""} key="select">Select</option>
                //     {usStates.map((item) => (
                //         <option key={item} value={item}>
                //             {item}
                //         </option>
                //     ))}
                // </>
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))

        );
    };

    const handleBankStatementUpload = (e) => {
        setUploading(true);
        let form = new FormData();
        form.append("verification-file", e.target.files[0]);
        addTenantBankStatement(params.applicationId, form).then((res) => {
            toast.success("Bank Statement Updated.");
            pageLoad();
            setBankStatementError("");
            setUploading(false);
        }).catch((err) => { setUploading(false); toast.error(err.response.data.error.message) });
    }

    const handleBankStatementRemove = () => {
        removeTenantBankStatement(params.applicationId).then(() => {
            toast.success("Bank Statement Removed.");
            setBankStatement(null);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleCreditReportUpload = (e) => {
        setCreditReportUploading(true);
        let form = new FormData();
        form.append("verification-file", e.target.files[0]);
        addTenantCreditReport(params.applicationId, form).then(() => {
            toast.success("Credit Report Updated.");
            pageLoad();
            setCreditReportError("");
            setCreditReportUploading(false);
        }).catch((err) => { setCreditReportUploading(false); toast.error(err.response.data.error.message) });
    }

    const handleCreditReportRemove = () => {
        removeTenantCreditReport(params.applicationId).then(() => {
            toast.success("Credit Report Removed.");
            setCreditReport(false);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleGovIdUpload = (e) => {
        setGovtIdUploading(true);
        let form = new FormData();
        form.append("verification-file", e.target.files[0]);
        addTenantGovernmentId(params.applicationId, form).then(() => {
            toast.success("Government ID Updated.");
            pageLoad();
            setGovIdError("");
            setGovtIdUploading(false);
        }).catch((err) => { setGovtIdUploading(false); toast.error(err.response.data.error.message) });
    }

    const handleGovIdRemove = () => {
        removeTenantGovernmentId(params.applicationId).then(() => {
            toast.success("Government ID Removed");
            setGovId(false);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const expirycheck = (e) => {
        var date1 = new Date(e);
        let date2 = new Date()
        if (date1.getTime() < date2.getTime()) {
            return false;
        }
        else return true;
    }
    const handleModify = () => {
        const res = withDrawApplication(params.applicationId).then((response) => {
            window.location.reload();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };
    const handleVerificationSubmit = () => {
        let error = false;

        if (dob == null) {
            setDobError("Select a valid date");
            error = true;
        } else {
            setDobError("");
        }

        if (!ageCheck()) {
            setDobError("Applicant should be atleast 21 years old");
            error = true;
        } else {
            setDobError("");
        }

        if (creditScore === "") {
            setCreditScoreError("Enter a valid credit score");
            error = true;
        } else {
            setCreditScoreError("");
        }
        if (ssn === "") {
            setSsnError("This field cannot be blank");
            error = true;
        } else {
            setSsnError("");
        }
        if (license === "") {
            setLicenseError("This field cannot be blank");
            error = true;
        } else {
            setLicenseError("");
        }
        if (state === "") {
            setStateError("This field is mandatory");
            error = true;
        } else {
            setStateError("");
        }
        if (expiryDate === null) {
            setExpiryDateError("Please select a valid date");
            error = true;
        } else {
            setExpiryDateError("");
        }
        if (!expirycheck(expiryDate)) {
            setExpiryDateError("Your License has expired.");
            error = true;
        }
        if (!bankStatement) {
            setBankStatementError("Upload your bank statement");
            error = true;
        } else {
            setBankStatementError("");
        }
        if (!creditReport) {
            setCreditReportError("Upload your credit report");
            error = true;
        } else {
            setCreditReportError("");
        }
        if (!govId) {
            setGovIdError("Upload your ID");
            error = true;
        } else {
            setGovIdError("");
        }

        if (error) return;

        let data = {
            dateOfBirth: dob,
            creditScore: creditScore,
            socialSecurityNumber: ssn,
            driverLicenseNumber: license,
            driverLicenseState: state,
            driverLicenseExpiryDate: expiryDate,
        };

        editTenantLeaseApplicantInfo(params.applicationId, data).then(response => {
            toast.success("Updated Verification details");
            pageLoad();
            navigate(`/tenant/lease-applications/${params.applicationId}/history`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const ageCheck = function () {
        var todayDate = new Date().getFullYear();
        var valueYear = new Date(dob).getFullYear()
        let difference = todayDate - valueYear;
        if (valueYear <= todayDate) {
            return (21 <= difference && 100 >= difference);
        }
    };


    // return (
    //     <div className="lease-form-item verification-form">
    //         <h1 className="form-header">Verification</h1>
    //         <div className="form-body">
    //             <div className="form-section">
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         <span className="form-item-label">Date of Birth</span>
    //                         <input
    //                             className="form-item-input"
    //                             type="date"
    //                             value={dob}
    //                             onChange={e => {
    //                                 setDob(e.target.value)
    //                                 setDobError("")

    //                             }}
    //                             disabled={FieldsDisabled}

    //                         />
    //                         {/* <label style={{ color: 'grey', fontSize: '12px' }}>Applicant should be atleast 21 years old</label> */}
    //                         {dobError &&
    //                             <span className="form-item-error">
    //                                 {dobError}
    //                             </span>
    //                         }
    //                     </div>
    //                 </div>
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         <span className="form-item-label">Credit Score</span>
    //                         <input
    //                             className="form-item-input"
    //                             type="number"
    //                             value={creditScore}
    //                             onChange={e => { setCreditScore(e.target.value) }}
    //                             onInput={(e) => {
    //                                 if (e.target.value.length > e.target.maxLength)
    //                                     e.target.value = e.target.value.slice(0, e.target.maxLength);
    //                             }}
    //                             maxlength={3}
    //                             disabled={FieldsDisabled}
    //                         />
    //                         {creditScoreError &&
    //                             <span className="form-item-error">
    //                                 {creditScoreError}
    //                             </span>
    //                         }
    //                     </div>
    //                 </div>
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         <span className="form-item-label">Social Security Number</span>
    //                         <input
    //                             className="form-item-input"
    //                             type="text"
    //                             value={ssn}
    //                             onChange={(e) => { onChangeHandler(e); handleInput(e) }}
    //                             disabled={FieldsDisabled}
    //                         />
    //                         {ssnError &&
    //                             <span className="form-item-error">
    //                                 {ssnError}
    //                             </span>
    //                         }
    //                     </div>
    //                 </div>
    //                 <h3>Driving License</h3>
    //                 <div className="form-item-driving-license">
    //                     <div className="form-item">
    //                         <span className="form-item-label">License Number</span>
    //                         <input
    //                             className="form-item-input"
    //                             type="text"
    //                             value={license}
    //                             onChange={e => { setLicense(e.target.value) }}
    //                             disabled={FieldsDisabled}
    //                         />
    //                         {licenseError &&
    //                             <span className="form-item-error">
    //                                 {licenseError}
    //                             </span>
    //                         }
    //                     </div>
    //                     <div className="form-item">
    //                         <span className="form-item-label">State Issued</span>
    //                         <select
    //                             className="form-item-input"
    //                             onChange={e => { setState(e.target.value) }}
    //                             value={state}
    //                             disabled={FieldsDisabled}
    //                         >
    //                             {renderStateOptions()}
    //                         </select>
    //                         {stateError &&
    //                             <span className="form-item-error">
    //                                 {stateError}
    //                             </span>
    //                         }
    //                     </div>
    //                     <div className="form-item">
    //                         <span className="form-item-label">Expiry Date</span>
    //                         <input
    //                             className="form-item-input"
    //                             type="date"
    //                             value={expiryDate}
    //                             onChange={e => { setExpiryDate(e.target.value) }}
    //                             disabled={FieldsDisabled}
    //                         />
    //                         {expiryDateError &&
    //                             <span className="form-item-error">
    //                                 {expiryDateError}
    //                             </span>
    //                         }
    //                     </div>
    //                     <div className="form-item">
    //                         <span className="form-item-label">Bank Statement</span>
    //                         <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
    //                             <input type="file" id="uploadBtn"
    //                                 accept="application/pdf,image/*"
    //                                 onChange={handleBankStatementUpload}
    //                             />
    //                             {bankStatement ?
    //                                 <label>
    //                                     <span onClick={handleBankStatementRemove}>Remove File</span>
    //                                 </label>
    //                                 :
    //                                 <label htmlFor="uploadBtn" style={{ fontSize: 13 }}>
    //                                     {uploading ? "Upload Inprogress..." : ""}
    //                                     {!uploading ? "Drop File / click to Upload" : ""}
    //                                 </label>
    //                             }
    //                         </div>
    //                         {bankStatementError &&
    //                             <span className="form-item-error">
    //                                 {bankStatementError}
    //                             </span>
    //                         }
    //                     </div>
    //                     <div className="form-item">
    //                         <span className="form-item-label">Credit Report</span>
    //                         <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
    //                             <input type="file" id="uploadBtnCreditReport"
    //                                 accept="*/*"
    //                                 onChange={handleCreditReportUpload}
    //                             />
    //                             {creditReport ?
    //                                 <label>
    //                                     <span onClick={handleCreditReportRemove}>Remove File</span>
    //                                 </label>
    //                                 :
    //                                 <label htmlFor="uploadBtnCreditReport" style={{ fontSize: 13 }}>
    //                                     {creditReportUploading ? "Upload Inprogress..." : ""}
    //                                     {!creditReportUploading ? "Drop File / click to Upload" : ""}
    //                                 </label>
    //                             }
    //                         </div>
    //                         {creditReportError &&
    //                             <span className="form-item-error">
    //                                 {creditReportError}
    //                             </span>
    //                         }
    //                     </div>
    //                     <div className="form-item">
    //                         <span className="form-item-label">Government ID</span>
    //                         <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
    //                             <input type="file" id="uploadBtnGovtId"
    //                                 accept="*/*"
    //                                 onChange={handleGovIdUpload}
    //                             />
    //                             {govId ?
    //                                 <label>
    //                                     <span onClick={handleGovIdRemove}>Remove File</span>
    //                                 </label>
    //                                 :
    //                                 <label htmlFor="uploadBtnGovtId" style={{ fontSize: 13 }}>
    //                                     {GovtIdUploading ? "Upload Inprogress..." : ""}
    //                                     {!GovtIdUploading ? "Drop File / click to Upload" : ""}
    //                                 </label>
    //                             }
    //                         </div>
    //                         {govIdError &&
    //                             <span className="form-item-error">
    //                                 {govIdError}
    //                             </span>
    //                         }
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="form-footer">
    //             {Modify === true ? (
    //                 <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         startIcon={<FaStepBackward />}
    //                         onClick={() => { setCurrentForm(formNames.employmentForm) }}
    //                     >EMPLOYMENT AND INCOME</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="success"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleVerificationSubmit}
    //                     >SAVE</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleVerificationSubmit}
    //                         endIcon={<FaStepForward />}
    //                     >HISTORY</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="info"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={() => { setCurrentForm(formNames.agreementForm) }}
    //                         endIcon={<FaStepForward />}
    //                     >GO TO SUBMIT</Button>
    //                 </div>
    //             ) : (
    //                 <>
    //                     {SubmittedStatus === true ? (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 startIcon={<FaStepBackward />}
    //                                 onClick={() => { setCurrentForm(formNames.employmentForm) }}
    //                             >EMPLOYMENT AND INCOME</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleVerificationSubmit}
    //                                 endIcon={<FaStepForward />}
    //                             >HISTORY</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={(e) => handleModify(e)}
    //                             >MODIFY</Button>
    //                         </div>
    //                     ) : (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 startIcon={<FaStepBackward />}
    //                                 onClick={() => { setCurrentForm(formNames.employmentForm) }}
    //                             >EMPLOYMENT AND INCOME</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="success"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleVerificationSubmit}
    //                             >SAVE</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleVerificationSubmit}
    //                                 endIcon={<FaStepForward />}
    //                             >HISTORY</Button>
    //                         </div>
    //                     )}
    //                 </>
    //             )}
    //         </div>
    //         <ToastContainer
    //             position="top-center"
    //             autoClose={2000}
    //             hideProgressBar={false}
    //             newestOnTop={false}
    //             closeOnClick
    //             rtl={false}
    //             pauseOnFocusLoss
    //             draggable
    //             pauseOnHover
    //             theme="colored"
    //         />
    //     </div>
    // );
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: "100%", maxWidth: "1350px", }}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TenantLeaseApplicationEditPage />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <h4 className="subHeader">Date of Birth</h4>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={["day", "month", "year"]}
                                label="Date of Birth"
                                value={dob}
                                onChange={newValue => {
                                    setDob(newValue);
                                    setDobError("");
                                }}
                                renderInput={(params) => <TextField {...params} margin="dense" required={true} error={dobError ? true : false} helperText={dobError && dobError} disabled={FieldsDisabled} fullWidth={true} />} />
                        </LocalizationProvider>
                        <h4 className="subHeader">Credit Score</h4>
                        <TextField
                            error={creditScoreError ? true : false}
                            helperText={creditScoreError && creditScoreError}
                            label="Credit Score"
                            placeholder="Enter Credit Score"
                            required={true}
                            type="number"
                            margin="dense"
                            variant="outlined"
                            fullWidth={true}
                            value={creditScore}
                            onChange={(e) => {
                                if (e.target.value.length <= 3) {
                                    setCreditScore(e.target.value)
                                }
                            }
                            }
                            disabled={FieldsDisabled}
                        />
                        <h4 className="subHeader">Social Security Number</h4>
                        <TextField
                            error={ssnError ? true : false}
                            helperText={ssnError && ssnError}
                            label="Social Security Number"
                            placeholder="333-22-4444"
                            required={true}
                            margin="dense"
                            variant="outlined"
                            fullWidth={true}
                            value={ssn}
                            onChange={(e) => { onChangeHandler(e); handleInput(e) }}
                            disabled={FieldsDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h4 className="subHeader">Driving License</h4>
                    </Grid>
                    <Grid container spacing={2} sx={{ p: 0 }}>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <TextField
                                error={licenseError ? true : false}
                                helperText={licenseError && licenseError}
                                label="Driver License Number"
                                placeholder="Enter Driver License Number"
                                required={true}
                                margin="dense"
                                variant="outlined"
                                fullWidth={true}
                                value={license}
                                onChange={e => { setLicense(e.target.value) }}
                                disabled={FieldsDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <FormControl
                                fullWidth={true}
                                error={stateError ? true : false}
                                margin="dense"
                                disabled={FieldsDisabled}
                                required={true}>
                                <InputLabel id="demo-simple-select-label">Select State Issued</InputLabel>
                                <Select
                                    label={"Select State Issued"}
                                    fullWidth={true}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                    onChange={e => { setState(e.target.value) }}
                                    value={state}
                                >
                                    <MenuItem value={""}>Select</MenuItem>
                                    {renderStateOptions()}
                                </Select>
                                {stateError && <FormHelperText>{stateError}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day", "month", "year"]}
                                    label="Expiry Date"
                                    value={expiryDate}
                                    onChange={newValue => { setExpiryDate(newValue) }}
                                    renderInput={(params) => <TextField {...params} margin="dense" required={true} error={expiryDateError ? true : false} helperText={expiryDateError && expiryDateError} disabled={FieldsDisabled} fullWidth={true} />} />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h5 className="subHeader">Bank Statement</h5>
                        <div className="uploadBtn" style={{ width: "175px", height: "160px", border: bankStatementError ? '1px solid #d32f2f' : '1px solid #e4e4e4' }}>
                            <input type="file" id="uploadBtn"
                                accept="application/pdf,image/*"
                                onChange={handleBankStatementUpload}
                            />
                            {bankStatement ?
                                <label>
                                    <span onClick={handleBankStatementRemove}>Remove File</span>
                                    <span title={bankStatement?.fileName}>{truncateAddress(bankStatement?.fileName, 20)}</span>
                                </label>
                                :
                                <label htmlFor="uploadBtn" style={{ fontSize: 13 }}>
                                    {uploading ? "Upload Inprogress..." : ""}
                                    {!uploading ? "Drop File / click to Upload" : ""}
                                </label>
                            }
                        </div>
                        <h5 className="subHeader">Credit Report</h5>
                        <div className="uploadBtn" style={{ width: "175px", height: "160px", border: creditReportError ? '1px solid #d32f2f' : '1px solid #e4e4e4' }}>
                            <input type="file" id="uploadBtnCreditReport"
                                accept="*/*"
                                onChange={handleCreditReportUpload}
                            />
                            {creditReport ?
                                <label>
                                    <span onClick={handleCreditReportRemove}>Remove File</span>
                                    <span title={creditReport?.fileName}>{truncateAddress(creditReport?.fileName, 20)}</span>
                                </label>
                                :
                                <label htmlFor="uploadBtnCreditReport" style={{ fontSize: 13 }}>
                                    {creditReportUploading ? "Upload Inprogress..." : ""}
                                    {!creditReportUploading ? "Drop File / click to Upload" : ""}
                                </label>
                            }
                        </div>
                        <h5 className="subHeader">Government ID</h5>
                        <div className="uploadBtn" style={{ width: "175px", height: "160px", border: govIdError ? '1px solid #d32f2f' : '1px solid #e4e4e4' }}>
                            <input type="file" id="uploadBtnGovtId"
                                accept="*/*"
                                onChange={handleGovIdUpload}
                            />
                            {govId ?
                                <label>
                                    <span onClick={handleGovIdRemove}>Remove File</span>
                                    <span title={govId?.fileName}>{truncateAddress(govId?.fileName, 20)}</span>
                                </label>
                                :
                                <label htmlFor="uploadBtnGovtId" style={{ fontSize: 13 }}>
                                    {GovtIdUploading ? "Upload Inprogress..." : ""}
                                    {!GovtIdUploading ? "Drop File / click to Upload" : ""}
                                </label>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {Modify === true ? (
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    sx={{ fontSize: 15 }}
                                    startIcon={<FaStepBackward />}
                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/employment`); }}
                                >EMPLOYMENT AND INCOME</Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{ fontSize: 15 }}
                                    onClick={handleVerificationSubmit}
                                >SAVE</Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    sx={{ fontSize: 15 }}
                                    onClick={handleVerificationSubmit}
                                    endIcon={<FaStepForward />}
                                >HISTORY</Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    sx={{ fontSize: 15 }}
                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/agreement`); }}
                                    endIcon={<FaStepForward />}
                                >GO TO SUBMIT</Button>
                            </div>
                        ) : (
                            <>
                                {SubmittedStatus === true ? (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            startIcon={<FaStepBackward />}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/employment`); }}
                                        >EMPLOYMENT AND INCOME</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleVerificationSubmit}
                                            endIcon={<FaStepForward />}
                                        >HISTORY</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={(e) => handleModify(e)}
                                        >MODIFY</Button>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            startIcon={<FaStepBackward />}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/employment`); }}
                                        >EMPLOYMENT AND INCOME</Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleVerificationSubmit}
                                        >SAVE</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleVerificationSubmit}
                                            endIcon={<FaStepForward />}
                                        >HISTORY</Button>
                                    </div>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div >
    )
}

export default VerificationForm;