import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, CircularProgress, Dialog, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
    getContractorService, getContractorMember, postContractorComment, patchContractorComment, postContractorAttachment, deleteContractorAttachment,
    deleteContractorComment, getSingleProperty, getContractorCurrentLease, getApiBaseURL, postContractorServiceRequestContractorFinance,
    patchContractorServiceRequestContractorFinance, deleteContractorServiceRequestContractorFinance, deleteContractorServiceRequestContractorFinanceAttachment, patchManagerServiceRequest, patchContractorServiceRequest,
    patchContractorServiceRequestDescriptionCheckBox,
} from "../../../services";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "moment";
import { extendMoment } from "moment-range";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ErrorIcon from '@mui/icons-material/Error';
import "semantic-ui-css/semantic.min.css";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import ToolTip from "../../../components/ToolTip";
import ContractorRedirectPopup from "../../../components/ContractorRedirectPopup/ContractorRedirectPopup";

function Contractor() {
    const navigate = useNavigate();
    const params = useParams();
    const moment = extendMoment(Moment);
    const hiddenFileInput = useRef();

    const [property, setProperty] = useState(null);
    const [tenant, setTenant] = useState(null);
    const [serviceRequest, setServiceRequest] = useState(null);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [titleCondition, setTitleCondition] = useState(false);
    const [descriptionCondition, setDescriptionCondition] = useState(false);

    const [attachFiles, setAttachFiles] = useState([]);
    const [comment, setComment] = useState([]);
    const [commentFiles, setCommentFiles] = useState([]);
    const [commonMapview, setcommonMapview] = useState([]);
    const [assignee, setAssignee] = useState();
    const [commentCondition, setcommentCondition] = useState({});
    const [images, setImages] = useState([]);

    const [content, setContent] = useState("");
    const [fileId, setFileId] = useState(0);

    // Contractor Finance History
    const [contractorTotalLabourCharge, setContractorTotalLabourCharge] = useState("0");
    const [contractorTotalMaterialCost, setContractorTotalMaterialCost] = useState("0");
    const [contractorTotalLabourHours, setContractorTotalLabourHours] = useState(0);
    const [contractorFinanceAttachment, setContractorFinanceAttachment] = useState(null);

    // loading
    const [loading, setLoading] = useState(true);

    var config = "";
    const pageLoad = async () => {
        setLoading(true);
        let files = [...attachFiles];
        files.length = 0;
        let cmnt = [...comment];
        cmnt.length = 0;
        let cmtFiles = [...commentFiles];
        cmtFiles.length = 0;
        await getContractorService(params.ContractorId, params.serviceId).then((res) => {
            let data = res.data.data;
            let getDetails = { ...data }
            setServiceRequest(getDetails);
            setTitle(getDetails?.title);
            setDescription(getDetails?.description);
            // Contractor Finance History
            setContractorTotalLabourCharge(getDetails?.totalContractorLaborCost);
            setContractorTotalLabourHours(getDetails?.totalContractorLaborHours);

            if (getDetails.lease) {
                getContractorCurrentLease(params.ContractorId, params.serviceId).then((res) => {
                    let responseData = res?.data?.data;
                    setTenant(responseData?.leaseTenants);
                })
            }

            if (getDetails?.property) {
                getSingleProperty(getDetails.property).then((res) => {
                    const getProp = res?.data?.data;
                    setProperty(getProp);
                })
            }

            if (getDetails.attachments.length != 0) {
                getDetails.attachments.map((g) => {
                    const authToken = sessionStorage.getItem("authToken");
                    config = {
                        headers: { Authorization: `Bearer ${authToken}` },

                    };
                    fetch(
                        `${getApiBaseURL()}/contractor/${params.ContractorId}/${g.url}`, config
                    ).then(res => {
                        return res.blob();
                    }).then(blob => {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(blob);
                        reader.onload = function () {
                            var imageDataUrl = reader.result;
                            const link = document.createElement('a');
                            link.href = imageDataUrl;
                            if (blob.type == "image/png"
                                || blob.type == "image/gif"
                                || blob.type == "image/jpeg"
                                || blob.type == "image/svg"
                                || blob.type == "image/jpg"
                            ) {
                                files.push({
                                    "files": imageDataUrl,
                                    "id": g.id,
                                    "url": g.url,
                                })
                            }
                            if (blob.type == "application/pdf") {
                            }
                            setAttachFiles(files.flat(Infinity))
                        }
                    })
                })
            }
            if (getDetails.comments.length != 0) {
                getDetails.comments.map((e) => {
                    e.attachments.map((h) => {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },
                        };
                        fetch(
                            `${getApiBaseURL()}/contractor/${params.ContractorId}/${h.url}`, config
                        ).then(res => {
                            return res.blob();
                        }).then(blob => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement('a');
                                link.href = imageDataUrl;
                                if (blob.type == "image/png"
                                    || blob.type == "image/gif"
                                    || blob.type == "image/jpeg"
                                    || blob.type == "image/svg"
                                    || blob.type == "image/jpg"
                                ) {
                                    cmtFiles.push({
                                        "files": imageDataUrl,
                                        "id": h.id,
                                        "url": h.url,
                                    })
                                }
                                if (blob.type == "application/pdf") {
                                }
                                setCommentFiles(cmtFiles.flat(Infinity))
                            }
                        })
                    })
                    cmnt.push({
                        "attachment": e.attachments,
                        "content": e.content,
                        "createdAt": e.createdAt,
                        "creator": e.creator,
                        "id": e.id,
                        "updatedAt": e.updatedAt,
                    })
                    setComment(cmnt);
                })
            }
            let groupedPeople = groupBy(getDetails?.events, "id");
            setcommonMapview(groupedPeople);
        }).finally(() => {
            setLoading(false);
        });
        getContractorMember(params.ContractorId, params.serviceId).then((res) => {
            let data = res.data.data;
            setAssignee(data);
        }).finally(() => {
            setLoading(false);
        });
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        pageLoad();
    }, []);

    // Activities comments expand and hide 
    const toggle = id => {
        setcommentCondition(prevcommentCondition => ({ [id]: !prevcommentCondition[id] }));
    }

    const handleClick = (e, id) => {
        e.preventDefault();
        setFileId(id);
        hiddenFileInput.current.click();
    };

    const UpdateTitleClick = () => {
        let data = {
            title: title,
        }
        patchContractorServiceRequest(params.ContractorId, params.serviceId, data).then((res) => {
            pageLoad();
            setTitleCondition(false);
            setDescriptionCondition(false);
        }).catch((err) => console.log(`Error${err}`));
    }

    const UpdateDescriptionClick = () => {
        let data = {
            description: description,
        }
        patchContractorServiceRequest(params.ContractorId, params.serviceId, data).then((res) => {
            pageLoad();
            setTitleCondition(false);
            setDescriptionCondition(false);
        }).catch((err) => console.log(`Error${err}`));
    }

    const descriptionCheckbox = async (event) => {
        const target = event.target;
        if (target.tagName.toLowerCase() === 'input' && target.getAttribute('type') === 'checkbox') {
            // Toggle the checked state of the checkbox
            const isChecked = target.hasAttribute('checked');
            if (isChecked) {
                target.removeAttribute('checked');
            } else {
                target.setAttribute('checked', '');
            }
            // Retrieve all checkbox elements
            const checkboxes = document.querySelectorAll('.contractor-service-request-html-description-checkbox');
            let newdata = "";
            Array.from(checkboxes)
                .map((checkbox) => {
                    newdata = checkbox.innerHTML
                })
            let data = { newHtmlDesc: newdata };
            await patchContractorServiceRequestDescriptionCheckBox(params.ContractorId, params.serviceId, data).then((res) => {
                pageLoad();
            }).catch((err) => { toast.error(err.response.data.error.message) });
        }
    }

    const handleCommentContentChange = (e, index, comment) => {
        const newData = [...comment];
        newData[index].content = e.target.value;
        setComment(newData);
    }

    const commentSaveClick = (e, id, cat, content) => {
        let data = {
            content: content,
        }
        patchContractorComment(params.ContractorId, params.serviceId, id, data).then((res) => {
            setcommentCondition(prevcommentCondition => ({ ...prevcommentCondition, [cat]: !prevcommentCondition[cat] }));
            toast.info("Updated Comment Successfully");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const addCommentAttachment = (e) => {
        let formData = new FormData();
        formData.append("attachment", e.target.files[0]);
        postContractorAttachment(params.ContractorId, params.serviceId, fileId, formData).then((res) => {
            toast.info("Comment Attachment Added");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const removeCommentImage = (e, id, idd) => {
        deleteContractorAttachment(params.ContractorId, params.serviceId, id, idd).then((res) => {
            toast.info("Comment Attachment Removed");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const deleteClick = (e, id) => {
        deleteContractorComment(params.ContractorId, params.serviceId, id).then((res) => {
            pageLoad();
            toast.info("Comment Deleted");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const postClick = () => {
        let formData = new FormData();
        formData.append("content", content);
        Array.from(images).forEach(item => { formData.append("attachments", item) });
        postContractorComment(params.ContractorId, params.serviceId, formData).then((res) => {
            toast.success("Added Comment Successfully");
            pageLoad();
            setContent("");
            setImages([]);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const renderImages = () => {
        return attachFiles.map((item, index) => {
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={item.files} loading="lazy" alt="images" style={{ height: "110px", width: "175px", marginTop: "20px", marginBottom: "20px", cursor: "pointer" }} onClick={(e) => imageClick(e, item.url)} />
                    </Paper>
                </div>
            );
        });
    };

    const imageClick = (e, url) => {
        e.preventDefault();
        fetch(
            `${getApiBaseURL()}/contractor/${params.ContractorId}/${url}`
        ).then((res) => {
            return res.blob();
        })
            .then((blob) => {
                var reader = new window.FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function () {
                    var imageDataUrl = reader.result;
                    const link = document.createElement("a");
                    link.href = imageDataUrl;
                    if (blob.type == "image/png" || blob.type == "image/jpeg") {
                        const newTab = window.open();
                        newTab?.document.write(
                            `<!DOCTYPE html><head><title>preview</title></head><body style="background: #0e0e0e; height:'100vh';"><div style="display:flex;justify-content:center;align-item:center"><img align="center "height='600rem' src="${imageDataUrl}"  ><div></body></html>`
                        );
                        newTab?.document.close();
                    }
                };
            });
    };

    const attachImages = () => {
        return Array.from(images).map((item, index) => {
            const url = URL.createObjectURL(item)
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={url} alt="images" style={{ height: "110px", width: "175px", marginTop: "20px" }} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={() => { removeAttachImage(index) }}>
                            <DeleteIcon color="error" />
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const handleImagesAdd = (e) => {
        setImages([...images, ...e.target.files]);
    }

    const removeAttachImage = (index) => {
        let toDelete;
        const newImages = images.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });
        setImages(newImages);
    };

    // Contractor Finance History Add

    const [contractorRecieptDialog, setContractorRecieptDialog] = useState(false);

    const handleContractorFinanceAttachmentChange = (e) => {
        setContractorFinanceAttachment(e.target.files[0]);
    }

    const removecontractorFinaceAddAtachment = () => {
        setContractorFinanceAttachment(null);
    }

    const contractorFinanceAddedImageClick = (e, file) => {
        e.preventDefault();
        let url = URL.createObjectURL(file);
        let newTab = window.open();
        newTab?.document.write(
            `<!DOCTYPE html><head><title>preview</title></head><body style="background: #0e0e0e; height:'100vh';"><div style="display:flex;justify-content:center;align-item:center"><img align="center "height='600rem' src="${url}"  ><div></body></html>`
        );
        newTab?.document.close();
    }

    const contractorFinanceSaveClick = () => {
        if (!Number(contractorTotalLabourCharge) === 0 || !Number(contractorTotalMaterialCost) === 0 || !contractorTotalLabourHours) {
            toast.error("The fields is blank. It should be required !");
        } else if (!contractorFinanceAttachment) {
            setContractorRecieptDialog(true);
        } else {
            const currentDate = new Date();
            let formData = new FormData();
            formData.append("financeEntryDate", currentDate);
            formData.append("financeEntryTransactionType", "REPAIRS_EXPENSE")
            formData.append("contractorTotalLabourCharge", Number(contractorTotalLabourCharge));
            formData.append("contractorTotalMaterialCost", Number(contractorTotalMaterialCost));
            formData.append("contractorTotalLabourHours", contractorTotalLabourHours);
            formData.append("contractor-finance-attachment", contractorFinanceAttachment);

            postContractorServiceRequestContractorFinance(params.ContractorId, params.serviceId, formData, `?status=${"totalMaterialCost"}`).then((res) => {
                toast.success("Contractor finance saved successfully.");
                pageLoad();
                removecontractorFinaceAddAtachment();
                setContractorTotalMaterialCost("0");
            }).catch((err) => { toast.error(err.response.data.error.message) });
        }
    }

    // Contractor Finance History Update

    const [contractorFinanceDeleteDialog, setContractorFinanceDeleteDialog] = useState(false);
    const [contractorFinanceDeleteFileDialog, setContractorFinanceDeleteFileDialog] = useState(false);
    const [contractorFinanceId, setContractorFinanceId] = useState("");
    const [contractorFinanceAttachId, setContractorFinanceAttachId] = useState("");
    const [contractorFinanceEditIndex, setContractorFinanceEditIndex] = useState(null);

    const contractorFinanceEditIconClick = (index) => {
        setContractorFinanceEditIndex(index);
    }

    const handleContractorFinanceAttachmentEditChange = (e, financeId) => {
        let formData = new FormData();
        formData.append("contractor-finance-attachment", e.target.files[0]);
        patchContractorServiceRequestContractorFinance(params.ContractorId, params.serviceId, financeId, formData).then((res) => {
            toast.success("Image Updated.");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleEditTotalMaterialsCostChange = (e, index, serviceRequest) => {
        const newData = { ...serviceRequest };
        newData.contractorFinance[index].totalMaterialsCost = e.target.value;
        setServiceRequest(newData);
    }

    const contractorFinanceUpdateClick = (contractorFinance) => {
        if (!contractorFinance?.contractorAttachment) {
            setContractorRecieptDialog(true);
        } else {
            const currentDate = new Date();
            let formData = new FormData();
            formData.append("financeEntryDate", currentDate);
            formData.append("financeEntryTransactionType", "REPAIRS_EXPENSE")
            formData.append("contractorTotalLabourCharge", Number(contractorTotalLabourCharge));
            formData.append("contractorTotalMaterialCost", Number(contractorFinance?.totalMaterialsCost));
            formData.append("contractorTotalLabourHours", contractorTotalLabourHours);
            patchContractorServiceRequestContractorFinance(params.ContractorId, params.serviceId, contractorFinance?.id, formData).then((res) => {
                setContractorFinanceEditIndex(null);
                toast.success("Contractor finance updated successfully.");
                pageLoad();
            }).catch((err) => { toast.error(err.response.data.error.message) });
        }
    }

    const contractorFinanceDeleteFileDialogOpen = (contractorFinance) => {
        setContractorFinanceId(contractorFinance?.id);
        setContractorFinanceAttachId(contractorFinance?.contractorAttachment?.id);
        setContractorFinanceDeleteFileDialog(true);
    }

    const contractorFinanceDeleteFileDialogClose = () => {
        setContractorFinanceId("");
        setContractorFinanceAttachId("");
        setContractorFinanceDeleteFileDialog(false);
    }

    const removecontractorFinaceUpdateAtachment = (contracFinId, contracAttachId) => {
        deleteContractorServiceRequestContractorFinanceAttachment(params.ContractorId, params.serviceId, contracFinId, contracAttachId).then((res) => {
            contractorFinanceDeleteFileDialogClose()
            toast.success("Image Removed.");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const contractorFinanceDeleteDialogOpen = (contractorFinance) => {
        setContractorFinanceId(contractorFinance?.id);
        setContractorFinanceDeleteDialog(true);
    }

    const contractorFinanceDeleteDialogClose = () => {
        setContractorFinanceId("");
        setContractorFinanceDeleteDialog(false);
    }

    const deleteContractFinance = (contractorFinId) => {
        deleteContractorServiceRequestContractorFinance(params.ContractorId, params.serviceId, contractorFinId).then((res) => {
            contractorFinanceDeleteDialogClose();
            toast.success("Contractor finance deleted successfully.");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const contractorTotalLabourChargeUpdateClick = () => {
        let formData = new FormData();
        formData.append("contractorTotalLabourCharge", Number(contractorTotalLabourCharge));
        postContractorServiceRequestContractorFinance(params.ContractorId, params.serviceId, formData, `?status=${"totalLabourCharge"}`).then((res) => {
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const contractorTotalLabourHoursUpdateClick = () => {
        let formData = new FormData();
        formData.append("contractorTotalLabourHours", contractorTotalLabourHours);
        postContractorServiceRequestContractorFinance(params.ContractorId, params.serviceId, formData, `?status=${"totalLabourHours"}`).then((res) => {
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const TruncateText = (text) => {
        const truncatedText = text.length > 10 ? `${text.slice(0, 10)}...` : text;
        return <span title={text}>{truncatedText}</span>;
    };

    const getcontractorFinanceLabourRate = (contractorFinance, contractorTotalMaterialCos) => {
        let cost = 0;
        let labourCost = 0;
        contractorFinance && contractorFinance.forEach((fin) => {
            cost += Number(fin?.totalMaterialsCost);
        })
        labourCost = cost + Number(contractorTotalMaterialCos);
        return "Total Materials cost: " + "$" + labourCost.toFixed(2);
    }

    const getContractorFinanceTotalCost = (contractorFinance, contractorTotalLabourChar, contractorTotalMaterialCos) => {
        let cost = 0;
        let labourCost = 0;
        contractorFinance && contractorFinance.forEach((fin) => {
            cost += Number(fin?.totalMaterialsCost);
        })
        labourCost = cost + Number(contractorTotalLabourChar) + Number(contractorTotalMaterialCos);
        return "Total to be paid:" + "$" + labourCost.toFixed(2);
    }

    const viewAllServiceRequestClick = () => {
        navigate(`/contractor/${params.ContractorId}/group-service-requests/${params.serviceId}/dashboard`)
    }

    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: '30px' }}>
            <CardContent style={{ textAlign: "center" }}>
                <p style={{ fontSize: 15, letterSpacing: 2, color: "grey" }}>SERVICE REQUESTS / {property?.address?.line1} {property?.address?.unitNumber} /  <span># {serviceRequest?.numberId}</span></p>
            </CardContent>
            <CardContent className="contractor-flex-box">
                {property ?
                    <div className="box-content-align">
                        <Box
                            sx={{
                                minWidth: 160,
                                minHeight: 80,
                                borderRadius: 5,
                                border: "1px solid #2196F3",
                                padding: "10px"
                            }}>
                            <div style={{ margin: "10px" }}>
                                <p className="contractor-flex-title">Manager</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <p style={{ marginBottom: "0px", fontWeight: "bold", fontSize: '16px' }}>{property?.propertyManagers[0]?.manager?.user?.name?.first + " " + property?.propertyManagers[0]?.manager?.user?.name?.last}</p>
                                    <ContractorRedirectPopup
                                        name={property?.propertyManagers[0]?.manager?.user?.name?.first + " " + property?.propertyManagers[0]?.manager?.user?.name?.last}
                                        email={property?.propertyManagers[0]?.manager?.user?.email?.id}
                                        phone={property?.propertyManagers[0]?.manager?.user?.phone?.code + " " + property?.propertyManagers[0]?.manager?.user?.phone?.number} />
                                </div>
                            </div>
                        </Box>
                    </div>
                    : ""}
                {tenant ?
                    <div className="box-content-align">
                        <Box
                            sx={{
                                width: 180,
                                height: 80,
                                borderRadius: 5,
                                border: "1px solid #2196F3",
                                padding: "10px"
                            }}
                        >
                            <div style={{ margin: "10px" }}>
                                <p className="contractor-flex-title">Tenant</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <p style={{ marginBottom: "0px", fontWeight: "bold", fontSize: '16px' }}>{tenant[0]?.name?.first + " " + tenant[0]?.name?.last}</p>
                                    <ContractorRedirectPopup
                                        name={tenant[0]?.name?.first + " " + tenant[0]?.name?.last}
                                        email={tenant[0]?.email?.id}
                                        phone={tenant[0]?.phone?.code + " " + tenant[0]?.phone?.number} />
                                </div>
                            </div>
                        </Box>
                    </div>
                    : ""}
                {property?.address ?
                    <div className="box-content-align">
                        <Box
                            sx={{
                                minWidth: 200,
                                minHeight: 110,
                                borderRadius: 5,
                                border: "1px solid #2196F3",
                                padding: "10px"
                            }}
                        >
                            <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                                <p className="contractor-flex-title">Property</p>
                                <p style={{ marginBottom: "0px", fontWeight: "bold", fontSize: '16px' }}>{property?.address?.line1}</p>
                                <p style={{ marginBottom: "0px", fontWeight: "bold", fontSize: '16px' }}>{property?.address?.line2} {property?.address?.unitNumber}</p>
                                <p style={{ marginBottom: "0px", fontWeight: "bold", fontSize: '16px' }}>{property?.address?.city}, {property?.address?.state} - {property?.address?.zipCode}</p>
                            </div>
                        </Box>
                    </div>
                    : ""}
            </CardContent>
            <CardContent style={{ textAlign: "center" }}>
                <p style={{ fontSize: 20, fontWeight: 600, marginBottom: "10px" }}>Service Request</p>
                <Button variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ fontSize: 15 }}
                    onClick={() => { viewAllServiceRequestClick() }}
                >VIEW ALL SERVICE REQUESTS</Button>
            </CardContent>
            <CardContent style={{ width: "75%", marginLeft: "auto", marginRight: "auto" }}>
                {/* <div> */}
                <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'wrap', gap: 10, alignItems: 'flex-start' }}>
                    <p className="contractor-flex-title"># {serviceRequest?.numberId}</p>
                    {serviceRequest?.priority === 0 ? <ReportProblemIcon style={{ color: "#F4C94C" }} /> : ""}
                    {serviceRequest?.priority === 1 ? <ReportProblemIcon style={{ color: "#F4C94C" }} /> : ""}
                    {serviceRequest?.priority === 2 ? <ErrorIcon style={{ color: "#DC3545" }} /> : ""}
                    {titleCondition === false ? (
                        <p style={{ fontSize: "1.17rem", fontWeight: "bold" }}>{serviceRequest?.title}</p>) : (
                        <div style={{ flex: 1 }}>
                            <TextField id="outlined-basic" variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={e => { setTitle(e.target.value) }} />
                            <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'wrap', gap: 10, justifyContent: "right" }}>
                                <Button color="success"
                                    type="submit"
                                    sx={{ fontSize: 15 }}
                                    onClick={UpdateTitleClick}
                                >SAVE</Button>
                                <Button color="error"
                                    type="submit"
                                    sx={{ fontSize: 15 }}
                                    onClick={() => setTitleCondition(false)}
                                >CANCEL</Button>
                            </div>
                        </div>
                    )}
                    {titleCondition === false ?
                        <div>
                            <EditIcon style={{ color: "#2196F3", cursor: 'pointer' }} onClick={() => setTitleCondition(true)} />
                        </div> : ""}
                </div>
                <div>
                    <span style={{ fontSize: "1em", color: "#6c757d" }}>
                        <strong style={{ color: "black", fontSize: 15 }}>Description </strong>
                    </span>
                </div>
                <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'wrap', gap: 10, paddingTop: "20px" }}>
                    {descriptionCondition === false ?
                        <div className="contractor-service-request-html-description-checkbox" style={{ fontSize: "1.17rem", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: serviceRequest?.newHtmlDesc }} onClick={(e) => { descriptionCheckbox(e) }} /> : ""}
                    {descriptionCondition === true ?
                        <div style={{ flex: 1 }}>
                            <TextField
                                id="outlined-basic"
                                label="Notes"
                                variant="outlined"
                                fullWidth={true}
                                rows={5}
                                multiline
                                placeholder="Description"
                                value={description}
                                onChange={e => { setDescription(e.target.value) }} />
                            <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'wrap', gap: 10, justifyContent: "right" }}>
                                <Button color="success"
                                    type="submit"
                                    sx={{ fontSize: 15 }}
                                    onClick={UpdateDescriptionClick}
                                >SAVE</Button>
                                <Button color="error"
                                    type="submit"
                                    sx={{ fontSize: 15 }}
                                    onClick={() => setDescriptionCondition(false)}
                                >CANCEL</Button>
                            </div>
                        </div>
                        : ""}
                    {descriptionCondition === false ?
                        <div>
                            <EditIcon style={{ color: "#2196F3", cursor: 'pointer' }} onClick={() => setDescriptionCondition(true)} />
                        </div> : ""}
                </div>
                {/* </div> */}
                <div>
                    <div className="img-container">
                        {renderImages()}
                    </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                    <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Assigned to</p>
                    {assignee && assignee ? assignee.map(details => details.id === serviceRequest?.assignee?.assignee ? (
                        <p style={{ marginBottom: "30px" }}>{details.name}</p>) : "") : ""}
                    {!serviceRequest?.assignee?.assignee ?
                        <p style={{ marginBottom: "30px" }}>None</p> : ""}
                </div>
                <div>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p style={{ fontSize: 16, fontWeight: "bold" }}>Enter Your Charges</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ display: 'flex', boxSizing: 'border-box', flexFlow: 'row wrap', placeContent: 'flex-start space-between', alignItems: "flex-start" }}>
                                <div style={{ display: 'flex', flex: '1 1 48%', maxWidth: '48%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                    <FormControl fullWidth sx={{ my: 1 }}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Total Labour Charge</InputLabel>
                                        <OutlinedInput
                                            onBlur={() => { contractorTotalLabourChargeUpdateClick() }}
                                            variant="outlined"
                                            value={contractorTotalLabourCharge}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                                setContractorTotalLabourCharge(numericValue);
                                            }}
                                            inputProps={{
                                                inputMode: 'numeric', // Restrict input to numeric characters
                                                pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                            }}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            label="Total Labor Charge"
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', flex: '1 1 48%', maxWidth: '48%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                    <FormControl fullWidth sx={{ my: 1 }}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Total Labour Hours</InputLabel>
                                        <OutlinedInput
                                            onBlur={() => { contractorTotalLabourHoursUpdateClick() }}
                                            type="number"
                                            variant="outlined"
                                            value={contractorTotalLabourHours}
                                            onChange={(e) => {
                                                setContractorTotalLabourHours(parseInt(e.target.value));
                                            }}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            label="Total Label Hours"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            {serviceRequest?.contractorFinance.map((contractorFin, index) => (
                                <div style={{ display: 'flex', boxSizing: 'border-box', flexFlow: 'row wrap', placeContent: 'flex-start space-between', alignItems: "flex-end" }} key={index}>
                                    <div style={{ display: 'flex', flex: '1 1 40%', maxWidth: '40%', boxSizing: 'border-box' }}>
                                        <FormControl fullWidth sx={{ my: 1 }}>
                                            <InputLabel htmlFor="outlined-adornment-amount">Total Materials Cost</InputLabel>
                                            <OutlinedInput
                                                disabled={contractorFinanceEditIndex === index ? false : true}
                                                variant="outlined"
                                                value={contractorFin?.totalMaterialsCost}
                                                onChange={(e) => handleEditTotalMaterialsCostChange(e, index, serviceRequest)}
                                                inputProps={{
                                                    inputMode: 'numeric', // Restrict input to numeric characters
                                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                                }}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Total Material Cost"
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{ display: 'flex', flex: '1 1 40%', maxWidth: '40%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                        <div style={{ display: "flex", flexDirection: "column", marginTop: '8px', marginBottom: '8px' }}>
                                            {contractorFinanceEditIndex === index ?
                                                <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                                    <span>Replace Receipt</span>
                                                    <span>
                                                        <input type="file" id="uploadBtnContractorFinanceUpdate" accept="image/*"
                                                            style={{ display: "none" }}
                                                            onChange={(e) => { handleContractorFinanceAttachmentEditChange(e, contractorFin?.id) }}
                                                        />
                                                        <label htmlFor="uploadBtnContractorFinanceUpdate" title="Upload Reciept">
                                                            <CloudUploadIcon color="primary" sx={{ cursor: "pointer" }} />
                                                        </label>
                                                    </span>
                                                </span>
                                                : ""}
                                            <Card sx={{ border: '1px solid grey', padding: '10px', boxShadow: 'none' }}>
                                                <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                    <span>
                                                        {contractorFin?.contractorAttachment ?
                                                            <span className="hover-blue-text" style={{ cursor: 'pointer' }} onClick={(e) => { imageClick(e, contractorFin?.contractorAttachment?.url) }}>
                                                                {TruncateText(contractorFin?.contractorAttachment?.fileName)}
                                                            </span>
                                                            :
                                                            <span>
                                                                No Receipts added.
                                                            </span>
                                                        }
                                                    </span>
                                                    {contractorFin?.contractorAttachment && contractorFinanceEditIndex === index ?
                                                        <span title="Delete Reciept">
                                                            <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { contractorFinanceDeleteFileDialogOpen(contractorFin) }} />
                                                        </span>
                                                        : ""}
                                                </span>
                                            </Card>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flex: '1 1 16%', maxWidth: '16%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <span>
                                                {contractorFinanceEditIndex == index ?
                                                    <Button variant="contained" color="success"
                                                        type="submit"
                                                        sx={{ fontSize: 15, marginBottom: "8px" }}
                                                        onClick={() => { contractorFinanceUpdateClick(contractorFin) }}>
                                                        SAVE
                                                    </Button>
                                                    :
                                                    <span title="Edit">
                                                        <EditIcon sx={{ cursor: 'pointer' }} color="primary" onClick={() => { contractorFinanceEditIconClick(index) }} />
                                                    </span>
                                                }
                                            </span>
                                            {contractorFinanceEditIndex != index ?
                                                <span title="Delete">
                                                    <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { contractorFinanceDeleteDialogOpen(contractorFin) }} />
                                                </span>
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            ))}
                            {contractorFinanceEditIndex == null ?
                                <div style={{ display: 'flex', boxSizing: 'border-box', flexFlow: 'row wrap', placeContent: 'flex-start space-between', alignItems: "flex-end" }}>
                                    <div style={{ display: 'flex', flex: '1 1 40%', maxWidth: '40%', boxSizing: 'border-box' }}>
                                        <FormControl fullWidth sx={{ my: 1 }}>
                                            <InputLabel htmlFor="outlined-adornment-amount">Total Materials Cost</InputLabel>
                                            <OutlinedInput
                                                variant="outlined"
                                                value={contractorTotalMaterialCost}
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                                    setContractorTotalMaterialCost(numericValue);
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric', // Restrict input to numeric characters
                                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                                }}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Total Material Cost"
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{ display: 'flex', flex: '1 1 40%', maxWidth: '40%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                        <div style={{ display: "flex", flexDirection: "column", marginTop: '8px', marginBottom: '8px' }}>
                                            <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                                <span>Upload Receipt</span>
                                                <span>
                                                    <input type="file" id="uploadBtnContractorFinanceAdd" accept="image/*"
                                                        style={{ display: "none" }}
                                                        onChange={handleContractorFinanceAttachmentChange}
                                                    />
                                                    <label htmlFor="uploadBtnContractorFinanceAdd" title="Upload Reciept">
                                                        <CloudUploadIcon color="primary" sx={{ cursor: "pointer" }} />
                                                    </label>
                                                </span>
                                            </span>
                                            <Card sx={{ border: '1px solid grey', padding: '10px', boxShadow: 'none' }}>
                                                <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                    <span>
                                                        {contractorFinanceAttachment ?
                                                            <span className="hover-blue-text" style={{ cursor: 'pointer' }} onClick={(e) => { contractorFinanceAddedImageClick(e, contractorFinanceAttachment) }}>
                                                                {contractorFinanceAttachment?.name}
                                                            </span>
                                                            :
                                                            <span>
                                                                No Receipts added.
                                                            </span>
                                                        }
                                                    </span>
                                                    {contractorFinanceAttachment ?
                                                        <span title="Delete Reciept">
                                                            <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { removecontractorFinaceAddAtachment() }} />
                                                        </span>
                                                        : ""}
                                                </span>
                                            </Card>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flex: '1 1 16%', maxWidth: '16%', boxSizing: 'border-box', flexDirection: 'column' }}>
                                        <Button variant="contained" color="success"
                                            type="submit"
                                            sx={{ fontSize: 15, marginTop: "8px", marginBottom: '8px' }}
                                            onClick={contractorFinanceSaveClick}
                                        >SAVE</Button>
                                    </div>
                                </div>
                                : ""}
                            <CardContent style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ paddingTop: 30 }}>
                                    <h3 style={{ fontSize: "12px", fontWeight: "bolder", textAlign: 'end' }}>{getcontractorFinanceLabourRate(serviceRequest?.contractorFinance, contractorTotalMaterialCost)}</h3>
                                </div>
                                <div style={{ paddingTop: 30 }}>
                                    <Typography
                                        variant="h6"
                                        textAlign="center"
                                    >
                                        {getContractorFinanceTotalCost(serviceRequest?.contractorFinance, contractorTotalLabourCharge, contractorTotalMaterialCost)}
                                    </Typography>
                                </div>
                            </CardContent>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </CardContent>
            <CardContent>
                <strong>Activity&nbsp;</strong>
                <span>
                    created by
                </span>
                {assignee && assignee ? assignee.map(details => details.type === "Manager" ? (
                    <small style={{ fontSize: "1em" }}>
                        <strong style={{ fontSize: "1em" }}> {details.name} </strong>
                    </small>) : "") : ""}
                <small style={{ fontSize: "1em" }}>on {serviceRequest?.createdAt ? moment(serviceRequest?.createdAt).format('MMM D, YYYY, h:mm:ss A') : ""}</small>
            </CardContent>
            <CardContent style={{ display: "flex", flexDirection: "column", boxSizing: "border-box" }}>
                <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box", width: "75%", marginLeft: "auto", marginRight: "auto" }}>
                    {Object.keys(commonMapview).map((cat) => (
                        <div>
                            {commonMapview[cat].map((f, t) => (
                                <div>
                                    {f.type !== 0 && f.type !== 8 && f.type !== 9 && f.type !== 7 ?
                                        <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
                                            {assignee && assignee ? assignee.map((details, i) => (
                                                <div>
                                                    {details.id === commonMapview[cat][t].details.openerId ?
                                                        <span style={{ fontSize: "1em" }}>
                                                            <small style={{ fontSize: "1em" }}>
                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                            </small>
                                                            <small style={{ fontSize: "1em" }}>reopened on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span> : ""}
                                                    {details.id === commonMapview[cat][t].details.closerId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            <small style={{ fontSize: "1em" }}>
                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                            </small>
                                                            <small style={{ fontSize: "1em" }}>closed on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {commonMapview[cat][t].details.newAssigneeId === null && commonMapview[cat][t].details.unAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.unAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>unassigned </small>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        {details.id === commonMapview[cat][t].details.changerId ?
                                                                            <strong style={{ fontSize: "1em" }}>{details.name} </strong> : ""}
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ("")}
                                                        </span> : ""}
                                                    {commonMapview[cat][t].details.unAssigneeId === null && commonMapview[cat][t].details.newAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>assigned to </small>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        {details.id === commonMapview[cat][t].details.changerId ? (
                                                                            <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ("")}
                                                        </span> : ""}
                                                    {details.id === commonMapview[cat][t].details.appointeeContractorId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            {assignee && assignee ? assignee.map((details, i) => (
                                                                <small style={{ fontSize: "1em" }} key={i}>
                                                                    {details.id === commonMapview[cat][t].details.changerId ? (
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                </small>)) : undefined}
                                                            <small style={{ fontSize: "1em" }}>appoined <strong>{details.name}</strong> as contractor on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {details.id === commonMapview[cat][t].details.unappointeeContractorId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            {assignee && assignee ? assignee.map((details, i) => (
                                                                <small style={{ fontSize: "1em" }} key={i}>
                                                                    {details.id === commonMapview[cat][t].details.changerId ? (
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                </small>)) : undefined}
                                                            <small style={{ fontSize: "1em" }}>unappoined <strong>{details.name}</strong> as contractor on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {commonMapview[cat][t].details.unAssigneeId !== null && commonMapview[cat][t].details.newAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    {assignee && assignee ? assignee.map((details, i) => (
                                                                        <small style={{ fontSize: "1em" }} key={i}>
                                                                            {details.id === commonMapview[cat][t].details.changerId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>assigned to </small>
                                                                    {assignee && assignee ? assignee.map((details, i) => (
                                                                        <small style={{ fontSize: "1em" }} key={i}>
                                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>and unassigned </small>
                                                                    {assignee && assignee ? assignee.map((details, i) => (
                                                                        <small style={{ fontSize: "1em" }} key={i}>
                                                                            {details.id === commonMapview[cat][t].details.unAssigneeId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ""}
                                                        </span> : ""}
                                                </div>)) : undefined}
                                        </div> : ""}
                                    {f.type !== 0 && f.type !== 8 && f.type !== 9 ?
                                        <div>
                                            {commonMapview[cat][t].details !== undefined ?
                                                <div>
                                                    {comment.map((k, ki) => (
                                                        <div key={ki}>
                                                            {commonMapview[cat][t].details.commentId === k.id ?
                                                                <div>
                                                                    <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "1rem", paddingTop: "1rem" }}>
                                                                        <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "flex-start" }}>
                                                                            <div>
                                                                                <Avatar src="/broken-image.jpg" style={{ backgroundColor: "whitesmoke", color: "#FF805D", marginTop: "0.5rem", marginRight: "1rem" }} />
                                                                            </div>
                                                                            <div style={{ display: 'flex', boxSizing: "border-box", flexDirection: "column", flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                                                                                <div style={{ marginBottom: "10px" }}>
                                                                                    <span style={{ fontSize: "1em" }}>
                                                                                        <small style={{ fontSize: "1em" }}>
                                                                                            <strong style={{ fontSize: "1em" }}>Akil Mon </strong>
                                                                                        </small>
                                                                                    </span>
                                                                                    <small style={{ fontSize: "1em" }}>commented on {k.createdAt ? moment(k.createdAt).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                                </div>
                                                                                <div>
                                                                                    {k.attachment !== undefined ?
                                                                                        <div className="img-container">
                                                                                            {k.attachment.map((j, k) => (
                                                                                                <div key={k}>
                                                                                                    {commentFiles.map((g, h) => (
                                                                                                        <div>
                                                                                                            {commentFiles[h].id == j.id ? (
                                                                                                                <Paper variant="outlined" square sx={{ height: 160 }}>
                                                                                                                    <img
                                                                                                                        style={{
                                                                                                                            height: "110px",
                                                                                                                            width: "175px",
                                                                                                                            marginTop: "20px",
                                                                                                                            cursor: "pointer"
                                                                                                                        }}
                                                                                                                        loading="lazy"
                                                                                                                        src={commentFiles[h].files}
                                                                                                                        onClick={(e) => imageClick(e, j.url)}
                                                                                                                    />
                                                                                                                    {commentCondition[cat] ?
                                                                                                                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={(e) => { removeCommentImage(e, commonMapview[cat][t].details.commentId, j.id) }}
                                                                                                                        ><DeleteIcon color="error" /></p> : ""}
                                                                                                                </Paper>
                                                                                                            ) : (
                                                                                                                null
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            ))}
                                                                                        </div> : ""}
                                                                                </div>
                                                                                {!commentCondition[cat] ?
                                                                                    <div style={{ paddingTop: "1rem" }}>
                                                                                        {k.content}
                                                                                    </div> : ""}
                                                                                {commentCondition[cat] ?
                                                                                    <TextField id="outlined-basic" label="Notes" variant="outlined"
                                                                                        fullWidth={true}
                                                                                        rows={3}
                                                                                        sx={{ mb: 5, mt: 3 }}
                                                                                        multiline
                                                                                        placeholder="Write a Comment"
                                                                                        value={k.content}
                                                                                        onChange={e => { handleCommentContentChange(e, ki, comment) }}
                                                                                    /> : null}
                                                                                {commentCondition[cat] ?
                                                                                    <CardContent style={{ display: "flex", justifyContent: "right", padding: "0px" }}>
                                                                                        <label htmlFor="uploadBtn">
                                                                                            <AttachFileIcon
                                                                                                style={{
                                                                                                    fontSize: "25px",
                                                                                                    cursor: "pointer",
                                                                                                    marginTop: "3px",
                                                                                                }}
                                                                                                onClick={(e) => handleClick(e, k.id)}
                                                                                            />
                                                                                        </label>
                                                                                        <div>
                                                                                            <label>
                                                                                                <input
                                                                                                    ref={hiddenFileInput}
                                                                                                    type="file"
                                                                                                    id="uploadBtn"
                                                                                                    className="inputfile"
                                                                                                    onChange={(e) => addCommentAttachment(e, fileId)}
                                                                                                />
                                                                                            </label>
                                                                                        </div>
                                                                                        <Button color="error"
                                                                                            type="submit"
                                                                                            sx={{ fontWeight: "bold" }}
                                                                                            onClick={() => { setcommentCondition(prevcommentCondition => ({ ...prevcommentCondition, [cat]: !prevcommentCondition[cat] })) }}>
                                                                                            CANCEL
                                                                                        </Button>
                                                                                        <Button color="success"
                                                                                            variant="contained"
                                                                                            type="submit"
                                                                                            sx={{ fontWeight: "bold" }}
                                                                                            onClick={(e) => commentSaveClick(e, k.id, cat, k.content)}>
                                                                                            Save
                                                                                        </Button>
                                                                                    </CardContent> : ""}
                                                                                {k?.creator?.type === "Contractor" ?
                                                                                    <div>
                                                                                        {!commentCondition[cat] ?
                                                                                            <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-end", alignContent: "flex-end", justifyContent: "flex-end", marginTop: '1rem' }}>
                                                                                                <Button color="primary"
                                                                                                    type="submit"
                                                                                                    size="small"
                                                                                                    sx={{ fontWeight: "bold" }}
                                                                                                    onClick={() => {
                                                                                                        toggle(cat);
                                                                                                    }}>
                                                                                                    Edit
                                                                                                </Button>
                                                                                                <Button color="error"
                                                                                                    type="submit"
                                                                                                    size="small"
                                                                                                    sx={{ fontWeight: "bold" }}
                                                                                                    onClick={(e) => deleteClick(e, k.id)}>
                                                                                                    Delete
                                                                                                </Button>
                                                                                            </div> : null}
                                                                                    </div>
                                                                                    : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""}
                                                        </div>))}

                                                </div> : ""}
                                        </div> : ""}
                                </div>))}
                        </div>))}
                </div>
                <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box", width: "75%", marginLeft: "auto", marginRight: "auto" }}>
                    <div style={{ paddingBottom: "1rem", paddingBottom: "1.5rem", borderBottom: "1px solid #dee2e6", marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box" }}>
                            <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "flex-start" }}>
                                <div>
                                    <Avatar src="/broken-image.jpg" style={{ backgroundColor: "whitesmoke", color: "#FF805D", marginTop: "0.5rem", marginRight: "1rem" }} />
                                </div>
                                <div style={{ display: 'flex', boxSizing: "border-box", flexDirection: "column", flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                                    <span style={{ fontSize: "1em", marginTop: "10px" }}>
                                        <small style={{ fontSize: "1em" }}>
                                            <strong style={{ fontSize: "1em" }}>{assignee && assignee ? assignee.map(details => details.type === "Manager" ? (
                                                <small style={{ fontSize: "1em" }}>
                                                    <strong style={{ fontSize: "1em" }}> {details.name} </strong>
                                                </small>) : "") : ""}</strong>
                                        </small>
                                    </span>
                                    <div className="img-container">
                                        {attachImages()}
                                    </div>
                                    <TextField id="outlined-basic" variant="outlined"
                                        fullWidth={true}
                                        sx={{ pb: 5 }}
                                        rows={3}
                                        multiline
                                        placeholder="Write a comment"
                                        value={content}
                                        onChange={(e) => {
                                            setContent(e.target.value);
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", boxSizing: 'border-box', alignItems: "center", alignContent: "center", justifyContent: "flex-end" }}>
                            <div>
                                <input type="file" id="uploadButton" accept="image/*"
                                    onChange={handleImagesAdd}
                                    className="inputfile"
                                    multiple
                                />
                                <label htmlFor="uploadButton">
                                    <AttachFileIcon style={{ fontSize: 30, marginRight: "20px" }} /></label>
                            </div>
                            <div>
                                <Button variant="contained" color="success"
                                    type="submit"
                                    style={{ fontSize: 15 }}
                                    onClick={postClick}
                                >POST</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
            {/* Contractor Finance Dialogs */}

            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={contractorRecieptDialog}
                onClose={() => { setContractorRecieptDialog(false) }}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        No receipt uploaded, changes will be lost .
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                    <div>
                        <Button variant="contained" color='primary' onClick={() => { setContractorRecieptDialog(false) }}>Yes</Button>
                        <Button color='error' onClick={() => { setContractorRecieptDialog(false) }}>NO</Button>
                    </div>
                </CardContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={contractorFinanceDeleteFileDialog}
                onClose={contractorFinanceDeleteFileDialogClose}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Are you sure you want to delete this file?
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                    <div>
                        <Button variant="contained" color='primary' onClick={() => { removecontractorFinaceUpdateAtachment(contractorFinanceId, contractorFinanceAttachId) }}>Ok</Button>
                        <Button color='error' onClick={() => { contractorFinanceDeleteFileDialogClose() }}>CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={contractorFinanceDeleteDialog}
                onClose={contractorFinanceDeleteDialogClose}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Are you sure you want delete this finance details?
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                    <div>
                        <Button variant="contained" color='primary' onClick={() => { deleteContractFinance(contractorFinanceId) }}>Yes</Button>
                        <Button color='error' onClick={() => { contractorFinanceDeleteDialogClose() }}>CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default Contractor;