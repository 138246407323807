import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { getManagerLeases, getPropertyFinal, getNoticeAddress, getNoticeTemplates, managerNoticePreview, getUser } from "../../../services";
import {
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolTip from "../../../components/ToolTip";

function PrepareNotice() {
  const navigate = useNavigate();
  const params = useParams();
  const date = new Date();
  const { quill, quillRef } = useQuill();

  const [notice, setNotice] = useState([]);
  const [property, setProperty] = useState({});
  const [tenant, setTenant] = useState(null);
  const [salvation, setSalvation] = useState("");
  const [subject, setSubject] = useState("");
  const [loadTemplate, setLoadTemplate] = useState("");
  const [toAddress, setToAddress] = useState("")
  const [fromAddress, setFromAddress] = useState("");

  const pageLoad = async () => {
    let notice;
    let getProp;
    let lease;
    let addr;
    let manager;
    await getUser().then((res) => {
      manager = res?.data?.data;
    })

    await getNoticeTemplates().then((res) => {
      notice = res?.data?.data
      setNotice(notice);
    })

    await getManagerLeases(params.applicationId).then((res) => {
      getProp = res?.data?.data;
      setProperty(getProp);
    }).catch((err) => console.log(`Error${err}`));

    await getPropertyFinal(params.applicationId).then((res) => {
      lease = res?.data?.data;
      setTenant(lease);
    }).catch((err) => console.log(`Error${err}`));

    await getNoticeAddress(params.applicationId).then((res) => {
      addr = res?.data?.data;
    }).catch((err) => console.log(`Error${err}`));

    let from = "";
    if (addr?.fromLegalName) {
      from = addr?.fromLegalName + '\n' + addr?.fromAddress?.fromLegalName + '\n';
    } else if (addr?.fromAddress) {
      from = manager?.name?.first + " " + manager?.name?.last + '\n' + addr?.fromAddress?.line1 + '\n' + addr?.fromAddress?.line2 + '\n' + addr?.fromAddress?.city + " " + addr?.fromAddress?.state + " - " + addr?.fromAddress?.zipCode;
    }
    else if (addr?.fromLegalName && addr?.fromAddress) {
      from = addr?.fromLegalName + '\n' + addr?.fromAddress?.fromLegalName + '\n' + addr?.fromAddress?.line1 + '\n' + addr?.fromAddress?.line2 + '\n' + addr?.fromAddress?.city + " " + addr?.fromAddress?.state + " - " + addr?.fromAddress?.zipCode;
    } else {
      from = manager?.name?.first + " " + manager?.name?.last + '\n';
    }
    setFromAddress(from);
    if (addr?.toAddress) {
      let to = lease?.leaseTenants[0]?.name?.first + " " + lease?.leaseTenants[0]?.name?.last + '\n' + addr?.toAddress?.line1 + '\n' + addr?.toAddress?.line2 + " " + addr?.toAddress?.unitNumber + '\n' + addr?.toAddress?.city + " " + addr?.toAddress?.state + " - " + addr?.toAddress?.zipCode;
      setToAddress(to);
    }
  }
  useEffect(() => {
    pageLoad();
  }, []);

  const selectClick = (e, detail) => {
    if (detail) {
      setSubject(detail?.subject);
      setSalvation(detail?.salutation);
      quill.clipboard.dangerouslyPasteHTML(detail?.body);
    }
  }

  const previewNoticeClick = (id) => {
    let data = {
      "leaseId": id,
      "from": fromAddress,
      "to": toAddress,
      "subject": subject,
      "salutation": salvation,
      "body": quillRef.current.firstChild.innerHTML,
    }
    managerNoticePreview(data).then((res) => {
      let responseData = res.data.data;
      navigate(`/manager/notice/preview-and-send`, {
        state: {
          preview: responseData.html,
          property: property,
          tenant: tenant,
          notic: data,
        }
      });
    }).catch((err) => toast.error(err.response.data.error.message));
  }

  const TemplatesClick = (e) => {
    navigate(`/manager/notice-templates`);
  };

  const tenantFullName = (ten) => {
    let fullName = "";
    if (ten?.leaseTenants && ten.leaseTenants.length > 0) {
      let first = ten.leaseTenants[0]?.name?.first;
      let last = ten.leaseTenants[0]?.name?.last;
      if (first && last) {
        fullName = first + " " + last;
      }
    }
    return fullName;
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ textAlign: "center", textTransform: "uppercase", paddingBottom: '50px' }}>
        <p style={{ fontSize: 17 }}>
          <Link to="/manager/home"> PROPERTIES </Link> /{" "}
          <Link to={`/manager/properties/${property?.id}/edit`}> {property?.address?.line1}, {property?.address?.line2} {property?.address?.unitNumber} </Link> /{" "}
          <Link to={`/manager/properties/${property?.id}/lease`}> LEASE </Link> / PREPARE NOTICE
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: 'wrap', gap: 10 }}>
        <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap' }}>
          <span style={{ fontSize: 20, fontWeight: "bold", paddingBottom: '20px' }}>Tenant</span>
          <span style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 10, alignItems: 'center' }}>
            <span style={{ fontSize: 16, fontWeight: "bold" }}>{tenantFullName(tenant)}</span>
            <ToolTip phone={tenant?.leaseTenants[0]?.phone?.code + " " + tenant?.leaseTenants[0]?.phone?.number} email={tenant?.leaseTenants[0]?.email?.id} />
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap', textAlign: 'right' }}>
          <span style={{ fontSize: 20, fontWeight: "bold", paddingBottom: '20px' }}>Property</span>
          <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.line1}</span>
          <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.line2} {property.unitNumber}</span>
          <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.city}</span>
          <span style={{ fontSize: 16, fontWeight: "bold" }}>{property?.address?.state} - {property?.address?.zipCode}</span>
        </div>
      </div>
      <p className="preview-notice-heading">Prepare Notice</p>
      <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: 'wrap', gap: 10, paddingBottom: '20px' }}>
        <TextField
          label="Load Template"
          select
          margin="dense"
          variant="outlined"
          sx={{ width: 0.3 }}
          value={loadTemplate}
          onChange={e => { setLoadTemplate(e.target.value) }}>
          <MenuItem value={""}>None</MenuItem>
          {notice.map((details, y) => (
            <MenuItem key={y} value={details?.id} onClick={(e) => selectClick(e, details)}>
              {details.name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          sx={{ fontSize: 15 }}
          variant="contained"
          color="primary"
          onClick={(e) => TemplatesClick(e)}>
          MANAGE TEMPLATES
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'right', paddingBottom: '20px' }}>
        <TextField
          label="From"
          margin="dense"
          variant="outlined"
          sx={{ width: 0.5 }}
          multiline
          rows={5}
          value={fromAddress}
          onChange={e => { setFromAddress(e.target.value) }}
        />
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <TextField
          label="To"
          margin="dense"
          variant="outlined"
          sx={{ width: 0.5 }}
          multiline
          rows={5}
          value={toAddress}
          onChange={e => { setToAddress(e.target.value) }}
        />
      </div>
      <p style={{ fontWeight: "bold", paddingBottom: '20px' }}>Date: {date ? moment(date).format('Do MMMM, YYYY') : ""}</p>
      <div style={{ display: "flex", justifyContent: 'right', paddingBottom: '20px' }}>
        <TextField
          label="Subject"
          margin="dense"
          variant="outlined"
          sx={{ width: 0.5 }}
          placeholder="Enter Subject"
          value={subject}
          onChange={e => { setSubject(e.target.value) }}
        />
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <TextField
          label="Salutation"
          margin="dense"
          variant="outlined"
          sx={{ width: 0.5 }}
          placeholder="e.g Dear Tenant"
          value={salvation}
          onChange={e => { setSalvation(e.target.value) }}
        />
      </div>
      <div className="update-notice-templates-quill-editor">
        <div ref={quillRef} />
      </div>
      <div style={{ display: 'flex', justifyContent: "right", paddingTop: '100px' }}>
        <Button
          sx={{ fontSize: 15 }}
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => { previewNoticeClick(tenant?.id) }}>
          PREVIEW NOTICE
        </Button>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default PrepareNotice;