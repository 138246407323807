import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCurrentUser, getInviteDetails, getSingleProperty, startCoApplication, tenantRegisterOrLogin, createTenantLeaseApplication, getApiBaseURL } from "../../../services";
import {
    FaBath,
    FaBed,
    FaCar,
} from "react-icons/fa";
import { MdLocalLaundryService } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";

function InvitationFormPage() {

    let [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const dispatch = useDispatch();

    const [invitaion, setInvitaion] = useState();
    const [property, setProperty] = useState();
    const [propertyLoading, setPropertyLoading] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [hasCoApplicant, setHasCoApplicant] = useState(false);
    const [coFirstName, setCoFirstName] = useState("");
    const [coFirstNameError, setCoFirstNameError] = useState("");
    const [coLastName, setCoLastName] = useState("");
    const [coLastNameError, setCoLastNameError] = useState("");
    const [coPhone, setCoPhone] = useState("");
    const [coPhoneError, setCoPhoneError] = useState("");
    const [coEmail, setCoEmail] = useState("");
    const [coEmailError, setCoEmailError] = useState("");


    const phoneCountryCode = "+1";

    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setPhone(formattedPhoneNumber);
    };
    const handleInput1 = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setCoPhone(formattedPhoneNumber);
    };
    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    const onChangeHandler = (event) => {
        const phone = event.target.value
        setPhone(phone)
    };
    const onChangeHandler1 = (e) => {
        const coPhone = e.target.value
        setCoPhone(coPhone)
    };

    useEffect(() => {
        if (searchParams.get("property")) {
            getSingleProperty(searchParams.get("property")).then((res) => {
                setProperty(res.data.data)
                setPropertyLoading(false);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            getInviteDetails(
                searchParams.get("application"),
                searchParams.get("applicant"),
                searchParams.get("code"),
            )
                .then(response => {
                    console.log("ss", response)
                    let inv = response.data.data;
                    setInvitaion(inv);
                    setFirstName(inv.applicant.firstName);
                    setLastName(inv.applicant.lastName);
                    setPhone(inv.applicant.phoneNumber);
                    setEmail(inv.applicant.email);
                })
                .catch(err => { console.log(err) });
        }
    }, [searchParams]);

    useEffect(() => {
        if (invitaion) {
            getSingleProperty(invitaion.propertyId)
                .then(response => {
                    setProperty(response.data.data);
                    setPropertyLoading(false);
                })
                .catch(err => { console.log(err) });
        }
    }, [invitaion]);


    const handleApplyClick = (e) => {
        e.preventDefault();
        let error = false;
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (firstName === "") {
            setFirstNameError("First name cannot be empty");
            error = true;
        } else {
            setFirstNameError("");
        }
        if (lastName === "") {
            setLastNameError("Last name cannot be empty");
            error = true;
        } else {
            setLastNameError("");
        }
        if (phone === "") {
            setPhoneError("Phone number cannot be empty");
            error = true;
        } else {
            setPhoneError("");
        }
        if (email === "") {
            setEmailError("Email cannot be empty");
            error = true;
        }
        else {
            setEmailError("");
        }

        if (!email.match(re)) {
            setEmailError("Enter a valid email");
            error = true;
        } else {
            setEmailError("");
        }

        if (password === "") {
            setPasswordError("Password cannot be empty");
            error = true;
        } else {
            setPasswordError("");
        }
        if (hasCoApplicant) {
            if (coFirstName === "") {
                setCoFirstNameError("First name cannot be empty");
                error = true;
            } else {
                setCoFirstNameError("");
            }
            if (coLastName === "") {
                setCoLastNameError("Last name cannot be empty");
                error = true;
            } else {
                setCoLastNameError("");
            }
            if (coPhone === "") {
                setCoPhoneError("Phone number cannot be empty");
                error = true;
            } else {
                setCoPhoneError("");
            }
            if (coEmail === "") {
                setCoEmailError("Email cannot be empty");
                error = true;
            } else {
                setCoEmailError("");
            }
        }

        if (error) return;

        console.log("Starting tenant registration.");
        tenantRegisterOrLogin(
            firstName,
            lastName,
            phoneCountryCode,
            phone,
            email,
            password,
        ).then(response => {
                // 1. Store the auth token to local storage.
                sessionStorage.setItem("authToken", response.data.data.token);
                // 2. Fetch user details from backend and store it to state.
                console.log("Fetching current user");
                return getCurrentUser();
            }).then(response => {
                dispatch(setUser(response.data.data));
                if (searchParams.get("property")) {
                    let applicationInvitees = [];
                    if (hasCoApplicant) {
                        applicationInvitees.push({
                            firstName: coFirstName,
                            lastName: coLastName,
                            phoneCountryCode: phoneCountryCode,
                            phoneNumber: coPhone,
                            email: coEmail,
                        })
                    }
                    createTenantLeaseApplication(searchParams.get("property"), applicationInvitees)
                        .then((response) => {
                            // 4. Save ongoing application ID to state.
                            // dispatch(setOngoingApplication(response.data.data.id));
                            sessionStorage.setItem("ongoingApplication", response.data.data.id);

                            // 5. Redirect to tenant dashboard.
                            console.log("Redirecting to tenant dashboard");
                            navigate("/tenant/dashboard");
                        }).catch((err) => {
                            console.log(err);
                        });
                } else {
                    // 3. Start the application that is already created.
                    startCoApplication(searchParams.get("application"))
                        .then(response => {
                            // 4. Save ongoing application ID to state.
                            // dispatch(setOngoingApplication(response.data.data.id));
                            sessionStorage.setItem("ongoingApplication", response.data.data.id);
                            // 5. Redirect to tenant dashboard.
                            console.log("Redirecting to tenant dashboard");
                            navigate("/tenant/dashboard");
                        })
                }
            })
    }

    return (
        <div className="page property-view-page">
            <div className="page-container">
                {propertyLoading && "Loading"}
                {!propertyLoading &&
                    <>
                        <div className="property-section">
                            <div className="property-left">
                                <div className="property-images">
                                    <img
                                        src={property.images.defaultImageId ?
                                            `${getApiBaseURL()}/properties/${property.id}/images/default` :
                                            "/images/default-property-picture.jpeg"}
                                        alt="Set of buildings"
                                        className="property-image"
                                    />
                                </div>
                            </div>
                            <div className="property-right">
                                <h1 className="property-title">{property.address.line1} {property.address.unitNumber}</h1>
                                <p>{property.address.city}, {property.address.state}</p>
                                <p>{property.description}</p>
                                <div className="facilities">
                                    {property.numberOfBedrooms &&
                                        <div className="facilities-item">
                                            <FaBed />
                                            <span>{property.numberOfBedrooms} bedrooms</span>
                                        </div>
                                    }
                                    {property.numberOfBathrooms &&
                                        <div className="facilities-item">
                                            <FaBath />
                                            <span>{property.numberOfBathrooms} bathrooms</span>
                                        </div>
                                    }
                                    {property.numberOfParkingSpaces &&
                                        <div className="facilities-item">
                                            <FaCar />
                                            <span>{property.numberOfParkingSpaces} parking spaces</span>
                                        </div>
                                    }
                                    {property.laundryFacility === "IN_UNIT" &&
                                        <div className="facilities-item">
                                            <MdLocalLaundryService />
                                            <span>Laundry available</span>
                                        </div>
                                    }
                                </div>
                                <p className="rent-amount">{`$${property.rentAmount}/month`}</p>
                                {/* <button className="button orange-main-button apply-button">
                                Apply
                            </button> */}
                            </div>
                        </div>
                        <h1>Apply now</h1>
                        <form className="application-section">
                            <div className="split-section">
                                <div className="main-application">
                                    <div className="application-header">
                                        <h2>Applicant details</h2>
                                        {searchParams.get("property") && (
                                            <div>
                                                {!hasCoApplicant && (
                                                    <button
                                                        className="button orange-main-button"
                                                        onClick={(e) => {
                                                            setHasCoApplicant(true);
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        + Co-applicant
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-row">
                                        <div className="form-item">
                                            <span className="form-item-label">First name</span>
                                            <input
                                                className="form-item-input"
                                                type="text"
                                                value={firstName}
                                                onChange={e => { setFirstName(e.target.value) }}
                                            />
                                            {firstNameError &&
                                                <span className="form-item-error">
                                                    {firstNameError}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-item">
                                            <span className="form-item-label">Last name</span>
                                            <input
                                                className="form-item-input"
                                                type="text"
                                                value={lastName}
                                                onChange={e => { setLastName(e.target.value) }}
                                            />
                                            {lastNameError &&
                                                <span className="form-item-error">
                                                    {lastNameError}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-item">
                                            <span className="form-item-label">Phone</span>
                                            <input
                                                className="form-item-input"
                                                type="text"
                                                value={phone}
                                                onChange={(e) => { onChangeHandler(e); handleInput(e) }}
                                            />
                                            {phoneError &&
                                                <span className="form-item-error">
                                                    {phoneError}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-item">
                                            <span className="form-item-label">Email</span>
                                            <input
                                                className="form-item-input"
                                                type="text"
                                                value={email}
                                                onChange={e => { setEmail(e.target.value) }}
                                            />
                                            {emailError &&
                                                <span className="form-item-error">
                                                    {emailError}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {hasCoApplicant && (
                                    <div className="co-application">
                                        <div className="application-header">
                                            <h2>Co-applicant details</h2>
                                            <button
                                                className="button orange-main-button"
                                                onClick={(e) => {
                                                    setHasCoApplicant(false);
                                                    e.preventDefault();
                                                }}
                                            >
                                                - Co-applicant
                                            </button>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-item">
                                                <span className="form-item-label">First name</span>
                                                <input
                                                    className="form-item-input"
                                                    type="text"
                                                    value={coFirstName}
                                                    onChange={(e) => {
                                                        setCoFirstName(e.target.value);
                                                    }}
                                                />
                                                {coFirstNameError && (
                                                    <span className="form-item-error">
                                                        {coFirstNameError}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-item">
                                                <span className="form-item-label">Last name</span>
                                                <input
                                                    className="form-item-input"
                                                    type="text"
                                                    value={coLastName}
                                                    onChange={(e) => {
                                                        setCoLastName(e.target.value);
                                                    }}
                                                />
                                                {coLastNameError && (
                                                    <span className="form-item-error">
                                                        {coLastNameError}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-item">
                                                <span className="form-item-label">Phone</span>
                                                <input
                                                    className="form-item-input"
                                                    type="text"
                                                    value={coPhone}
                                                    onChange={(e) => { onChangeHandler1(e); handleInput1(e) }}
                                                />
                                                {coPhoneError && (
                                                    <span className="form-item-error">
                                                        {coPhoneError}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-item">
                                                <span className="form-item-label">Email</span>
                                                <input
                                                    className="form-item-input"
                                                    type="email"
                                                    value={coEmail}
                                                    onChange={(e) => {
                                                        setCoEmail(e.target.value);
                                                    }}
                                                />
                                                {coEmailError && (
                                                    <span className="form-item-error">
                                                        {coEmailError}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="form-row">
                                <div className="form-item">
                                    <span className="form-item-label">Password</span>
                                    <input
                                        className="form-item-input"
                                        type="password"
                                        value={password}
                                        onChange={e => { setPassword(e.target.value) }}
                                    />
                                    {passwordError &&
                                        <span className="form-item-error">
                                            {passwordError}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-item">
                                    <input
                                        className="button orange-main-button"
                                        type="submit"
                                        onClick={handleApplyClick}
                                    />
                                </div>
                            </div>
                        </form>
                    </>
                }
            </div>
        </div>
    );
}

export default InvitationFormPage;