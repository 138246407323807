import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, Modal, OutlinedInput, Paper, TextField, Tooltip, span } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import {
    getPropertyFinal, getManagerLeases, managerMoveOutInspection, postManagerMoveOutInspectionItems, deleteManagerMoveOutInspectionItems, postManagerMoveOutInspectionItemsFiles,
    patchManagerMoveOutInspectionItems, postManagerMoveOut, getManagerFinanceBalanceOverview, postManagerMoveOutSendTenantRestitutionEstimate, postManagerMoveOutSubmit, deleteManagerMoveOutImage, getApiBaseURL, updateManagerMoveOutInspectionItemServiceRequestInfo, postManagerServiceRequest
} from "../../../services";
import { useParams, Link, useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignaturePad from "react-signature-canvas";
import axios from 'axios'
import ToolTip from "../../../components/ToolTip";
import imageClick from "../../../components/imageView";
import '../../../Styles/tenant/tenantMoveOut.css'

function ManagerMoveOutLease() {
    const params = useParams();
    const navigate = useNavigate();
    const [timeGet, settimeGet] = useState('');
    const [ip, setIP] = useState('');
    const signatureRef = useRef(null);
    const hiddenFileInput = useRef();

    const [tenantsSignn, setTenantsSignn] = useState(null)
    const [property, setProperty] = useState(null);
    const [moveout, setMoveOut] = useState(null);

    const [commonMapview, setcommonMapview] = useState([]);

    const [mansignImg, setmansignImg] = useState("");

    const [area, setArea] = useState("")

    const [areas, setAreas] = useState([]);

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);

    const [addItemDialog, setaddItemDialog] = useState(false);
    const [editItemDialog, seteditItemDialog] = useState(false);
    const [editAreaDialog, setEditareaDialog] = useState(false);
    const [isSigned, setIsSigned] = useState(false);

    const [aarea, setAarea] = useState("")
    const [addAreaName, setAddAreaName] = useState("");
    const [addAreaDesc, setAddAreaDesc] = useState("");
    const [settlementAmount, setSettlementAmount] = useState("");

    const [managerRemark, setmanagerRemark] = useState("")
    const [financeBalanceOverview, setFinanceBalanceOverview] = useState({});

    // loading
    const [loading, setLoading] = useState(true);

    const [idd, setIdd] = useState("")
    const [fileId, setfileId] = useState(0);

    // Save Button
    const saveBtnClick = () => {
        let data = {
            "managerRemarks": managerRemark,
        }
        postManagerMoveOut(params.leaseId, data).then((res) => {
            pageLoad1();
        })
    }

    // Add Inspection Items
    const commonAddOpen = (e, name) => {
        setaddItemDialog(true);
        setAarea(name);
    }
    const commonAddClose = () => {
        setaddItemDialog(false)
        setAarea("");
        setAddAreaDesc("");
        setAddAreaName("");
        setSettlementAmount("");
        setAreas([]);
    }

    const commonAddSave = () => {
        let data = {
            "area": aarea,
            "settlementRemarks": addAreaDesc,
            "name": addAreaName,
            "settlementAmount": settlementAmount,
        }
        postManagerMoveOutInspectionItems(params.leaseId, data).then((res) => {
            commonAddClose();
            pageLoad1();
        })
    }

    // Edit Inspection Items
    const editOpen = (e, area, id, name, settlementRemark, settleAmount) => {
        setIdd(id);
        setAarea(area);
        setAddAreaDesc(settlementRemark);
        setAddAreaName(name);
        setSettlementAmount(settleAmount);
        seteditItemDialog(true);
    }
    const editClose = () => {
        seteditItemDialog(false)
        setIdd("");
        setAarea("");
        setAddAreaDesc("");
        setAddAreaName("");
        setSettlementAmount("");
    }
    const editSave = () => {
        let datas = {
            "area": aarea,
            "settlementRemarks": addAreaDesc,
            "name": addAreaName,
            "settlementAmount": settlementAmount,
        }
        patchManagerMoveOutInspectionItems(params.leaseId, idd, datas).then((res) => {
            editClose();
            pageLoad1();
        })
    }

    // Delete Move-in Inspection
    const [removeInspectionDialog, setRemoveInspectionDialog] = useState(false);
    const [inspectionId, setInspectionId] = useState("");
    const removeInspectionDialogOpen = (e, id) => {
        setRemoveInspectionDialog(true);
        setInspectionId(id);
    }
    const removeInspectionDialogClose = () => {
        setRemoveInspectionDialog(false);
        setInspectionId("");
    }
    const deleteiconClick = (e) => {
        deleteManagerMoveOutInspectionItems(params.leaseId, inspectionId).then((res) => {
            removeInspectionDialogClose();
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    // Add Settelement Attachment
    const handleClick = (e, id) => {
        e.preventDefault();
        setfileId(id);
        hiddenFileInput.current.click();
    };

    const fileUploadHandler = (e, id) => {
        var file = e.target.files[0] || undefined,
            supportedFormats = [
                "image/jpg",
                "image/gif",
                "image/png",
                "image/jpeg",
                "application/pdf",
            ];
        if (file && file.type) {
            if (0 > supportedFormats.indexOf(file.type)) {
            } else {
                let formData = new FormData();
                formData.append("file", e.target.files[0]);
                postManagerMoveOutInspectionItemsFiles(params.leaseId, id, formData)
                    .then((res) => {
                        pageLoad1();
                    })
            }
        }
    }

    // Remove Settlement Attachment
    const removeImage = (e, fileId, imgId) => {
        deleteManagerMoveOutImage(params.leaseId, fileId, imgId).then((res) => {
            pageLoad1();
        })
    }

    // Send for tenant review button
    const sendToTenantClick = () => {
        postManagerMoveOutSendTenantRestitutionEstimate(params.leaseId).then((res) => {
            pageLoad1();
        })
    }

    // Finalize button
    const submitClick = () => {
        let data = {
            "managerRemarks": managerRemark,
            "signature": signatureRef.current.toDataURL("image/png"),
        }
        postManagerMoveOutSubmit(params.leaseId, data).then((res) => {
            pageLoad1();
        })
    }

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    // Sign Clear
    const clearSig = (e) => {
        e.preventDefault();
        signatureRef.current.clear();
        setIsSigned(false)
    }

    // Add New Area
    const handleAddArea = () => {
        let arr = [...areas];
        arr.length = 0;
        arr.push({
            name: area
        })
        setAreas(arr);
        setArea("");
        setOpen2(false);
    }

    // Edit Area
    const commonEditAreaClick = (e, name, status) => {
        if (status === "editAreaDialog") {
            setEditareaDialog(true);
            setAarea(name);
        }
        if (status === "closeEditAreaDialog") {
            setEditareaDialog(false);
            setAarea("");
        }
        if (status === "editAreaSave") {
            console.log("edit")
        }
    }

    // Remove All Area
    const removeAddArea = (index) => {
        let toDelete;
        const newArea = areas.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });
        setAreas(newArea);
    }

    // Delete All Inspection Items
    const [removeAreaDialog, setRemoveAreaDialog] = useState(false);
    const [removeAllIds, setRemoveAllIds] = useState(null);
    const removeAreaDialogOpen = (e, name, ids) => {
        let items = commonMapview[name];
        // check if items exist that are added by Tenant
        let tenantItemExists = items.some(item => item.tenantRemarks && item.tenantFiles?.length);
        if (tenantItemExists) {
            toast.error('Cannot change the area entered by the Tenant.')
            return;
        }
        setRemoveAreaDialog(true);
        setRemoveAllIds(ids);
    }
    const removeAreaDialogClose = () => {
        setRemoveAreaDialog(false);
        setRemoveAllIds(null);
    }
    const commonRemoveAreaClick = async (e) => {
        try {
            for (let id of removeAllIds) {
                await deleteManagerMoveOutInspectionItems(params.leaseId, id).then((res) => { })
            }
            window.location.reload()
        } catch (err) {
            toast.error(err.response.data.error.message);
        }
    }

    var config = "";
    const pageLoad1 = async () => {
        setLoading(true);
        let det = [];
        det.length = 0;
        try {
            await getManagerLeases(params.leaseId).then((res) => {
                const getProp = res?.data?.data;
                setProperty(getProp);
            })
            await getPropertyFinal(params.leaseId).then((res) => {
                const leas = res?.data?.data;
                setTenantsSignn(leas);
            })
            await managerMoveOutInspection(params.leaseId).then((res) => {
                let responseData = res?.data?.data;
                setMoveOut(responseData);
                setmanagerRemark(responseData?.managerRemarks);
                if (responseData?.managerSignature != undefined) {
                    if (responseData?.managerSignature.signatureFile != undefined) {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },
                        };
                        fetch(
                            `${getApiBaseURL()}/manager/leases/${params.leaseId}/move-out/manager-signature`, config
                        ).then(res => {
                            return res.blob();
                        }).then(blob => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement('a');
                                link.href = imageDataUrl;
                                if (blob.type == "image/png"
                                    || blob.type == "image/gif"
                                    || blob.type == "image/jpeg"
                                    || blob.type == "image/svg"
                                    || blob.type == "image/jpg"
                                ) {
                                    setmansignImg(imageDataUrl)
                                }
                            }
                        })
                    }
                }
                if (responseData?.inspectionItems.length != 0) {
                    responseData?.inspectionItems.map((e, i) => {
                        det.push({
                            "area": e.area,
                            "id": e.id,
                            "name": e.name,
                            "remindOnMoveOut": e.remindOnMoveOut,
                            "settlementAmount": e.settlementAmount,
                            "settlementRemarks": e.settlementRemarks,
                            "managerFiles": e.managerFiles,
                            "settlementFiles": e.settlementFiles,
                            "tenantFiles": e.tenantFiles,
                            "serviceRequestInfo": e.serviceRequestInfo,
                            "status": responseData?.status
                        });
                    })
                    det.sort((a, b) => a.area.localeCompare(b.area));
                    let groupedPeople = groupBy(det, "area");
                    setcommonMapview(groupedPeople);
                }
            })
            await getManagerFinanceBalanceOverview(params.leaseId).then((res) => {
                let responseData = res?.data?.data;
                setFinanceBalanceOverview(responseData);
            })
        } catch (err) {
            setLoading(false);
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        var today = new Date();
        var str = today.toGMTString();
        settimeGet(str)
        //passing getData method to the lifecycle method
        getData();
        pageLoad1();
    }, []);

    const createServiceRequest = async (inspectionItem) => {
        if (!inspectionItem?.settlementRemarks) {
            toast.error("Add Issue Description");
            return;
        }
        let moveInInfo = {
            isCreated: true,
            leaseId: params.leaseId,
            type: 'MoveOut',
            inspectionItemId: inspectionItem?.id,
        }
        let formData = new FormData();
        formData.append("propertyId", property?.id);
        formData.append("title", inspectionItem?.name);
        formData.append("description", inspectionItem?.settlementRemarks);
        formData.append("priority", 2);
        formData.append("privateToManagers", true);
        formData.append("moveInMoveOutInfo", JSON.stringify(moveInInfo));
        await postManagerServiceRequest(formData, `?groupStatus=${"serviceRequest"}`).then((res) => {
            let data = res?.data?.data;
            let serviceRequestInfo = {
                requested: true,
                serviceRequestId: data?.id,
            }
            return updateManagerMoveOutInspectionItemServiceRequestInfo(params.leaseId, inspectionItem?.id, serviceRequestInfo);
        }).then((res) => {
            toast.success("Service Request Created For The Issue");
            pageLoad1();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    const navigateToServiceRequest = (servId) => {
        navigate(`/manager/service-requests/${servId}`);
    }

    const handleSettlementAmmountChange = (e, item) => {
        const newData = { ...commonMapview };
        const itemData = newData[item.area].find(existingItem => existingItem.id === item.id);
        itemData.settlementAmount = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
        setcommonMapview(newData);
    };

    const onItemEstimateCostBlur = (event, item) => {
        let update = {
            name: item.name,
            area: item.area,
            settlementRemarks: item.settlementRemarks || "None",
            settlementAmount: item.settlementAmount,
        }
        patchManagerMoveOutInspectionItems(params.leaseId, item?.id, update).then((res) => {

        })
    }

    const updateEstimatedCosts = (area) => {
        let totalCost = {
            totalEstimatedCost: 0,
            toBeSentToTenantAmount: 0,
            amountTenantOwe: 0
        }
        Object.keys(area).forEach((cat, index) => {
            area[cat].forEach((item, i) => {
                if (item?.settlementAmount) totalCost.totalEstimatedCost += item?.settlementAmount;
            });
        });
        totalCost.toBeSentToTenantAmount = Math.max(0, (financeBalanceOverview?.securityDepositAmount - totalCost.totalEstimatedCost));
        totalCost.amountTenantOwe = Math.abs(Math.min(0, (financeBalanceOverview?.securityDepositAmount - totalCost.totalEstimatedCost)));
        return totalCost;
    };

    const managerHasSubmitted = (status) => {
        let stat = true;
        let states = [4, 5];
        if (states.includes(status)) return stat = true;
        return stat = false;
    }

    const managerCanEditAreaName = (areaName) => {
        let managerItemExists
        let items = commonMapview[areaName];
        // check if items exist that are added by Tenant
        if (items != undefined)
            managerItemExists = items.some(item => item.tenantRemarks || item.tenantFiles?.length);
        return !managerItemExists
    }

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
                    <p style={{ fontSize: 17 }}>
                        <Link to=""> PROPERTIES </Link> / <Link to=""> {property?.address?.line1} {property?.address?.line2} {property?.address?.unitNumber} </Link> / <Link to=""> LEASE </Link> / MOVE IN CHECKLIST
                    </p>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
                    <p style={{ fontSize: 25, fontWeight: "bold" }}>Lease Move Out Checklist</p>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <p style={{ fontSize: 21, fontWeight: "bold" }}>Tenant</p>
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{tenantsSignn?.leaseTenants[0]?.name?.first + " " + tenantsSignn?.leaseTenants[0]?.name?.last}</p>
                                <ToolTip
                                    phone={tenantsSignn?.leaseTenants[0]?.email?.id}
                                    email={tenantsSignn?.leaseTenants[0]?.phone?.code + " " + tenantsSignn?.leaseTenants[0]?.phone?.number}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6} textAlign={"right"}>
                            <p style={{ fontSize: 21, fontWeight: "bold" }}>Property</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.line1} {property?.address?.line2}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.unitNumber}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.city}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.state} - {property?.address?.zipCode}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div>
                <p style={{ fontSize: 21, fontWeight: "bold" }}>Move Out Checklist</p>
            </div>
            <div>
                {Object.keys(commonMapview).map((cat, index) => (
                    <Accordion sx={{ mt: 2 }} key={index} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{ flexGrow: 1 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10 }}>
                                    <div>
                                        <p style={{ fontSize: 18, fontWeight: "bold" }}>{cat}</p>
                                    </div>
                                    {!managerHasSubmitted(moveout?.status) ?
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30 }}>
                                            {managerCanEditAreaName(cat) ?
                                                <EditIcon color="primary"
                                                // onClick={(e) => editareaClick(e, cat, commonMapview[cat])} 
                                                />
                                                : ""}
                                            {managerCanEditAreaName(cat) ?
                                                <DeleteIcon color="error"
                                                    onClick={(e) => removeAreaDialogOpen(e, cat, commonMapview[cat].map(element => element.id))}
                                                />
                                                : ""}
                                        </div>
                                        : ""}
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {commonMapview[cat].map((item, i) => (
                                <div style={{ display: 'flex', flexDirection: 'column', borderTop: "1px solid rgba(0,0,0,.12)", paddingTop: "10px", gap: '10px' }} key={i}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                                        <h4 style={{ margin: '0px' }}>Issue : &nbsp;  </h4>
                                        <h4 style={{ margin: '0px' }} className="manager-move-out-primary">{item?.name}</h4>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', flexWrap: 'wrap', gap: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                            <h5 style={{ color: 'green' }}>Tenant Remark During Move In</h5>
                                            <span>
                                                {item?.tenantRemarks || "None"}
                                            </span>
                                            {item?.tenantFiles.length != 0 ? (
                                                <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                    {item?.tenantFiles.map((j, k) => (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                            <span className="tenant-move-in-attachment-hover-text" onClick={(e) => imageClick(e, `leases/${params.leaseId}/move-in/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                {j?.fileName}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                            <h5 className="warnig-move-in-desc">Manager Remark During Move In</h5>
                                            <span>
                                                {item.managerRemarks || "None"}
                                            </span>
                                             {item?.managerFiles.length != 0 ? (
                                                <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                    {item?.managerFiles.map((j, k) => (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                            <span className="tenant-move-in-attachment-hover-text" onClick={(e) => imageClick(e, `leases/${params.leaseId}/move-in/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                {j?.fileName}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                            <h5 style={{ color: 'red' }}>Condition on Move Out</h5>
                                            <span>
                                                {item.settlementRemarks || 'None'}
                                            </span>
                                            {item?.settlementFiles.length != 0 ? (
                                                <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                    {item?.settlementFiles.map((j, k) => (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                            <span className="tenant-move-in-attachment-hover-text" onClick={(e) => imageClick(e, `leases/${params.leaseId}/move-out/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                {j?.fileName}
                                                            </span>
                                                            {!managerHasSubmitted(moveout?.status) ?
                                                                <div title="Delete">
                                                                    <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={(e) => { removeImage(e, item?.id, j?.id) }} />
                                                                </div>
                                                                : ""}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px', width: '50%' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
                                                        {!managerHasSubmitted(moveout?.status) ?
                                                            <EditIcon color="primary"
                                                            sx={{cursor:'pointer'}}
                                                                onClick={(e) =>
                                                                    editOpen(
                                                                        e,
                                                                        cat,
                                                                        item?.id,
                                                                        item?.name,
                                                                        item?.settlementRemarks,
                                                                        item?.settlementAmount,
                                                                    )} />
                                                            : ""}
                                                        {!managerHasSubmitted(moveout?.status) ?
                                                            <div>
                                                                <label htmlFor="uploadBtn">
                                                                    <AttachFileIcon
                                                                        style={{
                                                                            fontSize: "25px",
                                                                            color: "#2196F3",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={(e) => handleClick(e, item?.id)}
                                                                    />
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        ref={hiddenFileInput}
                                                                        type="file"
                                                                        id="uploadBtn"
                                                                        className="inputfile"
                                                                        onChange={(e) => fileUploadHandler(e, fileId)}
                                                                    />
                                                                </label>
                                                            </div>
                                                            : ""}
                                                        {!managerHasSubmitted(moveout?.status) && !item?.serviceRequestInfo?.requested ?
                                                            <Tooltip title="Create Service Request">
                                                                <BuildIcon color="primary" sx={{ cursor: 'pointer' }} onClick={() => { createServiceRequest(item) }} />
                                                            </Tooltip>
                                                            : ""}
                                                        {item?.serviceRequestInfo?.requested ?
                                                            <BuildIcon color="success" sx={{ cursor: 'pointer' }} onClick={() => { navigateToServiceRequest(item?.serviceRequestInfo?.serviceRequestId) }} />
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                            <div>
                                                <p style={{ marginBottom: "12px", fontWeight: "bold", fontSize: 13 }}>Estimated Cost</p>
                                            </div>
                                            <div>
                                                <FormControl>
                                                    <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        disabled={managerHasSubmitted(moveout?.status)}
                                                        margin="dense"
                                                        variant="outlined"
                                                        onBlur={(e) => { onItemEstimateCostBlur(e, item) }}
                                                        onChange={(e) => { handleSettlementAmmountChange(e, item) }}
                                                        value={item?.settlementAmount}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    {!managerHasSubmitted(moveout?.status) ?
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '40%', justifyContent: 'flex-end' }}>
                                            {!item.tenantRemarks && !item.tenantFiles.length && !item.managerRemarks && !item.managerFiles.length ?
                                                <DeleteIcon color="error" onClick={(e) => removeInspectionDialogOpen(e, item?.id)} />
                                                : ""}
                                        </div>
                                        : ""}
                                </div>
                            ))}
                        </AccordionDetails>
                        {!commonMapview[cat].length ?
                            <div style={{ textAlign: "center" }}>
                                There are no issues here
                            </div>
                            : ""}
                        {!managerHasSubmitted(moveout?.status) ?
                            <div style={{ borderTop: "1px solid rgba(0,0,0,.12)" }}>
                                <Button
                                    color="primary"
                                    onClick={(e) => commonAddOpen(e, cat)}
                                    sx={{ fontSize: 15, marginLeft: "20px", marginTop: "10px", marginBottom: "10px" }}
                                >
                                    Add Issue
                                </Button>
                            </div>
                            : ""}
                    </Accordion>
                ))}
            </div>
            <div>
                {areas.map((f, i) => (
                    <Accordion sx={{ mt: 2 }} key={i} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{ flexGrow: 1 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10 }}>
                                    <div>
                                        <p style={{ fontSize: 18, fontWeight: "bold" }}>{f.name}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30 }}>
                                        <EditIcon color="primary"
                                            onClick={(e) => commonEditAreaClick(i)}
                                        />
                                        <DeleteIcon color="error"
                                            onClick={(e) => removeAddArea(i)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <span>
                                <div style={{ textAlign: "center" }}> There are no issues here </div>
                            </span>
                        </AccordionDetails>
                        <div style={{ borderTop: "1px solid rgba(0,0,0,.12)" }}>
                            <Button
                                color="primary"
                                onClick={(e) => commonAddOpen(e, f?.name)}
                                sx={{ fontSize: 15, marginLeft: "20px", marginTop: "10px", marginBottom: "10px" }}
                            >
                                Add Issue
                            </Button>
                        </div>
                    </Accordion>
                ))}
            </div>
            <div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                {!managerHasSubmitted(moveout?.status) ?
                    <Button
                        sx={{ fontSize: 15 }}
                        variant="contained"
                        color="success"
                        onClick={handleOpen2}
                    >ADD AREA</Button>
                    : ""}
            </div>

            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 20, marginTop: 20, marginBottom: 20, alignItems: 'flex-end' }}>
                {moveout ?
                    <div style={{ width: '50%' }}>
                        <p style={{ fontSize: 18, fontWeight: "bold" }}>Manager Remarks</p>
                        <TextField
                            id="standard-basic"
                            label="Mention for future records"
                            variant="standard"
                            sx={{ width: 1 }}
                            value={managerRemark}
                            onChange={e => { setmanagerRemark(e.target.value) }}
                            disabled={managerHasSubmitted(moveout?.status)}
                        />
                    </div>
                    : ""}
                {!managerHasSubmitted(moveout?.status) ?
                    <Button
                        sx={{ fontSize: 15 }}
                        variant="contained"
                        type="submit"
                        color="success"
                        onClick={saveBtnClick}
                    >SAVE</Button>
                    : ""}
            </div>

            {moveout ?
                <div style={{ display: "flex", flexDirection: "column", gap: '20px', marginTop: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: 'wrap', alignItem: 'center', gap: '20px' }}>
                        <h4>Total Restoration Cost</h4>
                        <FormControl>
                            <OutlinedInput
                                type="number"
                                value={updateEstimatedCosts(commonMapview)?.totalEstimatedCost}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                disabled={true}
                            />
                        </FormControl>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: 'wrap', alignItem: 'center', gap: '20px' }}>
                        <h4>Total Security Deposit</h4>
                        <FormControl>
                            <OutlinedInput
                                type="number"
                                value={financeBalanceOverview?.securityDepositAmount}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                disabled={true}
                            />
                        </FormControl>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: 'wrap', alignItem: 'center', gap: '20px' }}>
                        <h4>{updateEstimatedCosts(commonMapview)?.toBeSentToTenantAmount > 0 ? "Amount to be Refunded " : "Amount Tenant Owes"}</h4>
                        <FormControl>
                            <OutlinedInput
                                type="number"
                                value={updateEstimatedCosts(commonMapview)?.amountTenantOwe > 0 ? updateEstimatedCosts(commonMapview)?.amountTenantOwe : updateEstimatedCosts(commonMapview)?.toBeSentToTenantAmount}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                disabled={true}
                            />
                        </FormControl>
                    </div>
                </div>
                : ""}

            {!managerHasSubmitted(moveout?.status) ?
                <div style={{ marginTop: "50px", display: 'flex', justifyContent: 'right' }}>
                    <Button
                        sx={{ fontSize: 15 }}
                        color="primary"
                        variant="contained"
                        type="submit"
                        // disabled={}
                        onClick={sendToTenantClick}
                    >SEND FOR TENANT REVIEW</Button>
                </div>
                : ""}

            {moveout ?
                <div style={{ marginTop: "20px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {!moveout?.managerSignature ?
                            <div>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{property?.propertyManagers[0]?.manager?.user?.name?.first + " " + property?.propertyManagers[0]?.manager?.user?.name?.last}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Manager</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {ip}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{timeGet}</p>
                            </div>
                            : ""}
                        {moveout?.managerSignature ?
                            <div>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{moveout?.managerSignature?.name}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Manager</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {moveout?.managerSignature?.ipAddress}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{moveout?.managerSignature?.time}</p>
                            </div>
                            : ""}
                        {!moveout?.managerSignature ?
                            <div style={{ cursor: "pointer" }} className="manager-move-out-signbox">
                                <SignaturePad penColor='#4285f4'
                                    onEnd={() => { setIsSigned(true) }}
                                    canvasProps={{ width: 300, height: 200, className: 'sig-canvas curSig' }}
                                    ref={signatureRef} />
                                <Button sx={{ float: 'right' }} onClick={(e) => clearSig(e)}>Clear</Button>
                            </div>
                            : ""}
                        {moveout?.managerSignature ?
                            <div className="manager-move-out-signbox">
                                {mansignImg !== "" ?
                                    <img src={mansignImg} style={{ height: "200px", width: "300px" }}></img>
                                    : ""}
                            </div>
                            : ""}
                    </div>
                </div>
                : ""}

            <div style={{ marginTop: "50px" }}>
                {!managerHasSubmitted(moveout?.status) ?
                    <Button
                        sx={{ fontSize: 15 }}
                        variant="contained"
                        type="submit"
                        disabled={isSigned ? false : true}
                        onClick={submitClick}
                    >FINALIZE</Button>
                    : ""}
            </div>

            <Dialog
                maxWidth={"xs"}
                open={addItemDialog}
                onClose={(e) => commonAddClose()}
            >
                <DialogTitle>
                    Add Inspection Item
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={aarea}
                        disabled={true}
                        onChange={e => { setAarea(e.target.value) }}
                    />
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={addAreaName}
                        onChange={e => { setAddAreaName(e.target.value) }}
                    />
                    <TextField
                        label="Description"
                        id="standard-basic"
                        variant="standard"
                        fullWidth={true}
                        type="text"
                        sx={{ mb: 5 }}
                        multiline
                        value={addAreaDesc}
                        onChange={e => { setAddAreaDesc(e.target.value) }}
                    />
                    <TextField
                        label="Estimated Cost"
                        id="standard-basic"
                        variant="standard"
                        fullWidth={true}
                        type="text"
                        sx={{ mb: 5 }}
                        multiline
                        value={settlementAmount}
                        onChange={e => { setSettlementAmount(e.target.value) }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, flexWrap: 'wrap' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            onClick={(e) => commonAddClose()}
                        >CANCEL</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            onClick={(e) => commonAddSave()}
                        >ADD</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth={"xs"}
                open={editItemDialog}
                onClose={(e) => editClose()}>
                <DialogTitle>
                    Edit Inspection Item
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={aarea}
                        disabled={true}
                        onChange={e => { setAarea(e.target.value) }}
                    />
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Name"
                        type="text"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        value={addAreaName}
                        onChange={e => { setAddAreaName(e.target.value) }}
                    />

                    <TextField
                        label="Description"
                        id="standard-basic"
                        variant="standard"
                        fullWidth={true}
                        type="text"
                        sx={{ mb: 5 }}
                        multiline
                        value={addAreaDesc}
                        onChange={e => { setAddAreaDesc(e.target.value) }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, flexWrap: 'wrap' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            onClick={(e) => editClose()}
                        >CANCEL</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            onClick={(e) => editSave()}
                        >ADD</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={open2}
                onClose={() => setOpen2(false)}>
                <DialogTitle>
                    Add Area
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        sx={{ mb: 5 }}
                        type="text"
                        value={area}
                        onChange={e => { setArea(e.target.value) }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            variant="contained"
                            onClick={handleAddArea}
                        >ADD AREA</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={editAreaDialog}
                onClose={(e) => commonEditAreaClick(e, "", "closeEditAreaDialog")}>
                <DialogTitle>
                    Edit Area
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Area Name"
                        sx={{ mb: 5 }}
                        type="text"
                        value={aarea}
                        onChange={e => { setAarea(e.target.value) }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="warning"
                            variant="contained"
                            onClick={(e) => commonEditAreaClick(e, "", "editAreaSave")}
                        >SAVE AREA</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeInspectionDialog}
                onClose={(e) => removeInspectionDialogClose()}>
                <DialogTitle>
                    Are you sure you want to remove this item?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 10 }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={(e) => deleteiconClick(e)}
                        >YES</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="info"
                            onClick={removeInspectionDialogClose}
                        >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeAreaDialog}
                onClose={(e) => removeAreaDialogClose()}>
                <DialogTitle>
                    Are you sure you want to remove this area and all its items?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 10 }}>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={(e) => commonRemoveAreaClick(e)}
                        >YES</Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            type="submit"
                            color="info"
                            onClick={removeAreaDialogClose}
                        >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div >
    );
}

export default ManagerMoveOutLease;