import { useState, useEffect, useRef } from "react";
import { tenantLeaseAgreementView, tenantLeaseAgreement, tenantLeaseAgreementsign, tenantLeaseAgreementreject, getApiBaseURL } from "../services";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, CardContent, CircularProgress } from "@mui/material";
import ReactSignatureCanvas from "react-signature-canvas";
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";

function SignLeaseAgreement() {
    const params = useParams();
    const navigate = useNavigate();
    const [view, setView] = useState(null);
    const [attachment, setattachment] = useState([]);
    const signatureRef = useRef(null);
    const [name, setName] = useState([]);
    const [timeGet, settimeGet] = useState('');
    const [ip, setIP] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        setIP(res.data.IPv4);
    }

    const clearSignature = () => {
        signatureRef.current.clear();
        setIsSigned(false)
    }

    const pageLoad = async () => {
        try {
            setLoading(true);
            var fileatt = [...attachment]
            fileatt.length = 0;
            await tenantLeaseAgreementView(params.applicationId).then((res) => {
                const response = res.data.data;
                setView(response?.html)
                return tenantLeaseAgreement(params.applicationId);
            }).then((res) => {
                const response1 = res.data.data;
                setName(response1?.tenantSignees[0]?.fullName)
                response1.attachments.map((e, i) => {
                    fileatt.push({
                        "fileName": e.fileName,
                        "id": e.id
                    })
                    setattachment(fileatt);
                })
                setLoading(false);
            })
        } catch (err) {
            setLoading(false);
            console.log("Error:", err);
        }
    }

    useEffect(() => {
        var today = new Date();
        var str = today.toGMTString();
        settimeGet(str)
        pageLoad();
        getData();
    }, []);

    const downloadFile = (id) => {
        var config = "";
        const authToken = sessionStorage.getItem("authToken");
        if (authToken != undefined) {
            config = {
                headers: { Authorization: `Bearer ${authToken}` },
            };
        }
        fetch(`${getApiBaseURL()}/tenant/lease-applications/${params.applicationId}/lease-agreement/attachments/${id}`,
            config
        ).then((res) => {
            return res.blob();
        })
            .then((blob) => {
                var reader = new window.FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function () {
                    var imageDataUrl = reader.result;
                    const link = document.createElement("a");
                    link.href = imageDataUrl;
                    if (blob.type == "application/pdf") {
                        link.setAttribute("Download", "Attachment.pdf");
                        document.body.appendChild(link);
                        link.click();
                    }
                };
            })
    }

    const btnsendClick = () => {
        let data = {
            "ipAddress": "ip",
            "signature": signatureRef.current.toDataURL("image/png"),
        }
        tenantLeaseAgreementsign(params.applicationId, data).then((res) => {
            navigate(`/tenant/lease-applications/${params.applicationId}/status`)
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const btnrejectClick = () => {
        tenantLeaseAgreementreject(params.applicationId).then((res) => {
            navigate(`/tenant/lease-applications/${params.applicationId}/status`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div>
            <Box sx={{ m: 4 }}>
                <div dangerouslySetInnerHTML={{ __html: view }} />
            </Box>
            {attachment.map((r, i) => (
                <div key={i}>
                    <table>
                        <tbody>
                            <tr>
                                <td><Button sx={{ fontSize: 15 }} onClick={() => downloadFile(r.id)}>{i + 1}. {r.fileName}</Button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ))}
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div style={{ cursor: "pointer" }} className="signbox">
                                    <ReactSignatureCanvas
                                        ref={signatureRef}
                                        penColor='#4285f4'
                                        onEnd={() => { setIsSigned(true) }}
                                        canvasProps={{ width: 300, height: 200, className: "signature-canvas" }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Button onClick={clearSignature} style={{ float: 'right' }}>Clear</Button>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingLeft: "2.5rem" }}>
                                <h2>{name}</h2>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingLeft: "2.5rem" }}>
                                <h4 style={{ margin: 2 }}>Tenant</h4>
                                <h4 style={{ margin: 2 }}> IP Address: {ip}</h4>
                                <h4 style={{ margin: 2 }}>{timeGet}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: 10 }}>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ fontSize: 15 }}
                        onClick={(e) => btnrejectClick(e)}
                    >Reject</Button>
                    <Button
                        variant="contained"
                        color="warning"
                        sx={{ fontSize: 15 }}
                        onClick={(e) => btnsendClick(e)}
                        disabled={isSigned ? false : true}
                    >SIGN AND SEND TO MANAGER</Button>
                </CardContent>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default SignLeaseAgreement;