import { useState, useEffect, useRef } from "react";
import { getRenewalPreview, getSingleProperty, getPropertyFinal, postScheduleandSendNotice, getManagerRenewalLease, cancelrenewal, getApiBaseURL } from "../../../services";
import { useParams, useNavigate } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import { Box, Button, CircularProgress } from "@mui/material";
import axios from 'axios'
import { CardContent } from "semantic-ui-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LeaseRenewalPreviewNotice() {
    const params = useParams();
    const navigate = useNavigate();

    const [view, setView] = useState(null)
    const signatureRef = useRef(null);
    const [isSigned, setIsSigned] = useState(false);
    const [timeGet, settimeGet] = useState('');
    const [ip, setIP] = useState('');
    const [manager, setManager] = useState([]);
    const [id, setId] = useState();
    const [status, setStatus] = useState();
    const [mansignImg, setmansignImg] = useState();
    const [manSigns, setmanSigns] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    const clearSignature = () => {
        signatureRef.current.clear();
        setIsSigned(false)
    }

    useEffect(() => {
        var today = new Date();
        var str = today.toGMTString();
        settimeGet(str)
        //passing getData method to the lifecycle method
        getData();
        pageLoad();
    }, []);

    var config = "";
    const pageLoad = () => {
        setLoading(true);
        getRenewalPreview(params.applicationId).then((res) => {
            let responseData = res.data.data;
            setView(responseData.html)
        }).catch((err) => console.log(`Error${err}`));
        getPropertyFinal(params.applicationId).then((res) => {
            let responseData = res.data.data;
            setId(responseData.property);
            getSingleProperty(responseData.property).then((res) => {
                let man = [...manager]
                man.length = 0;
                let data = res.data.data;
                man.push({
                    "name": data.propertyManagers[0].manager.user.name.first + " " + data.propertyManagers[0].manager.user.name.last,
                    "manager": "Manager",
                })
                setManager(man);
            }).catch((err) => console.log(`Error${err}`));
        }).catch((err) => console.log(`Error${err}`));
        getManagerRenewalLease(params.applicationId).then((res) => {
            let data = res.data.data;
            setStatus(data.status);
            if (data.managerSignature != undefined) {
                let mansig = [...manSigns];
                mansig.length = 0;
                mansig.push({
                    ip: data.managerSignature.ipAddress,
                    time: data.managerSignature.time,
                });
                setmanSigns(mansig);
                if (
                    data.managerSignature.signatureFile != undefined
                ) {
                    const authToken = sessionStorage.getItem("authToken");
                    config = {
                        headers: { Authorization: `Bearer ${authToken}` },
                    };
                    fetch(
                        `${getApiBaseURL()}/manager/leases/${params.applicationId}/renewal/manager-signature`,
                        config
                    )
                        .then((res) => {
                            return res.blob();
                        })
                        .then((blob) => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement("a");
                                link.href = imageDataUrl;
                                if (
                                    blob.type == "image/png" ||
                                    blob.type == "image/gif" ||
                                    blob.type == "image/jpeg" ||
                                    blob.type == "image/svg" ||
                                    blob.type == "image/jpg"
                                ) {
                                    setmansignImg(imageDataUrl);
                                }
                            };
                        }).catch((err) => console.log(`Error${err}`));
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const btnsendClick = () => {
        let data = {
            "signature": signatureRef.current.toDataURL("image/png"),
        }
        postScheduleandSendNotice(params.applicationId, data).then((res) => {
            if (res) {
                toast.success("Lease Renewal is Scheduled Successfully");
                setTimeout(() => {
                    navigate(`/manager/properties/${id}/lease`)
                }, 2000);
            }
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const btnreviseClick = () => {
        navigate(`/lease-applications/${params.applicationId}/RenewLease`)
    }

    const CancelClick = () => {
        cancelrenewal(params.applicationId).then((res) => {
            if (res) {
                toast.success("Lease Renewal is Cancel");
                pageLoad();
            }
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: '30px' }}>
            <div>
                <div dangerouslySetInnerHTML={{ __html: view }} />
            </div>
            <table>
                <tbody>
                    <tr>
                        <td>
                            {status !== 3 ?
                                <div style={{ cursor: "pointer" }} className="signbox">
                                    <ReactSignatureCanvas
                                        ref={signatureRef}
                                        penColor='#4285f4'
                                        onEnd={() => { setIsSigned(true) }}
                                        canvasProps={{ width: 300, height: 200, className: "signature-canvas" }}
                                    />
                                </div> :
                                <div className="signbox">
                                    <img src={mansignImg} />
                                </div>}
                        </td>
                    </tr>
                    {status !== 3 ?
                        <tr>
                            <td>
                                <Button onClick={clearSignature} style={{ float: 'right' }}>Clear</Button>
                            </td>
                        </tr> : ""}
                    {manager.map((e, i) => (
                        <tr key={i}>
                            <td>
                                <p style={{ fontSize: 18, fontWeight: "bold", marginBottom: "0px", marginLeft: "35px" }}>{e.name}</p>
                                <p style={{ fontSize: 16, fontWeight: "bold", marginLeft: "35px" }}>{e.manager}</p>
                            </td>
                        </tr>
                    ))}
                    {manSigns.map((e, i) => (
                        <tr key={i}>
                            {status === 3 ?
                                <td>
                                    <p style={{ fontSize: 14, fontWeight: "bold", marginLeft: "35px" }}>IP Address: {e.ip}</p>
                                </td> :
                                ""}
                        </tr>))}
                    {status !== 3 ?
                    <tr>
                        <td>
                            <p style={{ fontSize: 14, fontWeight: "bold", marginLeft: "35px" }}>IP Address: {ip}</p>
                        </td>
                        </tr> : ""}
                    {manSigns.map((f, g) => (
                        <tr key={g}>
                            {status === 3 ?
                                <td>
                                    <p style={{ fontSize: 14, fontWeight: "bold", marginLeft: "35px" }}>{f.time}</p>
                                </td> :
                                ""}
                        </tr>))}
                    {status !== 3 ?
                        <tr>
                            <td>
                                <p style={{ fontSize: 14, fontWeight: "bold", marginLeft: "35px" }}>{timeGet}</p>
                            </td>
                        </tr> : ""}
                </tbody>
            </table>
            {status === 3 ?
                <CardContent style={{ display:'flex',justifyContent:'right' }}>
                    <Button variant="contained"
                        color="error"
                        onClick={(e) => CancelClick(e)}
                    >CANCEL RENEWAL</Button>
                </CardContent>
                :
                <CardContent style={{ display: 'flex', justifyContent: 'right' }}>
                    <CardContent>
                        <Button variant="contained"
                            color="error"
                            fullWidth={true}
                            onClick={(e) => btnreviseClick(e)}
                        >REVISE RENEWAL</Button>
                    </CardContent>
                    <CardContent style={{ paddingLeft: "20px" }}>
                        <Button
                            variant="contained"
                            color="warning"
                            fullWidth={true}
                            onClick={(e) => btnsendClick(e)}
                            disabled={isSigned ? false : true}
                        >SEND TO TENANTS</Button>
                    </CardContent>
                </CardContent>}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default LeaseRenewalPreviewNotice;