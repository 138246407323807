import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { unsetUser } from "../../redux/slices/userSlice";
import { managerRegister } from "../../services";
import { formatPhoneNumber } from "../../components/phoneNumberFormat";

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, setuserData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    formatPhoneNumber: "",
    email: "",
    password: "",
  });
  const [error, seterrors] = useState({
    firstName: { flag: null, message: "" },
    phoneNumber: { flag: null, message: "" },
    email: { flag: null, message: "" },
    password: { flag: null, message: "" },
  });

  // Privacycheck checkbox & Password Show & hide
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  //   validation
  const validation = () => {
    let errorFlag = false;
    let error = {
      firstName: { flag: null, message: "" },
      phoneNumber: { flag: null, message: "" },
      email: { flag: null, message: "" },
      password: { flag: null, message: "" },
    };

    var regexpEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // firstName Validation
    if (userData.firstName.length === 0) {
      errorFlag = true;
      error.firstName = { flag: true, message: "First name is mandatory" };
    }
    // phoneNumber Validation
    if (userData.phoneNumber.length === 0) {
      errorFlag = true;
      error.phoneNumber = { flag: true, message: "Phone number is mandatory" };
    } else if (userData.phoneNumber.length < 14) {
      errorFlag = true;
      error.phoneNumber = {
        flag: true,
        message: "Please enter valid phone number",
      };
    }
    // email Validation
    if (userData.email.length === 0) {
      errorFlag = true;
      error.email = { flag: true, message: "Email is mandatory" };
    } else if (!userData.email.match(regexpEmail)) {
      errorFlag = true;
      error.email = { flag: true, message: "Please enter a valid Email" };
    }
    // password Validation
    if (userData.password.length === 0) {
      errorFlag = true;
      error.password = { flag: true, message: "Password is mandatory" };
    } else if (userData.password.length < 8) {
      errorFlag = true;
      error.password = {
        flag: true,
        message: "Password should be atleast 8 characters",
      };
    }
    seterrors(error);
    return !errorFlag;
  };

  useEffect(() => {
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("super-user");
    dispatch(unsetUser());
  }, []);

  const onSubmit = () => {
    const isValidationSuccess = validation();
    if (isValidationSuccess) {
      let data = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: `${userData.phoneNumber.slice(
          1,
          4
        )}${userData.phoneNumber.slice(6, 9)}${userData.phoneNumber.slice(
          10,
          14
        )}`,
        phoneCountryCode: "01",
        email: userData.email,
        password: userData.password,
        superUser: "12345678"
      };
      managerRegister(data)
        .then((response) => {
          if (response) {
            toast.success(`Manager register is saved successfully.`);
            setTimeout(function () {
              navigate("/login");
            }, 2000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.error.message);
        });
    }
  };
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={6} lg={3} sx={{ boxShadow: { xs: 'none', sm: 'none', md: 'none', lg: '0 10px 20px rgba(53, 60, 115, 0.15)' }, borderRadius: '10px', padding: '30px 40px 30px 40px', }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography
            sx={{ fontFamily: "Times New Roman" }}
            variant="h4"
            textAlign={"center"}
          >
            Sign Up
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <TextField
                error={error.firstName.flag ? true : false}
                helperText={error.firstName.flag ? error.firstName.message : ""}
                sx={{ width: 1 }}
                variant="outlined"
                label="First Name *"
                type="text"
                onChange={(event) =>
                  setuserData({
                    ...userData,
                    firstName: event.target.value.trim(),
                  })
                }
                value={userData.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <TextField
                sx={{ width: 1 }}
                variant="outlined"
                label="Last Name *"
                type="text"
                onChange={(event) =>
                  setuserData({
                    ...userData,
                    lastName: event.target.value.trim(),
                  })
                }
                value={userData.lastName}
              />
            </Grid>
          </Grid>
          <TextField
            error={error.phoneNumber.flag ? true : false}
            helperText={error.phoneNumber.flag ? error.phoneNumber.message : ""}
            sx={{ width: 1 }}
            label="Phone *"
            variant="outlined"
            fullWidth={true}
            type="text"
            onChange={(event) => {
              setuserData({
                ...userData,
                phoneNumber: event.target.value,
                formatedPhoneNumber: formatPhoneNumber(event.target.value),
              });
            }}
            value={userData.formatedPhoneNumber}
          />
          <TextField
            error={error.email.flag ? true : false}
            helperText={error.email.flag ? error.email.message : ""}
            sx={{ width: 1 }}
            label="Email *"
            variant="outlined"
            fullWidth={true}
            type="email"
            onChange={(event) =>
              setuserData({
                ...userData,
                email: event.target.value.trim(),
              })
            }
            value={userData.email}
          />
          <TextField
            error={error.password.flag ? true : false}
            helperText={error.password.flag ? error.password.message : ""}
            sx={{ width: 1 }}
            label="Password *"
            variant="outlined"
            fullWidth={true}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) =>
              setuserData({
                ...userData,
                password: event.target.value.trim(),
              })
            }
            value={userData.password}
          />
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
            <Checkbox
              color="warning"
              size="small"
              onChange={() => {
                setPrivacyChecked(!privacyChecked);
              }}
            />
            <label>I agree to <span style={{color:'#ed6c02'}}>Terms and conditions</span></label>
          </div>
          <Button
            sx={{ fontSize: 14 }}
            variant="contained"
            disabled={!privacyChecked}
            type="submit"
            color="warning"
            onClick={onSubmit}
          >
            Signup
          </Button>
          <p style={{ textAlign: "center", maxWidth: "content" }}>
            Already have an account?
            <Link
              to="/login"
              style={{
                color: "black",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              {" "}
              Login
            </Link>
          </p>
        </div>
      </Grid>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Grid>
  );
}

export default Register;
