import axios from "axios";

export const getApiBaseURL = () => {
  // let apiBaseURL = "http://localhost:3030/api";
  // let apiBaseURL = "https://qa.unityon.com/api";
  let apiBaseURL = null;;
  let apiProtocol = window.location.protocol;
  let apiHost = window.location.host;
  if (process.env.REACT_APP_API_PROTOCOL !== undefined) apiProtocol = process.env.REACT_APP_API_PROTOCOL;
  if (process.env.REACT_APP_API_HOST !== undefined) apiHost = process.env.REACT_APP_API_HOST;
  apiBaseURL = apiProtocol + '//' + apiHost + '/api';
  return apiBaseURL;
}

export const api = axios.create({
  // baseURL: "http://localhost:3030/api",
  // baseURL: "https://qa.unityon.com/api",
  // console.log("test")
  baseURL: getApiBaseURL(),
});

export const getProperties = () => {
  return api.get("/properties");
};

export const getSingleProperty = (id) => {
  return api.get(`/properties/${id}`);
};

export const getInviteDetails = (leaseId, applicantId, accessCode) => {
  return api.get(`/tenant/lease-applications/${leaseId}/invite`, {
    params: {
      applicantId,
      accessCode,
    },
  });
};

export const startCoApplication = (applicationId) => {
  return api.post(
    `/tenant/lease-applications/${applicationId}/start`,
    {},
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const tenantRegisterOrLogin = (
  firstName,
  lastName,
  phoneCountryCode,
  phoneNumber,
  email,
  password
) => {
  return api.post("/tenant/register-or-login", {
    firstName,
    lastName,
    phoneCountryCode,
    phoneNumber,
    email,
    password,
  });
};

export const login = (email, password) => {
  return api.post("user/login", { email, password });
};

export const changePassword = (email) => {
  return api.post("/user/forgot-password", { email });
};

export const updatePassword = (email, password, token) => {
  return api.post(
    "/user/reset-password",
    {
      email,
      password,
      token,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const Requestemailverification = (email, token) => {
  return api.post(`/user/request-email-verification`, email, token);
};

export const emailVerificationSuccess = (email, token) => {
  return api.post(`/user/verify-email`, { email, token });
};

export const getCurrentUser = () => {
  return api.get("/user", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getUserProfile = () => {
  return api.get(`user/profile`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchUserProfile = (obj) => {
  return api.patch(`user/profile`, obj, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postUserProfilePicture = (data) => {
  return api.post(`user/profile/picture`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteUserProfilePicture = () => {
  return api.delete(`user/profile/picture`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerProfile = () => {
  return api.get(`manager/profile`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerProfile = (obj) => {
  return api.patch(`manager/profile`, obj, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const addManagerLeaseApplicationAttachment = (
  leaseApplicationId,
  form
) => {
  return api.post(
    `manager/lease-applications/${leaseApplicationId}/review/files`,
    form,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const removeManagerLeaseApplicationAttachment = (
  leaseApplicationId,
  reviewField
) => {
  return api.delete(
    `manager/lease-applications/${leaseApplicationId}/review/files/${reviewField}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const getManagerAgreement = (id) => {
  return api.get(`/manager/lease-applications/${id}/lease-agreement`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerLeaseAgreementAttachment = (id, form) => {
  return api.post(
    `/manager/lease-applications/${id}/lease-agreement/attachments`,
    form,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const removeManagerLeaseAgreementAttachment = (
  leaseApplicationId,
  attachmentId
) => {
  return api.delete(
    `manager/lease-applications/${leaseApplicationId}/lease-agreement/attachments/${attachmentId}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const inviteLeaseApplication = (propertyId, obj) => {
  return api.post(
    `manager/properties/${propertyId}/invite-lease-application`,
    obj,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const sendInvitaionink = (leaseApplicationId, leaseApplicantId) => {
  return api.post(
    `/tenant/lease-applications/${leaseApplicationId}/applicants/${leaseApplicantId}/send-link`,
    true,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const postCoapplicantInvite = (applicationid, data) => {
  return api.post(
    `/tenant/lease-applications/${applicationid}/applicants/invite`,
    data,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const patchCoapplicantInvite = (applicationid, applicantid, data) => {
  return api.patch(
    `/tenant/lease-applications/${applicationid}/applicants/${applicantid}/invite`,
    data,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const createTenantLeaseApplication = (
  propertyId,
  applicationInvitees
) => {
  return api.post(
    "/tenant/lease-applications",
    {
      propertyId,
      applicationInvitees,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const getTenantLeaseApplication = (id) => {
  return api.get(`/tenant/lease-applications/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const editTenantLeaseApplication = (id, data) => {
  return api.patch(`/tenant/lease-applications/${id}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const withDrawApplication = (id) => {
  return api.post(
    `/tenant/lease-applications/${id}/applicant/withdraw`,
    {},
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const editTenantLeaseApplicantInfo = (id, data) => {
  return api.patch(`/tenant/lease-applications/${id}/applicant`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const addTenantIncomeVerificationFile = (id, form) => {
  return api.put(
    `/tenant/lease-applications/${id}/applicant/verification-files/income-verification`,
    form,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const removeTenantIncomeVerificationFile = (id) => {
  return api.delete(
    `/tenant/lease-applications/${id}/applicant/verification-files/income-verification`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const addTenantOtherIncomeVerificationFile = (id, form) => {
  return api.put(
    `/tenant/lease-applications/${id}/applicant/verification-files/other-income-verification`,
    form,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const removeTenantOtherIncomeVerificationFile = (id) => {
  return api.delete(
    `/tenant/lease-applications/${id}/applicant/verification-files/other-income-verification`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const addTenantBankStatement = (id, form) => {
  return api.put(
    `/tenant/lease-applications/${id}/applicant/verification-files/bank-statement`,
    form,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const removeTenantBankStatement = (id) => {
  return api.delete(
    `/tenant/lease-applications/${id}/applicant/verification-files/bank-statement`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const addTenantCreditReport = (id, form) => {
  return api.put(
    `/tenant/lease-applications/${id}/applicant/verification-files/credit-report`,
    form,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const removeTenantCreditReport = (id) => {
  return api.delete(
    `/tenant/lease-applications/${id}/applicant/verification-files/credit-report`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const addTenantGovernmentId = (id, form) => {
  return api.put(
    `/tenant/lease-applications/${id}/applicant/verification-files/government-id`,
    form,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const removeTenantGovernmentId = (id) => {
  return api.delete(
    `/tenant/lease-applications/${id}/applicant/verification-files/government-id`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const tenantSignAndSubmit = (id, data) => {
  return api.post(
    `/tenant/lease-applications/${id}/applicant/sign-and-submit`,
    data,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const deleteTenantLeaseApplication = (id) => {
  return api.delete(`/tenant/lease-applications/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenantApplicationSign = (id) => {
  return api.get(`/tenant/lease-applications/${id}/applicant/signature/file`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenantLeasesOverview = () => {
  return api.get("/tenant/leases-overview", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenantLeaseApplicationsOverview = () => {
  return api.get("/tenant/lease-applications/overview", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const managerRegister = (obj) => {
  return api.post("/manager/register", obj);
};

export const getManagerPropertiesOverview = () => {
  return api.get("/manager/properties-overview", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const addProperty = (form) => {
  return api.post(`/properties`, form, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerProperty = (id) => {
  return api.get(`/manager/properties/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const addManagerPropertyImage = (id, form) => {
  return api.post(`/properties/${id}/images`, form, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const removeManagerPropertyImage = (propertyId, imageId) => {
  return api.delete(`/properties/${propertyId}/images/${imageId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const editManagerProperty = (id, data) => {
  return api.patch(`/properties/${id}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const activeInactiveStatus = (id, data) => {
  return api.patch(`/properties/${id}/activeInactive`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};


export const getManagerLeaseApplication = (id) => {
  return api.get(`/manager/lease-applications/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};


export const postReview = (applicationsid, data) => {

  try {
    return api.patch(
      `/manager/lease-applications/${applicationsid}/review`,
      data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
      }
    );
  } catch (error) {
    return error;
  }
};

export const acceptReview = (applicationsid) => {
  try {
    return api.post(
      `manager/lease-applications/${applicationsid}/review/accept`,
      true,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
      }
    );
  } catch (error) {
    return error;
  }
};

export const overViewaccept = (id) => {
  return api.post(`/manager/lease-applications/${id}/review/accept`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getLeasemanageragreementview = async (id) => {
  return await api.get(
    `/manager/lease-applications/${id}/lease-agreement/view`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const leaseagreement = (id, form) => {
  return api.patch(`/manager/lease-applications/${id}/lease-agreement`, form, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getLeaseappAgreement = async (id) => {
  return await api.get(`/manager/lease-applications/${id}/lease-agreement`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const previewAgreement = (id) => {
  return api.get(`/manager/lease-applications/${id}/lease-agreement/preview`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const managerfinalsign = (obj, id) => {
  return api.post(
    `/manager/lease-applications/${id}/lease-agreement/sign-and-setup`,
    obj,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const managerLeaseAgreementreject = (id) => {
  return api.post(
    `/manager/lease-applications/${id}/lease-agreement/reject`,
    true,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const getLeaseappAgreementtenant = async (id) => {
  try {
    return await api.get(`/tenant/lease-applications/${id}/lease-agreement`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    });
  } catch (error) {
    return error;
  }
};
export const getLeaseappAgreementManager = async (id) => {
  try {
    return await api.get(`/manager/lease-applications/${id}/lease-agreement`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    });
  } catch (error) {
    return error;
  }
};
export const agreementSend = async (id, email) => {
  try {
    return await api.post(
      `/manager/lease-applications/${id}/lease-agreement/send`,
      email,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
      }
    );
  } catch (error) {
    return error;
  }
};
export const tenantLeaseAgreement = (id) => {
  return api.get(`/tenant/lease-applications/${id}/lease-agreement`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantLeaseAgreementView = (id) => {
  return api.get(`/tenant/lease-applications/${id}/lease-agreement/view`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantLeaseAgreementsign = (id, data) => {
  return api.post(
    `/tenant/lease-applications/${id}/lease-agreement/sign-and-send`,
    data,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};
export const tenantLeaseAgreementreject = (id) => {
  return api.post(
    `/tenant/lease-applications/${id}/lease-agreement/reject`,
    true,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};
export const managerLeaseAgreementRevise = (id) => {
  return api.post(
    `/manager/lease-applications/${id}/review/revise-lease-agreement`,
    true,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    }
  );
};

export const overViewtenants = (id) => {
  return api.get(`/manager/lease-applications/properties/${id}/overview`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const managerLeasesOverview = (id) => {
  return api.get(`/manager/properties/${id}/leases/overview`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getPropertyFinal = (id) => {
  return api.get(`/manager/leases/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const updateManagerMoveInInspectionItemServiceRequestInfo = (leaseId, itemId, srInfo) => {
  return api.patch(`/manager/leases/${leaseId}/move-in/serviceRequestInfo/${itemId}`, srInfo, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getLeases = async (id) => {
  return api.get(`/tenant/leases/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getleaseEntries = async (id) => {
  return api.get(`/tenant/leases/${id}/finance-balance/entries/manager`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const serviceRequest = (leaseId, data) => {
  return api.post(`/tenant/service-requests/leases/${leaseId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getServiceRequest = (id) => {
  return api.get(`/tenant/service-requests/leases/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getManagerLeases = (id) => {
  return api.get(`/manager/leases/${id}/property`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postDismiss = (id) => {
  return api.post(`/manager/lease-applications/${id}/review/dismiss`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postConsider = (id) => {
  return api.post(`/manager/lease-applications/${id}/review/consider`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const reviewReject = (id) => {
  return api.post(`manager/lease-applications/${id}/review/reject`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const reviewUnreject = (id) => {
  return api.post(`/manager/lease-applications/${id}/review/unreject`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenantLeasesProperty = (id) => {
  return api.get(`/tenant/leases/${id}/property`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenantLeases = (id) => {
  return api.get(`/tenant/leases/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenantMovein = (id) => {
  return api.get(`/tenant/leases/${id}/move-in`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const updateMoveInInspectionItemServiceRequestInfo = (leaseId, itemId, srInfo) => {
  return api.patch(`/tenant/leases/${leaseId}/move-in/serviceRequestInfo/${itemId}`, srInfo, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};



export const setEndLease = (id, data) => {
  return api.post(`/manager/leases/${id}/end-lease`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getDocumentAndNotice = (id) => {
  return api.get(`/manager/leases/${id}/files`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const patchDocumentAndNotice = (id, fileId, data) => {
  return api.patch(`/manager/leases/${id}/editFiles/${fileId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const deleteDocumentAndNotice = (id, fileId) => {
  return api.delete(`/manager/leases/${id}/deleteFiles/${fileId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const managerMovinspectionitem = (id, data) => {
  return api.post(`/manager/leases/${id}/move-in/inspection-items`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const patchManagerMovinspectionitem = (id, itemId, datas) => {
  return api.patch(`/manager/leases/${id}/move-in/inspection-items/${itemId}`, datas, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const deletemanagerMovinspectionitem = (id, itemId) => {
  return api.delete(`/manager/leases/${id}/move-in/inspection-items/${itemId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postmanagerMovinspectionitemfile = (id, itemId, data) => {
  return api.post(`/manager/leases/${id}/move-in/inspection-items/${itemId}/files`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postManagerMoveinSubmit = (id, data) => {
  return api.post(`/manager/leases/${id}/move-in/submit`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postTenantMovinspectionitemfile = (id, itemId, data) => {
  return api.post(`/tenant/leases/${id}/move-in/inspection-items/${itemId}/files`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postTenantMovinspectionArea = (leaseId, data) => {
  return api.post(`tenant/leases/${leaseId}/move-in/inspection-area`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchTenantMovinspectionArea = (leaseId, itemId, data) => {
  return api.patch(`tenant/leases/${leaseId}/move-in/inspection-area/${itemId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteTenantMovinspectionitem = (id, itemId) => {
  return api.delete(`/tenant/leases/${id}/move-in/inspection-items/${itemId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const patchTenantMovinspectionitem = (id, itemId, datas) => {
  return api.patch(`/tenant/leases/${id}/move-in/inspection-items/${itemId}`, datas, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantMovinspectionitem = (id, data) => {
  return api.post(`/tenant/leases/${id}/move-in/inspection-items`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantMovinTenanRemarks = (id, data) => {
  return api.patch(`/tenant/leases/${id}/move-in/tenant-remarks`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postTenantMoveinSubmit = (id, data) => {
  return api.post(`/tenant/leases/${id}/move-in/submit`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const managerMovinTenanRemarks = (id, data) => {
  return api.patch(`/manager/leases/${id}/move-in/manager-remarks`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerMovinspectionArea = (leaseId, data) => {
  return api.post(`manager/leases/${leaseId}/move-in/inspection-area`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerMovinspectionArea = (leaseId, itemId, data) => {
  return api.patch(`/manager/leases/${leaseId}/move-in/inspection-area/${itemId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const managerSchedulemoveout = (id, data) => {
  return api.post(`/manager/leases/${id}/schedule-move-out`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const managerMoveoutForwardEmail = (id, data) => {
  return api.patch(`/manager/leases/${id}/mail-forwarding-address`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const managerCancelMoveout = (id) => {
  return api.post(`manager/leases/${id}/cancel-move-out`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantSchedulemoveout = (id, data) => {
  return api.post(`/tenant/leases/${id}/schedule-move-out`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantMoveoutForwardEmail = (id, data) => {
  return api.patch(`/tenant/leases/${id}/mail-forwarding-address`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantCancelMoveout = (id) => {
  return api.post(`tenant/leases/${id}/cancel-move-out`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const managerRenewalLease = (id, data) => {
  return api.patch(`manager/leases/${id}/renewal`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getManagerRenewalLease = (id) => {
  return api.get(`manager/leases/${id}/renewal`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getNoticeAddress = (id) => {
  return api.get(`/manager/notice/get-lease-addresses/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getNoticeTemplates = () => {
  return api.get(`manager/notice-templates`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const managerNoticePreview = (data) => {
  return api.post(`/manager/notice/preview`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantRecommendationLetter = (id) => {
  return api.post(`/tenant/leases/${id}/request-recommendation-letter`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postNoticeTemplates = (data) => {
  return api.post(`/manager/notice-templates`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getUpdateNoticeTemplates = (id) => {
  return api.get(`/manager/notice-templates/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const patchUpdateNoticeTemplates = (id, data) => {
  return api.patch(`/manager/notice-templates/${id}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const deleteUpdateNoticeTemplates = (id) => {
  return api.delete(`/manager/notice-templates/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getRenewalPreview = (id) => {
  return api.get(`/manager/leases/${id}/renewal/notice-preview`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postScheduleandSendNotice = (id, data) => {
  return api.post(`/manager/leases/${id}/renewal/schedule-and-send-notice`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const cancelrenewal = (id) => {
  return api.post(`/manager/leases/${id}/renewal/cancel`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const managerNoticeSend = (data) => {
  return api.post(`/manager/notice/send`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const managerMoveOutInspection = (id) => {
  return api.get(`manager/leases/${id}/move-out`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const updateManagerMoveOutInspectionItemServiceRequestInfo = (leaseId, itemId, srInfo) => {
  return api.patch(`/manager/leases/${leaseId}/move-out/serviceRequestInfo/${itemId}`, srInfo, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerMoveOutInspectionItems = (id, data) => {
  return api.post(`manager/leases/${id}/move-out/inspection-items`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const deleteManagerMoveOutInspectionItems = (id, itemId) => {
  return api.delete(`manager/leases/${id}/move-out/inspection-items/${itemId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postManagerMoveOutInspectionItemsFiles = (id, fileId, data) => {
  return api.post(`manager/leases/${id}/move-out/inspection-items/${fileId}/files`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const patchManagerMoveOutInspectionItems = (id, itemId, data) => {
  return api.patch(`/manager/leases/${id}/move-out/inspection-items/${itemId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postManagerMoveOut = (id, data) => {
  return api.patch(`/manager/leases/${id}/move-out`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getManagerFinanceBalanceOverview = (id) => {
  return api.get(`/manager/leases/${id}/finance-balance/overview`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postManagerMoveOutSendTenantRestitutionEstimate = (id) => {
  return api.post(`/manager/leases/${id}/move-out/send-tenant-restitution-estimate`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postManagerMoveOutSubmit = (id, data) => {
  return api.post(`/manager/leases/${id}/move-out/submit`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantMoveOutInspection = (id) => {
  return api.get(`tenant/leases/${id}/move-out`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getTenantFinanceBalanceOverview = (id) => {
  return api.get(`/tenant/leases/${id}/finance-balance/overview`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const deleteManagerMoveOutImage = (id, fileId, imgId) => {
  return api.delete(`/manager/leases/${id}/move-out/inspection-items/${fileId}/files/${imgId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getManagerServicerequests = (id) => {
  return api.get(`/manager/service-requests/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const ServicerequestsSuggestedTags = (id) => {
  return api.get(`/manager/service-requests/${id}/suggested-tags`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const Manager = (id) => {
  return api.get(`/manager`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const ServicerequestsMembers = (id) => {
  return api.get(`/manager/service-requests/${id}/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const ServicerequestsAttachments = (id, imageid) => {

  return api.get(`/manager/service-requests/${id}/attachments/${imageid}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getServiceRequestProperties = (id,status) => {
  return api.get(`/manager/service-requests/properties/${id}/overview${status}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getServiceRequestMembers = (id) => {
  return api.get(`/manager/service-requests/properties/${id}/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getServiceRequestSuggestedTags = (id) => {
  return api.get(`/manager/service-requests/properties/${id}/suggested-tags`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerServiceRequest = (data, id) => {
  return api.post(`/manager/service-requests${id}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};


export const postManagerServiceRequestFinance = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/finance`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerServiceRequestSuggestedTags = (id) => {
  return api.get(`/manager/service-requests/${id}/suggested-tags`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerServiceRequestMembers = (id) => {
  return api.get(`/manager/service-requests/${id}/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const patchManagerServiceRequestAssignee = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/assignee`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerServiceRequest = (id) => {
  return api.get(`/manager/service-requests/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerServiceRequestState = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/state`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerServiceRequestAttachments = (id, data) => {
  return api.post(`/manager/service-requests/${id}/attachments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerServiceRequestAttachments = (id, attachId) => {
  return api.delete(`/manager/service-requests/${id}/attachments/${attachId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerServiceRequest = (id, data) => {
  return api.patch(`/manager/service-requests/${id}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerServiceRequestHtmlDescCheckBox = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/description/check-box`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerServiceRequestComments = (id, data) => {
  return api.post(`/manager/service-requests/${id}/comments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerServiceRequestComments = (id, cmntId) => {
  return api.delete(`/manager/service-requests/${id}/comments/${cmntId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const patchManagerServiceRequestComments = (id, cmntId, data) => {
  return api.patch(`/manager/service-requests/${id}/comments/${cmntId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerServiceRequestCommentsAttachment = (id, cmntId, data) => {
  return api.post(`/manager/service-requests/${id}/comments/${cmntId}/attachments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerServiceRequestCommentsAttachment = (id, cmntId, imgId) => {
  return api.delete(`/manager/service-requests/${id}/comments/${cmntId}/attachments/${imgId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const tenantServiceRequest = (id) => {
  return api.get(`/tenant/service-requests/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenantServiceRequestMembers = (id) => {
  return api.get(`/tenant/service-requests/${id}/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

//---------------
export const getTenantServiceRequest = (id) => {
  return api.get(`/tenant/service-requests/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchTenantServiceRequestState = (id, data) => {
  return api.patch(`/tenant/service-requests/${id}/state`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postTenantServiceRequestAttachments = (id, data) => {
  return api.post(`/tenant/service-requests/${id}/attachments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteTenantServiceRequestAttachments = (id, attachId) => {
  return api.delete(`/tenant/service-requests/${id}/attachments/${attachId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchTenantServiceRequest = (id, data) => {
  return api.patch(`/tenant/service-requests/${id}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchTenantServiceRequestDescriptionCheckbox = (id, data) => {
  return api.patch(`/tenant/service-requests/${id}/description/check-box`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postTenantServiceRequestComments = (id, data) => {
  return api.post(`/tenant/service-requests/${id}/comments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteTenantServiceRequestComments = (id, cmntId) => {
  return api.delete(`/tenant/service-requests/${id}/comments/${cmntId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const patchTenantServiceRequestComments = (id, cmntId, data) => {
  return api.patch(`/tenant/service-requests/${id}/comments/${cmntId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postTenantServiceRequestCommentsAttachment = (id, cmntId, data) => {
  return api.post(`/tenant/service-requests/${id}/comments/${cmntId}/attachments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteTenantServiceRequestCommentsAttachment = (id, cmntId, imgId) => {
  return api.delete(`/tenant/service-requests/${id}/comments/${cmntId}/attachments/${imgId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerMoveInImg = (id, manId, imgId) => {
  return api.delete(`/manager/leases/${id}/move-in/inspection-items/${manId}/files/${imgId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const deleteTenantMoveInImg = (id, manId, imgId) => {
  return api.delete(`/tenant/leases/${id}/move-in/inspection-items/${manId}/files/${imgId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postContractorAdd = (data) => {
  return api.post(`/manager/contractors`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchContractorUpdate = (data, contId) => {
  return api.patch(`/manager/contractors/${contId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getContractor = () => {
  return api.get(`/manager/contractors`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getContractorTags = () => {
  return api.get(`/manager/contractors/inbuilt-tags`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const appointContractor = (id, data) => {
  return api.post(`/manager/service-requests/${id}/contractor/appoint`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const unAppointContractor = (id) => {
  return api.post(`/manager/service-requests/${id}/contractor/unappoint`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getContractorService = (conId, id) => {
  return api.get(`/contractor/${conId}/service-requests/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getContractorMember = (conId, id) => {
  return api.get(`/contractor/${conId}/service-requests/${id}/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postContractorComment = (contId, id, data) => {
  return api.post(`/contractor/${contId}/service-requests/${id}/comments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteContractorComment = (contId, id, cmntId) => {
  return api.delete(`/contractor/${contId}/service-requests/${id}/comments/${cmntId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchContractorComment = (contId, id, cmntId, data) => {
  return api.patch(`/contractor/${contId}/service-requests/${id}/comments/${cmntId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postContractorAttachment = (contId, id, cmntId, data) => {
  return api.post(`/contractor/${contId}/service-requests/${id}/comments/${cmntId}/attachments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteContractorAttachment = (contId, id, cmntId, attachId) => {
  return api.delete(`/contractor/${contId}/service-requests/${id}/comments/${cmntId}/attachments/${attachId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postContractorStarRating = (contId, id, data) => {
  return api.post(`/contractor/${contId}/service-requests/${id}/contractor/rate`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getContractorStarRating = (contId) => {
  return api.get(`/manager/service-requests/${contId}/contractor/rate`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getContractorCurrentLease = (contId, servId) => {
  return api.get(`/contractor/${contId}/service-requests/${servId}/current-lease`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerFinances = (start) => {
  return api.get(`/manager/finances${start}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerFinancetransactions = (id) => {
  return api.get(`/manager/properties/${id}/finance/transactions`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerFinanceBalanceEntries = (id) => {
  return api.get(`/manager/leases/${id}/finance-balance/entries/manager`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManProperty = (id) => {
  return api.get(`/manager/properties/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerFinanceBalanceDueEntries = (id, data) => {
  return api.post(`/manager/leases/${id}/finance-balance/due-entries`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const postManagerFinanceBalancePaidEntries = (id, data) => {
  return api.post(`/manager/leases/${id}/finance-balance/paid-entries`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerAddExpenses = (id, data) => {
  return api.post(`/manager/properties/${id}/finance/transactions`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerFinanceBalanceDueEntries = (id, entryId, data) => {
  return api.patch(`/manager/leases/${id}/finance-balance/due-entries/${entryId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerFinanceBalanceDueEntries = (id, entryId) => {
  return api.delete(`/manager/leases/${id}/finance-balance/due-entries/${entryId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerFinanceBalancePaidEntries = (id, entryId, data) => {
  return api.patch(`/manager/leases/${id}/finance-balance/paid-entries/${entryId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerFinanceBalancePaidEntries = (id, entryId) => {
  return api.delete(`/manager/leases/${id}/finance-balance/paid-entries/${entryId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchFinanceTransactions = (id, tranId, data) => {
  return api.patch(`/manager/properties/${id}/finance/transactions/${tranId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteFinanceTransactions = (id, tranId) => {
  return api.delete(`/manager/properties/${id}/finance/transactions/${tranId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchFinanceTransactionsReconlationStatus = (id, tranId) => {
  return api.patch(`/manager/properties/${id}/finance/transactions/${tranId}/re-concilated-status`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchLastSelectedDateReconlationStatus = () => {
  return api.patch(`/manager/LastSelectedFinanceDate/re-concilation-status`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getPropertyGroups = () => {
  return api.get(`/manager/property-groups?groupUngrouped=true`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getgroupedPropertyGroups = (grpId) => {
  return api.get(`/manager/property-groups/${grpId}?groupUngrouped=true`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postPropertyGroups = (data) => {
  return api.post(`/manager/property-groups/finance/group-transactions`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerPropertyGroups = () => {
  return api.get(`/manager/property-groups`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerPropertyGroups = (data) => {
  return api.post(`/manager/property-groups`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerPropertyGroups = (grpId, data) => {
  return api.patch(`/manager/property-groups/${grpId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerPropertyGroups = (grpId) => {
  return api.delete(`/manager/property-groups/${grpId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const putManagerPropertyGroups = (data) => {
  return api.put(`/manager/property-groups/grouping`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTeamPropertyAllotments = () => {
  return api.get(`/manager/team/property-allotments`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postTeamManagersInvite = (data) => {
  return api.post(`/manager/team/managers/invite`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerTeamManagers = () => {
  return api.get(`/manager/team/managers`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postTeamManagerInvite = (manId) => {
  return api.post(`/manager/team/managers/${manId}/invite`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteTeamManagers = (manId) => {
  return api.delete(`/manager/team/managers/${manId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchPropertyAllotments = (data) => {
  return api.patch(`/manager/team/property-allotments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postActive = (id) => {
  return api.post(`/manager/properties/${id}/active`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postInActive = (id) => {
  return api.post(`/manager/properties/${id}/inactive`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
export const getUser = () => {
  return api.get(`/user`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getFinanceGroups = (manId) => {
  return api.get(`/manager/${manId}/financesgroup`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getArchivedProperties = () => {
  return api.get(`/manager/properties-overview/archived`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getmanagerInvite = (token) => {
  return api.get(`/manager/manager-invitation${token}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postmanagerInvite = (token, data) => {
  return api.post(`/manager/manager-invitation${token}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postArchiveProperty = (propId) => {
  return api.post(`/manager/properties/${propId}/archive`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postUnArchiveProperty = (propId) => {
  return api.post(`/manager/properties/${propId}/unarchive`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postArchivegroup = (grpId, data) => {
  return api.post(`/manager/property-groups/group/${grpId}/archive`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postUnArchivegroup = (grpId, data) => {
  return api.post(`/manager/property-groups/group/${grpId}/unarchive`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getCanArchive = (id) => {
  return api.get(`manager/properties/${id}/can-archive`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postContractorArchive = (id, data) => {
  return api.post(`/manager/contractors/${id}/archive`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postContractorUnarchive = (id, data) => {
  return api.post(`/manager/contractors/${id}/unarchive`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getGroupServiceRequestTags = (grpId) => {
  return api.get(`/manager/service-requests/group/${grpId}/suggested-tags`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getGroupServiceRequestMembers = (grpId) => {
  return api.get(`/manager/service-requests/group/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerComplexIndividualFinances = (manId, grpId, start) => {
  return api.get(`/manager/${manId}/finances/indivdual-complex/${grpId}${start}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerComplexServiceRequests = (groupId,status) => {
  return api.get(`/manager/service-requests/complex/${groupId}/overview${status}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerHomeIncomeExpenseFinances = (id, start) => {
  return api.get(`/manager/${id}/home-income-expense-finances${start}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerLeases = (data) => {
  return api.post(`/manager/leases`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postuserEmailStatus = (data) => {
  return api.post(`/manager/leases/user-email-status`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const putLeaseTenants = (leaseId, leaseTenantId, data) => {
  return api.put(`/manager/leases/${leaseId}/lease-tenants/${leaseTenantId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const PostLeaseTenantsSendInvitaionEmail = (leaseId, leaseTenantId) => {
  return api.post(`/manager/leases/${leaseId}/lease-tenants/${leaseTenantId}/send-invitation-email`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchLeaseRentDetails = (leaseId, data) => {
  return api.patch(`/manager/leases/${leaseId}/rent-details`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const PostLeaseTenantsSendUpdateEmail = (leaseId, leaseTenantId) => {
  return api.post(`/manager/leases/${leaseId}/lease-tenants/${leaseTenantId}/send-update-email`, {}, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postRequestTenantMoveInInspection = (leaseId) => {
  return api.post(`/manager/leases/${leaseId}/request-move-in-inspection`, null, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenantsLeaseInvitation = (token) => {
  return api.get(`/tenant/lease-invitation?token=${token}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postTenantsLeaseInvitation = (token, data) => {
  return api.post(`/tenant/lease-invitation?token=${token}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchPaymentRemainderDate = (leaseId, data) => {
  return api.patch(`manager/leases/${leaseId}/finance-balance/due-reminder-date`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getComplexServiceRequest = (id) => {
  return api.get(`/manager/service-requests/${id}/complex-service-request`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerComplexServiceRequestFinance = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/finance/complex-service-request`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postFinanceLastSelectedDate = (data) => {
  return api.post(`/manager/LastSelectedFinanceDate`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getFinanceLastSelectedDate = () => {
  return api.get(`/manager/LastSelectedFinanceDate`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerServiceRequestFinanceAll = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/financeAll`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerServiceRequestFinanceAll = (id, financeId, transactionId, attachmentId) => {
  return api.delete(`/manager/service-requests/${id}/financeAll/${financeId}/transaction/${transactionId}/attachment/${attachmentId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerServiceRequestFinanceAll = (id, financeId, transactionId, data) => {
  return api.patch(`/manager/service-requests/${id}/finances/${financeId}/transaction/${transactionId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};
// Finance View Deails
export const getGroupFinanceTransactionsReconciled = (start) => {
  return api.get(`/manager/group-finance/transactions-reconciled${start}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerPropertyGroupsGroups = () => {
  return api.get(`/manager/property-groups/groups`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getManagerGroupsPropertiesAddress = (grpId) => {
  return api.get(`/manager/properties/group/${grpId}/properties-address`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postAddFinanceDetailsNew = (data) => {
  return api.post(`/manager/properties/finance/transactions/add-finance-details-new`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchAddFinanceDetailsNew = (tranId, data) => {
  return api.patch(`/manager/properties/finance/transactions/${tranId}/update-finance-details-new`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// Finance Csv File Upload
export const postFinanceDetailsCsvFileUpload = (manId, data, onUploadProgress) => {
  return api.post(`/manager/${manId}/add-CSV-new`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
    onUploadProgress: onUploadProgress
  });
};

export const deleteFinanceDetailsNew = (id, tranId) => {
  return api.delete(`/manager/properties/${id}/finance/transactions/${tranId}/delete-finance-details-new`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// Super user get function
export const getManagers = () => {
  return api.get("user/managers", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getTenants = () => {
  return api.get("user/tenants", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// Super User Delete Manager and tenant
export const deleteSuperUserManager = (managerId) => {
  return api.delete(`/manager/${managerId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteSuperUserTenants = (tenantId) => {
  return api.delete(`/tenant/${tenantId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// Complex Service Request
export const getComplexServiceRequestMembers = (id) => {
  return api.get(`/manager/service-requests/${id}/complex-service-request/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getComplexServiceRequestSuggestedTags = (id) => {
  return api.get(`/manager/service-requests/${id}/complex-service-request/suggested-tags`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchComplexServiceRequest = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/complex-service-request`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchComplexServiceRequestDescriptionCheckbox = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/complex-service-request/description/check-box`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchComplexServiceRequestState = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/complex-service-request/state`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchComplexServiceRequestAssignee = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/complex-service-request/assignee`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postComplexServiceRequestAttachments = (id, data) => {
  return api.post(`/manager/service-requests/${id}/complex-service-request/attachments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteComplexServiceRequestAttachments = (id, attachId) => {
  return api.delete(`/manager/service-requests/${id}/complex-service-request/attachments/${attachId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postComplexServiceRequestComments = (id, data) => {
  return api.post(`/manager/service-requests/${id}/complex-service-request/comments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchComplexServiceRequestComments = (id, cmntId, data) => {
  return api.patch(`/manager/service-requests/${id}/complex-service-request/comments/${cmntId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteComplexServiceRequestComments = (id, cmntId) => {
  return api.delete(`/manager/service-requests/${id}/complex-service-request/comments/${cmntId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postComplexServiceRequestCommentsAttachment = (id, cmntId, data) => {
  return api.post(`/manager/service-requests/${id}/comments/${cmntId}/complex-service-request/attachments`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteComplexServiceRequestCommentsAttachment = (id, cmntId, imgId) => {
  return api.delete(`/manager/service-requests/${id}/comments/${cmntId}/complex-service-request/attachments/${imgId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const complexServiceRequestappointContractor = (id, data) => {
  return api.post(`/manager/service-requests/${id}/contractor/appoint/complex-service-request`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const complexServiceRequestunAppointContractor = (id) => {
  return api.post(`/manager/service-requests/${id}/contractor/unappoint/complex-service-request`, true, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const complexServiceRequesPostContractorStarRating = (contId, id, data) => {
  return api.post(`/contractor/${contId}/service-requests/${id}/contractor/rate/complex-service-request`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};


export const getcomplexServiceRequestContractorStarRating = (contId) => {
  return api.get(`/manager/service-requests/${contId}/contractor/rate/complex-service-request`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postComplexServiceRequestFinanceAll = (id, data) => {
  return api.patch(`/manager/service-requests/${id}/financeAll/complex-service-request`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteComplexServiceRequestFinanceAll = (id, financeId, transactionId, attachmentId) => {
  return api.delete(`/manager/service-requests/${id}/financeAll/${financeId}/transaction/${transactionId}/attachment/${attachmentId}/complex-service-request`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchComplexServiceRequestFinanceAll = (id, financeId, transactionId, data) => {
  return api.patch(`/manager/service-requests/${id}/finances/${financeId}/transaction/${transactionId}/complex-service-request`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// SuperUser Login

export const superUserLogin = (data) => {
  return api.post("user/super-user/login", data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const superUserSecretKeyReset = (data) => {
  return api.post("user/super-user/reset/secret-key", data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// New

export const postManagerServiceRequestMaterialsAttachments = (id, data) => {
  return api.post(`/manager/service-requests/${id}/attachments/finance/materials`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerServiceRequestMaterialsAttachments = (id, attachId) => {
  return api.delete(`/manager/service-requests/${id}/finance/materials/attachments/${attachId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerServiceRequestMaterialsAttachments = (serviceRequestId, attachmentId, uniqueFinanceId, data) => {
  return api.patch(`/manager/service-requests/${serviceRequestId}/attachments/${attachmentId}/finance/materials/${uniqueFinanceId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerComplexServiceRequestMaterialsAttachments = (id, data) => {
  return api.post(`/manager/service-requests/${id}/attachments/finance/materials/complex-service-request`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerComplexServiceRequestMaterialsAttachments = (id, attachId) => {
  return api.delete(`/manager/service-requests/${id}/finance/materials/attachments/${attachId}/complex-service-request`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerComplexServiceRequestMaterialsAttachments = (serviceRequestId, attachmentId, uniqueFinanceId, data) => {
  return api.patch(`/manager/service-requests/${serviceRequestId}/attachments/${attachmentId}/complex-service-request/finance/materials/${uniqueFinanceId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postManagerServiceRequestContractorFinance = (serviceRequestId, data, status = null) => {
  return api.post(`/manager/service-requests/${serviceRequestId}/contractor/finance${status}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchManagerServiceRequestContractorFinance = (serviceRequestId, financeId, data) => {
  return api.patch(`/manager/service-requests/${serviceRequestId}/contractor/finance/${financeId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerServiceRequestContractorFinanceAttachment = (serviceRequestId, financeId, attachId) => {
  return api.delete(`/manager/service-requests/${serviceRequestId}/contractor/finance/${financeId}/attachments/${attachId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteManagerServiceRequestContractorFinance = (serviceRequestId, financeId) => {
  return api.delete(`/manager/service-requests/${serviceRequestId}/contractor/finance/${financeId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// Group Service Request
export const postGroupServiceRequestContractorFinance = (serviceRequestId, groupId, data, status = null) => {
  return api.post(`/manager/service-requests/${serviceRequestId}/property-group/${groupId}/contractor/finance${status}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchGroupServiceRequestContractorFinance = (serviceRequestId, groupId, financeId, data) => {
  return api.patch(`/manager/service-requests/${serviceRequestId}/property-group/${groupId}/contractor/finance/${financeId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteGroupServiceRequestContractorFinance = (serviceRequestId, groupId, financeId) => {
  return api.delete(`/manager/service-requests/${serviceRequestId}/property-group/${groupId}/contractor/finance/${financeId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteGroupServiceRequestContractorFinanceAttachment = (serviceRequestId, groupId, financeId, attachId) => {
  return api.delete(`/manager/service-requests/${serviceRequestId}/property-group/${groupId}/contractor/finance/${financeId}/attachments/${attachId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// Contractor
export const postContractorServiceRequestContractorFinance = (contractorId, serviceRequestId, data, status = null) => {
  return api.post(`/contractor/${contractorId}/service-requests/${serviceRequestId}/contractor/finance${status}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchContractorServiceRequestContractorFinance = (contractorId, serviceRequestId, financeId, data) => {
  return api.patch(`/contractor/${contractorId}/service-requests/${serviceRequestId}/contractor/finance/${financeId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteContractorServiceRequestContractorFinance = (contractorId, serviceRequestId, financeId) => {
  return api.delete(`/contractor/${contractorId}/service-requests/${serviceRequestId}/contractor/finance/${financeId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteContractorServiceRequestContractorFinanceAttachment = (contractorId, serviceRequestId, financeId, attachId) => {
  return api.delete(`/contractor/${contractorId}/service-requests/${serviceRequestId}/contractor/finance/${financeId}/attachments/${attachId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchContractorServiceRequest = (contractorId, id, data) => {
  return api.patch(`/contractor/${contractorId}/service-requests/${id}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchContractorServiceRequestDescriptionCheckBox = (contractorId, id, data) => {
  return api.patch(`/contractor/${contractorId}/service-requests/${id}/description/check-box`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};


export const getContractorServiceRequestGroupAndManager = (contractorId, id, managerId, groupId) => {
  return api.get(`/contractor/${contractorId}/service-requests/${id}/group/${groupId}/manager/${managerId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getAllSrAndCsr = (contractorId, id) => {
  return api.get(`/contractor/${contractorId}/service-requests/${id}/all-sr`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getContractorById = (contractorId, id) => {
  return api.get(`/contractor/${contractorId}/service-requests/${id}/assigned-contractor`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// Property Re-Occuring Service Request


export const getPropertyReOccuringServiceRequestMembers = (id) => {
  return api.get(`/manager/properties/${id}/reoccuring-service-requests/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getPropertyReOccuringServiceRequest = (id) => {
  return api.get(`/manager/properties/${id}/reoccuring-service-requests`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postPropertyReOccuringServiceRequest = (id, data) => {
  return api.post(`/manager/properties/${id}/reoccuring-service-request/new`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchPropertyReOccuringServiceRequest = (id, reoccurId, data) => {
  return api.patch(`/manager/properties/${id}/reoccuring-service-request/${reoccurId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deletePropertyReOccuringServiceRequest = (id, reoccurId) => {
  return api.delete(`/manager/properties/${id}/reoccuring-service-request/${reoccurId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

// Complex Property Re-Occuring Service Request


export const getComplexReOccuringServiceRequestMembers = (groupId, manId) => {
  return api.get(`/manager/property-groups/${groupId}/service-request-group/${manId}/re-occuring/members`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getComplexReOccuringServiceRequest = (groupId, manId) => {
  return api.get(`/manager/property-groups/${groupId}/service-request-group/${manId}/re-occuring`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const postComplexReOccuringServiceRequest = (manId, data) => {
  return api.post(`/manager/property-groups/service-request-group/${manId}/re-occuring/new`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const patchComplexReOccuringServiceRequest = (manId, reoccurId, data) => {
  return api.patch(`/manager/property-groups/service-request-group/${manId}/re-occuring/${reoccurId}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const deleteComplexReOccuringServiceRequest = (groupId, manId, reoccurId) => {
  return api.delete(`/manager/property-groups/${groupId}/service-request-group/${manId}/re-occuring/${reoccurId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};