import { CardContent, Breadcrumbs, Link, Typography, TextField, InputAdornment, OutlinedInput, InputLabel, FormControl, MenuItem, Button, Box, Radio, FormControlLabel, RadioGroup, Icon, CircularProgress } from '@mui/material'
import { useState, useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getManagerLeases, getPropertyFinal, managerRenewalLease, getManagerRenewalLease, cancelrenewal } from "../../../services";
import { useParams } from 'react-router-dom';
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import copy from "copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolTip from '../../../components/ToolTip';

const timePeriod = [
  {
    value: "86400",
    label: "Per Day",
  },
  {
    value: "604800",
    label: "Per Week",
  },
  {
    value: "2592000",
    label: "Per Month",
  },
];
function RenewLease() {
  const params = useParams();
  const moment = extendMoment(Moment);
  const navigate = useNavigate();

  const [property, setProperty] = useState(null);
  const [tenant, setTenant] = useState(null);

  const [beginDate, setBeginDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [rent, setRent] = useState();
  const [rentAmount, setRentAmount] = useState("0");
  const [rentPercent, setRentPercent] = useState(0);
  const [rentCalculation, setRentCalculation] = useState(0);
  const [rentLateFees, setRentLateFees] = useState("5");
  const [rentLateFeesGraceDuration, setRentLateFeesGraceDuration] = useState("0");
  const [period, setPeriod] = useState("");
  const [status, setStatus] = useState(0);
  const [notifiedDate, setNotifiedDate] = useState(null);
  const [monthYearRadio, setMonthYearRadio] = useState("month-to-month");
  const [loading, setLoading] = useState(true);
  const [propId, setPropId] = useState("");


  const [addenda, setaddenda] = useState([]);
  const [addendumtitle, setAddendumtitle] = useState("")
  const [addendumcontent, setAddendumcontent] = useState("")

  useEffect(() => {
    setRentCalculation(() => Number(rent, 10) + Number(rentAmount));
    setRentPercent(() => Number(rentAmount) / 4)
  }, [rentAmount]);

  const handleaddendaChange = (e, index) => {
    const { name, value } = e.target;
    const addendaa = [...addenda];
    addendaa[index][name] = value;
    setaddenda(addendaa);
  }
  const handleAddAddendumClick = () => {
    let newAddendum = [...addenda];
    newAddendum.push({
      title: addendumtitle,
      content: addendumcontent,
    })
    if (addendumtitle !== "" || addendumcontent !== "") {
      setAddendumtitle("");
      setAddendumcontent("");
      setaddenda(newAddendum);
    }
  }
  const removeAddendumclick = (index) => {
    let newAddendum = addenda.filter((value, i) => {
      return index !== i;
    });
    setaddenda(newAddendum);
  }

  const pageLoad = async () => {
    setLoading(true);
    try {
      await getManagerLeases(params.applicationId).then((res) => {
        const getProp = res?.data?.data;
        setProperty(getProp);
        return getPropertyFinal(params.applicationId);
      }).then((res) => {
        const leas = res.data.data;
        let getDetails = { ...leas };
        setPropId(getDetails?.property);
        setRent(getDetails?.rentAmount);
        if (getDetails?.leaseTenants?.length != 0) {
          setTenant(getDetails);
        }
        return getManagerRenewalLease(params.applicationId);
      }).then((res) => {
        let data = res?.data?.data;
        setStatus(data?.status);
        setMonthYearRadio(data?.leaseMonthYearSelect ?? "month-to-month");
        setNotifiedDate(data?.notifiedDate ? moment(data?.managerSignature?.time).format("MMMM DD, YYYY") : "")
        setBeginDate(data?.beginDate ? data?.beginDate : null);
        setExpiryDate(data?.expiryDate ? data?.expiryDate : null);
        setRentAmount(data?.rentAmount ?? 0);
        setPeriod(data?.rentLateFeesDuration ?? 0);
        setRentLateFees(data?.rentLateFeesAmount ?? "5");
        setRentLateFeesGraceDuration(data?.rentLateFeesGraceDuration ?? "0");
        setaddenda(data?.addenda ?? []);
        setLoading(false);
      })
    } catch (err) {
      setLoading(false);
      console.log(`Error${err}`);
    }
  }

  useEffect(() => {
    pageLoad();
  }, []);

  const saveClick = () => {
    let data = {
      "beginDate": beginDate,
      "expiryDate": expiryDate,
      "rentAmount": Number(rentCalculation) || 0,
      "rentLateFeesAmount": Number(rentLateFees) || 0,
      "rentLateFeesGraceDuration": Number(rentLateFeesGraceDuration) || 0,
      "rentLateFeesDuration": period,
      "addenda": addenda,
      "leaseMonthYearSelect": monthYearRadio
    }
    managerRenewalLease(params.applicationId, data).then((res) => {
      setBeginDate(null);
      setExpiryDate(null);
      setRentAmount("0");
      setRentLateFees("5");
      setRentLateFeesGraceDuration("0")
      setaddenda([]);
      setRentPercent(0);
      setRentCalculation(0);
      pageLoad();
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  const previewClick = () => {
    saveClick();
    navigate(`/manager/leases/${params.applicationId}/renewal/preview`)
  }

  const cancelClick = () => {
    cancelrenewal(params.applicationId).then((res) => {
      pageLoad();
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  const isValidAndComplete = (begin, expiry, ren) => {
    if (!begin) return false;
    if (!expiry) return false;
    if (!ren) return false;
    return true;
  }

  const handleMonthYearRadio = (value) => {
    setMonthYearRadio(value);
  }

  const handleBeginDateChange = (date) => {
    setBeginDate(date);

    // Calculate the expiry date with a 1-year gap
    const oneYearLater = new Date(date);
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
    setExpiryDate(oneYearLater);
  };

  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div style={{ padding: '30px' }}>
      <div style={{ display: "flex", justifyContent: "center", alignItem: "center", padding: '10px' }}>
        <Breadcrumbs aria-label="breadcrumb" >
          <Link underline="none" style={{ cursor: "pointer" }} href="/manager/home">
            PROPERTIES
          </Link>
          <Link underline="none" style={{ cursor: "pointer" }} href={`/manager/properties/${property && property?.owner.id ? property?.owner?.id : ""}`}>
            {property && property?.address?.line1 ? property?.address?.line1 : null + " " + property && property?.address?.line2 ? property?.address?.line2 : null + " " + property && property?.address?.unitNumber ? property?.address?.unitNumber : null}
          </Link>
          <Link underline="none" style={{ cursor: "pointer" }} href={`/manager/properties/${propId}/lease`}>
            LEASE
          </Link>
          <Typography color="text.primary">LEASE RENEWAL</Typography>
        </Breadcrumbs>
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItem: "center", textDecoration: "bold", paddingTop: '10px', paddingBottom: '10px' }}>
        <Typography variant="h4">Prepare Lease Renewal Notice</Typography>
      </div>

      <div className='prepare-lease-renewal-head-flex'>
        <div className='prepare-lease-renewal-head-flex-column'>
          <span className='prepare-lease-renewal-head'>Tenant</span>
          <span className='prepare-lease-renewal-head-flex-column-1'>
            <span className='prepare-lease-renewal-head-child'>{tenant && tenant?.leaseTenants[0]?.name?.first ? tenant?.leaseTenants[0].name?.first : null + " " + tenant && tenant?.leaseTenants[0]?.name?.last ? tenant?.leaseTenants[0]?.name?.last : null}</span>
            <ToolTip phone={tenant && tenant?.leaseTenants[0]?.phone?.number ? tenant?.leaseTenants[0].phone?.number : null} email={tenant && tenant?.leaseTenants[0]?.email?.id ? tenant?.leaseTenants[0].email?.id : null} />
          </span>
        </div>
        <div className='prepare-lease-renewal-head-flex-column-2'>
          <span className='prepare-lease-renewal-head'>Property</span>
          <span className='prepare-lease-renewal-head-child'>{property && property?.address?.line1 ? property?.address?.line1 : null}</span>
          <span className='prepare-lease-renewal-head-child'>{property && property?.address?.line2 ? property?.address?.line2 : null + " " + property && property?.address?.unitNumber ? property?.address?.unitNumber : null}</span>
          <span className='prepare-lease-renewal-head-child'>{property && property?.address?.city ? property?.address?.city : null}</span>
          <span className='prepare-lease-renewal-head-child'>{property && property?.address?.state ? property?.address?.state : null + "-" + property && property?.address?.zipCode ? property?.address?.zipCode : null}</span>
        </div>
      </div>
      <div className='prepare-lease-renewal-head-flex-column'>
        <span className='prepare-lease-renewal-head'>Current Lease Specifics</span>
        <span className='prepare-lease-renewal-head-child'>Lease Begin Date : {tenant && tenant?.beginDate ? moment(tenant && tenant?.beginDate ? tenant?.beginDate : "").format("MMM DD, YYYY") : ""}</span>
        <span className='prepare-lease-renewal-head-child'>Lease Expiry Date : {tenant && tenant?.expiryDate ? moment(tenant && tenant?.expiryDate ? tenant?.expiryDate : "").format("MMM DD, YYYY") : 'No Expiry Date'}</span>
        <span className='prepare-lease-renewal-head-child'>Lease Move In Date: {tenant && tenant?.moveInDetails?.moveInDate ? moment(tenant && tenant?.moveInDetails?.moveInDate ? tenant?.moveInDetails?.moveInDate : "").format("MMM DD, YYYY") : 'Unknown Move In Date'}</span>
        <span className='prepare-lease-renewal-head-child'>Rent Amount : ${rent}</span>
      </div>

      {status === 3 ?
        <div style={{ textAlign: "center", paddingTop: '10px', paddingBottom: '10px' }}>
          <p style={{ color: "#DC3545", fontWeight: "bold" }}>Lease Renewal has already been scheduled on {notifiedDate}</p>
        </div>
        : ""}

      <div className='prepare-lease-renewal-padding'>
        <span className='prepare-lease-renewal-head'>Renewal Lease Specifics</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10, alignItems: 'center' }}>
        <FormControlLabel
          control={<Radio />}
          label="Month of Lease"
          onChange={() => { handleMonthYearRadio("month-to-month"); }}
          checked={monthYearRadio === "month-to-month" ? true : false}
          disabled={status !== 3 ? false : true}
        />
        <FormControlLabel
          control={<Radio />}
          label="Year of Lease"
          onChange={() => { handleMonthYearRadio("year-to-year"); }}
          checked={monthYearRadio === "year-to-year" ? true : false}
          disabled={status !== 3 ? false : true}
        />
      </div>
      <div className='prepare-lease-renewal-padding'>
        <span className='prepare-lease-renewal-head'>Renewal Lease Duration</span>
      </div>
      <div style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        flexWrap: 'wrap',
        gap: 10
      }}>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["day", "month", "year"]}
              label="Lease Begin Month"
              value={beginDate}
              onChange={
                handleBeginDateChange}
              renderInput={(params) => <TextField {...params} />}
              disabled={status !== 3 ? false : true}
            />
          </LocalizationProvider>
        </div>
        <span>at 09:00 AM</span>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["day", "month", "year"]}
              label="Lease Expiry Month"
              value={expiryDate}
              onChange={(newValue) => {
                setExpiryDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              disabled={status !== 3 ? false : true}
            />
          </LocalizationProvider>
        </div>
        <span>noon</span>
      </div>
      <div className='prepare-lease-renewal-padding'>
        <span className='prepare-lease-renewal-head'>Rent</span>
      </div>
      <div style={{
        flexDirection: "row",
        display: "flex",
        flexWrap: 'wrap',
        alignItems: "center",
        marginTop: "15px",
        gap: 10
      }}>
        <FormControl>
          <InputLabel htmlFor="outlined-adornment-amount">Rent Increase</InputLabel>
          <OutlinedInput
            variant="outlined"
            fullWidth={true}
            value={rentAmount}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
              setRentAmount(numericValue);
            }}
            inputProps={{
              inputMode: 'numeric', // Restrict input to numeric characters
              pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
            }}
            disabled={status !== 3 ? false : true}
            startAdornment={<InputAdornment position="start"><Icon style={{ color: 'black' }}>$</Icon></InputAdornment>}
            label="Rent Increase"
          />
        </FormControl>
        <div style={{ fontSize: 16 }}>
          <b>{isNaN(rentPercent) ? 0 : rentPercent} % Decrease</b> from the <b>Current Rent ${isNaN(rent) ? 0 : rent}</b> making the<b> New Rent ${isNaN(rentCalculation) ? 0 : rentCalculation}</b>
        </div>
      </div>

      <div className='prepare-lease-renewal-padding'>
        <span className='prepare-lease-renewal-head'>Rent Late Fees</span>
      </div>
      <span style={{ fontSize: 15 }}> Rent is due in advance by the 1st day of each month</span>
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: 'wrap',
        gap: 10,
        alignItems: "center",
        marginTop: "15px"
      }}>
        <div style={{ flex: 1 }}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="outlined-adornment-amount">Late Payment Fees</InputLabel>
            <OutlinedInput
              variant="outlined"
              fullWidth={true}
              value={rentLateFees}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                setRentLateFees(numericValue);
              }}
              inputProps={{
                inputMode: 'numeric', // Restrict input to numeric characters
                pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
              }}
              disabled={status !== 3 ? false : true}
              startAdornment={<InputAdornment position="start"><Icon style={{ color: 'black' }}>$</Icon></InputAdornment>}
              label="Late Payment Fees"
            />
          </FormControl>
        </div>
        <div style={{ flex: 1 }}>
          <FormControl fullWidth>
            <TextField
              label="Late Fees Time Period"
              select
              variant="outlined"
              value={period}
              onChange={(e) => {
                setPeriod(Number(e.target.value));
              }}
              fullWidth={true}
              disabled={status !== 3 ? false : true}
            >
              {timePeriod.map((period) => (
                <MenuItem key={period.value} value={period.value}>
                  {period.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
        <div style={{ flex: 1 }}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="outlined-adornment-amount">Grace Period Ends on Day</InputLabel>
            <OutlinedInput
              variant="outlined"
              value={rentLateFeesGraceDuration}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                setRentLateFeesGraceDuration(numericValue);
              }}
              inputProps={{
                inputMode: 'numeric', // Restrict input to numeric characters
                pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
              }}
              disabled={status !== 3 ? false : true}
              endAdornment={<InputAdornment position="end"><span style={{ color: 'black', fontSize: 17 }}>/ per month</span></InputAdornment>}
              label="Grace Period Ends on Day"
            />
          </FormControl>
        </div>
      </div>
      <span style={{ fontSize: 15 }}>  Late Fees will be charged from day {isNaN(Number(rentLateFeesGraceDuration) + 1) ? 0 : Number(rentLateFeesGraceDuration) + 1} of the month</span>
      <div className='prepare-lease-renewal-padding'>
        <span className='prepare-lease-renewal-head'>Addenda</span>
      </div>
      <div>
        {addenda.map((addendum, index) => (
          <div key={index}>
            <div style={{ marginBottom: '10px' }}>
              <TextField
                name="title"
                variant="outlined"
                required
                type="text"
                fullWidth={true}
                label="Enter New Addendum Title"
                value={addendum.title}
                onChange={e => handleaddendaChange(e, index)}
                disabled={status !== 3 ? false : true}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <TextField
                name="content"
                label="Enter New Addendum Content"
                variant="outlined"
                fullWidth={true}
                multiline
                rows={5}
                value={addendum.content}
                onChange={e => handleaddendaChange(e, index)}
                disabled={status !== 3 ? false : true}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
              <Button variant="contained" onClick={() => { removeAddendumclick(index) }} color="error"
                disabled={status !== 3 ? false : true}>Delete</Button>
            </div>
          </div>
        ))}
        <Box>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              name="title"
              variant="outlined"
              required
              type="text"
              fullWidth={true}
              value={addendumtitle}
              label="Enter New Addendum Title"
              onChange={e => { setAddendumtitle(e.target.value) }}
              disabled={status !== 3 ? false : true}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              name="content"
              label="Enter New Addendum Content"
              variant="outlined"
              fullWidth={true}
              multiline
              value={addendumcontent}
              rows={5}
              onChange={e => { setAddendumcontent(e.target.value) }}
              disabled={status !== 3 ? false : true}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '30px' }}>
            <Button variant="contained" onClick={handleAddAddendumClick} color="primary"
              disabled={status !== 3 ? false : true}>ADD ADDENDUM</Button>
          </div>
        </Box>
      </div>

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "right" }} >
        <div style={{ display: "flex", flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
          {status !== 3 ?
            <div>
              <Button variant="contained" style={{ backgroundColor: "#8BC34A" }}
                onClick={saveClick}>Save</Button>
            </div> :
            <div>
              <Button variant="contained" color="error"
                onClick={cancelClick}>CANCEL RENEWAL</Button>
            </div>}
          {status !== 3 ?
            <div style={{ paddingLeft: "10px" }}>
              <Button variant="contained"
                onClick={previewClick}
                disabled={!isValidAndComplete(beginDate, expiryDate, rentAmount)}>Preview lease Renewal Notice</Button>
            </div> :
            <div style={{ paddingLeft: "10px" }}>
              <Button variant="contained"
                onClick={() => { navigate(`/manager/leases/${params.applicationId}/renewal/preview`) }}>VIEW LEASE RENEWAL</Button>
            </div>}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  )
}
export default RenewLease;