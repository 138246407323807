import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Requestemailverification,
  createTenantLeaseApplication,
  getApiBaseURL,
  getCurrentUser,
  getSingleProperty,
  tenantRegisterOrLogin,
} from "../../../services";
import { setUser } from "../../../redux/slices/userSlice";
import { Box, Button, CircularProgress, Fab, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import { DirectionsCarOutlined, LocalLaundryServiceOutlined } from "@mui/icons-material";
import { formatPhoneNumber } from "../../../components/phoneNumberFormat";

function PropertyViewPage() {
  const navigate = useNavigate();
  let params = useParams();

  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  const [property, setProperty] = useState();
  const [propertyLoading, setPropertyLoading] = useState(true);
  const [hasCoApplicant, setHasCoApplicant] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [coFirstName, setCoFirstName] = useState("");
  const [coFirstNameError, setCoFirstNameError] = useState("");
  const [coLastName, setCoLastName] = useState("");
  const [coLastNameError, setCoLastNameError] = useState("");
  const [coPhone, setCoPhone] = useState("");
  const [coPhoneError, setCoPhoneError] = useState("");
  const [coEmail, setCoEmail] = useState("");
  const [coEmailError, setCoEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const phoneCountryCode = "+1";

  useEffect(() => {
    setPropertyLoading(true);
    getSingleProperty(params.propertyId)
      .then((response) => {
        setProperty(response.data.data);
        setPropertyLoading(false);
      }).catch((err) => {
        console.log(err);
        setPropertyLoading(false);
      });
  }, [setProperty, params.propertyId]);

  useEffect(() => {
    if (user) {
      setFirstName(user?.name?.first);
      setLastName(user?.name?.last);
      setPhone(user?.phone?.number);
      setEmail(user?.email?.id);
    }
  }, [user]);

  const handleChange = (field, value) => {
    switch (field) {
      case "firstName":
        setFirstName(value);
        setFirstNameError(value.trim() === "" ? "First name cannot be empty" : "");
        break;
      case "lastName":
        setLastName(value);
        setLastNameError(value.trim() === "" ? "Last name cannot be empty" : "");
        break;
      case "phone":
        setPhone(value);
        setPhoneError(value.trim() === "" ? "Phone number cannot be empty" : "");
        break;
      case "email":
        setEmail(value);
        setEmailError(value.trim() === "" ? "Email cannot be empty" : !isValidEmail(value) ? "Enter a valid email" : "");
        break;
      case "coFirstName":
        setCoFirstName(value);
        setCoFirstNameError(value.trim() === "" ? "First name cannot be empty" : "");
        break;
      case "coLastName":
        setCoLastName(value);
        setCoLastNameError(value.trim() === "" ? "Last name cannot be empty" : "");
        break;
      case "coPhone":
        setCoPhone(value);
        setCoPhoneError(value.trim() === "" ? "Phone number cannot be empty" : "");
        break;
      case "coEmail":
        setCoEmail(value);
        setCoEmailError(value.trim() === "" ? "Email cannot be empty" : !isValidEmail(value) ? "Enter a valid email" : "");
        break;
      case "password":
        setPassword(value);
        setPasswordError(value.trim() === "" ? "Password cannot be empty" : value.trim().length < 8 ? "Atleast 8 characters required" : "");
        break;
      default:
        break;
    }
  };

  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  };

  var config = ""
  const handleApplyClick = (e) => {
    e.preventDefault();

    console.log("Starting tenant registration.");
    if (!user) {
      tenantRegisterOrLogin(
        firstName,
        lastName,
        phoneCountryCode,
        phone,
        email,
        password
      ).then((response) => {
        sessionStorage.setItem("authToken", response.data.data.token);
        let applicationInvitees = [];
        if (hasCoApplicant) {
          applicationInvitees.push({
            firstName: coFirstName,
            lastName: coLastName,
            phoneCountryCode: phoneCountryCode,
            phoneNumber: coPhone,
            email: coEmail,
          })
        }
        console.log("Creating tenant lease application");
        return createTenantLeaseApplication(property.id, applicationInvitees);
      }).then((response) => {
        sessionStorage.setItem("ongoingApplication", response.data.data.id);
        return getCurrentUser();
      }).then((response) => {
        dispatch(setUser(response.data.data));
        let responseData = response.data.data;
        if (responseData.email.verified === false) {
          const authToken = sessionStorage.getItem("authToken");
          if (authToken !== undefined) {
            config = {
              headers: { Authorization: `Bearer ${authToken}` },
            };
            Requestemailverification({}, config)
              .then((response) => {
                if (response.data.data) {
                  navigate(`/request-email-verification`);
                }
              })
          }
        } else {
          navigate("/tenant/dashboard");
        }
      }).catch((err) => { console.log(err); });
    } else {
      let applicationInvitees = [];
      if (hasCoApplicant) {
        applicationInvitees.push({
          firstName: coFirstName,
          lastName: coLastName,
          phoneCountryCode: phoneCountryCode,
          phoneNumber: coPhone,
          email: coEmail,
        })
      }
      createTenantLeaseApplication(property.id, applicationInvitees).then((response) => {
        sessionStorage.setItem("ongoingApplication", response.data.data.id);
        console.log("Redirecting to tenant dashboard");
        navigate("/tenant/dashboard");
      }).catch((err) => { console.log(err); });
    }
  };

  const submitValidation = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      phone === "" ||
      email === "" ||
      !isValidEmail(email) ||
      (!user && password === "" || !user && password.length < 8)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="start-application-page-container">
      <div className="start-application-page-container-width">
        {propertyLoading && (
          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ height: 70, width: 70 }} />
          </Box>
        )}
        {!propertyLoading && (
          <>
            <div>
              <p className="start-application-heading-title">Rental Application</p>
            </div>
            <div className="start-application-section-1-flex">
              <div className="start-application-flex-grow">
                <img
                  src={
                    property.images.defaultImageId
                      ? `${getApiBaseURL()}/properties/${property.id}/images/default`
                      : "/images/default-property-picture.jpeg"
                  }
                  alt="Set of buildings"
                  className="start-application-image-size"
                />
              </div>
              <div className="start-application-flex-grow">
                <div className="start-application-section-1-details-flex">
                  <div className="start-application-section-1-details-address-text">
                    {property?.address?.line1}
                  </div>
                  <div className="start-application-section-1-details-address-text">
                    {property?.address?.line2}
                  </div>
                  <div className="start-application-section-1-details-address-text">
                    {property?.address?.city}, {property?.address?.state}
                  </div>
                  {property?.numberOfBedrooms ? (
                    <div className="start-application-section-1-details-icon-flex">
                      <BedOutlinedIcon color="primary" sx={{ fontSize: 30 }} />
                      <span>{property?.numberOfBedrooms} bedrooms</span>
                    </div>
                  ) : ("")}
                  {property?.numberOfBathrooms ? (
                    <div className="start-application-section-1-details-icon-flex">
                      <BathtubOutlinedIcon color="primary" sx={{ fontSize: 30 }} />
                      <span>{property?.numberOfBathrooms} bathrooms</span>
                    </div>
                  ) : ("")}
                  {property?.numberOfParkingSpaces ? (
                    <div className="start-application-section-1-details-icon-flex">
                      <DirectionsCarOutlined color="primary" sx={{ fontSize: 30 }} />
                      <span>
                        {property?.numberOfParkingSpaces} parking spaces
                      </span>
                    </div>
                  ) : ("")}
                  {property?.laundryFacility === "IN_UNIT" ? (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", gap: 10 }}>
                      <LocalLaundryServiceOutlined color="primary" sx={{ fontSize: 30 }} />
                      <span>Laundry available</span>
                    </div>
                  ) : ("")}
                  <p className="start-application-section-1-details-rent-amount">{`$${parseFloat(property?.rentAmount).toFixed(2)} / month`}</p>
                </div>
              </div>
            </div>
            <div className="start-application-section-2-flex">
              <div className="start-application-flex-grow">
                <p className="start-application-sub-heading-title">Applicant Info</p>
                <div>
                  <TextField
                    label="First Name*"
                    margin="dense"
                    variant="outlined"
                    sx={{ mb: 3 }}
                    fullWidth={true}
                    value={firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    error={firstNameError ? true : false}
                    helperText={firstNameError && firstNameError}
                  />
                </div>
                <div>
                  <TextField
                    label="Last Name*"
                    margin="dense"
                    variant="outlined"
                    sx={{ mb: 3 }}
                    fullWidth={true}
                    value={lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    error={lastNameError ? true : false}
                    helperText={lastNameError && lastNameError}
                  />
                </div>
                <div>
                  <TextField
                    label="Mobile*"
                    margin="dense"
                    variant="outlined"
                    sx={{ mb: 3 }}
                    fullWidth={true}
                    value={phone}
                    onChange={(e) => {
                      let formatedPhone = formatPhoneNumber(e.target.value);
                      handleChange("phone", formatedPhone);
                    }}
                    error={phoneError ? true : false}
                    helperText={phoneError && phoneError}
                  />
                </div>
                <div>
                  <TextField
                    label="Email*"
                    margin="dense"
                    variant="outlined"
                    sx={{ mb: 3 }}
                    fullWidth={true}
                    value={email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    error={emailError ? true : false}
                    helperText={emailError && emailError}
                  />
                </div>
                {!user && (
                  <div>
                    <TextField
                      label="Password*"
                      margin="dense"
                      variant="outlined"
                      sx={{ mb: 3 }}
                      fullWidth={true}
                      value={password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      error={passwordError ? true : false}
                      helperText={passwordError ? passwordError : "Enter atleast 8 characters."}
                    />
                  </div>
                )}
              </div>
              <div className="start-application-flex-grow">
                {!hasCoApplicant ? (
                  <div className="start-application-add-coapplicant-flex">
                    <Fab
                      size="small"
                      color="warning"
                      aria-label="add"
                      onClick={(e) => {
                        e.preventDefault();
                        setHasCoApplicant(true);
                      }}
                    >
                      <AddIcon sx={{ fontSize: 30 }} />
                    </Fab>
                    <div className="start-application-add-applicant-text">Add Co-Applicant</div>
                  </div>
                ) : ("")}
                {hasCoApplicant && (
                  <div>
                    <div className="start-application-coapplicant-flex">
                      <p className="start-application-sub-heading-title">
                        Add Co-Applicant Info
                      </p>
                      <div>
                        <Fab
                          size="small"
                          color="warning"
                          aria-label="add"
                          onClick={(e) => {
                            e.preventDefault();
                            setHasCoApplicant(false);
                          }}
                        >
                          <RemoveIcon sx={{ fontSize: 30 }} />
                        </Fab>
                      </div>
                    </div>
                    <div>
                      <TextField
                        label="First Name"
                        margin="dense"
                        variant="outlined"
                        sx={{ mb: 3 }}
                        fullWidth={true}
                        value={coFirstName}
                        onChange={(e) => handleChange("coFirstName", e.target.value)}
                        error={coFirstNameError ? true : false}
                        helperText={coFirstNameError && coFirstNameError}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Last name"
                        margin="dense"
                        variant="outlined"
                        sx={{ mb: 3 }}
                        fullWidth={true}
                        value={coLastName}
                        onChange={(e) => handleChange("coLastName", e.target.value)}
                        error={coLastNameError ? true : false}
                        helperText={coLastNameError && coLastNameError}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Mobile*"
                        margin="dense"
                        variant="outlined"
                        sx={{ mb: 3 }}
                        fullWidth={true}
                        value={coPhone}
                        onChange={(e) => {
                          let formatedPhone = formatPhoneNumber(e.target.value);
                          handleChange("coPhone", formatedPhone);
                        }}
                        error={coPhoneError ? true : false}
                        helperText={coPhoneError && coPhoneError}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Email"
                        margin="dense"
                        variant="outlined"
                        sx={{ mb: 3 }}
                        fullWidth={true}
                        value={coEmail}
                        onChange={(e) => handleChange("coEmail", e.target.value)}
                        error={coEmailError ? true : false}
                        helperText={coEmailError && coEmailError}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="warning"
                onClick={handleApplyClick}
                sx={{ fontSize: 15 }}
                disabled={submitValidation()}
              >
                START APPLICATION
              </Button>
            </div>
          </>
        )}
      </div>
    </div >
  );
}

export default PropertyViewPage;
