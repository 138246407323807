import { useState, useEffect } from "react";
import { Button, TextField, Typography, MenuItem, FormControl, InputLabel, Select, Grid, CircularProgress, InputAdornment, Tooltip } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserProfile, patchUserProfile, patchManagerProfile, getManagerProfile, postUserProfilePicture, deleteUserProfilePicture, getApiBaseURL } from "../../services";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "../../components/phoneNumberFormat";
import { states } from "../../helpers";
import { MenuProps } from "../../components/MaterialUiSelectMenuStyle";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function UserProfile({ loadProfilePicture, setProfileImage }) {
    const user = useSelector((state) => state.user.value);

    const [images, setImages] = useState(null);
    const [ProfileInput, setProfileInput] = useState({
        firstName: {
            value: "",
            error: {
                message: "",
            },
        },
        lastName: {
            value: "",
            error: {
                message: "",
            },
        },
        email: {
            value: "",
            error: {
                message: "",
            },
        },

        phoneNumber: {
            value: "",
            error: {
                message: "",
            },
        },
    });

    const [managerProfile, setmanagerProfile] = useState({
        legalEntityName: {
            value: "",
        },
        legalEntityAddressLine1: {
            value: "",
        },
        legalEntityAddressLine2: {
            value: "",
        },
        legalEntityAddressCity: {
            value: "",
        },
        legalEntityAddressState: {
            value: "",
        },
        legalEntityAddressZipCode: {
            value: "",
        },
    });

    // loading
    const [loading, setLoading] = useState(false);

    // Tabs
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // States
    const renderStateOptions = () => {
        return states.map((item, index) => {
            return (
                <MenuItem key={index} value={item.value}>
                    {item.label}
                </MenuItem>
            );
        });
    };

    // phone NumberFormat
    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        ProfileInput.phoneNumber.value = formattedPhoneNumber;
    };

    // onchange User
    const onChangeHandler = (event, key) => {
        let change = { ...ProfileInput }
        if (key === "firstName") {
            change.firstName.value = event.target.value

        }
        if (key === "lastName") {
            change.lastName.value = event.target.value
        }
        if (key === "phoneNumber") {
            change.phoneNumber.value = event.target.value
        }
        setProfileInput(change)
    };

    // onchange Manager
    const onChangeManager = (event, key) => {
        let change = { ...managerProfile }
        if (key === "legalEntityName") {
            change.legalEntityName.value = event.target.value
        }
        if (key === "legalEntityAddressLine1") {
            change.legalEntityAddressLine1.value = event.target.value
        }
        if (key === "legalEntityAddressLine2") {
            change.legalEntityAddressLine2.value = event.target.value
        }
        if (key === "legalEntityAddressCity") {
            change.legalEntityAddressCity.value = event.target.value
        }
        if (key === "legalEntityAddressState") {
            change.legalEntityAddressState.value = event.target.value
        }
        if (key === "legalEntityAddressZipCode") {
            change.legalEntityAddressZipCode.value = event.target.value
        }
        setmanagerProfile(change)
    };

    let config = "";
    const pagemanageruserLoad = async (e) => {
        try {
            setLoading(true);
            const authToken = sessionStorage.getItem("auth_token");
            config = {
                headers: { Authorization: `Bearer ${authToken}` },
            };
            await getUserProfile().then((response) => {
                const getuserDetails = { ...ProfileInput };
                getuserDetails.email.value = response.data.data.email.id;
                getuserDetails.firstName.value = response.data.data.name.first;
                getuserDetails.lastName.value = response.data.data.name.last;
                getuserDetails.phoneNumber.value = formatPhoneNumber(response.data.data.phone.number);
                setProfileInput(getuserDetails);

                if (response?.data?.data?.profilePictureFile) {
                    const authToken = sessionStorage.getItem("authToken");
                    config = {
                        headers: { Authorization: `Bearer ${authToken}` },
                    };
                    fetch(`${getApiBaseURL()}/user/profile/picture`, config)
                        .then(res => {
                            return res.blob();
                        })
                        .then(blob => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement('a');
                                link.href = imageDataUrl;
                                if (
                                    blob.type == "image/png" ||
                                    blob.type == "image/gif" ||
                                    blob.type == "image/jpeg" ||
                                    blob.type == "image/svg" ||
                                    blob.type == "image/jpg"
                                ) {
                                    setImages(imageDataUrl);
                                }
                            };
                        });
                } else {
                    setImages(null);
                }
                if (response?.data?.data?.manager) return getManagerProfile();
            }).then((res) => {
                const getmanagerDetails = { ...managerProfile }
                getmanagerDetails.legalEntityName.value = res?.data?.data?.legalEntity?.name
                getmanagerDetails.legalEntityAddressLine1.value = res?.data?.data?.legalEntity?.address?.line1
                getmanagerDetails.legalEntityAddressLine2.value = res?.data?.data?.legalEntity?.address?.line2
                getmanagerDetails.legalEntityAddressCity.value = res?.data?.data?.legalEntity?.address?.city
                getmanagerDetails.legalEntityAddressState.value = res?.data?.data?.legalEntity?.address?.state
                getmanagerDetails.legalEntityAddressZipCode.value = res?.data?.data?.legalEntity?.address?.zipCode
                setmanagerProfile(getmanagerDetails)
                setLoading(false);
            })
        } catch (err) {
            setLoading(false);
            console.log("Error:", err);
        }
    };

    useEffect(() => {
        pagemanageruserLoad();
    }, [])

    const handleImagesAdd = async (e) => {
        let file = e.target.files[0];
        let form = new FormData();
        form.append("profile-picture", file);
        await postUserProfilePicture(form).then((res) => {
            pagemanageruserLoad();
            toast.success("Profile Picture Successfully Updated!");
            loadProfilePicture();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };

    const handleRemoveProfile = async () => {
        await deleteUserProfilePicture().then((res) => {
            pagemanageruserLoad();
            toast.success("Profile Picture Successfully Deleted");
            setProfileImage(null);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const managerSave = async (event) => {
        let datas = {
            "legalEntityName": managerProfile.legalEntityName.value,
            "legalEntityAddressLine1": managerProfile.legalEntityAddressLine1.value,
            "legalEntityAddressLine2": managerProfile.legalEntityAddressLine2.value,
            "legalEntityAddressCity": managerProfile.legalEntityAddressCity.value,
            "legalEntityAddressState": managerProfile.legalEntityAddressState.value,
            "legalEntityAddressZipCode": managerProfile.legalEntityAddressZipCode.value,
        }
        await patchManagerProfile(datas).then((res) => {
            toast.success("Manager Profile Successfully Updated!");
            pagemanageruserLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };

    const Save = async (event) => {
        let data = {
            "firstName": ProfileInput.firstName.value,
            "lastName": ProfileInput.lastName.value,
            "phoneCountryCode": "+1",
            "phoneNumber": ProfileInput.phoneNumber.value
        }
        await patchUserProfile(data)
            .then((res) => {
                toast.success("User Profile Successfully Updated!");
                pagemanageruserLoad();
            }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ m: 2 }}>
            <Grid item lg={3} md={6} sm={9} xs={10} sx={{ boxShadow: '0 10px 20px rgba(53, 60, 115, 0.15)', borderRadius: '10px', p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontFamily: "Times New Roman",
                                fontSize: 30,
                                textAlign: "center",
                            }}
                            variant="h4"
                        >
                            Profile
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {images ? (
                                <div className="profile-image-container">
                                    <label htmlFor="uploadBtn" style={{ cursor: 'pointer' }}>
                                        <img src={images} loading="lazy" alt="images" className="profile-image" />
                                    </label>
                                    <div className="overlay">
                                        <div className="profile-image-container-delete-btn" title="Delete">
                                            <DeleteIcon color="error" sx={{ height: 30, width: 30, cursor: 'pointer' }} onClick={() => { handleRemoveProfile() }} />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <label htmlFor="uploadBtn" style={{ cursor: 'pointer' }}>
                                    <PersonIcon style={{ width: "150px", height: "150px", color: "#ff805d" }} />
                                </label>
                            )}
                            <div style={{ display: 'none' }}>
                                <input type="file" id="uploadBtn" accept="image/*"
                                    onChange={handleImagesAdd}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                            {user &&
                                <label style={{ textAlign: "center", fontSize: "20px", fontWeight: "500" }}>{`${user.name.first} ${user.name.last}`}</label>
                            }
                            {user?.email?.verified ? (
                                <Tooltip title={"Verified"} placement="top">
                                    <VerifiedUserIcon sx={{ color: "#ff805d" }} />
                                </Tooltip>
                            ) : (
                                <Tooltip title={"Unverified"} placement="top">
                                    <RemoveModeratorIcon sx={{ color: "#ff805d" }} />
                                </Tooltip>
                            )}

                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <p style={{ textAlign: "center", color: "rgba(0,0,0,.54)", fontSize: "14px" }}>
                            Account Type:{" "}{user?.manager ? "Manager" : ""}{user?.tenant ? "Tenant" : ""}
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <Box sx={{ width: '100%', px: 2 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={value} onChange={handleChange}
                                    aria-label="basic tabs example"
                                    sx={{
                                        "& .css-heg063-MuiTabs-flexContainer": {
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                        },
                                        "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
                                            fontSize: 15,
                                        },
                                        "& .css-1aquho2-MuiTabs-indicator": {
                                            backgroundColor: "#ed6c02",
                                            fontSize: 15,
                                        },
                                        "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                                            color: "#ed6c02",
                                            fontSize: 15,
                                        }
                                    }}
                                >
                                    <Tab label="User" {...a11yProps(0)} />
                                    {user?.manager ? (
                                        <Tab label="Manager" {...a11yProps(1)} />
                                    ) : ("")}
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            fullWidth={true}
                                            label="Email"
                                            variant="outlined"
                                            type="email"
                                            disabled={true}
                                            value={ProfileInput.email.value}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} lg={6} md={6}>
                                                <TextField
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    label="First Name"
                                                    type="text"
                                                    onChange={(event) =>
                                                        onChangeHandler(event, "firstName")
                                                    }
                                                    value={ProfileInput.firstName.value}
                                                    error={ProfileInput.firstName.value === ""}
                                                    helperText={ProfileInput.firstName.value === ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6} md={6}>
                                                <TextField
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    label="Last Name"
                                                    type="text"
                                                    onChange={(event) =>
                                                        onChangeHandler(event, "lastName")
                                                    }
                                                    value={ProfileInput.lastName.value}
                                                    error={ProfileInput.lastName.value === ""}
                                                    helperText={ProfileInput.lastName.value === ""}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            label="Phone"
                                            variant="outlined"
                                            fullWidth={true}
                                            type="text"
                                            onChange={(event) => {
                                                onChangeHandler(
                                                    event,
                                                    "phoneNumber"
                                                ); handleInput(event)
                                            }}
                                            value={ProfileInput.phoneNumber.value}
                                            error={ProfileInput.phoneNumber.value === ""}
                                            helperText={ProfileInput.phoneNumber.value === ""}
                                            InputProps={{ startAdornment: (<InputAdornment position="start">+1</InputAdornment>) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                sx={{ fontSize: 15 }}
                                                variant="contained"
                                                type="submit"
                                                color="warning"
                                                onClick={(event) => Save(event)}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <label>Company Details</label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            sx={{ width: 1 }}
                                            label="Company Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            type="text"
                                            onChange={(event) => onChangeManager(event, "legalEntityName")}
                                            value={managerProfile.legalEntityName.value}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <label>Company Address</label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            sx={{ width: 1 }}
                                            label="Address Line 1"
                                            variant="outlined"
                                            fullWidth={true}
                                            type="text"
                                            onChange={(event) => onChangeManager(event, "legalEntityAddressLine1")}
                                            value={managerProfile.legalEntityAddressLine1.value}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            sx={{ width: 1 }}
                                            label="Address Line 2"
                                            variant="outlined"
                                            fullWidth={true}
                                            type="text"
                                            onChange={(event) => onChangeManager(event, "legalEntityAddressLine2")}
                                            value={managerProfile.legalEntityAddressLine2.value}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            sx={{ width: 1 }}
                                            label="City"
                                            variant="outlined"
                                            fullWidth={true}
                                            type="text"
                                            onChange={(event) => onChangeManager(event, "legalEntityAddressCity")}
                                            value={managerProfile.legalEntityAddressCity.value}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="State"
                                                MenuProps={MenuProps}
                                                onChange={(event) => onChangeManager(event, "legalEntityAddressState")}
                                                value={managerProfile.legalEntityAddressState.value}
                                            >
                                                {renderStateOptions()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <TextField
                                            sx={{ width: 1 }}
                                            label="ZipCode"
                                            variant="outlined"
                                            fullWidth={true}
                                            type="text"
                                            onChange={(event) => onChangeManager(event, "legalEntityAddressZipCode")}
                                            value={managerProfile.legalEntityAddressZipCode.value}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <div style={{ padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                sx={{ fontSize: 15 }}
                                                variant="contained"
                                                type="submit"
                                                color="warning"
                                                onClick={(event) => managerSave(event)}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </Grid>
        </Grid >
    );
}

export default UserProfile;