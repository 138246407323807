import React, { useState, useEffect } from 'react'
import { getFinanceLastSelectedDate, getManagerComplexIndividualFinances, postFinanceLastSelectedDate } from '../../../../services'
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { CSVLink } from "react-csv";
import ComplexServiceRequestTabs from './complexserviceRequestTab';
import { Box, CardContent, CircularProgress, Dialog, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FinanceCategoriesUppercase from '../../../../components/financeCategoriesUppercase';

const ComplexFinances = () => {
    const params = useParams();
    const navigate = useNavigate();
    const moment = extendMoment(Moment);

    const [data, setData] = useState(null);
    const [categoryId, setCategoryId] = useState("");
    const [openDetails, setOpenDetails] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [startDate, setStartDate] = useState(new Date("2023-01-01"));
    const [endDate, setEndDate] = useState(null);
    const [openTotalDetails, setOpenTotalDetails] = useState(false);
    const [totalTransctions, setTotalTransactions] = useState([]);
    const [allTransactions, setAllTransactions] = useState([]);
    // loading
    const [loading, setLoading] = useState(true);

    const pageLoad = async () => {
        try {
            setLoading(true);
            let lastSelected;
            await getFinanceLastSelectedDate().then((response) => {
                lastSelected = response?.data?.data;
                if (lastSelected && lastSelected.length !== 0) {
                    setStartDate(lastSelected[0].FinancesStartDate);
                    setEndDate(lastSelected[0].FinancesEndDate);
                }
            })
            const timeBegin = lastSelected && lastSelected.length !== 0 ? Date.parse(lastSelected[0].FinancesStartDate) : Date.parse(startDate);
            const timeEnd = lastSelected && lastSelected.length !== 0 ? Date.parse(lastSelected[0].FinancesEndDate) : Date.parse(endDate);

            await getManagerComplexIndividualFinances(params.managerId, params.groupId, `?timeBegin=${timeBegin}&timeEnd=${timeEnd}`).then((res) => {
                let data = res?.data?.data;
                setData(data);
                const allTran = data?.groups.flatMap(e => [
                    ...e.categories.RENTAL_INCOME.transactions,
                    ...e.categories.OTHER_INCOME.transactions,
                    ...e.categories.PARKING_INCOME.transactions,
                    ...e.categories.LAUNDRY_SERVICE_INCOME.transactions,
                    ...e.categories.SHORT_TERM_RENTS_INCOME.transactions,
                    ...e.categories.LATE_FEES_INCOME.transactions,
                    ...e.categories.STORAGE_INCOME.transactions,
                    ...e.categories.MISC_INTEREST_INCOME.transactions,
                    ...e.categories.ADVERTISING_EXPENSE.transactions,
                    ...e.categories.CLEANING_AND_MAINTENANCE_EXPENSE.transactions,
                    ...e.categories.INSURANCE_EXPENSE.transactions,
                    ...e.categories.LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE.transactions,
                    ...e.categories.MANAGEMENT_EXPENSE.transactions,
                    ...e.categories.MEALS_EXPENSE.transactions,
                    ...e.categories.OTHER_EXPENSE.transactions,
                    ...e.categories.REPAIRS_EXPENSE.transactions,
                    ...e.categories.SUPPLIES_EXPENSE.transactions,
                    ...e.categories.TAXES.transactions,
                    ...e.categories.TRAVEL_EXPENSE.transactions,
                    ...e.categories.UTILITIES_EXPENSE.transactions,
                    ...e.categories.TRAVEL_MILES.transactions,
                    ...e.categories.CAPITAL_EXPENSE.transactions,
                    ...e.categories.MORTGAGES_AND_LOANS_EXPENSE.transactions]
                    .map(h => ({
                        Date: h.date ? moment(h.date).format('MMM D, YYYY') : "",
                        description: h.description,
                        amount: h.amount,
                        Property: h.propertyName,
                        Type: h.type,
                    })));
                setAllTransactions(allTran);
            })
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        pageLoad();
    }, []);

    const handleDetails = (transactions, id) => {
        setTransactions(transactions);
        setCategoryId(id);
        const categories = {
            "RENTAL_INCOME": "Rental Income",
            "OTHER_INCOME": "Other Income",
            "PARKING_INCOME": "Parking Income",
            "LAUNDRY_SERVICE_INCOME": "Laundry Service Income",
            "SHORT_TERM_RENTS_INCOME": 'Short Term Rents Income',
            "LATE_FEES_INCOME": 'Late Fees Income',
            "STORAGE_INCOME": 'Storage Income',
            "MISC_INTEREST_INCOME": 'Misc Interest Income',
            "TAXES": "Taxes",
            "INSURANCE_EXPENSE": "Insurance Expense",
            "ADVERTISING_EXPENSE": "Advertising Expense",
            "CLEANING_AND_MAINTENANCE_EXPENSE": "Cleaning and Maintenance Expense",
            "LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE": "Legal and Professional Services Expense",
            "MANAGEMENT_EXPENSE": "Management Expense",
            "MEALS_EXPENSE": "Meals Expense",
            "REPAIRS_EXPENSE": "Repairs Expense",
            "SUPPLIES_EXPENSE": "Supplies Expense",
            "TRAVEL_EXPENSE": "Travel Expense",
            "UTILITIES_EXPENSE": "Utilities Expense",
            "OTHER_EXPENSE": "Other Expense",
            "TRAVEL_MILES": "Travel Miles",
            "CAPITAL_EXPENSE": 'Capital Expense',
            "MORTGAGES_AND_LOANS_EXPENSE": 'Mortgages and Loans Expense',
            "SECURITY_DEPOSITS": 'Security Deposits',
        };
        if (transactions.length !== 0) {
            setOpenDetails(true);
        } else {
            toast.info(`No transactions for ${categories[id] || id}`);
        }
    };


    const details = (transactions, id, total) => {
        const categories = {
            "RENTAL_INCOME": "Rental Income",
            "OTHER_INCOME": "Other Income",
            "PARKING_INCOME": "Parking Income",
            "LAUNDRY_SERVICE_INCOME": "Laundry Service Income",
            "SHORT_TERM_RENTS_INCOME": 'Short Term Rents Income',
            "LATE_FEES_INCOME": 'Late Fees Income',
            "STORAGE_INCOME": 'Storage Income',
            "MISC_INTEREST_INCOME": 'Misc Interest Income',
            "TAXES": "Taxes",
            "INSURANCE_EXPENSE": "Insurance Expense",
            "ADVERTISING_EXPENSE": "Advertising Expense",
            "CLEANING_AND_MAINTENANCE_EXPENSE": "Cleaning and Maintenance Expense",
            "LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE": "Legal and Professional Services Expense",
            "MANAGEMENT_EXPENSE": "Management Expense",
            "MEALS_EXPENSE": "Meals Expense",
            "REPAIRS_EXPENSE": "Repairs Expense",
            "SUPPLIES_EXPENSE": "Supplies Expense",
            "TRAVEL_EXPENSE": "Travel Expense",
            "UTILITIES_EXPENSE": "Utilities Expense",
            "OTHER_EXPENSE": "Other Expense",
            "TRAVEL_MILES": "Travel Miles",
            "CAPITAL_EXPENSE": 'Capital Expense',
            "MORTGAGES_AND_LOANS_EXPENSE": 'Mortgages and Loans Expense',
            "SECURITY_DEPOSITS": 'Security Deposits',
        };
        setTotalTransactions(transactions.flat());
        setCategoryId(id);

        if (total !== 0) {
            setOpenTotalDetails(true);
        } else {
            toast.info(`No transactions for ${categories[id] || id}`);
        }
    };

    const descriptionClick = (link) => {
        navigate(link);
    }

    const totalTransctionCalculation = (transaction, id) => {
        let total = 0
        if (id === "TRAVEL_MILES") {
            transaction.forEach((amount) => {
                total += amount.trvelMiles;
            })
        } else {
            transaction.forEach((amount) => {
                total += amount.amount;
            })
        }
        return total.toFixed(2);
    }

    const transactionTotalCalculation = (transactions, id) => {
        let total = 0
        if (id === "TRAVEL_MILES") {
            transactions.forEach((amount) => {
                total += amount.trvelMiles;
            })
        } else {
            transactions.forEach((amount) => {
                total += amount.amount;
            })
        }
        return total.toFixed(2);
    }

    const incomeAndExpenseTotal = (cat) => {
        let income = 0;
        let expense = 0;
        if (cat) {
            income = cat.categories[0].total
                + cat.categories[1].total
                + cat.categories[2].total
                + cat.categories[3].total
                + cat.categories[4].total
                + cat.categories[5].total
                + cat.categories[6].total
                + cat.categories[7].total;
            expense = cat.categories[8].total
                + cat.categories[9].total
                + cat.categories[10].total
                + cat.categories[11].total
                + cat.categories[12].total
                + cat.categories[13].total
                + cat.categories[14].total
                + cat.categories[15].total
                + cat.categories[16].total
                + cat.categories[17].total
                + cat.categories[19].total
                + cat.categories[20].total
                + cat.categories[21].total
                + cat.categories[22].total;
        }
        return <div className={income < expense ? "finance-text-profit-loss-total-color-red" : "finance-text-profit-loss-total-color-green"}>(${Math.abs(income - expense).toFixed(2)})</div>
    }

    const profitLossTotal = (group) => {
        let income = 0;
        let expense = 0;
        if (group) {
            income = group.categories.RENTAL_INCOME.total
                + group.categories.OTHER_INCOME.total
                + group.categories.PARKING_INCOME.total
                + group.categories.LAUNDRY_SERVICE_INCOME.total
                + group.categories.SHORT_TERM_RENTS_INCOME.total
                + group.categories.LATE_FEES_INCOME.total
                + group.categories.STORAGE_INCOME.total
                + group.categories.MISC_INTEREST_INCOME.total;
            expense = group.categories.ADVERTISING_EXPENSE.total
                + group.categories.CLEANING_AND_MAINTENANCE_EXPENSE.total
                + group.categories.INSURANCE_EXPENSE.total
                + group.categories.LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE.total
                + group.categories.MANAGEMENT_EXPENSE.total
                + group.categories.MEALS_EXPENSE.total
                + group.categories.OTHER_EXPENSE.total
                + group.categories.REPAIRS_EXPENSE.total
                + group.categories.SUPPLIES_EXPENSE.total
                + group.categories.TAXES.total
                + group.categories.TRAVEL_EXPENSE.total
                + group.categories.UTILITIES_EXPENSE.total
                + group.categories.CAPITAL_EXPENSE.total
                + group.categories.MORTGAGES_AND_LOANS_EXPENSE.total;
        }
        return <div className={income < expense ? "finance-text-individual-profit-loss-total-color-red" : "finance-text-individual-profit-loss-total-color-green"}>(${Math.abs(income - expense).toFixed(2)})</div>
    }

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "30px" }}>
            <ComplexServiceRequestTabs />
            <p style={{ fontSize: 22, textAlign: "center", fontWeight: "bold", marginTop: '30px' }}>Finance</p>
            {/* <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bolder" }}>( Report from {moment(startDate).format("MMM DD, YYYY")} to {moment(endDate).format("MMM DD, YYYY")} )</p> */}
            <TableContainer sx={{ maxHeight: 700 }}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableCell-maxWidth-finance' align='left'><span className='finance-page-table-head-grey-color'>Category</span></TableCell>
                            <TableCell className='tableCell-maxWidth-finance' align='center'><span className='finance-page-table-head-grey-color'>Total</span></TableCell>
                            {data && data.groups ? data?.groups.map((e, i) => (
                                <TableCell className='tableCell-maxWidth-finance' align='center' key={i}>
                                    <span className='finance-page-table-head-grey-color'>{e?.name}</span>
                                </TableCell>
                            )) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.categories.map((e, i) => (e.id !== "SECURITY_DEPOSITS" ?
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={i}
                            >
                                <TableCell className='tableCell-maxWidth-finance' component="th" scope="row" align='left'>
                                    <span className='finance-travel-expense-flex'>
                                        <span className={e?.id === "TRAVEL_MILES" ? 'finance-travel-expense-miles-color-head' : 'finance-category-name-style'}>
                                            {FinanceCategoriesUppercase(e?.id)}
                                        </span>
                                    </span>
                                </TableCell>
                                <TableCell className='tableCell-maxWidth-finance' align='center'>
                                    {e.id === "ADVERTISING_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.ADVERTISING_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "CLEANING_AND_MAINTENANCE_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.CLEANING_AND_MAINTENANCE_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "INSURANCE_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.INSURANCE_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "MANAGEMENT_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.MANAGEMENT_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "MEALS_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.MEALS_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "OTHER_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.OTHER_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "OTHER_INCOME" ?
                                        <div className='finance-text-color-green' onClick={() => { details(data.groups.map(e => e.categories.OTHER_INCOME.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "RENTAL_INCOME" ?
                                        <div className='finance-text-color-green' onClick={() => { details(data.groups.map(e => e.categories.RENTAL_INCOME.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "PARKING_INCOME" ?
                                        <div className='finance-text-color-green' onClick={() => { details(data.groups.map(e => e.categories.PARKING_INCOME.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "LAUNDRY_SERVICE_INCOME" ?
                                        <div className='finance-text-color-green' onClick={() => { details(data.groups.map(e => e.categories.LAUNDRY_SERVICE_INCOME.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "SHORT_TERM_RENTS_INCOME" ?
                                        <div className='finance-text-color-green' onClick={() => { details(data.groups.map(e => e.categories.SHORT_TERM_RENTS_INCOME.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "LATE_FEES_INCOME" ?
                                        <div className='finance-text-color-green' onClick={() => { details(data.groups.map(e => e.categories.LATE_FEES_INCOME.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "STORAGE_INCOME" ?
                                        <div className='finance-text-color-green' onClick={() => { details(data.groups.map(e => e.categories.STORAGE_INCOME.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "MISC_INTEREST_INCOME" ?
                                        <div className='finance-text-color-green' onClick={() => { details(data.groups.map(e => e.categories.MISC_INTEREST_INCOME.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {/* {e.id === "SECURITY_DEPOSITS" ?
                                    <div className='finance-text-color-blue' onClick={() => { details(data.groups.map(e => e.categories.SECURITY_DEPOSITS.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                    : ""} */}
                                    {e.id === "REPAIRS_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.REPAIRS_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "SUPPLIES_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.SUPPLIES_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "TAXES" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.TAXES.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "TRAVEL_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.TRAVEL_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "TRAVEL_MILES" ?
                                        <div className='finance-text-color-orange' onClick={() => { details(data.groups.map(e => e.categories.TRAVEL_MILES.transactions), e.id, e.total) }}>{parseFloat(e.total)}</div>
                                        : ""}
                                    {e.id === "UTILITIES_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.UTILITIES_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "CAPITAL_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.CAPITAL_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                    {e.id === "MORTGAGES_AND_LOANS_EXPENSE" ?
                                        <div className='finance-text-color-red' onClick={() => { details(data.groups.map(e => e.categories.MORTGAGES_AND_LOANS_EXPENSE.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                </TableCell>
                                {data?.groups.map((j, k) => (
                                    <TableCell className='tableCell-maxWidth-finance' align='center' key={k}>
                                        {e.id === "ADVERTISING_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.ADVERTISING_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.ADVERTISING_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "CLEANING_AND_MAINTENANCE_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.CLEANING_AND_MAINTENANCE_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.CLEANING_AND_MAINTENANCE_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "INSURANCE_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.INSURANCE_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.INSURANCE_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "MANAGEMENT_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.MANAGEMENT_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.MANAGEMENT_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "MEALS_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.MEALS_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.MEALS_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "OTHER_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.OTHER_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.OTHER_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "OTHER_INCOME" ?
                                            <div className='finance-text-color-green' onClick={() => { handleDetails(j?.categories?.OTHER_INCOME.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.OTHER_INCOME.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "RENTAL_INCOME" ?
                                            <div className='finance-text-color-green' onClick={() => { handleDetails(j?.categories?.RENTAL_INCOME.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.RENTAL_INCOME.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "PARKING_INCOME" ?
                                            <div className='finance-text-color-green' onClick={() => { handleDetails(j?.categories?.PARKING_INCOME.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.PARKING_INCOME.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "LAUNDRY_SERVICE_INCOME" ?
                                            <div className='finance-text-color-green' onClick={() => { handleDetails(j?.categories?.LAUNDRY_SERVICE_INCOME.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.LAUNDRY_SERVICE_INCOME.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "SHORT_TERM_RENTS_INCOME" ?
                                            <div className='finance-text-color-green' onClick={() => { handleDetails(j?.categories?.SHORT_TERM_RENTS_INCOME.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.SHORT_TERM_RENTS_INCOME.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "LATE_FEES_INCOME" ?
                                            <div className='finance-text-color-green' onClick={() => { handleDetails(j?.categories?.LATE_FEES_INCOME.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.LATE_FEES_INCOME.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "STORAGE_INCOME" ?
                                            <div className='finance-text-color-green' onClick={() => { handleDetails(j?.categories?.STORAGE_INCOME.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.STORAGE_INCOME.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "MISC_INTEREST_INCOME" ?
                                            <div className='finance-text-color-green' onClick={() => { handleDetails(j?.categories?.MISC_INTEREST_INCOME.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.MISC_INTEREST_INCOME.total).toFixed(2)}
                                            </div> : ""}
                                        {/* {e.id === "SECURITY_DEPOSITS" ?
                                        <div className='finance-text-color-blue' onClick={() => { handleDetails(j?.categories?.SECURITY_DEPOSITS.transactions, e.id) }}>
                                            {parseFloat(j?.categories?.SECURITY_DEPOSITS.total).toFixed(2)}
                                        </div> : ""} */}
                                        {e.id === "REPAIRS_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.REPAIRS_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.REPAIRS_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "SUPPLIES_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.SUPPLIES_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.SUPPLIES_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "TAXES" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.TAXES.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.TAXES.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "TRAVEL_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.TRAVEL_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.TRAVEL_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "TRAVEL_MILES" ?
                                            <div className='finance-text-color-orange' onClick={() => { handleDetails(j?.categories?.TRAVEL_MILES.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.TRAVEL_MILES.total)}
                                            </div> : ""}
                                        {e.id === "UTILITIES_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.UTILITIES_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.UTILITIES_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "CAPITAL_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.CAPITAL_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.CAPITAL_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                        {e.id === "MORTGAGES_AND_LOANS_EXPENSE" ?
                                            <div className='finance-text-color-red' onClick={() => { handleDetails(j?.categories?.MORTGAGES_AND_LOANS_EXPENSE.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.MORTGAGES_AND_LOANS_EXPENSE.total).toFixed(2)}
                                            </div> : ""}
                                    </TableCell>
                                ))}
                            </TableRow>
                            : ""))}
                    </TableBody>
                    <TableFooter style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 100 }}>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell className='tableCell-maxWidth-finance' component="th" scope="row" align='left'>
                                <div style={{ fontSize: 17, fontWeight: "bold", color: 'black' }}>Profit/Loss</div>
                            </TableCell>
                            <TableCell className='tableCell-maxWidth-finance' align='center'>
                                {incomeAndExpenseTotal(data)}
                            </TableCell>
                            {data?.groups.map((groups, k) => (
                                <TableCell className='tableCell-maxWidth-finance' align='center' key={k}>
                                    {profitLossTotal(groups)}
                                </TableCell>
                            ))}
                        </TableRow>
                        {data?.categories.map((e, i) => (e.id === "SECURITY_DEPOSITS" ?
                            <TableRow key={i}>
                                <TableCell className='tableCell-maxWidth-finance' component="th" scope="row" align='left'>
                                    <div style={{ fontSize: 17, fontWeight: "bold", color: 'black' }}>{FinanceCategoriesUppercase(e?.id)}</div>
                                </TableCell>
                                <TableCell className='tableCell-maxWidth-finance' align='center'>
                                    {e.id === "SECURITY_DEPOSITS" ?
                                        <div className='finance-text-color-blue' onClick={() => { details(data.groups.map(e => e.categories.SECURITY_DEPOSITS.transactions), e.id, e.total) }}>{parseFloat(e.total).toFixed(2)}</div>
                                        : ""}
                                </TableCell>
                                {data?.groups.map((j, k) => (
                                    <TableCell className='tableCell-maxWidth-finance' align='center' key={k}>
                                        {e.id === "SECURITY_DEPOSITS" ?
                                            <div className='finance-text-color-blue' onClick={() => { handleDetails(j?.categories?.SECURITY_DEPOSITS.transactions, e.id) }}>
                                                {parseFloat(j?.categories?.SECURITY_DEPOSITS.total).toFixed(2)}
                                            </div> : ""}
                                    </TableCell>
                                ))}
                            </TableRow>
                            : null))}
                    </TableFooter>
                </Table>
            </TableContainer>
            <CardContent>
                <p style={{ fontSize: 17, fontWeight: "bolder" }}>Download Report</p>
            </CardContent>
            <CardContent style={{ display: "flex", flexDireaction: "row", gap: 20, flexWrap: "wrap", alignItems: 'flex-end' }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DatePicker
                                views={["day", "month", "year"]}
                                type="date"
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => { setStartDate(newValue) }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DatePicker
                                views={["day", "month", "year"]}
                                type="date"
                                label="Today's Date"
                                value={endDate}
                                onChange={(newValue) => { setEndDate(newValue); }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div>
                    <CSVLink
                        data={allTransactions}
                        filename={"property-financial-report.csv"}
                        className="btn btn-primary"
                        target="_blank"
                    >
                        <Tooltip placement="top" title="Download CSV">
                            <Button variant='contained' color='primary' sx={{ fontSize: 15 }}>Download</Button>
                        </Tooltip>
                    </CSVLink>
                </div>
            </CardContent>

            <Dialog
                open={openDetails}
                onClose={() => { setOpenDetails(false) }}
                fullWidth={true}
                maxWidth="md">
                <CardContent>
                    <CardContent>
                        {categoryId === "RENTAL_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Rental Income</p> : ""}
                        {categoryId === "OTHER_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Other Income</p> : ""}
                        {categoryId === "PARKING_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Parking Income</p> : ""}
                        {categoryId === "LAUNDRY_SERVICE_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Laundry Service Income</p> : ""}
                        {categoryId === "SHORT_TERM_RENTS_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Short Term Rents Income</p> : ""}
                        {categoryId === "LATE_FEES_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Late Fees Income</p> : ""}
                        {categoryId === "STORAGE_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Storage Income</p> : ""}
                        {categoryId === "MISC_INTEREST_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Misc Interest Income</p> : ""}
                        {categoryId === "SECURITY_DEPOSITS" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Security Deposits</p> : ""}
                        {categoryId === "TAXES" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Taxes</p> : ""}
                        {categoryId === "INSURANCE_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Insurance Expense</p> : ""}
                        {categoryId === "ADVERTISING_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Advertising Expense</p> : ""}
                        {categoryId === "CLEANING_AND_MAINTENANCE_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Cleaning and Maintenance Expense</p> : ""}
                        {categoryId === "LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Legal and Professional Services Expense</p> : ""}
                        {categoryId === "MANAGEMENT_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Management Expense</p> : ""}
                        {categoryId === "MEALS_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Meals Expense</p> : ""}
                        {categoryId === "REPAIRS_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Repairs Expense</p> : ""}
                        {categoryId === "SUPPLIES_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Supplies Expense</p> : ""}
                        {categoryId === "UTILITIES_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Utilities Expense</p> : ""}
                        {categoryId === "OTHER_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Other Expense</p> : ""}
                        {categoryId === "TRAVEL_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Travel Expense</p> : ""}
                        {categoryId === "TRAVEL_MILES" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Manager Travelled Miles</p> : ""}
                        {categoryId === "CAPITAL_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Capital Expense</p> : ""}
                        {categoryId === "MORTGAGES_AND_LOANS_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Mortgages and Loans Expense</p> : ""}
                    </CardContent>
                    <table className='manager-finance-table-modals'>
                        <thead>
                            {categoryId === "TRAVEL_MILES" ? (
                                <tr>
                                    <th className='manager-finance-table-head-modals'>Travelled Date</th>
                                    <th className='manager-finance-table-head-modals'>Address</th>
                                    <th className='manager-finance-table-head-modals'>Service Req. Description</th>
                                    <th className='manager-finance-table-head-modals'>Travel Miles</th>
                                </tr>
                            ) : (
                                <tr>
                                    <th className='manager-finance-table-head-modals'>Date</th>
                                    <th className='manager-finance-table-head-modals'>Address</th>
                                    <th className='manager-finance-table-head-modals'>Description</th>
                                    <th className='manager-finance-table-head-modals'>Amount</th>
                                </tr>
                            )}
                        </thead>
                        {categoryId === "TRAVEL_MILES" ? (
                            <tbody>
                                {transactions.map((e, i) => (
                                    <tr key={i}>
                                        <td className='manager-finance-table-body-modals'>{e.date ? moment(e.date).format('MMM D, YYYY') : ""}</td>
                                        <td className='manager-finance-table-body-modals'>{e.propertyName}</td>
                                        <td className='manager-finance-table-body-modals'
                                        // onClick={() => { descriptionClick(e?.serviceRequestUrl) }}
                                        >{e.description}</td>
                                        <td className='manager-finance-table-body-modals'>{e.trvelMiles}</td>
                                    </tr>))}
                                <tr>
                                    <td className='manager-finance-table-body-modals'></td>
                                    <td className='manager-finance-table-body-modals'></td>
                                    <td className='manager-finance-table-body-modals'></td>
                                    <td className='manager-finance-table-body-modals-total'>Total:$ {transactionTotalCalculation(transactions, categoryId)}</td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {transactions.map((e, i) => (
                                    <tr key={i}>
                                        <td className='manager-finance-table-body-modals'>{e.date ? moment(e.date).format('MMM D, YYYY') : ""}</td>
                                        <td className='manager-finance-table-body-modals'>{e.propertyName}</td>
                                        <td className='manager-finance-table-body-modals-description-text' onClick={() => { descriptionClick(e?.serviceRequestUrl) }}>{e.description}</td>
                                        <td className='manager-finance-table-body-modals'>{parseFloat(e.amount).toFixed(2)}</td>
                                    </tr>))}
                                <tr>
                                    <td className='manager-finance-table-body-modals'></td>
                                    <td className='manager-finance-table-body-modals'></td>
                                    <td className='manager-finance-table-body-modals'></td>
                                    <td className='manager-finance-table-body-modals-total'>Total:$ {transactionTotalCalculation(transactions, categoryId)}</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </CardContent>
            </Dialog>
            <Dialog
                open={openTotalDetails}
                onClose={() => { setOpenTotalDetails(false) }}
                fullWidth={true}
                maxWidth="md">
                <CardContent>
                    <CardContent>
                        {categoryId === "RENTAL_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Rental Income</p> : ""}
                        {categoryId === "OTHER_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Other Income</p> : ""}
                        {categoryId === "PARKING_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Parking Income</p> : ""}
                        {categoryId === "LAUNDRY_SERVICE_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Laundry Service Income</p> : ""}
                        {categoryId === "SHORT_TERM_RENTS_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Short Term Rents Income</p> : ""}
                        {categoryId === "LATE_FEES_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Late Fees Income</p> : ""}
                        {categoryId === "STORAGE_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Storage Income</p> : ""}
                        {categoryId === "MISC_INTEREST_INCOME" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Misc Interest Income</p> : ""}
                        {categoryId === "SECURITY_DEPOSITS" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Security Deposits</p> : ""}
                        {categoryId === "TAXES" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Taxes</p> : ""}
                        {categoryId === "INSURANCE_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Insurance Expense</p> : ""}
                        {categoryId === "ADVERTISING_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Advertising Expense</p> : ""}
                        {categoryId === "CLEANING_AND_MAINTENANCE_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Cleaning and Maintenance Expense</p> : ""}
                        {categoryId === "LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Legal and Professional Services Expense</p> : ""}
                        {categoryId === "MANAGEMENT_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Management Expense</p> : ""}
                        {categoryId === "MEALS_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Meals Expense</p> : ""}
                        {categoryId === "REPAIRS_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Repairs Expense</p> : ""}
                        {categoryId === "SUPPLIES_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Supplies Expense</p> : ""}
                        {categoryId === "UTILITIES_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Utilities Expense</p> : ""}
                        {categoryId === "OTHER_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Other Expense</p> : ""}
                        {categoryId === "TRAVEL_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Travel Expense</p> : ""}
                        {categoryId === "TRAVEL_MILES" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Manager Travelled Miles</p> : ""}
                        {categoryId === "CAPITAL_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Capital Expense</p> : ""}
                        {categoryId === "MORTGAGES_AND_LOANS_EXPENSE" ?
                            <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>Mortgages and Loans Expense</p> : ""}
                    </CardContent>
                    <table className='manager-finance-table-modals'>
                        <thead>
                            {categoryId === "TRAVEL_MILES" ? (
                                <tr>
                                    <th className='manager-finance-table-head-modals'>Travelled Date</th>
                                    <th className='manager-finance-table-head-modals'>Address</th>
                                    <th className='manager-finance-table-head-modals'>Service Req. Description</th>
                                    <th className='manager-finance-table-head-modals'>Travel Miles</th>
                                </tr>
                            ) : (
                                <tr>
                                    <th className='manager-finance-table-head-modals'>Date</th>
                                    <th className='manager-finance-table-head-modals'>Address</th>
                                    <th className='manager-finance-table-head-modals'>Description</th>
                                    <th className='manager-finance-table-head-modals'>Amount</th>
                                </tr>
                            )}
                        </thead>
                        {categoryId === "TRAVEL_MILES" ?
                            <tbody>
                                {totalTransctions.map((h, j) => (
                                    <tr key={j}>
                                        <td className='manager-finance-table-body-modals'>{h?.date ? moment(h?.date).format('MMM D, YYYY') : ""}</td>
                                        <td className='manager-finance-table-body-modals'>{h?.propertyName}</td>
                                        <td className='manager-finance-table-body-modals'
                                        // onClick={() => { descriptionClick(h?.serviceRequestUrl) }}
                                        >{h?.description}</td>
                                        <td className='manager-finance-table-body-modals'>{h?.trvelMiles}</td>
                                    </tr>))}
                            </tbody> :
                            <tbody>
                                {totalTransctions.map((e, j) => (
                                    <tr key={j}>
                                        <td className='manager-finance-table-body-modals'>{e?.date ? moment(e?.date).format('MMM D, YYYY') : ""}</td>
                                        <td className='manager-finance-table-body-modals'>{e?.propertyName}</td>
                                        <td className='manager-finance-table-body-modals-description-text' onClick={() => { descriptionClick(e?.serviceRequestUrl) }}>{e?.description}</td>
                                        <td className='manager-finance-table-body-modals'>{parseFloat(e?.amount).toFixed(2)}</td>
                                    </tr>))}
                            </tbody>}
                        <tfoot>
                            <tr>
                                <td className='manager-finance-table-body-modals'></td>
                                <td className='manager-finance-table-body-modals'></td>
                                <td className='manager-finance-table-body-modals'></td>
                                <td className='manager-finance-table-body-modals-total'>Total: ${totalTransctionCalculation(totalTransctions, categoryId)}</td>
                            </tr>
                        </tfoot>
                    </table>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
        </div >
    )
}

export default ComplexFinances
