import { useEffect, useState } from "react";
import { getApiBaseURL, getProperties } from "../../services";
import { FaBath, FaBed, FaCar } from "react-icons/fa";
import { MdLocalLaundryService } from "react-icons/md";
import { Link } from "react-router-dom";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

function HomePage() {
  const [properties, setProperties] = useState([]);
  const [propertiesLoading, setPropertiesLoading] = useState(true);

  useEffect(() => {
    getProperties()
      .then((response) => {
        setProperties(response.data.data);
        setPropertiesLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setProperties]);

  const renderPropertiesSkeleton = () => {
    const elements = [];

    for (let i = 0; i < 3; i++) {
      elements.push(
        <div
          className="property-list-item skeleton"
          key={i}
          style={{
            backgroundColor: "#dddddd",
            height: "300px",
            width: "300px",
          }}
        />
      );
    }

    return elements;
  };

  const renderProperties = () => {
    const elements = [];

    properties.forEach((item) => {
      elements.push(
        <Link
          to={`/properties/${item.id}`}
          className="property-list-item"
          key={item.id}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardMedia
                  component="img"
                  height={200}
                  image={
                    item.images.defaultImageId
                      ? `${getApiBaseURL()}/properties/${item.id}/images/default`
                      : "/images/default-property-picture.jpeg"
                  }
                  alt="Set of buildings"
                />
                <CardContent>
                  <Typography
                    style={{ color: "#ff805d", textTransform: "capitalize" }}
                    variant="subtitle1"
                  >
                    {item.address.city}, {item.address.state}
                  </Typography>
                  <Typography style={{ color: "#8cc659" }} variant="body2">
                    ${item.rentAmount}
                  </Typography>
                  <div className="facilities">
                    {item.numberOfBedrooms && (
                      <div className="facilities-item">
                        <FaBed />
                        <span>{item.numberOfBedrooms}</span>
                      </div>
                    )}
                    {item.numberOfBathrooms && (
                      <div className="facilities-item">
                        <FaBath />
                        <span>{item.numberOfBathrooms}</span>
                      </div>
                    )}
                    {item.numberOfParkingSpaces && (
                      <div className="facilities-item">
                        <FaCar />
                        <span>{item.numberOfParkingSpaces}</span>
                      </div>
                    )}
                    {item.laundryFacility === "IN_UNIT" && (
                      <div className="facilities-item">
                        <MdLocalLaundryService />
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Link>
      );
    });

    return elements;
  };
  return (
    <div className="page home-page">
      <div className="banner">
        <h1 className="main-text">Find your dream home</h1>
        {/* <p className="sub-text">
          From as low as $10 per day with limited time offers discounts
        </p> */}
      </div>
      <div className="properties-section">
        <div className="properties-section-header">
          <h2>Featured properties</h2>
          <div>Exclusive properties by our team</div>
        </div>
        <div className="property-list">
          {propertiesLoading ? renderPropertiesSkeleton() : renderProperties()}
          {/* {propertiesLoading && renderPropertiesSkeleton()}
                        {!propertiesLoading && renderProperties()} */}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
