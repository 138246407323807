import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import PhoneIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Mail";
import { Button, Typography } from '@mui/material';
import copy from "copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const yellowBtn = {
    backgroundColor: '#FFCF2F',
    color: 'white',
    fontSize: '15px',
    fontWeight: 'bold',
    boxShadow: 'none',
}
const blueBtn = {
    backgroundColor: '#37A0F4',
    color: 'white',
    fontSize: '15px',
    fontWeight: 'bold',
    boxShadow: 'none',
}
const greenBtn = {
    backgroundColor: '#26D657',
    color: 'white',
    fontSize: '15px',
    fontWeight: 'bold',
    boxShadow: 'none'
}

function ContractorRedirectPopup({ name, email, phone }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, setState] = useState("");

    const handleClick = (event, status) => {
        setAnchorEl(event.currentTarget);
        setState(status);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setState("");
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const actionButton = (name, data, status) => {
        if (status === "call") {
            window.location.href = 'tel:' + data;
        }
        if (status === "phone-copy") {
            copy(data);
            toast.success(name + '\'s ' + data + ' is copied to Clipboard');
        }
        if (status === "text") {
            window.location.href = 'sms:' + data;
        }
        if (status === "email-copy") {
            copy(data);
            toast.success(name + '\'s ' + data + ' is copied to Clipboard');
        }
        if (status === "email") {
            window.location.href = 'mailto:' + data;
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', gap: 10 }}>
                <PhoneIcon color="primary" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, "phone")} />
                <EmailIcon color="primary" sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, "email")} />
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ opacity: '0.9' }}
            >
                <div style={{ padding: '20px', background: '#B8DC98', color: 'white' }}>
                    <Typography variant="h6" textAlign="center" sx={{ mb: 2, fontSize: 17 }}>
                        {name}'s {state === "phone" ? "Phone Number" : "Email"} is Copied to Clipboard
                    </Typography>
                    <Typography variant="h6" textAlign="center" sx={{ mb: 3, fontSize: 18 }}>
                        {state === "phone" ? phone : email}
                    </Typography>
                    {state === "phone" ?
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                            <Button variant="contained" onClick={() => { actionButton(name, phone, "call") }} style={yellowBtn}>
                                CALL
                            </Button>
                            <Button variant="contained" onClick={() => { actionButton(name, phone, "phone-copy") }} style={blueBtn} >
                                COPY
                            </Button>
                            <Button variant="contained" onClick={() => { actionButton(name, phone, "text") }} style={greenBtn} >
                                TEXT
                            </Button>
                        </div>
                        : ""}
                    {state === "email" ?
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                            <Button variant="contained" onClick={() => { actionButton(name, email, "email-copy") }} style={blueBtn}>
                                COPY
                            </Button>
                            <Button variant="contained" onClick={() => { actionButton(name, email, "email") }} style={greenBtn}>
                                Email
                            </Button>
                        </div>
                        : ""}
                </div>
            </Popover>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default ContractorRedirectPopup;
