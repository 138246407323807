import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import AppBar from "./components/AppBar";
import NavBar from "./components/NavBar";
import AppFooter from "./components/AppFooter";
import ManagerBar from "./components/ManagerBar";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/forgotpassword/resetPassword";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import ManagerPropertiesEditPageLatest from "./pages/manager/properties/edit/ManagerPropertiesEditLatest";
import ManagerPropertiesIndexPage from "./pages/manager/properties/index";
import PropertyViewPage from "./pages/properties/view";
import TenantDashboardPage from "./pages/tenant/dashboard";
import TenantLeaseApplicationEditPage from "./pages/tenant/lease-applications/edit";
import TenantLeaseApplicationViewPage from "./pages/tenant/lease-applications/view";
import { finishLoadingUser } from "./redux/slices/userLoadingSlice";
import { setUser } from "./redux/slices/userSlice";
import { getApiBaseURL, getCurrentUser } from "./services";
import AuthShield from "./shields/AuthShield";
import CreateLease from "./pages/createlease";
import InvitationFormPage from "./pages/properties/apply";
import Register from "./pages/managerRegister";
import RequestEmailVerification from "./pages/requestEmailVerification";
import EmailVerification from "./pages/requestEmailVerification/emailVerification";
import LeaseApp from "./pages/manager/lease-applications/view/leaseApp";
import OccupantsForm from "./pages/tenant/lease-applications/edit/occupantsForm";
import ResidenceForm from "./pages/tenant/lease-applications/edit/residenceForm";
import EmploymentForm from "./pages/tenant/lease-applications/edit/employmentForm";
import VerificationForm from "./pages/tenant/lease-applications/edit/verificationForm";
import HistoryForm from "./pages/tenant/lease-applications/edit/historyForm";
import AgreementForm from "./pages/tenant/lease-applications/edit/agreementForm";
import Leaseagreementpreview from "./pages/leasepreview/";
import SignLeaseAgreement from "./signleaseagreement";
import LeasemanagerSign from "./pages/managerSignleaseagreemenet";
import LeaseApplicationView from "./pages/leaseapplicationsView";
import LeaseOverview from "./pages/manager/properties/edit/LeaseOverview";
import LeaseView from ".//pages/tenant/lease-applications/leaseview";
import MoveInLease from ".//pages/tenant/lease-applications/moveinlease";
import ServiceRequest from ".//pages/tenant/lease-applications/servicerequest";
import ManagerMoveInLease from "./pages/manager/moveinlease";
import ScheduleMoveOut from "./pages/manager/scheduleMoveOut";
import RenewLease from "./pages/manager/RenewLease";
import PrepareNotice from "./pages/manager/prepareNotice";
import NoticeTemplates from "./pages/manager/notice-templates";
import TenantScheduleMoveOut from "./pages/tenant/tenantScheduleMoveOut";
import UpdateNoticeTemplates from "./pages/manager/notice-templates/updateNotice";
import LeaseRenewalPreviewNotice from "./pages/manager/RenewLease/leaseRenewalpreview";
import NoticePreview from "./pages/manager/prepareNotice/noticePreview";
import ManagerMoveOutLease from "./pages/manager/moveOutLease";
import TenantMoveOutLease from "./pages/tenant/moveOutLease";
import ManagerServiceRequest from "./pages/manager/serviceRequest";
import NewServiceRequest from "./pages/manager/serviceRequest/serviceRequestNew";
import ServiceRequests from "./pages/manager/serviceRequest/serviceRequests";
import TenantServiceRequest from "./pages/tenant/serviceRequest";
import Contractor from "./pages/manager/contractor/contractor";
import Finance from "./pages/manager/finance";
import ManagerFinance from "./pages/manager/finance/finance";
import PropertyGrouping from "./pages/manager/finance/propertyGrouping";
import TeamManagers from "./pages/manager/team/teamManagers";
import TeamActiveContractors from "./pages/manager/team/teamActiveContractors";
import Home from "./pages/manager/home/home";
import LeaseAdd from "./pages/manager/properties/edit/leaseAdd";
import ActiveProperties from "./pages/manager/properties/index/activeProperties";
import InactiveProperties from "./pages/manager/properties/index/inactiveProperties";
import ManagerInvite from "./pages/manager/team/manager-invite";
import ComplexServiceRequests from "./pages/manager/properties/complexServiceRequest/complexServiceRequests";
import ManagerPropertiesArchived from "./pages/manager/properties/index/archivedProperties";
import TeamInactiveContractors from "./pages/manager/team/teamInactiveContractors";
import ComplexEdit from "./pages/manager/properties/complexServiceRequest/complexEdit";
import ComplexFinances from "./pages/manager/properties/complexServiceRequest/complexFinances";
import GroupComplexServiceRequests from "./pages/manager/properties/complexServiceRequest/groupComplexServiceRequests";
import LeaseUpdateContactDetails from "./pages/manager/properties/edit/leaseUpdateContactDetails";
import ComplexServiceRequestView from "./pages/manager/properties/complexServiceRequest/complexServiceRequestView";
import LeaseAppNew from "./pages/manager/lease-applications/view/LeaseAppNew";
import ViewDetails from "./pages/manager/finance/ViewDetails";
import GroupServiceRequestContractor from "./pages/manager/contractor/GroupServiceRequestContractor";
import ContractorServiceRequestDashboard from "./pages/manager/contractor/contractorServiceRequestDashboard";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import Managers from "./pages/Users/Managers";
import Tenants from "./pages/Users/Tenants";
import AddProperty from "./pages/manager/properties/add/AddProperty";
import ReOccuringServiceRequest from "./pages/manager/serviceRequest/ReOccuringServiceRequest";
import GroupReOccuringServiceRequest from "./pages/manager/properties/complexServiceRequest/ReOccuringServiceRequest";
import UserProfile from "./pages/profile/userProfile";
import RentalStatus from "./pages/tenant/lease-applications/edit/status";

function App() {
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);

  let config = "";
  const getProfile = async () => {
    const authToken = sessionStorage.getItem("authToken");
    config = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    fetch(`${getApiBaseURL()}/user/profile/picture`, config)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          const link = document.createElement('a');
          link.href = imageDataUrl;
          if (
            blob.type == "image/png" ||
            blob.type == "image/gif" ||
            blob.type == "image/jpeg" ||
            blob.type == "image/svg" ||
            blob.type == "image/jpg"
          ) {
            setImage(imageDataUrl);
          }
        };
      });
  }

  useEffect(() => {
    if (sessionStorage.getItem("authToken")) {
      getCurrentUser()
        .then((response) => {
          dispatch(setUser(response?.data?.data));
          dispatch(finishLoadingUser());
          if (response?.data?.data?.profilePictureFile) {
            getProfile();
          }
        })
        .catch((err) => {
          sessionStorage.removeItem("authToken");
          dispatch(finishLoadingUser());
        });
    } else {
      dispatch(finishLoadingUser());
    }
  }, [dispatch]); 

  return (
    <>
      <div className="app">
        <NavBar profileImage={image} setProfileImage={setImage} />
        <div className="app-body">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage loadProfilePicture={getProfile} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/request-email-verification" element={<RequestEmailVerification />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/user/profile" element={<UserProfile loadProfilePicture={getProfile} setProfileImage={setImage} />} />
            <Route path="/properties/:propertyId" element={<PropertyViewPage />} />
            <Route path="/lease-applications/apply" element={<InvitationFormPage />} />
            <Route path="/lease-agreement" element={<CreateLease />} />
            <Route path="/LeaseApplicationView" element={<LeaseApplicationView />} />
            <Route path="/move-in-inspection/:leaseId" element={<ManagerMoveInLease />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/users/managers" element={<Managers />} />
            <Route path="/users/tenants" element={<Tenants />} />

            <Route
              path="/tenant"
              element={
                <AuthShield>
                  <Outlet />
                </AuthShield>
              }>
              <Route path="dashboard" element={<TenantDashboardPage />} />
              <Route path="lease-applications/:applicationId" element={<TenantLeaseApplicationViewPage />} />
              <Route path="leaseagreement/:applicationId" element={<SignLeaseAgreement />} />
              <Route path="lease-applications/:applicationId/edit" element={<TenantLeaseApplicationEditPage />} />
              <Route path="lease-applications/:applicationId/occupant" element={<OccupantsForm />} />
              <Route path="lease-applications/:applicationId/residence" element={<ResidenceForm />} />
              <Route path="lease-applications/:applicationId/employment" element={<EmploymentForm />} />
              <Route path="lease-applications/:applicationId/verification" element={<VerificationForm />} />
              <Route path="lease-applications/:applicationId/history" element={<HistoryForm />} />
              <Route path="lease-applications/:applicationId/agreement" element={<AgreementForm />} />
              <Route path="lease-applications/:applicationId/status" element={<RentalStatus />} />
              <Route path="lease-applications/:applicationId/leaseview" element={<LeaseView />} />
              <Route path="lease-applications/:applicationId/moveinlease" element={<MoveInLease />} />
              <Route path="lease-applications/:applicationId/service-requests" element={<ServiceRequest />} />
              <Route path="lease-applications/:applicationId/ScheduleMoveOut" element={<TenantScheduleMoveOut />} />
              <Route path="lease-applications/:applicationId/move-Out-inspection" element={<TenantMoveOutLease />} />
              <Route path="service-requests/:serviceId" element={<TenantServiceRequest />} />
            </Route>

            <Route
              path="/manager"
              element={
                <AuthShield>
                  <Outlet />
                </AuthShield>
              }>
              <Route path="properties/:managerId" element={<ManagerPropertiesIndexPage />} />
              <Route path="properties/:managerId/archived" element={<ManagerPropertiesArchived />} />
              <Route path="active-Properties" element={<ActiveProperties />} />
              <Route path="inactive-Properties" element={<InactiveProperties />} />
              <Route path="properties/add" element={<AddProperty />} />
              <Route path="properties/:propertyId/edit" element={<ManagerPropertiesEditPageLatest />} />
              <Route path="lease-applications/:applicationId" element={<LeaseAppNew />} />
              <Route path="createlease/:applicationId" element={<CreateLease />} />
              <Route path="leasepreview/:applicationId" element={<Leaseagreementpreview />} />
              <Route path="managerSignleaseagreemenet/:applicationId" element={<LeasemanagerSign />} />
              <Route path="properties/:propertyId/overview" element={<LeaseApplicationView />} />
              <Route path="properties/:propertyId/lease" element={<LeaseOverview />} />
              <Route path="properties/:propertyId/lease/add" element={<LeaseAdd />} />
              <Route path="properties/:propertyId/service-requests" element={<ManagerServiceRequest />} />
              <Route path="properties/:propertyId/service-requests/re-occuring" element={<ReOccuringServiceRequest />} />
              <Route path="properties/:propertyId/finance" element={<Finance />} />
              <Route path="properties/:propertyId/service-requests/new" element={<NewServiceRequest />} />
              <Route path="leases/:leaseId/move-in-inspection" element={<ManagerMoveInLease />} />
              <Route path="leases/:leaseId/move-Out-inspection" element={<ManagerMoveOutLease />} />
              <Route path="finances-details" element={<ViewDetails />} />
              <Route path="notice/prepare/:applicationId" element={<PrepareNotice />} />
              <Route path="notice-templates" element={<NoticeTemplates />} />
              <Route path="notice-templates/:noticeTemplateId" element={<UpdateNoticeTemplates />} />
              <Route path="notice/preview-and-send" element={<NoticePreview />} />
              <Route path="leases/:applicationId/renewal/prepare" element={<RenewLease />} />
              <Route path="leases/:applicationId/renewal/preview" element={<LeaseRenewalPreviewNotice />} />
              <Route path="leases/:applicationId/move-out-form" element={<ScheduleMoveOut />} />
              <Route path="service-requests/:serviceId" element={<ServiceRequests />} />
              <Route path="finance" element={<ManagerFinance />} />
              <Route path="property-grouping" element={<PropertyGrouping />} />
              <Route path="team/managers" element={<TeamManagers />} />
              <Route path="team/active-Contractors" element={<TeamActiveContractors />} />
              <Route path="team/Inactive-Contractors" element={<TeamInactiveContractors />} />
              <Route path="home" element={<Home />} />
              <Route path=":managerId/property-group/:groupId/serviceRequest/group/new" element={<ComplexServiceRequests />} />
              <Route path=":managerId/property-group/:groupId/edit" element={<ComplexEdit />} />
              <Route path=":managerId/property-group/:groupId/group-finances" element={<ComplexFinances />} />
              <Route path=":managerId/property-group/:groupId/service-requests/group" element={<GroupComplexServiceRequests />} />
              <Route path=":managerId/property-group/:groupId/service-requests/group/re-occuring" element={<GroupReOccuringServiceRequest />} />
              <Route path=":managerId/property-group/:groupId/service-requests/:serviceId" element={<ComplexServiceRequestView />} />
            </Route>

            <Route path="/contractor/:ContractorId/service-requests/:serviceId" element={<Contractor />} />
            <Route path="/contractor/:ContractorId/group/:groupId/service-requests/:serviceId/manager/:managerId" element={<GroupServiceRequestContractor />} />
            <Route path="/contractor/:ContractorId/group-service-requests/:serviceId/dashboard" element={<ContractorServiceRequestDashboard />} />
            <Route path="/manager-invitation" element={<ManagerInvite />} />
            <Route path="lease-update-contact-details" element={<LeaseUpdateContactDetails />} />
          </Routes>
        </div>
      </div>
      <AppFooter />
    </>
  );
}

export default App;