import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formNames, usStates } from "../../../../helpers";
import { FaStepBackward, FaStepForward } from "react-icons/fa";
import {
    addTenantIncomeVerificationFile, addTenantOtherIncomeVerificationFile,
    editTenantLeaseApplicantInfo, removeTenantIncomeVerificationFile,
    removeTenantOtherIncomeVerificationFile, getTenantLeaseApplication,
    withDrawApplication

} from "../../../../services";
import { Button, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TenantLeaseApplicationEditPage from ".";
import { MenuProps } from "../../../../components/MaterialUiSelectMenuStyle";
import { formatPhoneNumber } from "../../../../components/phoneNumberFormat";
import { truncateAddress } from "../../../../components/textTruncate/truncate";

function EmploymentForm() {
    let params = useParams();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.value);

    const [currentRole, setCurrentRole] = useState("");
    const [currentRoleError, setCurrentRoleError] = useState("");
    const [currentEmployer, setCurrentEmployer] = useState("");
    const [currentEmployerError, setCurrentEmployerError] = useState("");
    const [currentManager, setCurrentManager] = useState("");
    const [currentManagerError, setCurrentManagerError] = useState("");
    const [currentManagerPhone, setCurrentManagerPhone] = useState("");
    const [currentManagerPhoneError, setCurrentManagerPhoneError] = useState("");
    const [currentStreet, setCurrentStreet] = useState("");
    const [currentStreetError, setCurrentStreetError] = useState("");
    const [currentBuilding, setCurrentBuilding] = useState("");
    const [
        currentBuildingError,
        // setCurrentBuildingError
    ] = useState("");
    const [currentCity, setCurrentCity] = useState("");
    const [currentCityError, setCurrentCityError] = useState("");
    const [currentState, setCurrentState] = useState("");
    const [currentStateError, setCurrentStateError] = useState("");
    const [currentZip, setCurrentZip] = useState("");
    const [currentZipError, setCurrentZipError] = useState("");
    const [currentYears, setCurrentYears] = useState("1");
    const [
        currentYearsError,
        // setCurrentYearsError
    ] = useState("");

    const [previousRole, setPreviousRole] = useState("");
    const [previousRoleError, setPreviousRoleError] = useState("");
    const [previousEmployer, setPreviousEmployer] = useState("");
    const [previousEmployerError, setPreviousEmployerError] = useState("");
    const [previousManager, setPreviousManager] = useState("");
    const [previousManagerError, setPreviousManagerError] = useState("");
    const [previousManagerPhone, setPreviousManagerPhone] = useState("");
    const [previousManagerPhoneError, setPreviousManagerPhoneError] = useState("");
    const [previousStreet, setPreviousStreet] = useState("");
    const [previousStreetError, setPreviousStreetError] = useState("");
    const [previousBuilding, setPreviousBuilding] = useState("");
    const [
        previousBuildingError,
        // setPreviousBuildingError
    ] = useState("");
    const [previousCity, setPreviousCity] = useState("");
    const [previousCityError, setPreviousCityError] = useState("");
    const [previousState, setPreviousState] = useState("");
    const [previousStateError, setPreviousStateError] = useState("");
    const [previousZip, setPreviousZip] = useState("");
    const [previousZipError, setPreviousZipError] = useState("");

    const [income, setIncome] = useState(0);
    const [incomeError, setIncomeError] = useState("");
    const [payStub, setPayStub] = useState(null);
    const [payStubError, setPayStubError] = useState("");
    const [payStubUploading, setpayStubUploading] = useState(false);
    const [hasOtherIncomeSource, setHasOtherIncomeSource] = useState("no");
    const [otherIncome, setOtherIncome] = useState("1");
    const [
        otherIncomeError,
        // setOtherIncomeError
    ] = useState("");
    const [otherIncomeSource, setOtherIncomeSource] = useState("");
    const [otherIncomeSourceError, setOtherIncomeSourceError] = useState("");
    const [verificationContact, setVerificationContact] = useState("");
    const [verificationContactError, setVerificationContactError] = useState("");
    const [verificationContactPhone, setVerificationContactPhone] = useState("");
    const [verificationContactPhoneError, setVerificationContactPhoneError] = useState("");
    const [otherFile, setOtherFile] = useState(null);
    const [otherFileError, setOtherFileError] = useState("");
    const [otherIncomeEvidenceUploading, setOtherIncomeEvidenceUploading] = useState(false);

    const [FieldsDisabled, setFieldsDisabled] = useState(false);
    const [SubmittedStatus, setSubmittedStatus] = useState(false);
    const [Modify, setModify] = useState(false);

    const pageLoad = async () => {
        await getTenantLeaseApplication(params.applicationId).then((res) => {
            let application = res?.data?.data;
            if (application) {
                // Variables for current employment and previous employment.
                let applicant, ce, pe;
                // If there two applicants, we need to find the currently logged in user from the array.
                application.applicants.forEach((item, index) => {
                    if (item.user === user.id) {
                        applicant = application.applicants[index];
                        ce = applicant.currentEmployment;
                        pe = applicant.previousEmployment;
                    }
                });

                setCurrentRole(ce?.role ?? "");
                setCurrentEmployer(ce?.employerName ?? "");
                setCurrentManager(ce?.managerName ?? "");
                setCurrentManagerPhone(ce?.managerPhone ?? "");
                setCurrentStreet(ce?.employerAddress.line1 ?? "");
                setCurrentBuilding(ce?.employerAddress.line2 ?? "");
                setCurrentCity(ce?.employerAddress.city ?? "");
                setCurrentState(ce?.employerAddress.state ?? "");
                setCurrentZip(ce?.employerAddress.zipCode ?? "");
                setCurrentYears(ce?.yearsOfEmployment ?? "0");

                setPreviousRole(pe?.role ?? "");
                setPreviousEmployer(pe?.employerName ?? "");
                setPreviousManager(pe?.managerName ?? "");
                setPreviousManagerPhone(pe?.managerPhone ?? "");
                setPreviousStreet(pe?.employerAddress.line1 ?? "");
                setPreviousBuilding(pe?.employerAddress.line2 ?? "");
                setPreviousCity(pe?.employerAddress.city ?? "");
                setPreviousState(pe?.employerAddress.state ?? "");
                setPreviousZip(pe?.employerAddress.zipCode ?? "");

                let incomeDetails = applicant.incomeDetails;
                if (incomeDetails?.monthlyIncome) {
                    setIncome(incomeDetails?.monthlyIncome);
                }
                if (incomeDetails?.incomeVerificationFile) {
                    setPayStub(incomeDetails?.incomeVerificationFile);
                }
                if (incomeDetails?.hasOtherIncome) {
                    setHasOtherIncomeSource("yes");
                } else {
                    setHasOtherIncomeSource("no");
                }
                setOtherIncome(incomeDetails?.otherMonthlyIncome ?? "1");
                setOtherIncomeSource(incomeDetails?.otherIncomeSource ?? "");
                setVerificationContact(incomeDetails?.otherIncomeVerificationContactName ?? "");
                setVerificationContactPhone(incomeDetails?.otherIncomeVerificationContactPhone ?? "");
                if (incomeDetails?.otherIncomeVerificationFile) {
                    setOtherFile(incomeDetails?.otherIncomeVerificationFile);
                }
            }
            if (application?.status == "APPLICANT_SUBMITTED") {
                setFieldsDisabled(true);
                setSubmittedStatus(true);
            }
            if (application?.status == "APPLICANT_WITHDRAWN") {
                setFieldsDisabled(false);
                setModify(true);
            }
        }).catch(err => { console.log(err) });
    }

    useEffect(() => {
        pageLoad();
    }, []);

    // Phone Format
    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setCurrentManagerPhone(formattedPhoneNumber);
    };
    const handleInput1 = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setPreviousManagerPhone(formattedPhoneNumber);
    };
    const handleInput2 = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setVerificationContactPhone(formattedPhoneNumber);
    };

    const onChangeHandler = (event) => {
        const currentManagerPhone = event.target.value
        setCurrentManagerPhone(currentManagerPhone)
    };
    const onChangeHandler1 = (e) => {
        const previousManagerPhone = e.target.value
        setPreviousManagerPhone(previousManagerPhone)
    };
    const onChangeHandler2 = (e) => {
        const verificationContactPhone = e.target.value
        setVerificationContactPhone(verificationContactPhone)
    };

    const renderStateOptions = () => {
        return (
            usStates.map((item) => (
                // <>
                //     <option value={""} key="select">Select</option>
                //     {usStates.map((item) => (
                //         <option key={item} value={item}>
                //             {item}
                //         </option>
                //     ))}
                // </>
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))

        );
    };

    const handlePayStubUpload = (e) => {
        setpayStubUploading(true);
        let form = new FormData();
        form.append("verification-file", e.target.files[0]);
        addTenantIncomeVerificationFile(params.applicationId, form).then(() => {
            toast.success("Pay Stub Updated.");
            pageLoad();
            setPayStubError("");
        }).catch((err) => { setpayStubUploading(false); toast.error(err.response.data.error.message) });
    }

    const handlePayStubRemove = (e) => {
        removeTenantIncomeVerificationFile(params.applicationId).then(() => {
            toast.success("Pay Stub Removed.");
            setPayStub(null);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleOtherFileUpload = (e) => {
        setOtherIncomeEvidenceUploading(true);
        let form = new FormData();
        form.append("verification-file", e.target.files[0]);
        addTenantOtherIncomeVerificationFile(params.applicationId, form).then(() => {
            toast.success("Other Income Evidence Updated");
            pageLoad();
            setOtherFileError("");
        }).catch((err) => {
            setOtherIncomeEvidenceUploading(false);
            toast.error(err.response.data.error.message);
        });
    }

    const handleOtherFileRemove = (e) => {
        removeTenantOtherIncomeVerificationFile(params.applicationId).then(() => {
            toast.success("Other Income Evidence Removed");
            setOtherFile(null);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleModify = () => {
        const res = withDrawApplication(params.applicationId).then((response) => {
            window.location.reload();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };

    const handleEmploymentSubmit = () => {
        let error = false;

        if (currentRole === "") {
            setCurrentRoleError("Position cannot be empty");
            error = true;
        } else {
            setCurrentRoleError("");
        }
        if (currentEmployer === "") {
            setCurrentEmployerError("Employer name cannot be empty");
            error = true;
        } else {
            setCurrentEmployerError("");
        }
        if (currentManager === "") {
            setCurrentManagerError("Manager name cannot be empty");
            error = true;
        } else {
            setCurrentManagerError("");
        }
        if (currentManagerPhone === "") {
            setCurrentManagerPhoneError("Manager phone cannot be empty");
            error = true;
        } else {
            setCurrentManagerPhoneError("");
        }
        if (currentStreet === "") {
            setCurrentStreetError("Street cannot be empty");
            error = true;
        } else {
            setCurrentStreetError("");
        }
        if (currentCity === "") {
            setCurrentCityError("City cannot be empty");
            error = true;
        } else {
            setCurrentCityError("");
        }
        if (currentState === "") {
            setCurrentStateError("State cannot be empty");
            error = true;
        } else {
            setCurrentStateError("");
        }
        if (currentZip === "") {
            setCurrentZipError("Zip code cannot be empty");
            error = true;
        } else if (currentZip && !currentZip.match(/(^\d{5}$)/g)) {
            setCurrentZipError("Enter a valid US Zipcode")
        } else {
            setCurrentZipError("");
        }
        if (Number(income) == 0) {
            setIncomeError("Monthly Income cannot be empty");
            error = true;
        } else {
            setIncomeError("");
        }

        if (Number(currentYears) < 3) {
            if (previousRole === "") {
                setPreviousRoleError("Position cannot be empty");
                error = true;
            } else {
                setPreviousRoleError("");
            }
            if (previousEmployer === "") {
                setPreviousEmployerError("Employer name cannot be empty");
                error = true;
            } else {
                setPreviousEmployerError("");
            }
            if (previousManager === "") {
                setPreviousManagerError("Manager name cannot be empty");
                error = true;
            } else {
                setPreviousManagerError("");
            }
            if (previousManagerPhone === "") {
                setPreviousManagerPhoneError("Manager phone cannot be empty");
                error = true;
            } else {
                setPreviousManagerPhoneError("");
            }
            if (previousStreet === "") {
                setPreviousStreetError("Street cannot be empty");
                error = true;
            } else {
                setPreviousStreetError("");
            }
            if (previousCity === "") {
                setPreviousCityError("City cannot be empty");
                error = true;
            } else {
                setPreviousCityError("");
            }
            if (previousState === "") {
                setPreviousStateError("State cannot be empty");
                error = true;
            } else {
                setPreviousStateError("");
            }
            if (previousZip === "") {
                setPreviousZipError("Zip code cannot be empty");
                error = true;
            } else if (previousZip && !previousZip.match(/(^\d{5}$)/g)) {
                setPreviousZipError("Enter a valid US Zipcode")
            } else {
                setPreviousZipError("");
            }
        }

        if (!payStub) {
            setPayStubError("Upload a pay stub");
            error = true;
        } else {
            setPayStubError("");
        }

        if (hasOtherIncomeSource === "yes") {
            if (otherIncomeSource === "") {
                setOtherIncomeSourceError("Other income source cannot be empty");
                error = true;
            } else {
                setOtherIncomeSourceError("");
            }
            if (verificationContact === "") {
                setVerificationContactError("This field cannot be empty");
                error = true;
            } else {
                setVerificationContactError("");
            }
            if (verificationContactPhone === "") {
                setVerificationContactPhoneError("This field cannot be empty");
                error = true;
            } else {
                setVerificationContactPhoneError("");
            }
            if (!otherFile) {
                setOtherFileError("Upload a verification file");
                error = true;
            } else {
                setOtherFileError("");
            }
        }

        if (error) return;

        let data = {
            currentEmploymentRole: currentRole,
            currentEmployerName: currentEmployer,
            currentEmploymentManagerName: currentManager,
            currentEmploymentManagerPhone: currentManagerPhone,
            currentEmploymentAddressLine1: currentStreet,
            currentEmploymentAddressLine2: currentBuilding,
            currentEmploymentAddressCity: currentCity,
            currentEmploymentAddressState: currentState,
            currentEmploymentAddressZipCode: currentZip,
            currentEmploymentYears: currentYears,
        };

        if (Number(currentYears) < 3) {
            data.previousEmploymentRole = previousRole;
            data.previousEmployerName = previousEmployer;
            data.previousEmploymentManagerName = previousManager;
            data.previousEmploymentManagerPhone = previousManagerPhone;
            data.previousEmploymentAddressLine1 = previousStreet;
            data.previousEmploymentAddressLine2 = previousBuilding;
            data.previousEmploymentAddressCity = previousCity;
            data.previousEmploymentAddressState = previousState;
            data.previousEmploymentAddressZipCode = previousZip;
        }

        data.monthlyIncome = income;

        if (hasOtherIncomeSource === "yes") {
            data.hasOtherIncome = true;
            data.otherMonthlyIncome = otherIncome;
            data.otherIncomeSource = otherIncomeSource;
            data.otherIncomeVerificationContactName = verificationContact;
            data.otherIncomeVerificationContactPhone = verificationContactPhone;
        } else {
            data.hasOtherIncome = false;
        }

        editTenantLeaseApplicantInfo(params.applicationId, data).then(response => {
            toast.success("Updated Employment and Income details");
            pageLoad();
            navigate(`/tenant/lease-applications/${params.applicationId}/verification`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    // return (
    //     <div className="lease-form-item employment-form">
    //         <h1 className="form-header">Employment and Income</h1>
    //         <div className="form-body">
    //             <div className="form-section">
    //                 <h2>Current Employment Details</h2>
    //                 <div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Position/Title</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={currentRole}
    //                                 onChange={e => { setCurrentRole(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentRoleError &&
    //                                 <span className="form-item-error">
    //                                     {currentRoleError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Current Employer</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={currentEmployer}
    //                                 onChange={e => { setCurrentEmployer(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentEmployerError &&
    //                                 <span className="form-item-error">
    //                                     {currentEmployerError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Manager Name</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={currentManager}
    //                                 onChange={e => { setCurrentManager(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentManagerError &&
    //                                 <span className="form-item-error">
    //                                     {currentManagerError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Manager Phone</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={currentManagerPhone}
    //                                 // onChange={e => { setCurrentManagerPhone(e.target.value) }}
    //                                 onChange={(event) => { onChangeHandler(event); handleInput(event) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentManagerPhoneError &&
    //                                 <span className="form-item-error">
    //                                     {currentManagerPhoneError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Street</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={currentStreet}
    //                                 onChange={e => { setCurrentStreet(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentStreetError &&
    //                                 <span className="form-item-error">
    //                                     {currentStreetError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Building</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={currentBuilding}
    //                                 onChange={e => { setCurrentBuilding(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentBuildingError &&
    //                                 <span className="form-item-error">
    //                                     {currentBuildingError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">City</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="text"
    //                                 value={currentCity}
    //                                 onChange={e => { setCurrentCity(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentCityError &&
    //                                 <span className="form-item-error">
    //                                     {currentCityError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">State</span>
    //                             <select
    //                                 className="form-item-input"
    //                                 onChange={e => { setCurrentState(e.target.value) }}
    //                                 value={currentState}
    //                                 disabled={FieldsDisabled}
    //                             >
    //                                 {renderStateOptions()}
    //                             </select>
    //                             {currentStateError &&
    //                                 <span className="form-item-error">
    //                                     {currentStateError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">Zip Code</span>
    //                             <input
    //                                 input maxLength={5}
    //                                 className="form-item-input"
    //                                 type="number"
    //                                 value={currentZip}
    //                                 onChange={e => {
    //                                     if (e.target.value.length == 6) return false;
    //                                     setCurrentZip(e.target.value)
    //                                 }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentZipError &&
    //                                 <span className="form-item-error">
    //                                     {currentZipError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="form-row">
    //                         <div className="form-item">
    //                             <span className="form-item-label">How many years have you been working here?</span>
    //                             <input
    //                                 className="form-item-input"
    //                                 type="number"
    //                                 min="1"
    //                                 value={currentYears}
    //                                 onChange={e => { setCurrentYears(e.target.value) }}
    //                                 disabled={FieldsDisabled}
    //                             />
    //                             {currentYearsError &&
    //                                 <span className="form-item-error">
    //                                     {currentYearsError}
    //                                 </span>
    //                             }
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             {Number(currentYears) < 3 &&
    //                 <div className="form-section">
    //                     <h2>Previous Employment Details</h2>
    //                     <div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Position/Title</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousRole}
    //                                     onChange={e => { setPreviousRole(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousRoleError &&
    //                                     <span className="form-item-error">
    //                                         {previousRoleError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Previous Employer</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousEmployer}
    //                                     onChange={e => { setPreviousEmployer(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousEmployerError &&
    //                                     <span className="form-item-error">
    //                                         {previousEmployerError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Manager Name</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousManager}
    //                                     onChange={e => { setPreviousManager(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousManagerError &&
    //                                     <span className="form-item-error">
    //                                         {previousManagerError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Manager Phone</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousManagerPhone}
    //                                     // onChange={e => { setPreviousManagerPhone(e.target.value) }}
    //                                     onChange={(e) => { onChangeHandler1(e); handleInput1(e) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousManagerPhoneError &&
    //                                     <span className="form-item-error">
    //                                         {previousManagerPhoneError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Street</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousStreet}
    //                                     onChange={e => { setPreviousStreet(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousStreetError &&
    //                                     <span className="form-item-error">
    //                                         {previousStreetError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Building</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousBuilding}
    //                                     onChange={e => { setPreviousBuilding(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousBuildingError &&
    //                                     <span className="form-item-error">
    //                                         {previousBuildingError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">City</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={previousCity}
    //                                     onChange={e => { setPreviousCity(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousCityError &&
    //                                     <span className="form-item-error">
    //                                         {previousCityError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">State</span>
    //                                 <select
    //                                     className="form-item-input"
    //                                     onChange={e => { setPreviousState(e.target.value) }}
    //                                     value={previousState}
    //                                     disabled={FieldsDisabled}
    //                                 >
    //                                     {renderStateOptions()}
    //                                 </select>
    //                                 {previousStateError &&
    //                                     <span className="form-item-error">
    //                                         {previousStateError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Zip Code</span>
    //                                 <input
    //                                     maxLength={5}
    //                                     className="form-item-input"
    //                                     type="number"
    //                                     value={previousZip}
    //                                     onChange={e => {
    //                                         if (e.target.value.length == 6) return false;
    //                                         setPreviousZip(e.target.value)
    //                                     }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {previousZipError &&
    //                                     <span className="form-item-error">
    //                                         {previousZipError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             }
    //             <div className="form-section">
    //                 <h2>Income Details</h2>
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         <span className="form-item-label">Current Monthly Income</span>
    //                         <input
    //                             className="form-item-input"
    //                             type="number"
    //                             min="1"
    //                             value={income}
    //                             onChange={e => { setIncome(e.target.value) }}
    //                             disabled={FieldsDisabled}
    //                         />
    //                         {incomeError &&
    //                             <span className="form-item-error">
    //                                 {incomeError}
    //                             </span>
    //                         }
    //                     </div>
    //                 </div>
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         <span className="form-item-label">Pay Stub</span>
    //                         <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
    //                             <input type="file" id="uploadBtnPayStub"
    //                                 accept="*/*"
    //                                 onChange={handlePayStubUpload}
    //                             />
    //                             {payStub ?
    //                                 <label>
    //                                     <span onClick={handlePayStubRemove}>Remove File</span>
    //                                 </label>
    //                                 :
    //                                 <label htmlFor="uploadBtnPayStub" style={{ fontSize: 13 }}>
    //                                     {payStubUploading ? "Upload Inprogress..." : ""}
    //                                     {!payStubUploading ? "Drop File / click to Upload" : ""}
    //                                 </label>
    //                             }
    //                         </div>
    //                         {payStubError &&
    //                             <span className="form-item-error">
    //                                 {payStubError}
    //                             </span>
    //                         }
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="form-section">
    //                 <h2>Other Income Details</h2>
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         <span className="form-item-label">Do you have other source of income?</span>
    //                         <div onChange={e => {
    //                             setHasOtherIncomeSource(e.target.value);
    //                         }}>
    //                             <label>
    //                                 <input
    //                                     type="radio"
    //                                     value="yes"
    //                                     name="other-income"
    //                                     checked={hasOtherIncomeSource === "yes"}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 Yes
    //                             </label>
    //                             <label>
    //                                 <input
    //                                     type="radio"
    //                                     value="no"
    //                                     name="other-income"
    //                                     checked={hasOtherIncomeSource === "no"}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 No
    //                             </label>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 {hasOtherIncomeSource === "yes" &&
    //                     <>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Other Monthly Income</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="number"
    //                                     min="1"
    //                                     value={otherIncome}
    //                                     onChange={e => { setOtherIncome(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {otherIncomeError &&
    //                                     <span className="form-item-error">
    //                                         {otherIncomeError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Other Income Source</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={otherIncomeSource}
    //                                     onChange={e => { setOtherIncomeSource(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {otherIncomeSourceError &&
    //                                     <span className="form-item-error">
    //                                         {otherIncomeSourceError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Verification Contact</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={verificationContact}
    //                                     onChange={e => { setVerificationContact(e.target.value) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {verificationContactError &&
    //                                     <span className="form-item-error">
    //                                         {verificationContactError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Verification Contact Phone</span>
    //                                 <input
    //                                     className="form-item-input"
    //                                     type="text"
    //                                     value={verificationContactPhone}
    //                                     onChange={(e) => { onChangeHandler2(e); handleInput2(e) }}
    //                                     disabled={FieldsDisabled}
    //                                 />
    //                                 {verificationContactPhoneError &&
    //                                     <span className="form-item-error">
    //                                         {verificationContactPhoneError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">Other Income evidence</span>
    //                                 <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
    //                                     <input type="file" id="uploadBtnotherIncomeEvidence"
    //                                         accept="*/*"
    //                                         onChange={handleOtherFileUpload}
    //                                     />
    //                                     {otherFile ?
    //                                         <label>
    //                                             <span onClick={handleOtherFileRemove}>Remove File</span>
    //                                         </label>
    //                                         :
    //                                         <label htmlFor="uploadBtnotherIncomeEvidence" style={{ fontSize: 13 }}>
    //                                             {otherIncomeEvidenceUploading ? "Upload Inprogress..." : ""}
    //                                             {!otherIncomeEvidenceUploading ? "Drop File / click to Upload" : ""}
    //                                         </label>
    //                                     }
    //                                 </div>
    //                                 {otherFileError &&
    //                                     <span className="form-item-error">
    //                                         {otherFileError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                     </>
    //                 }
    //             </div>
    //         </div>
    //         <div className="form-footer">
    //             {Modify === true ? (
    //                 <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         startIcon={<FaStepBackward />}
    //                         onClick={() => { setCurrentForm(formNames.residenceForm) }}
    //                     >RESIDENCE</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="success"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleEmploymentSubmit}
    //                     >SAVE</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleEmploymentSubmit}
    //                         endIcon={<FaStepForward />}
    //                     >VERIFICATION</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="info"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={() => { setCurrentForm(formNames.agreementForm) }}
    //                         endIcon={<FaStepForward />}
    //                     >GO TO SUBMIT</Button>
    //                 </div>
    //             ) : (
    //                 <>
    //                     {SubmittedStatus === true ? (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 startIcon={<FaStepBackward />}
    //                                 onClick={() => { setCurrentForm(formNames.residenceForm) }}
    //                             >RESIDENCE</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleEmploymentSubmit}
    //                                 endIcon={<FaStepForward />}
    //                             >VERIFICATION</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={(e) => handleModify(e)}
    //                             >MODIFY</Button>
    //                         </div>
    //                     ) : (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 startIcon={<FaStepBackward />}
    //                                 onClick={() => { setCurrentForm(formNames.residenceForm) }}
    //                             >RESIDENCE</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="success"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleEmploymentSubmit}
    //                             >SAVE</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={handleEmploymentSubmit}
    //                                 endIcon={<FaStepForward />}
    //                             > VERIFICATION</Button>
    //                         </div>
    //                     )}
    //                 </>
    //             )}
    //         </div>
    //         <ToastContainer
    //             position="top-center"
    //             autoClose={2000}
    //             hideProgressBar={false}
    //             newestOnTop={false}
    //             closeOnClick
    //             rtl={false}
    //             pauseOnFocusLoss
    //             draggable
    //             pauseOnHover
    //             theme="colored"
    //         />
    //     </div>
    // );
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: "100%", maxWidth: "1350px", }}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TenantLeaseApplicationEditPage />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h3 className="subHeader">Current Employment Details</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    error={currentRoleError ? true : false}
                                    helperText={currentRoleError && currentRoleError}
                                    label="Position/Title"
                                    placeholder="Current Job"
                                    margin="dense"
                                    required={true}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={currentRole}
                                    onChange={e => { setCurrentRole(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={currentEmployerError ? true : false}
                                    helperText={currentEmployerError && currentEmployerError}
                                    label="Current Employer"
                                    placeholder="Current Employer Name / Company"
                                    required={true}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={currentEmployer}
                                    onChange={e => { setCurrentEmployer(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={currentManagerError ? true : false}
                                    helperText={currentManagerError && currentManagerError}
                                    label="Manager Name"
                                    placeholder="Manager Name"
                                    required={true}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={currentManager}
                                    onChange={e => { setCurrentManager(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={currentManagerPhoneError ? true : false}
                                    helperText={currentManagerPhoneError && currentManagerPhoneError}
                                    label="Manager (work) Phone#"
                                    placeholder="Eg (123) 456-7890"
                                    required={true}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={currentManagerPhone}
                                    onChange={(event) => { onChangeHandler(event); handleInput(event) }}
                                    disabled={FieldsDisabled}
                                // InputProps={{
                                //     startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                // }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    error={currentStreetError ? true : false}
                                    helperText={currentStreetError && currentStreetError}
                                    label="Street"
                                    placeholder="Enployer Street Address"
                                    required={true}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={currentStreet}
                                    onChange={e => { setCurrentStreet(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={currentBuildingError ? true : false}
                                    helperText={currentBuildingError && currentBuildingError}
                                    label="Building"
                                    placeholder="Employer Building"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={currentBuilding}
                                    onChange={e => { setCurrentBuilding(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={currentCityError ? true : false}
                                    helperText={currentCityError && currentCityError}
                                    label="City"
                                    placeholder="Enter Employer City"
                                    required={true}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={currentCity}
                                    onChange={e => { setCurrentCity(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <FormControl
                                    fullWidth={true}
                                    error={currentStateError ? true : false}
                                    margin="dense"
                                    disabled={FieldsDisabled}
                                    required={true}>
                                    <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                                    <Select
                                        label={"Select State"}
                                        fullWidth={true}
                                        MenuProps={MenuProps}
                                        variant="outlined"
                                        onChange={e => { setCurrentState(e.target.value) }}
                                        value={currentState}
                                    >
                                        <MenuItem value={""}>Select</MenuItem>
                                        {renderStateOptions()}
                                    </Select>
                                    {currentStateError && <FormHelperText>{currentStateError}</FormHelperText>}
                                </FormControl>
                                <TextField
                                    error={currentZipError ? true : false}
                                    helperText={currentZipError && currentZipError}
                                    label="Zip Code"
                                    placeholder="Enter Zipcode"
                                    required={true}
                                    type="number"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={currentZip}
                                    onChange={e => {
                                        if (e.target.value.length == 6) return false;
                                        setCurrentZip(e.target.value)
                                    }}
                                    disabled={FieldsDisabled}
                                />
                                <TextField
                                    error={currentYearsError ? true : false}
                                    helperText={currentYearsError && currentYearsError}
                                    label="How many years have you been working here?"
                                    type="number"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    value={currentYears}
                                    onChange={e => { setCurrentYears(e.target.value) }}
                                    disabled={FieldsDisabled}
                                    inputProps={{
                                        min: "1",
                                    }}
                                    // startAdornment={<InputAdornment position="end">$</InputAdornment>}
                                />
                            </Grid>
                            {Number(currentYears) < 3 &&
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h3 className="subHeader">Previous Employment Details</h3>
                                </Grid>
                            }
                            {Number(currentYears) < 3 &&
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <TextField
                                        error={previousRoleError ? true : false}
                                        helperText={previousRoleError && previousRoleError}
                                        label="Position/Title"
                                        placeholder="Previous Job"
                                        margin="dense"
                                        required={true}
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousRole}
                                        onChange={e => { setPreviousRole(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <TextField
                                        error={previousEmployerError ? true : false}
                                        helperText={previousEmployerError && previousEmployerError}
                                        label="Previous Employer"
                                        placeholder="Previous Employer"
                                        required={true}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousEmployer}
                                        onChange={e => { setPreviousEmployer(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <TextField
                                        error={previousManagerError ? true : false}
                                        helperText={previousManagerError && previousManagerError}
                                        label="Manager Name"
                                        placeholder="Manager Name"
                                        required={true}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousManager}
                                        onChange={e => { setPreviousManager(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <TextField
                                        error={previousManagerPhoneError ? true : false}
                                        helperText={previousManagerPhoneError && previousManagerPhoneError}
                                        label="Manager (work) Phone#"
                                        placeholder="Eg (123) 456-7890"
                                        required={true}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousManagerPhone}
                                        onChange={(e) => { onChangeHandler1(e); handleInput1(e) }}
                                        disabled={FieldsDisabled}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                    // }}
                                    />
                                </Grid>
                            }
                            {Number(currentYears) < 3 &&
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <TextField
                                        error={previousStreetError ? true : false}
                                        helperText={previousStreetError && previousStreetError}
                                        label="Address line1"
                                        placeholder="Employer Address line1"
                                        required={true}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousStreet}
                                        onChange={e => { setPreviousStreet(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <TextField
                                        error={previousBuildingError ? true : false}
                                        helperText={previousBuildingError && previousBuildingError}
                                        label="Address line2"
                                        placeholder="Employer Address line2"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousBuilding}
                                        onChange={e => { setPreviousBuilding(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <TextField
                                        error={previousCityError ? true : false}
                                        helperText={previousCityError && previousCityError}
                                        label="City"
                                        placeholder="Enter Employer City"
                                        required={true}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousCity}
                                        onChange={e => { setPreviousCity(e.target.value) }}
                                        disabled={FieldsDisabled}
                                    />
                                    <FormControl
                                        fullWidth={true}
                                        error={previousStateError ? true : false}
                                        margin="dense"
                                        disabled={FieldsDisabled}
                                        required={true}>
                                        <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                                        <Select
                                            label={"Select State"}
                                            fullWidth={true}
                                            MenuProps={MenuProps}
                                            variant="outlined"
                                            onChange={e => { setPreviousState(e.target.value) }}
                                            value={previousState}
                                        >
                                            <MenuItem value={""}>Select</MenuItem>
                                            {renderStateOptions()}
                                        </Select>
                                        {previousStateError && <FormHelperText>{previousStateError}</FormHelperText>}
                                    </FormControl>
                                    <TextField
                                        error={previousZipError ? true : false}
                                        helperText={previousZipError && previousZipError}
                                        label="Zip Code"
                                        placeholder="Enter Zipcode"
                                        required={true}
                                        type="number"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={previousZip}
                                        onChange={e => {
                                            if (e.target.value.length == 6) return false;
                                            setPreviousZip(e.target.value)
                                        }}
                                        disabled={FieldsDisabled}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <h4 className="subHeader">Income Details</h4>
                                <TextField
                                    error={incomeError ? true : false}
                                    helperText={incomeError && incomeError}
                                    label="Current Monthly Income"
                                    placeholder="Enter your Current Monthly Income"
                                    type="number"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    value={income}
                                    onChange={e => { setIncome(e.target.value) }}
                                    disabled={FieldsDisabled}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <h5 className="subHeader">Pay Stub</h5>
                                <div className="uploadBtn" style={{ width: "175px", height: "160px", border: payStubError ? '1px solid #d32f2f' : '1px solid #e4e4e4' }}>
                                    <input type="file" id="uploadBtnPayStub"
                                        accept="*/*"
                                        onChange={handlePayStubUpload}
                                    />
                                    {payStub ?
                                        <label>
                                            <span onClick={handlePayStubRemove}>Remove File</span>
                                            <span title={payStub?.fileName}>{truncateAddress(payStub?.fileName, 20)}</span>
                                        </label>
                                        :
                                        <label htmlFor="uploadBtnPayStub" style={{ fontSize: 13 }}>
                                            {payStubUploading ? "Upload Inprogress..." : ""}
                                            {!payStubUploading ? "Drop File / click to Upload" : ""}
                                        </label>
                                    }
                                </div>
                                <h3 className="subHeader">Do you have other source of income?</h3>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(e) => { setHasOtherIncomeSource(e.target.value) }}
                                    >
                                        <FormControlLabel value="yes" checked={hasOtherIncomeSource === "yes"} control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" checked={hasOtherIncomeSource === "no"} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                                {hasOtherIncomeSource === "yes" &&
                                    <h3 className="subHeader">Other Monthly Income</h3>
                                }
                                {hasOtherIncomeSource === "yes" &&
                                    <div>
                                        <TextField
                                            error={otherIncomeError ? true : false}
                                            helperText={otherIncomeError && otherIncomeError}
                                            label="Other Monthly Income"
                                            placeholder="Enter Amount Income"
                                            required={true}
                                            type="number"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={otherIncome}
                                            onChange={e => { setOtherIncome(e.target.value) }}
                                            disabled={FieldsDisabled}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                        />
                                        <TextField
                                            error={otherIncomeSourceError ? true : false}
                                            helperText={otherIncomeSourceError && otherIncomeSourceError}
                                            label="Other Income Source"
                                            placeholder="Enter Description"
                                            required={true}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={otherIncomeSource}
                                            onChange={e => { setOtherIncomeSource(e.target.value) }}
                                            disabled={FieldsDisabled}
                                        />
                                        <TextField
                                            error={verificationContactError ? true : false}
                                            helperText={verificationContactError && verificationContactError}
                                            label="Verification Contact"
                                            placeholder="Verification Contact Name"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                            value={verificationContact}
                                            onChange={e => { setVerificationContact(e.target.value) }}
                                            disabled={FieldsDisabled}
                                        // InputProps={{
                                        //     startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                        // }}
                                        />
                                        <TextField
                                            error={verificationContactPhoneError ? true : false}
                                            helperText={verificationContactPhoneError && verificationContactPhoneError}
                                            label="Verification Contact Phone #"
                                            placeholder="Eg (123) 456-7890"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                            value={verificationContactPhone}
                                            onChange={(e) => { onChangeHandler2(e); handleInput2(e) }}
                                            disabled={FieldsDisabled}
                                        // InputProps={{
                                        //     startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                        // }}
                                        />
                                        <h5 className="subHeader">Other Income evidence</h5>
                                        <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
                                            <input type="file" id="uploadBtnotherIncomeEvidence"
                                                accept="*/*"
                                                onChange={handleOtherFileUpload}
                                            />
                                            {otherFile ?
                                                <label>
                                                    <span onClick={handleOtherFileRemove}>Remove File</span>
                                                    <span title={otherFile?.fileName}>{truncateAddress(otherFile?.fileName, 20)}</span>
                                                </label>
                                                :
                                                <label htmlFor="uploadBtnotherIncomeEvidence" style={{ fontSize: 13 }}>
                                                    {otherIncomeEvidenceUploading ? "Upload Inprogress..." : ""}
                                                    {!otherIncomeEvidenceUploading ? "Drop File / click to Upload" : ""}
                                                </label>
                                            }
                                        </div>
                                        {otherFileError &&
                                            <span className="form-item-error">
                                                {otherFileError}
                                            </span>
                                        }
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                {Modify === true ? (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            startIcon={<FaStepBackward />}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/residence`); }}
                                        >RESIDENCE</Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleEmploymentSubmit}
                                        >SAVE</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleEmploymentSubmit}
                                            endIcon={<FaStepForward />}
                                        >VERIFICATION</Button>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            sx={{ fontSize: 15 }}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/agreement`); }}
                                            endIcon={<FaStepForward />}
                                        >GO TO SUBMIT</Button>
                                    </div>
                                ) : (
                                    <>
                                        {SubmittedStatus === true ? (
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    startIcon={<FaStepBackward />}
                                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/residence`); }}
                                                >RESIDENCE</Button>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleEmploymentSubmit}
                                                    endIcon={<FaStepForward />}
                                                >VERIFICATION</Button>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={(e) => handleModify(e)}
                                                >MODIFY</Button>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    startIcon={<FaStepBackward />}
                                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/residence`); }}
                                                >RESIDENCE</Button>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleEmploymentSubmit}
                                                >SAVE</Button>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleEmploymentSubmit}
                                                    endIcon={<FaStepForward />}
                                                > VERIFICATION</Button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default EmploymentForm;