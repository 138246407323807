import { CardContent, Typography } from '@mui/material'
import React from 'react'

function PrivacyPolicy() {
    return (
        <div style={{ padding: "1.5rem" }}>
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", paddingTop: "0px" }}>
                <CardContent style={{ width: "100%", maxWidth: "1350px", p: 0, flexGrow: 1, paddingTop: "0px" }}>
                    <div className='privacy-policy-heading-bottom-line'>
                        <p className='privacy-policy-heading'>
                            Privacy Policy & Terms and Conditions
                        </p>
                    </div>
                    <div>
                        <p className='privacy-policy-text'>
                            Our goal is to make unityon the most trusted place to manage real estate. To do this, we need your help, so any suggestions for improvement can be <span className='privacy-policy-blue-color'>sent to us</span> directly.
                        </p>
                        <p className='privacy-policy-text'>
                            unityon.com is a new framework loved by both landlords and tenants designed to help them communicate more effectively. It saves landlord and property managers time by allowing clearer and efficient communications with prospective and current tenants. Tenants save time by transacting and tracking all their communication in one place. Landlords and property managers no longer need to deal with paper applications, paper leases, or mind-numbing spreadsheets. unityon is built by experienced people who understand both sides and we sincerely want these relationships to improve.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            For the Owner
                        </p>
                        <p className='privacy-policy-text-left-margin-with-lighter'>
                            Owner understands that "unityon.com", can make no guarantee as to the credit or character of any applicant or tenant. unityon.com accepts no responsibility for any act or omission of any applicant or tenant. We are here to help you with the mundane tasks, and we ultimately rely on your judgement to make the right decisions with regard to tenancy.
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            unityon.com is given permission to disseminate information concerning the property for purposes of rental, including, but not limited to, listing the property on partner websites and publications if deemed necessary at our sole discretion. unityon.com shall not be liable for error or omissions in connection with submitted data.
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            It is not the responsibility of unityon.com to procure city, county, state, or other such local rental licenses/permits if it applies to your property. Owner also understands that it is Owner's responsibility to provide state-required brochures related to tenant responsibilities, operating manuals, pest control notices and history, mold or radon notices and history, methamphetamine history, or any such state-required documentation to the tenant. Owner warrants that documented appliances and systems noted in any lease or otherwise displayed with unityon.com will be operational at time of occupancy and does not hold unityon.com liable for such appliances and systems.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Personal Information
                        </p>
                        <p className='privacy-policy-text-left-margin-with-lighter'>
                            unityon collects personally identifiable information that you provide to us. This includes (at your discretion) your name, an email address, and a password. For applicants, we collect rental application information. For managers, we collect some basic company information. Our software uses the information to personalize and manage unityon for you. If you do decide to give us this information, you are telling us that this information is both true and accurate. Also, you are telling us that you have the authorization to provide us with that information.
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            To make our site more efficient, we may also collect certain non personally identifiable information (such as site usage behavior) based on your use of unityon. We may use this information to display advertising that you find interesting and suppress advertising that you might not find useful.
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            To help us debug problems with our software (rare as they may be), we may collect some technical information from your browser software (such as Mozilla Firefox, Chrome, Safari, or Internet Explorer), screen resolution and the website that referred you to us.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Opt-out Policy
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            The use of your email address for login allows unityon the chance to email you a new password in case you forget or lose your old one. This is perfectly fine and happens to the best of us, particularly those of us that are incredibly busy. If you do register with us, unityon will save your information and recognize you. So if you are visiting friends and using their computer, we will know it is you and display your preferences when you log in.
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            unityon gives you the option of receiving emails from us. You can opt out any time. Our emails are sent from a unityon address and are sent to your email address. Every email that we send has an opt-out URL, so you can unsubscribe when you want. We hate spam at unityon and suspect that you do too.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Information Sharing
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            The personally identifiable information that you chose to share with us is not going anywhere off of our site. We will not sell your information to anyone. There might be one exception in the event that unityon itself is sold. In this case, we would transfer your information to our buyers so that they could continue to provide the same great service.
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            unityon occasionally will share aggregated usage information with others. This type of information does not contain your identity or anything personally identifiable to you.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Disclosures
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            unityon may disclose to authorized law enforcement personnel the personal data from anyone who poses a threat to our interests. This might include those that are attempting to commit fraud or those who conduct activities that could bring harm to others. We assume that this is not you so please do not worry.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Protecting Your Data
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            We store your information in secure data centers located in the United States. We also use Secure Socket Layer (SSL) technology that protects the data you send over the Internet. We take information security seriously and use bank-level security where it is needed.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Cookies, Logs, and JS
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            When you visit unityon, we may store information about your web session and assign you one or more "cookies." Your browser will store these cookies on your computer in a small text file. For example, when you decide to login as a unityon user, we store encrypted information in your browser so you don't have to login on every page. unityon uses cookies to improve this site and deliver a personalized, secure and responsive service.
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            Permanent cookie files remain on your computer's hard drive until you delete them manually using your browser or operating system software. You can enable your browser to refuse cookies, but doing so may disable access to some services and tools that we offer. Disabling Javascript in your browser will also disable many of the features of the site, so we ask that you leave it enabled in your browser (as it is by default).
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            Our third party advertising partners often use a cookie or third party web beacon (a single pixel GIF image) track clicks. No personally identifiable information is ever sent to these advertising partners.
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            We use third-party advertising companies to serve ads when you visit our web site. These companies may use information (not including your name, address, email address or telephone number) about your visits to this and other Web sites to provide advertisements about goods and services of interest to you.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Partners
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            Although we work with responsible third parties, their business policies may include different privacy policies and practices than we have. So when you click through to third party websites, our privacy policies no longer apply. We encourage you to read the privacy policies of these partner websites.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Not for Minors
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            unityon does not knowingly and directly collect personally identifiable information from people under the age of 18. If we become aware that a minor is attempting to submit this information, we will inform the minor that he or she is ineligible to do so. We will then remove this information from our records. This does not include minor information included by a parent or guardian on a rental application.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Representation
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            unityon makes no representations regarding the appropriateness of any financials listed on this website or guarantees any financial result. You should carefully review any investment and seek appropriate professional services if you have any questions.
                        </p>
                        <p className='privacy-policy-text-lighter'>
                            Future Changes
                        </p>
                        <p className='privacy-policy-text-left-margin'>
                            unityon reserves the right to modify this privacy statement at any time. If there are material changes to this statement on how unityon will use your personal information, we will prominently post these changes prior to implementing them. If you use our website after we post these changes, this indicates your acceptance of them. Regardless of later updates, we will follow the privacy practices described in this privacy statement at the time you provided us with your information.
                        </p>
                    </div>
                </CardContent>
            </div >
        </div >
    )
}

export default PrivacyPolicy