import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, Icon, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextareaAutosize, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { deleteFinanceDetailsNew, deleteFinanceTransactions, getFinanceLastSelectedDate, getGroupFinanceTransactionsReconciled, getManagerGroupsPropertiesAddress, getManagerPropertyGroupsGroups, getUser, patchAddFinanceDetailsNew, patchFinanceTransactions, patchFinanceTransactionsReconlationStatus, patchLastSelectedDateReconlationStatus, postAddFinanceDetailsNew, postFinanceDetailsCsvFileUpload, postFinanceLastSelectedDate } from '../../../services';
import { CSVLink } from 'react-csv';
import { Expenses, Incomes } from '../../../helpers';
import FinanceCategoriesUppercase from '../../../components/financeCategoriesUppercase';
import { useNavigate } from 'react-router-dom';
import { truncateAddress } from '../../../components/textTruncate/truncate';
import Select from '@mui/material/Select';
import imageClick from '../../../components/imageView';
import Papa from 'papaparse';
import { MenuProps } from '../../../components/MaterialUiSelectMenuStyle';
import CircularProgressWithLabel from '../../../components/financeCsvUploadProgressBar/financeCsvUploadProgressBar';
import '../../../Styles/manager/financeViewDetails.css'

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        color: 'white',
        boxShadow: theme.shadows[5],
        fontSize: 14,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        "&::before": {
            backgroundColor: theme.palette.common.black,
            color: 'white',
            border: "1px solid #999"
        }
    },
}));

const editFinanceTransactionExpense = [
    {
        value: "OTHER_EXPENSE",
        label: "Other Expense",
    },
    {
        value: "TAXES",
        label: "Taxes",
    },
    {
        value: "INSURANCE_EXPENSE",
        label: "Insurance Expense",
    },
    {
        value: "ADVERTISING_EXPENSE",
        label: "Advertising Expense",
    },
    {
        value: "CLEANING_AND_MAINTENANCE_EXPENSE",
        label: "Cleaning and Maintenance Expense",
    },
    {
        value: "LEGAL_AND_PROFESSIONAL_SERVICE_EXPENSE",
        label: "Legal and Professional Services Expense",
    },
    {
        value: "MANAGEMENT_EXPENSE",
        label: "Management Expense",
    },
    {
        value: "MEALS_EXPENSE",
        label: "Meals Expense",
    },
    {
        value: "REPAIRS_EXPENSE",
        label: "Repairs Expense",
    },
    {
        value: "SUPPLIES_EXPENSE",
        label: "Supplies Expense",
    },
    {
        value: "TRAVEL_EXPENSE",
        label: "Travel Expense",
    },
    {
        value: "UTILITIES_EXPENSE",
        label: "Utilities Expense",
    },
    {
        value: "CAPITAL_EXPENSE",
        label: 'Capital Expense',
    },
    {
        value: "MORTGAGES_AND_LOANS_EXPENSE",
        label: 'Mortgages and Loans Expense',
    },
    {
        value: "OTHER_INCOME",
        label: "Other Income",
    },
    {
        value: "RENTAL_INCOME",
        label: "Rental Income",
    },
    {
        value: "PARKING_INCOME",
        label: "Parking Income"
    },
    {
        value: "LAUNDRY_SERVICE_INCOME",
        label: "Laundry Service Income"
    },
    {
        value: "SHORT_TERM_RENTS_INCOME",
        label: "Short Term Rents Income"
    },
    {
        value: "LATE_FEES_INCOME",
        label: "Late Fees Income"
    },
    {
        value: "STORAGE_INCOME",
        label: "Storage Income"
    },
    {
        value: "MISC_INTEREST_INCOME",
        label: "Misc Interest Income"
    },
]

const securityDeposit = [
    {
        value: "SECURITY_DEPOSITS",
        label: "Security Deposits"
    },
]

function ViewDetails() {
    // Income and Expenses
    const inComes = [
        "RENTAL_INCOME",
        "OTHER_INCOME",
        "PARKING_INCOME",
        "LAUNDRY_SERVICE_INCOME",
        "SHORT_TERM_RENTS_INCOME",
        "LATE_FEES_INCOME",
        "STORAGE_INCOME",
        "MISC_INTEREST_INCOME"];
    const expenses = [
        "ADVERTISING_EXPENSE",
        "CLEANING_AND_MAINTENANCE_EXPENSE",
        "INSURANCE_EXPENSE",
        "LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE",
        "MANAGEMENT_EXPENSE",
        "MEALS_EXPENSE",
        "OTHER_EXPENSE",
        "REPAIRS_EXPENSE",
        "SUPPLIES_EXPENSE",
        "TAXES",
        "TRAVEL_EXPENSE",
        "UTILITIES_EXPENSE",
        "CAPITAL_EXPENSE",
        "MORTGAGES_AND_LOANS_EXPENSE"];

    const navigate = useNavigate();
    const [userName, setUserName] = useState("");

    const [startDate, setStartDate] = useState(new Date("2023-01-01"));
    const [endDate, setEndDate] = useState(new Date());
    const [lastSelectedDateReConcilated, setLastSelectedDateReConcilated] = useState(false);

    // grouped data
    const [groupedData, setGroupedData] = useState({});

    // Loader
    const [loading, setLoading] = useState(true);

    // CSV
    const [csvData, setCsvData] = useState([]);

    // Load Csv Data Dialog
    const [loadCsvDialog, setLoadCsvDialog] = useState(false);
    const [selectedLoadCsvUpload, setselectedLoadCsvUpload] = useState(null);
    const [selectedLoadCsvFileParsed, setselectedLoadCsvFileParsed] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Dialog box state
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("0");

    const [categoryType, setCategoryType] = useState("");
    const [categories, setCategories] = useState(securityDeposit);

    const [PropGroups, setPropGroups] = useState([]);
    const [propertyGroups, setPropertyGroups] = useState("");

    const [addresses, setAddresses] = useState("");
    const [selectedAddress, setSelectedAddress] = useState([]);

    const [description, setDescription] = useState("");
    const [comment, setComment] = useState("");

    const [categorySelectedValue, setCategorySelectedValue] = useState(0);

    // Attachment Add and Update
    const [attachments, setAttachments] = useState([]);

    // Validation
    const [dateError, setDateError] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [categoryTypeError, setCategoryTypeError] = useState(false);
    const [properyGroupError, setPropertyGroupError] = useState(false);
    const [addressesError, setAddressesError] = useState(false);

    // For Dialog box
    const [addFinance, setAddFinance] = useState(false);
    const [deleteFinanceDialog, setDeleteFinanceDialog] = useState(false);
    const [updateCondition, setUpdateCondition] = useState({
        condition: false,
        propertyId: "",
        transactionId: ""
    })

    // Attachment Add and Update
    const handleAttachmentChange = (e) => {
        setAttachments([...attachments, ...e.target.files]);
    }

    const removeAttachMents = (index) => {
        let toDelete;
        const newAttachments = attachments.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });
        setAttachments(newAttachments);
    };

    // Load Csv Data Dialog and Add Function
    const LoadCsvDialogOpen = () => {
        setselectedLoadCsvUpload(null);
        setLoadCsvDialog(true);
    }
    const LoadCsvDialogClose = () => {
        setLoadCsvDialog(false);
    }

    const handleSelectLoadCsvUpload = (e) => {
        setselectedLoadCsvUpload(e.target.files[0]);
        Papa.parse(e.target.files[0], {
            complete: (result) => {
                setselectedLoadCsvFileParsed(result.data);
            },
            header: true,
            encoding: 'UTF-8',
        });
    }

    // Initial Load Api
    const getReconcilatedTransactions = async () => {
        try {
            setLoading(true);
            let tran = [];
            tran.length = 0;
            let csv = [...csvData];
            csv.length = 0;
            await getUser().then((res) => {
                let data = res?.data?.data;
                setUserName(data?.name.first + " " + data?.name.last);
            })
            await getFinanceLastSelectedDate().then((response) => {
                if (response.data.data.length !== 0) {
                    setStartDate(response?.data?.data[0]?.FinancesStartDate);
                    setEndDate(response?.data?.data[0]?.FinancesEndDate);
                    setLastSelectedDateReConcilated(response?.data?.data[0]?.reconciledCheckBoxStatus);
                }
                getGroupFinanceTransactionsReconciled(`?timeBegin=${response.data.data.length !== 0 ? Date.parse(response.data.data[0].FinancesStartDate) : startDate}&timeEnd=${response.data.data.length !== 0 ? Date.parse(response.data.data[0].FinancesEndDate) : endDate}`).then((res) => {
                    let data = res?.data?.data
                    data.sort((a, b) => new Date(a.date) - new Date(b.date));
                    data.map((reconcilated) => {
                        if (response?.data?.data[0]?.reconciledCheckBoxStatus == reconcilated?.reconciledCheckBoxStatus) {
                            tran.push({
                                addedByManager: reconcilated?.addedByManager,
                                amount: reconcilated?.amount,
                                date: reconcilated?.date,
                                description: reconcilated?.description,
                                id: reconcilated?.id,
                                lease: reconcilated?.lease,
                                month: reconcilated?.month,
                                sourceId: reconcilated?.sourceId,
                                sourceType: reconcilated.sourceType,
                                type: reconcilated?.type,
                                year: reconcilated?.year,
                                groupName: reconcilated?.groupName,
                                property: reconcilated?.property,
                                propertyName: reconcilated?.propertyName,
                                transactionId: reconcilated?.transactionId,
                                groupId: reconcilated?.groupId,
                                comments: reconcilated?.comments,
                                address: reconcilated?.address,
                                reciepts: reconcilated?.receipts,
                                travelMiles: reconcilated?.travelMiles,
                                reconciledCheckBoxStatus: reconcilated?.reconciledCheckBoxStatus,
                            })
                            csv.push({
                                Date: reconcilated?.date ? moment(reconcilated?.date).format('MMM D, YYYY') : "",
                                Description: reconcilated?.description,
                                Amount: reconcilated?.type === "TRAVEL_MILES" ? reconcilated.travelMiles : reconcilated?.amount,
                                property: reconcilated?.propertyName,
                                Complex: reconcilated?.groupName,
                                type: reconcilated?.type,
                            })
                        }
                    })
                    setCsvData(csv);
                    let groupedPeople = groupBy(tran, "year");
                    setGroupedData(groupedPeople);
                })
            })
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = {};
            }
            if (!acc[key][obj.month]) {
                acc[key][obj.month] = [];
            }
            acc[key][obj.month].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        getReconcilatedTransactions();
    }, []);

    // Dialog Box
    const handleOpenAddFinance = async (status, date, amount, description, group, comment, address, type, prop, transId, receipts) => {
        if (status == "edit") {
            await getManagerGroupsPropertiesAddress(group).then((res) => {
                let data = res?.data?.data;
                setSelectedAddress(data);
            })
            setDate(date);
            setAmount(amount);
            setDescription(description);
            setPropertyGroups(group);
            setAddresses(address);
            setComment(comment);
            changeCategoryForEdit(type);
            setCategoryType(type);
            setAttachments(receipts)
            setAddFinance(true);
            setUpdateCondition({
                condition: true,
                propertyId: prop,
                transactionId: transId
            });
        }
        if (status == "add") {
            setAddFinance(true);
        }
        await getManagerPropertyGroupsGroups().then((res) => {
            let data = res?.data?.data;
            setPropGroups(data);
        })
    }

    const handleCloseAddFinance = () => {
        setDate(null);
        setAmount("0");
        setDescription("");
        setCategoryType("");
        setPropertyGroups("");
        setAddresses("");
        setComment("");
        setCategories(securityDeposit);
        setAddFinance(false);
        setCategorySelectedValue(0);
        setAttachments([]);
        setUpdateCondition({
            condition: false,
            propertyId: "",
            transactionId: ""
        });
    }

    const FinancedeletedDialogOpen = (propId, tranId) => {
        setDeleteFinanceDialog(true);
        setUpdateCondition({
            condition: false,
            propertyId: propId,
            transactionId: tranId
        });
    }
    const FinancedeletedDialogClose = () => {
        setDeleteFinanceDialog(false);
        setUpdateCondition({
            condition: false,
            propertyId: "",
            transactionId: ""
        });
    }

    // Handle Changes

    const changeCategoryForEdit = (type) => {
        if (Incomes.find(e => e.value == type)) {
            setCategorySelectedValue(1);
            setCategories(Incomes);
        }
        if (Expenses.find(e => e.value == type)) {
            setCategorySelectedValue(2);
            setCategories(Expenses);
        }
    }

    const changeCategory = (e) => {
        if (e.target.value === "income") {
            setCategorySelectedValue(1);
            setCategories(Incomes);
        }
        if (e.target.value === "expense") {
            setCategorySelectedValue(2);
            setCategories(Expenses);
        }
        setCategoryType("");
    }

    const handlePropertyGroupChange = async (e) => {
        setPropertyGroups(e.target.value);
        await getManagerGroupsPropertiesAddress(e.target.value).then((res) => {
            let data = res?.data?.data;
            setSelectedAddress(data);
        })
    }

    const refreshClick = async () => {
        let date = {
            FinancesStartDate: startDate,
            FinancesEndDate: endDate
        }
        await postFinanceLastSelectedDate(date).then((res) => {
            getReconcilatedTransactions();
        })
    }
    // Validation function
    const validate = () => {
        let error = false;

        if (date === null) {
            setDateError(true);
            error = true;
        } else {
            setDateError(false);
        }
        if (Number(amount) == 0) {
            setAmountError(true);
            error = true;
        } else {
            setAmountError(false);
        }
        if (categoryType === "") {
            setCategoryTypeError(true);
            error = true;
        } else {
            setCategoryTypeError(false);
        }
        if (propertyGroups === "") {
            setPropertyGroupError(true);
            error = true;
        } else {
            setPropertyGroupError(false);
        }
        if (addresses === "") {
            setAddressesError(true);
            error = true;
        } else {
            setAddressesError(false);
        }

        return error;
    }

    // Api Calls For Add, Update, Delete Finance
    const addFinanceDetailsSaveClick = async () => {
        let validation = validate();
        if (validation) return;

        let formData = new FormData();
        formData.append("amount", Number(amount));
        formData.append("description", description);
        formData.append("date", date);
        formData.append("type", categoryType);
        formData.append("address", addresses);
        formData.append("comments", comment);
        formData.append("groupId", propertyGroups);
        Array.from(attachments).forEach(item => { formData.append("receipts", item) });
        await postAddFinanceDetailsNew(formData).then((res) => {
            handleCloseAddFinance();
            getReconcilatedTransactions();
        })
    }

    const UpdateFinanceDetailsUpdateClick = async () => {
        let validation = validate();
        if (validation) return;

        let array = [];
        attachments.map((file) => {
            if (file.url) {
                array.push(file)
            }
        })
        let formData = new FormData();
        formData.append("amount", Number(amount));
        formData.append("description", description);
        formData.append("date", date);
        formData.append("type", categoryType);
        formData.append("address", addresses);
        formData.append("comments", comment);
        formData.append("groupId", propertyGroups);
        formData.append("existingReceipts", JSON.stringify(array))
        Array.from(attachments).forEach(item => { formData.append("newReceipts", item) });

        await patchAddFinanceDetailsNew(updateCondition.transactionId, formData).then((res) => {
            handleCloseAddFinance();
            getReconcilatedTransactions();
        })
    }

    const deleteFinanceViewDetailsPageById = async (propId, transId) => {
        await deleteFinanceDetailsNew(propId, transId).then((res) => {
            FinancedeletedDialogClose();
            getReconcilatedTransactions();
        })
    }

    // Calculations

    const yearGroupTotalIncome = (year) => {
        if (groupedData.hasOwnProperty(year)) {
            const yearGroup = groupedData[year];
            let totalIncome = 0;
            for (const month in yearGroup) {
                if (yearGroup.hasOwnProperty(month)) {
                    for (const transaction of yearGroup[month]) {
                        if (inComes.includes(transaction.type)) {
                            if (transaction.amount > 0) {
                                totalIncome += transaction.amount;
                            }
                        }
                    }
                }
            }
            return totalIncome.toFixed(2);
        }
        return "0.00";
    }

    const monthGroupTotalIncome = (year, month) => {
        if (groupedData.hasOwnProperty(year) && groupedData[year].hasOwnProperty(month)) {
            const monthGroup = groupedData[year][month];
            let totalIncome = 0;
            for (const transaction of monthGroup) {
                if (inComes.includes(transaction.type)) {
                    if (transaction.amount > 0) {
                        totalIncome += transaction.amount;
                    }
                }
            }
            return totalIncome.toFixed(2);
        }
        return "0.00";
    }

    const yearGroupTotalExpense = (year) => {
        if (groupedData.hasOwnProperty(year)) {
            const yearGroup = groupedData[year];
            let totalIncome = 0;
            for (const month in yearGroup) {
                if (yearGroup.hasOwnProperty(month)) {
                    for (const transaction of yearGroup[month]) {
                        if (expenses.includes(transaction.type)) {
                            if (transaction.amount > 0) {
                                totalIncome += transaction.amount;
                            }
                        }
                    }
                }
            }
            return totalIncome.toFixed(2);
        }
        return "0.00";
    }

    const monthGroupTotalExpense = (year, month) => {
        if (groupedData.hasOwnProperty(year) && groupedData[year].hasOwnProperty(month)) {
            const monthGroup = groupedData[year][month];
            let totalIncome = 0;
            for (const transaction of monthGroup) {
                if (expenses.includes(transaction.type)) {
                    if (transaction.amount > 0) {
                        totalIncome += transaction.amount;
                    }
                }
            }
            return totalIncome.toFixed(2);
        }
        return "0.00";
    }

    const yearGroupNetIncome = (year) => {
        let total = 0
        const income = parseFloat(yearGroupTotalIncome(year));
        const expense = parseFloat(yearGroupTotalExpense(year));
        total += (income - expense);
        return total.toFixed(2);
    }

    const monthGroupNetIncome = (year, month) => {
        let total = 0
        const income = parseFloat(monthGroupTotalIncome(year, month));
        const expense = parseFloat(monthGroupTotalExpense(year, month));
        total += (income - expense);
        return total.toFixed(2);
    }

    // Show color with incomes or expenses
    const getColorsByType = (type) => {
        if (inComes.includes(type)) {
            return "finance-view-details-green-color";
        }
        if (expenses.includes(type)) {
            return "finance-view-details-red-color";
        }
        if (type === "SECURITY_DEPOSITS") {
            return "finance-view-details-blue-color";
        }
        if (type === "TRAVEL_MILES") {
            return "finance-view-details-yellow-color";
        }
    }

    // Source Type 4 TransactionUpdate Delete 
    const initialTransactionState = {
        condition: false,
        date: null,
        amount: "",
        expenseType: "",
        description: "",
        transId: "",
        propertyId: "",
    };
    const [editTransaction, setEditTransaction] = useState({ ...initialTransactionState });

    const handleTransactionChange = (fieldName, value) => {
        setEditTransaction((prevTransaction) => ({
            ...prevTransaction,
            [fieldName]: value,
        }));
    };

    const editSourceType4DialogOpen = (date, amount, desc, type, propId, tranId, receipts) => {
        setEditTransaction({
            condition: true,
            date: date,
            amount: amount,
            expenseType: type,
            description: desc,
            propertyId: propId,
            transId: tranId,
        });
        setAttachments(receipts);
    };

    const editSourceType4DialogClose = () => {
        setEditTransaction({ ...initialTransactionState });
        setAttachments([]);
    };

    const editUpdateTransactionClick = () => {
        let array = [];
        attachments.map((file) => {
            if (file.url) {
                array.push(file)
            }
        })
        let formData = new FormData();
        formData.append("amount", Number(editTransaction.amount));
        formData.append("description", editTransaction.description);
        formData.append("date", editTransaction.date);
        formData.append("type", editTransaction.expenseType);
        formData.append("existingReceipts", JSON.stringify(array))
        Array.from(attachments).forEach(item => { formData.append("newReceipts", item) });

        patchFinanceTransactions(editTransaction.propertyId, editTransaction.transId, formData).then((res) => {
            getReconcilatedTransactions();
            editSourceType4DialogClose();
        });
    };

    const deleteTransactionClick = () => {
        deleteFinanceTransactions(editTransaction.propertyId, editTransaction.transId).then((res) => {
            getReconcilatedTransactions();
            editSourceType4DialogClose();
        });
    };

    // CSV File Upload 
    const loadCsvDataFileUpload = async () => {
        await postFinanceDetailsCsvFileUpload("", selectedLoadCsvFileParsed, progressEvent => {
            let percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentageCompleted);
        }).then((res) => {
            setUploadProgress(0);
        })
    }

    const lastSelectedDateReconcilatedStatus = async () => {
        await patchLastSelectedDateReconlationStatus().then((res) => {
            getReconcilatedTransactions();
        })
    }

    const reconcilatedStatuschangetransactions = async (transaction) => {
        await patchFinanceTransactionsReconlationStatus(transaction?.property, transaction?.id).then((res) => {
            getReconcilatedTransactions();
        })
    }

    const editFinanceOrNavigate = (transactions) => {
        console.log("test")
        if (transactions?.sourceType === 3) {
            navigate(`/manager/service-requests/${transactions?.sourceId}`);
        } 
        if (transactions?.sourceType === 5) {
            navigate(`/manager/${transactions?.addedByManager}/property-group/${transactions?.property}/service-requests/${transactions?.sourceId}`);
        }
        if (transactions?.sourceType === 4) {
            editSourceType4DialogOpen(transactions?.date, transactions?.amount, transactions?.description, transactions?.type, transactions?.property, transactions?.id, transactions?.reciepts);
        }
        if (transactions?.sourceType === 1) {
            handleOpenAddFinance("edit", transactions?.date, transactions?.amount, transactions?.description, transactions?.groupId, transactions?.comments, transactions?.address, transactions?.type, transactions?.property, transactions?.id, transactions?.reciepts);
        }
    }

    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "30px" }}>
            <p className='finance-view-details-paragraph-1'>Welcome! {userName}</p>
            <p className='finance-view-details-paragraph-2'>Finance Details</p>
            <p className='finance-view-details-paragraph-3'>( Report from {moment(startDate).format("MMM DD, YYYY")} to {moment(endDate).format("MMM DD, YYYY")} )</p>
            <div className='finance-view-details-checkbox-flex'>
                <FormControlLabel
                    checked={lastSelectedDateReConcilated}
                    label="Show un-reconcilated data only"
                    control={<Checkbox color='success' onClick={() => { lastSelectedDateReconcilatedStatus() }} />}
                />
                <span style={{ display: 'flex', flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ fontSize: 15 }}
                        onClick={LoadCsvDialogOpen}
                    >
                        LOAD CSV DATA FILE
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ fontSize: 15 }}
                    >
                        PURGE ALL UNRECONCILED RECORDS
                    </Button>
                </span>
            </div>
            <div className='finance-view-details-fab-icon-flex'>
                <div className='finance-view-details-dates-select-flex'>
                    <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={["day"]}
                                type="date"
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => { setStartDate(newValue) }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={["day"]}
                                type="date"
                                label="Today's Date"
                                value={endDate}
                                onChange={(newValue) => { setEndDate(newValue); }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div>
                        <Tooltip placement="top" title="Refresh!">
                            <RefreshIcon onClick={refreshClick} />
                        </Tooltip>
                    </div>
                </div>
                <div title="Add">
                    <Fab
                        size="large"
                        color="primary"
                        aria-label="add"
                        style={{ transform: 'scale(0.8)', backgroundColor: "rgb(11, 119, 11)", color: "white" }}
                        onClick={() => { handleOpenAddFinance("add") }}
                    >
                        <AddIcon sx={{ fontSize: 40 }} />
                    </Fab>
                </div>
            </div>
            {Object.keys(groupedData).length == 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p className='finance-view-details-paragraph-no-data '>Sorry, no records found!</p>.
                </div>
            ) : (
                <div style={{ paddingTop: "20px" }}>
                    {Object.keys(groupedData).map((itemGroupName, ind) => (
                        <Accordion className='finance-view-details-accordion-border' key={ind}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <span style={{ flex: 1 }}>
                                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px' }}>
                                        <span>
                                            <Tooltip title={"LOCK"} placement="bottom">
                                                <LockOpenIcon />
                                            </Tooltip>
                                        </span>
                                        <span>
                                            {itemGroupName}
                                        </span>
                                    </span>
                                </span>
                                <span style={{ flex: 2 }}>
                                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px' }}>
                                        <span>
                                            <span>
                                                Income :
                                            </span>
                                            <span style={{ color: 'rgb(11, 119, 11)', fontWeight: 'bold' }}>
                                                ${yearGroupTotalIncome(itemGroupName)}
                                            </span>
                                        </span>
                                        <span>
                                            <span>
                                                Expense :
                                            </span>
                                            <span style={{ color: '#DC3545', fontWeight: 'bold' }}>
                                                ${yearGroupTotalExpense(itemGroupName)}
                                            </span>
                                        </span>
                                        <span>
                                            <span>
                                                Net :
                                            </span>
                                            <span style={{ color: yearGroupNetIncome(itemGroupName) < 0 ? "#DC3545" : "rgb(11, 119, 11)", fontWeight: 'bold' }}>
                                                ${Math.abs(yearGroupNetIncome(itemGroupName))}
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </AccordionSummary>
                            <AccordionDetails>
                                {Object.keys(groupedData[itemGroupName]).map((subGroupId, i) => (
                                    <Accordion className='finance-view-details-accordion-border-child' key={i}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <span style={{ flex: 1 }}>
                                                <span>
                                                    {subGroupId}
                                                </span>
                                            </span>
                                            <span style={{ flex: 2 }}>
                                                <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px' }}>
                                                    <span>
                                                        <span>
                                                            Income :
                                                        </span>
                                                        <span style={{ color: 'rgb(11, 119, 11)', fontWeight: 'bold' }}>
                                                            ${monthGroupTotalIncome(itemGroupName, subGroupId)}
                                                        </span>
                                                    </span>
                                                    <span>
                                                        <span>
                                                            Expense :
                                                        </span>
                                                        <span style={{ color: '#DC3545', fontWeight: 'bold' }}>
                                                            ${monthGroupTotalExpense(itemGroupName, subGroupId)}
                                                        </span>
                                                    </span>
                                                    <span>
                                                        <span>
                                                            Net :
                                                        </span>
                                                        <span style={{ color: monthGroupNetIncome(itemGroupName, subGroupId) < 0 ? "#DC3545" : "rgb(11, 119, 11)", fontWeight: 'bold' }}>
                                                            ${Math.abs(monthGroupNetIncome(itemGroupName, subGroupId))}
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>#</span></TableCell>
                                                            <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>Date</span></TableCell>
                                                            <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>Description</span></TableCell>
                                                            <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>Amount</span></TableCell>
                                                            <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>Address</span></TableCell>
                                                            <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>Property Group</span></TableCell>
                                                            <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>Category</span></TableCell>
                                                            {/* <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>Comments</span></TableCell> */}
                                                            {/* <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }} align="center"><span className='finance-view-details-table-head'>Receipts/Minutes</span></TableCell> */}
                                                            {/* <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}><span className='finance-view-details-table-head'>Actions</span></TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {groupedData[itemGroupName][subGroupId].map((item, index) => (
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                key={index}
                                                            >
                                                                <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                    <span>
                                                                        <FormControlLabel
                                                                            checked={item?.reconciledCheckBoxStatus}
                                                                            control={<Checkbox onChange={() => { reconcilatedStatuschangetransactions(item) }} color="success" />}
                                                                        />
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                    <span className={getColorsByType(item?.type)}>
                                                                        {item?.date ? moment(item?.date).format('MMM D, YYYY') : ""}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                    <LightTooltip title={item?.description ? item?.description : ""} placement="top">
                                                                        <span className={getColorsByType(item?.type)}>
                                                                            {truncateAddress(item?.description ? item?.description : "", 15)}
                                                                        </span>
                                                                    </LightTooltip>
                                                                </TableCell>
                                                                <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                    {item.type !== "TRAVEL_MILES" ?
                                                                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} className={getColorsByType(item?.type)} onClick={() => { editFinanceOrNavigate(item) }}>
                                                                            ${item?.amount.toFixed(2)}
                                                                        </span>
                                                                        : ""}
                                                                </TableCell>
                                                                <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                    <LightTooltip title={item?.propertyName ? item?.propertyName : ""} placement="top">
                                                                        <span className={getColorsByType(item?.type)}>
                                                                            {truncateAddress(item?.propertyName ? item?.propertyName : "", 15)}
                                                                        </span>
                                                                    </LightTooltip>
                                                                </TableCell>
                                                                <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                    <LightTooltip title={item?.groupName ? item?.groupName : ""} placement="top">
                                                                        <span className={getColorsByType(item?.type)}>
                                                                            {item?.groupName}
                                                                        </span>
                                                                    </LightTooltip>
                                                                </TableCell>
                                                                <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                    <LightTooltip title={item?.type ? FinanceCategoriesUppercase(item?.type) : ""} placement="top">
                                                                        <span className={getColorsByType(item?.type)}>
                                                                            {FinanceCategoriesUppercase(item?.type)}
                                                                        </span>
                                                                    </LightTooltip>
                                                                </TableCell>
                                                                {/* {item?.type == "TRAVEL_MILES" ? (
                                                                    <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                        <span className={getColorsByType(item?.type)}>
                                                                            {item?.travelMiles + " " + "Miles"}
                                                                        </span>
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                        <LightTooltip title={item?.comments ? item?.comments : "No Comments"} placement="top">
                                                                            <span className={getColorsByType(item?.type)}>
                                                                                {truncateAddress(item?.comments ? item?.comments : "-", 15)}
                                                                            </span>
                                                                        </LightTooltip>
                                                                    </TableCell>
                                                                )} */}
                                                                {/* <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }} align='center'>
                                                                    {item?.reciepts.length > 0 ?
                                                                        <span>
                                                                            {item.sourceType === 3 ?
                                                                                <RequestQuoteIcon sx={{ color: 'rgb(0, 191, 255)' }} style={{ cursor: 'pointer' }} onClick={() => { navigate(`/manager/service-requests/${item?.sourceId}`) }} />
                                                                                : ""}
                                                                            {item.sourceType === 5 ?
                                                                                <RequestQuoteIcon sx={{ color: 'rgb(0, 191, 255)' }} style={{ cursor: 'pointer' }} onClick={() => { navigate(`/manager/${item?.addedByManager}/property-group/${item?.property}/service-requests/${item?.sourceId}`) }} />
                                                                                : ""}
                                                                            {item.sourceType === 1 ?
                                                                                <span title='View Reciepts' className='file-attach-length-flex-alignment'>
                                                                                    <RequestQuoteIcon sx={{ color: 'rgb(0, 191, 255)' }} style={{ cursor: 'pointer' }} />
                                                                                    <span className='file-attach-length-color'>{item?.reciepts.length} files</span>
                                                                                </span>
                                                                                : ""}
                                                                            {item.sourceType === 4 ?
                                                                                <span title='View Reciepts' className='file-attach-length-flex-alignment'>
                                                                                    <RequestQuoteIcon sx={{ color: 'rgb(0, 191, 255)' }} style={{ cursor: 'pointer' }} />
                                                                                    <span className='file-attach-length-color'>{item?.reciepts.length} files</span>
                                                                                </span>
                                                                                : ""}
                                                                        </span>
                                                                        : ""}
                                                                </TableCell> */}
                                                                {/* <TableCell className='tableCell-maxWidth' style={{ padding: '5px' }}>
                                                                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                                                        {item.sourceType === 3 ?
                                                                            <AssignmentIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => { navigate(`/manager/service-requests/${item?.sourceId}`) }} />
                                                                            : ""}
                                                                        {item.sourceType === 5 ?
                                                                            <AssignmentIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => { navigate(`/manager/${item?.addedByManager}/property-group/${item?.property}/service-requests/${item?.sourceId}`) }} />
                                                                            : ""}
                                                                        {item.sourceType === 4 ?
                                                                            <AssignmentIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => { editSourceType4DialogOpen(item?.date, item?.amount, item?.description, item?.type, item?.property, item?.id, item?.reciepts) }} />
                                                                            : ""}
                                                                        {item?.sourceType === 1 ?
                                                                            <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>
                                                                                <EditIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => { handleOpenAddFinance("edit", item?.date, item?.amount, item?.description, item?.groupId, item?.comments, item?.address, item?.type, item?.property, item?.id, item?.reciepts) }} sx={{ cursor: 'pointer' }} />
                                                                                <DeleteIcon color='error' sx={{ cursor: 'pointer' }} onClick={() => { FinancedeletedDialogOpen(item?.property, item?.id) }} />
                                                                            </span>
                                                                            : ""}
                                                                        {item?.sourceType === 3 || item?.sourceType === 5 ?
                                                                            <span title={"Update Date"}>
                                                                                <AccessTimeIcon style={{ color: '#0000FF' }} />
                                                                            </span>
                                                                            : ""}
                                                                    </span>
                                                                </TableCell> */}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <div className='finance-view-details-download-flex'>
                        <CSVLink
                            data={csvData}
                            filename={"property-financial-report.csv"}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ fontSize: 15 }}
                            >
                                DOWNLOAD
                            </Button>
                        </CSVLink>
                    </div>
                </div>
            )}
            <Dialog onClose={handleCloseAddFinance} open={addFinance}>
                <DialogTitle textAlign={"center"}>
                    {!updateCondition.condition ? "Add" : ""} {updateCondition.condition ? "Update" : ""} Finance Details
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            views={["day", "month", "year"]}
                                            label="Date"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                            style={{ marginTop: 20 }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true} error={dateError} />} />
                                    </LocalizationProvider>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth style={{ marginTop: 20 }} error={amountError}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                        <OutlinedInput
                                            variant="outlined"
                                            value={amount}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                                setAmount(numericValue);
                                            }}
                                            inputProps={{
                                                inputMode: 'numeric', // Restrict input to numeric characters
                                                pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                            }}
                                            startAdornment={<InputAdornment position="start"><Icon style={{ color: 'black' }}>$</Icon></InputAdornment>}
                                            label="Amount"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <FormControlLabel
                                        value={"income"}
                                        control={<Radio color='success' />}
                                        label="Income"
                                        onChange={(e) => changeCategory(e)}
                                        checked={categorySelectedValue === 1}
                                        style={{ color: '#DC3545' }}
                                        disabled={categoryType === "SECURITY_DEPOSITS" ? true : false}
                                    />
                                    <FormControlLabel
                                        value={"expense"}
                                        control={<Radio color='success' />}
                                        label="Expense"
                                        onChange={(e) => changeCategory(e)}
                                        checked={categorySelectedValue === 2}
                                        style={{ color: '#DC3545' }}
                                        disabled={categoryType === "SECURITY_DEPOSITS" ? true : false}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth={true} error={categoryTypeError}>
                                        <InputLabel id="demo-simple-select-label">Category Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            fullWidth={true}
                                            MenuProps={MenuProps}
                                            label="Category Type"
                                            variant="outlined"
                                            value={categoryType}
                                            onChange={(e) => {
                                                setCategoryType(e.target.value);
                                            }}
                                            input={<OutlinedInput label="Category Type" />}
                                        >
                                            {categories.map((cat) => (
                                                <MenuItem key={cat.value} value={cat.value}>
                                                    {cat.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth={true} error={properyGroupError}>
                                        <InputLabel id="demo-simple-select-label">Property Group</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            fullWidth={true}
                                            label="Property Group"
                                            variant="outlined"
                                            value={propertyGroups}
                                            onChange={(e) => { handlePropertyGroupChange(e) }}>
                                            {PropGroups.map((grps, index) => (
                                                <MenuItem key={index} value={grps?.id}>
                                                    {grps?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormControl fullWidth={true} error={addressesError}>
                                        <InputLabel id="demo-simple-select-label">Address</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled={propertyGroups ? false : true}
                                            fullWidth={true}
                                            label="Address"
                                            variant="outlined"
                                            value={addresses}
                                            onChange={(e) => {
                                                setAddresses(e.target.value);
                                            }}
                                        >
                                            {selectedAddress.map((ad) => (
                                                <MenuItem key={ad?.id} value={ad?.id}>
                                                    {ad?.address}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={5}
                                        style={{ width: "100%" }}
                                        placeholder="Description"
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={5}
                                        style={{ width: "100%" }}
                                        placeholder="Comments"
                                        value={comment}
                                        onChange={(e) => {
                                            setComment(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', justifyContent: 'center' }}>
                                <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                    <span>Minutes/Receipts</span>
                                    <span>
                                        <input type="file" id="uploadBtnAttachmentAdd" accept="image/*"
                                            style={{ display: "none" }}
                                            onChange={handleAttachmentChange}
                                        />
                                        <label htmlFor="uploadBtnAttachmentAdd" title="Upload Reciept">
                                            <CloudUploadIcon color="primary" sx={{ cursor: "pointer" }} />
                                        </label>
                                    </span>
                                </span>
                                <Card sx={{ border: '1px solid grey', padding: '10px', boxShadow: 'none' }}>
                                    <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10 }}>
                                        {attachments.length > 0 ? (
                                            attachments.map((attach, index) => (
                                                <span key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 10 }}>
                                                    <span className="hover-blue-text" style={{ cursor: 'pointer' }} onClick={(e) => { imageClick(e, attach?.url) }}>
                                                        {attach?.name || attach?.fileName}
                                                    </span>
                                                    <span title="Delete Reciept">
                                                        <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { removeAttachMents(index) }} />
                                                    </span>
                                                </span>
                                            ))
                                        ) : (
                                            <span>
                                                No Receipts added.
                                            </span>
                                        )}
                                    </span>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', gap: 10, flexWrap: 'wrap', paddingTop: 30 }}>
                        {!updateCondition.condition ?
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={{ fontSize: 15 }}
                                onClick={() => { addFinanceDetailsSaveClick() }}
                            >
                                SAVE
                            </Button> : ""}
                        {updateCondition.condition ?
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={{ fontSize: 15 }}
                                onClick={() => { UpdateFinanceDetailsUpdateClick() }}
                            >
                                UPDATE
                            </Button>
                            : ""}
                        <Button color="error"
                            type="submit"
                            sx={{ fontSize: 15 }}
                            onClick={() => handleCloseAddFinance()}
                        >
                            CANCEL
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={deleteFinanceDialog}
                onClose={FinancedeletedDialogClose}
            >
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: 'column', gap: 30 }}>
                        <div>
                            <Typography variant="h6" component="h2">
                                Are you sure you want delete this finance details?
                            </Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                            <div>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    sx={{ fontSize: 15 }}
                                    onClick={() => deleteFinanceViewDetailsPageById(updateCondition.propertyId, updateCondition.transactionId)}
                                >
                                    Yes
                                </Button>
                                <Button
                                    color='error'
                                    sx={{ fontSize: 15 }}
                                    onClick={FinancedeletedDialogClose}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={editTransaction.condition}
                onClose={editSourceType4DialogClose}>
                <DialogTitle>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                        <DeleteIcon color="error" sx={{ width: '30px', height: '30px', cursor: 'pointer' }} onClick={deleteTransactionClick} />
                        <CloseIcon color="error" sx={{ width: '30px', height: '30px', cursor: 'pointer' }} onClick={() => editSourceType4DialogClose()} />
                    </div>
                </DialogTitle>
                <Typography
                    variant="h5"
                    gutterBottom
                    textAlign="center"
                    sx={{ marginBottom: "10px" }}
                >
                    Edit Property Finance Transaction
                </Typography>
                <Typography
                    variant="h6"
                    gutterBottom
                    textAlign="center"
                >
                    ID: #{editTransaction.transId}
                </Typography>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
                        <div style={{ flexGrow: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day"]}
                                    label="Date *"
                                    value={editTransaction.date}
                                    onChange={(newValue) => {
                                        handleTransactionChange("date", newValue);
                                    }}
                                    renderInput={(params) => <TextField color="warning"  {...params} fullWidth={true} sx={{ my: 2 }} />} />
                            </LocalizationProvider>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount USD *</InputLabel>
                                <OutlinedInput
                                    fullWidth={true}
                                    type="number"
                                    variant="outlined"
                                    value={editTransaction.amount}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                        handleTransactionChange("amount", numericValue);
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric', // Restrict input to numeric characters
                                        pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                    }}
                                    startAdornment={<InputAdornment position="start"><Icon style={{ color: "black" }}>$</Icon></InputAdornment>}
                                    label="Amount USD *"
                                />
                            </FormControl>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel id="demo-multiple-name-label">Type *</InputLabel>
                                <Select
                                    fullWidth={true}
                                    label="Type *"
                                    variant="outlined"
                                    MenuProps={MenuProps}
                                    value={editTransaction.expenseType}
                                    onChange={(e) => {
                                        handleTransactionChange("expenseType", e.target.value);
                                    }}
                                    input={<OutlinedInput label="Type *" />}
                                >
                                    {editFinanceTransactionExpense.map((exp, index) => (
                                        <MenuItem key={index} value={exp.value}>{exp.label}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            label="Description *"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            fullWidth={true}
                            value={editTransaction.description}
                            onChange={(e) => {
                                handleTransactionChange("description", e.target.value);
                            }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20, paddingBottom: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', justifyContent: 'center' }}>
                            <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                <span>Receipts</span>
                                <span>
                                    <input type="file" id="uploadBtnAttachmentAdd" accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={handleAttachmentChange}
                                    />
                                    <label htmlFor="uploadBtnAttachmentAdd" title="Upload Reciept">
                                        <CloudUploadIcon color="primary" sx={{ cursor: "pointer" }} />
                                    </label>
                                </span>
                            </span>
                            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10 }}>
                                {attachments.length > 0 ? (
                                    attachments.map((attach, index) => (
                                        <span key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 10 }}>
                                            <span className="finance-add-group-expense-attachment-hover-text" style={{ cursor: 'pointer' }} onClick={(e) => { imageClick(e, attach?.url) }}>
                                                {attach?.name || attach?.fileName}
                                            </span>
                                            <span title="Delete Reciept">
                                                <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { removeAttachMents(index) }} />
                                            </span>
                                        </span>
                                    ))
                                ) : (
                                    <span>
                                        No Receipts added.
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button color="primary" variant="contained" sx={{ fontSize: 15 }} onClick={editUpdateTransactionClick}>UPDATE TRANSACTION</Button>
                        {/* <Button color="error" variant="contained" sx={{ fontSize: 15 }} onClick={deleteTransactionClick}>DELETE</Button>
                        <Button type="submit" color="inherit" sx={{ fontSize: 15 }} onClick={() => editSourceType4DialogClose()}>CANCEL</Button> */}
                    </div>
                </DialogContent>
            </Dialog>

            {/* Load CSV Data File Dialog Box */}
            <Dialog
                open={loadCsvDialog}
                onClose={LoadCsvDialogClose}
            >
                {uploadProgress ? (
                    <DialogContent>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', gap: 20 }}>
                            <Typography variant='h4'>Please Wait</Typography>
                            <CircularProgressWithLabel value={uploadProgress} />
                            <div className="load-wrapp">
                                <div className="load-progress">
                                    <div className="letter-holder font-weight-bold text-center">
                                        <div className="l-1 letter">L</div>
                                        <div className="l-2 letter">o</div>
                                        <div className="l-3 letter">a</div>
                                        <div className="l-4 letter">d</div>
                                        <div className="l-5 letter">i</div>
                                        <div className="l-6 letter">n</div>
                                        <div className="l-7 letter">g</div>
                                        <div className="l-8 letter">.</div>
                                        <div className="l-9 letter">.</div>
                                        <div className="l-10 letter">.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', justifyContent: 'center' }}>
                                <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                    <span>Upload CSV File</span>
                                    <span>
                                        <input type="file" id="uploadBtnLoadCsvFile" accept=".csv"
                                            style={{ display: "none" }}
                                            onChange={handleSelectLoadCsvUpload}
                                        />
                                        <label htmlFor="uploadBtnLoadCsvFile" title="Upload Reciept">
                                            <CloudUploadIcon color="primary" sx={{ cursor: "pointer" }} />
                                        </label>
                                    </span>
                                </span>
                                <Card sx={{ border: '1px solid grey', padding: '10px', boxShadow: 'none' }}>
                                    <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10 }}>
                                        {selectedLoadCsvUpload ? (
                                            <span style={{ cursor: 'pointer' }} title={selectedLoadCsvUpload.name}>
                                                {truncateAddress(selectedLoadCsvUpload.name, 25)}
                                            </span>
                                        ) : (
                                            <span>
                                                No CSV File Added.
                                            </span>
                                        )}
                                    </span>
                                </Card>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10, flexWrap: 'wrap', marginTop: "20px" }}>
                            <Button
                                color="success"
                                variant="contained"
                                sx={{ fontSize: 15 }}
                                onClick={() => { loadCsvDataFileUpload() }}
                            >UPLOAD
                            </Button>
                            <Button
                                type="submit"
                                color="error"
                                sx={{ fontSize: 15 }}
                                onClick={LoadCsvDialogClose}
                            >CANCEL
                            </Button>
                        </div>
                    </DialogContent>
                )}
            </Dialog>
        </div >
    )
}

export default ViewDetails;