import { Box, Button, CardContent, Chip, Dialog, FormControl, InputLabel, MenuItem, OutlinedInput, Rating, Select, TextareaAutosize, TextField, Tooltip, Typography } from '@mui/material';
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TeamManagementTabs from './teamManagementTab';
import { getContractor, getContractorTags, postContractorAdd, patchContractorUpdate, postContractorArchive } from '../../../services';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GroupIcon from '@mui/icons-material/Group';

const TeamActiveContractors = () => {
    const [addContractorDialog, setAddContractorDialog] = useState(false);
    const [editContractorDialog, setEditContractorDialog] = useState(false);

    const [contractor, setContractor] = useState([]);
    const [contractorTags, setContractorTages] = useState([]);
    const [contractFirstName, setContractFirstName] = useState("");
    const [contractLastName, setContractLastName] = useState("");
    const [contractEmail, setContractEmail] = useState("");
    const [contractPhoneNo, setContractPhoneNo] = useState("");
    const [contractCity, setContractCity] = useState("");
    const [contractState, setContractState] = useState("");
    const [contractSkills, setContractSkills] = useState([]);
    const [contractDesc, setContractDesc] = useState("");

    const [contractUpdateFirstName, setContractUpdateFirstName] = useState("");
    const [contractUpdateLastName, setContractUpdateLastName] = useState("");
    const [contractUpdateEmail, setContractUpdateEmail] = useState("");
    const [contractUpdatePhoneNo, setContractUpdatePhoneNo] = useState("");
    const [contractUpdateCity, setContractUpdateCity] = useState("");
    const [contractUpdateState, setContractUpdateState] = useState("");
    const [contractUpdateSkills, setContractUpdateSkills] = useState([]);
    const [contractUpdateDesc, setContractUpdateDesc] = useState("");
    const [contId, setContId] = useState("");

    useEffect(() => {
        pageLoad();
    }, []);

    const pageLoad = () => {
        getContractor().then((res) => {
            let responseData = res.data.data;
            setContractor(responseData);
        }).catch((err) => console.log(`Error${err}`));
        getContractorTags().then((res) => {
            setContractorTages(res.data.data);
        }).catch((err) => console.log(`Error${err}`));
    }

    // Phone Format
    const handleInput = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setContractPhoneNo(formattedPhoneNumber);
    };
    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }
    const onChangeHandler = (e) => {
        const contractPhoneNo = e.target.value
        setContractPhoneNo(contractPhoneNo)
    };

    // Phone Format
    const handleInput1 = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setContractUpdatePhoneNo(formattedPhoneNumber);
    };
    const onChangeHandler1 = (e) => {
        const contractPhoneNo = e.target.value
        setContractUpdatePhoneNo(contractPhoneNo)
    };

    const handleChangeContractSkills = (event) => {
        const {
            target: { value },
        } = event;
        setContractSkills(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeContractSkillsUpdate = (event) => {
        const {
            target: { value },
        } = event;
        setContractUpdateSkills(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const contractorAddClick = () => {
        let data = {
            "firstName": contractFirstName,
            "lastName": contractLastName,
            "email": contractEmail,
            "phoneCode": "01",
            "phoneNumber": contractPhoneNo,
            "city": contractCity,
            "state": contractState,
            "skills": contractSkills,
            "starRatings": 0,
            "description": contractDesc,
        }
        postContractorAdd(data).then((res) => {
            setAddContractorDialog(false);
            pageLoad();
            toast.success("Contractor Added Successfully");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleOpenAdd = () => {
        setAddContractorDialog(true);
    }
    const handleOpenEdit = (first, last, email, phone, desc, id) => {
        setEditContractorDialog(true);
        setContractUpdateFirstName(first);
        setContractUpdateLastName(last);
        setContractUpdateEmail(email);
        setContractUpdatePhoneNo(phone);
        setContractUpdateDesc(desc);
        setContId(id);
    }
    const contractorEditClick = () => {
        let data = {
            "firstName": contractUpdateFirstName,
            "lastName": contractUpdateLastName,
            "email": contractUpdateEmail,
            "phoneCode": "01",
            "phoneNumber": contractUpdatePhoneNo,
            "city": contractUpdateCity,
            "state": contractUpdateState,
            "skills": contractUpdateSkills,
            "description": contractUpdateDesc,
        }
        patchContractorUpdate(data, contId).then((res) => {
            setEditContractorDialog(false);
            pageLoad();
            toast.success("Contractor Added Successfully");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleArchive = (id, email) => {
        let data = {
            "archive": true
        }
        postContractorArchive(id, data).then((res) => {
            toast.success(`Contractor with ${email} has InActive Successfully`);
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }
    return (
        <div>
            <CardContent>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                <div style={{ display: "flex", margin: "10px", alignItems: "center", flexWrap: "wrap" }}>
                    <GroupIcon sx={{ fontSize: 25, fontWeight: "bolder", width: '28px', height: "28px", backgroundColor: "#4a90e2", color: "white", borderRadius: "5px", padding: "5px" }} />
                    <p style={{ fontSize: 22, textAlign: "center", fontWeight: "bolder", marginLeft: "10px" }}>Team Management</p>
                </div>
            </div>
                <Link to="/manager/team/Inactive-Contractors"><p className='active-contractors-link'>Show InActive Contractors</p></Link>
            </CardContent>
            <CardContent>
                <TeamManagementTabs active={"ACTIVE CONTRACTORS"} />
            </CardContent>
            <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                <Button sx={{ fontSize: "small", fontWeight: 500, borderRadius: 5 }} variant="contained" type="submit" color="success" onClick={handleOpenAdd}>ADD CONTRACTOR</Button>
            </CardContent>
            <CardContent sx={{ py: 0 }}>
                <TextField
                    id="standard-basic"
                    fullWidth={true}
                    variant="standard"
                    label="Search..."
                    type="text"
                />
                <table className="team-contractors-table">
                    <thead>
                        <tr>
                            <th className="team-contractors-table-head">Name</th>
                            <th className="team-contractors-table-head">Email</th>
                            <th className="team-contractors-table-head">Phone</th>
                            <th className="team-contractors-table-head">Description</th>
                            <th className="team-contractors-table-head">Skills</th>
                            <th className="team-contractors-table-head">Ratings</th>
                            <th className="team-contractors-table-head">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contractor && contractor ? contractor.map((e, i) => (e.archive === false &&
                            <tr key={i}>
                                <td className="team-contractors-table-body">{e.name.first} {e.name.last}</td>
                                <td className="team-contractors-table-body">{e.email.id}</td>
                                <td className="team-contractors-table-body">{e.phone.number}</td>
                                <td className="team-contractors-table-body">{e.description}</td>
                                <td className="team-contractors-table-body">
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                        {e.skills.map((h, i) => (
                                            <div style={{ marginRight: "10px" }} key={i}>
                                                <Button
                                                    style={{
                                                        borderRadius: 35,
                                                        marginLeft: "-5px",
                                                        backgroundColor: "#e0e0e0",
                                                        color: "black",
                                                        textTransform: "lowercase"
                                                    }}
                                                    size="small">{h}
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td className="team-contractors-table-body">{e.starRatings === 0 ? <p>Not Rated</p> : (<div><Rating name="size-small" defaultValue={1} max={1} size="small" /> {e.starRatings === 0.2 ? "1.0" : ""}{e.starRatings === 0.4 ? "2.0" : ""}{e.starRatings === 0.6 ? "3.0" : ""}{e.starRatings === 0.8 ? "4.0" : ""}{e.starRatings === 1 ? "5.0" : ""} / 5 (1 Ratings)</div>)}</td>
                                <td className="team-contractors-table-body">
                                    <Button sx={{ fontSize: "small", fontWeight: 500 }} type="submit" color="success" onClick={() => { handleOpenEdit(e.name.first, e.name.last, e.email.id, e.phone.number, e.description, e.id) }}>Edit</Button>
                                    <Tooltip title={"Active Contractor"} placement="top">
                                        <Button sx={{ fontSize: "small", fontWeight: 500 }} type="submit" color="error" onClick={() => { handleArchive(e.id, e.email.id) }}>Inactive</Button>
                                    </Tooltip>
                                </td>
                            </tr>
                        )) : undefined}
                    </tbody>
                </table>
            </CardContent>
            <Dialog
                open={addContractorDialog}
                onClose={() => setAddContractorDialog(false)}>

                <CardContent>
                    <Typography id="modal-modal-title" textAlign='center' variant="h6" component="h2">
                        Add Contractor
                    </Typography>
                </CardContent>

                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '18ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="First Name" id="outlined-size-small" size="small"
                            value={contractFirstName}
                            onChange={(e) => { setContractFirstName(e.target.value) }} />
                        <TextField label="Last Name" id="outlined-size-small" size="small"
                            value={contractLastName}
                            onChange={(e) => { setContractLastName(e.target.value) }} />

                    </Box>

                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Email" id="outlined-size-small" size="small"
                            value={contractEmail}
                            onChange={(e) => { setContractEmail(e.target.value) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Phone No" id="outlined-size-small" size="small"
                            value={contractPhoneNo}
                            onChange={(e) => { onChangeHandler(e); handleInput(e) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="City" id="outlined-size-small" size="small"
                            value={contractCity}
                            onChange={(e) => { setContractCity(e.target.value) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Select State</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                label="Select State"
                                value={contractState}
                                onChange={(e) => {
                                    setContractState(e.target.value);
                                }}
                            >
                                <MenuItem value="-Select-">-Select-</MenuItem>
                                <MenuItem value="CA">California</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl sx={{ width: 1 }}>
                            <InputLabel id="demo-multiple-chip-label">Skills Required</InputLabel>
                            <Select
                                sx={{ width: 1 }}
                                size="small"
                                variant="standard"
                                label="Skills Required"
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                input={<OutlinedInput label="Skills Required" />}
                                value={contractSkills}
                                onChange={handleChangeContractSkills}
                                renderValue={(ContractSelected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {ContractSelected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {contractorTags && contractorTags ? contractorTags.map((e, i) => (
                                    <MenuItem key={i} value={e}>
                                        {e}
                                    </MenuItem>
                                )) : undefined}
                            </Select>
                        </FormControl>
                        <p style={{ fontSize: 11, marginTop: "0px", marginBottom: "0px" }}>Press Enter or type","after entering a skill</p>

                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Description"
                            style={{ width: 310 }}
                            value={contractDesc}
                            onChange={(e) => { setContractDesc(e.target.value) }}
                        />
                    </Box>
                </CardContent>
                <CardContent style={{ display: "flex", flexDirection: "row", justifyContent: "right", marginTop: "-29px" }}>
                    <Button variant="contained" color="success"
                        type="submit"
                        sx={{ fontWeight: "bold" }}
                        size="small"
                        onClick={contractorAddClick}
                    >Add</Button>
                </CardContent>
            </Dialog>
            <Dialog
                open={editContractorDialog}
                onClose={() => setEditContractorDialog(false)}>

                <CardContent>
                    <Typography id="modal-modal-title" textAlign='center' variant="h6" component="h2">
                        Edit Contractor
                    </Typography>
                </CardContent>

                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '18ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="First Name" id="outlined-size-small" size="small"
                            value={contractUpdateFirstName}
                            onChange={(e) => { setContractUpdateFirstName(e.target.value) }} />
                        <TextField label="Last Name" id="outlined-size-small" size="small"
                            value={contractUpdateLastName}
                            onChange={(e) => { setContractUpdateLastName(e.target.value) }} />

                    </Box>

                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Email" id="outlined-size-small" size="small"
                            value={contractUpdateEmail}
                            onChange={(e) => { setContractUpdateEmail(e.target.value) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Phone No" id="outlined-size-small" size="small"
                            value={contractUpdatePhoneNo}
                            onChange={(e) => { onChangeHandler1(e); handleInput1(e) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="City" id="outlined-size-small" size="small"
                            value={contractUpdateCity}
                            onChange={(e) => { setContractUpdateCity(e.target.value) }} />
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Select State</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                label="Select State"
                                value={contractUpdateState}
                                onChange={(e) => {
                                    setContractUpdateState(e.target.value);
                                }}
                            >
                                <MenuItem value="-Select-">-Select-</MenuItem>
                                <MenuItem value="CA">California</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl sx={{ width: 1 }}>
                            <InputLabel id="demo-multiple-chip-label">Skills Required</InputLabel>
                            <Select
                                sx={{ width: 1 }}
                                size="small"
                                variant="standard"
                                label="Skills Required"
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                input={<OutlinedInput label="Skills Required" />}
                                value={contractUpdateSkills}
                                onChange={handleChangeContractSkillsUpdate}
                                renderValue={(ContractSelected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {ContractSelected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {contractorTags && contractorTags ? contractorTags.map((e, i) => (
                                    <MenuItem key={i} value={e}>
                                        {e}
                                    </MenuItem>
                                )) : undefined}
                            </Select>
                        </FormControl>
                        <p style={{ fontSize: 11, marginTop: "0px", marginBottom: "0px" }}>Press Enter or type","after entering a skill</p>

                    </Box>
                </CardContent>
                <CardContent className="outlined-basic">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '38ch' }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Description"
                            style={{ width: 310 }}
                            value={contractUpdateDesc}
                            onChange={(e) => { setContractUpdateDesc(e.target.value) }}
                        />
                    </Box>
                </CardContent>
                <CardContent style={{ display: "flex", flexDirection: "row", justifyContent: "right", marginTop: "-29px" }}>
                    <Button variant="contained" color="success"
                        type="submit"
                        sx={{ fontWeight: "bold" }}
                        size="small"
                        onClick={contractorEditClick}
                    >Update</Button>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default TeamActiveContractors;
