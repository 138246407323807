import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleProperty, getTenantLeaseApplication } from "../../../../services";

const TenantLeaseApplicationViewPage = () => {
    const params = useParams();
    const user = useSelector((state) => state.user.value);

    const [application, setApplication] = useState();
    const [property, setProperty] = useState();
    const [applicant, setApplicant] = useState();
    const [currentResidence, setCurrentResidence] = useState();
    const [previousResidence, setPreviousResidence] = useState();
    const [currentEmployment, setCurrentEmployment] = useState();
    const [previousEmployment, setPreviousEmployment] = useState();
    const [incomeDetails, setIncomeDetails] = useState();

    const renderAdults = () => {
        return application.occupants.adults.map((item, index) => {
            return (
                <div key={index} className="adult-list-item">
                    <div>{item.name}</div>
                    <div>Relation: {item.relationship}</div>
                </div>
            );
        });
    }
    const renderMinors = () => {
        return application.occupants.minors.map((item, index) => {
            return (
                <div key={index}>
                    {`${item.name}, ${item.dateOfBirth}`}
                </div>
            );
        });
    }
    const renderPets = () => {
        return application.occupants.pets.map((item, index) => {
            return (
                <div key={index}>
                    <div>
                        {item.name}
                    </div>
                    <div>
                        {item.description}
                    </div>
                </div>
            );
        });
    }
    const renderAutomobiles = () => {
        return application.automobiles.map((item, index) => {
            return (
                <div key={index}>
                    <div>
                        {`${item.year} ${item.manufacturer} ${item.model}, License: ${item.license}, State: ${item.state}`}
                    </div>
                </div>
            );
        });
    }

    useEffect(() => {
        getTenantLeaseApplication(params.applicationId).then(response => {
            setApplication(response.data.data);
            response.data.data.applicants.forEach((item, index) => {
                if (item.user === user.id) {
                    let ta = response.data.data.applicants[index];
                    setApplicant(ta);
                    setCurrentResidence(ta.currentResidence);
                    setPreviousResidence(ta.previousResidence ?? undefined);
                    setCurrentEmployment(ta.currentEmployment);
                    setPreviousEmployment(ta.previousEmployment ?? undefined);
                    setIncomeDetails(ta.incomeDetails);
                }
            });



            return getSingleProperty(response.data.data.property);
        }).then(response => {
            setProperty(response.data.data);
        }).catch(err => { console.log(err) });
    }, [params, setApplication, setProperty]);

    if (!application || !property) {
        return <div>Loading...</div>;
    }

    return (
        <div className="page tenant-lease-application-view-page">
            <div className="page-container">
                <div className="property-details" id="property-details">
                    <div className="property-address">
                        <div className="property-address-line-one">{property.address.line1}</div>
                        <div className="property-address-line-two">{`${property.address.city}, ${property.address.state}`}</div>
                    </div>
                    <div className="property-manager">
                        <div>Questions?</div>
                        <div>
                            {`Manager: ${property.owner.user.name.first} ${property.owner.user.name.last}`}
                        </div>
                        <a href={`tel:${property.owner.user.phone.code}${property.owner.user.phone.number}`}>
                            {`${property.owner.user.phone.code}${property.owner.user.phone.number}`}
                        </a>
                    </div>
                </div>
                <div className="form">
                    <div className="form-section">
                        <h1>Occupants</h1>
                        <h2>Adults</h2>
                        <div className="adults-list">
                            {renderAdults()}
                        </div>
                        <h2>Minors</h2>
                        <div className="minors-list">
                            {renderMinors()}
                        </div>
                        <h2>Pets</h2>
                        <div className="pets-list">
                            {renderPets()}
                        </div>
                        <h2>Automobiles</h2>
                        <div className="automobiles-list">
                            {renderAutomobiles()}
                        </div>
                    </div>
                    <div className="form-section">
                        <h1>Residence</h1>
                        <h2>Current residence</h2>
                        <h3>Address</h3>
                        <p>Street: {currentResidence.address.line1}</p>
                        <p>Building: {currentResidence.address.line2}</p>
                        <p>City: {currentResidence.address.city}</p>
                        <p>State: {currentResidence.address.state}</p>
                        <p>Zip Code: {currentResidence.address.zipCode}</p>
                        <h3>Manager Details</h3>
                        <p>Name: {currentResidence.managerName}</p>
                        <p>Phone: {currentResidence.managerPhone}</p>
                        <h3>Lease Details</h3>
                        <p>Rent: {currentResidence.monthlyRent}</p>
                        <p>Duration: {currentResidence.yearsOfResidence}</p>
                        <p>Reason for leaving: {currentResidence.reasonForLeaving}</p>
                        {previousResidence &&
                            <>
                                <h2>Previous residence</h2>
                                <h3>Address</h3>
                                <p>Street: {previousResidence.address.line1}</p>
                                <p>Building: {previousResidence.address.line2}</p>
                                <p>City: {previousResidence.address.city}</p>
                                <p>State: {previousResidence.address.state}</p>
                                <p>Zip Code: {previousResidence.address.zipCode}</p>
                                <h3>Manager Details</h3>
                                <p>Name: {previousResidence.managerName}</p>
                                <p>Phone: {previousResidence.managerPhone}</p>
                                <h3>Lease Details</h3>
                                <p>Rent: {previousResidence.monthlyRent}</p>
                                <p>Reason for leaving: {previousResidence.reasonForLeaving}</p>
                            </>
                        }
                    </div>
                    <div className="form-section">
                        <h1>Employment and Income</h1>
                        <h2>Current Employment</h2>
                        <p>Title: {currentEmployment.role}</p>
                        <p>Employer: {currentEmployment.employerName}</p>
                        <h3>Manager Details</h3>
                        <p>Name: {currentEmployment.managerName}</p>
                        <p>Phone: {currentEmployment.managerPhone}</p>
                        <h3>Address</h3>
                        <p>Street: {currentEmployment.employerAddress.line1}</p>
                        <p>Building: {currentEmployment.employerAddress.line2}</p>
                        <p>City: {currentEmployment.employerAddress.city}</p>
                        <p>State: {currentEmployment.employerAddress.state}</p>
                        <p>Zip Code: {currentEmployment.employerAddress.zipCode}</p>
                        <p>Duration: {currentEmployment.yearsOfEmployment} year(s)</p>
                        <h3>Income Details</h3>
                        <p>Income: ${incomeDetails.monthlyIncome}</p>
                        <p>Paystub: (Click here)</p>
                        {incomeDetails?.hasOtherIncome &&
                            <>
                                <h3>Other Income Details</h3>
                                <p>Other Income: ${incomeDetails.otherMonthlyIncome}</p>
                                <p>Source: {incomeDetails.otherIncomeSource}</p>
                                <p>Verification Contact: {incomeDetails.otherIncomeVerificationContactName} ({incomeDetails.otherIncomeVerificationContactPhone})</p>
                                <p>Evidence: (Click here)</p>
                            </>
                        }
                    </div>
                    <div className="form-section">
                        <h1>Verification</h1>
                        <p>Date of Birth: {applicant.dateOfBirth}</p>
                        <p>Credit Score: {incomeDetails.creditScore}</p>
                        <p>Social Security Number: {applicant.verificationDetails.socialSecurityNumber}</p>
                        <p>Driving License: {applicant.verificationDetails.driversLicense.number} {applicant.verificationDetails.driversLicense.issuerState} (Expiry date: {applicant.verificationDetails.driversLicense.expiryDate})</p>
                        <h3>Verification files</h3>
                        <p>Bank Statement</p>
                        <p>Credit report</p>
                        <p>Government ID</p>
                    </div>
                    <div className="form-section">
                        <h1>History</h1>
                        <p>Date of Birth: {applicant.dateOfBirth}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TenantLeaseApplicationViewPage;