import React from 'react'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import copy from "copy-to-clipboard";
import PhoneIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Mail";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[5],
        fontSize: 14,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        "&::before": {
            backgroundColor: theme.palette.common.white,
            border: "1px solid #999"
        }
    },
}));

function ToolTip({ phone, email }) {
    const copyPhone = (phoneNumber) => {
        copy(phoneNumber);
        toast.success("Phone Number is copied to Clipboard")
    }

    const copyEmail = (emailId) => {
        copy(emailId);
        toast.success("Email is copied to Clipboard")
    }
    return (
        <div style={{display:'flex',gap:10}}>
            <LightTooltip title={"Copy" + " " + phone} placement="top">
                <PhoneIcon color="primary" sx={{ cursor: 'pointer' }} onClick={() => copyPhone(phone)} />
            </LightTooltip>
            <LightTooltip title={"Copy" + " " + email} placement="top">
                <EmailIcon color="primary" sx={{ cursor: 'pointer' }} onClick={() => copyEmail(email)} />
            </LightTooltip>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default ToolTip;