import { Button, Card, Snackbar, Alert, Breadcrumbs, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, CircularProgress } from "@mui/material";
import React from "react";
import '../../../../Styles/tenant/tenantLeaseview.css'
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Link from '@mui/material/Link';
import {
  getLeases,
  getleaseEntries,
  getServiceRequest,
  tenantRecommendationLetter,
  getApiBaseURL,
} from "../../../../services";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { extendMoment } from "moment-range";
import Moment from "moment";

function LeaseView() {
  const navigate = useNavigate();
  const moment = extendMoment(Moment);
  let params = useParams();
  const [leaseTbl, setleaseTbl] = useState([]);
  const [leaseAmt, setleaseAmt] = useState([]);
  const [leaseFiles, setleaseFiles] = useState([]);
  const [alladdress, setalladdress] = useState([]);
  const [securityAmt, setsecurityAmt] = useState(null);
  const [showFillMoveIn, setshowFillMoveIn] = useState(0);
  const [allservice, setallservice] = useState([]);
  const [moveinStatus, setMoveinStatus] = useState();
  const [moveOutStatus, setMoveOutStatus] = useState();
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [moveInAlertBox, setMoveInAlertBox] = useState(true);
  const [loading, setLoading] = useState(true);

  const pageLoad = async () => {
    setLoading(true);
    let files = [...leaseFiles];
    files.length = 0;
    let address = [...alladdress];
    address.length = 0;
    await getLeases(params.applicationId).then((response) => {
      const getDetails = response.data.data;

      setMoveinStatus(getDetails.moveInDetails.status);
      setMoveOutStatus(getDetails.moveOutDetails.status);
      setshowFillMoveIn(getDetails?.moveInDetails?.status);

      address.push({
        line1: getDetails.property.address.line1,
        line2: getDetails?.property?.address?.line2,
        leaseDur: getDetails.beginDate ? moment(getDetails.date).format("MMMM DD, YYYY") : "",
        moveIn: getDetails?.moveInDetails?.moveInDate ? moment(getDetails.moveInDetails.moveInDate).format("MMMM DD, YYYY") : "",
        moveOut: getDetails?.moveOutDetails?.moveOutDate ? moment(getDetails.moveOutDetails.moveOutDate).format("MMMM DD, YYYY") : "",
        leaseEnd: getDetails?.endDate ? moment(getDetails.endDate).format("MMMM DD, YYYY") : "",
        email: getDetails?.property?.owner?.user?.email.id,
        fullName: getDetails?.property?.owner?.user.name.first + " " + getDetails.property?.owner?.user.name.last,
        phone: getDetails?.property?.owner?.user.phone.code + getDetails.property?.owner?.user.phone.number,
        expiry: getDetails?.expiryDate ? " - " + moment(getDetails.expiryDate).format("MMMM DD, YYYY") : "",
        expiryDate: getDetails?.expiryDate ? moment(getDetails.expiryDate).format("MMMM DD, YYYY") : "",
      });
      setalladdress(address);

      getDetails.leaseFiles.map((u, o) => {
        files.push({
          date: "Published on " + u.addedOnDate ? moment(u.addedOnDate).format("MMMM DD, YYYY") : "",
          description: u.description,
          fileExtension: u.fileExtension,
          fileName: u.fileName,
          filePath: u.filePath,
          id: u._id,
          mimeType: u.mimeType,
          url: u.url,
        });
      });
      setleaseFiles(files);
    });

    await getleaseEntries(params.applicationId).then((entries) => {
      let responseEntries = entries.data.data;
      setsecurityAmt(responseEntries.securityDepositAmount);
      let leaseState = [...leaseTbl];
      let leasetotAmt = [...leaseAmt];
      let getDetails = { ...responseEntries };
      leaseState.length = 0;
      leasetotAmt.length = 0;
      getDetails.dueEntries.map((s, d) => {
        leaseState.push({
          description: s.description,
          date: s.date != null || s.date != undefined ? moment(s.date).format("MMMM DD, YYYY") : "",
          amount: s.amount != null ? "$" + s.amount + ".00" : "$0.00",
          lateFeesRateAmount: s.lateFeesRateAmount != null ? "$" + s.lateFeesRateAmount + ".00" + "/day" : "$0.00",
          lateFees: s.lateFees != null ? "$" + s.lateFees + ".00" : "$0.00",
          amountResolved: s.amountResolved != null ? "$" + s.amountResolved + ".00" : "$0.00",
          amountWaived: s.amountWaived != null ? "$" + s.amountWaived + ".00" : "$0.00",
          amountUnresolved: s.amountUnresolved != null ? "$" + s.amountUnresolved + ".00" : "$0.00",
        });
      });
      setleaseTbl(leaseState);

      getDetails.dueEntries.map((s, d) => {
        var tot = 0;
        var initial = 0;
        var lateIni = 0;
        var totLate = 0;
        var paidIni = 0;
        var totPaid = 0;
        var waivedIni = 0;
        var totWaived = 0;
        var totdueIni = 0;
        var totdue = 0;
        if (d == 0) {
          initial = s.amount;
          lateIni = s.lateFees;
          paidIni = s.amountResolved;
          waivedIni = s.amountWaived;
          totdueIni = s.amountUnresolved;
        }
        if (d > 1) {
          tot = s.amount;
          totLate = s.lateFees;
          totPaid = s.amountResolved;
          totWaived = s.amountWaived;
          totdue = s.amountUnresolved;
        }
        leasetotAmt.push({
          dueAmt: initial + tot,
          lateAmt: lateIni + totLate,
          paid: paidIni + totPaid,
          waveAmt: waivedIni + totWaived,
          totAmt: totdueIni + totdue,
        });
      });
      setleaseAmt(leasetotAmt);
    });

    await getServiceRequest(params.applicationId).then((res) => {
      const getservice = res?.data?.data;
      setallservice(getservice);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const datediff = (d1) => {
    if ([null, undefined].includes(d1)) return "";
    const date = new Date(d1);
    var d2 = new Date();
    var t2 = d2.getTime();
    var t1 = date.getTime();
    return Math.floor((t1 - t2) / (24 * 3600 * 1000));
  };
  const RecommendationLetterClick = () => {
    tenantRecommendationLetter(params.applicationId).then((res) => {
      setSnackBarOpen(true);
    });
  };
  const fileDownload = async (url) => {
    const authToken = sessionStorage.getItem("authToken");
    const config = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    fetch(
      `${getApiBaseURL()}/tenant/${url}
`,
      config
    )
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          const link = document.createElement("a");
          link.href = imageDataUrl;
          if (
            blob.type == "image/png" ||
            blob.type == "image/gif" ||
            blob.type == "image/jpeg" ||
            blob.type == "image/svg" ||
            blob.type == "image/jpg"
          ) {
            const newTab = window.open();
            newTab?.document.write(
              `<!DOCTYPE html><head><title>Document preview</title></head><body style="margin: auto; background: #0e0e0e; height: 100%;"><img align="center"  src="${imageDataUrl}"  ></body></html>`
            );

            newTab?.document.close();
          }
          if (blob.type == "application/pdf") {
            link.setAttribute("download", "Document.pdf");
            document.body.appendChild(link);
            link.click();
          }
        };
      });
  };

  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div style={{ padding: '30px' }}>
      <div>
        {alladdress.map((j, k) => (
          <div>
            <div style={{ textAlign: "center" }}>
              <h1>Lease</h1>
            </div>
            <div style={{ textAlign: "center" }}>
              <div role="presentation" className='breedCrump'>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    style={{ fontSize: 18 }}
                    underline="hover"
                    color="inherit"
                    href=""
                  >
                    DASHBOARD
                  </Link>
                  <Link
                    style={{ color: '#4a90e2', fontSize: 18 }}
                    underline="hover"
                    color="inherit"
                  >
                    {j.line1}
                  </Link>
                </Breadcrumbs>
              </div>
            </div>

            {showFillMoveIn == 3 ? (moveInAlertBox == true &&
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: '20px',
                  paddingBottom: '20px'
                }}
              >
                <Card style={{ padding: "20px", maxWidth: "700px" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <CloseIcon style={{ cursor: 'pointer' }} onClick={() => { setMoveInAlertBox(false) }} />
                    </div>
                    <div>
                      <div style={{ paddingBottom: '10px' }}>
                        <h3 style={{ color: "#A6ABB1" }}>
                          Congratulations on your move!
                        </h3>
                      </div>
                      <div>
                        <p style={{ fontSize: 16 }}>
                          Start filling in the{" "}
                          <span style={{ color: "#4A90E7", cursor: 'pointer' }} onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/moveinlease`) }}>
                            move in checklist
                          </span>{" "}
                          to update your property manager about the current
                          condition of the property. (Note: Move-In Form is
                          valid only for 2 weeks, if not filled, all the
                          conditions would be set as good by default)
                        </p>
                      </div>
                      <div>
                        <p style={{ fontSize: 16 }}>
                          Use this website to{" "}
                          <span style={{ color: "rgb(11, 119, 11)", cursor: 'pointer' }} onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/service-requests`) }}>
                            request service
                          </span>{" "}
                          from your Landlord.
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ) : ("")}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: "space-between",
                paddingTop: '20px',
                paddingBottom: '20px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 10 }}>
                <span style={{ fontSize: 15, fontWeight: 'bold' }}>{j.line1 + " , " + j.line2}</span>
                <span style={{ fontSize: 15, fontWeight: 'bold' }}>Lease Duration: {j.leaseDur} {j.expiry}</span>
                <span style={{ fontSize: 15, fontWeight: 'bold' }}>Move In Date: {j.moveIn}</span>
                <span style={{ fontSize: 15, fontWeight: 'bold', color: "#A6ABB1" }}>Time remaining: {datediff(j.moveIn)} Days</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 10 }}>
                <span>Property Manager</span>
                <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10, flexWrap: 'wrap', fontSize: 15, fontWeight: 'bold' }}>
                  <span>
                    <PersonIcon color="primary" />
                  </span>
                  <span>
                    {j.fullName}
                  </span>
                </span>
                <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10, flexWrap: 'wrap', fontSize: 15, fontWeight: 'bold' }}>
                  <span>
                    <PhoneIcon color="primary" />
                  </span>
                  <span>
                    {j.phone}
                  </span>
                </span>
                <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10, flexWrap: 'wrap', fontSize: 15, fontWeight: 'bold' }}>
                  <span>
                    <EmailIcon color="primary" />
                  </span>
                  <span>
                    {j.email}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ))}

        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <div>
            <div>
              <p style={{ fontSize: 18, fontWeight: 'bold', paddingBottom: '10px' }}>Finance Balance</p>
            </div>
            {leaseAmt.map((h, ts) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ color: "rgba(0,0,0,.8)", marginBottom: "0px" }}>
                  Current Due:
                </p>
                <p style={{ display: "flex", alignItems: "center" }}>
                  <b>${h.totAmt}.00</b>
                </p>
              </div>
            ))}
            <hr />
            <div style={{ display: "flex" }}>
              <p style={{ color: "rgba(0,0,0,.8)" }}>
                Security Deposit:
              </p>
              <p>
                <b>${securityAmt}.00</b>
              </p>
            </div>
          </div>
          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <p style={{ fontSize: 18, fontWeight: 'bold', paddingBottom: '10px' }}>Amount Due</p>
          </div>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='tableCell-maxWidth'>Description</TableCell>
                  <TableCell className='tableCell-maxWidth'>Due Date</TableCell>
                  <TableCell className='tableCell-maxWidth'>Due Amount</TableCell>
                  <TableCell className='tableCell-maxWidth'>Late Fee Rate</TableCell>
                  <TableCell className='tableCell-maxWidth'>Late Fees</TableCell>
                  <TableCell className='tableCell-maxWidth'>Amount Paid</TableCell>
                  <TableCell className='tableCell-maxWidth'>Amount Waived</TableCell>
                  <TableCell className='tableCell-maxWidth'>Total Due</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaseTbl.map((r, y) => (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell className='tableCell-maxWidth' component="th" scope="row">{r.description}</TableCell>
                    <TableCell className='tableCell-maxWidth'>{r.date}</TableCell>
                    <TableCell className='tableCell-maxWidth'>{r.amount}</TableCell>
                    <TableCell className='tableCell-maxWidth'>{r.lateFeesRateAmount}</TableCell>
                    <TableCell className='tableCell-maxWidth'>{r.lateFees}</TableCell>
                    <TableCell className='tableCell-maxWidth'>{r.amountResolved}</TableCell>
                    <TableCell className='tableCell-maxWidth'>{r.amountWaived}</TableCell>
                    <TableCell className='tableCell-maxWidth'>{r.amountUnresolved}</TableCell>
                  </TableRow>
                ))}
                {leaseAmt.map((f, g) => (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell className='tableCell-maxWidth' component="th" scope="row">Total</TableCell>
                    <TableCell className='tableCell-maxWidth'></TableCell>
                    <TableCell className='tableCell-maxWidth'>${f.dueAmt}.00</TableCell>
                    <TableCell className='tableCell-maxWidth'></TableCell>
                    <TableCell className='tableCell-maxWidth'>${f.lateAmt}.00</TableCell>
                    <TableCell className='tableCell-maxWidth'>${f.paid}.00</TableCell>
                    <TableCell className='tableCell-maxWidth'>${f.waveAmt}.00</TableCell>
                    <TableCell className='tableCell-maxWidth'>${f.totAmt}.00</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 10, flexWrap: 'wrap', paddingTop: '20px', paddingBottom: '20px' }}>
            <p style={{ fontSize: 18, fontWeight: 'bold', paddingBottom: '10px' }}>ServiceRequest</p>
            <div>
              <Button
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/service-requests`) }}>
                +Service Request
              </Button>
            </div>
          </div>
          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className='tableCell-maxWidth-1'>No.</TableCell>
                    <TableCell className='tableCell-maxWidth-1'>Title</TableCell>
                    <TableCell className='tableCell-maxWidth-1'>Date Issued</TableCell>
                    <TableCell className='tableCell-maxWidth-1'>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allservice.map((r, y) => (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={y}>
                      <TableCell className='tableCell-maxWidth' component="th" scope="row">{r?.numberId}</TableCell>
                      <TableCell className='tableCell-maxWidth'>
                        <div className="tennat-lease-view-sr-title" onClick={() => { navigate(`/tenant/service-requests/${r?.id}`) }}>
                          {r.title}
                        </div>
                      </TableCell>
                      <TableCell className='tableCell-maxWidth'>{r?.createdAt ? moment(r.createdAt).format("MMMM DD, YYYY") : ""}</TableCell>
                      <TableCell className='tableCell-maxWidth'>{r?.state === 0 ? <div className="tennat-lease-view-sr-state-green">OPEN</div> : <div className="tennat-lease-view-sr-state-orange">CLOSED</div>}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <p style={{ fontSize: 18, fontWeight: 'bold', paddingBottom: '10px' }}>Tenant Documents</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 10 }}>
            {leaseFiles.map((jg, k) => (
              <Card style={{ maxWidth: 300 }} key={k}>
                <div
                  onClick={() => fileDownload(jg.url)}
                  style={{
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 10,
                    cursor: "pointer",
                  }}
                  title="Click to download!"
                >
                  <InsertDriveFileIcon
                    color="primary"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  />
                  <span style={{ fontSize: 15, fontWeight: 'bold' }}>{"Published on : " + jg.date}</span>
                  <span style={{ fontSize: 15, color: "#4a90e2", fontWeight: 'bold' }}>{" "}{jg.fileName}{" "}</span>
                </div>
              </Card>
            ))}
          </div>
          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <p style={{ fontSize: 18, fontWeight: 'bold', paddingBottom: '10px' }}>Move In</p>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              sx={{ fontSize: 15 }}
              onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/moveinlease`) }}
            >
              {moveinStatus == 3 ? "SUBMIT MOVE IN CHECKLIST" : "VIEW MOVE IN CHECKLIST"}
            </Button>
          </div>
          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <p style={{ fontSize: 18, fontWeight: 'bold', paddingBottom: '10px' }}>Move Out</p>
          </div>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 10 }}>
            {moveOutStatus === 1 ? (
              <div>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#F2994A", fontSize: 15 }}
                  onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/ScheduleMoveOut`) }}
                >
                  SCHEDULE MOVE OUT
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ fontSize: 15 }}
                  onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/ScheduleMoveOut`) }}
                >
                  VIEW MOVE OUT
                </Button>
              </div>
            )}
            {moveOutStatus === 4 ?
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ fontSize: 15 }}
                  onClick={()=>{navigate(`/tenant/lease-applications/${params.applicationId}/move-Out-inspection`)}}
                >
                  SECURITY DEPOSIT ESTIMATE
                </Button>
              </div>
              : ""}
            <div>
              <Button
                variant="contained"
                color="success"
                sx={{ fontSize: 15 }}
                onClick={RecommendationLetterClick}
              >
                REQUEST RECOMMENDATION LETTER
              </Button>
              <Snackbar
                open={snackBarOpen}
                autoHideDuration={2500}
                onClose={() => {
                  setSnackBarOpen(false); 
                }}
              >
                <Alert icon={false} variant="filled" severity="success">
                  Recommentation letter has been requested
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaseView;
