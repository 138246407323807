import React, { useState } from "react";
import { Alert, AlertTitle, Button, TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import { emailVerificationSuccess } from "../../services";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EmailVerification() {
    const navigate = useNavigate();

    // Query Params
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const email = queryParams.get('email');

    const EmailVerification = (e) => {
        e.preventDefault();
        emailVerificationSuccess(email, token)
            .then((response) => {
                console.log(response.data)
                if (response.data) {
                    toast.success("Your email is successfully verified")
                    setTimeout(() => {
                        navigate("/login");
                    }, 2000);
                }
            });
    }

    return (
        <div>
            <div className="page login-page">
                <form className="login-form"
                    onSubmit={EmailVerification}>
                    <Typography variant="h4">Verify Your Email</Typography>
                    <TextField
                        value={email}
                        disabled={true}
                        margin="dense"
                        required
                        type="email"
                        label="Email"
                        variant="outlined"
                        fullWidth={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <EmailIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        type="submit"
                        style={{ background: "#ff805d" }}
                        variant="contained"
                        fullWidth={true}
                    >
                        Verify Email
                    </Button>
                </form>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default EmailVerification;