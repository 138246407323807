import { useState, useEffect } from "react";
import { Button, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { getNoticeTemplates, postNoticeTemplates } from "../../../services";
import AddIcon from '@mui/icons-material/Add';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NoticeTemplates() {
  const { quillRef } = useQuill();
  const navigate = useNavigate();

  const [noticeTemplates, setNoticeTemplates] = useState([])
  const [addNewTemplates, setAddNewTemplates] = useState(false);

  const [desc, setDesc] = useState("")
  const [name, setName] = useState("")
  const [salvation, setSalvation] = useState("")
  const [subject, setSubject] = useState("")

  const [descError, setDescError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [salvationError, setSalvationError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);

  const pageLoad = async () => {
    await getNoticeTemplates().then((res) => {
      let notices = res?.data?.data;
      setNoticeTemplates(notices);
    })
  }

  useEffect(() => {
    pageLoad();
  }, []);

  const validateInput = (value, setErrorState) => {
    const isValid = value.trim() !== "";
    setErrorState(!isValid);
    return isValid;
  };

  const handleInputChange = (value, setState, setErrorState) => {
    validateInput(value, setErrorState);
    setState(value);
  };

  const saveBtnClick = () => {
    const isValid =
      validateInput(name, setNameError) &&
      validateInput(desc, setDescError) &&
      validateInput(subject, setSubjectError) &&
      validateInput(salvation, setSalvationError);

    if (!isValid) return;

    let data = {
      body: quillRef.current.firstChild.innerHTML,
      description: desc,
      name: name,
      salutation: salvation,
      subject: subject,
    }
    postNoticeTemplates(data).then((res) => {
      toast.success("Successfully Added Template")
      pageLoad();
      setAddNewTemplates(false);
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  const nameClick = (e, id) => {
    navigate(`/manager/notice-templates/${id}`);
  }

  return (
    <div style={{ padding: '30px' }}>
      <p className="update-notice-templates-heading">Manage Notice Templates</p>
      <div style={{ display: "flex", justifyContent: 'right' }}>
        <Button variant="contained" color="success"
          type="submit"
          style={{ fontSize: 15, borderRadius: 20 }}
          onClick={() => setAddNewTemplates(true)}><AddIcon /> NEW TEMPLATES</Button>
      </div>
      {addNewTemplates === true ?
        <div>
          <p className="update-notice-templates-heading-child">Edit Template</p>
          <div>
            <TextField
              error={nameError}
              label="Template Name"
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder="Eg 60 Days Notice Template"
              value={name}
              onChange={(e) => handleInputChange(e.target.value, setName, setNameError)}
            />
            <TextField
              error={descError}
              label="Template Description"
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder="Enter Description about template"
              rows={2}
              multiline
              value={desc}
              onChange={(e) => handleInputChange(e.target.value, setDesc, setDescError)}
            />
            <TextField
              error={subjectError}
              label="Notice Subject"
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder="Eg 60 Days Notice Alert"
              value={subject}
              onChange={(e) => handleInputChange(e.target.value, setSubject, setSubjectError)}
            />
            <TextField
              error={salvationError}
              label="Notice Salutation"
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder="Eg Dear[Tenants]"
              value={salvation}
              onChange={(e) => handleInputChange(e.target.value, setSalvation, setSalvationError)}
            />
            <div className="update-notice-templates-quill-editor">
              <div ref={quillRef} />
            </div>
          </div>
          <div className="add-notice-templates-button-flex-1">
            <Button
              type="submit"
              color="error"
              variant="text"
              sx={{ fontSize: 15 }}
              onClick={() => setAddNewTemplates(false)}
            >DISCARD</Button>
            <Button
              type="submit"
              color="primary"
              sx={{ fontSize: 15 }}
              variant="contained"
              onClick={saveBtnClick}
            >SAVE</Button>
          </div>
        </div> : ""}
      <p className="update-notice-templates-heading-child">Saved Templates</p>
      <div>
        {noticeTemplates.length === 0 ?
          (<p style={{ fontSize: 17 }}>You haven't added any notice templates yet! Click on "New Template" button to add templates.</p>)
          :
          (<TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>

                  <TableCell className='tableCell-maxWidth'><span className="add-notice-templates-table-head-color">Name</span></TableCell>
                  <TableCell className='tableCell-maxWidth'><span className="add-notice-templates-table-head-color">Subject</span></TableCell>
                  <TableCell className='tableCell-maxWidth'><span className="add-notice-templates-table-head-color">Description</span></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {noticeTemplates.map((g, index) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={index}>
                    <TableCell className='tableCell-maxWidth' component="th" scope="row" onClick={(e) => nameClick(e, g.id)}>
                      <span style={{ color: "#5B99E3", cursor: "pointer" }}>
                        {g?.name}
                      </span>
                    </TableCell>
                    <TableCell className='tableCell-maxWidth'>{g?.subject}</TableCell>
                    <TableCell className='tableCell-maxWidth'><span className="add-notice-templates-table-desc-font">{g?.description}</span></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
export default NoticeTemplates;