import { Button, div, Paper, TextareaAutosize, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { serviceRequest } from "../../../../services"
import DeleteIcon from '@mui/icons-material/Delete';

function ServiceRequest() {
    const navigate = useNavigate();
    const params = useParams();
    const [images, setImages] = useState([]);
    const [Title, setTitle] = useState();
    const [Desc, setDesc] = useState();

    const handleImagesAdd = (e) => {
        setImages([...images, ...e.target.files]);
    }

    const removeImage = (index) => {
        let toDelete;
        const newImages = images.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });
        setImages(newImages);
    };

    const renderImages = () => {
        return Array.from(images).map((item, index) => {
            const url = URL.createObjectURL(item)
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={url} alt="images" style={{ height: "110px", width: "175px", marginTop: "20px" }} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={() => { removeImage(index) }}>
                            <DeleteIcon color="error" />
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const btnsave = () => {
        let formData = new FormData();
        formData.append("leaseId", params.applicationId);
        formData.append("title", Title);
        formData.append("description", Desc);
        Array.from(images).forEach(item => { formData.append("attachments", item) });

        serviceRequest(params.applicationId, formData)
            .then((res) => {
                navigate(`/tenant/lease-applications/${params.applicationId}/leaseview`);
            });
    }


    return (
        <div style={{ padding: '20px' }}>
            <div style={{ textAlign: "center" }}>
                <h2>New Service Request</h2>
            </div>
            <div style={{paddingTop:'20px',paddingBottom:'20px'}}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>Create new service request</p>
            </div>
            <div style={{paddingTop:'20px',paddingBottom:'20px'}}>
                <TextField
                    id="standard-basic"
                    variant="outlined"
                    label="Title"
                    sx={{ width: '50%' }}
                    type="text"
                    fullWidth={true}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                >
                </TextField>
            </div>
            <div style={{paddingTop:'20px',paddingBottom:'20px'}}>
                <TextField
                    label="Details"
                    id="standard-basic"
                    variant="outlined"
                    sx={{ width: '50%' }}
                    type="text"
                    multiline
                    rows={5}
                    onChange={(e) => {
                        setDesc(e.target.value);
                    }}
                >
                </TextField>
            </div>
            <div style={{paddingTop:'20px',paddingBottom:'20px'}}>
                <div className="container">
                    {renderImages()}
                    <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
                        <input type="file" id="uploadBtn" accept="image/*"
                            onChange={handleImagesAdd}
                            multiple
                        />
                        <label htmlFor="uploadBtn" style={{ fontSize: 13 }}>
                            Drop images / click to Upload</label>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right',paddingTop:'20px',paddingBottom:'20px' }}>
                <Button variant="contained" color="success"
                    onClick={(e) => btnsave(e)}>SAVE</Button>
            </div>
        </div>
    );
}

export default ServiceRequest;