import React, { useEffect, useState } from "react";
import {
  CardContent,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { previewAgreement, getLeaseappAgreementManager, agreementSend, getApiBaseURL } from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Leaseagreementpreview = () => {
  const params = useParams();
  const [view, setView] = useState(null)
  const navigate = useNavigate();
  const [fileView, setFileview] = useState([]);
  const [loading, setLoading] = useState(true);

  const pageLoad = () => {
    setLoading(true);
    previewAgreement(params.applicationId).then(response => {
      const responseData1 = response.data.data;
      setView(responseData1.html)
    }).finally(() => {
      setLoading(false);
    });
    getLeaseappAgreementManager(params.applicationId).then((response) => {
      const responseData = response.data.data;
      var fileatt = [...fileView]
      fileatt.length = 0;
      responseData?.attachments?.map((e, i) => {
        fileatt.push({
          "fileName": e.fileName,
          "id": e.id
        })
      })
      setFileview(fileatt);
    })
  }
  useEffect(() => {
    pageLoad();
  }, []);

  const downloadFile = (id) => {
    var config = "";
    const authToken = sessionStorage.getItem("authToken");
    if (authToken != undefined) {
      config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
    }
    fetch(`${getApiBaseURL()}/manager/lease-applications/${params.applicationId}/lease-agreement/attachments/${id}`,
      config
    ).then((res) => {
      return res.blob();
    })
      .then((blob) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          const link = document.createElement("a");
          link.href = imageDataUrl;
          if (blob.type == "application/pdf") {
            link.setAttribute("Download", "Attachment.pdf");
            document.body.appendChild(link);
            link.click();
          }
        };
      })
  }

  const btnreviseClick = () => {
    navigate(`/manager/createlease/${params.applicationId}`);
  }

  const btnsendClick = () => {
    agreementSend(params.applicationId, "").then((response) => {
      navigate(`/manager/lease-applications/${params.applicationId}`);
    }).catch((err) => { toast.error(err.response.data.error.message) });

  }

  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div>
      <Box sx={{ m: 4 }} >
        <div dangerouslySetInnerHTML={{ __html: view }} />
        <Box >
          <table>
            <tbody>
              {fileView.map((e, r) => (
                <tr key={r}>
                  <td >
                    {r + 1}. <a style={{ color: "#4a90e2", cursor: "pointer", fontSize: "12pt" }} onClick={() => downloadFile(e.id)}>{e.fileName}</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: 10 }}>
          <Button variant="contained" style={{ backgroundColor: '#ED6C02', fontSize: 15 }}
            onClick={(e) => btnreviseClick(e)}
          >REVISE</Button>
          <Button variant="contained"
            sx={{ fontSize: 15 }}
            onClick={(e) => btnsendClick(e)}
          >Send To TENANTS</Button>
        </CardContent>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>);



};
export default Leaseagreementpreview;
