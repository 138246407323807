import React, { useEffect, useState } from 'react'
import { deleteSuperUserManager, getManagers } from '../../services';
import { Box, Button, CardContent, CircularProgress, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsersTab from './UsersTab';


function Managers() {
    const [allUsers, setAllUsers] = useState([]);

    // loading
    const [loading, setLoading] = useState(true);

    // Dialog Box
    const [dialog, setDialog] = useState(false);

    const [managerId, setManagerId] = useState("");

    const dialogOpen = (managerId) => {
        setDialog(true);
        setManagerId(managerId);
    }
    const dialogClose = () => {
        setDialog(false);
        setManagerId("");
    }

    const getAllUsers = async () => {
        try {
            setLoading(true);
            await getManagers().then((res) => {
                let data = res?.data?.data;
                setAllUsers(data);
                setLoading(false);
            })
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const deleteManager = async (managerId) => {
        await deleteSuperUserManager(managerId).then((res) => {
            toast.success("Manager deleted successfully.");
            dialogClose();
            getAllUsers();
        }).catch((err) => { toast.error(err.response.data.error.message) })
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "20px" }}>
            <UsersTab user={"Managers"}/>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableCell-maxWidth' align='center'><span className='users-page-managers-table-head'>Sl.No</span></TableCell>
                            <TableCell className='tableCell-maxWidth' align='center'><span className='users-page-managers-table-head'>Name</span></TableCell>
                            <TableCell className='tableCell-maxWidth' align='center'><span className='users-page-managers-table-head'>Email</span></TableCell>
                            <TableCell className='tableCell-maxWidth' align='center'><span className='users-page-managers-table-head'>Phone</span></TableCell>
                            <TableCell className='tableCell-maxWidth' align='center'><span className='users-page-managers-table-head'>Action</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allUsers.map((user, index) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={index}
                            >
                                <TableCell className='tableCell-maxWidth' align='center'>{index + 1} .</TableCell>
                                <TableCell className='tableCell-maxWidth' align='center'>{user?.name?.first + " " + user?.name?.last}</TableCell>
                                <TableCell className='tableCell-maxWidth' align='center'>{user?.email?.id}</TableCell>
                                <TableCell className='tableCell-maxWidth' align='center'>{user?.phone?.code + " " + user?.phone?.number}</TableCell>
                                <TableCell className='tableCell-maxWidth' align='center'><DeleteIcon color="error" sx={{ cursor: "pointer" }} onClick={() => { dialogOpen(user?.manager) }} /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={dialog}
                onClose={dialogClose}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Are you sure you want delete this Manager?
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "right", flexWrap: 'wrap' }}>
                    <div>
                        <Button
                            variant="contained"
                            color='primary'
                            sx={{ fontSize: 15 }}
                            onClick={() => { deleteManager(managerId) }}
                        >Yes
                        </Button>
                        <Button
                            color='error'
                            sx={{ fontSize: 15 }}
                            onClick={() => { dialogClose() }}
                        >No
                        </Button>
                    </div>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default Managers;