import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import ReactSignatureCanvas from "react-signature-canvas";
import SignaturePad from "react-signature-canvas";
import { tenantSignAndSubmit, getTenantLeaseApplication, getApiBaseURL } from "../../../../services";
import { FaStepBackward, FaStepForward } from "react-icons/fa";
import { formNames } from "../../../../helpers";
import { withDrawApplication } from "../../../../services";
import { Button, Grid, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TenantLeaseApplicationEditPage from ".";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import axios from "axios";

function AgreementForm() {
    let params = useParams();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [ip, setIP] = useState('');
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.value);
    const signatureRef = useRef(null);
    const [signatureError, setSignatureError] = useState("");
    const [signImg, setsignImg] = useState("");
    const [desiredMoveInDate, setDesiredMoveInDate] = useState(null);
    const [desiredMoveInDateError, setDesiredMoveInDateError] = useState("");

    const clearSignature = () => {
        signatureRef.current.clear();
        setIsSigned(false)
    }

    const [FieldsDisabled, setFieldsDisabled] = useState(false);
    const [SubmittedStatus, setSubmittedStatus] = useState(false);
    const [Modify, setModify] = useState(false);
    const [valStatus, setvalStatus] = useState(false);
    const [isSigned, setIsSigned] = useState(false);

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res?.data?.IPv4)
    }

    const pageLoad = async () => {
        await getTenantLeaseApplication(params.applicationId).then((res) => {
            const responseData = res.data.data;
            if (responseData.status == "APPLICANT_SUBMITTED") {
                setDesiredMoveInDate(responseData?.moveInDate);
                setFieldsDisabled(true);
                setSubmittedStatus(true);
                const authToken = sessionStorage.getItem("authToken");
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` },
                };
                fetch(
                    `${getApiBaseURL()}/tenant/lease-applications/${params.applicationId}/applicant/signature/file`,
                    config
                ).then((res) => {
                    return res.blob();
                }).then((blob) => {
                    var reader = new window.FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = function () {
                        var imageDataUrl = reader.result;
                        setsignImg(imageDataUrl);
                    };
                }).catch((err) => console.log(`Error${err}`));
            }
            if (responseData.status == "APPLICANT_WITHDRAWN") {
                setFieldsDisabled(false);
                setModify(true);
            }
            let getData = { ...responseData };
            let applicant;
            getData.applicants.forEach((item, index) => {
                if (item.user === user.id) {
                    applicant = getData?.applicants[index];
                }
                if (applicant?.signature?.time) { setCurrentDate(applicant?.signature?.time) };
                if (
                    applicant.currentResidence != undefined &&
                    applicant.currentEmployment != undefined &&
                    applicant.incomeDetails.incomeVerificationFile != undefined &&
                    applicant.verificationDetails != undefined &&
                    applicant.verificationDetails.bankStatemantFile != undefined &&
                    applicant.verificationDetails.creditReportFile != undefined &&
                    applicant.verificationDetails.driversLicense != undefined &&
                    applicant.verificationDetails.governmentIdFile != undefined &&
                    applicant.hasCriminalHistory != undefined &&
                    applicant.hasEvictionHistory != undefined
                ) {
                    if (
                        applicant.currentResidence.address.city != "" &&
                        applicant.currentResidence.address.line1 != "" &&
                        // applicant.currentResidence.address.line2 != "" &&
                        applicant.currentResidence.address.state != "" &&
                        applicant.currentResidence.address.zipCode != "" &&
                        applicant.currentResidence.managerName != "" &&
                        applicant.currentResidence.managerPhone != "" &&
                        applicant.currentResidence.monthlyRent != "" &&
                        applicant.currentResidence.reasonForLeaving != "" &&
                        applicant.verificationDetails.driversLicense.expiryDate != "" &&
                        applicant.verificationDetails.driversLicense.issuerState != "" &&
                        applicant.verificationDetails.driversLicense.number != "" &&
                        applicant.verificationDetails.socialSecurityNumber != "" &&
                        applicant.dateOfBirth != null &&
                        applicant.incomeDetails.creditScore != "" &&
                        applicant.currentEmployment.employerAddress.city != "" &&
                        applicant.currentEmployment.employerAddress.line1 != "" &&
                        // applicant.currentEmployment.employerAddress.line2 != "" &&
                        applicant.currentEmployment.employerAddress.state != "" &&
                        applicant.currentEmployment.employerAddress.zipCode != "" &&
                        applicant.currentEmployment.employerName != "" &&
                        applicant.currentEmployment.managerName != "" &&
                        applicant.currentEmployment.managerPhone != "" &&
                        applicant.currentEmployment.role != "" &&
                        applicant.incomeDetails.hasOtherIncome != null
                    ) {
                        setvalStatus(true);
                        // console.log("All Mandatory Fields Filled")
                    } else {
                        setvalStatus(false);
                        // console.log("Please Fill All Mandatory Fields")
                    }
                }
            })
        }).catch(err => { console.log(err) });
    }

    useEffect(() => {
        pageLoad();
        getData()
    }, []);

    const handleModify = () => {
        const res = withDrawApplication(params.applicationId).then((response) => {
            window.location.reload();
        }).catch((err) => { toast.error(err.response.data.error.message) });

    };

    const handleAgreementSubmit = () => {
        let error = false;
        if (signatureRef.current.isEmpty()) {
            setSignatureError("Please provide your signature");
            error = true;
        } else {
            setSignatureError("");
        }
        if (desiredMoveInDate === null) {
            setDesiredMoveInDateError("Please provide Desired Move-In-Date");
            error = true;
        } else {
            setDesiredMoveInDateError("");
        }
        if (error) return;
        let data = {
            ipAddress: "",
            signature: signatureRef.current.toDataURL("image/png"),
            moveInDate: desiredMoveInDate,
        };
        tenantSignAndSubmit(params.applicationId, data).then(response => {
            toast.success("Application submitted.");
            sessionStorage.removeItem("ongoingApplication");
            navigate("/tenant/dashboard");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    // useEffect(() => {
    //     document.getElementById("property-details").scrollIntoView({ behavior: "smooth" });
    // }, []);

    // return (
    //     <div className="lease-form-item agreement-form">
    //         <h1 className="form-header">Agreement</h1>
    //         <div className="form-body">
    //             <div className="form-section">
    //                 <div className="form-row">
    //                     <div className="form-item">
    //                         I authorize, the landlord and it's agents the release of information from previous or current landlords, employers, and bank representatives, and any other entities mentioned. This investgation is for resident screening purposes only, and is strictly confidential. Data collected may be used in aggregate for statistical and marketing purposes.
    //                     </div>
    //                 </div>
    //                 <div>
    //                     <div className="form-body">
    //                         <span className="form-item-label">Desired Move-in Date</span>
    //                         <input
    //                             className="form-item-input"
    //                             type="date"
    //                             value={desiredMoveInDate}
    //                             onChange={e => { setDesiredMoveInDate(e.target.value) }}
    //                         // disabled={FieldsDisabled}
    //                         />
    //                     </div>
    //                 </div>
    //                 {FieldsDisabled === true ? (
    //                     <img
    //                         style={{
    //                             border: "1px solid #dee2e6",
    //                             padding: "3px",
    //                             width: "250px",
    //                             height: "150px",
    //                         }}
    //                         src={signImg}
    //                     />
    //                 ) : (
    //                     <div>
    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 {`${user?.name?.first} ${user?.name?.last} `}
    //                             </div>
    //                         </div>

    //                         <div className="form-row">
    //                             <div className="form-item">
    //                                 <span className="form-item-label">
    //                                     Your signature <button onClick={clearSignature}>Clear</button>
    //                                 </span>
    //                                 <ReactSignatureCanvas
    //                                     onEnd={() => { setIsSigned(true) }}
    //                                     ref={signatureRef}
    //                                     penColor='black'
    //                                     canvasProps={{ className: "signature-canvas" }}
    //                                 />
    //                                 {signatureError &&
    //                                     <span className="form-item-error">
    //                                         {signatureError}
    //                                     </span>
    //                                 }
    //                             </div>
    //                         </div>
    //                     </div>
    //                 )}
    //             </div>
    //         </div>
    //         <div className="form-footer">
    //             {Modify === true ? (
    //                 <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={() => { setCurrentForm(formNames.historyForm) }}
    //                         startIcon={<FaStepBackward />}
    //                     >HISTORY</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="success"
    //                         sx={{ fontSize: 15 }}
    //                         onClick={handleAgreementSubmit}
    //                     >SAVE</Button>
    //                     <Button
    //                         variant="contained"
    //                         color="warning"
    //                         sx={{ fontSize: 15 }}
    //                         disabled={valStatus === true && isSigned == true ? false : true}
    //                         onClick={handleAgreementSubmit}
    //                         endIcon={<FaStepForward />}
    //                     >SUBMIT</Button>
    //                 </div>
    //             ) : (
    //                 <>
    //                     {SubmittedStatus === true ? (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={() => { setCurrentForm(formNames.historyForm) }}
    //                                 startIcon={<FaStepBackward />}
    //                             >HISTORY</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={(e) => handleModify(e)}
    //                             >MODIFY</Button>
    //                         </div>
    //                     ) : (
    //                         <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 onClick={() => { setCurrentForm(formNames.historyForm) }}
    //                                 startIcon={<FaStepBackward />}
    //                             >HISTORY</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="success"
    //                                 sx={{ fontSize: 15 }}
    //                                 disabled={valStatus === true && isSigned == true ? false : true}
    //                                 onClick={handleAgreementSubmit}
    //                             >SAVE</Button>
    //                             <Button
    //                                 variant="contained"
    //                                 color="warning"
    //                                 sx={{ fontSize: 15 }}
    //                                 disabled={valStatus === true && isSigned == true ? false : true}
    //                                 onClick={handleAgreementSubmit}
    //                                 endIcon={<FaStepForward />}
    //                             >SUBMIT</Button>
    //                         </div>
    //                     )}
    //                 </>
    //             )}
    //         </div>
    //         <ToastContainer
    //             position="top-center"
    //             autoClose={2000}
    //             hideProgressBar={false}
    //             newestOnTop={false}
    //             closeOnClick
    //             rtl={false}
    //             pauseOnFocusLoss
    //             draggable
    //             pauseOnHover
    //             theme="colored"
    //         />
    //     </div>
    // );
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: "100%", maxWidth: "1350px", }}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TenantLeaseApplicationEditPage />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ fontSize: 15, fontWeight: 500, marginTop: 30 }}>
                            I authorize, the landlord and it's agents the release of information from previous or current landlords, employers, and bank representatives, and any other entities mentioned. This investgation is for resident screening purposes only, and is strictly confidential. Data collected may be used in aggregate for statistical and marketing purposes.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <h3 className="subHeader">Desired Move-in Date</h3>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={["day", "month", "year"]}
                                value={desiredMoveInDate}
                                onChange={newValue => { setDesiredMoveInDate(newValue) }}
                                renderInput={(params) => <TextField {...params} margin="dense" label="Desired Move-in Date" error={desiredMoveInDateError ? true : false} helperText={desiredMoveInDateError && desiredMoveInDateError} fullWidth={true} required={true} />} />
                        </LocalizationProvider>
                        {FieldsDisabled === true ? (
                            <div style={{ paddingTop: 10 }}>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{`${user?.name?.first} ${user?.name?.last} `}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Applicant</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {ip}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{moment(currentDate).format("MMMM DD, YYYY [at] hh:mm:ss A [GMT]Z")}</p>
                                <img
                                    style={{
                                        border: "1px solid #dee2e6",
                                        padding: "3px",
                                        width: 300,
                                        height: 200,
                                    }}
                                    src={signImg}
                                />
                            </div>
                        ) : (
                            <div style={{ paddingTop: 10 }}>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{`${user?.name?.first} ${user?.name?.last} `}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Applicant</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {ip}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{moment(currentDate).format("MMMM DD, YYYY [at] hh:mm:ss A [GMT]Z")}</p>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 300, maxWidth: 300 }}>
                                    <div style={{ cursor: "pointer" }} className="signboxx">
                                        <SignaturePad penColor='#4285f4'
                                            onEnd={() => { setIsSigned(true) }}
                                            canvasProps={{ width: 300, height: 200, className: 'sig-canvas curSig' }}
                                            ref={signatureRef} /></div>
                                    <div>
                                        <Button sx={{ fontWeight: 'bold' }} onClick={clearSignature}>Clear</Button>
                                    </div>
                                    {signatureError &&
                                        <span className="form-item-error">
                                            {signatureError}
                                        </span>
                                    }
                                </div>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {Modify === true ? (
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    sx={{ fontSize: 15 }}
                                    onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/history`); }}
                                    startIcon={<FaStepBackward />}
                                >HISTORY</Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{ fontSize: 15 }}
                                    onClick={handleAgreementSubmit}
                                >SAVE</Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    sx={{ fontSize: 15 }}
                                    disabled={valStatus === true && isSigned == true ? false : true}
                                    onClick={handleAgreementSubmit}
                                    endIcon={<FaStepForward />}
                                >SUBMIT</Button>
                            </div>
                        ) : (
                            <>
                                {SubmittedStatus === true ? (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/history`); }}
                                            startIcon={<FaStepBackward />}
                                        >HISTORY</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={(e) => handleModify(e)}
                                        >MODIFY</Button>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/history`); }}
                                            startIcon={<FaStepBackward />}
                                        >HISTORY</Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            sx={{ fontSize: 15 }}
                                            disabled={valStatus === true && isSigned == true ? false : true}
                                            onClick={handleAgreementSubmit}
                                        >SAVE</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            disabled={valStatus === true && isSigned == true ? false : true}
                                            onClick={handleAgreementSubmit}
                                            endIcon={<FaStepForward />}
                                        >SUBMIT</Button>
                                    </div>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default AgreementForm;