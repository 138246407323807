import { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { unsetUser } from "../redux/slices/userSlice";
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupIcon from '@mui/icons-material/Group';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getUser, superUserLogin, superUserSecretKeyReset } from '../services';
import { CardContent, Dialog, Icon, InputAdornment, Popover, TextField, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: '#FFFFFF',
        fontSize: 15,
    },
}));

const NavBar = ({ profileImage, setProfileImage }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    // Password Show & Hide
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const [showPasswordUpdateField, setShowPasswordUpdateField] = useState(false);
    const handleClickShowPasswordUpdateField = () => setShowPasswordUpdateField(!showPasswordUpdateField);

    // secret KeY Fields
    const [SuperUserSecretKey, setSuperUserSecretKey] = useState("");
    const [superUserSecretKeyUpdate, setSuperUserSecretKeyUpdate] = useState("");

    // Super User Popover and Dialog boxs
    const [superUserSecretKeyPatchDialog, setSuperUserSecretKeyPatchDialog] = useState(false);
    const [superUserPopper, setSuperUserPopper] = useState(null);
    const [superUserSecretKeyUpdateDialog, setSuperUserSecretKeyUpdateDialog] = useState(false);

    const superUserOpen = Boolean(superUserPopper);
    const superUserId = superUserOpen ? 'simple-popover' : undefined;

    const closeProfileMenu = () => {
        setProfileMenuOpen(false);
    }

    const handleLogoutClick = () => {
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("super-user");
        dispatch(unsetUser());
        navigate(`/login`);
        setProfileImage(null);
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const propNav = () => {
        getUser().then((res) => {
            navigate(`/manager/properties/${res?.data?.data?.manager}`);
        })
    }

    // Dialog box Open and Close Functions

    const handleSuperUserPopperOpen = (event) => {
        if (sessionStorage.getItem("super-user")) {
            navigate(`/users/managers`);
        } else {
            setSuperUserPopper(event.currentTarget);
        }
    }

    const handleSuperUserPopperClose = () => {
        setSuperUserPopper(null);
        setSuperUserSecretKey("");
    }

    const superUserSecretKeyUpdateDialogOpen = () => {
        setSuperUserSecretKeyUpdateDialog(true);
    }

    const superUserSecretKeyUpdateDialogClose = () => {
        setSuperUserSecretKeyUpdateDialog(false);
    }

    const superUserSecretKeyPatchDialogOpen = () => {
        setSuperUserSecretKeyPatchDialog(true);
    }

    const superUserSecretKeyPatchDialogClose = () => {
        setSuperUserSecretKeyPatchDialog(false);
        setSuperUserSecretKeyUpdate("");

    }

    // Super User Login Function

    const superUserLoginClick = () => {
        let data = {
            secretKey: SuperUserSecretKey,
        }
        superUserLogin(data).then((res) => {
            superUserSecretKeyUpdateDialogOpen();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    // If super user secret user secret key matches navigate to user dashboard 

    const dialogNoClick = () => {
        superUserSecretKeyUpdateDialogClose();
        handleSuperUserPopperClose();
        sessionStorage.setItem("super-user", "super user logged in");
        navigate(`/users/managers`);
    }

    // Update secret key dialog box

    const dialogYesClick = () => {
        superUserSecretKeyPatchDialogOpen();
    }

    // Super User Update Function

    const superUserUpdateClick = () => {
        let data = {
            secretKey: superUserSecretKeyUpdate,
        }
        superUserSecretKeyReset(data).then((res) => {
            superUserSecretKeyPatchDialogClose();
            superUserSecretKeyUpdateDialogClose();
            handleSuperUserPopperClose();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    return (
        <AppBar position="static" sx={{ backgroundColor: "#ffffff" }}>
            <Box sx={{ px: 5 }}>
                <Toolbar disableGutters sx={{ display: "flex", justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                        <Avatar alt="Remy Sharp" src="/images/ico.png" sx={{ display: { xs: 'none', md: 'flex' } }} />
                        <Typography
                            variant="h4"
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 'bold',
                                color: '#4a90e2',
                                textDecoration: 'none',
                            }}
                        >UnityOn
                        </Typography>
                    </div>
                    {sessionStorage.getItem("authToken") && user?.manager ?
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <button
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                className='navBar-mobile-menu-icon'
                            >
                                <MenuIcon />
                            </button>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {sessionStorage.getItem("authToken") && user?.manager ?
                                    <MenuItem onClick={handleCloseNavMenu} className='navBar-mobile-view-link-flex-style'>
                                        <HomeIcon />
                                        <Typography textAlign="center" component="a" href="/manager/home" sx={{ color: "black" }}>Home</Typography>
                                    </MenuItem> : ""}
                                {sessionStorage.getItem("authToken") && user?.manager ?
                                    <MenuItem onClick={handleCloseNavMenu} className='navBar-mobile-view-link-flex-style'>
                                        <ApartmentIcon />
                                        <Typography textAlign="center" component="a" href={`/manager/properties/${user?.manager}`} sx={{ color: "black" }}>Properties</Typography>
                                    </MenuItem> : ""}
                                {sessionStorage.getItem("authToken") && user?.manager ?
                                    <MenuItem onClick={handleCloseNavMenu} className='navBar-mobile-view-link-flex-style'>
                                        <AttachMoneyIcon />
                                        <Typography textAlign="center" component="a" href="/manager/finance" sx={{ color: "black" }}>Financials</Typography>
                                    </MenuItem> : ""}
                                {sessionStorage.getItem("authToken") && user?.manager ?
                                    <MenuItem onClick={handleCloseNavMenu} className='navBar-mobile-view-link-flex-style'>
                                        <GroupIcon />
                                        <Typography textAlign="center" component="a" href="/manager/team/managers" sx={{ color: "black" }}>Team Management</Typography>
                                    </MenuItem> : ""}
                                {sessionStorage.getItem("authToken") && user?.manager ?
                                    <MenuItem className='navBar-mobile-view-link-flex-style' onClick={(e) => { handleSuperUserPopperOpen(e) }}>
                                        <GroupIcon />
                                        <Typography textAlign="center" sx={{ color: "black" }}>User Dashboard</Typography>
                                    </MenuItem> : ""}
                            </Menu>
                        </Box> : ""}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                        <Avatar alt="Remy Sharp" src="/images/ico.png" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h4"
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontWeight: 'bold',
                                color: '#4a90e2',
                                textDecoration: 'none',
                            }}
                        >UnityOn
                        </Typography>
                    </div>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: "center", gap: 10 } }}>
                        {sessionStorage.getItem("authToken") && user?.manager ?
                            <div className="navBar-link-flex-style" onClick={() => { navigate(`manager/home`) }}>
                                <HomeIcon />
                                <span
                                    onClick={handleCloseNavMenu}
                                    style={{ fontSize: 18 }}>
                                    Home
                                </span>
                            </div> : ""}
                        {sessionStorage.getItem("authToken") && user?.manager ?
                            <div className="navBar-link-flex-style" onClick={() => { propNav() }}>
                                <ApartmentIcon />
                                <span
                                    onClick={handleCloseNavMenu}
                                    style={{ fontSize: 18 }}>
                                    Properties
                                </span>
                            </div> : ""}
                        {sessionStorage.getItem("authToken") && user?.manager ?
                            <div className="navBar-link-flex-style" onClick={() => { navigate(`/manager/finance`) }}>
                                <AttachMoneyIcon />
                                <span
                                    onClick={handleCloseNavMenu}
                                    style={{ fontSize: 18 }}>
                                    Financials
                                </span>
                            </div> : ""}
                        {sessionStorage.getItem("authToken") && user?.manager ?
                            <div className="navBar-link-flex-style" onClick={() => { navigate(`manager/team/managers`) }}>
                                <GroupIcon />
                                <span
                                    onClick={handleCloseNavMenu}
                                    style={{ fontSize: 18 }}>
                                    Team Management
                                </span>
                            </div> : ""}
                        {sessionStorage.getItem("authToken") && user?.manager ?
                            <div className="navBar-link-flex-style" onClick={(e) => { handleSuperUserPopperOpen(e); }}>
                                <GroupIcon />
                                <span
                                    onClick={handleCloseNavMenu}
                                    style={{ fontSize: 18 }}>
                                    User Dashboard
                                </span>
                            </div> : ""}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <div onClick={handleOpenUserMenu} style={{ padding: 0, color: 'black', fontSize: "1.5rem" }}>
                            {!user &&
                                <Link to="/login">
                                    <Button variant="contained" color="warning">Login</Button>
                                </Link>
                            }
                            {user && <div
                                onClick={() => { setProfileMenuOpen(!profileMenuOpen) }}
                            >
                                <CustomTooltip title={`${user.name.first} ${user.name.last}`} TransitionComponent={Zoom} placement="bottom" arrow>
                                    <span style={{ cursor: 'pointer' }}>
                                        {profileImage ? (
                                            <Avatar alt="Remy Sharp" src={profileImage} />
                                        ) : (
                                            <PersonIcon />)}
                                    </span>
                                </CustomTooltip>
                            </div>}
                        </div>
                        {profileMenuOpen &&
                            <Menu
                                anchorEl={anchorElUser}
                                id="account-menu"
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {user?.manager &&
                                    <MenuItem onClick={() => { handleCloseUserMenu(); navigate(`/manager/home`) }}>
                                        <Typography textAlign="center" onClick={closeProfileMenu} sx={{ color: "black" }}>Manager Dashboard</Typography>
                                    </MenuItem>}
                                {!user?.manager &&
                                    <MenuItem onClick={() => { handleCloseUserMenu(); navigate(`/tenant/dashboard`) }}>
                                        <Typography textAlign="center" onClick={closeProfileMenu} sx={{ color: "black" }}>Tenant Dashboard</Typography>
                                    </MenuItem>}
                                {user?.manager &&
                                    <MenuItem onClick={() => { handleCloseUserMenu(); navigate(`/user/profile`) }}>
                                        <Typography textAlign="center" onClick={closeProfileMenu} sx={{ color: "black" }}>Profile</Typography>
                                    </MenuItem>}
                                {!user?.manager &&
                                    <MenuItem onClick={() => { handleCloseUserMenu(); navigate(`/user/profile`) }}>
                                        <Typography textAlign="center" onClick={closeProfileMenu} sx={{ color: "black" }}>Profile</Typography>
                                    </MenuItem>}
                                <MenuItem onClick={() => {
                                    handleLogoutClick();
                                    closeProfileMenu();
                                    handleCloseUserMenu()
                                }}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>}
                    </Box>
                </Toolbar>
                <Popover
                    id={superUserId}
                    open={superUserOpen}
                    anchorEl={superUserPopper}
                    onClose={handleSuperUserPopperClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ padding: "20px", display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h6" sx={{ pb: 2 }} textAlign="center">Super User</Typography>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label={"Secret Key"}
                            sx={{ pb: "20px" }}
                            value={SuperUserSecretKey}
                            onChange={e => { setSuperUserSecretKey(e.target.value) }}
                            // fullWidth={true}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            sx={{ cursor: 'pointer' }}
                                            onClick={handleClickShowPassword}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </Icon>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button
                            type="submit"
                            color='warning'
                            sx={{ fontSize: 15 }}
                            variant="contained"
                            fullWidth={true}
                            onClick={() => { superUserLoginClick() }}
                        >
                            Login
                        </Button>
                    </div>
                </Popover>
            </Box>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={superUserSecretKeyUpdateDialog}
                onClose={superUserSecretKeyUpdateDialogClose}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Do you want to update secret key?
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "right", flexWrap: 'wrap', gap: 10 }}>
                    <div>
                        <Button
                            variant="contained"
                            color='primary'
                            onClick={() => { dialogYesClick() }}
                            sx={{ fontSize: 15 }}
                        >
                            Yes
                        </Button>
                        <Button
                            color='error'
                            sx={{ fontSize: 15 }}
                            onClick={() => { dialogNoClick() }}
                        >
                            No
                        </Button>
                    </div>
                </CardContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={superUserSecretKeyPatchDialog}
                onClose={superUserSecretKeyPatchDialogClose}
            >
                <div style={{ padding: "20px", display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <Typography variant="h6" sx={{ pb: 2 }} textAlign="center">Update Super User</Typography>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label={"Secret Key"}
                        sx={{ pb: "20px" }}
                        value={superUserSecretKeyUpdate}
                        onChange={e => { setSuperUserSecretKeyUpdate(e.target.value) }}
                        fullWidth={true}
                        type={showPasswordUpdateField ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon
                                        sx={{ cursor: 'pointer' }}
                                        onClick={handleClickShowPasswordUpdateField}>
                                        {showPasswordUpdateField ? <Visibility /> : <VisibilityOff />}
                                    </Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'right', gap: 10 }}>
                        <Button
                            type="submit"
                            color='warning'
                            sx={{ fontSize: 15 }}
                            variant="contained"
                            onClick={() => { superUserUpdateClick() }}
                        >
                            Update
                        </Button>
                        <Button
                            type="submit"
                            color='error'
                            sx={{ fontSize: 15 }}
                            onClick={() => { superUserSecretKeyPatchDialogClose() }}
                        >
                            cancel
                        </Button>
                    </div>
                </div>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </AppBar>
    )
}

export default NavBar;
