import {
  Box,
  MenuItem,
  Checkbox,
  TextField,
  Breadcrumbs,
  Card,
  CardContent,
  FormControlLabel,
  Link,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  Button,
  OutlinedInput,
  InputAdornment,
  FormControl,
  CardActions,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "react-datepicker/dist/react-datepicker.css";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import PetsIcon from "@mui/icons-material/Pets";
import CampaignIcon from "@mui/icons-material/Campaign";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import EngineeringIcon from "@mui/icons-material/Engineering";
import KeyIcon from "@mui/icons-material/Key";
import PropaneTankIcon from "@mui/icons-material/PropaneTank";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import GavelIcon from "@mui/icons-material/Gavel";
import AttachmentIcon from "@mui/icons-material/Attachment";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import {
  leaseagreement,
  postManagerLeaseAgreementAttachment,
  removeManagerLeaseAgreementAttachment,
  getManagerAgreement, getLeaseappAgreementManager, getApiBaseURL,
  getSingleProperty,
} from "../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const timePeriod = [
  {
    value: "86400",
    label: "Per Day",
  },
  {
    value: "604800",
    label: "Per Week",
  },
  {
    value: "2592000",
    label: "Per Month",
  },
];

const petsservice = [
  {
    value: "removed from premises",
    label: "removed from premises",
  },
  {
    value: "put in a kennel",
    label: "put in a kennel",
  },
];

const noise = [
  { value: "06:00 PM", label: "06:00 PM" },
  { value: "07:00 PM", label: "07:00 PM" },
  { value: "08:00 PM", label: "08:00 PM" },
  { value: "09:00 PM", label: "09:00 PM" },
  { value: "10:00 PM", label: "10:00 PM" },
  { value: "11:00 PM", label: "11:00 PM" },
  { value: "12:00 AM", label: "12:00 AM" },
  { value: "01:00 AM", label: "01:00 AM" },
  { value: "02:00 AM", label: "02:00 AM" },
  { value: "03:00 AM", label: "03:00 AM" },
  { value: "04:00 AM", label: "04:00 AM" },
  { value: "05:00 AM", label: "05:00 AM" },
  { value: "06:00 AM", label: "06:00 AM" },
];


const hours = [
  { value: "06:00 AM", label: "06:00 AM" },
  { value: "07:00 AM", label: "07:00 AM" },
  { value: "08:00 AM", label: "08:00 AM" },
  { value: "09:00 AM", label: "09:00 AM" },
  { value: "10:00 AM", label: "10:00 AM" },
  { value: "11:00 AM", label: "11:00 AM" },
  { value: "12:00 PM", label: "12:00 PM" },
  { value: "01:00 PM", label: "01:00 PM" },
  { value: "02:00 PM", label: "02:00 PM" },
  { value: "03:00 PM", label: "03:00 PM" },
  { value: "04:00 PM", label: "04:00 PM" },
  { value: "05:00 PM", label: "05:00 PM" },
  { value: "06:00 PM", label: "06:00 PM" },
  { value: "07:00 PM", label: "07:00 PM" },
  { value: "08:00 PM", label: "08:00 PM" },
  { value: "09:00 PM", label: "09:00 PM" },
  { value: "10:00 PM", label: "10:00 PM" },
  { value: "11:00 PM", label: "11:00 PM" },
  { value: "12:00 AM", label: "12:00 AM" },
  { value: "01:00 AM", label: "01:00 AM" },
  { value: "02:00 AM", label: "02:00 AM" },
  { value: "03:00 AM", label: "03:00 AM" },
  { value: "04:00 AM", label: "04:00 AM" },
  { value: "05:00 AM", label: "05:00 AM" },
];

const days = [
  { value: "year", label: "days in a year" },
  { value: "month", label: "days in a month" },
];

const CreateLease = () => {
  const params = useParams();
  const navigate = useNavigate();
  const fileRef = useRef();

  const [property, setProperty] = useState(null);

  const [beginDate, setbeginDate] = useState(null);
  const [attachment, setattachment] = useState([]);

  const [expiryDate, setexpiryDate] = useState(null);
  const [moveInDate, setmoveInDate] = useState(null);
  const [securityDepositAmount, setsecurityDepositAmount] = useState(0);
  const [securityDepositAmountDetails, setsecurityDepositAmountDetails] = useState("");
  const [securityDepositPaymentDeadline, setsecurityDepositPaymentDeadline] = useState("prior to moving in");
  const [rentAmount, setrentAmount] = useState(0);
  const [rentAmountDetails, setrentAmountDetails] = useState("");
  const [rentLateFeesAmount, setrentLateFeesAmount] = useState(5);
  const [rentLateFeesGraceDuration, setrentLateFeesGraceDuration] = useState(1);
  const [rentLateFeesDuration, setrentLateFeesDuration] = useState(0);
  const [rentLateFeesDurationInWord, setrentLateFeesDurationInWord] = useState("");

  // radio button
  const [havexpiryDate, setHavexpiryDate] = useState(false);
  const [tenantSecurity, setTenantSecurity] = useState("");
  const [smokingAllowed, setsmokingAllowed] = useState(false);
  const [smokingAllowedAreaDetails, setsmokingAllowedAreaDetails] = useState("");
  const [petsAllowed, setpetsAllowed] = useState(false);
  const [grillingAllowed, setGrillingAllowed] = useState(false);
  const [leadContaminated, setleadContaminated] = useState(false);
  const [renterInsurance, setRenterInsurance] = useState("optional")
  const [asbestosContaminated, setasbestosContaminated] = useState(false);
  const [minors, setMinors] = useState([]);
  const [adults, setadults] = useState([]);
  const [pets, setPets] = useState([]);
  const [leadContaminationRecordsAvailable, setleadContaminationRecordsAvailable] = useState(false);
  const [petsMustBeNeutered, setpetsMustBeNeutered] = useState(false);
  const [petControlWhileServicing, setpetControlWhileServicing] = useState("");
  const [landscapingResponsibility, setlandscapingResponsibility] = useState("Tenant");
  const [quietHoursBegin, setquietHoursBegin] = useState("06:00 PM");
  const [quietHoursEnd, setquietHoursEnd] = useState("06:00 AM");
  const [grillingAllowedAreaDetails, setgrillingAllowedAreaDetails] = useState("");

  const [paymentBeginTime, setpaymentBeginTime] = useState("08:00 AM");
  const [paymentEndTime, setpaymentEndTime] = useState("05:00 PM");
  const [paymentCheckFirstBounceFee, setpaymentCheckFirstBounceFee] = useState("");
  const [paymentCheckBounceFee, setpaymentCheckBounceFee] = useState(0);
  const [doorLockChangeFees, setdoorLockChangeFees] = useState(25);
  const [garageDoorLockChangeFees, setgarageDoorLockChangeFees] = useState(50);
  const [guestStayAllowedNumberOfDays, setguestStayAllowedNumberOfDays] = useState(3);
  const [guestStayAllowedWithinDuration, setguestStayAllowedWithinDuration] = useState("month");
  const [attorneyFeesCompensationRules, setattorneyFeesCompensationRules] = useState("");
  const [attorneyFeesMaxCompensationAmount, setattorneyFeesMaxCompensationAmount] = useState(0);
  const [tenantSignees, settenantSignees] = useState([]);
  // const [managerSign, setmanagerSign] = useState([]);
  const [application, setApplication] = useState();
  const [asbestosContaminationRecordsAvailable, setAsbestosContaminationRecordsAvailable] = useState(false);
  const [monthYearRadio, setMonthYearRadio] = useState("month-to-month");
  const [loading, setLoading] = useState(true);

  const handlePeriod = (event) => {
    setrentLateFeesDuration(event.target.value);
  };

  // Acceptable Payment Method
  const [paymentMethods, setpaymentMethods] = useState([]);
  const [paymentName, setpaymentName] = useState("");
  const [paymentDescription, setpaymentDescription] = useState("");

  const handlepaymentChange = (e, index) => {
    const { name, value } = e.target;
    const paymethod = [...paymentMethods];
    paymethod[index][name] = value;
    setpaymentMethods(paymethod);
  }

  const handleAddPaymentClick = () => {
    let newPay = [...paymentMethods];
    newPay.push({
      name: paymentName,
      description: paymentDescription,
    })
    if (paymentName !== "" || paymentDescription !== "") {
      setpaymentName('');
      setpaymentDescription('');
      setpaymentMethods(newPay);
    }
  }
  const handleRemovepaymentClick = (index) => {
    let newPay = paymentMethods.filter((value, i) => {
      return index !== i;
    });
    setpaymentMethods(newPay);
  }

  // Addenda
  const [addenda, setaddenda] = useState([]);
  const [addendumtitle, setAddendumtitle] = useState("")
  const [addendumcontent, setAddendumcontent] = useState("")

  const handleaddendaChange = (e, index) => {
    const { name, value } = e.target;
    const addendaa = [...addenda];
    addendaa[index][name] = value;
    setaddenda(addendaa);
  }
  const handleAddAddendumClick = () => {
    let newAddendum = [...addenda];
    newAddendum.push({
      title: addendumtitle,
      content: addendumcontent,
    })
    if (addendumtitle !== "" || addendumcontent !== "") {
      setAddendumtitle("");
      setAddendumcontent("");
      setaddenda(newAddendum);
    }
  }
  const removeAddendumclick = (index) => {
    let newAddendum = addenda.filter((value, i) => {
      return index !== i;
    });
    setaddenda(newAddendum);
  }

  //Utilities
  const [addUtility, setAddUtility] = useState("")
  const [utilitiesIncludedInRentCost, setutilitiesIncludedInRentCost] = useState([]);
  const [utilitiesMandatoryDuringLease, setutilitiesMandatoryDuringLease] = useState([]);

  const handleAddutilityClick = (val) => {
    let utilityMandatory = [...utilitiesMandatoryDuringLease]
    let utilityIncluded = [...utilitiesIncludedInRentCost]
    var status = "";
    if (val != "") {
      utilityMandatory.map((f) => {
        if (val == f.text) {
          status = true
        }
      })
      if (status != true) {
        utilityMandatory.push({
          "text": addUtility,
          "checked": false
        })
        utilityIncluded.push({
          "text": addUtility,
          "checked": false
        })
      }
    }
    setAddUtility("")
    setutilitiesMandatoryDuringLease(utilityMandatory);
    setutilitiesIncludedInRentCost(utilityIncluded);
  }

  const addUtilitychangeClick = (e, index, status) => {
    e.preventDefault();
    let utilityIncluded = [...utilitiesIncludedInRentCost]
    if (status == true) {
      utilityIncluded.map((er, tr) => {
        if (tr == index) {
          er.checked = false
        }
      })
    }
    if (status == false) {
      utilityIncluded.map((er, tr) => {
        if (tr == index) {
          er.checked = true
        }
      })
    }
    setutilitiesIncludedInRentCost(utilityIncluded);
  }

  const addUtilitymustchangeClick = (e, index, status) => {
    e.preventDefault();
    let utilityMandatory = [...utilitiesMandatoryDuringLease]
    if (status == true) {

      utilityMandatory.map((er, tr) => {
        if (tr == index) {
          er.checked = false
        }
      })
    }
    if (status == false) {
      utilityMandatory.map((er, tr) => {
        if (tr == index) {
          er.checked = true
        }
      })
    }
    setutilitiesMandatoryDuringLease(utilityMandatory);
  }

  // Insurance
  const [tenantInsurance, settenantInsurance] = useState("");
  const [tenantInsuranceCoverageItems, settenantInsuranceCoverageItems] = useState([]);

  const handleaddInsuranceCover = (val) => {
    let insuranceCover = [...tenantInsuranceCoverageItems];
    var status = "";
    if (val != "") {
      insuranceCover.map((g) => {
        if (val == g.text) {
          status = true
        }
      })
      if (status != true) {
        insuranceCover.push({
          "text": tenantInsurance,
          "checked": false
        })
      }
    }
    if (tenantInsurance !== "") {
      settenantInsurance("")
      settenantInsuranceCoverageItems(insuranceCover);
    }
  }

  const tenantInsuranceCover = (e, index, status) => {
    e.preventDefault();
    let cover = [...tenantInsuranceCoverageItems]
    if (status == true) {

      cover.map((er, tr) => {
        if (tr == index) {
          er.checked = false
        }
      })
    }
    if (status == false) {
      cover.map((er, tr) => {
        if (tr == index) {
          er.checked = true
        }
      })
    }
    settenantInsuranceCoverageItems(cover);
  }

  // On the following days
  // const [weekdayss, setweekdayss] = useState([
  //   {
  //     "text": "Sunday",
  //     "checked": false
  //   },
  //   {
  //     "text": "Monday",
  //     "checked": true
  //   },
  //   {
  //     "text": "Tuesday",
  //     "checked": true
  //   },
  //   {
  //     "text": "Wednesday",
  //     "checked": true
  //   },
  //   {
  //     "text": "Thursday",
  //     "checked": true
  //   },
  //   {
  //     "text": "Friday",
  //     "checked": true
  //   },
  //   {
  //     "text": "Saturday",
  //     "checked": false
  //   }
  // ]);

  // const onchangeWeek = (e, val, status) => {
  //   var week = [...weekdayss]
  //   week.map((e, i) => {
  //     if (val == i) {
  //       if (status == true) {
  //         e.checked = false
  //       }
  //       if (status == false) {
  //         e.checked = true
  //       }

  //     }
  //   })
  //   setweekdayss(week)
  // }

  // Attachment
  const attachmentClick = (e) => {
    e.preventDefault();
    var file = e.target.files[0] || undefined,
      supportedFormats = ["application/pdf"];
    if (file && file.type) {
      if (0 > supportedFormats.indexOf(file.type)) {
      } else {
        let form = new FormData();
        form.append("attachment", e.target.files[0]);
        attachment.length = 0
        var fileatt = [...attachment]
        postManagerLeaseAgreementAttachment(params.applicationId, form)
          .then((res) => {
            getManagerAgreement(params.applicationId)
              .then((res) => {
                const responseData1 = res.data.data.attachments;
                responseData1.map((e, i) => {
                  fileatt.push({
                    "fileName": e.fileName,
                    "id": e.id
                  })
                })
                setattachment(fileatt);
              })
          })
      }
    }
  }

  const attachmentremoveClick = (e, id) => {
    e.preventDefault();
    attachment.length = 0
    var fileatts = [...attachment]
    removeManagerLeaseAgreementAttachment(params.applicationId, id)
      .then((res) => {
        const responseData = res.data.data;
      })
    getManagerAgreement(params.applicationId)
      .then((res) => {
        const responseData2 = res.data.data.attachments;
        responseData2.map((e, i) => {
          fileatts.push({
            "fileName": e.fileName,
            "id": e.id
          })
        })
        setattachment(fileatts);
      })
  }

  const attachmentView = (e, id) => {
    var config = "";
    e.preventDefault();
    const authToken = sessionStorage.getItem("authToken");
    if (authToken != undefined) {
      config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
    }
    fetch(`${getApiBaseURL()}/manager/lease-applications/${params.applicationId}/lease-agreement/attachments/${id}`,
      config
    )
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          const link = document.createElement("a");
          link.href = imageDataUrl;
          if (blob.type == "application/pdf") {
            link.setAttribute("Download", "Attachment.pdf");
            document.body.appendChild(link);
            link.click();
          }
        };
      })
  }

  // // Manager Sign
  // const signChange = (e, index, status) => {
  //   var sig = [...managerSign];
  //   sig.map((e, i) => {
  //     if (index == i) {
  //       if (status == true) {
  //         e.required = false
  //       }
  //       if (status == false) {
  //         e.required = true
  //       }
  //     }
  //   })
  //   setmanagerSign(sig);
  // }

  // const renderNames = () => {
  //   return managerSign.map((item, index) => {
  //     return (
  //       <div  >
  //         <Card sx={{ width: 145, height: 50, display: "flex", mt: 3 }}>
  //           <FormControlLabel
  //             sx={{ pl: 2 }}
  //             control={<Checkbox />}
  //             onChange={(e) => signChange(e, index, item.required)}
  //             checked={item.required}
  //             label={item.user}
  //           />
  //         </Card>
  //       </div>
  //     );
  //   });
  // };

  //Tenant Sign
  const tenantSignChange = (e, index, status) => {
    var tenSig = [...tenantSignees];
    tenSig.map((e, i) => {
      if (index == i) {
        if (status == true) {
          e.required = false
        }
        if (status == false) {
          e.required = true
        }
      }
    })
    settenantSignees(tenSig);
  }

  const renderTenantNames = () => {
    return tenantSignees.map((item, index) => {
      return (
        <div key={index}>
          <Card sx={{ width: 145, height: 50, display: "flex", mt: 3 }}>
            <FormControlLabel
              sx={{ pl: 2 }}
              control={<Checkbox />}
              onChange={(e) => tenantSignChange(e, index, item.required)}
              checked={item.required}
              label={item.user}
            />
          </Card>
        </div>
      );
    });
  };

  const [attorneyValue, setAttorneyValue] = useState(2);

  const changeAttorny = (e) => {
    if (e.target.value == "self") {
      setattorneyFeesCompensationRules(e.target.value)
      setAttorneyValue(1)
    }
    if (e.target.value == "prevailing-party") {
      setattorneyFeesCompensationRules(e.target.value)
      setAttorneyValue(2)
    }
    if (e.target.value == "prevailing-party-with-limit") {
      setattorneyFeesCompensationRules(e.target.value)
      setAttorneyValue(3)
    }
  }
  const AttorneyFees = () => {
    return (
      <RadioGroup >
        <FormControlLabel
          value={"self"}
          control={<Radio />}
          label="Each party shall be responsible for their own attorneys' fees and court costs."
          onChange={(e) => changeAttorny(e)}
          checked={attorneyValue === 1}
        />
        <FormControlLabel
          value={"prevailing-party"}
          control={<Radio />}
          label="The prevailing party shall recover, in addition to all other relief, reasonable attorneys' fees and court costs."
          onChange={(e) => changeAttorny(e)}
          checked={attorneyValue === 2}
        />
        <FormControlLabel
          value={"prevailing-party-with-limit"}
          control={<Radio />}
          label="The prevailing party shall recover, in addition to all other relief, attorneys' fees not to exceed the defined Max Compensation Amount"
          onChange={(e) => changeAttorny(e)}
          checked={attorneyValue === 3}
        />
      </RadioGroup>

    )
  }

  const renderAdults = () => {
    if (application != undefined) {
      return application.occupants.adults.map((item, index) => {
        return (
          <div key={index}>
            {" "}
            {index + 1}
            {". "}
            {item.name}
          </div>
        );
      });
    }
  };

  const renderMinors = () => {
    if (application != undefined) {
      return application.occupants.minors.map((item, index) => {
        return (
          <div key={index}>
            {index + 1}
            {". "}
            {item.name}
          </div>
        );
      });
    }
  };

  const renderPets = () => {
    if (application != undefined) {
      return application.occupants.pets.map((item, index) => {
        return (
          <div key={index}>
            {index + 1}
            {". "}
            {item.name}
            {"("}
            {item.description}
            {")"}
          </div>
        );
      });
    }
  };

  const renderAutomobiles = () => {
    if (application != undefined) {
      return application.automobiles.map((item, index) => {
        return (
          <div key={index}>
            {index + 1}
            {". "}
            {item.manufacturer} {item.model}
          </div>
        );
      });
    }
  };

  // Validations
  const [error, setError] = useState({
    beginDate: false,
    expiryDate: false,
    moveInDate: false,
    rentAmount: false,
    rentLateFeesAmount: false,
    rentLateFeesDuration: false,
    rentLateFeesGraceDuration: false,
    securityDepositAmount: false,
    tenantSecurity: false,
    smokingAllowedAreaDetails: false,
    grillingAllowedAreaDetails: false,
    guestStayAllowedNumberOfDays: false,
    guestStayAllowedWithinDuration: false,
    doorLockChangeFees: false,
    garageDoorLockChangeFees: false,
    paymentCheckFirstBounceFee: false,
    paymentCheckBounceFee: false,
    paymentBeginTime: false,
    paymentEndTime: false,
  })

  const handleToggle = (key) => {
    setError((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const handleClose = (key) => {
    setError((prevState) => ({
      ...prevState,
      [key]: false,
    }));
  };

  const validations = () => {
    if (beginDate == null) {
      handleToggle("beginDate")
    } else {
      handleClose("beginDate");
    }
    if (expiryDate == null) {
      handleToggle("expiryDate")
    } else {
      handleClose("expiryDate");
    }
    if (moveInDate == null) {
      handleToggle("moveInDate")
    } else {
      handleClose("moveInDate");
    }
    if (rentAmount == 0) {
      handleToggle("rentAmount")
    } else {
      handleClose("rentAmount");
    }
    if (rentLateFeesAmount == 0) {
      handleToggle("rentLateFeesAmount")
    } else {
      handleClose("rentLateFeesAmount");
    }
    if (rentLateFeesDuration == 0) {
      handleToggle("rentLateFeesDuration")
    } else {
      handleClose("rentLateFeesDuration");
    }
    if (rentLateFeesGraceDuration == 0) {
      handleToggle("rentLateFeesGraceDuration")
    } else {
      handleClose("rentLateFeesGraceDuration");
    }
    if (securityDepositAmount == 0) {
      handleToggle("securityDepositAmount")
    } else {
      handleClose("securityDepositAmount");
    }
    if (securityDepositPaymentDeadline !== "prior to moving in") {
      if (tenantSecurity == "") {
        handleToggle("tenantSecurity")
      } else {
        handleClose("tenantSecurity");
      }
    }
    if (smokingAllowed === true) {
      if (smokingAllowedAreaDetails == "") {
        handleToggle("smokingAllowedAreaDetails")
      } else {
        handleClose("smokingAllowedAreaDetails");
      }
    }
    if (grillingAllowed === true) {
      if (grillingAllowedAreaDetails) {
        handleToggle("grillingAllowedAreaDetails");
      } else {
        handleClose("grillingAllowedAreaDetails");
      }
    }
    if (guestStayAllowedWithinDuration == "") {
      handleToggle("guestStayAllowedWithinDuration")
    } else {
      handleClose("guestStayAllowedWithinDuration");
    }
    if (guestStayAllowedNumberOfDays == 0) {
      handleToggle("guestStayAllowedNumberOfDays")
    } else {
      handleClose("guestStayAllowedNumberOfDays");
    }
    if (doorLockChangeFees == 0) {
      handleToggle("doorLockChangeFees")
    } else {
      handleClose("doorLockChangeFees");
    }
    if (garageDoorLockChangeFees == 0) {
      handleToggle("garageDoorLockChangeFees")
    } else {
      handleClose("garageDoorLockChangeFees")
    }
    if (paymentCheckFirstBounceFee == "") {
      handleToggle("paymentCheckFirstBounceFee");
    } else {
      handleClose("paymentCheckFirstBounceFee");
    }
    if (paymentCheckBounceFee == 0) {
      handleToggle("paymentCheckBounceFee");
    } else {
      handleClose("paymentCheckBounceFee");
    }
    if (paymentBeginTime == "") {
      handleToggle("paymentBeginTime");
    } else {
      handleClose("paymentBeginTime");
    }
    if (paymentEndTime == "") {
      handleToggle("paymentEndTime");
    } else {
      handleClose("paymentEndTime");
    }
  }
  const handleSubmitPreview = (e) => {
    handleSubmit();
    navigate(`/manager/leasepreview/${params.applicationId}`);
  }

  const handleSubmit = (e) => {
    // Validation
    validations();

    // let arr = [];
    // weekdayss.map((el, i) => {
    //   if (el.checked == true) {
    //     arr.push(el.text)
    //   }
    // })

    let arra = [];
    utilitiesIncludedInRentCost.map((e, i) => {
      if (e.checked == true) {
        arra.push(e.text)
      }
    })

    let array = [];
    utilitiesMandatoryDuringLease.map((e, i) => {
      if (e.checked == true) {
        array.push(e.text)
      }
    })

    let arrayy = [];
    tenantInsuranceCoverageItems.map((e, i) => {
      if (e.checked == true) {
        arrayy.push(e.text)
      }
    })

    let data = {
      beginDate: beginDate,
      expiryDate: expiryDate,
      moveInDate: moveInDate,
      securityDepositAmount: securityDepositAmount,
      securityDepositAmountDetails: securityDepositAmountDetails,
      securityDepositPaymentDeadline: securityDepositPaymentDeadline,
      rentAmount: rentAmount,
      rentAmountDetails: rentAmountDetails,
      rentLateFeesAmount: rentLateFeesAmount,
      rentLateFeesGraceDuration: (parseInt(rentLateFeesGraceDuration) - 1) * 86400,
      rentLateFeesDuration: parseInt(rentLateFeesDuration),
      rentLateFeesDurationInWord: rentLateFeesDurationInWord,
      occupants: {
        minors: minors,
        adults: adults,
        pets: pets
      },
      smokingAllowed: smokingAllowed,
      smokingAllowedAreaDetails: smokingAllowedAreaDetails,
      asbestosContaminated: asbestosContaminated,
      asbestosContaminationRecordsAvailable: asbestosContaminationRecordsAvailable,
      leadContaminated: leadContaminated,
      leadContaminationRecordsAvailable: leadContaminationRecordsAvailable,
      petsAllowed: petsAllowed,
      petsMustBeNeutered: petsMustBeNeutered,
      petControlWhileServicing: petControlWhileServicing,
      landscapingResponsibility: landscapingResponsibility,
      quietHoursBegin: quietHoursBegin,
      quietHoursEnd: quietHoursEnd,
      grillingAllowed: grillingAllowed,
      grillingAllowedAreaDetails: grillingAllowedAreaDetails,
      paymentBeginTime: paymentBeginTime,
      addenda: addenda,
      paymentMethods: paymentMethods,
      utilitiesIncludedInRentCost: arra,
      utilitiesMandatoryDuringLease: array,
      // paymentSubmissionDays: arr,
      paymentEndTime: paymentEndTime,
      paymentCheckFirstBounceFee: paymentCheckFirstBounceFee,
      paymentCheckBounceFee: paymentCheckBounceFee,
      doorLockChangeFees: doorLockChangeFees,
      garageDoorLockChangeFees: garageDoorLockChangeFees,
      guestStayAllowedNumberOfDays: guestStayAllowedNumberOfDays,
      guestStayAllowedWithinDuration: guestStayAllowedWithinDuration,
      tenantInsuranceCoverageItems: arrayy,
      tenantInsurance: renterInsurance,
      attorneyFeesCompensationRules: attorneyFeesCompensationRules,
      attorneyFeesMaxCompensationAmount: attorneyFeesMaxCompensationAmount,
      tenantSignees: tenantSignees,
      // managerSignees: managerSign,
      leaseMonthYearSelect: monthYearRadio
    };
    leaseagreement(params.applicationId, data).then(response => {
      toast.success("Lease Aggreement saved successfully");
      getApplication();
    }).catch((err) => { toast.error(err.response.data.error.message) });
  };

  const PayCard = [];
  PayCard.push({
    method: "",
    description: ""
  })

  const getApplication = () => {
    setLoading(true);
    let adu = [...adults];
    adu.length = 0;
    let min = [...minors];
    min.length = 0;
    let pe = [...pets];
    pe.length = 0;
    let ten = [...tenantSignees];
    ten.length = 0;
    // let man = [...managerSign];
    // man.length = 0;
    getLeaseappAgreementManager(params.applicationId).then((response) => {
      let data = response.data.data;
      setApplication(data);
      data.occupants.adults.map((e) => {
        adu.push({
          "name": e.name,
          "relationship": e.relationship,
        })
      })
      setadults(adu);
      data.occupants.minors.map((e) => {
        min.push({
          "name": e.name,
          "relationship": e.relationship,
        })
      })
      setMinors(min);
      data.occupants.pets.map((e) => {
        pe.push({
          "name": e.name,
          "description": e.description,
        })
      })
      setPets(pe);
      // data.managerSignees.map((e) => {
      //   man.push({
      //     "user": e.fullName,
      //     "required": e.required,
      //   })
      // })
      // setmanagerSign(man);
      data.tenantSignees.map((e) => {
        ten.push({
          "user": e.fullName,
          "required": e.required,
        })
      })
      settenantSignees(ten);
      setsecurityDepositAmount(data.securityDepositAmount ?? 0);
      setsecurityDepositAmountDetails(data.securityDepositAmountDetails ?? "");
      setrentAmount(data.rentAmount ?? 0);
      setrentLateFeesAmount(data.rentLateFeesAmount ?? 5);
      const graceDuration = response.data.data.rentLateFeesGraceDuration;
      setrentLateFeesGraceDuration((graceDuration / 86400) + 1 || 1);
      setrentLateFeesDuration(data.rentLateFeesDuration ?? 0);
      setrentLateFeesDurationInWord(data.rentLateFeesDurationInWord ?? "");
      setsmokingAllowed(data.smokingAllowed ?? false);
      setsmokingAllowedAreaDetails(data.smokingAllowedAreaDetails ?? "");
      setasbestosContaminated(data.asbestosContaminated ?? false);
      setAsbestosContaminationRecordsAvailable(data.asbestosContaminationRecordsAvailable ?? false);
      setleadContaminated(data.leadContaminated ?? false);
      setleadContaminationRecordsAvailable(data.leadContaminationRecordsAvailable ?? false);
      setpetsAllowed(data.petsAllowed ?? false);
      setpetsMustBeNeutered(data.petsMustBeNeutered ?? false);
      setpetControlWhileServicing(data.petControlWhileServicing ?? "");
      setlandscapingResponsibility(data.landscapingResponsibility ?? "Tenant");
      setquietHoursBegin(data.quietHoursBegin ?? "06:00 PM");
      setquietHoursEnd(data.quietHoursEnd ?? "06:00 AM");
      setGrillingAllowed(data.grillingAllowed ?? false);
      setgrillingAllowedAreaDetails(data.grillingAllowedAreaDetails ?? "");
      setpaymentBeginTime(data.paymentBeginTime ?? "08:00 AM");
      setaddenda(data.addenda ?? []);
      setpaymentEndTime(data.paymentEndTime ?? "05:00 PM");
      setpaymentCheckFirstBounceFee(data.paymentCheckFirstBounceFee ?? "");
      setpaymentCheckBounceFee(data.paymentCheckBounceFee ?? 0);
      setdoorLockChangeFees(data.doorLockChangeFees ?? 25);
      setgarageDoorLockChangeFees(data.garageDoorLockChangeFees ?? 50);
      setguestStayAllowedNumberOfDays(data.guestStayAllowedNumberOfDays ?? 3);
      setguestStayAllowedWithinDuration(data.guestStayAllowedWithinDuration ?? "month");
      setattorneyFeesCompensationRules(data.attorneyFeesCompensationRules ?? "prevailing-party");
      setattorneyFeesMaxCompensationAmount(data.attorneyFeesMaxCompensationAmount ?? 0);
      setrentAmountDetails(data.rentAmountDetails ?? "");
      setbeginDate(data?.beginDate ?? null);
      setexpiryDate(data?.expiryDate ?? null);
      setmoveInDate(data?.moveInDate ?? null);
      setsecurityDepositPaymentDeadline(data.securityDepositPaymentDeadline ?? "prior to moving in");
      setRenterInsurance(data.tenantInsurance ?? "optional");
      setMonthYearRadio(data.leaseMonthYearSelect ?? "month-to-month")

      // var week1 = [...weekdayss]
      // weekdayss.map((e, i) => {
      //   let results = data.paymentSubmissionDays.
      //     filter((auto) => auto.includes(e.text));
      //   let c = e;
      //   if (results.length > 0) c.checked = true; else c.checked = false;
      //   week1.push({
      //     "text": c.text,
      //     "checked": c.checked
      //   })
      // })

      var utiInclude = [...utilitiesIncludedInRentCost]
      utiInclude.length = 0;
      var utiMandatory = [...utilitiesMandatoryDuringLease]
      utiMandatory.length = 0;
      var insCover = [...tenantInsuranceCoverageItems]
      insCover.length = 0;
      if (data.utilitiesIncludedInRentCost != undefined
        && data.utilitiesIncludedInRentCost != ""
      ) {

        data.utilitiesIncludedInRentCost.map((e, r) => {
          utiInclude.push({
            "text": e,
            "checked": true
          })
        })
      }
      else {
        utiInclude.push(
          {
            "text": "Water",
            "checked": false
          },
          {
            "text": "Electricity",
            "checked": false
          },
          {
            "text": "Gas",
            "checked": false
          },
          {
            "text": "Trash",
            "checked": false
          },
          {
            "text": "Sewer",
            "checked": false
          },
          {
            "text": "Internet",
            "checked": false
          },
        )
      }
      if (data.utilitiesMandatoryDuringLease != undefined
        && data.utilitiesMandatoryDuringLease != ""
      ) {
        data.utilitiesMandatoryDuringLease.map((h, j) => {
          utiMandatory.push({
            "text": h,
            "checked": true
          })
        })
      }
      else {
        utiMandatory.push(
          {
            "text": "Water",
            "checked": false
          },
          {
            "text": "Electricity",
            "checked": false
          },
          {
            "text": "Gas",
            "checked": false
          },
          {
            "text": "Trash",
            "checked": false
          },
          {
            "text": "Sewer",
            "checked": false
          },
          {
            "text": "Internet",
            "checked": false
          },
        )
      }
      if (data.tenantInsuranceCoverageItems != undefined
        && data.tenantInsuranceCoverageItems != ""
      ) {

        data.tenantInsuranceCoverageItems.map((e, r) => {
          insCover.push({
            "text": e,
            "checked": true
          })
        })
      }
      else {
        insCover.push(
          {
            "text": "Grills",
            "checked": false
          },
          {
            "text": "Pets",
            "checked": false
          },
          {
            "text": "Satellite Dishes/Antennae",
            "checked": false
          },
        )
      }
      settenantInsuranceCoverageItems(insCover);
      setutilitiesIncludedInRentCost(utiInclude);
      setutilitiesMandatoryDuringLease(utiMandatory);

      if (data.attorneyFeesCompensationRules === "self") {
        setattorneyFeesCompensationRules("self");
        setAttorneyValue(1)
      }
      if (data.attorneyFeesCompensationRules === "prevailing-party") {
        setattorneyFeesCompensationRules("prevailing-party");
        setAttorneyValue(2)
      }
      if (data.attorneyFeesCompensationRules === "prevailing-party-with-limit") {
        setattorneyFeesCompensationRules("prevailing-party-with-limit");
        setAttorneyValue(3)
      }

      let file = [...attachment];
      file.length = 0;
      data.attachments.map((e, i) => {
        file.push({
          "fileName": e.fileName,
          "id": e.id
        })
      })
      setattachment(file)
      let payMethod = [...paymentMethods];
      payMethod.length = 0;
      if (data.paymentMethods.length !== 0
      ) {
        data.paymentMethods.map((e, r) => {
          payMethod.push({
            name: e.name,
            description: e.description,
          })
        })
      } else {
        payMethod.push(
          {
            name: "Zelle",
            description: ""

          },
          {
            name: "Cash",
            description: ""

          },
          {
            name: "Personal Check",
            description: ""

          },
          {
            name: "Cashier Check",
            description: ""

          },
          {
            name: "Money Order",
            description: ""

          },
          {
            name: "Bank Transfer",
            description: ""

          },
          {
            name: "Credit Card",
            description: ""

          },
          {
            name: "Paypal",
            description: ""

          })
      }
      setpaymentMethods(payMethod);
      if (data?.property) {
        return getSingleProperty(data?.property);
      }
    }).then((res) => {
      let responseData = res?.data?.data;
      setProperty(responseData);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    window.scroll(0, 0);
    getApplication();
  }, []);

  useEffect(() => {
    setsecurityDepositPaymentDeadline(`within ${tenantSecurity} days after the lease starts`);
  }, [tenantSecurity]);

  const handleMonthYearRadio = (value) => {
    setMonthYearRadio(value);
  }

  const handleBeginDateChange = (date) => {
    setbeginDate(date);

    // Calculate the expiry date with a 1-year gap
    const oneYearLater = new Date(date);
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
    setexpiryDate(oneYearLater);
  };

  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div style={{ padding: "30px" }}>
      <p style={{ fontSize: 22, textAlign: "center", fontWeight: "bolder" }}>Customize Your Lease</p>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
        <p style={{ fontSize: 17 }}>
          <Link to="/manager/home"> DASHBOARD </Link> / <Link to={``}> TENANT APPLICATION </Link> / SETUP LEASE AGREEMENT
        </p>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3 className="subHeader">Lease Specifics</h3>
          <h4 className="subHeader">Term</h4>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10, alignItems: 'center' }}>
            <FormControlLabel
              control={<Radio />}
              label="month to month"
              onChange={() => { handleMonthYearRadio("month-to-month"); }}
              checked={monthYearRadio === "month-to-month" ? true : false}
            />
            <FormControlLabel
              control={<Radio />}
              label="year to year"
              onChange={() => { handleMonthYearRadio("year-to-year"); }}
              checked={monthYearRadio === "year-to-year" ? true : false}
            />
          </div>
          <h4 className="subHeader">Lease Begin Date</h4>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["day", "month", "year"]}
                label="Lease Begin Date"
                value={beginDate}
                onChange={
                  handleBeginDateChange}
                renderInput={(params) => <TextField {...params} error={error.beginDate} required={true} />} />
            </LocalizationProvider>
            <p>
              <i>at 09:00 AM</i>
            </p>
          </div>
          <h4 className="subHeader">Does Lease have an Expiry Date?</h4>
          <RadioGroup row>
            <FormControlLabel
              control={<Radio color="success" />}
              label="No, Lease Renews Month to Month"
              value={havexpiryDate}
              checked={havexpiryDate === true}
              onChange={() => {
                setHavexpiryDate(!havexpiryDate);
              }}
            />
            <FormControlLabel
              value={havexpiryDate}
              control={<Radio color="success" />}
              label="Yes, Lease has an Expiry Date"
              checked={havexpiryDate === false}
              onChange={() => {
                setHavexpiryDate(!havexpiryDate);
              }}
            />
          </RadioGroup>
          {!havexpiryDate ?
            <div style={{ display: "flex", alignItems: "center", gap: 10, padding: '20px 0px 40px 0px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["day", "month", "year"]}
                  label="Lease End Date"
                  value={expiryDate}
                  onChange={(newValue) => {
                    setexpiryDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} error={error.expiryDate} required={true} />} />
              </LocalizationProvider>
              <p>
                <i>noon</i>
              </p>
            </div>
            : ""}
          <h4 className="subHeader">When do Tenants Move in?</h4>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["day", "month", "year"]}
                label="Move In Date"
                value={moveInDate}
                onChange={(newValue) => {
                  setmoveInDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} error={error.moveInDate} />} required={true} fullWidth={true} />
            </LocalizationProvider>
            <p>
              <i>at 09:00 AM</i>
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3 className="subHeader">Rent Details</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h4 className="subHeader">Rent</h4>
              <FormControl fullWidth={true}>
                <TextField
                  required={true}
                  error={error.rentAmount}
                  type="number"
                  label="Rent Amount"
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  onChange={(e) => {
                    setrentAmount(e.target.value);
                  }}
                  value={rentAmount}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={9}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  <h3 className="subHeader">Adults</h3>
                  {renderAdults()}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  <h3 className="subHeader">Minors</h3>
                  {renderMinors()}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  <h3 className="subHeader">Pets</h3>
                  {renderPets()}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  <h3 className="subHeader">Automobiles</h3>
                  {renderAutomobiles()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            label="Rent Explanation"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            multiline
            rows={3}
            onChange={(e) => {
              setrentAmountDetails(e.target.value);
            }}
            value={rentAmountDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p style={{ fontSize: 17, margin: '20px 0px 20px 0px' }}>Rent is due in advance by the 1st day of each month</p>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl margin="dense" fullWidth={true} required={true}>
                <InputLabel>Late Payment Fees</InputLabel>
                <OutlinedInput
                  error={error.rentLateFeesAmount}
                  label="Late Payment Fees"
                  variant="outlined"
                  type="number"
                  fullWidth={true}
                  inputprops={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    setrentLateFeesAmount(e.target.value);
                  }}
                  value={rentLateFeesAmount}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl margin="dense" fullWidth required={true}>
                <TextField
                  error={error.rentLateFeesDuration}
                  label="Late Fees Time Period"
                  select
                  variant="outlined"
                  value={rentLateFeesDuration}
                  onChange={handlePeriod}
                  fullWidth={true}
                >
                  {timePeriod.map((period) => (
                    <MenuItem key={period.value} value={period.value}>
                      {period.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl margin="dense" fullWidth required={true}>
                <InputLabel>Grace Period Ends on Day</InputLabel>
                <OutlinedInput
                  error={error.rentLateFeesGraceDuration}
                  label="Grace Period Ends on Day"
                  variant="outlined"
                  type="number"
                  inputProps={{
                    min: "1",
                    max: "27"
                  }}
                  fullWidth={true}
                  endAdornment={
                    <InputAdornment position="end">
                      of the month
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    setrentLateFeesGraceDuration(e.target.value);
                  }}
                  value={rentLateFeesGraceDuration}
                />
              </FormControl>
            </Grid>
          </Grid>
          <p style={{ fontSize: 17, margin: '20px 0px 20px 0px' }}>Late Fees will be charged from day {Number(rentLateFeesGraceDuration) + 1} of the month</p>
          <h4 className="subHeader">Security Deposit</h4>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl margin="dense" fullWidth required={true}>
                <InputLabel>Security Deposit Amount</InputLabel>
                <OutlinedInput
                  error={error.securityDepositAmount}
                  label="Security Deposit Amount"
                  variant="outlined"
                  type="number"
                  fullWidth={true}
                  onChange={(e) => {
                    setsecurityDepositAmount(e.target.value);
                  }}
                  value={securityDepositAmount}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="Security Deposit Explanation"
                  variant="outlined"
                  fullWidth={true}
                  multiline
                  rows={3}
                  onChange={(e) => {
                    setsecurityDepositAmountDetails(e.target.value);
                  }}
                  value={securityDepositAmountDetails}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h4 className="subHeader">Tenant should pay the Securty Deposit</h4>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Prior to moving in"
            name="radio-buttons-group"
          >
            <FormControlLabel
              checked={securityDepositPaymentDeadline === "prior to moving in"}
              control={<Radio />}
              label="Prior to moving in"
              onChange={() => {
                setsecurityDepositPaymentDeadline("prior to moving in");
              }}
            />
            <FormControlLabel
              checked={securityDepositPaymentDeadline.includes(`within`)}
              control={<Radio />}
              label="no later than"
              onChange={() => {
                setsecurityDepositPaymentDeadline(`within ${tenantSecurity} days after the lease starts`);
              }}
            />
          </RadioGroup>
          {securityDepositPaymentDeadline.includes(`within`) ?
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl margin="dense" fullWidth={true} required={true}>
                  <InputLabel>Enter number of days</InputLabel>
                  <OutlinedInput
                    error={error.tenantSecurity}
                    label="Enter number of days"
                    variant="outlined"
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">
                        days after lease start
                      </InputAdornment>
                    }
                    value={tenantSecurity}
                    onChange={(e) => {
                      setTenantSecurity(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid> : ""}

          <h3 className="subHeader">Tenancy Rules</h3>
          <h4 className="subHeader">Is Smoking allowed ? <SmokingRoomsIcon /></h4>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Smoking Prohibited"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="Smoking Prohibited"
              control={<Radio />}
              label="Smoking Prohibited"
              checked={smokingAllowed === false}
              onChange={() => {
                setsmokingAllowed(!smokingAllowed);
              }}
            />
            <FormControlLabel
              value="Smoking Allowed"
              control={<Radio />}
              label="Smoking Allowed"
              checked={smokingAllowed === true}
              onChange={() => {
                setsmokingAllowed(!smokingAllowed);
              }}
            />
          </RadioGroup>
          {!smokingAllowed ? "" :
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  error={error.smokingAllowedAreaDetails}
                  label="Smoking Allowed Areas"
                  margin="dense"
                  fullWidth={true}
                  variant="outlined"
                  onChange={(e) => {
                    setsmokingAllowedAreaDetails(e.target.value);
                  }}
                  value={smokingAllowedAreaDetails}
                />
              </Grid>
            </Grid>}
          <h4 className="subHeader">Are Pets allowed ? <PetsIcon /></h4>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={false}
            name="radio-buttons-group"
          >
            <FormControlLabel
              checked={petsAllowed === false}
              onChange={() => {
                setpetsAllowed(!petsAllowed);
              }}
              control={<Radio />}
              label="Pets Prohibited"
            />
            <FormControlLabel
              checked={petsAllowed === true}
              onChange={() => {
                setpetsAllowed(!petsAllowed);
              }}
              control={<Radio />}
              label="Pets Allowed"
            />
          </RadioGroup>
          {petsAllowed ?
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Pets must be neutered"
                  checked={petsMustBeNeutered}
                  onChange={() => { setpetsMustBeNeutered(!petsMustBeNeutered) }}
                />
                <Typography variant="body1">
                  When Servicing unit, pets must be
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  select
                  margin="dense"
                  variant="outlined"
                  value={petControlWhileServicing}
                  onChange={(e) => {
                    setpetControlWhileServicing(e.target.value);
                  }}
                  fullWidth={true}
                >
                  {petsservice.map((pet) => (
                    <MenuItem key={pet.value} value={pet.value}>
                      {pet.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            : ""}
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h4 className="subHeader">Noise Control <CampaignIcon /></h4>
              <p style={{ margin: '20px 0px 0px 0px' }}>Quiet Hours Starts at</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl fullWidth={true} margin="dense">
                <TextField
                  select
                  variant="outlined"
                  value={quietHoursBegin}
                  onChange={(e) => {
                    setquietHoursBegin(e.target.value);
                  }}
                >
                  {noise.map((hours, index) => (
                    <MenuItem key={hours.value} value={hours.value}>
                      {hours.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <p style={{ margin: '20px 0px 0px 0px' }}>Quiet Hours Ends at</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl fullWidth={true} margin="dense">
                <TextField
                  select
                  variant="outlined"
                  value={quietHoursEnd}
                  onChange={(e) => {
                    setquietHoursEnd(e.target.value);
                  }}
                >
                  {noise.map((hours, index) => (
                    <MenuItem key={hours.value} value={hours.value}>
                      {hours.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <h4 className="subHeader">Grilling <OutdoorGrillIcon /></h4>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Grilling Prohibited"
            name="radio-buttons-group"
          >
            <FormControlLabel
              checked={grillingAllowed === false}
              onChange={() => {
                setGrillingAllowed(!grillingAllowed);
              }}
              control={<Radio />}
              label="Grilling Prohibited"
            />
            <FormControlLabel
              checked={grillingAllowed === true}
              onChange={() => {
                setGrillingAllowed(!grillingAllowed);
              }}
              control={<Radio />}
              label="Grilling Allowed"
            />
          </RadioGroup>
          {grillingAllowed ?
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  error={error.grillingAllowedAreaDetails}
                  label="Designated Grilling Area"
                  required={true}
                  fullWidth={true}
                  margin="dense"
                  variant="outlined"
                  value={grillingAllowedAreaDetails}
                  onChange={(e) => {
                    setgrillingAllowedAreaDetails(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            : null}
          <h4 className="subHeader">Guests <PeopleAltIcon /></h4>
          <p style={{ margin: '0px 0px 20px 0px' }}>Guests of tenants can stay up to</p>
          <Grid container>
            <Grid item xs={12} sm={12} lg={3} md={6}>
              <TextField
                error={error.guestStayAllowedNumberOfDays}
                label="Days"
                required={true}
                fullWidth={true}
                margin="dense"
                variant="outlined"
                type="number"
                value={guestStayAllowedNumberOfDays}
                onChange={(e) => {
                  setguestStayAllowedNumberOfDays(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} lg={3} md={6}>
              <TextField
                error={error.guestStayAllowedWithinDuration}
                label="in"
                required={true}
                fullWidth={true}
                select
                margin="dense"
                variant="outlined"
                value={guestStayAllowedWithinDuration}
                onChange={(e) => {
                  setguestStayAllowedWithinDuration(e.target.value);
                }}
              >
                {days.map((days) => (
                  <MenuItem key={days.value} value={days.value}>
                    {days.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h4 className="subHeader">Maintenance <EngineeringIcon /></h4>
          <p style={{ margin: '0px 0px 20px 0px' }}>Landscaping taken care of by:</p>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Tenant"
            name="radio-buttons-group"
          >
            <FormControlLabel
              checked={landscapingResponsibility === "Tenant"}
              onChange={() => {
                setlandscapingResponsibility("Tenant");
              }}
              control={<Radio />}
              label="Tenant"
            />
            <FormControlLabel
              checked={landscapingResponsibility === "Manager"}
              onChange={() => {
                setlandscapingResponsibility("Manager");
              }}
              control={<Radio />}
              label="Owner/Property Manager"
            />
          </RadioGroup>
          <h4 className="subHeader">Lock Change Fees <KeyIcon /></h4>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={3} md={6}>
              <FormControl margin="dense" fullWidth={true} required={true}>
                <InputLabel>
                  Door Lock Change Fees (Re-Key Premises)
                </InputLabel>
                <OutlinedInput
                  error={error.doorLockChangeFees}
                  label="Door Lock Change Fees (Re-Key Premises)"
                  variant="outlined"
                  type="number"
                  fullWidth={true}
                  value={doorLockChangeFees}
                  onChange={(e) => {
                    setdoorLockChangeFees(e.target.value);
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={3} md={6}>
              <FormControl margin="dense" fullWidth={true} required={true}>
                <InputLabel>
                  Garage Door Opener Change Fee Per Opener
                </InputLabel>
                <OutlinedInput
                  error={error.garageDoorLockChangeFees}
                  label="Garage Door Opener Change Fee Per Opener"
                  margin="dense"
                  fullWidth={true}
                  variant="outlined"
                  type="number"
                  value={garageDoorLockChangeFees}
                  onChange={(e) => {
                    setgarageDoorLockChangeFees(e.target.value);
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          {property?.address?.state == "California" ?
            <h3 className="subHeader">Contamination</h3>
            : ""}
          {property?.address?.state == "California" ?
            <h4 className="subHeader">Asbestos</h4>
            : ""}
          {property?.address?.state == "California" ?
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Known to contain Asbestos"
              name="radio-buttons-group"
            >
              <FormControlLabel
                checked={asbestosContaminated === true}
                onChange={() => {
                  setasbestosContaminated(!asbestosContaminated);
                }}
                control={<Radio />}
                label="Known to contain Asbestos"
              />
              <FormControlLabel
                checked={asbestosContaminated === false}
                onChange={() => {
                  setasbestosContaminated(!asbestosContaminated);
                }}
                control={<Radio />}
                label="No knowledge of Asbestos"
              />
            </RadioGroup>
            : ""}
          {property?.address?.state == "California" ?
            <div>
              {asbestosContaminated ? <FormControlLabel
                checked={asbestosContaminationRecordsAvailable}
                onChange={() => { setAsbestosContaminationRecordsAvailable(!asbestosContaminationRecordsAvailable) }}
                control={<Checkbox />}
                label="Asbestos Contamination Records can be provided"
              /> : ""}
            </div>
            : ""}
          {property?.address?.state == "California" ?
            <h4 className="subHeader">Lead</h4>
            : ""}
          {property?.address?.state == "California" ?
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Known to contain Lead"
              name="radio-buttons-group"
            >
              <FormControlLabel
                checked={leadContaminated === true}
                onChange={() => {
                  setleadContaminated(!leadContaminated);
                }}
                control={<Radio />}
                label="Known to contain Lead"
              />
              <FormControlLabel
                checked={leadContaminated === false}
                onChange={() => {
                  setleadContaminated(!leadContaminated);
                }}
                control={<Radio />}
                label="No knowledge of Lead"
              />
            </RadioGroup>
            : ""}
          {property?.address?.state == "California" ?
            <div>
              {leadContaminated ? <FormControlLabel
                checked={leadContaminationRecordsAvailable}
                onChange={() => { setleadContaminationRecordsAvailable(!leadContaminationRecordsAvailable) }}
                control={<Checkbox />}
                label="Lead Contamination Records can be provided"
              /> : ""}
            </div>
            : ""}
          <h3 className="subHeader">Utilities <PropaneTankIcon /></h3>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={8} sm={8} lg={4} md={4}>
              <TextField
                label="Add Utility"
                margin="dense"
                variant="outlined"
                fullWidth={true}
                value={addUtility}
                onChange={e => { setAddUtility(e.target.value) }}
              />
            </Grid>
            <Grid item xs={4} sm={4} lg={8} md={8}>
              <Button variant="text" sx={{ fontSize: 15 }} onClick={(e) => handleAddutilityClick(e, addUtility)}>Add utility</Button>
            </Grid>
          </Grid>
          <h4 className="subHeader">Utilities INCLUDED in Rent</h4>
          {utilitiesIncludedInRentCost.map((utility, i) => (<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <FormControlLabel control={<Checkbox />} checked={utility.checked} label={utility.text} key={i}
              onChange={(e) => addUtilitychangeClick(e, i, utility.checked)} />
          </div>))}
          <h4 className="subHeader">Utilities which MUST remain connected or subscribed by Tenant{" "}</h4>
          {utilitiesMandatoryDuringLease.map((utility, i) => (<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <FormControlLabel control={<Checkbox />} checked={utility.checked} label={utility.text} key={i}
              onChange={(e) => addUtilitymustchangeClick(e, i, utility.checked)} />
          </div>))}
          <h3 className="subHeader">Renters Insurance <ThumbUpIcon /></h3>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            <FormControlLabel
              checked={renterInsurance === "optional" && true}
              onChange={() => {
                setRenterInsurance("optional");
              }}
              control={<Radio />}
              label="Resident is encouraged but not required to obtain renters insurance."
            />
            <FormControlLabel
              checked={renterInsurance === "required" && true}
              onChange={() => {
                setRenterInsurance("required");
              }}
              control={<Radio />}
              label="Resident is required to maintain renters insurance throughout the duration of the tenancy.
                Resident must provide proof of insurance to the Owner/Agent within 30 days of the start of the tenancy."
            />
          </RadioGroup>
          {renterInsurance === "required" ? <div>
            <h4 className="subHeader">
              Require resident to carry renter's insurance to cover damages from:
            </h4>
            <RadioGroup row>
              {tenantInsuranceCoverageItems.map((insurance, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox />}
                  checked={insurance.checked}
                  label={insurance.text}
                  onChange={(e) => tenantInsuranceCover(e, i, insurance.checked)}
                />
              ))}
            </RadioGroup>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item xs={8} sm={8} lg={4} md={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Add Item"
                  type="text"
                  fullWidth={true}
                  value={tenantInsurance}
                  onChange={e => { settenantInsurance(e.target.value) }}
                />
              </Grid>
              <Grid item xs={4} sm={4} lg={8} md={8}>
                <Button variant="text" sx={{ fontSize: 15 }} onClick={(e) => handleaddInsuranceCover(e, tenantInsurance)}>Add Insurance Coverage Item</Button>
              </Grid>
            </Grid>
          </div> : ""}
          <h3 className="subHeader">Attorney Fees <GavelIcon /></h3>
          <AttorneyFees />
          {
            attorneyFeesCompensationRules === "prevailing-party-with-limit" ?
              <Grid container>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <FormControl margin="dense" fullWidth={true}>
                    <InputLabel>Max Compensation Amount</InputLabel>
                    <OutlinedInput
                      label="Max Compensation Amount"
                      variant="outlined"
                      type="number"
                      fullWidth={true}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      onChange={(e) => {
                        setattorneyFeesMaxCompensationAmount(e.target.value);
                      }}
                      value={attorneyFeesMaxCompensationAmount}
                    />
                  </FormControl>
                </Grid>
              </Grid> : ""
          }
          <h3 className="subHeader">Payment</h3>
          <h4 className="subHeader">Acceptable Payment Methods</h4>
          <Grid container spacing={2}>
            {paymentMethods.map((payments, index) => (
              <Grid item xs={12} md={3} className="customCard" sx={{ borderRadius: '5px' }} key={index}>
                <CardContent sx={{ px: 0, pb: 0 }}>
                  <TextField
                    sx={{
                      width: 1, '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                        fontSize: 23,
                        fontWeight: 500,
                      }
                    }}
                    name="name"
                    size="small"
                    placeholder="Name"
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    value={payments.name}
                    onChange={e => handlepaymentChange(e, index)}
                  />
                </CardContent>
                <CardContent sx={{ px: 0, pb: 0 }}>
                  <textarea
                    name="description"
                    placeholder="Description"
                    style={{ width: "100%", borderRadius: "4px", height: '65px' }}
                    value={payments.description}
                    onChange={e => handlepaymentChange(e, index)}
                  >
                  </textarea>
                </CardContent>
                <CardActions sx={{ px: 0, float: 'right' }}>
                  <Button variant="text" sx={{ fontSize: 15 }} color="error" startIcon={<DeleteIcon />}
                    onClick={() => { handleRemovepaymentClick(index) }}>
                    REMOVE
                  </Button>
                </CardActions>
              </Grid>
            ))}
            <Grid item xs={12} md={3} className="customCard">
              <CardContent sx={{ px: 0, pb: 0 }}>
                <TextField
                  sx={{
                    width: 1, '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontSize: 23,
                      fontWeight: 500,
                    }
                  }}
                  inputprops={{ style: { fontSize: 20 } }}
                  size="small"
                  placeholder="Name"
                  variant="outlined"
                  fullWidth={true}
                  type="text"
                  value={paymentName}
                  onChange={e => { setpaymentName(e.target.value) }}
                ></TextField>
              </CardContent>
              <CardContent sx={{ px: 0, pb: 0 }}>
                <textarea
                  value={paymentDescription}
                  placeholder="Description"
                  style={{ width: "100%", borderRadius: "4px", height: '65px' }}
                  onChange={e => { setpaymentDescription(e.target.value) }}
                >
                </textarea>
              </CardContent>
              <CardActions sx={{ px: 0, float: 'right' }}>
                <Button variant="text" sx={{ fontSize: 15 }} color="success" startIcon={<AddIcon />}
                  onClick={handleAddPaymentClick}>
                  Add
                </Button>
              </CardActions>
            </Grid>
          </Grid>
          <h3 className="subHeader">Check Bounce Fees</h3>
          <Grid container>
            <Grid item xs={12} sm={12} lg={3} md={6}>
              <FormControl margin="dense" fullWidth={true} required={true}>
                <InputLabel>Fee for First Check Bounce</InputLabel>
                <OutlinedInput
                  error={error.paymentCheckFirstBounceFee}
                  label="Fee for First Check Bounce"
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                  type="number"
                  onChange={(e) => {
                    setpaymentCheckFirstBounceFee(e.target.value);
                  }}
                  value={paymentCheckFirstBounceFee}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} lg={3} md={6}>
              <FormControl margin="dense" fullWidth={true} required={true}>
                <InputLabel>Fee for Subsequent Check Bounce</InputLabel>
                <OutlinedInput
                  error={error.paymentCheckBounceFee}
                  label="Fee for Subsequent Check Bounce"
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                  type="number"
                  onChange={(e) => {
                    setpaymentCheckBounceFee(e.target.value);
                  }}
                  value={paymentCheckBounceFee}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <h3 className="subHeader">Payment Submission Schedule</h3>
          <Grid container>
            <Grid item xs={12} sm={12} lg={3} md={6}>
              <TextField
                error={error.paymentBeginTime}
                label="Start Time:"
                required={true}
                fullWidth={true}
                select
                margin="dense"
                variant="outlined"
                value={paymentBeginTime}
                onChange={(e) => {
                  setpaymentBeginTime(e.target.value);
                }}
              >
                {hours.map((hours, index) => (
                  <MenuItem key={hours.value} value={hours.value}>
                    {hours.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} lg={3} md={6}>
              <TextField
                error={error.paymentEndTime}
                label="End Time:"
                required={true}
                fullWidth={true}
                select
                margin="dense"
                variant="outlined"
                value={paymentEndTime}
                onChange={(e) => {
                  setpaymentEndTime(e.target.value);
                }}
              >
                {hours.map((hours, index) => (
                  <MenuItem key={hours.value} value={hours.value}>
                    {hours.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {/* <h3 className="subHeader">On the following days:</h3>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            {weekdayss.map((r, t) => (
              <FormControlLabel control={<Checkbox />} label={r.text} key={t}
                checked={r.checked}
                onChange={(e) => onchangeWeek(e, t, r.checked)} />
            ))}
          </div> */}
          <h3 className="subHeader">Addenda</h3>
          {
            addenda.map((addendum, index) => (
              <div>
                <TextField
                  name="title"
                  variant="outlined"
                  margin="dense"
                  required
                  type="text"
                  fullWidth={true}
                  label="Enter New Addendum Title"
                  value={addendum.title}
                  onChange={e => handleaddendaChange(e, index)}
                />
                <TextField
                  name="content"
                  label="Enter New Addendum Content"
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                  multiline
                  rows={5}
                  value={addendum.content}
                  onChange={e => handleaddendaChange(e, index)}
                />
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
                  <Button variant="contained" sx={{ fontSize: 15 }} onClick={() => { removeAddendumclick(index) }} color="error">Delete</Button>
                </div>
              </div>
            ))
          }
          <TextField
            name="title"
            variant="outlined"
            margin="dense"
            required
            type="text"
            fullWidth={true}
            value={addendumtitle}
            label="Enter New Addendum Title"
            onChange={e => { setAddendumtitle(e.target.value) }}
          />
          <TextField
            name="content"
            label="Enter New Addendum Content"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            multiline
            value={addendumcontent}
            rows={5}
            onChange={e => { setAddendumcontent(e.target.value) }}
          />
          <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
            <Button variant="contained" sx={{ fontSize: 15 }} onClick={handleAddAddendumClick} color="primary">ADD ADDENDUM</Button>
          </div>
          <h3 className="subHeader">Attachments</h3>
          <p style={{ fontSize: 17 }}>These are additional documents that need to be added for legal
            reasons. These include: RENT CONTROL, FAIR HOUSING RULES, ETC.
            Please visit your local government housing website or office for
            more info.</p>
          {
            attachment.map((r, t) => (
              <table key={t}>
                <tbody >
                  <tr>
                    <td style={{ paddingRight: "30px", paddingBottom: '15px' }}>
                      <label style={{ fontSize: '1rem' }}>
                        {r.fileName}
                      </label>
                    </td>
                    <td style={{ paddingRight: "15px", paddingBottom: '15px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ fontSize: 15 }}
                        onClick={(e) => attachmentView(e, r.id)}
                      >
                        VIEW
                      </Button>
                    </td>
                    <td style={{ paddingRight: "15px", paddingBottom: '15px' }}>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{ fontSize: 15 }}
                        onClick={(e) => attachmentremoveClick(e, r.id)}
                      >
                        REMOVE
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
          }
          <input
            ref={fileRef}
            type="file"
            name="fileOut"
            id="upAtt"
            className="inputfile"
            accept="application/pdf"
            onChange={(e) => attachmentClick(e, "add", null)}
            hidden
          />
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ fontSize: 15 }}
              onClick={() => fileRef.current.click()}
            >
              ADD ATTACHMENT
            </Button>
          </div>
          <h3 className="subHeader"> Signee <EditIcon /></h3>
          <p style={{ fontSize: 17 }}>Select the Tenants who are required to sign the Lease Agreement</p>
          {renderTenantNames()}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: 20, flexWrap: 'wrap', marginTop: 20 }}>
            <Button variant="contained" color="success" sx={{ fontSize: 15 }} onClick={handleSubmit}>Save</Button>
            <Button variant="contained" sx={{ fontSize: 15 }} onClick={handleSubmitPreview}>Preview lease Application</Button>
          </div>
        </Grid >
      </Grid >
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div >
  );
};

export default CreateLease;