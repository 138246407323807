import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, Grid, Modal, TextField, Typography, DialogTitle, FormControl, Dialog, DialogContent, InputAdornment, Icon, MenuItem, Fab, CircularProgress, TableContainer, Paper, TableRow, TableBody, Table, TableHead, TableCell, DialogActions } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { managerLeasesOverview, getPropertyFinal, getPropertyLeases, setEndLease, getSingleProperty, getDocumentAndNotice, patchDocumentAndNotice, deleteDocumentAndNotice, postuserEmailStatus, putLeaseTenants, PostLeaseTenantsSendInvitaionEmail, patchLeaseRentDetails, PostLeaseTenantsSendUpdateEmail, getApiBaseURL, postRequestTenantMoveInInspection, getManagerLeaseApplication } from "../../../../services";
import { Link, useParams, useNavigate } from "react-router-dom";
import Moment from "moment";
import { extendMoment } from "moment-range";
import TabMenu from "../edit/tabMenu";
import "react-datepicker/dist/react-datepicker.css";
import { TextArea } from "semantic-ui-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const timePeriod = [
  {
    value: "86400",
    label: "Per Day",
  },
  {
    value: "604800",
    label: "Per Week",
  },
  {
    value: "2592000",
    label: "Per Month",
  },
];

const hasMoveInInitiated = [2, 3, 4, 5, 6, 7];
const hasMoveInCompleted = [7];
const hasTenantSubmitted = [4, 5, 6, 7];
const canManagerSubmit = [4, 5];
const hasMoveOutInitiated = [2, 3, 4, 5];
const hasManagerSubmitted = [4, 5];
const hasMoveInMovedIn = [3, 4, 5, 6, 7];

function LeaseOverview() {
  const navigate = useNavigate();
  const params = useParams();
  const moment = extendMoment(Moment);
  const date1 = new Date();
  const ref = useRef();

  const [property, setProperty] = useState({});
  const [leaseDetails, setleaseDetails] = useState(null);
  const [leaseOverview, setleaseOverview] = useState(null);

  // End Tenancy
  const [moveOutDate, setmoveOutDate] = useState(null);
  const [earliestMoveOutDate, setEarliestMoveOutDate] = useState(null);
  const [endRemarks, setendRemarks] = useState("");

  const [recordDoc, setRecordDoc] = useState(false);
  const [docDeleteAlert, setDocDeleteAlert] = useState(false);

  const [documents, setDocument] = useState([]);

  const [rentDetail, setRentDetail] = useState(false);
  const [currentRent, setCurrentRent] = useState(0);
  const [latePaymentFees, setLatePaymentFees] = useState(0);
  const [lateFeesTimePeriod, setLateFeesTimePeriod] = useState("");
  const [lateFeesGraceDuration, setLateFeesGraceDuration] = useState(0);
  const [miscellaneousRent, setMiscellaneousRent] = useState(0);
  const [miscellaneousRentExplanation, setMiscellaneousRentExplanation] = useState("");

  // Verification File
  const [verificationDetails, setVerficationDetails] = useState(null);

  // loader
  const [loading, setLoading] = useState(true);

  // Dialogbox
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const handlEditIconeClick = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);
  const recordClose = () => setRecordDoc(false);

  //End Tenancy Earlier And MoveoutDate 
  const getEarlierAndMoveoutDate = (moveoutDate) => {
    let move = null;
    if (!moveoutDate) {
      move = new Date();
      move.setHours(12, 0, 0, 0);
      setmoveOutDate(move);
    } else {
      move = new Date(moveoutDate);
      setmoveOutDate(move);
    }
    let todayDate = new Date();
    let earliestMove = move && move.getTime() < todayDate.getTime() ? new Date(move) : new Date(todayDate);
    setEarliestMoveOutDate(earliestMove);
  };

  const pageLoad1 = async () => {
    try {
      setLoading(true);
      await getSingleProperty(params.propertyId).then((res) => {
        let data = res.data.data;
        setProperty(data);
      }).catch((err) => console.log(`Error${err}`));

      await managerLeasesOverview(params.propertyId).then((res) => {
        let responseData = res.data.data;
        setleaseDetails(responseData);
        if (responseData.length > 0) {
          let id = responseData[0];
          getPropertyFinal(id.id).then((res) => {
            let responseData = res?.data?.data;
            setleaseOverview(responseData);
            if (responseData?.originLeaseApplication) {
              getManagerLeaseApplication(responseData?.originLeaseApplication).then((response) => {
                setVerficationDetails(response?.data?.data);
              })
            } else {
              setVerficationDetails(null);
            }
            if (responseData?.moveOutDetails?.moveOutDate) {
              getEarlierAndMoveoutDate(responseData?.moveOutDetails?.moveOutDate);
            }
          }).catch((err) => console.log(`Error${err}`));
        }
      })
    } finally {
      setLoading(false);
    }
  }

  const selectClick = async (e, id) => {
    await getPropertyFinal(id).then((res) => {
      let responseData = res?.data?.data;
      setleaseOverview(responseData);
      if (responseData?.originLeaseApplication) {
        getManagerLeaseApplication(responseData?.originLeaseApplication).then((response) => {
          setVerficationDetails(response?.data?.data);
        })
      } else {
        setVerficationDetails(null);
      }
      if (responseData?.moveOutDetails?.moveOutDate) {
        getEarlierAndMoveoutDate(responseData?.moveOutDetails?.moveOutDate);
      }
    }).catch((err) => console.log(`Error${err}`));
  }

  useEffect(() => {
    pageLoad1();
  }, []);

  // Verification Files View
  const paystubClick = async (id, leaseID, url) => {
    var config = "";
    const authToken = sessionStorage.getItem("authToken");
    if (authToken != undefined) {
      config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
    }
    try {
      fetch(
        `${getApiBaseURL()}/manager/lease-applications/${leaseID}/applicants/${id}/verification-files/${url}`,
        config
      )
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(blob);
          reader.onload = function () {
            var imageDataUrl = reader.result;
            const link = document.createElement("a");
            link.href = imageDataUrl;
            if (
              blob.type == "image/png" ||
              blob.type == "image/gif" ||
              blob.type == "image/jpeg" ||
              blob.type == "image/svg" ||
              blob.type == "image/jpg"
            ) {
              const newTab = window.open();
              newTab?.document.write(
                `<!DOCTYPE html><head><title>Document preview</title></head><body style="margin: auto; background: #0e0e0e; height: 100%;"><img align="center"  src="${imageDataUrl}"  ></body></html>`
              );

              newTab?.document.close();
            }
            if (blob.type == "application/pdf") {
              link.setAttribute("download", "paystub.pdf");
              document.body.appendChild(link);
              link.click();
            }
          };
        })
        .catch((err) => console.log(`Error${err}`));
    } catch (err) {
      console.log(err)
    }
  };

  // Document and Notice
  const pageLoad2 = (id) => {
    setLoading(true);
    getDocumentAndNotice(id)
      .then((res) => {
        let data = res?.data?.data;
        setDocument(data);
      }).finally(() => {
        setLoading(false);
      });
  }

  const documentAndRecordsClick = (id) => {
    setRecordDoc(true);
    pageLoad2(id);
  }

  var config = "";
  const FileDownload = (e, url, fileName) => {
    const authToken = sessionStorage.getItem("authToken");
    config = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    fetch(`${getApiBaseURL()}/manager/${url}`, config)
      .then((res) => {
        return res.blob();
      }).then((blob) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          const link = document.createElement("a");
          link.href = imageDataUrl;
          if (blob.type == "image/png"
            || blob.type == "image/gif"
            || blob.type == "image/jpeg"
            || blob.type == "image/svg"
            || blob.type == "image/jpg"
          ) {
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
          if (blob.type == "application/pdf") {
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        }
      });
  }

  // Document Delete By Id
  const [docId, setDocId] = useState("")
  const DocDelDialogOpen = (e,id) => {
    setDocDeleteAlert(true);
    setDocId(id);
  }
  const DocDelDialogClose = () => {
    setDocDeleteAlert(false);
    setDocId("");
  }
  const DocRecordClick = (e, docId, id) => {
    deleteDocumentAndNotice(id, docId)
      .then((res) => {
        DocDelDialogClose();
        setRecordDoc(false);
      }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  // Document Edit By Id
  const [docEdit, setdocEdit] = useState(false);
  const [desc, setDesc] = useState("");
  const [date, setDate] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const fileChange = (e) => {
    var file = e.target.files[0] || undefined,
      supportedFormats = [
        "image/jpg",
        "image/gif",
        "image/png",
        "image/jpeg",
        "application/pdf",
      ];
    if (file && file.type) {
      if (0 > supportedFormats.indexOf(file.type)) {
        ref.current.value = ""
        setSelectedFile(null);
      } else {
        setSelectedFile(e.target.files[0]);
      }
    }
  }

  const editClickDialogOpen = (id) => {
    setdocEdit(true);
    setDocId(id);
  }

  const editClickDialogClose = () => {
    setdocEdit(false);
    setDocId("");
  }

  const EditDoc = (e, id) => {
    let formData = new FormData();
    if (selectedFile) {
      formData.append("LeasePdf", selectedFile)
    }
    if (desc) {
      formData.append("description", desc)
    }
    if (date) {
      formData.append("date", date)
    }
    patchDocumentAndNotice(id, docId, formData)
      .then((res) => {
        setRecordDoc(false);
        editClickDialogClose();
      }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  // End Tenancy

  const EndLease = (id) => {
    let endDate = new Date();
    let data = {
      moveOutDate: moveOutDate,
      endDate: endDate,
      endRemarks: endRemarks,
    };
    setEndLease(id, data).then((res) => {
      setOpen1(false);
      pageLoad1();
    });
  };

  //Email Update
  const [updateEmailcondition, setUpdateEmailCondition] = useState(false);
  const [emailStatusCondition, setEmailStatusCondition] = useState(false);
  const [tenantFirstname, setTenantFirstname] = useState("");
  const [tenantLastname, setTenantLastname] = useState("");
  const [tenantEmail, setTenantEmail] = useState("");
  const [tenantphone, setTenantPhone] = useState("");
  const [leaseId, setLeaseId] = useState("");
  const [tenantLeaseId, setTenantLeaseId] = useState("");

  const rentDetailsOpen = (rentAmount, rentLateFeesAmount, rentLateFeesDuration, rentLateFeesGraceDuration, id) => {
    setRentDetail(true);
    setCurrentRent(rentAmount);
    setLatePaymentFees(rentLateFeesAmount);
    setLateFeesTimePeriod(rentLateFeesDuration);
    setLateFeesGraceDuration(parseInt(rentLateFeesGraceDuration / 86400) + 1 || 1);
    setLeaseId(id);
  }

  const rentDetailSaveClick = () => {
    let data = {
      rentLateFeesDuration: lateFeesTimePeriod,
      rentLateFeesAmount: latePaymentFees,
      rentLateFeesGraceDuration: (parseInt(lateFeesGraceDuration) - 1) * 86400,
      rentAmount: currentRent,
    }
    patchLeaseRentDetails(leaseId, data).then((res) => {
      toast.success("Rent details updated successfully");
      pageLoad1();
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  //Email Update
  const toggle = id => {
    setUpdateEmailCondition(prevupdatesetUpdateEmailCondition => ({ [id]: !prevupdatesetUpdateEmailCondition[id] }))
  }

  const updateEmailClick = (first, last, email, phone, leaseId, leaseTenantId) => {
    setUpdateEmailCondition(true);
    setTenantFirstname(first);
    setTenantLastname(last);
    setTenantEmail(email);
    setTenantPhone(phone);
    setLeaseId(leaseTenantId);
    setTenantLeaseId(leaseId);
  }

  const cancelupdateEmailClick = () => {
    setUpdateEmailCondition(false);
  }

  const saveEmailUpdateClick = () => {
    let data = {
      emailId: tenantEmail,
    }
    postuserEmailStatus(data).then((res) => {
      let data = res.data.data;

      if (data === true) {
        setEmailStatusCondition(true);
      }
      if (data === false) {
        let data = {
          firstName: tenantFirstname,
          lastName: tenantLastname,
          emailId: tenantEmail,
          phoneCountryCode: '+1',
          phoneNumber: tenantphone
        }
        putLeaseTenants(leaseId, tenantLeaseId, data).then((response) => {
          PostLeaseTenantsSendUpdateEmail(leaseId, tenantLeaseId).then((res) => {
            pageLoad1();
            toast.success('Resend tenant update contact details link successfully. please check your inbox for email verification.')
          })
        }).catch((err) => { toast.error(err.response.data.error.message) });
      }
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  const resendActivationLink = (leaseId, leaseTenantId) => {
    PostLeaseTenantsSendUpdateEmail(leaseTenantId, leaseId).then((res) => {
      toast.success("Resend tenant activation link successfully,Please check your email for verification.")
      pageLoad1();
    }).catch((err) => { toast.error(err.response.data.error.message) });
  }

  // Request move-In Inspection
  const [moveInInspectionRequested, setMoveInInspectionRequested] = useState(null);

  const requestTenantMoveInInspection = (leaseId) => {
    postRequestTenantMoveInInspection(leaseId).then((success) => {
      setMoveInInspectionRequested(success);
      if (success) toast.success('Move In Checklist requested. Ask your Tenants to check their Email.');
    }).catch((err) => {
      let errorMessage = err.response.data.error.message;
      if (errorMessage)
        toast.error(errorMessage);
      else toast.error('Failed to request Move In Checklist. Please try again later.')
    });
  }

  // Navigations

  const btnLeaseApplicationView = async (e, leaseId) => {
    navigate(`/manager/lease-applications/${leaseId}`);
  };

  const moveOutInspectionClick = (leaseId) => {
    navigate(`/manager/leases/${leaseId}/move-out-inspection`);
  }
  const movinViewClick = (e, leaseId) => {
    navigate(`/manager/leases/${leaseId}/move-in-inspection`);
  };
  const ScheduleMoveOutClick = (e, leaseId) => {
    navigate(`/manager/leases/${leaseId}/move-out-form`);
  };
  const RenewClick = (e, leaseId) => {
    navigate(`/manager/leases/${leaseId}/renewal/prepare`);
  };
  const SendNoticeClick = (e, leaseId) => {
    navigate(`/manager/notice/prepare/${leaseId}`);
  };

  const LeaseHeading = ({ status }) => {
    let head = "";
    if (status === 1) head = "Scheduled Upcoming Lease";
    if (status === 2) head = "Lease Summary";
    if (status === 3) head = "Ended Past Lease";

    return <h3>{head}</h3>;
  };

  // Loader
  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div style={{ padding: '30px' }}>
      <p style={{ fontSize: 25, textAlign: "center", fontWeight: "bold" }}>{property?.address?.line1 + "," + property?.address?.line2 + " " + property?.address?.unitNumber}</p>
      <TabMenu />
      <div style={{ marginTop: '30px' }}>
        {leaseOverview ?
          <CardContent>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: 'center',
                flexWrap: 'wrap',
                pb: 0,
                pl: 0,
              }}
            >
              <CardContent sx={{ p: 0 }}>
                <LeaseHeading status={leaseOverview.status} />
              </CardContent>
              <CardContent style={{ padding: "0px" }}>
                <Fab color="primary" aria-label="edit">
                  <EditIcon onClick={handlEditIconeClick} />
                </Fab>
              </CardContent>
            </CardContent>
            <CardContent sx={{ p: 0 }}>
              {leaseOverview ? (
                <CardContent sx={{ p: 0 }}>
                  <p style={{ fontSize: 17, fontWeight: "bold", margintop: "5px", marginBottom: "5px" }} >
                    Lease Duration :{" "}
                    {leaseOverview && leaseOverview?.beginDate ? moment(leaseOverview?.beginDate).format("MMM DD, YYYY") : ""}
                    {leaseOverview?.expiryDate ? ' - ' + moment(leaseOverview?.expiryDate).format("MMM DD, YYYY") : ' - Till Notice'}
                  </p>
                  {leaseOverview?.moveInDetails?.moveInDate ?
                    <p style={{ fontSize: 17, fontWeight: "bold", margintop: "5px", marginBottom: "5px" }}>
                      Move In Date :{" "}
                      {moment(leaseOverview?.moveInDetails?.moveInDate).format("MMM DD, YYYY")}
                    </p>
                    : ""}
                  {leaseOverview?.expiryDate ?
                    <p style={{ color: "#ABB1B5", fontSize: 15, fontWeight: "bold", margintop: "10px", marginBottom: "10px" }}>
                      Time remaining:{" "}
                      {moment(leaseOverview?.expiryDate).diff(moment(date1), "days")}{" "}days
                    </p>
                    : ""}
                  <span className="lease-overview-current-rent-flex">
                    <p style={{ fontSize: 17, fontWeight: "bold", margintop: "5px", marginBottom: "5px" }}>
                      Current Rent : {"$" + leaseOverview?.rentAmount}
                    </p>
                    <EditIcon color="primary"
                      onClick={() => {
                        rentDetailsOpen(
                          leaseOverview?.rentAmount,
                          leaseOverview?.rentLateFeesAmount,
                          leaseOverview?.rentLateFeesDuration,
                          leaseOverview?.rentLateFeesGraceDuration,
                          leaseOverview?.id
                        )
                      }}
                      sx={{ cursor: "pointer" }} />
                  </span>
                  {leaseOverview?.rentLateFeesGraceDuration ?
                    <p style={{ color: "#ABB1B5", fontSize: 15, fontWeight: "bold", margintop: "10px", marginBottom: "10px" }}>
                      ( Late Fees : $ {leaseOverview?.rentLateFeesAmount} {leaseOverview?.rentLateFeesDuration == 86400 ? '/Day'
                        : (leaseOverview.rentLateFeesDuration == 604800) ? '/Week' : '/Month'} after {leaseOverview?.rentLateFeesGraceDuration / 86400}
                      days )
                    </p>
                    : ""}
                  {leaseOverview?.moveOutDetails?.moveOutDate ?
                    <p style={{ fontSize: 17, fontWeight: "bold", margintop: "5px", marginBottom: "5px" }}>
                      Move Out Date : {moment(leaseOverview?.moveOutDetails?.moveOutDate).format("MMM DD, YYYY")}
                    </p>
                    : ""}
                  {leaseOverview?.endDate ?
                    <p style={{ fontSize: 17, fontWeight: "bold", margintop: "5px", marginBottom: "5px" }}>
                      Lease End Date : {moment(leaseOverview?.endDate).format("MMM DD, YYYY")}
                    </p>
                    : ""}
                  {!leaseOverview?.active ?
                    <p style={{ color: "#ABB1B5", fontSize: 15, fontWeight: "bold", margintop: "10px", marginBottom: "10px" }}>
                      Lease expiry : {moment(leaseOverview?.expiryDate).format("MMM DD, YYYY")}
                    </p>
                    : ""}
                </CardContent>
              ) : null}
            </CardContent>
            <CardContent sx={{ p: 0 }}>
              <CardContent sx={{ px: 0 }}>
                <h3>Tenants</h3>
              </CardContent>
              <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", p: 0 }}>
                {leaseOverview && leaseOverview.leaseTenants
                  ? leaseOverview.leaseTenants.map((tenant, index) => (
                    <Card variant="outlined" style={{ maxWidth: "fit-content", borderRadius: "10px", margin: "10px" }} key={index}>
                      <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', p: 0, }}>
                        <CardContent sx={{ pb: 0 }}>
                          <Avatar sx={{ color: "#F2994A", backgroundColor: "antiquewhite" }} src="/broken-image.jpg" />
                        </CardContent>
                        <CardContent sx={{ pb: 0, flexGrow: 1 }}>
                          <h3 style={{ marginBottom: "0px" }}>{`${tenant.name.first} ${tenant.name.last}`}</h3>
                          {updateEmailcondition[index] ?
                            <div className="lease-overview-tenant-firstlastname-field-flex">
                              <div style={{ flexGrow: 1 }}>
                                <TextField
                                  label="First Name*"
                                  value={tenantFirstname}
                                  onChange={e => { setTenantFirstname(e.target.value) }}
                                />
                              </div>
                              <div style={{ flexGrow: 1 }}>
                                <TextField
                                  label="Last Name*"
                                  value={tenantLastname}
                                  onChange={e => { setTenantLastname(e.target.value) }}
                                />
                              </div>
                            </div> : ""}
                          <label style={{ color: "#A7ACB1", fontSize: 14 }}>
                            {tenant.verified && tenant.verified === true
                              ? "Verified"
                              : "Pending verification"}
                          </label>
                        </CardContent>
                      </CardContent>
                      <CardContent sx={{ py: 0 }}>
                        <CardContent sx={{ px: 0, pt: 1 }}>
                          <span className="lease-overview-tenant-emailandphone-flex"><span>Email:</span><span>{tenant.email.id}</span></span>
                        </CardContent>
                        <CardContent sx={{ p: 0 }}>
                          <span className="lease-overview-tenant-emailandphone-flex"><span>Phone:</span><span>{`${tenant.phone.code} ${tenant.phone.number}`}</span></span>
                        </CardContent>
                      </CardContent>
                      {updateEmailcondition[index] ?
                        <div className="lease-overview-tenant-phonenademail-field-flex">
                          <TextField
                            fullWidth={true}
                            label="Email*"
                            value={tenantEmail}
                            onChange={e => { setTenantEmail(e.target.value) }}
                          />
                          <TextField
                            fullWidth={true}
                            label="Phone*"
                            value={tenantphone}
                            onChange={e => { setTenantPhone(e.target.value) }}
                          />
                          <div className="lease-overview-tenant-button-flex">
                            <Button onClick={cancelupdateEmailClick}>CANCEL</Button>
                            <Button variant="contained" color="success" onClick={saveEmailUpdateClick}>SAVE</Button>
                          </div>
                        </div> : ""}
                      <CardContent style={{ paddingTop: 0, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                        {!updateEmailcondition[index] ?
                          <Button sx={{ color: 'black', fontSize: 14 }} onClick={() => { updateEmailClick(tenant.name.first, tenant.name.last, tenant.email.id, tenant.phone.number, tenant.id, leaseOverview && leaseOverview.id ? leaseOverview.id : null); toggle(index); }}>UPDATE EMAIL</Button>
                          : ""}
                        {tenant.verified && tenant.verified == true ?
                          "" : <Button variant="outlined" sx={{ fontSize: 14 }} onClick={() => { resendActivationLink(tenant.id, leaseOverview && leaseOverview.id ? leaseOverview.id : null) }}>RESEND ACTIVATION LINK</Button>
                        }
                      </CardContent>
                    </Card>)) : null}
              </CardContent>
            </CardContent>
            <CardContent sx={{ px: 0 }}>
              <CardContent sx={{ px: 0 }}>
                <h2>Actions</h2>
              </CardContent>
              <Grid container spacing={2}>
                {leaseOverview?.active && hasMoveInInitiated.includes(leaseOverview?.moveInDetails?.status) && !hasMoveInCompleted.includes(leaseOverview?.moveInDetails?.status) ?
                  <Grid item xs={12} md={6} sx={{ borderRadius: "5px" }}>
                    <Accordion style={{ backgroundColor: "#8CC659" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Move In</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardContent style={{ padding: "0px" }}>
                          {!hasTenantSubmitted.includes(leaseOverview?.moveInDetails?.status) ?
                            <CardContent style={{ paddingTop: "0px" }}>
                              <Button
                                variant="contained"
                                style={{ backgroundColor: "#FFFFFF", color: "black" }}
                                disabled={moveInInspectionRequested ? true : false}
                                onClick={() => { requestTenantMoveInInspection(leaseOverview?.id) }}
                              >
                                ASK MOVE IN-CHECKLIST
                              </Button>
                            </CardContent>
                            : ""}
                          <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#FFFFFF", color: "black" }}
                              onClick={(e) => movinViewClick(e, leaseOverview?.id)}>
                              {canManagerSubmit.includes(leaseOverview?.moveInDetails?.status) ? "Verify" : "View"} Move-In-Checklist
                            </Button>
                          </CardContent>
                        </CardContent>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  : ""}
                {leaseOverview?.active ?
                  <Grid item xs={12} md={6} sx={{ borderRadius: "5px" }}>
                    <Accordion style={{ backgroundColor: "#F2C94C" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Lease Maintainance</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardContent style={{ padding: "0px" }}>
                          <CardContent style={{ paddingTop: "0px" }}>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#FFFFFF", color: "black" }}
                              onClick={(e) => SendNoticeClick(e, leaseOverview?.id)}>
                              Send Notice
                            </Button>
                          </CardContent>
                          <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#FFFFFF", color: "black" }}
                              onClick={(e) => RenewClick(e, leaseOverview?.id)}>
                              {leaseOverview?.renewal?.status == 3 || false ? "View Lease Renewal" : "Renew Lease"}
                            </Button>
                          </CardContent>
                        </CardContent>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  : ""}
                {leaseOverview?.active ?
                  <Grid item xs={12} md={6} sx={{ borderRadius: "5px" }}>
                    <Accordion style={{ backgroundColor: "#F2994A" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Move Out</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardContent style={{ padding: "0px" }}>
                          <CardContent style={{ paddingTop: "0px" }}>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#FFFFFF", color: "black" }}>
                              Send 60 Day Notice for Move Out
                            </Button>
                          </CardContent>
                          <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#FFFFFF", color: "black" }}
                              onClick={(e) => ScheduleMoveOutClick(e, leaseOverview?.id)}>
                              {leaseOverview?.moveOutDetails && hasMoveOutInitiated.includes(leaseOverview?.moveOutDetails?.status) ? "Update Forwarding Address" : "Schedule Move Out"}
                            </Button>
                            {leaseOverview?.moveOutDetails && hasMoveOutInitiated.includes(leaseOverview?.moveOutDetails?.status) ?
                              <div style={{ paddingTop: "15px" }}>
                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "#FFFFFF", color: "black" }}
                                  onClick={() => { moveOutInspectionClick(leaseOverview?.id) }}>
                                  {leaseOverview?.moveInDetails && hasManagerSubmitted.includes(leaseOverview?.moveOutDetails?.status) ? "View Move Out Checklist" : "Submit Move Out Checklist"}
                                </Button>
                              </div>
                              : ""}
                          </CardContent>
                        </CardContent>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  : ""}
                {leaseOverview?.status == 2 || leaseOverview?.status == 1 ?
                  <Grid item xs={12} md={6} sx={{ borderRadius: "5px" }}>
                    <Accordion style={{ backgroundColor: "#DC4535" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>End Tenancy</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#FFFFFF", color: "black" }}
                          onClick={handleOpen1}
                        >
                          {leaseOverview?.active ? "End" : "Cancel"} Lease
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  : ""}
                <Grid item xs={12} md={6} sx={{ borderRadius: "5px" }}>
                  <Accordion style={{ backgroundColor: "#508BEB" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Records</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", p: 0 }}>
                        {leaseOverview && leaseOverview.leaseTenants
                          ? leaseOverview.leaseTenants.map((tenant, index) => (
                            <Card variant="outlined" style={{ maxWidth: "fit-content", borderRadius: "10px", margin: "10px", border: '1px solid grey' }} key={index}>
                              <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', p: 0, }}>
                                <CardContent sx={{ pb: 0 }}>
                                  <Avatar sx={{ color: "#508BEB", backgroundColor: "aliceblue" }} src="/broken-image.jpg" />
                                </CardContent>
                                <CardContent sx={{ pb: 0, flexGrow: 1 }}>
                                  <h3 style={{ marginBottom: "0px" }}>{`${tenant.name.first} ${tenant.name.last}`}</h3>
                                  <label style={{ color: "#A7ACB1", fontSize: 14 }}>
                                    {tenant.verified && tenant.verified === true
                                      ? "Verified"
                                      : "Pending verification"}
                                  </label>
                                </CardContent>
                              </CardContent>
                              <CardContent sx={{ py: 0 }}>
                                <CardContent sx={{ px: 0, pt: 1 }}>
                                  <span className="lease-overview-tenant-emailandphone-flex">
                                    <span>
                                      Email:
                                    </span>
                                    <span>
                                      {tenant.email.id}
                                    </span>
                                  </span>
                                </CardContent>
                                <CardContent sx={{ p: 0 }}>
                                  <span className="lease-overview-tenant-emailandphone-flex">
                                    <span>
                                      Phone:
                                    </span>
                                    <span>
                                      {`${tenant.phone.code} ${tenant.phone.number}`}
                                    </span>
                                  </span>
                                </CardContent>
                              </CardContent>
                            </Card>)) : null}
                      </CardContent>
                      {verificationDetails ?
                        <CardContent sx={{ pb: 0 }}>
                          <span style={{ fontWeight: 'bold', color: "white" }}>Verification Files</span>
                        </CardContent>
                        : ""}
                      {verificationDetails && verificationDetails?.applicants ? verificationDetails?.applicants.map((item, index) => (
                        <CardContent key={index}>
                          {item?.verificationDetails?.bankStatemantFile ?
                            <CardContent
                              sx={{ px: 0, pt: 0, color: 'white', cursor: 'pointer' }}
                              onClick={() => paystubClick(item?.id, verificationDetails && verificationDetails?.id ? verificationDetails?.id : null, "bank-statement")}>
                              {"Bank Statement: " + item?.verificationDetails?.bankStatemantFile?.fileName}
                            </CardContent>
                            : ""}
                          {item?.verificationDetails?.creditReportFile ?
                            <CardContent
                              sx={{ px: 0, pt: 0, color: 'white', cursor: 'pointer' }}
                              onClick={() => paystubClick(item?.id, verificationDetails && verificationDetails?.id ? verificationDetails?.id : null, "credit-report")}>
                              {"Credit Report: " + item?.verificationDetails?.creditReportFile?.fileName}
                            </CardContent>
                            : ""}
                          {item?.verificationDetails?.governmentIdFile ?
                            <CardContent
                              sx={{ px: 0, pt: 0, color: 'white', cursor: 'pointer' }}
                              onClick={() => paystubClick(item?.id, verificationDetails && verificationDetails?.id ? verificationDetails?.id : null, "government-id")}>
                              {"Government Id: " + item?.verificationDetails?.governmentIdFile?.fileName}
                            </CardContent>
                            : ""}
                        </CardContent>
                      )) : null}
                      {leaseOverview?.originLeaseApplication ?
                        <CardContent style={{ paddingTop: "0px" }}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#FFFFFF", color: "black" }}
                            onClick={(e) => btnLeaseApplicationView(e, leaseOverview?.originLeaseApplication)}
                          >
                            View Lease Application
                          </Button>
                        </CardContent>
                        : ""}
                      {hasMoveInMovedIn.includes(leaseOverview?.moveOutDetails?.status) ?
                        <CardContent style={{ paddingTop: "0px" }}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#FFFFFF", color: "black" }}
                            onClick={(e) => movinViewClick(e, leaseOverview?.id)}
                          >
                            View Move In Checklist
                          </Button>
                        </CardContent>
                        : ""}
                      <CardContent
                        style={{ paddingTop: "0px" }}
                      >
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#FFFFFF", color: "black" }}
                          onClick={() => { documentAndRecordsClick(leaseOverview?.id) }}
                        >
                          VIEW DOCUMENTS AND NOTICES
                        </Button>
                      </CardContent>
                      {leaseOverview?.moveOutDetails && hasMoveOutInitiated.includes(leaseOverview?.moveOutDetails?.status) ?
                        <CardContent
                          style={{ paddingTop: "0px" }}
                        >
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#FFFFFF", color: "black" }}
                            onClick={(e) => ScheduleMoveOutClick(e, leaseOverview?.id)}
                          >
                            Update Forwarding Address
                          </Button>
                        </CardContent>
                        : ""}
                      {leaseOverview?.moveOutDetails && hasMoveOutInitiated.includes(leaseOverview?.moveOutDetails?.status) ?
                        <CardContent
                          style={{ paddingTop: "0px" }}
                        >
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#FFFFFF", color: "black" }}
                            onClick={() => { moveOutInspectionClick(leaseOverview?.id) }}
                          >
                            View Move Out Inspection
                          </Button>
                        </CardContent>
                        : ""}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </CardContent>
          </CardContent>
          : ""}
        {!leaseOverview ?
          <CardContent>
            <CardContent sx={{ p: 0 }}>
              <h3>No Active Lease</h3>
            </CardContent>
            <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", gap: "20px" }}>
              <CardContent>
                <Button
                  sx={{ fontSize: "medium", borderRadius: 10 }}
                  variant="contained"
                  type="submit"
                  color="primary">
                  REVIEW LEASE APPLICATIONS
                </Button>
              </CardContent>
              <CardContent>
                <Button
                  sx={{ fontSize: "medium", borderRadius: 10 }}
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={() => { navigate(`/manager/properties/${params.propertyId}/lease/add`) }}
                >
                  ADD EXIXTING LEASE
                </Button>
              </CardContent>
            </CardContent>
          </CardContent>
          : ""}
        <CardContent>
          <CardContent sx={{ p: 0 }}>
            <h2>All Lease</h2>
          </CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Tenants</TableCell>
                  <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Begin</TableCell>
                  <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Expire</TableCell>
                  <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>End Date</TableCell>
                  <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaseDetails?.map((lease, index) => (
                  lease.leaseTenants.map((tenant, i) => (
                    <TableRow key={`${index}-${i}`}>
                      <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{tenant?.name?.first + " " + tenant?.name?.last}</TableCell>
                      <TableCell className='tableCell-maxWidth'>{lease?.beginDate ? moment(lease?.beginDate).format("MMMM DD, YYYY") : "-"}</TableCell>
                      <TableCell className='tableCell-maxWidth'>{lease?.expireDate ? moment(lease?.expireDate).format("MMMM DD, YYYY") : "-"}</TableCell>
                      <TableCell className='tableCell-maxWidth'>
                        {lease?.endDate ? moment(lease?.endDate).format("MMMM DD, YYYY") : "Time Remaining " + moment(lease?.expireDate).diff(moment(date1), "days")}{" "}days
                      </TableCell>
                      <TableCell className='tableCell-maxWidth'>
                        <Button onClick={(e) => selectClick(e, lease?.id)}>
                          Select Lease
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Dialog
          open={open}
          onClose={handleClose}>
          <DialogContent>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Rent Amount
            </Typography>
            <div>
              <input type="text" />
            </div>
            <button style={{ width: "4rem" }}>SAVE</button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={open1}
          onClose={handleClose1}
          fullWidth
          maxWidth="xs">
          <DialogTitle>
            <Typography variant="h6" component="h2" textAlign="center">
              Do you want to end the Lease?
            </Typography>
          </DialogTitle>
          <DialogContent>
            {leaseOverview?.status == 2 ?
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["day", "month", "year"]}
                  label="Move out Date"
                  value={moveOutDate}
                  onChange={(newValue) => {
                    setmoveOutDate(newValue);
                  }}
                  maxDate={date1}
                  minDate={earliestMoveOutDate}
                  renderInput={(params) => <TextField {...params} fullWidth={true} style={{ paddingBottom: '20px' }} />} />
              </LocalizationProvider>
              : ""}
            <TextField
              label="Remarks"
              variant="outlined"
              fullWidth={true}
              type="text"
              multiline={true}
              value={endRemarks}
              onChange={(e) => {
                setendRemarks(e.target.value);
              }} />
            <div className="lease-overview-endlease-dialog-action-text">
              You cannot undo this Action!
            </div>
            <DialogActions>
              <div style={{ display: "flex", flexWrap: 'wrap', justifyContent: "right", gap: 10 }}>
                <Button
                  sx={{ fontSize: 15 }}
                  type="submit"
                  color="error"
                  onClick={() => setOpen1(false)}>
                  Cancel
                </Button>
                <Button
                  sx={{ fontSize: 15 }}
                  variant="contained"
                  type="submit"
                  color="warning"
                  disabled={!moveOutDate || !endRemarks ? true : false}
                  onClick={() => EndLease(leaseOverview?.id)}>
                  End Lease
                </Button>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Dialog
          open={recordDoc}
          onClose={() => setRecordDoc(false)}
          fullWidth
          maxWidth="md">
          <CardContent>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div style={{ float: "right", cursor: "pointer" }}><ClearIcon onClick={recordClose} /></div>
            </div>
            <DialogTitle style={{ textAlign: "center" }}>Documents and Notices</DialogTitle>
            <CardContent style={{ paddingBottom: "0px" }}>
              <AddIcon
                onClick={() => { editClickDialogOpen(1) }}
                color="primary" sx={{ fontSize: 25, cursor: 'pointer' }} />
            </CardContent>
            <DialogContent style={{ padding: "0px" }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Description</TableCell>
                      <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Date</TableCell>
                      <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents.map((doc, index) => (
                      <TableRow key={index}>
                        <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{doc?.description}</TableCell>
                        <TableCell className='tableCell-maxWidth'>{doc?.addedOnDate ? moment(doc?.addedOnDate).format('MMMM DD, YYYY') : ""}</TableCell>
                        <TableCell className='tableCell-maxWidth'>
                          <span style={{ display: 'flex', flexDirection: 'row', gap: 10, flexWrap: 'wrap', alignItems: 'center' }}>
                            <CloudDownloadIcon color="primary" sx={{ fontSize: 25, cursor: 'pointer' }} onClick={(e) => FileDownload(e, doc?.url, doc?.fileName)} />
                            <EditIcon onClick={() => { editClickDialogOpen(doc?.id) }} color="primary" sx={{ fontSize: 25, cursor: "pointer" }} />
                            <DeleteIcon color="primary" sx={{ fontSize: 25, cursor: 'pointer' }} onClick={(e) => DocDelDialogOpen(e, doc?.id)}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </CardContent>
        </Dialog>
        <Dialog
          open={docDeleteAlert}
          onClose={DocDelDialogClose}
          maxWidth="sm">
          <Box>
            <CardContent>
              <Typography variant="h6" component="h2">
                Are you sure you want to delete this item?
              </Typography>
            </CardContent>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: 'wrap', gap: 10, padding: '20px' }}>
              <Button
                variant="contained"
                sx={{ fontSize: 15 }}
                type="submit"
                color="warning"
                onClick={(e) => DocRecordClick(e, docId, leaseOverview?.id)}>
                DELETE RECORD
              </Button>
              <Button
                sx={{ fontSize: 15 }}
                color="error"
                type="submit"
                onClick={DocDelDialogClose}>
                CANCEL
              </Button>
            </div>
          </Box>
        </Dialog>
        <Dialog
          open={docEdit}
          onClose={editClickDialogClose}>
          <Box
            sx={{ width: "300px" }}>
            <CardContent>
              <DialogTitle style={{ paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px" }}>Description</DialogTitle>
              <input type="text"
                style={{
                  height: "50px", width: "267px", border: "none", outline: "none", fontSize: 17, fontWeight: "lighter",
                }}
                value={desc}
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
              />
              <p style={{ fontSize: 17, marginBottom: "0px" }}>Lease Begin Date</p>
              <div style={{ marginBottom: "20px" }}>
                <input type="date"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  style={{
                    height: "50px", width: "267px", border: "none", outline: "none", fontSize: 17, fontWeight: "lighter",
                  }}
                />
              </div>
              <input
                type="file"
                ref={ref}
                onChange={(e) => fileChange(e)}
              />
              <div style={{ marginTop: "15px" }}>
                <button onClick={(e) => EditDoc(e, leaseOverview?.id)}>SAVE</button>
              </div>
            </CardContent>
          </Box>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={rentDetail}
          onClose={() => setRentDetail(false)}>
          <CardContent sx={{ pt: 3 }}>
            <p style={{ fontSize: "20px", fontWeight: "bolder" }}>Rent Details</p>
          </CardContent>
          <CardContent sx={{ py: 0 }}>
            <TextField
              type="number"
              label="Current Rent"
              margin="dense"
              variant="outlined"
              fullWidth={true}
              value={currentRent}
              onChange={(e) => { setCurrentRent(e.target.value) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon sx={{ color: 'black' }}>
                      $
                    </Icon>
                  </InputAdornment>
                )
              }} />
          </CardContent>
          <CardContent sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px" }}>
            <div style={{ flex: 1 }}>
              <TextField
                label="Late Payment Fees"
                type="number"
                variant="outlined"
                fullWidth={true}
                value={latePaymentFees}
                onChange={(e) => { setLatePaymentFees(e.target.value) }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon sx={{ color: 'black' }}>
                        $
                      </Icon>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <FormControl fullWidth>
                <TextField
                  fullWidth={true}
                  label="Late Fees Time Period"
                  select
                  variant="outlined"
                  value={lateFeesTimePeriod}
                  onChange={e => { setLateFeesTimePeriod(e.target.value) }}>
                  {timePeriod.map((type) => (
                    <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ flex: 1 }}>
              <TextField
                type="number"
                label="Late Fees Grace Period"
                variant="outlined"
                fullWidth={true}
                value={lateFeesGraceDuration}
                onChange={e => { setLateFeesGraceDuration(e.target.value) }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      Days
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </CardContent>
          <CardContent sx={{ pt: 0 }}>
            <TextField
              type="number"
              label="Miscellaneous Rent"
              margin="dense"
              variant="outlined"
              fullWidth={true}
              value={miscellaneousRent}
              onChange={e => { setMiscellaneousRent(e.target.value) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon sx={{ color: 'black' }}>
                      $
                    </Icon>
                  </InputAdornment>
                )
              }} />
          </CardContent>
          <CardContent sx={{ pt: 0 }}>
            <FormControl fullWidth={true}>
              <TextArea
                placeholder="Miscellaneous Rent Explanation"
                margin="dense"
                variant="outlined"
                value={miscellaneousRentExplanation}
                onChange={e => { setMiscellaneousRentExplanation(e.target.value) }}
                fullWidth={true} />
            </FormControl>
          </CardContent>
          <CardContent sx={{ display: "flex", flexDirection: "row" }}>
            <Button sx={{ fontSize: "small", fontWeight: 500 }} variant="contained" type="submit" color="success" onClick={rentDetailSaveClick}>SAVE</Button>
            <Button sx={{ fontSize: "small", fontWeight: 500 }} type="submit" color="error" onClick={() => { setRentDetail(false) }}>CANCEL</Button>
          </CardContent>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={emailStatusCondition}
          onClose={() => { setEmailStatusCondition(false) }}
        >
          <CardContent>
            <Typography variant="h6" component="h2">
              ({tenantEmail}) Email already in system. Can't proceed with existing email. Contact Administrator
            </Typography>
          </CardContent>
          <CardContent sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { setEmailStatusCondition(false) }}>Ok</Button>
            <Button color='error' sx={{ fontSize: 15 }} onClick={() => { setEmailStatusCondition(false) }} >CANCEL</Button>
          </CardContent>
        </Dialog>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div >
  );
}

export default LeaseOverview;