import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TableSortLabel } from "@mui/material";
import { useState, useEffect } from "react";
import TabMenu from "../properties/edit/tabMenu";
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getServiceRequestProperties, getSingleProperty } from "../../../services";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import { extendMoment } from "moment-range";
import ToolTip from "../../../components/ToolTip";

function ManagerServiceRequest() {
    const navigate = useNavigate();
    const params = useParams();
    const moment = extendMoment(Moment);

    const [property, setProperty] = useState(null);
    const [searchAllProperties, setSearchAllProperties] = useState(false);
    const [search, setSearch] = useState("");
    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('createdAt');

    const pageLoad = async (status) => {
        try {
            setLoading(true);
            await getSingleProperty(params.propertyId).then((res) => {
                let data = res?.data?.data;
                setProperty(data);
                return getServiceRequestProperties(params.propertyId, status ? `?status=all-service-request` : `?status=individual-property-service-request`);
            }).then((res) => {
                let data = res.data.data;
                setService(data);
                setLoading(false);
            })
        } catch (err) {
            setLoading(false);
            console.log(`Error${err}`);
        }
    }

    const handleCheckbox = (event) => {
        setSearchAllProperties(event);
        pageLoad(event);
    }

    useEffect(() => {
        pageLoad(false);
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const filteredServiceRequests = service.filter((item) => {
        if (search === "") {
            return item;
        }
        if (item?.title.toLowerCase().includes(search.toLowerCase())) {
            return item;
        }
        if (item?.assigneeName.toLowerCase().includes(search.toLowerCase())) {
            return item;
        }
        if (item?.propertyAddress.toLowerCase().includes(search.toLowerCase())) {
            return item;
        }
    });

    const getAllTotal = (arr, status) => {
        let total = 0;
        if (status === "lCharge") {
            arr.map((el) => {
                total = parseFloat(total.toFixed(2)) + parseFloat(el?.totalLaborCost);
            });
        }
        if (status === "mCharge") {
            arr.map((el) => {
                if (el?.financeEntryTransactionType !== "RE_FUND") {
                    total = parseFloat(total.toFixed(2)) + parseFloat(el?.totalMaterialsCost);
                }
            });
        }
        if (status === "reFund") {
            arr.map((el) => {
                if (el?.financeEntryTransactionType === "RE_FUND") {
                    total = total + el?.totalMaterialsCost;
                }
            });
        }
        return total;
    };

    const getTotalCost = () => {
        let total = 0;
        if (filteredServiceRequests && filteredServiceRequests.length) {
            filteredServiceRequests.forEach((element) => {
                if (element?.financeAll && element?.financeAll.length) {
                    total += getAllTotal(element?.financeAll, "lCharge") +
                        getAllTotal(element?.financeAll, "mCharge") -
                        getAllTotal(element?.financeAll, "reFund");
                }
            });
        }
        return total;
    };

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "30px" }}>
            <p style={{ fontSize: 25, textAlign: "center", fontWeight: "bold" }}>{property?.address?.line1 + "," + property?.address?.line2 + " " + property?.address?.unitNumber}</p>
            <div>
                <div>
                    <TabMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "0px", flexWrap: 'wrap', marginTop: '30px' }}>
                    <div>
                        <div>
                            <input type="text" placeholder="Search(Any field in service request)"
                                style={{ outline: "3px solid #4a90e2", border: "none", height: "40px", minWidth: "300px", borderRadius: "3px" }}
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }} />
                        </div>
                        <FormGroup sx={{ mt: 3 }}>
                            <FormControlLabel control={<Checkbox />}
                                checked={searchAllProperties}
                                onChange={(e) => { handleCheckbox(e.target.checked) }}
                                label={<span style={{ fontSize: 12, fontWeight: "bold" }}>Search across all properties</span>} />
                        </FormGroup>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: '20px', flexWrap: 'wrap' }}>
                        <div>
                            <Button
                                variant="contained"
                                type="submit"
                                style={{ fontSize: 15, borderRadius: 20, background: '#3f48cc' }}
                                onClick={() => { navigate(`/manager/properties/${params.propertyId}/service-requests/re-occuring`) }}
                            ><AddIcon />{" "}RE-OCCURING SERVICE REQUEST</Button>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="success"
                                type="submit"
                                style={{ fontSize: 15, borderRadius: 20 }}
                                onClick={() => { navigate(`/manager/properties/${params.propertyId}/service-requests/new`) }}
                            ><AddIcon />{" "}SERVICE REQUEST</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>
                                        Sr.No
                                    </TableCell>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>
                                        <TableSortLabel
                                            active={orderBy === 'createdAt'}
                                            direction={orderBy === 'createdAt' ? order : 'asc'}
                                            onClick={(e) => handleRequestSort(e, 'createdAt')}
                                        >
                                            Date
                                        </TableSortLabel>
                                    </TableCell>
                                    {searchAllProperties &&
                                        <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>
                                            <TableSortLabel
                                                active={orderBy === 'propertyAddress'}
                                                direction={orderBy === 'propertyAddress' ? order : 'asc'}
                                                onClick={(e) => handleRequestSort(e, 'propertyAddress')}
                                            >
                                                Address
                                            </TableSortLabel>
                                        </TableCell>}
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>
                                        <TableSortLabel
                                            active={orderBy === 'title'}
                                            direction={orderBy === 'title' ? order : 'asc'}
                                            onClick={(e) => handleRequestSort(e, 'title')}
                                        >
                                            Title
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>
                                        Cost
                                    </TableCell>
                                    <TableCell className='tableCell-maxWidth' sx={{ color: "darkgrey" }}>
                                        <TableSortLabel
                                            active={orderBy === 'assigneeName'}
                                            direction={orderBy === 'assigneeName' ? order : 'asc'}
                                            onClick={(e) => handleRequestSort(e, 'assigneeName')}
                                        >
                                            Assignee
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(filteredServiceRequests, getComparator(order, orderBy)).map((h, j) => (
                                    <TableRow key={j}>
                                        <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{h?.numberId}</TableCell>
                                        <TableCell className='tableCell-maxWidth'>{moment(h?.createdAt).format('MMM D, YYYY')}</TableCell>
                                        {searchAllProperties && <TableCell className='tableCell-maxWidth'>{h?.propertyAddress}</TableCell>}
                                        <TableCell className='tableCell-maxWidth'><Link to={`/manager/service-requests/${h?.id}`} style={{ color: h?.state === 1 ? 'grey' : '' }}>{h?.title}</Link></TableCell>
                                        <TableCell className='tableCell-maxWidth'>${" "}{(getAllTotal(h?.financeAll, "lCharge") + getAllTotal(h?.financeAll, "mCharge") - getAllTotal(h?.financeAll, "reFund")).toFixed(2)}</TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: 'wrap', gap: 10 }}>
                                                <p style={{ marginBottom: "0px" }}>{h?.assigneeName}</p>
                                                {h?.assigneePhone || h?.assigneeEmail ?
                                                    <ToolTip phone={h?.assigneePhone} email={h?.assigneeEmail} />
                                                    : ""}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell className='tableCell-maxWidth' component="th" stylescope="row" sx={{ fontWeight: 'bold' }}>Total</TableCell>
                                    <TableCell className='tableCell-maxWidth'></TableCell>
                                    {searchAllProperties && <TableCell className='tableCell-maxWidth'></TableCell>}
                                    <TableCell className='tableCell-maxWidth'></TableCell>
                                    <TableCell className='tableCell-maxWidth' sx={{ fontWeight: 'bold' }}>${" "}{getTotalCost().toFixed(2)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
}

export default ManagerServiceRequest;
