import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getApiBaseURL, getArchivedProperties, getFinanceGroups, getManagerPropertiesOverview, postUnArchivegroup } from '../../../../services';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CircularProgress, Dialog, Fab, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import Moment from "moment";
import { extendMoment } from "moment-range";
import axios from "axios";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToolTip from '../../../../components/ToolTip';
import { Gauge, gaugeClasses } from '@mui/x-charts';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[5],
        fontSize: 14,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        "&::before": {
            backgroundColor: theme.palette.common.white,
            border: "1px solid #999"
        }
    },
}));

const ManagerPropertiesArchived = () => {
    const params = useParams();
    const date = new Date();
    const navigate = useNavigate();
    const moment = extendMoment(Moment);

    const [groupView, setGroupView] = useState([]);
    const [search, setSearch] = useState("");
    const [defaultGroupView, setDefaultGroupView] = useState([]);
    const [incomeExpense, setIncomeExpense] = useState([]);
    const [archivedIncomeExpense, setArchivedIncomeExpense] = useState([]);
    const [startDate, setStartDate] = useState(new Date("2023-01-01"));
    const start = Date.parse(startDate);
    const [unarchiveCondition, setUnarchiveCondition] = useState({});
    const [unarchive, setUnarchive] = useState(false);
    const [groupId, setGroupId] = useState("");
    const [loading, setLoading] = useState(true);

    const toggle = id => {
        setUnarchiveCondition(prevunarchiveCondition => ({ [id]: !prevunarchiveCondition[id] }))
    }

    const toggles = id => {
        setUnarchiveCondition(prevunarchiveCondition => ({ [id]: !prevunarchiveCondition[id] }))
    }

    const unarchiveClick = (id, status) => {
        if (status === "openUnarchiveDialog") {
            setUnarchive(true);
            setGroupId(id);
        }
        if (status === "UnarchiveokClick") {
            let data = {
                archive: false
            }
            postUnArchivegroup(groupId, data).then((res) => {
                pageLoad();
                setGroupId("");
                navigate(`/manager/properties/${params.managerId}`)
                toast.success(`PropertyGroup unarchived successfully`)
            }).catch((err) => { toast.error(err.response.data.error.message) });
        }
        if (status === "UnarchiveCancelClick") {
            setUnarchive(false);
            setGroupId("");
        }
    }

    useEffect(() => {
        pageLoad();
    }, []);

    var config = ""
    const pageLoad = async () => {
        setLoading(true);
        let arc = [];
        let prop = [];
        let group = [];
        let defaultGroup = [];
        let finance = [...incomeExpense];
        finance.length = 0;
        let archivedFinance = [...archivedIncomeExpense];
        archivedFinance.length = 0;

        const financeGroup = await getFinanceGroups(params.managerId).then((res) => {
            let responseData = res.data.data;
            responseData.map((grp) => {
                if (params.managerId == grp.manager) {
                    if (grp.archived == true) {
                        group.push({
                            "email": grp?.email,
                            "manager": grp?.manager,
                            "name": grp?.name,
                            "phone": grp?.phone,
                            "groupName": grp?.groupName,
                            "properties": grp?.properties,
                            "groupId": grp?.groupId,
                            "groupServiceRequest": grp?.groupServiceRequest,
                        })
                    }
                    if (grp.groupName == "Portfolio") {
                        defaultGroup.push({
                            "email": grp?.email,
                            "manager": grp?.manager,
                            "name": grp?.name,
                            "phone": grp?.phone,
                            "groupName": grp?.groupName,
                            "properties": grp?.properties,
                            "groupId": grp?.groupId,
                            "groupServiceRequest": grp?.groupServiceRequest,
                        })
                    }
                }
            })
        }).catch((err) => { console.log(err); });

        const archProperty = await getArchivedProperties().then((response) => {
            let props = response.data.data;
            props.map((e) => {
                e.propertyManagers.map((f) => {
                    if (f.manager.id == params.managerId) {
                        if (f?.permissions.main === true && f?.permissions.owner === true || f.permissions.main === true && f.permissions.owner === false) {
                            arc.push({
                                "active": e?.active,
                                "manId": f?.manager.id,
                                "name": f?.manager.user.name.first + " " + f?.manager.user.name.last,
                                "address": e?.address.line1 + ` ` + e?.address.unitNumber + ` ` + e?.address.line2 + ` ` + e?.address.city,
                                "objectAddress": e?.address,
                                "currentLease": e?.currentLease,
                                "dueAmount": e?.dueAmount,
                                "id": e?.id,
                                "leaseApplications": e?.leaseApplications,
                                "owner": e?.owner,
                                "ownerId": e?.owner.user.name.first + " " + e?.owner.user.name.last,
                                "propertyManagers": e?.propertyManagers,
                                "rentAmount": e?.rentAmount,
                                "serviceRequests": e?.serviceRequests,
                            })
                        }
                    }
                })
            })
        }).catch((err) => { console.log(err); });

        const property = await getManagerPropertiesOverview().then((response) => {
            let props = response.data.data;
            props.map((e) => {
                e.propertyManagers.map((f) => {
                    if (f.manager.id == params.managerId) {
                        if (f?.permissions.main === true && f?.permissions.owner === true || f.permissions.main === true && f.permissions.owner === false) {
                            prop.push({
                                "active": e?.active,
                                "manId": f?.manager.id,
                                "name": f?.manager.user.name.first + " " + f?.manager.user.name.last,
                                "address": e?.address.line1 + ` ` + e?.address.unitNumber + ` ` + e?.address.line2 + ` ` + e?.address.city,
                                "objectAddress": e?.address,
                                "currentLease": e?.currentLease,
                                "dueAmount": e?.dueAmount,
                                "id": e?.id,
                                "leaseApplications": e?.leaseApplications,
                                "owner": e?.owner,
                                "ownerId": e?.owner.user.name.first + " " + e?.owner.user.name.last,
                                "propertyManagers": e?.propertyManagers,
                                "rentAmount": e?.rentAmount,
                                "serviceRequests": e?.serviceRequests,
                            })
                        }
                    }
                })
            })
        }).catch((err) => { console.log(err); });

        const authToken = sessionStorage.getItem("authToken");
        if (authToken != undefined) {
            config = {
                headers: { Authorization: `Bearer ${authToken}` },
                params: {
                    timeBegin: start,
                    timeEnd: null
                },
            };
        }
        const financeArch = await axios.get(`${getApiBaseURL()}/manager/${params.managerId}/finances/archived`, config).then((res) => {
            let fin = res.data.data;
            fin.groups.map((f) => {
                archivedFinance.push({
                    "name": f.name,
                    "id": f.id,
                    "incomeToDate": f.categories.RENTAL_INCOME.total
                        + f.categories.OTHER_INCOME.total
                        + f.categories.PARKING_INCOME.total
                        + f.categories.LAUNDRY_SERVICE_INCOME.total
                        + f.categories.SHORT_TERM_RENTS_INCOME.total
                        + f.categories.LATE_FEES_INCOME.total
                        + f.categories.STORAGE_INCOME.total
                        + f.categories.MISC_INTEREST_INCOME.total,
                    "expenseToDate": f.categories.ADVERTISING_EXPENSE.total
                        + f.categories.CLEANING_AND_MAINTENANCE_EXPENSE.total
                        + f.categories.INSURANCE_EXPENSE.total
                        + f.categories.LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE.total
                        + f.categories.MANAGEMENT_EXPENSE.total
                        + f.categories.MEALS_EXPENSE.total
                        + f.categories.OTHER_EXPENSE.total
                        + f.categories.REPAIRS_EXPENSE.total
                        + f.categories.SUPPLIES_EXPENSE.total
                        + f.categories.TAXES.total
                        + f.categories.TRAVEL_EXPENSE.total
                        + f.categories.UTILITIES_EXPENSE.total
                        + f.categories.CAPITAL_EXPENSE.total
                        + f.categories.MORTGAGES_AND_LOANS_EXPENSE.total
                })
            })
            setArchivedIncomeExpense(archivedFinance);
        }).catch((err) => { console.log(err); });

        const financeCal = await axios.get(`${getApiBaseURL()}/manager/${params.managerId}/financegroupCalculation`, config).then((res) => {
            let fin = res.data.data;
            fin.groups.map((f) => {
                finance.push({
                    "name": f.name,
                    "id": f.id,
                    "incomeToDate": f.categories.RENTAL_INCOME.total + f.categories.OTHER_INCOME.total + f.categories.PARKING_INCOME.total + f.categories.LAUNDRY_SERVICE_INCOME.total,
                    "expenseToDate": f.categories.ADVERTISING_EXPENSE.total + f.categories.CLEANING_AND_MAINTENANCE_EXPENSE.total + f.categories.INSURANCE_EXPENSE.total + f.categories.LEGAL_AND_PROFESSIONAL_SERVICES_EXPENSE.total + f.categories.MANAGEMENT_EXPENSE.total
                        + f.categories.MEALS_EXPENSE.total + f.categories.OTHER_EXPENSE.total + f.categories.REPAIRS_EXPENSE.total + f.categories.SUPPLIES_EXPENSE.total + f.categories.TAXES.total +
                        f.categories.TRAVEL_EXPENSE.total + f.categories.UTILITIES_EXPENSE.total,
                })
            })
            setIncomeExpense(finance);
        }).finally(() => {
            setLoading(false);
        });
        archivedGroupPropertyAndFinanceGroup(prop, group);
        defaultArchivedGroupPropertyAndFinanceGroup(arc, defaultGroup);
        setLoading(false);
    }

    const archivedGroupPropertyAndFinanceGroup = (pro, group) => {
        const newArray = [];
        group.forEach(item => {
            let newItem = { email: item.email, manager: item.manager, name: item.name, phone: item.phone, groupName: item.groupName, groupId: item.groupId, groupServiceRequest: item.groupServiceRequest, properties: [], propertyIds: [] };
            item.properties.forEach(items => {
                pro.forEach(prop => {
                    if (prop.id == items) {
                        newItem.properties = newItem.properties.concat(prop);
                        newItem.propertyIds = newItem.propertyIds.concat(prop.id);
                    }
                });
            })
            newArray.push(newItem);
        });
        let groupedPeople = groupBy(newArray, "groupId");
        setGroupView(groupedPeople);
    }

    const filteredGroupView = Object.keys(groupView).reduce((acc, category) => {
        acc[category] = groupView[category].filter((item) =>
            item.properties.some((obj) => {
                if (search == "") {
                    return obj;
                } else if (obj.address.includes(search) || obj.address.toLowerCase().includes(search)) {
                    return obj;
                } else if (obj.serviceRequests.find((serv) => (serv.title.toLowerCase().includes(search) || serv.title.includes(search)))) {
                    return obj;
                } else if (obj.currentLease && obj.currentLease.leaseTenants.find((lease) => (lease.name.first.includes(search)))) {
                    return obj;
                }
            }
            )
        );
        return acc;
    }, {});

    const defaultArchivedGroupPropertyAndFinanceGroup = (arc, defaultGroup) => {
        const newArray = [];
        defaultGroup.forEach(item => {
            let newItem = { email: item.email, manager: item.manager, name: item.name, phone: item.phone, groupName: item.groupName, groupId: item.groupId, groupServiceRequest: item.groupServiceRequest, properties: [], propertyIds: [] };
            arc.forEach(prop => {
                newItem.properties = newItem.properties.concat(prop);
                newItem.propertyIds = newItem.propertyIds.concat(prop.id);
            });
            newArray.push(newItem);
        });
        let grp = newArray.reduce((ac, a) => ac.find(x => x.groupName === a.groupName) ? [...ac] : [...ac, a], []);
        let groupedPeople = groupBy(grp, "groupId");
        setDefaultGroupView(groupedPeople);
    }

    const filteredDefaultGroupView = Object.keys(defaultGroupView).reduce((acc, category) => {
        acc[category] = defaultGroupView[category].filter((item) =>
            item.properties.some((obj) => {
                if (search == "") {
                    return obj;
                } else if (obj.address.includes(search) || obj.address.toLowerCase().includes(search)) {
                    return obj;
                } else if (obj.serviceRequests.find((serv) => (serv.title.toLowerCase().includes(search) || serv.title.includes(search)))) {
                    return obj;
                } else if (obj.currentLease && obj.currentLease.leaseTenants.find((lease) => (lease.name.first.includes(search)))) {
                    return obj;
                }
            }
            )
        );
        return acc;
    }, {});

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    const getServiceRequestTitleColor = (status) => {
        if (status === 0) {
            return 'rgb(11, 119, 11)';
        }
        if (status === 1) {
            return '#F2994A';
        }
        if (status === 2) {
            return '#DC3545';
        }
    }

    const dueRemianderDaysShow = (dat) => {
        let today = new Date();
        let d1 = moment(today);
        let d2 = dat ? moment(dat) : null;
        if (!d2) {
            return 'No Due Date Set'
        }
        if (d2) {
            if (d1 < d2) {
                return 'Due in' + " " + Math.abs(moment(d2).diff(moment(d1), "days")) + " " + 'days';
            } else if (d1 > d2) {
                return 'Due date was' + " " + Math.abs(moment(d2).diff(moment(d1), "days")) + " " + 'days ago';
            } else {
                return 'Currently due';
            }
        }
    }

    const dueAmountColor = (dat, due, rent) => {
        let d2 = dat ? moment(dat) : null;
        if (!d2) {
            if (due > rent) {
                return 'property-list-due-amount-text-red'
            }
            else if (due > 0) {
                return 'property-list-due-amount-text-yellow'
            }
        }
        if (d2) {
            if (due > rent) {
                return 'property-list-due-amount-text-red'
            } else if (due > 0) {
                return 'property-list-due-amount-text-yellow'
            }
        }
    }

    const serviceRequestLength = (data) => {
        let totalServiceRequests = 0;

        data.forEach((item) => {
            totalServiceRequests += item.groupServiceRequest.length;
            item.properties.forEach((property) => {
                totalServiceRequests += property.serviceRequests.length;
            });
        });

        return totalServiceRequests; // Return the total service requests
    };

    const rentPastDue = (data) => {
        let totalDueAmount = 0;
        data.forEach((item) => {
            item.properties.forEach((property) => {
                totalDueAmount += property.dueAmount;
            });
        });
        return totalDueAmount; // Return total dueAmounts
    }

    // const renderLeaseApplications = (applications) => {
    //     return applications.map((item, i) => {
    //         return (
    //             <div onClick={() => { leaseApplicationViewClick(item.id) }} key={i}>
    //                 {item.applicants.map((applicant, index) => {
    //                     return (
    //                         <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", fontWeight: "bold", color: "#1976D2", cursor: 'pointer' }} key={index}>
    //                             <RateReviewIcon style={{ color: "#DC3545", fontSize: "1.5rem", marginRight: 10, marginLeft: 20 }} />
    //                             {applicant.firstName} {applicant.lastName}
    //                         </div>
    //                     );
    //                 })}
    //             </div>
    //         );
    //     });
    // };

    const renderMoveIn = (item, date) => {
        if (item.currentLease.moveInDetails.status === 2) {
            const daysUntilMoveIn = moment(item.currentLease.moveInDetails.moveInDate).diff(moment(date), "days");
            return (
                <div style={{ color: "#4a90e2", fontWeight: "bold", cursor: 'pointer' }} onClick={() => moveInClick(item.id)}>
                    Move In in {daysUntilMoveIn} days
                </div>
            );
        }
        if (item.currentLease.moveInDetails.status === 5) {
            return (
                <div style={{ color: "#DC3545", fontWeight: "bold", cursor: 'pointer' }} onClick={() => moveInVerifyClick(item.currentLease.id)}>
                    Verify Move In Checklist
                </div>
            );
        }
        return null;
    }

    const renderLeaseExpiry = (item, date) => {
        // const daysUntilExpiry = moment(item.currentLease.expiryDate).diff(moment(date), "days");
        // const isExpired = Math.sign(daysUntilExpiry) === -1;

        let beginDate = new Date(item?.currentLease?.beginDate);
        let expiryDate = new Date(item?.currentLease?.expiryDate)
        let today = date;

        let Percentage = ((moment(expiryDate).diff(moment(), 'days')) /
            (moment().diff(moment(beginDate), 'days') + moment(expiryDate).diff(moment(), 'days')) * 100) <= 0 ? 0 : ((moment(expiryDate).diff(moment(), 'days')) /
                (moment().diff(moment(beginDate), 'days') + moment(expiryDate).diff(moment(), 'days')) * 100);

        let fillColor;
        if (Percentage < 25) {
            fillColor = '#DD3F19'; // Red color for values less than 25
        } else if (Percentage >= 25 && Percentage <= 30) {
            fillColor = '#FC661A'; // Yellow color for values between 25 and 30
        } else {
            fillColor = '#52b202'; // Green color for values greater than 30
        }

        return (
            // <div
            //   style={{
            //     color: isExpired ? "#DC3545" : "rgb(11, 119, 11)",
            //     fontWeight: "bold",
            //     cursor: 'pointer'
            //   }}
            //   onClick={() => leaseExpiryClick(item.id)}
            // >
            //   {isExpired ? (
            //     <div>Expired {daysUntilExpiry} days ago</div>
            //   ) : (
            //     <div>Expires in {Math.abs(daysUntilExpiry)} days</div>
            //   )}
            // </div>

            <div>
                <Gauge
                    // width={300}
                    height={100}
                    value={Percentage}
                    startAngle={-90}
                    endAngle={90}
                    text={moment(expiryDate).diff(moment(today), "days") <= 0 ? 0 : moment(expiryDate).diff(moment(today), "days").toString()}
                    sx={(theme) => ({
                        [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 15,
                            fontWeight: 'bold'
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: fillColor,
                        },
                        [`& .${gaugeClasses.referenceArc}`]: {
                            fill: theme.palette.text.disabled,
                        },
                        width: '60%'
                    })}
                />
            </div>
        );
    }

    const renderMoveOut = (item, date) => {
        if (item.currentLease.moveOutDetails.status === 2) {
            const daysUntilMoveOut = moment(item.currentLease.moveOutDetails.moveOutDate).diff(moment(date), "days");
            return (
                <div style={{ color: "#ffa500", fontWeight: "bold", cursor: 'pointer' }} onClick={() => moveOutClick(item.currentLease.id)}>
                    Move Out in {daysUntilMoveOut} days
                </div>
            );
        }
        return null;
    }

    const leaseApplicationViewClick = (id) => {
        navigate(`/manager/lease-applications/${id}`)
    }

    const newServiceRequest = (id) => {
        navigate(`/manager/properties/${id}/service-requests/new`)
    }

    const leaseExpiryClick = (id) => {
        navigate(`/manager/properties/${id}/lease`)
    }

    const moveOutClick = (id) => {
        navigate(`/manager/leases/${id}/move-out-form`)
    }

    const moveInClick = (id) => {
        navigate(`/manager/properties/${id}/lease`)
    }

    const moveInVerifyClick = (id) => {
        navigate(`/manager/leases/${id}/move-in-inspection`)
    }

    const incomeExpenseClick = (id) => {
        navigate(`/manager/${params.managerId}/property-group/${id}/group-finances`)
    }

    const serviceRequestsClick = (id) => {
        navigate(`/manager/service-requests/${id}`)
    }

    const complexAddClick = (manId, grpId) => {
        navigate(`/manager/${manId}/property-group/${grpId}/serviceRequest/group/new`)
    }

    const unArchivedClick = () => {
        navigate(`/manager/properties/${params.managerId}`)
    }

    const leaseOverviewClick = (id) => {
        navigate(`/manager/properties/${id}/overview`)
    }

    const addPropertyClick = () => {
        navigate(`/manager/properties/add`)
    }

    const activePropertiesClick = () => {
        navigate(`/manager/active-Properties`)
    }

    const dueAmountClick = (id) => {
        navigate(`/manager/properties/${id}/finance`);
    }

    const complexServiceRequestsClick = (manId, grpId, id) => {
        navigate(`/manager/${manId}/property-group/${grpId}/service-requests/${id}`)
    }

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "1.5rem" }}>
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                <CardContent style={{ width: "100%", maxWidth: "1250px", p: 0, flexGrow: 1 }}>
                    <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", p: 0 }}>
                        <div style={{ display: "flex", flexDirection: "row", margin: "0px", alignItems: "center", flexWrap: "wrap" }}>
                            <ApartmentIcon sx={{ fontSize: 25, fontWeight: "bolder", width: '40px', height: "40px", backgroundColor: "#4a90e2", color: "white", borderRadius: "5px", padding: "5px" }} />
                            <span style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                <p style={{ fontSize: 20, fontWeight: "bolder", marginLeft: "10px", marginBottom: "10px", height: "18px" }}>Properties</p>
                                <span style={{ display: "flex", flexDirection: "row", fontSize: 14, fontWeight: "bolder", color: "#4A90E2", marginLeft: "10px" }} onClick={unArchivedClick}>Back to Active Properties</span>
                            </span>
                        </div>
                        <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", gap: 20, p: 0 }}>
                            <Button style={{ backgroundColor: '#8bc34a', borderRadius: 35, width: "200px", fontSize: 15 }}
                                variant="contained"
                                onClick={addPropertyClick}>
                                + PROPERTY</Button>
                            <Button
                                style={{ borderRadius: 35, fontSize: 15, width: "200px" }}
                                color="primary" variant="contained"
                                onClick={activePropertiesClick}>
                                Active Properties
                            </Button>
                        </CardContent>
                        <CardContent style={{ display: "flex", justifyContent: "right", alignItems: "right", flexWrap: "wrap" }}>
                            <div style={{ outline: "3px solid #4a90e2", borderRadius: "5px" }}>
                                <FormControl sx={{ backgroundColor: "#EFEFEF" }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        defaultValue="All"
                                    // value={contractState}
                                    // onChange={(e) => {
                                    //     setContractState(e.target.value);
                                    // }}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        <MenuItem value="OK">OK</MenuItem>
                                        <MenuItem value="WARN">WARN</MenuItem>
                                        <MenuItem value="ALERT">ALERT</MenuItem>
                                        <MenuItem value="NOT OK">NOT OK</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField placeholder="Search" id="outlined-size-small" size="small"
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }} />
                            </div>
                        </CardContent>
                    </CardContent>
                    <div>
                        {Object.keys(filteredDefaultGroupView).map((cat, index) => (
                            <CardContent key={index}>
                                {filteredDefaultGroupView[cat].map((group, i) => (
                                    <CardContent sx={{ outline: "3px solid #818589", borderRadius: 1, backgroundColor: "#FFF", padding: " 40px 15px 15px" }} key={i}>
                                        <Accordion style={{ backgroundColor: "#FFF", padding: " 30px 10px 10px", boxShadow: "0 0 10px #D3D3D3" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: 10 }}>
                                                <CardContent sx={{ flexGrow: 1, p: 0 }}>
                                                    <p className="property-head-paragraph">
                                                        Complex
                                                    </p>
                                                    <p className="property-address">{group.groupName}</p>
                                                </CardContent>
                                                <CardContent sx={{ flexGrow: 2, p: 0 }}>
                                                    <p className="property-head-paragraph">
                                                        Manager
                                                    </p>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                                        <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{group.name}</p>
                                                        <ToolTip phone={group.phone} email={group.email} />
                                                    </div>
                                                </CardContent>
                                                <CardContent sx={{ flexGrow: 1, p: 0 }}>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', flexWrap: "wrap" }}>
                                                        <p className="property-head-paragraph" style={{ textAlign: 'center' }}>
                                                            Finances
                                                        </p>
                                                        {rentPastDue(filteredDefaultGroupView[cat]) != 0 ?
                                                            <div style={{ color: '#DC3545', fontWeight: "bold" }}>
                                                                {'('}{'$'}{rentPastDue(filteredDefaultGroupView[cat]).toLocaleString('en')}{'.00'} {'Rent Past Due)'}
                                                            </div> : ""}
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                        <p className="property-head-paragraph">
                                                            Income To-Date :{" "}<span style={{ fontWeight: "bold", color: "rgb(37, 175, 2)", fontSize: "15px" }}>$ {archivedIncomeExpense.map((f) => (f.incomeToDate)).reduce((prev, current) => {
                                                                return prev + current;
                                                            }, 0).toFixed(2)}</span>
                                                        </p>
                                                        <p className="property-head-paragraph" style={{ marginBottom: "0px !important" }}>
                                                            Expense To-Date :{" "}<span style={{ fontWeight: "bold", color: "rgb(228, 0, 0)", fontSize: "15px" }}>$ {archivedIncomeExpense.map((f) => (f.expenseToDate)).reduce((prev, current) => {
                                                                return prev + current;
                                                            }, 0).toFixed(2)}</span>
                                                        </p>
                                                    </div>
                                                </CardContent>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Card>
                                                    {group.properties.map((item, i) => (
                                                        <CardContent key={i}>
                                                            {/* {item.active === true ? */}
                                                            <CardContent sx={{ boxShadow: "0 0 10px #888888", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", outline: '3px solid #818589', borderRadius: 1 }}>
                                                                <CardContent sx={{ borderRight: "1px solid black", flexGrow: 1, maxWidth: 300, backgroundColor: 'rgb(245, 245, 245)' }}>
                                                                    <p className="property-paragraph">Property</p>
                                                                    <Link style={{ textDecoration: 'none', color: "black" }}
                                                                        to={`/manager/properties/${item.id}/edit`}>
                                                                        <p className="property-address">{item?.objectAddress?.line1 + ` ` + item?.objectAddress?.line2 + ` ` + item?.objectAddress?.unitNumber}</p>
                                                                        <p className="property-address">{item?.objectAddress?.city}</p>
                                                                        <p className="property-address">{item?.objectAddress?.state + ` ` + item?.objectAddress?.zipCode}</p>
                                                                    </Link>
                                                                </CardContent>
                                                                <CardContent sx={{ borderRight: "1px solid black", flexGrow: 1, maxWidth: 300, backgroundColor: 'rgb(245, 245, 245)' }}>
                                                                    <p className="property-paragraph">Tenants</p>
                                                                    {item.currentLease ? (
                                                                        item.currentLease.leaseTenants.map((lease, i) => (
                                                                            <div style={{ display: "flex", flexDirection: "row", gap: 10, flexWrap: 'wrap' }} key={i}>
                                                                                <span style={{ marginBottom: "0px", fontWeight: "bold", display: "flex", flexDirection: "row", flexWrap: "wrap", cursor: 'pointer' }}>
                                                                                    <span><HomeIcon style={{ color: "#28A745", fontSize: "1.5rem", marginRight: 10 }} /></span>
                                                                                    <span onClick={() => { leaseOverviewClick(item.id) }}>{lease.name.first}{" "}{lease.name.last}</span>
                                                                                </span>
                                                                                <ToolTip phone={lease?.phone?.code + " " + lease?.phone?.number} email={lease?.email?.id} />
                                                                            </div>))
                                                                    ) : (
                                                                        <div>
                                                                            <p style={{ color: "#F2994A", fontWeight: "bold" }}>No Active Tenants</p>
                                                                        </div>)}
                                                                    {/* <div>{renderLeaseApplications(item.leaseApplications)}</div> */}
                                                                </CardContent>
                                                                <CardContent sx={{ borderRight: "1px solid black", flexGrow: 1, maxWidth: 300, backgroundColor: 'rgb(245, 245, 245)' }}>
                                                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                                                                        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                                                                            <p className="property-paragraph">Service Requests</p>
                                                                        </div>
                                                                        <div>
                                                                            <Fab
                                                                                size="small"
                                                                                color="primary"
                                                                                aria-label="add"
                                                                                style={{ transform: 'scale(0.6)', backgroundColor: "#1976D2", color: "white" }}
                                                                                onClick={() => { newServiceRequest(item.id) }}
                                                                            >
                                                                                <AddIcon sx={{ fontSize: 40 }} />
                                                                            </Fab>
                                                                        </div>
                                                                    </div>
                                                                    {item.serviceRequests.map((serv, i) => (i < 5 &&
                                                                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }} onClick={() => { serviceRequestsClick(serv.id) }} key={i}>
                                                                            <div style={{ color: getServiceRequestTitleColor(serv.priority), cursor: "pointer", fontWeight: "bold" }}>{i + 1}.{" "}{serv.title}</div>
                                                                            {serv.contractor !== null &&
                                                                                <Tooltip title={serv?.contractor.name} placement="top" arrow>
                                                                                    <PersonIcon sx={{ color: "black", marginLeft: "5px" }} />
                                                                                </Tooltip>}
                                                                            {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 15 &&
                                                                                <div className="property-service-date-badge-cond-green">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                            {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 30 && Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 15 &&
                                                                                <div className="property-service-date-badge-cond-yellow">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                            {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 31 &&
                                                                                <div className="property-service-date-badge-cond-red">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                        </div>
                                                                    ))}
                                                                </CardContent>
                                                                <CardContent sx={{ flexGrow: 1, maxWidth: 300, backgroundColor: 'rgb(245, 245, 245)' }}>
                                                                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                        {item.dueAmount > 0 ?
                                                                            <span>
                                                                                <p className="property-paragraph">Due Amount</p>
                                                                                <LightTooltip title={dueRemianderDaysShow(item.dueReminderDate)} placement="top-start">
                                                                                    <p className={dueAmountColor(item.dueReminderDate, item.dueAmount, item.rentAmount)} onClick={() => { dueAmountClick(item.id) }}>$ {item.dueAmount.toLocaleString('en')}.00</p>
                                                                                </LightTooltip>
                                                                            </span> : ""}
                                                                        <p className="property-paragraph">Lease Status</p>
                                                                        {!item.currentLease ? (
                                                                            <p style={{ color: "#F2994A", fontWeight: "bold" }}>No Active Lease</p>
                                                                        ) : (
                                                                            <div>
                                                                                {renderMoveIn(item, date)}
                                                                                {renderLeaseExpiry(item, date)}
                                                                                {renderMoveOut(item, date)}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </CardContent>
                                                            </CardContent>
                                                            {/* : ""} */}
                                                        </CardContent>))}
                                                </Card>
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardContent>
                                ))}
                            </CardContent>
                        ))}
                    </div>
                    <div>
                        {Object.keys(filteredGroupView).sort((a, b) => a.groupName < b.groupName ? 1 : -1).map((cat, index) => (
                            <CardContent key={index}>
                                {filteredGroupView[cat].map((group, i) => (
                                    <CardContent sx={{ outline: "3px solid #DC3545", borderRadius: 1, backgroundColor: "#FFF", padding: " 40px 15px 15px" }} key={i}>
                                        <Accordion style={{ backgroundColor: "#FFF", padding: " 30px 10px 10px", boxShadow: "0 0 10px #D3D3D3" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: 10 }}>
                                                <CardContent sx={{ flexGrow: 1, p: 0 }} onMouseEnter={() => { toggle(cat) }} onMouseLeave={() => { toggles(cat) }}>
                                                    <p className="property-head-paragraph">
                                                        Complex
                                                    </p>
                                                    <p className="property-address">{group.groupName}</p>
                                                    {unarchiveCondition[cat] && group.groupName !== ".Default" ?
                                                        <p className="group-unarchive" onClick={() => { unarchiveClick(cat, "openUnarchiveDialog") }}>Unarchive</p> : ''}
                                                </CardContent>
                                                <CardContent sx={{ flexGrow: 1, p: 0 }}>
                                                    <p className="property-head-paragraph">
                                                        Manager
                                                    </p>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                                        <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{group.name}</p>
                                                        <ToolTip phone={group?.phone} email={group?.email} />
                                                    </div>
                                                </CardContent>
                                                <CardContent sx={{ flexGrow: 1, p: 0 }}>
                                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                                                            <p className="property-head-paragraph">
                                                                Complex Service Request
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Fab
                                                                size="small"
                                                                color="primary"
                                                                aria-label="add"
                                                                style={{ transform: 'scale(0.6)', backgroundColor: "#1976D2", color: "white" }}
                                                                onClick={() => { complexAddClick(group.manager, group.groupId) }}
                                                            >
                                                                <AddIcon sx={{ fontSize: 40 }} />
                                                            </Fab>
                                                        </div>
                                                    </div>
                                                    {serviceRequestLength(filteredGroupView[cat]) != 0 ?
                                                        <div style={{ color: '#DC3545', fontWeight: "bold" }}>
                                                            {'('}{serviceRequestLength(filteredGroupView[cat])} {'Pending)'}
                                                        </div> : ""}
                                                    {group.groupServiceRequest !== undefined &&
                                                        <div>
                                                            {group.groupServiceRequest.map((serv, i) => (i < 5 &&
                                                                <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }} onClick={() => { complexServiceRequestsClick(group.manager, group.groupId, serv.id) }} key={i}>
                                                                    <div style={{ color: getServiceRequestTitleColor(serv.priority), cursor: "pointer", fontWeight: "bold" }}>{i + 1}.{" "}{serv.title}</div>
                                                                    {serv.contractor !== null &&
                                                                        <Tooltip title={serv?.contractor.name} placement="top" arrow>
                                                                            <PersonIcon sx={{ color: "black", marginLeft: "5px" }} />
                                                                        </Tooltip>}
                                                                    {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 15 &&
                                                                        <div className="property-service-date-badge-cond-green">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                    {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 30 && Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 15 &&
                                                                        <div className="property-service-date-badge-cond-yellow">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                    {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 31 &&
                                                                        <div className="property-service-date-badge-cond-red">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                </div>
                                                            ))}
                                                        </div>}
                                                </CardContent>
                                                <CardContent sx={{ flexGrow: 1, p: 0 }}>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', flexWrap: "wrap" }}>
                                                        <p className="property-head-paragraph">
                                                            Finances
                                                        </p>
                                                        {rentPastDue(filteredGroupView[cat]) != 0 ?
                                                            <div style={{ color: '#DC3545', fontWeight: "bold" }}>
                                                                {'('}{'$'}{rentPastDue(filteredGroupView[cat]).toLocaleString('en')}{'.00'} {'Rent Past Due)'}
                                                            </div> : ""}
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                        <p className="property-head-paragraph">
                                                            Income To-Date :{" "}<span style={{ fontWeight: "bold", color: "rgb(37, 175, 2)", fontSize: "15px" }} onClick={() => { incomeExpenseClick(group.groupId) }}>${" "}{incomeExpense.map((f) => (f.id == cat && f.incomeToDate)).reduce((prev, current) => {
                                                                return prev + current;
                                                            }, 0).toFixed(2)}</span>
                                                        </p>
                                                        <p className="property-head-paragraph" style={{ marginBottom: "0px !important" }}>
                                                            Expense To-Date :{" "}<span style={{ fontWeight: "bold", color: "rgb(228, 0, 0)", fontSize: "15px" }} onClick={() => { incomeExpenseClick(group.groupId) }}>${" "}{incomeExpense.map((f) => (f.id == cat && f.expenseToDate)).reduce((prev, current) => {
                                                                return prev + current;
                                                            }, 0).toFixed(2)}</span>
                                                        </p>
                                                    </div>
                                                </CardContent>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Card>
                                                    {group.properties.map((item, i) => (
                                                        <CardContent key={i}>
                                                            {/* {item.active === true ? */}
                                                            <CardContent sx={{ boxShadow: "0 0 10px #888888", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", outline: !item.currentLease ? "3px solid #ffa500" : Math.sign(moment(item.currentLease.expiryDate).diff(moment(date), "days")) === -1 ? "3px solid #DC3545" : "3px solid rgb(11, 119, 11)", borderRadius: 1 }}>
                                                                <CardContent sx={{ borderRight: "1px solid black", flexGrow: 1, maxWidth: 300, backgroundColor: 'rgb(245, 245, 245)' }}>
                                                                    <p className="property-paragraph">Property</p>
                                                                    <Link style={{ textDecoration: 'none', color: "black" }}
                                                                        to={`/manager/properties/${item.id}/edit`}>
                                                                        <p className="property-address">{item?.objectAddress?.line1 + ` ` + item?.objectAddress?.line2 + ` ` + item?.objectAddress?.unitNumber}</p>
                                                                        <p className="property-address">{item?.objectAddress?.city}</p>
                                                                        <p className="property-address">{item?.objectAddress?.state + ` ` + item?.objectAddress?.zipCode}</p>
                                                                    </Link>
                                                                </CardContent>
                                                                <CardContent sx={{ borderRight: "1px solid black", flexGrow: 1, maxWidth: 300, backgroundColor: 'rgb(245, 245, 245)' }}>
                                                                    <p className="property-paragraph">Tenants</p>
                                                                    {item.currentLease ? (
                                                                        item.currentLease.leaseTenants.map((lease, i) => (
                                                                            <div style={{ display: "flex", flexDirection: "row", gap: 10, flexWrap: 'wrap' }} key={i} >
                                                                                <span style={{ marginBottom: "0px", fontWeight: "bold", display: "flex", flexDirection: "row", flexWrap: "wrap", cursor: 'pointer' }}>
                                                                                    <span><HomeIcon style={{ color: "#28A745", fontSize: "1.5rem", marginRight: 10 }} /></span>
                                                                                    <span onClick={() => { leaseOverviewClick(item.id) }}>{lease.name.first}{" "}{lease.name.last}</span>
                                                                                </span>
                                                                                <ToolTip phone={lease?.phone?.code + " " + lease?.phone?.number} email={lease?.email?.id} />
                                                                            </div>))
                                                                    ) : (
                                                                        <div>
                                                                            <p style={{ color: "#F2994A", fontWeight: "bold" }}>No Active Tenants</p>
                                                                        </div>
                                                                    )}
                                                                    {/* <div>{renderLeaseApplications(item.leaseApplications)}</div> */}
                                                                </CardContent>
                                                                <CardContent sx={{ borderRight: "1px solid black", flexGrow: 1, maxWidth: 300, backgroundColor: 'rgb(245, 245, 245)' }}>
                                                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                                                                        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                                                                            <p className="property-paragraph">Service Requests</p>
                                                                        </div>
                                                                        <div>
                                                                            <Fab
                                                                                size="small"
                                                                                color="primary"
                                                                                aria-label="add"
                                                                                style={{ transform: 'scale(0.6)', backgroundColor: "#1976D2", color: "white" }}
                                                                                onClick={() => { newServiceRequest(item.id) }}
                                                                            >
                                                                                <AddIcon sx={{ fontSize: 40 }} />
                                                                            </Fab>
                                                                        </div>
                                                                    </div>
                                                                    {item.serviceRequests.map((serv, i) => (i < 5 &&
                                                                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }} onClick={() => { serviceRequestsClick(serv.id) }} key={i}>
                                                                            <div style={{ color: getServiceRequestTitleColor(serv.priority), cursor: "pointer", fontWeight: "bold" }}>{i + 1}.{" "}{serv.title}</div>
                                                                            {serv.contractor !== null &&
                                                                                <Tooltip title={serv?.contractor.name} placement="top" arrow>
                                                                                    <PersonIcon sx={{ color: "black", marginLeft: "5px" }} />
                                                                                </Tooltip>}
                                                                            {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 15 &&
                                                                                <div className="property-service-date-badge-cond-green">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                            {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) < 30 && Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 15 &&
                                                                                <div className="property-service-date-badge-cond-yellow">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                            {Math.abs(moment(serv.updatedAt).diff(moment(date), "days")) > 31 &&
                                                                                <div className="property-service-date-badge-cond-red">{Math.abs(moment(serv.updatedAt).diff(moment(date), "days"))}</div>}
                                                                        </div>
                                                                    ))}
                                                                </CardContent>
                                                                <CardContent sx={{ flexGrow: 1, maxWidth: 300, backgroundColor: 'rgb(245, 245, 245)' }}>
                                                                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                                                                        {item.dueAmount > 0 ?
                                                                            <span>
                                                                                <p className="property-paragraph">Due Amount</p>
                                                                                <LightTooltip title={dueRemianderDaysShow(item.dueReminderDate)} placement="top-start">
                                                                                    <p className={dueAmountColor(item.dueReminderDate, item.dueAmount, item.rentAmount)} onClick={() => { dueAmountClick(item.id) }}>$ {item.dueAmount.toLocaleString('en')}.00</p>
                                                                                </LightTooltip>
                                                                            </span> : ""}
                                                                        <p className="property-paragraph">Lease Status</p>
                                                                        {!item.currentLease ? (
                                                                            <p style={{ color: "#F2994A", fontWeight: "bold" }}>No Active Lease</p>
                                                                        ) : (
                                                                            <div>
                                                                                {renderMoveIn(item, date)}
                                                                                {renderLeaseExpiry(item, date)}
                                                                                {renderMoveOut(item, date)}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </CardContent>
                                                            </CardContent>
                                                            {/* : ""} */}
                                                        </CardContent>))}
                                                </Card>
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardContent>
                                ))}
                            </CardContent>
                        ))}
                    </div>
                </CardContent>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={unarchive}
                onClose={() => { setUnarchive(false); setGroupId("") }}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Do you want Unarchive this complex ?
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                    <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { unarchiveClick("", "UnarchiveokClick") }}>Ok</Button>
                    <Button color='error' sx={{ fontSize: 15 }} onClick={() => { unarchiveClick("", "UnarchiveCancelClick") }} >CANCEL</Button>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default ManagerPropertiesArchived;
