import { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, CardContent, Dialog, DialogContent, DialogTitle, FormControl, Icon, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TabMenu from '../properties/edit/tabMenu';
import {
    getManProperty,
    getManagerFinanceBalanceEntries,
    getManagerFinancetransactions,
    postManagerFinanceBalanceDueEntries,
    postManagerFinanceBalancePaidEntries,
    postManagerAddExpenses,
    patchManagerFinanceBalanceDueEntries,
    deleteManagerFinanceBalanceDueEntries,
    patchManagerFinanceBalancePaidEntries,
    deleteManagerFinanceBalancePaidEntries,
    patchFinanceTransactions,
    deleteFinanceTransactions,
    patchPaymentRemainderDate
} from '../../../services';
import { useParams, useNavigate } from 'react-router-dom';
import Moment from "moment";
import { extendMoment } from "moment-range";
import DeleteIcon from '@mui/icons-material/Delete';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import EventIcon from '@mui/icons-material/Event';
import imageClick from '../../../components/imageView';
import { MenuProps } from '../../../components/MaterialUiSelectMenuStyle';
import TablePaginationActions from '../../../components/TablePagination/tablePagination';
import FinanceCategoriesUppercase from '../../../components/financeCategoriesUppercase';

const editLateFeesRateDuration = [
    {
        value: 86400,
        label: "Per Day",
    },
    {
        value: 259200,
        label: "Per Week",
    },
    {
        value: 2592000,
        label: "Per Month",
    },
]

const addAndEditDueType = [
    {
        value: "RENTAL_INCOME",
        label: "Rental Income",
    },
    {
        value: "SECURITY_DEPOSITS",
        label: "Security Deposits"
    },
    {
        value: "PARKING_INCOME",
        label: "Parking Income"
    },
    {
        value: "LAUNDRY_SERVICE_INCOME",
        label: "Laundry Service Income"
    },
]

const dueType = [
    {
        value: "OTHER_INCOME",
        label: "Other Income",
    },
    {
        value: "RENTAL_INCOME",
        label: "Rental Income",
    },
    {
        value: "SECURITY_DEPOSITS",
        label: "Security Deposits"
    },
    {
        value: "PARKING_INCOME",
        label: "Parking Income"
    },
    {
        value: "LAUNDRY_SERVICE_INCOME",
        label: "Laundry Service Income"
    },
    {
        value: "SHORT_TERM_RENTS_INCOME",
        label: "Short Term Rents Income"
    },
    {
        value: "LATE_FEES_INCOME",
        label: "Late Fees Income"
    },
    {
        value: "STORAGE_INCOME",
        label: "Storage Income"
    },
    {
        value: "MISC_INTEREST_INCOME",
        label: "Misc Interest Income"
    },
]

const expense = [
    {
        value: "OTHER_EXPENSE",
        label: "Other Expense",
    },
    {
        value: "TAXES",
        label: "Taxes",
    },
    {
        value: "INSURANCE_EXPENSE",
        label: "Insurance Expense",
    },
    {
        value: "ADVERTISING_EXPENSE",
        label: "Advertising Expense",
    },
    {
        value: "CLEANING_AND_MAINTENANCE_EXPENSE",
        label: "Cleaning and Maintenance Expense",
    },
    {
        value: "LEGAL_AND_PROFESSIONAL_SERVICE_EXPENSE",
        label: "Legal and Professional Services Expense",
    },
    {
        value: "MANAGEMENT_EXPENSE",
        label: "Management Expense",
    },
    {
        value: "MEALS_EXPENSE",
        label: "Meals Expense",
    },
    {
        value: "REPAIRS_EXPENSE",
        label: "Repairs Expense",
    },
    {
        value: "SUPPLIES_EXPENSE",
        label: "Supplies Expense",
    },
    {
        value: "TRAVEL_EXPENSE",
        label: "Travel Expense",
    },
    {
        value: "UTILITIES_EXPENSE",
        label: "Utilities Expense",
    },
    {
        value: "CAPITAL_EXPENSE",
        label: 'Capital Expense',
    },
    {
        value: "MORTGAGES_AND_LOANS_EXPENSE",
        label: 'Mortgages and Loans Expense',
    },
];

function Finance() {
    const params = useParams();
    const navigate = useNavigate();
    const moment = extendMoment(Moment);

    const [property, setProperty] = useState(null);
    const [financeBalanceEntries, setFinanceBalanceEntries] = useState(null);
    const [transactions, setTransactions] = useState([]);

    const [leaseId, setLeaseId] = useState("");

    // Add Income and Expense
    const [addExpIncomeDate, setAddExpIncomeDate] = useState(null);
    const [addExpIncomeAmount, setAddExpIncomeAmount] = useState("");
    const [addExpType, setAddExpType] = useState("");
    const [addIncomeType, setAddIncomeType] = useState("")
    const [addExpIncomeDesc, setAddExpIncomeDesc] = useState("");

    const [editDueOn, setEditDueOn] = useState(null);
    const [editAmount, setEditAmount] = useState("");
    const [editDueDesc, setEditDueDesc] = useState("");
    const [editDueType, setEditDueType] = useState("");
    const [editDueLateFeesAmount, setEditDueLateFeesAmount] = useState("");
    const [editDueLateFeesTimePeriod, setEditDueLateFeesTimePeriod] = useState(86400);
    const [editDueLateFeesGracePeriod, setEditDueLateFeesGracePeriod] = useState(0);
    const [editWaivedAmount, setEditWaivedAmount] = useState("");
    const [editDueTotalLateFees, setEditDueTotalLateFees] = useState("");
    const [editRecievedAmount, setEditRecievedAmount] = useState("");

    const [entryId, setEntryId] = useState("");
    const [recievedDate, setRecievedDate] = useState(null);
    const [recievedType, setRecievedType] = useState("RENTAL_INCOME");
    const [amountRecieved, setAmountRecieved] = useState(0);
    const [paymentReciedDesc, setPaymentRecivedDesc] = useState("");

    const [paidEntryId, setPaidEntryId] = useState("");
    const [editFinanceTransactionDate, setEditFinanceTransactionDate] = useState(null);
    const [editFinanceTransactionAmount, setEditFinanceTransactionAmount] = useState("");
    const [editFinanceTransactionExpenseType, setEditFinanceTransactionExpenseType] = useState("");
    const [editFinanceTransactionDesc, setEditFinanceTransactionDesc] = useState("");

    const [transId, setTransId] = useState("");
    const [paymentRecievedDate, setPaymentRecievedDate] = useState(null);
    const [paymentRecievedType, setPaymentRecievedType] = useState("RENTAL_INCOME");
    const [paymentRecievedAmount, setPaymentRecievedAmount] = useState("");
    const [paymentRecievedDesc, setPaymentRecievedDesc] = useState("");
    const [financeHistoryCondition, setFinanceHistoryCondition] = useState(false);
    const [paymentRemainderDate, setPaymentRemainderDate] = useState(null);

    // Add Due Dialog Box
    const [dueOn, setDueOn] = useState(null);
    const [amount, setAmount] = useState("");
    const [addDueDesc, setAddDueDesc] = useState("");
    const [addDueType, setAddDueType] = useState("RENTAL_INCOME");
    const [addDueLateFeesAmount, setAddDueLateFeesAmount] = useState("0");
    const [addDueLateFeesTimePeriod, setAddDueLateFeesTimePeriod] = useState(86400);
    const [addDueLateFeesGracePeriod, setAddDueLateFeesGracePeriod] = useState(0);

    // Dialog Box 
    const [amountPaidCondition, setAmountPaidCondition] = useState(false);
    const [dueModelCondition, setDueModelCondition] = useState(false);
    const [addPropertyModelCondition, setAddPropertyModelCondition] = useState(false);
    const [propertyExpenseModelCondition, setPropertyExpenseModelCondition] = useState(false);
    const [editPaymentRecievedModelCondition, setEditPaymentRecievedModelCondition] = useState(false);
    const [editDueModelCondition, setEditDueModelCondition] = useState(false);
    const [editFinanceTransactionModelCondition, setEditFinanceTransactionModelCondition] = useState(false);
    const [deleteFinanceTransactionModelCondition, setDeleteFinanceTransactionModelCondition] = useState(false);
    const [paymentRecievedModelCondition, setPaymentRecievedModelCondition] = useState(false);

    // Add Due Dialog Box Error
    const [dueOnError, setDueOnError] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [addDueDescError, setAddDueDescError] = useState(false);

    // Update Group Expense Attachments
    const [attachments, setAttachments] = useState([]);

    // Paginations
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [paymentHistoryPage, setPaymentHistoryPage] = useState(0);
    const [paymentHistoryrowsPerPage, setPaymentHistoryRowsPerPage] = useState(5);
    const [dueHistoryPage, setDueHistoryPage] = useState(0);
    const [dueHistoryrowsPerPage, setDueHistoryRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePaymentHistoryPage = (event, newPage) => {
        setPaymentHistoryPage(newPage);
    };

    const handleChangePaymentHistoryRowsPerPage = (event) => {
        setPaymentHistoryRowsPerPage(parseInt(event.target.value, 10));
        setPaymentHistoryPage(0);
    };

    const handleChangeDueHistoryPage = (event, newPage) => {
        setDueHistoryPage(newPage);
    };

    const handleChangeDueHistoryRowsPerPage = (event) => {
        setDueHistoryPage(parseInt(event.target.value, 10));
        setDueHistoryPage(0);
    };

    // Initial Load
    const pageLoad = async () => {
        try {
            await getManProperty(params.propertyId).then((res) => {
                let data = res?.data?.data;
                setProperty(data);
                setLeaseId(data?.currentLease);
                if (data?.currentLease) {
                    return getManagerFinanceBalanceEntries(data?.currentLease);
                }
            }).then((res) => {
                let getResponseData = res?.data?.data;
                setFinanceBalanceEntries(getResponseData);
                setPaymentRemainderDate(getResponseData?.dueReminderDate);
            })

            await getManagerFinancetransactions(params.propertyId).then((res) => {
                let data = res?.data?.data;
                let filteredData = data.filter(fil => fil.type !== "TRAVEL_MILES");
                filteredData.sort((a, b) => a.date < b.date ? 1 : -1)
                setTransactions(filteredData);
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        pageLoad();
    }, []);

    const addDueModel = () => {
        setDueModelCondition(true);
        setDueOn(null);
        setAmount("");
        setAddDueDesc("");
        setAddDueType("RENTAL_INCOME");
        setAddDueLateFeesAmount("0");
        setAddDueLateFeesTimePeriod(86400);
        setAddDueLateFeesGracePeriod(0);
    }

    const propertyExpenseModel = () => {
        setPropertyExpenseModelCondition(true);
        setAddPropertyModelCondition(true);
        setAddExpIncomeDesc("");
        setAddExpIncomeAmount("");
        setAddExpIncomeDate(null);
        setAddExpType("")
    }

    const addIncomeModel = () => {
        setPropertyExpenseModelCondition(true);
        setAddPropertyModelCondition(false);
        setAddExpIncomeDesc("");
        setAddExpIncomeAmount("");
        setAddExpIncomeDate(null);
        setAddIncomeType("")
    }

    const handleAttachmentChange = (e) => {
        setAttachments([...attachments, ...e.target.files]);
    }

    const removeAttachMents = (index) => {
        let toDelete;
        const newAttachments = attachments.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });
        setAttachments(newAttachments);
    };

    const handleWaivedChange = (e, index) => {
        const newData = { ...financeBalanceEntries };
        newData.dueEntries[index].amountWaived = Number(e.target.value);
        setFinanceBalanceEntries(newData);
    }

    const editDueUpdateClick = () => {
        let data = {
            amount: Number(editAmount),
            amountWaived: Number(editWaivedAmount),
            date: editDueOn,
            description: editDueDesc,
            lateFeesGraceDuration: editDueLateFeesGracePeriod,
            lateFeesRateAmount: Number(editDueLateFeesAmount),
            lateFeesRateDuration: editDueLateFeesTimePeriod,
            resolvingTransactionDefaultType: editDueType,
        }

        patchManagerFinanceBalanceDueEntries(leaseId, entryId, data).then((res) => {
            pageLoad();
            setEditDueModelCondition(false);
        })
    }

    const deleteDueClick = () => {
        deleteManagerFinanceBalanceDueEntries(leaseId, entryId).then((res) => {
            setEditDueModelCondition(false);
            pageLoad();
        })
    }

    const editPaymentUpdateClick = () => {
        let data = {
            amount: Number(amountRecieved),
            date: recievedDate,
            description: paymentReciedDesc,
            resolvingTransactionDefaultType: recievedType,
        }
        patchManagerFinanceBalancePaidEntries(leaseId, paidEntryId, data).then((res) => {
            pageLoad();
            setEditPaymentRecievedModelCondition(false);
        })
    }

    const deletePaidEntry = () => {
        deleteManagerFinanceBalancePaidEntries(leaseId, paidEntryId).then((res) => {
            pageLoad();
            setEditPaymentRecievedModelCondition(false);
        })
    }

    const editUpdateTransactionClick = () => {
        let array = [];
        attachments.map((file) => {
            if (file.url) {
                array.push(file)
            }
        })
        let formData = new FormData();
        formData.append("amount", Number(editFinanceTransactionAmount));
        formData.append("description", editFinanceTransactionDesc);
        formData.append("date", editFinanceTransactionDate);
        formData.append("type", editFinanceTransactionExpenseType);
        formData.append("existingReceipts", JSON.stringify(array))
        Array.from(attachments).forEach(item => { formData.append("newReceipts", item) });
        patchFinanceTransactions(params.propertyId, transId, formData).then((res) => {
            pageLoad();
            setEditFinanceTransactionModelCondition(false);
        })
    }

    const DeleteTransactionClick = () => {
        deleteFinanceTransactions(params.propertyId, transId).then((res) => {
            pageLoad();
            setEditFinanceTransactionModelCondition(false);
        })
    }

    const paymentRecievedUpdateClicked = async () => {
        let result = financeBalanceEntries?.dueEntries.map(({ amountWaived, id }) => ({ amountWaived, id }));
        let data = {
            paidAmount: Number(paymentRecievedAmount),
            paidDate: paymentRecievedDate,
            paidDescription: paymentRecievedDesc,
            resolveDueEntries: result,
            resolvingTransactionType: paymentRecievedType,
        }
        await postManagerFinanceBalancePaidEntries(leaseId, data).then((res) => {
            setPaymentRecievedModelCondition(false);
            pageLoad();
        })
    }

    const handleAddDueInputChange = (field, value) => {
        switch (field) {
            case 'dueOn':
                setDueOn(value);
                setDueOnError(value === null);
                break;
            case 'amount':
                const numericValue = value.replace(/[^0-9.]/g, '');
                setAmount(numericValue);
                setAmountError(Number(numericValue) === 0);
                break;
            case 'addDueDesc':
                setAddDueDesc(value);
                setAddDueDescError(value === "");
                break;
            case 'type':
                setAddDueType(value);
                break;
            case 'lateFeesAmount':
                const numeric = value.replace(/[^0-9.]/g, '');
                setAddDueLateFeesAmount(numeric);
                break;
            case 'LatefeesTimePeriod':
                setAddDueLateFeesTimePeriod(value);
                break;
            case 'LateFeesGracePeriod':
                setAddDueLateFeesGracePeriod(value);
                break;
            default:
                break;
        }
    };

    const addDueClick = async () => {
        if (!dueOn || Number(amount) === 0 || addDueDesc === "") {
            setDueOnError(!dueOn);
            setAmountError(Number(amount) === 0);
            setAddDueDescError(addDueDesc === "");
            return;
        }
        let data = {
            amount: Number(amount) == 0 ? null : Number(amount),
            date: dueOn,
            description: addDueDesc,
            dueTo: "MANAGER",
            lateFeesGraceDuration: Number(addDueLateFeesGracePeriod),
            lateFeesRateAmount: Number(addDueLateFeesAmount),
            lateFeesRateDuration: addDueLateFeesTimePeriod,
            resolvingTransactionDefaultType: addDueType,
        }
        await postManagerFinanceBalanceDueEntries(leaseId, data).then((res) => {
            pageLoad();
            setDueModelCondition(false);
        })
    }

    const addPropertyExpensesClick = () => {
        let data = {
            amount: Number(addExpIncomeAmount),
            date: addExpIncomeDate,
            description: addExpIncomeDesc,
            sourceType: 1,
            type: addExpType,
        }
        postManagerAddExpenses(params.propertyId, data).then((res) => {
            pageLoad();
            setPropertyExpenseModelCondition(false);
        })
    }

    const addPropertyIncomeClick = () => {
        let data = {
            amount: Number(addExpIncomeAmount),
            date: addExpIncomeDate,
            description: addExpIncomeDesc,
            sourceType: 1,
            type: addIncomeType,
        }
        postManagerAddExpenses(params.propertyId, data).then((res) => {
            pageLoad();
            setPropertyExpenseModelCondition(false);
        })
    }

    const paymentremainderDateUpdate = (date) => {
        let data = {
            reminderDate: date
        }
        patchPaymentRemainderDate(leaseId, data).then((res) => {

        })
    }
    const paymentremainderDateclearClick = (clear) => {
        let data = {
            reminderDate: clear
        }
        patchPaymentRemainderDate(leaseId, data).then((res) => {
            pageLoad();
        })
    }

    const financeHistorytypeColor = (type) => {
        let categories = [
            "RENTAL_INCOME",
            "OTHER_INCOME",
            "PARKING_INCOME",
            "LAUNDRY_SERVICE_INCOME",
            "SHORT_TERM_RENTS_INCOME",
            "LATE_FEES_INCOME",
            "STORAGE_INCOME",
            "MISC_INTEREST_INCOME",
        ];
        if (categories.includes(type)) {
            return 'finance-view-details-green-color';
        }
        else if (type === "SECURITY_DEPOSITS") {
            return "finance-view-details-blue-color"
        }
        else {
            return 'finance-view-details-red-color';
        }
    }

    const renderDueType = (type) => {
        return type.map((due, index) => (
            <MenuItem key={index} value={due.value}>{due.label}</MenuItem>
        ));
    };

    const renderExpenseMenuType = (exp) => {
        return exp.map((exp, index) => (
            <MenuItem key={index} value={exp.value}>{exp.label}</MenuItem>
        ))
    }

    const renderEditFinanceType = (transactionIncome, transactionExpense) => {
        let types = [...transactionExpense, ...transactionIncome];
        return types.map((exp, index) => (
            <MenuItem key={index} value={exp.value}>{exp.label}</MenuItem>
        ))
    }

    const getDueEntriesTotal = (dueEntries, status) => {
        if (status == "totalAmountUnresolved") {
            let totalAmountUnresolved = 0
            if (dueEntries) {
                dueEntries.forEach((amount) => {
                    totalAmountUnresolved += amount?.amountUnresolved
                })
            }
            return totalAmountUnresolved.toFixed(2);
        }
        if (status == "totalAmount") {
            let totalAmount = 0
            if (dueEntries) {
                dueEntries.forEach((amount) => {
                    totalAmount += amount?.amount
                })
            }
            return totalAmount.toFixed(2);
        }
        if (status == "totalLateFees") {
            let totalLateFees = 0
            if (dueEntries) {
                dueEntries.forEach((amount) => {
                    totalLateFees += amount?.lateFees
                })
            }
            return totalLateFees.toFixed(2);
        }
        if (status == "totalAmountResolved") {
            let totalAmountResolved = 0
            if (dueEntries) {
                dueEntries.forEach((amount) => {
                    totalAmountResolved += amount?.amountResolved
                })
            }
            return totalAmountResolved.toFixed(2);
        }
        if (status == "totalAmountWaived") {
            let totalAmountWaived = 0
            if (dueEntries) {
                dueEntries.forEach((amount) => {
                    totalAmountWaived += amount?.amountWaived
                })
            }
            return totalAmountWaived.toFixed(2);
        }
        if (status == "totalAmoutUnresolved") {
            let totalAmoutUnresolved = 0
            if (dueEntries) {
                dueEntries.forEach((amount) => {
                    totalAmoutUnresolved += amount?.amountUnresolved
                })
            }
            return totalAmoutUnresolved.toFixed(2);
        }
    }

    const getPaidEntriesTotal = (paidEntries, status) => {
        if (status == "advanceRecieved") {
            let totalAmountUnresolved = 0
            if (paidEntries) {
                paidEntries.forEach((amount) => {
                    totalAmountUnresolved += amount?.amountUnresolved
                })
            }
            return totalAmountUnresolved.toFixed(2);
        }
        if (status == "amountResolved") {
            let totalamountResolved = 0
            if (paidEntries) {
                paidEntries.forEach((amount) => {
                    totalamountResolved += amount?.amountResolved
                })
            }
            return totalamountResolved.toFixed(2);
        }
    }

    const getRemainingDue = (dueEntries) => {
        let total = 0
        if (dueEntries) {
            dueEntries.forEach((amount) => {
                total += amount?.amount + amount?.lateFees - amount?.amountResolved - amount?.amountWaived
            })
        }
        return (total - Number(paymentRecievedAmount)).toFixed(2);
    }

    const sendRentNoticeClick = (e) => {
        navigate(`/manager/notice/prepare/${null}`);
    };

    return (
        <div style={{ padding: '30px' }}>
            <p style={{ fontSize: 25, textAlign: "center", fontWeight: "bold" }}>{property?.address?.line1 + "," + property?.address?.line2 + " " + property?.address?.unitNumber}</p>
            <TabMenu />
            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: 'wrap', justifyContent: "space-between", margin: '20px 0px 20px 0px' }}>
                <span style={{ fontSize: 15, fontWeight: "bolder" }}>Lease Finance</span>
                <Button color="primary" sx={{ borderRadius: 35, fontSize: 15 }} variant="outlined" onClick={(e) => sendRentNoticeClick(e)}>SEND RENT NOTICE</Button>
            </div>
            <div style={{ display: "flex", justifyContent: "right", margin: '20px 0px 20px 0px' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        views={["day", "month", "year"]}
                        label="Payment Reminder"
                        value={paymentRemainderDate}
                        onChange={(newValue) => {
                            setPaymentRemainderDate(newValue);
                            paymentremainderDateUpdate(newValue);
                        }}
                        renderInput={(params) => <TextField color="warning"  {...params} />}
                        slotProps={{
                            field: { clearable: true, onClear: () => paymentremainderDateclearClick(null) },
                        }}
                    />
                </LocalizationProvider>
            </div>
            <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box", margin: '20px 0px 20px 0px' }}>
                <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box", width: "100%", marginLeft: "3px", marginRight: "3px" }}>
                    <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "5px", paddingTop: "5px" }}>
                        <p style={{ fontSize: 13 }}>
                            Current Due:<span style={{ float: "right", fontWeight: "bold" }}>
                                $ {getDueEntriesTotal(financeBalanceEntries?.dueEntries, "totalAmountUnresolved")}
                            </span>
                        </p>
                    </div>
                    <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "10px", paddingTop: "10px" }}>
                        <p style={{ fontSize: 13 }}>
                            Advance Received:<span style={{ float: "right", fontWeight: "bold" }}>
                                $ {getPaidEntriesTotal(financeBalanceEntries?.paidEntries, "advanceRecieved")}
                            </span>
                        </p>
                    </div>
                    <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "10px", paddingTop: "10px" }}>
                        <p style={{ fontSize: 13 }}>
                            Security Deposit:<span style={{ float: "right", fontWeight: "bold" }}>
                                $ {parseFloat(financeBalanceEntries?.securityDepositAmount).toFixed(2)}
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            {/* Due Amount and Due History table and Add Due button */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10, flexWrap: 'wrap', alignItems: 'center', margin: '20px 0px 20px 0px' }}>
                <span style={{ display: 'flex', flexDirection: 'row', gap: 10, flexWrap: 'wrap', alignItems: 'center' }} >
                    <span style={{ fontSize: 15, fontWeight: "bolder" }}>
                        {amountPaidCondition ? 'Due History' : 'Amount Due'}
                    </span>
                    <span style={{ fontSize: 13, color: '#1976d2', textDecoration: "underline", cursor: 'pointer' }} onClick={() => setAmountPaidCondition(!amountPaidCondition)}>
                        {amountPaidCondition ? 'View Current Amount Due Only' : 'View Amount Due History'}
                    </span>
                </span>
                {!amountPaidCondition && (
                    <Button color="error" sx={{ fontSize: 15 }} variant="outlined" onClick={addDueModel}>
                        ADD DUE
                    </Button>
                )}
            </div>

            {/* Due Amount and Due History table */}
            {amountPaidCondition === false ? (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Description</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Type</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Due Date</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Due Amount</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Late Fee Rate</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Late Fees</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Amount Received</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Amount Waived</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Total Due</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {financeBalanceEntries?.dueEntries.sort((a, b) => a.date < b.date ? 1 : -1).map((e, i) => (
                                <TableRow key={i}>
                                    <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{e?.description}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>{FinanceCategoriesUppercase(e?.resolvingTransactionDefaultType)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>{e?.date ? moment(e?.date).format('MMM D, YYYY') : ""}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>$ {parseFloat(e?.amount).toFixed(2)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>$ {parseFloat(e?.lateFeesRateAmount).toFixed(2)} / Day</TableCell>
                                    <TableCell className='tableCell-maxWidth'>$ {parseFloat(e?.lateFees).toFixed(2)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>$ {parseFloat(e?.amountResolved).toFixed(2)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>$ {parseFloat(e?.amountWaived).toFixed(2)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>$ {parseFloat(e?.amountUnresolved).toFixed(2)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'><EditIcon color="primary" style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setEditDueModelCondition(true);
                                            setEntryId(e?.id);
                                            setEditDueOn(e?.date);
                                            setEditAmount(e?.amount);
                                            setEditDueDesc(e?.description);
                                            setEditDueType(e?.resolvingTransactionDefaultType);
                                            setEditDueLateFeesAmount(e?.lateFeesRateAmount);
                                            setEditDueTotalLateFees(e?.lateFees + e?.lateFeesRateAmount);
                                            setEditDueLateFeesTimePeriod(e?.lateFeesRateDuration);
                                            setEditDueLateFeesGracePeriod(e.lateFeesGraceDuration);
                                            setEditRecievedAmount(e?.amountResolved);
                                            setEditWaivedAmount(e?.amountWaived);
                                        }}
                                    />
                                    </TableCell>
                                </TableRow>))}
                            <TableRow>
                                <TableCell className='tableCell-maxWidth' component="th" stylescope="row" ></TableCell>
                                <TableCell className='tableCell-maxWidth'></TableCell>
                                <TableCell className='tableCell-maxWidth' style={{ fontWeight: "bold" }}>Total</TableCell>
                                <TableCell className='tableCell-maxWidth' style={{ fontWeight: "bold" }}>
                                    $ {getDueEntriesTotal(financeBalanceEntries?.dueEntries, "totalAmount")}
                                </TableCell>
                                <TableCell className='tableCell-maxWidth'></TableCell>
                                <TableCell className='tableCell-maxWidth' style={{ fontWeight: "bold" }}>
                                    $ {getDueEntriesTotal(financeBalanceEntries?.dueEntries, "totalLateFees")}
                                </TableCell>
                                <TableCell className='tableCell-maxWidth' style={{ fontWeight: "bold" }}>
                                    $ {getDueEntriesTotal(financeBalanceEntries?.dueEntries, "totalAmountResolved")}
                                </TableCell>
                                <TableCell className='tableCell-maxWidth' style={{ fontWeight: "bold" }}>
                                    $ {getDueEntriesTotal(financeBalanceEntries?.dueEntries, "totalAmountWaived")}
                                </TableCell>
                                <TableCell className='tableCell-maxWidth' style={{ fontWeight: "bold" }}>
                                    $ {getDueEntriesTotal(financeBalanceEntries?.dueEntries, "totalAmoutUnresolved")}
                                </TableCell>
                                <TableCell className='tableCell-maxWidth'>
                                    <Button
                                        color="success"
                                        variant="outlined"
                                        sx={{ fontSize: 15 }}
                                        onClick={() => {
                                            setPaymentRecievedModelCondition(true);
                                            setPaymentRecievedDate(new Date())
                                        }}
                                    >$ RECEIVED
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Description</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Type</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Date</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Amount Paid</TableCell>
                                <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {financeBalanceEntries?.paidEntries.sort((a, b) => a.date < b.date ? 1 : -1).slice(dueHistoryPage * dueHistoryrowsPerPage, dueHistoryPage * dueHistoryrowsPerPage + dueHistoryrowsPerPage).map((e, i) => (
                                <TableRow key={i}>
                                    <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{e?.description}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>{FinanceCategoriesUppercase(e?.resolvingTransactionDefaultType)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>{e.date ? moment(e?.date).format('MMM D, YYYY') : ""}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>$ {parseFloat(e?.amountResolved).toFixed(2)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'><EditIcon color="primary"
                                        onClick={() => {
                                            setEditPaymentRecievedModelCondition(true);
                                            setRecievedDate(e?.date);
                                            setRecievedType(e?.resolvingTransactionDefaultType);
                                            setAmountRecieved(e?.amount);
                                            setPaymentRecivedDesc(e?.description);
                                            setPaidEntryId(e?.id);
                                        }} /></TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell className='tableCell-maxWidth' component="th" stylescope="row" style={{ fontWeight: "bold" }}>Total</TableCell>
                                <TableCell className='tableCell-maxWidth'></TableCell>
                                <TableCell className='tableCell-maxWidth' style={{ fontWeight: "bold" }}>
                                    $ {getPaidEntriesTotal(financeBalanceEntries?.paidEntries, "amountResolved")}
                                </TableCell>
                                <TableCell className='tableCell-maxWidth'></TableCell>
                                <TableCell className='tableCell-maxWidth'>
                                    <Button color="success" sx={{ fontSize: 15 }} variant="outlined" onClick={() => { setPaymentRecievedModelCondition(true); }}>$ RECEIVED</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <TablePagination
                        sx={{
                            '.css-pdct74-MuiTablePagination-selectLabel': {
                                marginBottom: "0px",
                                color: '#1976d2'
                            },
                            '.css-levciy-MuiTablePagination-displayedRows': {
                                marginBottom: "0px",
                                color: '#1976d2'
                            }
                        }}
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={financeBalanceEntries?.paidEntries.length}
                        rowsPerPage={dueHistoryrowsPerPage}
                        page={dueHistoryPage}
                        onPageChange={handleChangeDueHistoryPage}
                        onRowsPerPageChange={handleChangeDueHistoryRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableContainer>
            )}

            {/* Finance and Payment Table Header */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10, alignItems: 'center', flexWrap: 'wrap', margin: '20px 0px 20px 0px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', flexWrap: 'wrap' }}>
                    <span style={{ fontSize: 15, fontWeight: "bolder" }}>
                        {financeHistoryCondition ? 'Payment' : 'Finance'} History
                    </span>
                    <span style={{ fontSize: 13, color: '#1976d2', textDecoration: "underline", cursor: 'pointer' }} onClick={() => { setFinanceHistoryCondition(!financeHistoryCondition) }}>
                        {financeHistoryCondition ? 'View Finance' : 'View Payment'}{" "}History{" "}{financeHistoryCondition ? '' : 'Only'}
                    </span>
                </div>
                {/* Income and Expense add open dialog Buttons */}
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 10, flexWrap: "wrap" }}>
                    <Button color="error" variant="outlined" sx={{ fontSize: 15 }} onClick={propertyExpenseModel}>ADD EXPENSE</Button>
                    <Button color="success" variant="outlined" sx={{ fontSize: 15 }} onClick={addIncomeModel}>ADD INCOME</Button>
                </div>
            </div>

            {/* Finance and Payment Table */}
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>ID</TableCell>
                            <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Date</TableCell>
                            <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Description</TableCell>
                            <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Type</TableCell>
                            <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Reciepts</TableCell>
                            <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}>Amount</TableCell>
                            <TableCell className='tableCell-maxWidth' sx={{ color: "grey" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    {financeHistoryCondition == false ? (
                        <TableBody>
                            {transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e, i) => (
                                <TableRow key={i}>
                                    <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{e?.id}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>{e?.date ? moment(e?.date).format('MMM D, YYYY') : ""}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>{e?.description}</TableCell>
                                    <TableCell className='tableCell-maxWidth'><span className={financeHistorytypeColor(e?.type)}>{e?.type}</span></TableCell>
                                    <TableCell className='tableCell-maxWidth'>
                                        {e?.receipts.map((rec, index) => (
                                            <span key={index} onClick={(e) => imageClick(e, rec?.url)} className='cursor'>
                                                {rec?.fileName},{' '}
                                            </span>
                                        ))}

                                    </TableCell>
                                    <TableCell className='tableCell-maxWidth'>{parseFloat(e?.amount).toFixed(2)}</TableCell>
                                    <TableCell className='tableCell-maxWidth'>
                                        {[1, 4].includes(e?.sourceType) ?
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 20, flexWrap: 'wrap', alignItems: 'center' }}>
                                                <EditIcon color="primary" sx={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setEditFinanceTransactionModelCondition(true);
                                                        setDeleteFinanceTransactionModelCondition(false);
                                                        setEditFinanceTransactionDate(e?.date);
                                                        setEditFinanceTransactionDesc(e?.description);
                                                        setEditFinanceTransactionExpenseType(e?.type);
                                                        setEditFinanceTransactionAmount(e?.amount);
                                                        setTransId(e?.id);
                                                        setAttachments(e?.receipts);
                                                    }} />
                                                <DeleteIcon color="error" sx={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setEditFinanceTransactionModelCondition(true);
                                                        setDeleteFinanceTransactionModelCondition(true);
                                                        setEditFinanceTransactionDate(e?.date);
                                                        setEditFinanceTransactionDesc(e?.description);
                                                        setEditFinanceTransactionExpenseType(e?.type);
                                                        setEditFinanceTransactionAmount(e?.amount);
                                                        setTransId(e?.id);
                                                        setAttachments(e?.receipts);
                                                    }} />
                                            </div>
                                            : ""}
                                        {e?.sourceType === 2 ?
                                            <div>
                                                <ReceiptIcon color="primary"
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setEditPaymentRecievedModelCondition(true);
                                                        setRecievedDate(e?.date);
                                                        setRecievedType(e?.type);
                                                        setAmountRecieved(e?.amount);
                                                        setPaymentRecivedDesc(e?.description);
                                                        setPaidEntryId(e?.sourceId);
                                                    }} />
                                            </div>
                                            : ""}
                                        {e?.sourceType === 3 ?
                                            <AssignmentIcon color="primary" style={{ cursor: 'pointer' }}
                                                onClick={() => { navigate(`/manager/service-requests/${e?.sourceId}`) }}
                                            />
                                            : ""}
                                    </TableCell>
                                </TableRow>))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            {transactions.filter(filter => filter?.type === "RENTAL_INCOME"
                                || filter?.type === "OTHER_INCOME"
                                || filter?.type === "PARKING_INCOME"
                                || filter?.type === "LAUNDRY_SERVICE_INCOME"
                                || filter?.type === "SHORT_TERM_RENTS_INCOME"
                                || filter?.type === "LATE_FEES_INCOME"
                                || filter?.type === "STORAGE_INCOME"
                                || filter?.type === "MISC_INTEREST_INCOME").slice(paymentHistoryPage * paymentHistoryrowsPerPage, paymentHistoryPage * paymentHistoryrowsPerPage + paymentHistoryrowsPerPage).map((e, i) => (
                                    <TableRow key={i}>
                                        <TableCell className='tableCell-maxWidth' component="th" stylescope="row" >{e?.id}</TableCell>
                                        <TableCell className='tableCell-maxWidth'>{e?.date ? moment(e?.date).format('MMM D, YYYY') : ""}</TableCell>
                                        <TableCell className='tableCell-maxWidth'>{e?.description}</TableCell>
                                        <TableCell className='tableCell-maxWidth'><span className={financeHistorytypeColor(e?.type)}>{e?.type}</span></TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            {e?.receipts.map((rec, index) => (
                                                <span key={index} onClick={(e) => imageClick(e, rec?.url)} className='cursor'>
                                                    {rec?.fileName},{' '}
                                                </span>
                                            ))}

                                        </TableCell>
                                        <TableCell className='tableCell-maxWidth'>{parseFloat(e?.amount).toFixed(2)}</TableCell>
                                        <TableCell className='tableCell-maxWidth'>
                                            {[1, 4].includes(e?.sourceType) ?
                                                <div style={{ display: 'flex', flexDirection: 'row', gap: 20, flexWrap: 'wrap', alignItems: 'center' }}>
                                                    <EditIcon color="primary" onClick={() => {
                                                        setEditFinanceTransactionModelCondition(true);
                                                        setDeleteFinanceTransactionModelCondition(false);
                                                        setEditFinanceTransactionDate(e?.date);
                                                        setEditFinanceTransactionDesc(e?.description);
                                                        setEditFinanceTransactionExpenseType(e?.type);
                                                        setEditFinanceTransactionAmount(e?.amount);
                                                        setTransId(e?.id);
                                                    }} />
                                                    <DeleteIcon color="error"
                                                        onClick={() => {
                                                            setEditFinanceTransactionModelCondition(true);
                                                            setDeleteFinanceTransactionModelCondition(true);
                                                            setEditFinanceTransactionDate(e?.date);
                                                            setEditFinanceTransactionDesc(e?.description);
                                                            setEditFinanceTransactionExpenseType(e?.type);
                                                            setEditFinanceTransactionAmount(e?.amount);
                                                            setTransId(e?.id);
                                                        }} />
                                                </div>
                                                : ""}
                                            {e?.sourceType === 2 ?
                                                <div>
                                                    <ReceiptIcon color="primary"
                                                        onClick={() => {
                                                            setEditPaymentRecievedModelCondition(true);
                                                            setRecievedDate(e?.date);
                                                            setRecievedType(e?.type);
                                                            setAmountRecieved(e?.amount);
                                                            setPaymentRecivedDesc(e?.description);
                                                            setPaidEntryId(e?.sourceId);
                                                        }} />
                                                </div>
                                                : ""}
                                            {e?.sourceType === 3 ?
                                                <AssignmentIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => { navigate(`/manager/service-requests/${e?.sourceId}`) }} />
                                                : ""}
                                        </TableCell>
                                    </TableRow>))}
                        </TableBody>)}
                </Table>
                {financeHistoryCondition == false ? (
                    <TablePagination
                        sx={{
                            '.css-pdct74-MuiTablePagination-selectLabel': {
                                marginBottom: "0px",
                                color: '#1976d2'
                            },
                            '.css-levciy-MuiTablePagination-displayedRows': {
                                marginBottom: "0px",
                                color: '#1976d2'
                            }
                        }}
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={transactions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                ) : (
                    <TablePagination
                        sx={{
                            '.css-pdct74-MuiTablePagination-selectLabel': {
                                marginBottom: "0px",
                                color: '#1976d2'
                            },
                            '.css-levciy-MuiTablePagination-displayedRows': {
                                marginBottom: "0px",
                                color: '#1976d2'
                            }
                        }}
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={transactions.filter(filter => filter?.type === "RENTAL_INCOME"
                            || filter?.type === "OTHER_INCOME"
                            || filter?.type === "PARKING_INCOME"
                            || filter?.type === "LAUNDRY_SERVICE_INCOME"
                            || filter?.type === "SHORT_TERM_RENTS_INCOME"
                            || filter?.type === "LATE_FEES_INCOME"
                            || filter?.type === "STORAGE_INCOME"
                            || filter?.type === "MISC_INTEREST_INCOME").length}
                        rowsPerPage={paymentHistoryrowsPerPage}
                        page={paymentHistoryPage}
                        onPageChange={handleChangePaymentHistoryPage}
                        onRowsPerPageChange={handleChangePaymentHistoryRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                )}
            </TableContainer>

            {/* Add Income and Expense Dialog Box */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={propertyExpenseModelCondition}
                onClose={() => setPropertyExpenseModelCondition(false)}>
                <DialogTitle>
                    {addPropertyModelCondition === true ?
                        <p style={{ textAlign: "center", fontSize: 17, fontWeight: "bolder", }}>Add Property Expense</p>
                        :
                        <p style={{ textAlign: "center", fontSize: 17, fontWeight: "bolder", }}>Add Property Income</p>
                    }
                </DialogTitle>
                <DialogContent>
                    <div className='finance-add-due-dialog-box-textfield-flex '>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day"]}
                                    label="Date *"
                                    value={addExpIncomeDate}
                                    onChange={(newValue) => {
                                        setAddExpIncomeDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField color="warning"  {...params} fullWidth={true} sx={{ my: 2 }} />} />

                            </LocalizationProvider>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount USD *</InputLabel>
                                <OutlinedInput
                                    fullWidth={true}
                                    label="Amount USD *"
                                    type="number"
                                    variant="outlined"
                                    value={addExpIncomeAmount}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                        setAddExpIncomeAmount(numericValue)
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric', // Restrict input to numeric charactersinputMode: 'numeric', // Restrict input to numeric characters
                                        pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbersattern: '[0-9]*', // Pattern to allow only numeric characters
                                    }}
                                    startAdornment={<InputAdornment position="start"><Icon style={{ color: "black" }}>$</Icon></InputAdornment>}
                                /></FormControl>
                        </div>
                        {addPropertyModelCondition === true ?
                            <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                                <FormControl fullWidth={true} sx={{ my: 2 }}>
                                    <InputLabel id="demo-simple-select-label">Expense Type *</InputLabel>
                                    <Select
                                        label={"Expense Type *"}
                                        fullWidth={true}
                                        MenuProps={MenuProps}
                                        variant="outlined"
                                        value={addExpType}
                                        onChange={e => { setAddExpType(e.target.value) }}
                                        input={<OutlinedInput label="Expense Type *" />}
                                    >
                                        {renderExpenseMenuType(expense)}
                                    </Select>
                                </FormControl> </div> :
                            <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                                <FormControl fullWidth={true} sx={{ my: 2 }}>
                                    <InputLabel id="demo-simple-select-label">Income Type *</InputLabel>
                                    <Select
                                        MenuProps={MenuProps}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={addIncomeType}
                                        onChange={e => { setAddIncomeType(e.target.value) }}
                                        input={<OutlinedInput label="Income Type *" />}
                                    >
                                        {renderDueType(dueType)}
                                    </Select>
                                </FormControl>
                            </div>}
                    </div>
                    <div>
                        <TextField
                            sx={{ mb: 2 }}
                            id="outlined-basic"
                            label="Description *"
                            variant="outlined"
                            fullWidth={true}
                            value={addExpIncomeDesc}
                            onChange={e => { setAddExpIncomeDesc(e.target.value) }} />
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex-action-button'>
                        <Button
                            type="submit"
                            sx={{ fontSize: 15 }}
                            onClick={() => setPropertyExpenseModelCondition(false)}
                        >
                            CANCEL
                        </Button>
                        {addPropertyModelCondition === true ?
                            <Button
                                disabled={addExpIncomeDate === null || Number(addExpIncomeAmount) === 0 || addExpType === "" || addExpIncomeDesc === "" ? true : false}
                                sx={{ fontSize: 15 }}
                                color="error"
                                variant="contained"
                                onClick={addPropertyExpensesClick}
                            >ADD EXPENSE
                            </Button>
                            :
                            <Button
                                disabled={Number(addExpIncomeAmount) === 0 || addExpIncomeDate === null || addIncomeType === "" || addExpIncomeDesc === "" ? true : false}
                                sx={{ fontSize: 15 }}
                                variant="contained"
                                color='error'
                                onClick={addPropertyIncomeClick}
                            >ADD Income
                            </Button>
                        }
                    </div>
                </DialogContent>
            </Dialog>

            {/* Add Due Dialog */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={dueModelCondition}
                onClose={() => setDueModelCondition(false)}>
                <DialogTitle textAlign={"center"}>Add Due</DialogTitle>
                <DialogContent>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-add-due-dialog-box-textfield-flex-width'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day"]}
                                    label="Due On *"
                                    value={dueOn}
                                    onChange={(newValue) => handleAddDueInputChange('dueOn', newValue)}
                                    renderInput={(params) => <TextField color="warning"  {...params} fullWidth={true} error={dueOnError} sx={{ my: 2 }} />} />
                            </LocalizationProvider>
                        </div>
                        <div className='finance-add-due-dialog-box-textfield-flex-width'>
                            <TextField
                                sx={{ my: 2 }}
                                id="outlined-basic"
                                label="Amount *"
                                variant="outlined"
                                fullWidth={true}
                                value={amount}
                                onChange={(e) => handleAddDueInputChange('amount', e.target.value)}
                                placeholder="USD"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon sx={{ color: "black" }}>$</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                                error={amountError}
                                inputProps={{
                                    inputMode: 'numeric', // Restrict input to numeric characters
                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbersers
                                }}
                            />
                        </div>
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-add-due-dialog-box-textfield-flex-width-description'>
                            <TextField
                                id="outlined-basic"
                                label="Description *"
                                variant="outlined"
                                fullWidth={true}
                                sx={{ mb: 2 }}
                                value={addDueDesc}
                                onChange={(e) => handleAddDueInputChange('addDueDesc', e.target.value)}
                                error={addDueDescError}
                            />
                        </div>
                        <div className='finance-add-due-dialog-box-textfield-flex-width-type'>
                            <FormControl fullWidth={true}>
                                <InputLabel id="demo-simple-select-label">Type *</InputLabel>
                                <Select
                                    sx={{ mb: 2 }}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                    value={addDueType}
                                    onChange={(e) => handleAddDueInputChange('type', e.target.value)}
                                    input={<OutlinedInput label="Type *" />}
                                >
                                    {renderDueType(addAndEditDueType)}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-add-due-dialog-box-textfield-flex-width-type'>
                            <TextField
                                fullWidth={true}
                                sx={{ mb: 2 }}
                                id="outlined-basic"
                                label="Late Fees Amount"
                                placeholder='USD'
                                variant="outlined"
                                value={addDueLateFeesAmount}
                                onChange={(e) => handleAddDueInputChange('lateFeesAmount', e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon sx={{ color: "black" }}>
                                                $
                                            </Icon>
                                        </InputAdornment>
                                    )
                                }}
                                inputProps={{
                                    inputMode: 'numeric', // Restrict input to numeric characters
                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbersers
                                }} />
                        </div>
                        <div className='finance-add-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ mb: 2 }}>
                                <TextField
                                    label="Late fees Time Period"
                                    select
                                    variant="outlined"
                                    value={addDueLateFeesTimePeriod}
                                    onChange={(e) => handleAddDueInputChange('LatefeesTimePeriod', e.target.value)}
                                >
                                    <MenuItem value={86400}>Per Day</MenuItem>
                                </TextField>
                            </FormControl>
                        </div>
                        <div className='finance-add-due-dialog-box-textfield-flex-width-type'>
                            <TextField
                                sx={{ mb: 2 }}
                                id="outlined-basic"
                                label="Late Fees Grace Period"
                                placeholder='Number of Days'
                                variant="outlined"
                                value={addDueLateFeesGracePeriod}
                                onChange={(e) => handleAddDueInputChange('LateFeesGracePeriod', e.target.value)}
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon sx={{ color: "black", justifyContent: "space-between", minWidth: "50px" }}>
                                                Days
                                            </Icon>
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex-action-button'>
                        <Button
                            sx={{ fontSize: 15 }}
                            color="success"
                            variant="contained"
                            onClick={addDueClick}
                        >ADD DUE
                        </Button>
                        <Button
                            sx={{ fontSize: 15 }}
                            color="error"
                            type="submit"
                            onClick={() => setDueModelCondition(false)}
                        >CANCEL
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Edit Due Dialog */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={editDueModelCondition}
                onClose={() => setEditDueModelCondition(false)}>
                <DialogTitle textAlign={"center"}>Edit Due</DialogTitle>
                <DialogContent>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-add-due-dialog-box-textfield-flex-width'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day"]}
                                    label="Due On *"
                                    value={editDueOn}
                                    onChange={(newValue) => {
                                        setEditDueOn(newValue);
                                    }}
                                    renderInput={(params) => <TextField color="warning"  {...params} fullWidth={true} sx={{ my: 2 }} />} />
                            </LocalizationProvider>
                        </div>
                        <div className='finance-add-due-dialog-box-textfield-flex-width'>
                            <TextField
                                sx={{ my: 2 }}
                                id="outlined-basic"
                                label="Amount *"
                                variant="outlined"
                                fullWidth={true}
                                value={editAmount}
                                onChange={(e) => {
                                    const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                    setEditAmount(numericValue);
                                }}
                                inputProps={{
                                    inputMode: 'numeric', // Restrict input to numeric characters
                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon sx={{ color: "black" }}>
                                                $
                                            </Icon>
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-add-due-dialog-box-textfield-flex-width-description'>
                            <TextField
                                sx={{ mb: 2 }}
                                id="outlined-basic"
                                label="Description *"
                                variant="outlined"
                                fullWidth={true}
                                value={editDueDesc}
                                onChange={(e) => {
                                    setEditDueDesc(e.target.value);
                                }} />
                        </div>
                        <div className='finance-add-due-dialog-box-textfield-flex-width-type'>
                            <FormControl fullWidth={true} sx={{ mb: 2 }}>
                                <InputLabel id="demo-simple-select-label">Type *</InputLabel>
                                <Select
                                    fullWidth={true}
                                    label="Type *"
                                    variant="outlined"
                                    MenuProps={MenuProps}
                                    value={editDueType}
                                    onChange={e => { setEditDueType(e.target.value) }}
                                    input={<OutlinedInput label="Type *" />}
                                >
                                    {renderDueType(addAndEditDueType)}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <TextField
                                sx={{ mb: 2 }}
                                id="outlined-basic"
                                label="Late Fees Amount"
                                variant="outlined"
                                fullWidth={true}
                                value={editDueLateFeesAmount}
                                onChange={e => {
                                    const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                    setEditDueLateFeesAmount(numericValue);
                                }}
                                inputProps={{
                                    inputMode: 'numeric', // Restrict input to numeric characters
                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon sx={{ color: "black" }}>
                                                $
                                            </Icon>
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth={true}
                                    label="Late fees Time Period"
                                    select
                                    variant="outlined"
                                    value={editDueLateFeesTimePeriod}
                                    onChange={e => { setEditDueLateFeesTimePeriod(e.target.value) }}
                                >
                                    {editLateFeesRateDuration.map((late, index) => (
                                        <MenuItem key={index} value={late.value}>{late.label}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <TextField
                                sx={{ mb: 2 }}
                                id="outlined-basic"
                                label="Late Fees Grace Period"
                                variant="outlined"
                                fullWidth={true}
                                type="number"
                                value={editDueLateFeesGracePeriod}
                                onChange={e => { setEditDueLateFeesGracePeriod(e.target.value) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon sx={{ color: "black" }}>
                                                $
                                            </Icon>
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <TextField
                                sx={{ mb: 2 }}
                                id="outlined-basic"
                                label="Total Late Fees"
                                variant="outlined"
                                fullWidth={true}
                                value={editDueTotalLateFees}
                                onChange={e => {
                                    const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                    setEditDueTotalLateFees(numericValue);
                                }}
                                inputProps={{
                                    inputMode: 'numeric', // Restrict input to numeric characters
                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon sx={{ color: "black" }}>
                                                $
                                            </Icon>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true}>
                                <TextField
                                    sx={{ mb: 2 }}
                                    id="outlined-basic"
                                    label="Recieved Amount"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={editRecievedAmount}
                                    onChange={e => {
                                        const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                        setEditRecievedAmount(numericValue)
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric', // Restrict input to numeric characters
                                        pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon sx={{ color: "black" }}>
                                                    $
                                                </Icon>
                                            </InputAdornment>
                                        )
                                    }} />
                            </FormControl>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <TextField
                                sx={{ mb: 2 }}
                                id="outlined-basic"
                                label="Waived Amount"
                                variant="outlined"
                                fullWidth={true}
                                value={editWaivedAmount}
                                onChange={e => {
                                    const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                    setEditWaivedAmount(numericValue)
                                }}
                                inputProps={{
                                    inputMode: 'numeric', // Restrict input to numeric characters
                                    pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon sx={{ color: "black" }}>
                                                $
                                            </Icon>
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                    </div>
                    <div className='finance-edit-due-dialog-box-textfield-flex-action-button-2'>
                        <div>
                            <Button
                                sx={{ fontSize: 15 }}
                                color="error"
                                variant="contained"
                                onClick={deleteDueClick}
                            >DELETE
                            </Button>
                        </div>
                        <div className='finance-add-due-dialog-box-textfield-flex-action-button'>
                            <Button
                                color="error"
                                type="submit"
                                onClick={() => setEditDueModelCondition(false)}
                                sx={{ fontSize: 15 }}
                            >CANCEL
                            </Button>
                            <Button
                                sx={{ fontSize: 15 }}
                                color="primary"
                                variant="contained"
                                onClick={editDueUpdateClick}
                            >Update
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Edit Payment Recieved Dialog Box */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={editPaymentRecievedModelCondition}
                onClose={() => setEditPaymentRecievedModelCondition(false)}>
                <DialogTitle textAlign={'center'}>Edit Payment Recieved</DialogTitle>
                <DialogContent>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day"]}
                                    label="Recieved Date *"
                                    value={recievedDate}
                                    onChange={(newValue) => {
                                        setRecievedDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField color="warning"  {...params} fullWidth={true} sx={{ my: 2 }} />} />
                            </LocalizationProvider>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel id="demo-simple-select-label">Type *</InputLabel>
                                <Select
                                    fullWidth={true}
                                    label="Type *"
                                    variant="outlined"
                                    MenuProps={MenuProps}
                                    value={recievedType}
                                    onChange={e => { setRecievedType(e.target.value) }}
                                    input={<OutlinedInput label="Type *" />}
                                >
                                    {renderDueType(dueType)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount Recieved</InputLabel>
                                <OutlinedInput
                                    fullWidth={true}
                                    variant="outlined"
                                    value={amountRecieved}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                        setAmountRecieved(numericValue)
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric', // Restrict input to numeric characters
                                        pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                    }}
                                    startAdornment={<InputAdornment position="start"> <Icon sx={{ color: "black" }}>$</Icon></InputAdornment>}
                                    label="Amount USD *"
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div>
                        <TextField
                            sx={{ mb: 2 }}
                            id="outlined-basic"
                            label="Description *"
                            variant="outlined"
                            fullWidth={true}
                            value={paymentReciedDesc}
                            onChange={e => { setPaymentRecivedDesc(e.target.value) }} />
                    </div>
                    <div className='finance-edit-due-dialog-box-textfield-flex-action-button-2 '>
                        <div>
                            <Button
                                sx={{ fontSize: 15 }}
                                color="error"
                                variant="contained"
                                onClick={deletePaidEntry}
                            >DELETE
                            </Button>
                        </div>
                        <div className='finance-add-due-dialog-box-textfield-flex-action-button '>
                            <Button
                                sx={{ fontSize: 15 }}
                                color="error"
                                type="submit"
                                onClick={() => setEditPaymentRecievedModelCondition(false)}
                            >CANCEL
                            </Button>
                            <Button
                                sx={{ fontSize: 15 }}
                                color="primary"
                                variant="contained"
                                onClick={editPaymentUpdateClick}
                            >Update
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Edit or Delete Property Finance Transaction */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={editFinanceTransactionModelCondition}
                onClose={() => setEditFinanceTransactionModelCondition(false)}>
                <DialogTitle>
                    <p style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}>{!deleteFinanceTransactionModelCondition ? "Edit" : "Delete"} Property Finance Transaction</p>
                    <p style={{ textAlign: "center", fontSize: 17, fontWeight: "bolder" }}>ID: #{transId}</p>
                </DialogTitle>
                <DialogContent>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day"]}
                                    label="Date *"
                                    value={editFinanceTransactionDate}
                                    onChange={(newValue) => {
                                        setEditFinanceTransactionDate(newValue);
                                    }}
                                    disabled={deleteFinanceTransactionModelCondition === false ? false : true}
                                    renderInput={(params) => <TextField color="warning"  {...params} fullWidth={true} sx={{ my: 2 }} />} />

                            </LocalizationProvider>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount USD *</InputLabel>
                                <OutlinedInput
                                    fullWidth={true}
                                    type="number"
                                    variant="outlined"
                                    value={editFinanceTransactionAmount}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                        setEditFinanceTransactionAmount(numericValue);
                                    }}
                                    startAdornment={<InputAdornment position="start"><Icon style={{ color: "black" }}>$</Icon></InputAdornment>}
                                    label="Amount USD *"
                                    inputProps={{
                                        inputMode: 'numeric', // Restrict input to numeric characters
                                        pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                    }}
                                    disabled={deleteFinanceTransactionModelCondition === false ? false : true}
                                />
                            </FormControl>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel id="demo-simple-select-label">Type *</InputLabel>
                                <Select
                                    fullWidth={true}
                                    label="Type *"
                                    variant="outlined"
                                    MenuProps={MenuProps}
                                    value={editFinanceTransactionExpenseType}
                                    onChange={e => { setEditFinanceTransactionExpenseType(e.target.value) }}
                                    disabled={deleteFinanceTransactionModelCondition === false ? false : true}
                                    input={<OutlinedInput label="Type *" />}
                                >
                                    {renderEditFinanceType(dueType, expense)}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            label="Description *"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            fullWidth={true}
                            value={editFinanceTransactionDesc}
                            onChange={e => { setEditFinanceTransactionDesc(e.target.value) }}
                            disabled={deleteFinanceTransactionModelCondition === false ? false : true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 20, paddingBottom: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', justifyContent: 'center' }}>
                            <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 10 }}>
                                <span>Receipts</span>
                                <span>
                                    <input type="file" id="uploadBtnAttachmentAdd" accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={handleAttachmentChange}
                                    />
                                    <label htmlFor="uploadBtnAttachmentAdd" title="Upload Reciept">
                                        <CloudUploadIcon color="primary" sx={{ cursor: "pointer" }} />
                                    </label>
                                </span>
                            </span>
                            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10 }}>
                                {attachments.length > 0 ? (
                                    attachments.map((attach, index) => (
                                        <span key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 10 }}>
                                            <span className="finance-add-group-expense-attachment-hover-text" style={{ cursor: 'pointer' }} onClick={(e) => { imageClick(e, attach?.url) }}>
                                                {attach?.name || attach?.fileName}
                                            </span>
                                            <span title="Delete Reciept">
                                                <DeleteIcon sx={{ cursor: 'pointer' }} color="error" onClick={() => { removeAttachMents(index) }} />
                                            </span>
                                        </span>
                                    ))
                                ) : (
                                    <span>
                                        No Receipts added.
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex-action-button '>
                        {!deleteFinanceTransactionModelCondition ?
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{ fontSize: 15 }}
                                onClick={editUpdateTransactionClick}
                            >UPDATE TRANSACTION
                            </Button>
                            :
                            <Button
                                color="error"
                                variant="contained"
                                sx={{ fontSize: 15 }}
                                onClick={DeleteTransactionClick}
                            >Delete TRANSACTION
                            </Button>
                        }
                        <Button
                            type="submit"
                            sx={{ fontSize: 15 }}
                            onClick={() => setEditFinanceTransactionModelCondition(false)}
                        >CANCEL
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Payment Recieved Dialog */}
            <Dialog
                open={paymentRecievedModelCondition}
                onClose={() => setPaymentRecievedModelCondition(false)}
                fullWidth={true}
                maxWidth="lg">
                <DialogTitle textAlign={"center"}>Payment Recieved</DialogTitle>
                <DialogContent>
                    <div className='finance-add-due-dialog-box-textfield-flex'>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day"]}
                                    label="Recieved Date *"
                                    value={paymentRecievedDate}
                                    onChange={(newValue) => {
                                        setPaymentRecievedDate(newValue);
                                    }}
                                    maxDate={moment().toDate()}
                                    renderInput={(params) => <TextField color="warning"  {...params} fullWidth={true} sx={{ my: 2 }} />} />
                            </LocalizationProvider>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel id="demo-simple-select-label">Type *</InputLabel>
                                <Select
                                    fullWidth={true}
                                    label="Type *"
                                    variant="outlined"
                                    MenuProps={MenuProps}
                                    value={paymentRecievedType}
                                    onChange={e => { setPaymentRecievedType(e.target.value) }}
                                    input={<OutlinedInput label="Type *" />}
                                >
                                    {renderDueType(addAndEditDueType)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='finance-edit-due-dialog-box-textfield-flex-width'>
                            <FormControl fullWidth={true} sx={{ my: 2 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount Recieved</InputLabel>
                                <OutlinedInput
                                    fullWidth={true}
                                    variant="outlined"
                                    value={paymentRecievedAmount}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                                        setPaymentRecievedAmount(numericValue);
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric', // Restrict input to numeric characters
                                        pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                                    }}
                                    startAdornment={<InputAdornment position="start"><Icon style={{ color: 'black' }}>$</Icon></InputAdornment>}
                                    label="Amount USD *"
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div>
                        <TextField
                            sx={{ mb: 2 }}
                            id="outlined-basic"
                            label="Description *"
                            variant="outlined"
                            fullWidth={true}
                            value={paymentRecievedDesc}
                            onChange={e => { setPaymentRecievedDesc(e.target.value) }} />
                    </div>
                    <div className='textAlign-center'>
                        <p>Dues as on {paymentRecievedDate ? moment(paymentRecievedDate).format('MMM D, YYYY') : ""}</p>
                    </div>
                    <div className='finance-payment-recieved-dialog-first-flex '>
                        <div className='finance-payment-recieved-dialog-second-flex'>
                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                Date
                            </span>
                            <span className='finance-payment-recieved-dialog-second-flex-grow'>
                                Description
                            </span>
                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                Amount
                            </span>
                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                Late Fees
                            </span>
                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                Paid
                            </span>
                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                Remaining
                            </span>
                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                Waived
                            </span>
                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                Receiving For
                            </span>
                        </div>
                        {financeBalanceEntries?.dueEntries.sort((a, b) => a.date > b.date ? 1 : -1).map((e, i) => (
                            <div style={{ paddingBottom: "10px" }} key={i}>
                                {e?.resolved === false ?
                                    <Accordion expanded={false}>
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignContent: "center", justifyContent: "flex-start", alignItems: "center" }}>
                                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                                {e?.date ? moment(e?.date).format('MMM D, YYYY') : ""}
                                            </span>
                                            <span className='finance-payment-recieved-dialog-second-flex-grow'>
                                                {e?.description}
                                            </span>
                                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                                ${parseFloat(e?.amount).toFixed(2)}
                                            </span>
                                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                                ${parseFloat(e?.lateFees).toFixed(2)}
                                            </span>
                                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                                ${parseFloat(e?.amountResolved).toFixed(2)}
                                            </span>
                                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                                ${parseFloat(e?.amount + e?.lateFees - e?.amountResolved).toFixed(2)}
                                            </span>
                                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                                <input
                                                    style={{ width: "75%", height: "30px" }}
                                                    name="amountWaived"
                                                    type="number"
                                                    margin="dense"
                                                    variant="outlined"
                                                    value={e?.amountWaived}
                                                    onChange={e => handleWaivedChange(e, i)}
                                                />
                                            </span>
                                            <span className='finance-payment-recieved-dialog-first-flex-grow'>
                                                $0.00
                                            </span>
                                        </AccordionSummary>
                                    </Accordion> : ""}
                            </div>
                        ))}
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex-action-button'>
                        <p style={{ fontSize: 17, fontWeight: "bold" }}>Dues Remaining:</p>
                        <p style={{ fontSize: 17, fontWeight: "bold" }}>$ {getRemainingDue(financeBalanceEntries?.dueEntries)}</p>
                    </div>
                    <div className='finance-add-due-dialog-box-textfield-flex-action-button'>
                        <Button
                            sx={{ fontSize: 15 }}
                            color="primary"
                            variant="contained"
                            disabled={paymentRecievedDate === null || paymentRecievedType === "" || Number(paymentRecievedAmount) === 0 || paymentRecievedDesc === "" ? true : false}
                            onClick={paymentRecievedUpdateClicked}
                        >Update
                        </Button>
                        <Button
                            color="error"
                            type="submit"
                            onClick={() => setPaymentRecievedModelCondition(false)}
                            sx={{ fontSize: 15 }}
                        >CANCEL
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    );
}

export default Finance;