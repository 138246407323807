import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getTenantLeasesProperty, tenantMoveOutInspection, getTenantFinanceBalanceOverview, getApiBaseURL } from "../../../services";
import ToolTip from "../../../components/ToolTip";
import { tenantImageClick } from "../../../components/imageView";
import '../../../Styles/tenant/tenantMoveOut.css'

function TenantMoveOutLease() {
    const params = useParams();

    const [property, setProperty] = useState(null);
    const [moveout, setMoveOut] = useState(null);
    const [mansignImg, setmansignImg] = useState("");
    const [commonMapview, setcommonMapview] = useState([]);
    const [financeBalanceOverview, setFinanceBalanceOverview] = useState({});

    // loading
    const [loading, setLoading] = useState(true);

    var config = "";
    const pageLoad1 = async () => {
        setLoading(true);
        let det = [];
        det.length = 0;
        try {
            await getTenantLeasesProperty(params.applicationId).then((res) => {
                const getProp = res?.data?.data;
                setProperty(getProp);
            })
            await tenantMoveOutInspection(params.applicationId).then((res) => {
                let getDetails = res?.data?.data;
                setMoveOut(getDetails);
                if (getDetails.managerSignature != undefined) {
                    if (getDetails.managerSignature.signatureFile != undefined) {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },
                        };
                        fetch(
                            `${getApiBaseURL()}/tenant/leases/${params.applicationId}/move-out/manager-signature`, config
                        ).then(res => {
                            return res.blob();
                        }).then(blob => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement('a');
                                link.href = imageDataUrl;
                                if (blob.type == "image/png"
                                    || blob.type == "image/gif"
                                    || blob.type == "image/jpeg"
                                    || blob.type == "image/svg"
                                    || blob.type == "image/jpg"
                                ) {
                                    setmansignImg(imageDataUrl)
                                }
                            }
                        })
                    }
                }
                if (getDetails.inspectionItems.length != 0) {
                    getDetails.inspectionItems.map((e, i) => {
                        det.push({
                            "area": e.area,
                            "id": e.id,
                            "name": e.name,
                            "remindOnMoveOut": e.remindOnMoveOut,
                            "settlementAmount": e.settlementAmount,
                            "settlementRemarks": e.settlementRemarks,
                            "managerFiles": e.managerFiles,
                            "settlementFiles": e.settlementFiles,
                            "tenantFiles": e.tenantFiles,
                            "status": getDetails.status
                        });
                    })
                    det.sort((a, b) => a.area.localeCompare(b.area));
                    let groupedPeople = groupBy(det, "area");
                    setcommonMapview(groupedPeople);
                }
            })
            await getTenantFinanceBalanceOverview(params.applicationId).then((res) => {
                let responseData = res?.data?.data;
                setFinanceBalanceOverview(responseData);
            })
        } catch (err) {
            setLoading(false);
            console.log("Error:", err)
        } finally {
            setLoading(false);
        }
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        pageLoad1();
    }, []);

    const updateEstimatedCosts = (area) => {
        let totalCost = {
            totalEstimatedCost: 0,
            toBeSentToTenantAmount: 0,
            amountTenantOwe: 0
        }
        Object.keys(area).forEach((cat, index) => {
            area[cat].forEach((item, i) => {
                if (item?.settlementAmount) totalCost.totalEstimatedCost += item?.settlementAmount;
            });
        });
        totalCost.toBeSentToTenantAmount = Math.max(0, (financeBalanceOverview?.securityDepositAmount - totalCost.totalEstimatedCost));
        totalCost.amountTenantOwe = Math.abs(Math.min(0, (financeBalanceOverview?.securityDepositAmount - totalCost.totalEstimatedCost)));
        return totalCost;
    };

    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
                    <p style={{ fontSize: 17 }}>
                        <Link to="/tenant/dashboard"> DASHBOARD </Link> / <Link to={`/tenant/lease-applications/${params.applicationId}/leaseview`}> LEASE </Link> / MOVE OUT CHECKLIST
                    </p>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12} textAlign={"center"}>
                    <p style={{ fontSize: 25, fontWeight: "bold" }}>Lease Move Out Checklist</p>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <p style={{ fontSize: 21, fontWeight: "bold" }}>Mananger</p>
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{property?.propertyManagers[0]?.manager?.user?.name?.first + " " + property?.propertyManagers[0]?.manager?.user?.name?.last}</p>
                                <ToolTip
                                    phone={property?.propertyManagers[0]?.manager?.user?.email?.id}
                                    email={property?.propertyManagers[0]?.manager?.user?.phone?.code + " " + property?.propertyManagers[0]?.manager?.user?.phone?.number}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6} textAlign={"right"}>
                            <p style={{ fontSize: 21, fontWeight: "bold" }}>Property</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.line1} {property?.address?.line2}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.unitNumber}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.city}</p>
                            <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: "0px" }}>{property?.address?.state} - {property?.address?.zipCode}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div>
                <p style={{ fontSize: 21, fontWeight: "bold" }}>Move Out Checklist</p>
            </div>

            <div>
                {Object.keys(commonMapview).map((cat, index) => (
                    <Accordion sx={{ mt: 2 }} key={index} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p style={{ fontSize: 18, fontWeight: "bold" }}>{cat}</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            {commonMapview[cat].map((item, i) => (
                                <div style={{ display: 'flex', flexDirection: 'column', borderTop: "1px solid rgba(0,0,0,.12)", paddingTop: "10px", paddingBottom: "10px", gap: '10px' }} key={i}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: '15px' }}>
                                        <h4 className="manager-move-out-primary">{item?.name}</h4>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', flexWrap: 'wrap', gap: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                            <h5 style={{ color: 'green' }}>Your Remark During Move In</h5>
                                            <span>
                                                {item?.tenantRemarks || "None"}
                                            </span>
                                            {item?.tenantFiles.length != 0 ? (
                                                <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                    {item?.tenantFiles.map((j, k) => (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                            <span className="tenant-move-in-attachment-hover-text" onClick={(e) => tenantImageClick(e, `leases/${params.applicationId}/move-in/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                {j?.fileName}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                            <h5 className="warnig-move-in-desc">Manager Remark During Move In</h5>
                                            <span>
                                                {item.managerRemarks || "None"}
                                            </span>
                                            {item?.managerFiles.length != 0 ? (
                                                <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                    {item?.managerFiles.map((j, k) => (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                            <span className="tenant-move-in-attachment-hover-text" onClick={(e) => tenantImageClick(e, `leases/${params.applicationId}/move-in/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                {j?.fileName}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                            <h5 style={{ color: 'red' }}>Condition on Move Out</h5>
                                            <span>
                                                {item.settlementRemarks || 'None'}
                                            </span>
                                            {item?.settlementFiles.length != 0 ? (
                                                <div style={{ display: "flex", flexDirection: 'column', gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                                                    {item?.settlementFiles.map((j, k) => (
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '10px', flexWrap: 'wrap' }} key={k} >
                                                            <span className="tenant-move-in-attachment-hover-text" onClick={(e) => tenantImageClick(e, `leases/${params.applicationId}/move-out/inspection-items/${item?.id}/files/${j?.id}`)}>
                                                                {j?.fileName}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                            <div>
                                                <p style={{ marginBottom: "12px", fontWeight: "bold", fontSize: 13 }}>Estimated Cost</p>
                                            </div>
                                            <div>
                                                <FormControl>
                                                    <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                                                    <OutlinedInput
                                                        type="number"
                                                        disabled={true}
                                                        margin="dense"
                                                        variant="outlined"
                                                        value={item?.settlementAmount}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!commonMapview[cat].length ?
                                <div sx={{ textAlign: 'center' }}>
                                    There are no issues here
                                </div>
                                : ""}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>

            {moveout ?
                <div style={{ width: '50%', marginTop: '20px' }}>
                    <p style={{ fontSize: 18, fontWeight: "bold" }}>Manager Remarks</p>
                    <TextField
                        id="standard-basic"
                        label="Mention for future records"
                        variant="standard"
                        sx={{ width: 1 }}
                        value={moveout?.managerRemarks}
                        disabled={true}
                    />
                </div>
                : ""}

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center', marginTop: "20px" }}>
                {moveout ?
                    <div>
                        {moveout?.managerSignature ?
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <p style={{ fontSize: 21, fontWeight: "bold" }}>{moveout?.managerSignature?.name}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>Manager</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>IP Address: {moveout?.managerSignature?.ipAddress}</p>
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>{moveout?.managerSignature?.time}</p>
                                <div style={{ cursor: "pointer" }} className="manager-move-out-signbox">
                                    <img src={mansignImg} style={{ height: 200, width: 300 }}></img>
                                </div>
                            </div>
                            : ""}
                    </div>
                    : ""}
                {moveout ?
                    <div style={{ display: "flex", flexDirection: "column", gap: '20px', marginTop: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: 'wrap', alignItem: 'center', gap: '20px' }}>
                            <h4>Total Restoration Cost</h4>
                            <FormControl>
                                <OutlinedInput
                                    type="number"
                                    value={updateEstimatedCosts(commonMapview)?.totalEstimatedCost}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    disabled={true}
                                />
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: 'wrap', alignItem: 'center', gap: '20px' }}>
                            <h4>Total Security Deposit</h4>
                            <FormControl>
                                <OutlinedInput
                                    type="number"
                                    value={financeBalanceOverview?.securityDepositAmount}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    disabled={true}
                                />
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: 'wrap', alignItem: 'center', gap: '20px' }}>
                            <h4>{updateEstimatedCosts(commonMapview)?.toBeSentToTenantAmount > 0 ? "Amount to be Refunded " : "Amount Tenant Owes"}</h4>
                            <FormControl>
                                <OutlinedInput
                                    type="number"
                                    value={updateEstimatedCosts(commonMapview)?.amountTenantOwe > 0 ? updateEstimatedCosts(commonMapview)?.amountTenantOwe : updateEstimatedCosts(commonMapview)?.toBeSentToTenantAmount}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    disabled={true}
                                />
                            </FormControl>
                        </div>
                    </div>
                    : ""}
            </div>
        </div >
    );
}

export default TenantMoveOutLease;