import { createSlice } from '@reduxjs/toolkit';

export const ongoingApplicationSlice = createSlice({
    name: "ongoingApplication",
    initialState: {
        value: null,
    },
    reducers: {
        setOngoingApplication: (state, action) => {
            state.value = action.payload;
        },
        unsetOngoingApplication: (state) => {
            state.value = false;
        },
    },
});

export const { setOngoingApplication, unsetOngoingApplication } = ongoingApplicationSlice.actions;

export const ongoingApplicationReducer = ongoingApplicationSlice.reducer;
