import React, { useState } from 'react'
import { addProperty } from '../../../../services';
import { useNavigate } from 'react-router-dom';
import { usStates } from '../../../../helpers';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, FormControlLabel, RadioGroup, Radio, TextField, MenuItem, OutlinedInput, InputLabel, Select, FormControl, Paper, InputAdornment, Icon, Grid } from '@mui/material';
import { MenuProps } from '../../../../components/MaterialUiSelectMenuStyle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function AddProperty() {
    const navigate = useNavigate();

    const [images, setImages] = useState([]);
    const [imageBases, setImageBases] = useState([]);
    const [propertyType, setPropertyType] = useState("private");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine1Error, setAddressLine1Error] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [addressLine2Error, setAddressLine2Error] = useState("");
    const [unitNumber, setUnitNumber] = useState("");
    const [unitNumberError, setUnitNumberError] = useState("");
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");
    const [state, setState] = useState("Alabama");
    const [stateError, setStateError] = useState("");
    const [zip, setZip] = useState("");
    const [zipError, setZipError] = useState("");
    const [bedRoomCount, setBedRoomCount] = useState("0");
    const [bedRoomCountError, setBedRoomCountError] = useState("");
    const [bathRoomCount, setBathRoomCount] = useState("0");
    const [bathRoomCountError, setBathRoomCountError] = useState("");
    const [parkingSpaceCount, setParkingSpaceCount] = useState("0");
    const [parkingSpaceCountError, setParkingSpaceCountError] = useState("");
    const [rent, setRent] = useState("0");
    const [rentError, setRentError] = useState("");
    const [securityDeposit, setSecurityDeposit] = useState("0");
    const [securityDepositError, setSecurityDepositError] = useState("");
    const [area, setArea] = useState("0");
    const [areaError, setAreaError] = useState("");
    const [trashSchedule, setTrashSchedule] = useState("");
    const [trashScheduleError, setTrashScheduleError] = useState("");
    const [recyclingSchedule, setRecyclingSchedule] = useState("");
    const [recyclingScheduleError, setRecyclingScheduleError] = useState("");
    const [assignedParkingSpace, setAssignedParkingSpace] = useState("");
    const [assignedParkingSpaceError, setAssignedParkingSpaceError] = useState("");
    const [rentControlled, setRentControlled] = useState(false);
    const [furnished, setFurnished] = useState(false);
    const [laundry, setLaundry] = useState("NONE");
    const [vehicleCode, setVehicleCode] = useState("");
    const [civilCode, setCivilCode] = useState("");

    const renderStateOptions = () => {
        return usStates.map((item) => {
            return (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            );
        });
    };

    const removeImage = (index) => {
        let newImages, newImageBases;

        newImages = images.filter((item, i) => {
            return i !== index;
        });
        newImageBases = imageBases.filter((item, i) => {
            return i !== index;
        });
        setImages(newImages);
        setImageBases(newImageBases);
    };

    const renderImages = () => {
        return imageBases.map((item, index) => {
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={item} loading="lazy" alt="Property" style={{ height: "110px", width: "175px", marginTop: "20px", cursor: "pointer" }} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer", marginBottom: '5px' }} onClick={() => { removeImage(index) }}>
                            Remove
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleImagesAdd = async (e) => {
        const newImages = [...images];
        newImages.push(e.target.files[0]);
        setImages(newImages);
        let base64 = await getBase64(e.target.files[0]);
        let newImageBases = [...imageBases];
        newImageBases.push(base64);
        setImageBases(newImageBases);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let error = false;

        if (description === "") {
            setDescriptionError("Description cannot be empty");
            error = true;
        } else {
            setDescriptionError("");
        }
        if (addressLine1 === "") {
            setAddressLine1Error("Please fill this field");
            error = true;
        } else {
            setAddressLine1Error("");
        }
        if (city === "") {
            setCityError("City cannot be empty");
            error = true;
        } else {
            setCityError("");
        }
        if (zip === "") {
            setZipError("Zip code cannot be empty");
            error = true;
        } else {
            setZipError("");
        }
        if (trashSchedule === "") {
            setTrashScheduleError("Please fill this field");
            error = true;
        } else {
            setTrashScheduleError("");
        }
        if (recyclingSchedule === "") {
            setRecyclingScheduleError("Please fille this field");
            error = true;
        } else {
            setRecyclingScheduleError("");
        }
        if (assignedParkingSpace === "") {
            setAssignedParkingSpaceError("Please fill in this field");
            error = true;
        } else {
            setAssignedParkingSpaceError("");
        }

        if (error) return;

        let form = new FormData();

        form.append("isPublic", propertyType === "public");
        form.append("description", description);
        form.append("addressLine1", addressLine1);
        form.append("addressLine2", addressLine2);
        form.append("unitNumber", unitNumber);
        form.append("city", city);
        form.append("state", state);
        form.append("zipCode", zip);
        form.append("numberOfBedrooms", bedRoomCount);
        form.append("numberOfBathrooms", bathRoomCount);
        form.append("numberOfParkingSpaces", parkingSpaceCount);
        form.append("rentAmount", rent);
        form.append("securityDepositAmount", securityDeposit);
        form.append("area", area);
        form.append("vehicleCode", vehicleCode);
        form.append("civilCode", civilCode);
        form.append("trashSchedule", trashSchedule);
        form.append("recyclingSchedule", recyclingSchedule);
        form.append("parkingSpacesDetails", assignedParkingSpace);
        form.append("isFurnished", furnished);
        form.append("isRentControlled", rentControlled);
        form.append("laundryFacility", laundry);
        images.forEach((item) => {
            form.append("images", item);
        });

        addProperty(form).then((response) => {
            toast.success("Property Added Successfully.")
            navigate("/manager/home");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };

    return (
        <div style={{ padding: '30px' }}>
            <h1 style={{ textAlign: 'center' }}>Add Property</h1>
            <div className="property-tab-wrp">
                <div className="tabContentWrp">
                    <div className="imgUploadSec">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap', alignItems: 'center' }}>
                            <h3 className="subHeader">Add Images</h3>
                            <label htmlFor="uploadBtn" className='property-edit-add-image-icon'>
                                <CloudUploadIcon color='primary' sx={{ cursor: 'pointer', height: 25, width: 25 }} />
                            </label>
                        </div>
                        <div className="img-container">
                            {renderImages()}
                            <div className="uploadBtn">
                                <input type="file" id="uploadBtn" accept="image/*"
                                    onChange={handleImagesAdd}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="propDetails">
                        <h3 className="subHeader">Make your property Public or Private (Public properties will appear in public search results).</h3>
                        <form>
                            <div>
                                <RadioGroup row>
                                    <FormControlLabel
                                        value={"public"}
                                        control={<Radio color="success" />}
                                        label="Public"
                                        onChange={e => { setPropertyType('public') }}
                                        checked={propertyType === 'public'}
                                    />
                                    <FormControlLabel
                                        value={"private"}
                                        control={<Radio color="success" />}
                                        label="Private"
                                        onChange={e => { setPropertyType('private') }}
                                        checked={propertyType === 'private'}
                                    />
                                </RadioGroup>
                            </div>
                            <div className="form-item">
                                <h3 className="subHeader">Description</h3>
                                <TextField
                                    helperText={descriptionError && descriptionError}
                                    error={descriptionError ? true : false}
                                    placeholder={`Describe the property accurately. Your real estate listing description should be accurate and attractive. Here are some tips:\n
 1. Choose the right adjectives.\n 2. Highlight unique features.\n 3. Leave out super basic info.`}
                                    margin="dense"
                                    variant="outlined"
                                    multiline
                                    rows={7}
                                    value={description}
                                    onChange={e => { setDescription(e.target.value) }}
                                />
                            </div>
                        </form>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} md={6}>
                            <h3 className="subHeader">Address</h3>
                            <TextField
                                helperText={addressLine1Error && addressLine1Error}
                                error={addressLine1Error ? true : false}
                                margin="dense"
                                variant="outlined"
                                placeholder="Enter address line 1"
                                sx={{ width: 1 }}
                                value={addressLine1}
                                label="Address Line 1"
                                onChange={e => { setAddressLine1(e.target.value) }}
                            />
                            <TextField
                                helperText={addressLine2Error && addressLine2Error}
                                error={addressLine2Error ? true : false}
                                margin="dense"
                                variant="outlined"
                                label="Address Line 2"
                                placeholder="Enter address line 2"
                                sx={{ width: 1 }}
                                value={addressLine2}
                                onChange={e => { setAddressLine2(e.target.value) }}
                            />
                            <TextField
                                helperText={unitNumberError && unitNumberError}
                                error={unitNumberError ? true : false}
                                margin="dense"
                                variant="outlined"
                                label="Unit Number"
                                placeholder="Enter unit number"
                                sx={{ width: 1 }}
                                value={unitNumber}
                                onChange={e => { setUnitNumber(e.target.value) }}
                            />
                            <TextField
                                helperText={cityError && cityError}
                                error={cityError ? true : false}
                                margin="dense"
                                variant="outlined"
                                value={city}
                                label="City"
                                placeholder="Enter City"
                                sx={{ width: 1 }}
                                onChange={e => { setCity(e.target.value) }}
                            />
                            <FormControl
                                fullWidth={true}
                                helperText={stateError && stateError}
                                error={stateError ? true : false}
                                sx={{ width: 1 }}
                                margin="dense">
                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                <Select
                                    label={"State"}
                                    fullWidth={true}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                    onChange={e => { setState(e.target.value) }}
                                    value={state}
                                    input={<OutlinedInput label="State" />}
                                >
                                    {renderStateOptions()}
                                </Select>
                            </FormControl>
                            <TextField
                                helperText={zipError && zipError}
                                error={zipError ? true : false}
                                margin="dense"
                                variant="outlined"
                                value={zip}
                                label="Zip Code"
                                placeholder="Enter ZIP Code"
                                sx={{ width: 1 }}
                                onChange={e => {
                                    if (e.target.value.length == 6) return false;
                                    setZip(e.target.value)
                                }}
                            />
                            <TextField
                                margin="dense"
                                variant="outlined"
                                value={vehicleCode}
                                label="Vehicle Code"
                                placeholder="Enter Vehicle Code"
                                sx={{ width: 1 }}
                                onChange={e => {
                                    setVehicleCode(e.target.value)
                                }}
                            />
                            <TextField
                                margin="dense"
                                variant="outlined"
                                value={civilCode}
                                label="Civil Code"
                                placeholder="Enter Civil Code"
                                sx={{ width: 1 }}
                                onChange={e => {
                                    setCivilCode(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <h3 className="subHeader">Basic Details</h3>
                            <TextField
                                helperText={bedRoomCountError && bedRoomCountError}
                                error={bedRoomCountError ? true : false}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                sx={{ width: 1 }}
                                value={bedRoomCount}
                                label="Bedroom Count"
                                onChange={e => { setBedRoomCount(e.target.value) }}
                                inputProps={{ min: "0" }}
                            />
                            <TextField
                                helperText={bathRoomCountError && bathRoomCountError}
                                error={bathRoomCountError ? true : false}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={bathRoomCount}
                                label="Bathroom Count"
                                onChange={e => { setBathRoomCount(e.target.value) }}
                                inputProps={{ min: "0" }}
                                sx={{ width: 1 }}
                            />
                            <TextField
                                helperText={parkingSpaceCountError && parkingSpaceCountError}
                                error={parkingSpaceCountError ? true : false}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                label="Parking Space Count"
                                value={parkingSpaceCount}
                                onChange={e => { setParkingSpaceCount(e.target.value) }}
                                inputProps={{ min: "0" }}
                                sx={{ width: 1 }}
                            />
                            <TextField
                                fullWidth={true}
                                helperText={rentError && rentError}
                                error={rentError ? true : false}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={rent}
                                label="Rent"
                                sx={{ width: 1 }}
                                onChange={e => { setRent(e.target.value) }}
                                inputProps={{ min: "0" }}
                                InputProps={{ startAdornment: <InputAdornment position="start"><Icon style={{ color: "black" }}>$</Icon></InputAdornment> }}
                            />
                            <TextField
                                fullWidth={true}
                                helperText={securityDepositError && securityDepositError}
                                error={securityDepositError ? true : false}
                                variant="outlined"
                                type="number"
                                label="Security Deposit"
                                margin="dense"
                                sx={{ width: 1 }}
                                value={securityDeposit}
                                onChange={e => { setSecurityDeposit(e.target.value) }}
                                inputProps={{ min: "0" }}
                                InputProps={{ startAdornment: <InputAdornment position="start"><Icon style={{ color: "black" }}>$</Icon></InputAdornment> }}
                            />
                            <TextField
                                helperText={areaError && areaError}
                                error={areaError ? true : false}
                                margin="dense"
                                variant="outlined"
                                sx={{ width: 1 }}
                                type="number"
                                value={area}
                                label="Area"
                                onChange={e => { setArea(e.target.value) }}
                                inputProps={{ min: "0" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} md={6}>
                            <h3 className="subHeader">Other Details</h3>
                            <TextField
                                helperText={trashScheduleError && trashScheduleError}
                                error={trashScheduleError ? true : false}
                                margin="dense"
                                variant="outlined"
                                value={trashSchedule}
                                label="Trash Schedule"
                                placeholder='Eg Monday'
                                sx={{ width: 1 }}
                                onChange={e => { setTrashSchedule(e.target.value) }}
                                inputProps={{ min: "0" }}
                            />
                            <TextField
                                helperText={recyclingScheduleError && recyclingScheduleError}
                                error={recyclingScheduleError ? true : false}
                                margin="dense"
                                variant="outlined"
                                label="Recycling Schedule"
                                placeholder='Eg Monday'
                                sx={{ width: 1 }}
                                value={recyclingSchedule}
                                onChange={e => { setRecyclingSchedule(e.target.value) }}
                            />
                            <TextField
                                helperText={assignedParkingSpaceError && assignedParkingSpaceError}
                                error={assignedParkingSpaceError ? true : false}
                                margin="dense"
                                variant="outlined"
                                label="Assigned Parking Space"
                                placeholder="Enter details of Assigned Parking Space"
                                sx={{ width: 1 }}
                                value={assignedParkingSpace}
                                onChange={e => { setAssignedParkingSpace(e.target.value) }}
                            />
                            <div className="btnSecWrp">
                                <div className="optionBoxSec">
                                    <div
                                        className="optionBox"
                                        style={{
                                            fontWeight: 600,
                                            flexDirection: "row"
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            id="rentcontrol"
                                            checked={rentControlled}
                                            onChange={() => { setRentControlled(!rentControlled) }}
                                        />
                                        <label for="rentcontrol" style={{ cursor: 'pointer' }} htmlFor="rentcontrol">Rent Controlled</label>
                                    </div>
                                    <div className="radioSecWrp">
                                        <RadioGroup row>
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio color="success" />}
                                                label="Unfurnished"
                                                checked={furnished === false}
                                                onChange={e => { setFurnished(false) }}
                                            />
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio color="success" />}
                                                label=" Furnished"
                                                checked={furnished === true}
                                                onChange={e => { setFurnished(true) }}
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                                <br></br>
                                <div className="optionBoxSec">
                                    <h3 className="subHeader">Laundry Availablity</h3>
                                    <div className="radioSecWrp">
                                        <RadioGroup row>
                                            <FormControlLabel
                                                value={'NONE'}
                                                control={<Radio color="success" />}
                                                label="NONE"
                                                checked={laundry === 'NONE'}
                                                onChange={e => { setLaundry('NONE') }}
                                            />
                                            <FormControlLabel
                                                value={'IN_UNIT'}
                                                control={<Radio color="success" />}
                                                label="IN_UNIT"
                                                checked={laundry === 'IN_UNIT'}
                                                onChange={e => { setLaundry('IN_UNIT') }}
                                            />
                                            <FormControlLabel
                                                value={'IN_COMPLEX'}
                                                control={<Radio color="success" />}
                                                label=" IN_COMPLEX"
                                                checked={laundry === 'IN_COMPLEX'}
                                                onChange={e => { setLaundry('IN_COMPLEX') }}
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="warning"
                            sx={{ fontSize: 15 }}
                            onClick={handleSubmit}
                        >
                            Save Property
                        </Button>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default AddProperty;