import { Tabs, Tab, Box } from "@mui/material";
import React from "react";
import { useLocation, Link } from 'react-router-dom';

function PropertiesActiveInactiveTabs() {
    const location = useLocation();
    const currentTab = location.pathname;

    return (
        <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
        >
            <Tabs
                variant="scrollable"
                aria-label="basic tabs example"
                value={currentTab}
            >
                <Tab
                    label={<h5 style={{ margin: "0px 30px" }}>ACTIVE PROPERTIES</h5>}
                    value={`/manager/active-Properties`}
                    to={`/manager/active-Properties`}
                    component={Link}
                />
                <Tab
                    label={<h5 style={{ margin: "0px" }}>INACTIVE Properties</h5>}
                    value={`/manager/inactive-Properties`}
                    to={`/manager/inactive-Properties`}
                    component={Link}
                />
            </Tabs>
        </Box>
    );
}

export default PropertiesActiveInactiveTabs;