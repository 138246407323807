import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteTenantLeaseApplication, getTenantLeaseApplicationsOverview, getTenantLeasesOverview } from "../../../services";
import { Box, CircularProgress, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import { Button, Table, TableRow, Typography } from "@mui/material";
import moment from "moment";

function TenantDashboardPage() {
  const navigate = useNavigate();

  const [leases, setLeases] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTenantLeaseOverviewAndApplications = async () => {
    try {
      setLoading(true);
      await getTenantLeasesOverview().then((response) => {
        setLeases(response.data.data);
      })
      await getTenantLeaseApplicationsOverview().then((response) => {
        setApplications(response?.data?.data);
      })
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTenantLeaseOverviewAndApplications();
  }, []);

  const handleDeleteClick = (id) => {
    deleteTenantLeaseApplication(id).then((response) => {
      getTenantLeaseOverviewAndApplications();
    })
  };

  const renderLeases = (lease) => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className='tableCell-maxWidth-1'><span className="tenant-dashboard-table-head">Leases</span></TableCell>
              <TableCell className='tableCell-maxWidth-1'><span className="tenant-dashboard-table-head">Expires / Ended On</span></TableCell>
              <TableCell className='tableCell-maxWidth-1'><span className="tenant-dashboard-table-head">Renewal</span></TableCell>
              <TableCell className='tableCell-maxWidth-1'><span className="tenant-dashboard-table-head">Action</span></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lease?.map((item, index) => (
              < TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                <TableCell className='tableCell-maxWidth-1' component="th" scope="row">
                  <span className="application-address">
                    {item?.property?.address?.line1 + ", " + item?.property?.address?.line2 + " " + item?.property?.address?.unitNumber}
                  </span>
                </TableCell>
                <TableCell className='tableCell-maxWidth-1'>
                  {item?.active ?
                    <span>
                      {item?.expiryDate ? "(Expires:" + " " + moment(item?.expiryDate).format("MMMM DD, YYYY") + ")" : ""}
                    </span>
                    : ""}
                  {!item?.active ?
                    <span>
                      {item?.status != 1 ? "(Ended On:" + " " + moment(item?.endDate).format("MMMM DD, YYYY") + ")" : ""}<br />
                      {item?.status == 1 ? "(Starting On:" + " " + moment(item?.beginDate).format("MMMM DD, YYYY") + ")" : ""}
                    </span>
                    : ""}
                </TableCell>
                <TableCell className='tableCell-maxWidth-1'>
                  <span>
                    {item?.renewal?.beginDate ? "(Starting On:" + " " + moment(item?.renewal?.beginDate).format("MMMM DD, YYYY") + ")" : ""}<br />
                    {item?.renewal?.expiryDate ? "(Ended On:" + " " + moment(item?.renewal?.expiryDate).format("MMMM DD, YYYY") + ")" : ""}
                  </span>
                </TableCell>
                <TableCell className='tableCell-maxWidth-1'>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ fontSize: 15 }}
                    onClick={() => { leaseViewClick(item?.id) }}
                  >
                    VIEW
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  };

  const renderApplications1 = () => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className='tableCell-maxWidth-1'><span className="tenant-dashboard-table-head">Address</span></TableCell>
              <TableCell className='tableCell-maxWidth-1'><span className="tenant-dashboard-table-head">Monthly Rent</span></TableCell>
              <TableCell className='tableCell-maxWidth-1'><span className="tenant-dashboard-table-head">Status</span></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications?.map((item, index) => {
              return (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                  <TableCell className='tableCell-maxWidth-1'>
                    <span className="application-address" onClick={() => { applicationsPropertyNameClick(item?.id) }}>
                      {item?.property?.address?.line1}
                      {item?.property?.address?.line2 &&
                        `, ${item?.property?.address?.line2}`}
                    </span>
                  </TableCell>
                  <TableCell className='tableCell-maxWidth-1'>${item?.property?.rentAmount}</TableCell>
                  <TableCell className='tableCell-maxWidth-1'>
                    <div>
                      {item?.status === "APPLICANT_SUBMITTED" && (
                        <>
                          <span className="status-success">
                            <h4>Submitted</h4>
                          </span>

                          <span className="status-text">
                            The landlord will be reviewing your application soon.
                            Questions? Call the landlord{" "}
                            {`${item?.property?.owner?.user?.name?.first} ${item?.property?.owner?.user?.name?.last}`}{" "}
                            at{" "}
                            <a
                              href={`tel:${item.property.owner.user.phone.code}${item.property.owner.user.phone.number}`}
                            >{`${item?.property?.owner?.user?.phone?.code}${item?.property?.owner?.user?.phone?.number}`}</a>
                          </span>
                          <br />
                          <Link to={`/tenant/lease-applications/${item?.id}/occupant`}>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ m: "2%", fontSize: 15 }}
                            >
                              View
                            </Button>
                          </Link>

                          <Button
                            variant="contained"
                            color="error"
                            sx={{ m: "2%", fontSize: 15 }}
                            onClick={() => {
                              handleDeleteClick(item?.id);
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                      {item?.status === "APPLICANT_STARTED" && (
                        <>
                          <span className="status-success">
                            <h4>Started</h4>
                          </span>
                          <span className="status-text">
                            Please complete and submit your Application
                          </span>

                          <br />
                          <Link to={`/tenant/lease-applications/${item?.id}/occupant`}>
                            <Button
                              variant="contained"
                              color="warning"
                              sx={{ m: "2%", fontSize: 15 }}
                            >
                              Resume
                            </Button>
                          </Link>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ m: "2%", fontSize: 15 }}
                            onClick={() => {
                              handleDeleteClick(item?.id);
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                      {item.status === "MANAGER_SENT_LEASE_AGREEMENT" && (
                        <>
                          <span className="status-success">
                            <h4>Lease Agreement Prepared</h4>
                          </span>
                          <span className="status-text">
                            Awaiting signature from Applicant's side.
                          </span>
                          <br />
                          <Link to={`/tenant/leaseagreement/${item?.id}`}>
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ m: "2%", fontSize: 15 }}
                            >
                              {" "}
                              SIGN LEASE AGREEMENT
                            </Button>
                          </Link>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ m: "2%", fontSize: 15 }}
                            onClick={() => {
                              handleDeleteClick(item?.id);
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                      {item?.status ===
                        "MANAGER_ACCEPTED_AND_PREPARING_LEASE_AGREEMENT" && (
                          <>
                            <span className="status-success">
                              <h4>Manager Preparing Lease Agreement</h4>
                            </span>
                            <span className="status-text">
                              The landlord will be reviewing your application soon.
                              Questions? Call the landlord{" "}
                              {`${item?.property?.owner?.user?.name?.first} ${item?.property?.owner?.user?.name?.last}`}{" "}
                              at{" "}
                              <a
                                href={`tel:${item?.property?.owner?.user?.phone?.code}${item?.property?.owner?.user?.phone?.number}`}
                              >{`${item?.property?.owner?.user?.phone?.code}${item?.property?.owner?.user?.phone?.number}`}</a>
                            </span>
                            <br />

                            <Link to={`/tenant/lease-applications/${item?.id}/occupant`}>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ m: "2%", fontSize: 15 }}
                              >
                                View
                              </Button>{" "}
                            </Link>

                            <Button
                              variant="contained"
                              color="error"
                              sx={{ m: "2%", fontSize: 15 }}
                              onClick={() => {
                                handleDeleteClick(item?.id);
                              }}
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      {item?.status === "APPLICANT_LEASE_AGREEMENT_SIGNED" && (
                        <>
                          <span className="status-success">
                            <h4>Lease Agreement Signed by Applicants</h4>
                          </span>
                          <span className="status-text">
                            Please wait for the Property Manager to verify and
                            sign the Lease Agreement.
                          </span>
                          <br />
                          <Link
                            to={`/tenant/lease-applications/${item?.id}/status`}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ m: "2%", fontSize: 15 }}
                            >
                              View
                            </Button>
                          </Link>
                        </>
                      )}
                      {item?.status === "MANAGER_SET_UP_LEASE_SUCCESSFULLY" && (
                        <>
                          <span className="status-success">
                            <h4>Lease Set Up Successfully</h4>
                          </span>
                          <br />{" "}
                          <Link
                            to={`/tenant/lease-applications/${item.id}/status`}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ m: "2%", fontSize: 15 }}
                            >
                              View
                            </Button>
                          </Link>
                        </>
                      )}
                      {item?.status === "APPLICANT_WITHDRAWN" && (
                        <>
                          <span className="status-withdrawn">
                            <h4>Withdrawn</h4>
                          </span>
                          <span className="status-text">
                            You may modify and resubmit your application. You can
                            also completely delete your application from the
                            system. Questions? Call the landlord{" "}
                            {`${item?.property?.owner?.user?.name?.first} ${item?.property?.owner?.user?.name?.last}`}{" "}
                            at{" "}
                            <a
                              href={`tel:${item?.property?.owner?.user?.phone?.code}${item?.property?.owner?.user?.phone?.number}`}
                            >{`${item?.property?.owner?.user?.phone?.code}${item?.property?.owner?.user?.phone?.number}`}</a>
                          </span>
                          <br />
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ m: "2%", fontSize: 15 }}
                            onClick={() => {
                              handleDeleteClick(item?.id);
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const applicationsPropertyNameClick = (id) => {
    navigate(`/tenant/lease-applications/${id}/occupant`);
  }

  const leaseViewClick = (id) => {
    navigate(`/tenant/lease-applications/${id}/leaseview`)
  }

  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ height: 70, width: 70 }} />
      </Box>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ margin: "2% auto", textAlign: "center" }}>
        <Typography variant="h4">Dashboard</Typography>
      </div>
      <div style={{ paddingTop: '50px' }}>
        <Typography variant="h5">Leases</Typography>
      </div>
      <div style={{ paddingTop: '30px' }}>
        {renderLeases(leases)}
      </div>
      <div>
        <hr className="tenant-dashboard-page-split-line" />
      </div>
      <div>
        <Typography variant="h5">Current Applications</Typography>
      </div>
      <div style={{ paddingTop: '30px' }}>
        {renderApplications1()}
      </div>
    </div>
  );
}

export default TenantDashboardPage;
