import { React, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import { Box, Avatar, Button, CardContent, TextField, Paper, CircularProgress } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
    tenantServiceRequest, postTenantServiceRequestComments,
    deleteTenantServiceRequestComments, patchTenantServiceRequestComments
    , postTenantServiceRequestCommentsAttachment, deleteTenantServiceRequestCommentsAttachment,
    patchTenantServiceRequest, postTenantServiceRequestAttachments, getApiBaseURL, deleteTenantServiceRequestAttachments, getTenantServiceRequestMembers,
    patchTenantServiceRequestDescriptionCheckbox
} from "../../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tenantImageClick } from "../../../components/imageView";
import moment from "moment";
// import ToolTip from "../../../components/ToolTip";

function TenantServiceRequest() {
    const params = useParams();
    const navigate = useNavigate();
    const hiddenFileInput = useRef();

    const [serviceRequest, setServiceRequest] = useState(null);
    const [name, setName] = useState(false);
    const [desc, setDesc] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [attachFiles, setAttachFiles] = useState([]);
    const [commonMapview, setcommonMapview] = useState([]);
    const [commentFiles, setCommentFiles] = useState([]);
    const [comment, setComment] = useState([]);
    const [commentCondition, setcommentCondition] = useState({});
    const [assignee, setAssignee] = useState([]);
    const [content, setContent] = useState("");
    const [images, setImages] = useState([]);
    const [fileId, setFileId] = useState(0);

    // loading
    const [loading, setLoading] = useState(true);

    // Activities comments expand and hide 
    const toggle = id => {
        setcommentCondition(prevcommentCondition => ({ [id]: !prevcommentCondition[id] }));
    }

    var config = "";
    const pageLoad = async () => {
        try {
            setLoading(true);
            let files = [...attachFiles];
            files.length = 0;
            let cmtFiles = [...commentFiles];
            cmtFiles.length = 0;
            let cmnt = [...comment];
            cmnt.length = 0;
            await tenantServiceRequest(params.serviceId).then((res) => {
                let getDetails = res?.data?.data;
                setServiceRequest(getDetails);
                setTitle(getDetails?.title);
                setDescription(getDetails?.description);

                if (getDetails.attachments.length != 0) {
                    getDetails.attachments.map((g, h) => {
                        const authToken = sessionStorage.getItem("authToken");
                        config = {
                            headers: { Authorization: `Bearer ${authToken}` },

                        };
                        fetch(
                            `${getApiBaseURL()}/tenant/service-requests/${params.serviceId}/attachments/${g.id}`, config
                        ).then(res => {
                            return res.blob();
                        }).then(blob => {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function () {
                                var imageDataUrl = reader.result;
                                const link = document.createElement('a');
                                link.href = imageDataUrl;
                                if (blob.type == "image/png"
                                    || blob.type == "image/gif"
                                    || blob.type == "image/jpeg"
                                    || blob.type == "image/svg"
                                    || blob.type == "image/jpg"
                                ) {
                                    files.push({
                                        "files": imageDataUrl,
                                        "id": g?.id,
                                        "url": g?.url,
                                    })
                                }
                                if (blob.type == "application/pdf") {
                                }
                                console.log(files)
                                setAttachFiles(files.flat(Infinity))
                            }
                        })
                    })
                }

                if (getDetails?.comments.length != 0) {
                    getDetails?.comments.map((e) => {
                        e?.attachments.map((h) => {
                            const authToken = sessionStorage.getItem("authToken");
                            config = {
                                headers: { Authorization: `Bearer ${authToken}` },

                            };
                            fetch(
                                `${getApiBaseURL()}/tenant/${h?.url}`, config
                            ).then(res => {
                                return res.blob();
                            }).then(blob => {
                                var reader = new window.FileReader();
                                reader.readAsDataURL(blob);
                                reader.onload = function () {
                                    var imageDataUrl = reader.result;
                                    const link = document.createElement('a');
                                    link.href = imageDataUrl;
                                    if (blob.type == "image/png"
                                        || blob.type == "image/gif"
                                        || blob.type == "image/jpeg"
                                        || blob.type == "image/svg"
                                        || blob.type == "image/jpg"
                                    ) {
                                        cmtFiles.push({
                                            "files": imageDataUrl,
                                            "id": h.id,
                                            "url": h.url,
                                        })
                                    }
                                    if (blob.type == "application/pdf") {
                                    }
                                    setCommentFiles(cmtFiles.flat(Infinity))
                                }
                            })
                        })
                        cmnt.push({
                            "attachment": e?.attachments,
                            "content": e?.content,
                            "createdAt": e?.createdAt ? moment(e.createdAt).format('MMM D, YYYY, h:mm:ss a') : "",
                            "creator": e?.creator,
                            "id": e?.id,
                            "updatedAt": e?.updatedAt,
                        })
                        setComment(cmnt);
                    })
                }
                if (getDetails?.events) {
                    let groupedPeople = groupBy(getDetails?.events, "id");
                    setcommonMapview(groupedPeople);
                }
            })
            // Members
            await getTenantServiceRequestMembers(params.serviceId).then((res) => {
                let data = res?.data?.data;
                setAssignee(data);
            })

        } catch (err) {
            console.log(err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        pageLoad();
    }, []);

    // Update
    const UpdateTitleClick = () => {
        let data = {
            title: title,
        }
        patchTenantServiceRequest(params.serviceId, data).then((res) => {
            pageLoad();
            setName(false);
            toast.success("Service Request Title Updated");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const UpdateDescriptionClick = () => {
        let data = {
            description: description,
        }
        patchTenantServiceRequest(params.serviceId, data).then((res) => {
            pageLoad();
            setDesc(false);
            toast.success("Service Request Description Updated");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const descriptionCheckbox = async (event) => {
        const target = event.target;
        if (target.tagName.toLowerCase() === 'input' && target.getAttribute('type') === 'checkbox') {
            // Toggle the checked state of the checkbox
            const isChecked = target.hasAttribute('checked');
            if (isChecked) {
                target.removeAttribute('checked');
            } else {
                target.setAttribute('checked', '');
            }
            // Retrieve all checkbox elements
            const checkboxes = document.querySelectorAll('.tenant-service-request-html-description-checkbox');
            let newdata = "";
            Array.from(checkboxes)
                .map((checkbox) => {
                    newdata = checkbox.innerHTML
                })
            let data = { newHtmlDesc: newdata };
            await patchTenantServiceRequestDescriptionCheckbox(params.serviceId, data).then((res) => {
                toast.success("Service Request Description Updated");
                pageLoad();
            }).catch((err) => { toast.error(err.response.data.error.message) });
        }
    }

    const addAttachment = (e) => {
        let formData = new FormData();
        formData.append("attachment", e.target.files[0]);
        postTenantServiceRequestAttachments(params.serviceId, formData).then((res) => {
            console.log("file", res)
            pageLoad();
        })

    }

    const removeImage = (id) => {
        deleteTenantServiceRequestAttachments(params.serviceId, id).then((res) => {
            toast.info("Image Removed");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };

    const renderImages = () => {
        return attachFiles.map((item, index) => {
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={item.files} loading="lazy" alt="images" style={{ height: "110px", width: "175px", marginTop: "20px", cursor: "pointer" }} onClick={(e) => tenantImageClick(e, item.url)} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={() => { removeImage(item.id) }}>
                            <DeleteIcon color="error" />
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const removeAttachImage = (index) => {
        let toDelete;
        const newImages = images.filter((item, i) => {
            if (index === i) {
                toDelete = item;
                return false;
            } else {
                return true;
            }
        });
        setImages(newImages);
    };

    const handleImagesAdd = (e) => {
        setImages([...images, ...e.target.files]);
    }

    const attachImages = () => {
        return Array.from(images).map((item, index) => {
            const url = URL.createObjectURL(item)
            return (
                <div key={index}>
                    <Paper variant="outlined" square>
                        <img src={url} alt="images" style={{ height: "110px", width: "175px", marginTop: "20px" }} />
                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={() => { removeAttachImage(index) }}>
                            <DeleteIcon color="error" />
                        </p>
                    </Paper>
                </div>
            );
        });
    };

    const postClick = () => {
        let formData = new FormData();
        formData.append("content", content);
        Array.from(images).forEach(item => { formData.append("attachments", item) });
        postTenantServiceRequestComments(params.serviceId, formData).then((res) => {
            toast.success("Added Comment Successfully");
            pageLoad();
            setContent("");
            setImages([]);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const handleCommentContentChange = (e, index, comment) => {
        const newData = [...comment];
        newData[index].content = e.target.value;
        setComment(newData);
    }

    const handleClick = (e, id) => {
        e.preventDefault();
        setFileId(id);
        hiddenFileInput.current.click();
    };

    const addCommentAttachment = (e) => {
        let formData = new FormData();
        formData.append("attachment", e.target.files[0]);
        postTenantServiceRequestCommentsAttachment(params.serviceId, fileId, formData).then((res) => {
            toast.info("Comment Attachment Added");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const commentSaveClick = (e, id, cat, content) => {
        let data = {
            content: content,
        }
        patchTenantServiceRequestComments(params.serviceId, id, data).then((res) => {
            setcommentCondition(prevcommentCondition => ({ ...prevcommentCondition, [cat]: !prevcommentCondition[cat] }));
            toast.info("Updated Comment Successfully");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const removeCommentImage = (e, id, idd) => {
        deleteTenantServiceRequestCommentsAttachment(params.serviceId, id, idd).then((res) => {
            toast.info("Comment Attachment Removed");
            pageLoad();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const deleteClick = (e, id) => {
        deleteTenantServiceRequestComments(params.serviceId, id).then((res) => {
            pageLoad();
            toast.info("Comment Deleted");
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const navigateToMoveInMoveOut = (move) => {
        if (move?.type === "MoveIn") {
            navigate(`/tenant/lease-applications/${move?.leaseId}/moveinlease`);
        }
        if (move?.type === "MoveOut") {
            navigate(`/tenant/lease-applications/${move?.leaseId}/move-Out-inspection`)
        }
    }

    const activityCreatedBy = (service, assi) => {
        let name = ""
        assi && assi.forEach((e) => {
            if (service?.creator?.creator == e?.id && service?.creator?.type == e?.type) {
                name = `Created by` + ` ` + e?.name + ` ` + `on` + ` ` + moment(service?.createdAt).format('MMM D, YYYY, h:mm:ss A');
            }
        })
        return name;
    }

    const commentBoxName = (service, assi) => {
        let name = ""
        assi && assi.forEach((e) => {
            if (service?.creator?.creator == e?.id && service?.creator?.type == e?.type) {
                name = e.name;
            }
        })
        return name;
    }

    // Loader
    if (loading) {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress style={{ height: 70, width: 70 }} />
            </Box>
        );
    }

    return (
        <div style={{ padding: "20px" }}>
            <div style={{ textAlign: "center" }}>
                <h2>Service Request</h2>
            </div>
            {/* <CardContent className="contractor-flex-box">
                <div className="box-content-align">
                    <Box
                        sx={{
                            minWidth: 175,
                            minHeight: 100,
                            borderRadius: 5,
                            border: "1px solid #2196F3",
                            paddingTop: "10px"
                        }}>
                        {assignee.map((man, index) => (man.type == "Manager" &&
                            <div style={{ margin: "10px" }} key={{ index }}>
                                <p className="contractor-flex-title">Manager</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{man?.name}</p>
                                    <ToolTip
                                        phone={man?.email}
                                        email={man?.phone}
                                    />
                                </div>
                            </div>
                        ))}
                    </Box>
                </div>
                <div className="box-content-align">
                    {assignee.map((man, index) => (serviceRequest?.assignee?.assignee == man?.id && serviceRequest?.assignee?.type == man.type ?
                        <Box
                            sx={{
                                minWidth: 175,
                                minHeight: 100,
                                borderRadius: 5,
                                border: "1px solid #2196F3",
                                paddingTop: "10px"
                            }}
                            key={index}
                        >
                            <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                                <p className="contractor-flex-title">Assigned to</p>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{property?.groupPropertyName}</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{man?.name}</p>
                                    <ToolTip
                                        phone={man?.email}
                                        email={man?.phone}
                                    />
                                </div>
                            </div>
                        </Box>
                        : ""))}
                </div>
            </CardContent> */}
            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: '10px', marginTop: '20px', marginBottom: '20px', alignItems: 'center' }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
                    <div>
                        <div style={{ fontSize: "1.17rem", fontWeight: "bold" }}>service Request -{serviceRequest?.numberId}</div>
                    </div>
                    <div>
                        {serviceRequest?.priority === 0 ? <ReportProblemIcon style={{ color: "#F4C94C" }} /> : ""}
                        {serviceRequest?.priority === 1 ? <ReportProblemIcon style={{ color: "#F4C94C" }} /> : ""}
                        {serviceRequest?.priority === 2 ? <ErrorIcon style={{ color: "#DC3545" }} /> : ""}
                    </div>
                    {name === false ? (
                        <div style={{ fontSize: "1.17rem", fontWeight: "bold" }}>{serviceRequest?.title}</div>) : (
                        <div style={{ display: "flex", gap: '10px', flexWrap: 'wrap', alignItems: 'center' }}>
                            <TextField id="outlined-basic" variant="outlined"
                                sx={{ width: "350px" }}
                                value={title}
                                onChange={e => { setTitle(e.target.value) }} />
                            <Button color="success"
                                type="submit"
                                sx={{ fontSize: 15 }}
                                onClick={UpdateTitleClick}
                            >SAVE</Button>
                            <Button color="error"
                                type="submit"
                                sx={{ fontSize: 15 }}
                                onClick={() => setName(false)}
                            >CANCEL</Button>
                        </div>
                    )}
                </div>
                {name === false ?
                    <div>
                        <EditIcon style={{ color: "#2196F3", cursor: 'pointer' }} onClick={() => setName(true)} />
                    </div> : ""}
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
                <div style={{ fontSize: "1.17rem", fontWeight: "bold" }}>Description</div>
                {/* <div style={{ fontSize: "12px", fontWeight: "bold", color: "#6c757d" }}> Created by Akilesh Mon on {dateFormatWithTime(serviceRequest?.createdAt)}</div> */}
                {desc === false ?
                    <EditIcon style={{ color: "#2196F3" }} onClick={() => setDesc(true)} />
                    : ""}
            </div>
            <div style={{ marginBottom: '20px' }}>
                {desc === false ? (
                    <div className="tenant-service-request-html-description-checkbox" style={{ fontSize: "1.17rem", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: serviceRequest?.newHtmlDesc }} onClick={(e) => { descriptionCheckbox(e) }} />) : (
                    <div>
                        <TextField id="outlined-basic" label="Notes" variant="outlined"
                            sx={{ width: '50%' }}
                            rows={5}
                            multiline
                            placeholder="Description"
                            value={description}
                            onChange={e => { setDescription(e.target.value) }}
                        />
                        <div style={{ display: "flex", justifyContent: "right", gap: '10px', flexWrap: 'wrap', width: '50%' }}>
                            <Button color="success"
                                type="submit"
                                sx={{ fontSize: 15 }}
                                onClick={UpdateDescriptionClick}
                            >SAVE</Button>
                            <Button color="error"
                                type="submit"
                                sx={{ fontSize: 15 }}
                                onClick={() => setDesc(false)}
                            >CANCEL</Button>
                        </div>
                    </div>)}
            </div>
            <div style={{ marginBottom: '20px' }}>
                <div className="img-container">
                    {renderImages()}
                    {attachFiles?.length < 5 ?
                        <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
                            <input type="file" id="uploadBtn" accept="image/*"
                                onChange={(e) => addAttachment(e)}
                            />
                            <label htmlFor="uploadBtn" style={{ fontSize: 13 }}>
                                Drop images / click to Upload
                            </label>
                        </div> : ""}
                </div>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span style={{ fontSize: "1.17rem", fontWeight: "bold", marginRight: "8px" }}>Activity</span>
                <span style={{ color: '#6c757d' }}>{activityCreatedBy(serviceRequest && serviceRequest, assignee)}</span>
            </div>
            {serviceRequest?.moveInMoveOutInfo?.isCreated ?
                <div style={{ marginTop: "10px" }}>
                    <Button variant="outlined" color="success" sx={{ fontSize: 15 }} onClick={() => { navigateToMoveInMoveOut(serviceRequest?.moveInMoveOutInfo) }}>
                        View MoveIn/MoveOut Checklist
                    </Button>
                </div>
                : ""}
            <CardContent style={{ display: "flex", flexDirection: "column", boxSizing: "border-box" }}>
                <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box", width: "75%", marginLeft: "auto", marginRight: "auto" }}>
                    {Object.keys(commonMapview).map((cat, index) => (
                        <div key={index}>
                            {commonMapview[cat].map((f, t) => (
                                <div key={t}>
                                    {f.type !== 0 && f.type !== 8 && f.type !== 9 && f.type !== 7 ?
                                        <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "0.5rem", paddingTop: "0.5rem" }}>
                                            {assignee && assignee ? assignee.map((details, i) => (
                                                <div key={i}>
                                                    {details.id === commonMapview[cat][t].details.openerId ?
                                                        <span style={{ fontSize: "1em" }}>
                                                            <small style={{ fontSize: "1em" }}>
                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                            </small>
                                                            <small style={{ fontSize: "1em" }}>reopened on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span> : ""}
                                                    {details.id === commonMapview[cat][t].details.closerId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            <small style={{ fontSize: "1em" }}>
                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                            </small>
                                                            <small style={{ fontSize: "1em" }}>closed on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {commonMapview[cat][t].details.newAssigneeId === null && commonMapview[cat][t].details.unAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.unAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>unassigned </small>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        {details.id === commonMapview[cat][t].details.changerId ?
                                                                            <strong style={{ fontSize: "1em" }}>{details.name} </strong> : ""}
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ("")}
                                                        </span> : ""}
                                                    {commonMapview[cat][t].details.unAssigneeId === null && commonMapview[cat][t].details.newAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>assigned to </small>
                                                                    <small style={{ fontSize: "1em" }}>
                                                                        {details.id === commonMapview[cat][t].details.changerId ? (
                                                                            <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                    </small>
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ("")}
                                                        </span> : ""}
                                                    {details.id === commonMapview[cat][t].details.appointeeContractorId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            {assignee && assignee ? assignee.map((details, index) => (
                                                                <small style={{ fontSize: "1em" }} key={index}>
                                                                    {details.id === commonMapview[cat][t].details.changerId ? (
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                </small>)) : undefined}
                                                            <small style={{ fontSize: "1em" }}>appoined <strong>{details.name}</strong> as contractor on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {details.id === commonMapview[cat][t].details.unappointeeContractorId ? (
                                                        <span style={{ fontSize: "1em" }}>
                                                            {assignee && assignee ? assignee.map((details, index) => (
                                                                <small style={{ fontSize: "1em" }} key={index}>
                                                                    {details.id === commonMapview[cat][t].details.changerId ? (
                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                </small>)) : undefined}
                                                            <small style={{ fontSize: "1em" }}>unappoined <strong>{details.name}</strong> as contractor on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                        </span>) : ("")}
                                                    {commonMapview[cat][t].details.unAssigneeId !== null && commonMapview[cat][t].details.newAssigneeId !== null ?
                                                        <span>
                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                <span style={{ fontSize: "1em" }}>
                                                                    {assignee && assignee ? assignee.map((details, index) => (
                                                                        <small style={{ fontSize: "1em" }} key={index}>
                                                                            {details.id === commonMapview[cat][t].details.changerId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>assigned to </small>
                                                                    {assignee && assignee ? assignee.map((details, index) => (
                                                                        <small style={{ fontSize: "1em" }} key={index}>
                                                                            {details.id === commonMapview[cat][t].details.newAssigneeId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>and unassigned </small>
                                                                    {assignee && assignee ? assignee.map((details, index) => (
                                                                        <small style={{ fontSize: "1em" }} key={index}>
                                                                            {details.id === commonMapview[cat][t].details.unAssigneeId ? (
                                                                                <strong style={{ fontSize: "1em" }}>{details.name} </strong>) : ""}
                                                                        </small>)) : undefined}
                                                                    <small style={{ fontSize: "1em" }}>on {f.time ? moment(f?.time).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                </span>) : ""}
                                                        </span> : ""}
                                                </div>)) : undefined}
                                        </div> : ""}
                                    {f.type !== 0 && f.type !== 8 && f.type !== 9 ?
                                        <div>
                                            {commonMapview[cat][t].details !== undefined ?
                                                <div>
                                                    {comment.map((k, ki) => (
                                                        <div key={ki}>
                                                            {commonMapview[cat][t].details.commentId === k.id ?
                                                                <div>
                                                                    <div style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "1rem", paddingTop: "1rem" }}>
                                                                        <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "flex-start" }}>
                                                                            <div>
                                                                                <Avatar src="/broken-image.jpg" style={{ backgroundColor: "whitesmoke", color: "#FF805D", marginTop: "0.5rem", marginRight: "1rem" }} />
                                                                            </div>
                                                                            <div style={{ display: 'flex', boxSizing: "border-box", flexDirection: "column", flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                                                                                <div style={{ marginBottom: "10px" }}>
                                                                                    {assignee && assignee ? assignee.map((details, index) => (
                                                                                        <span style={{ fontSize: "1em" }} key={index}>
                                                                                            {k.id == commonMapview[cat][t].details.commentId ? (
                                                                                                <small style={{ fontSize: "1em" }}>
                                                                                                    {k.creator.creator == details.id ?
                                                                                                        <strong style={{ fontSize: "1em" }}>{details.name} </strong>
                                                                                                        : ""}
                                                                                                </small>) : ("")}
                                                                                        </span>)) : null}
                                                                                    <small style={{ fontSize: "1em" }}>commented on {k.createdAt ? moment(k.createdAt).format('MMM D, YYYY, h:mm:ss a') : ""}</small>
                                                                                </div>
                                                                                <div>
                                                                                    {k.attachment !== undefined ?
                                                                                        <div className="img-container">
                                                                                            {k.attachment.map((j, k) => (
                                                                                                <div key={k}>
                                                                                                    {commentFiles.map((g, h) => (
                                                                                                        <div>
                                                                                                            {commentFiles[h].id == j.id ? (
                                                                                                                <Paper variant="outlined" square sx={{ height: 160 }}>
                                                                                                                    <img
                                                                                                                        style={{
                                                                                                                            height: "110px",
                                                                                                                            width: "175px",
                                                                                                                            marginTop: "20px",
                                                                                                                            cursor: "pointer"
                                                                                                                        }}
                                                                                                                        loading="lazy"
                                                                                                                        src={commentFiles[h].files}
                                                                                                                        onClick={(e) => tenantImageClick(e, j.url)}
                                                                                                                    />
                                                                                                                    {commentCondition[cat] ?
                                                                                                                        <p style={{ textAlign: "center", fontSize: 13, color: "#7A8184", cursor: "pointer" }} onClick={(e) => { removeCommentImage(e, commonMapview[cat][t].details.commentId, j.id) }}>
                                                                                                                            <DeleteIcon color="error" />
                                                                                                                        </p> : ""}
                                                                                                                </Paper>
                                                                                                            ) : (
                                                                                                                null
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            ))}
                                                                                        </div> : ""}
                                                                                </div>
                                                                                {!commentCondition[cat] ?
                                                                                    <div style={{ paddingTop: "1rem" }}>
                                                                                        {k.content}
                                                                                    </div> : ""}
                                                                                {commentCondition[cat] ?
                                                                                    <TextField id="outlined-basic" label="Notes" variant="outlined"
                                                                                        fullWidth={true}
                                                                                        rows={3}
                                                                                        sx={{ mb: 5, mt: 3 }}
                                                                                        multiline
                                                                                        placeholder="Write a Comment"
                                                                                        value={k.content}
                                                                                        onChange={(e) => { handleCommentContentChange(e, ki, comment) }}
                                                                                    /> : null}
                                                                                {commentCondition[cat] ?
                                                                                    <CardContent style={{ display: "flex", justifyContent: "right", padding: "0px" }}>
                                                                                        <label htmlFor="uploadBtnEdit">
                                                                                            <AttachFileIcon
                                                                                                style={{
                                                                                                    fontSize: "25px",
                                                                                                    cursor: "pointer",
                                                                                                    marginTop: "3px",
                                                                                                }}
                                                                                                onClick={(e) => handleClick(e, k.id)}
                                                                                            />
                                                                                        </label>
                                                                                        <div>
                                                                                            <label>
                                                                                                <input
                                                                                                    ref={hiddenFileInput}
                                                                                                    type="file"
                                                                                                    id="uploadBtnEdit"
                                                                                                    className="inputfile"
                                                                                                    onChange={(e) => addCommentAttachment(e, fileId)}
                                                                                                />
                                                                                            </label>
                                                                                        </div>
                                                                                        <Button color="error"
                                                                                            type="submit"
                                                                                            sx={{ fontWeight: "bold" }}
                                                                                            onClick={() => { setcommentCondition(prevcommentCondition => ({ ...prevcommentCondition, [cat]: !prevcommentCondition[cat] })) }}>
                                                                                            CANCEL
                                                                                        </Button>
                                                                                        <Button color="success"
                                                                                            variant="contained"
                                                                                            type="submit"
                                                                                            sx={{ fontWeight: "bold" }}
                                                                                            onClick={(e) => commentSaveClick(e, k.id, cat, k.content)}
                                                                                        >
                                                                                            Save
                                                                                        </Button>
                                                                                    </CardContent> : ""}
                                                                                {k?.creator?.type === "Tenant" ?
                                                                                    <div>
                                                                                        {!commentCondition[cat] ?
                                                                                            <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-end", alignContent: "flex-end", justifyContent: "flex-end", marginTop: '1rem' }}>
                                                                                                <Button color="primary"
                                                                                                    type="submit"
                                                                                                    size="small"
                                                                                                    sx={{ fontWeight: "bold" }}
                                                                                                    onClick={() => {
                                                                                                        toggle(cat);
                                                                                                    }}>
                                                                                                    Edit
                                                                                                </Button>
                                                                                                <Button color="error"
                                                                                                    type="submit"
                                                                                                    size="small"
                                                                                                    sx={{ fontWeight: "bold" }}
                                                                                                    onClick={(e) => deleteClick(e, k.id)}
                                                                                                >
                                                                                                    Delete
                                                                                                </Button>
                                                                                            </div> : null}
                                                                                    </div>
                                                                                    : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ""}
                                                        </div>))}
                                                </div> : ""}
                                        </div> : ""}
                                </div>))}
                        </div>))}
                </div>
                <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box", width: "75%", marginLeft: "auto", marginRight: "auto" }}>
                    <div style={{ paddingBottom: "1rem", borderBottom: "1px solid #dee2e6", marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "column", boxSizing: "border-box" }}>
                            <div style={{ display: "flex", flexDirection: "row", boxSizing: "border-box", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "flex-start" }}>
                                <div>
                                    <Avatar src="/broken-image.jpg" style={{ backgroundColor: "whitesmoke", color: "#FF805D", marginTop: "0.5rem", marginRight: "1rem" }} />
                                </div>
                                <div style={{ display: 'flex', boxSizing: "border-box", flexDirection: "column", flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                                    <span style={{ fontSize: "1em", marginTop: "10px" }}>
                                        <small style={{ fontSize: "1em" }}>
                                            <strong style={{ fontSize: "1em" }}>{commentBoxName(serviceRequest && serviceRequest, assignee)}</strong>
                                        </small>
                                    </span>
                                    <div className="img-container">
                                        {attachImages()}
                                    </div>
                                    <TextField id="outlined-basic" variant="outlined"
                                        fullWidth={true}
                                        sx={{ pb: 5 }}
                                        rows={3}
                                        multiline
                                        placeholder="Write a comment"
                                        value={content}
                                        onChange={(e) => {
                                            setContent(e.target.value);
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", boxSizing: 'border-box', alignItems: "center", alignContent: "center", justifyContent: "flex-end" }}>
                            <div>
                                <input type="file" id="uploadButton" accept="image/*"
                                    onChange={handleImagesAdd}
                                    className="inputfile"
                                    multiple
                                />
                                <label htmlFor="uploadButton">
                                    <AttachFileIcon style={{ fontSize: 30, marginRight: "20px" }} /></label>
                            </div>
                            <div>
                                <Button variant="contained" color="success"
                                    type="submit"
                                    style={{ fontSize: 15 }}
                                    onClick={postClick}
                                >POST</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}
export default TenantServiceRequest;