import { Box, Button, CardContent, Typography, TextField, Modal, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getTenantLeaseApplication, sendInvitaionink, postCoapplicantInvite, patchCoapplicantInvite, withDrawApplication } from "../../../../services";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Container } from "@mui/system";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TenantLeaseApplicationEditPage from ".";
import { formatPhoneNumber } from "../../../../components/phoneNumberFormat";

function RentalStatus() {
  const [open, setOpen] = useState(false);
  let params = useParams();

  const [item, setItem] = useState();
  const [applicantStatus, setapplicantStatus] = useState([]);
  const [buttonStatus, setbuttonStatus] = useState(false);
  const [modbuttonStatus, setmodbuttonStatus] = useState(false);
  const [status, setstatus] = useState(false);
  const [coAppl, setcoAppl] = useState(false);
  const [addco, setaddco] = useState(false);

  const [coapplicant, setcoapplicant] = useState({
    firstName: {
      value: "",
      error: {
        message: "",
      },
    },
    lastName: {
      value: "",
      error: {
        message: "",
      },
    },
    email: {
      value: "",
      error: {
        message: "",
      },
    },
    phoneNumber: {
      value: "",
      error: {
        message: "",
      },
    },
  });

  const [SubmittedStatus, setSubmittedStatus] = useState(false);

  const pageLoad1 = () => {
    getTenantLeaseApplication(params.applicationId).then((res) => {
      console.log("ddd", res.data.data.status)
      if (res.data.data.status == "APPLICANT_SUBMITTED") {
        setSubmittedStatus(true);
      }
    }).catch(err => { console.log(err) });
  }

  const handleInput = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    coapplicant.phoneNumber.value = formattedPhoneNumber;
  };

  const onChangeHandler = (event, key) => {
    let validatedState = validateco(key, event.target.value, undefined);
    setcoapplicant(validatedState);
    let val = { ...coapplicant };
    if (
      val.firstName.value == "" ||
      val.phoneNumber.value == "" ||
      val.email.value == "" ||
      val.firstName.error.message != "" ||
      val.phoneNumber.error.message != "" ||
      val.email.error.message != ""
    ) {
      setbuttonStatus(false);
    } else {
      setbuttonStatus(true);
    }
  };

  const validateco = (name, value, message) => {
    const newState = { ...coapplicant };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;
    var regexpEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    switch (name) {
      case "firstName":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "First Name is required";
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.message = "";
        } return newState;
      case "lastName":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Last Name is required";
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.message = "";
        }
        return newState;
      case "phoneNumber":
        if (!value || value == "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Phone number is Required";
          newState[name].error.message = errorMessage;
        } else if (
          !value.match(
            /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/
          )
        ) {
          errorMessage = errorMessage !== null ? errorMessage : "Please enter valid Phone number";
          newState[name].error.message = errorMessage;
        } else if (
          value.match(
            /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/
          )
        ) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.message = "";
        }
        return newState;
      case "email":
        if (!value || value == "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Email is required";
          newState[name].error.message = errorMessage;
        } else if (!value.match(regexpEmail)) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Please enter valid Email";
          newState[name].error.message = errorMessage;
        } else if (value.match(regexpEmail)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.message = "";
        }
        return newState;
      default: {
        return newState;
      }
    }
  };

  // pageLoad
  const pageLoad = () => {
    const statusGet = [...applicantStatus];
    statusGet.length = 0;
    getTenantLeaseApplication(params.applicationId).then((res) => {
      const responseData = res.data.data;
      if (responseData.applicants[0].status != "APPLICANT_STARTED") {
        setstatus(true);
      }

      var statusMain = "";
      if (responseData.applicants[0].status == "APPLICANT_STARTED")
        statusMain = "Started";
      if (responseData.applicants[0].status == "APPLICANT_SUBMITTED")
        statusMain = "Submitted";
      if (responseData.applicants[0].status == "APPLICANT_WITHDRAWN")
        statusMain = "Withdrawn";
      if (responseData.applicants[0].status == "MANAGER_REJECTED")
        statusMain = "Rejected";
      if (responseData.applicants[0].status == "MANAGER_ACCEPTED_AND_PREPARING_LEASE_AGREEMENT")
        statusMain = "Accepted and preparing lease agreement";
      if (responseData.applicants[0].status == "MANAGER_SENT_LEASE_AGREEMENT")
        statusMain = "Sent lease agreement";
      if (responseData.applicants[0].status == "APPLICANT_LEASE_AGREEMENT_REJECTED")
        statusMain = "Lease agreement rejected";
      if (responseData.applicants[0].status == "APPLICANT_LEASE_AGREEMENT_SIGNED")
        statusMain = "Lease agreement signed";
      if (responseData.applicants[0].status == "MANAGER_LEASE_AGREEMENT_REJECTED")
        statusMain = "Lease agreement rejected";
      if (responseData.applicants[0].status == "MANAGER_LEASE_AGREEMENT_REVISING")
        statusMain = "Lease agreement revising";
      if (responseData.applicants[0].status == "MANAGER_SET_UP_LEASE_SUCCESSFULLY")
        statusMain = "Lease successfully";
      if (responseData.applicants[0].status == "WAITING_FOR_MANAGER")
        statusMain = "Waiting for manager";
      if (responseData.applicants[0].status == "WAITING_FOR_APPLICANT")
        statusMain = "Waiting for applicant";

      setItem(statusMain);
      let datas = { ...responseData };

      if (datas.applicants.length < 2) {
        setaddco(true);
      } else {
        setaddco(false);
      }
      var status = "";

      datas.applicants.map((el, i) => {
        if (el.status == "APPLICANT_NOT_STARTED") status = "Not Started";
        if (el.status == "APPLICANT_STARTED") status = "Started";
        if (el.status == "APPLICANT_SUBMITTED") status = "Submitted";
        if (el.status == "APPLICANT_WITHDRAWN") status = "Withdrawn";
        if (el.status == "MANAGER_REJECTED") status = "Rejected";
        if (el.status == "MANAGER_ACCEPTED_AND_PREPARING_LEASE_AGREEMENT") status = "Accepted and preparing lease agreement";
        if (el.status == "MANAGER_SENT_LEASE_AGREEMENT") status = "Sent lease agreement";
        if (el.status == "APPLICANT_LEASE_AGREEMENT_REJECTED") status = "Lease agreement rejected";
        if (el.status == "APPLICANT_LEASE_AGREEMENT_SIGNED") status = "Lease agreement signed";
        if (el.status == "MANAGER_LEASE_AGREEMENT_REJECTED") status = "Lease agreement rejected";
        if (el.status == "MANAGER_LEASE_AGREEMENT_REVISING") status = "Lease agreement revising";
        if (el.status == "MANAGER_SET_UP_LEASE_SUCCESSFULLY") status = "Lease successfully";
        if (el.status == "WAITING_FOR_MANAGER") status = "Waiting for manager";
        if (el.status == "WAITING_FOR_APPLICANT") status = "Waiting for applicant";
        statusGet.push({
          fullName: el.firstName + " " + el.lastName,
          id: el.id,
          email: el.email,
          status: status,
        });
      });
      setapplicantStatus(statusGet);
    })
  };

  useEffect(() => {
    pageLoad();
    pageLoad1();
  }, []);

  const modify = (e) => {
    e.preventDefault();
    getTenantLeaseApplication(params.applicationId).then((res) => {
      const responseData = res.data.data;
      console.log(responseData);
      let getData = { ...responseData };
      let val = { ...coapplicant };
      getData.applicants.map((r, t) => {
        val.firstName.value = r.firstName;
        val.lastName.value = r.lastName;
        val.phoneNumber.value = r.phoneNumber;
        val.email.value = r.email;
      });
      setcoapplicant(val)
      setmodbuttonStatus(true);
      setmodbuttonStatus(true);
    });
    setcoAppl(true);
    setOpen(true);
  };

  const resendInvitation = (e) => {
    e.preventDefault();
    var id = "";
    getTenantLeaseApplication(params.applicationId).then((res) => {
      const responseData = res.data.data;
      console.log(responseData)
      let getData = { ...responseData };
      getData.applicants.map((r, t) => {
        id = r.id;
        console.log("id", id)
      });
      sendInvitaionink(params.applicationId, id).then((res) => {
        toast.success("Application link sent Successfully")
      })
    })
  };

  const onShowhide = (e, status) => {
    e.preventDefault();
    if (status == "plus") {
      setcoAppl(true);
      setOpen(true);
    }
    if (status == "minus") {
      setcoAppl(false);
      setOpen(false);
      let val = { ...coapplicant };
      val.firstName.value = "";
      val.phoneNumber.value = "";
      val.email.value = "";
      val.lastName.value = "";
      setcoapplicant(val);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    let val = { ...coapplicant };
    if (
      val.firstName.value == "" ||
      val.phoneNumber.value == "" ||
      val.email.value == "" ||
      val.firstName.error.message != "" ||
      val.phoneNumber.error.message != "" ||
      val.email.error.message != ""
    ) {
      setbuttonStatus(false);
    } else {
      setbuttonStatus(true);
      let detail = {
        firstName: val.firstName.value,
        lastName: val.lastName.value,
        phoneCountryCode: "+1",
        phoneNumber: val.phoneNumber.value,
        email: val.email.value
      };
      postCoapplicantInvite(params.applicationId, detail).then((res) => {
        let responseData = res.data;
        setcoAppl(false);
        setOpen(false);
        pageLoad();
      })
    }
  };
  const handleModify = () => {

    const res = withDrawApplication(params.applicationId).then((response) => {
      window.location.reload();
    })

  };
  const updateClick = (e) => {
    e.preventDefault();
    let val = { ...coapplicant };
    let detail = {
      firstName: val.firstName.value,
      lastName: val.lastName.value,
      phoneCountryCode: "+1",
      phoneNumber: val.phoneNumber.value,
      email: val.email.value,
    }
    getTenantLeaseApplication(params.applicationId).then((res) => {
      const responseData = res.data.data;
      let getData = { ...responseData };
      var aplicantId = "";
      getData.applicants.map((r, t) => {
        aplicantId = r.id;
      });
      patchCoapplicantInvite(params.applicationId, aplicantId, detail).then((res) => {
        let responseData = res.data;
        setcoAppl(false);
        setOpen(false);
        pageLoad();
      })
    })
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center', width: "100%", maxWidth: "1350px", }}>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TenantLeaseApplicationEditPage />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Container style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'row', placeContent: 'stretch space-between', alignItems: 'stretch', flexWrap: 'wrap', p: 0 }} >
                <CardContent sx={{ pt: 4 }}>
                  <Typography variant="subtitle1">Application Status</Typography>
                  <CardContent sx={{ py: 0 }}>
                    <Typography variant="body1" color="#ff805d">
                      {item}
                    </Typography>
                  </CardContent>
                </CardContent>
                <CardContent >
                  <CardContent sx={{ pl: 0 }}>
                    <Typography variant="subtitle1">Applicants Status</Typography>
                  </CardContent>
                  {applicantStatus && applicantStatus.length
                    ? applicantStatus.map((r, t) => (
                      <CardContent sx={{ border: "1px solid #c4c4c4", p: 2, borderRadius: '10px', maxWidth: 500, mb: 2 }} key={t}>
                        <CardContent
                          sx={{ display: 'flex', flexDirection: 'row', placeContent: 'stretch space-between', alignIttems: 'stretch', flexWrap: 'wrap', p: 0 }}
                          style={{ paddingBottom: '0px' }}>
                          <CardContent sx={{ py: 0 }} >
                            <Typography variant="subtitle1">{r.fullName}</Typography>
                            <Typography variant="body2">{r.email}</Typography>
                          </CardContent>
                          <CardContent sx={{ py: 0 }}>
                            <Typography variant="subtitle2" color="#ff805d" sx={{ textAlign: 'right' }}>{r.status}</Typography>
                          </CardContent>
                        </CardContent>
                        {r.status == "Not Started" ? (
                          <CardContent sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', }} style={{ paddingBottom: '0px' }}>
                            <Button
                              sx={{
                                fontWeight: "bold",
                                fontSize: 14,
                                letterSpacing: 2,
                                color: "#ff805d"
                              }}
                              style={{ width: '200px' }}
                              size="small"
                              type="submit"
                              onClick={(e) => modify(e)}
                            >Modify</Button>
                            <Button
                              sx={{
                                fontWeight: "bold",
                                fontSize: 14,
                                letterSpacing: 2,
                                color: "#0000FF"
                              }}
                              size="small"
                              type="submit"
                              onClick={(e) => resendInvitation(e)}
                            >RESEND INVITATION</Button>

                          </CardContent>
                        ) : (
                          ""
                        )}
                      </CardContent>
                    ))
                    : ""}
                  {SubmittedStatus === true ? (
                    <CardContent>
                      <CardContent style={{ float: "right" }}>
                        <Button variant="contained" color="warning"
                          onClick={(e) => handleModify(e)}

                        >Modify</Button>
                      </CardContent>
                    </CardContent>
                  ) : (
                    ""
                  )}
                  <CardContent>
                    {
                      status == false ? (
                        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                          {coAppl == false && addco == true ? (
                            <CardContent >
                              <Fab color="success" aria-label="add" onClick={(e) => onShowhide(e, "plus")}>
                                <AddIcon />
                              </Fab>
                            </CardContent>
                          ) : (
                            ""
                          )}
                        </CardContent>
                      ) : (
                        ""
                      )}
                  </CardContent>
                </CardContent>
              </CardContent>
              <Modal
                open={open}>
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 'auto',
                  bgcolor: 'white',
                  borderRadius: '5px',
                  p: 1
                }}>
                  <CardContent>
                    <Typography variant="h5" component="h2" sx={{ textAlign: 'center' }}>
                      Invite Co-Applicant
                    </Typography>
                  </CardContent>
                  <CardContent sx={{ pb: 0 }}>
                    <TextField
                      variant="outlined"
                      label="First Name *"
                      type="text"
                      size="small"
                      onChange={(event) => onChangeHandler(event, "firstName")}
                      value={coapplicant.firstName.value}
                      error={
                        coapplicant.firstName.error.message != ""
                          ? coapplicant.firstName.error.message
                          : ""
                      }
                      helperText={
                        coapplicant.firstName.error.message != "" ? (
                          <span style={{ color: "red" }}>
                            {coapplicant.firstName.error.message}
                          </span>
                        ) : (
                          " "
                        )}>
                    </TextField>
                  </CardContent>
                  <CardContent sx={{ pb: 0 }}>
                    <TextField
                      variant="outlined"
                      label="Last Name"
                      type="text"
                      size="small"
                      onChange={(event) => onChangeHandler(event, "lastName")}
                      value={coapplicant.lastName.value}
                      helperText={
                        coapplicant.lastName.error.message != "" ? (
                          <span style={{ color: "red" }}>"</span>
                        ) : (
                          " "
                        )}>
                    </TextField>
                  </CardContent>
                  <CardContent sx={{ pb: 0 }}>
                    <TextField
                      variant="outlined"
                      label="Phone Number"
                      type="text"
                      size="small"
                      onChange={(event) => {
                        onChangeHandler(event, "phoneNumber");
                        handleInput(event);
                      }}
                      value={coapplicant.phoneNumber.value}
                      error={
                        coapplicant.phoneNumber.error.message != ""
                          ? coapplicant.phoneNumber.error.message
                          : ""
                      }
                      helperText={
                        coapplicant.phoneNumber.error.message != "" ? (
                          <span style={{ color: "red" }}>
                            {coapplicant.phoneNumber.error.message}
                          </span>
                        ) : (
                          " "
                        )}>
                    </TextField>
                  </CardContent>
                  <CardContent sx={{ pb: 0 }}>
                    <TextField
                      label="Email *"
                      variant="outlined"
                      type="email"
                      size="small"
                      onChange={(event) => onChangeHandler(event, "email")}
                      value={coapplicant.email.value}
                      error={
                        coapplicant.email.error.message != ""
                          ? coapplicant.email.error.message
                          : ""
                      }
                      helperText={
                        coapplicant.email.error.message != "" ? (
                          <span style={{ color: "red" }}>
                            {coapplicant.email.error.message}
                          </span>
                        ) : (
                          " "
                        )}>
                    </TextField>
                  </CardContent>
                  <CardContent sx={{ display: 'flex', justifyContent: "space-around" }}>
                    {modbuttonStatus == false ? (
                      <div>
                        {buttonStatus == true ? (
                          <Button
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                              letterSpacing: 2,
                            }}
                            size="small"
                            variant="contained"
                            type="submit"
                            color="warning"
                            onClick={(e) => submit(e)}
                          >Save</Button>
                        ) : (
                          <Button
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                              letterSpacing: 2,
                            }}
                            size="small"
                            variant="contained"
                            type="submit"
                            color="warning"
                            disabled="true"
                            name={"automobiles"}
                            title="Please fill in all the required fields."
                          >Save</Button>
                        )}
                      </div>
                    ) : (
                      <div>
                        {buttonStatus == true ? (
                          <Button
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                              letterSpacing: 2,
                            }}
                            size="small"
                            variant="contained"
                            type="submit"
                            color="warning"
                            onClick={(e) => updateClick(e)}
                          >Update</Button>
                        ) : (
                          <Button
                            sx={{
                              fontWeight: "bold",
                              fontSize: 14,
                              letterSpacing: 2,
                            }}
                            size="small"
                            variant="contained"
                            type="submit"
                            color="warning"
                            disabled="true"
                            title="Please fill in all the required fields."
                          >Update</Button>
                        )}
                      </div>
                    )}
                    <Button
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                        letterSpacing: 2,
                      }}
                      size="small"
                      variant="contained"
                      type="submit"
                      color="warning"
                      onClick={(e) => onShowhide(e, "minus")}
                    >CANCEL</Button>
                  </CardContent>
                </Box>
              </Modal>
              <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
            </Container>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default RentalStatus;