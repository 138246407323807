import { Button, TextField, Typography, InputAdornment, IconButton, Grid } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import React, { useState } from "react";
import { changePassword } from "../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  const [isloading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError(!validateEmail(emailValue));
    setEmailHelperText(validateEmail(emailValue) ? "" : "Please enter the valid email address");
  };

  const validateEmail = (email) => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const forgotPassword = () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailHelperText("Please enter the valid email address");
      return;
    }
    setLoading(true);
    changePassword(email)
      .then((res) => {
        if (res.data) {
          toast.success("Reset Password Link has been send to your email");
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.error.message);
      });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={6} lg={3} sx={{ boxShadow: { xs: 'none', sm: 'none', md: 'none', lg: '0 10px 20px rgba(53, 60, 115, 0.15)' }, borderRadius: '10px', padding: '30px 40px 30px 40px', }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography variant="h4" textAlign={"center"}>Forgot Password</Typography>
          <TextField
            error={emailError}
            helperText={emailHelperText}
            value={email}
            onChange={(e) => {
              handleEmailChange(e);
            }}
            margin="dense"
            required
            type="email"
            label="Email"
            variant="outlined"
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled="true">
                    <EmailIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            color="warning"
            sx={{ fontSize: 15 }}
            variant="contained"
            fullWidth={true}
            disabled={isloading}
            onClick={() => forgotPassword()}
          >
            {`${isloading ? "Loading" : "Recover"}`}
          </Button>
        </div>
      </Grid>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Grid>
  );
}

export default ForgotPassword;
