import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formNames, usStates } from "../../../../helpers";
import { editTenantLeaseApplication, getTenantLeaseApplication, withDrawApplication } from "../../../../services";
import { FaTrash } from "react-icons/fa";
import { Button, Card, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FaStepForward } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TenantLeaseApplicationEditPage from ".";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MenuProps } from "../../../../components/MaterialUiSelectMenuStyle";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";

function OccupantsForm() {
    let params = useParams();
    const navigate = useNavigate();

    const [adults, setAdults] = useState([]);
    const [adultName, setAdultName] = useState("");
    const [adultNameError, setAdultNameError] = useState("");
    const [adultRelation, setAdultRelation] = useState("");
    const [adultRelationError, setAdultRelationError] = useState("");

    const [minors, setMinors] = useState([]);
    const [minorName, setMinorName] = useState("");
    const [minorNameError, setMinorNameError] = useState("");
    const [minorDOB, setMinorDOB] = useState(null);
    const [minorDOBError, setMinorDOBError] = useState("");

    const [pets, setPets] = useState([]);
    const [petName, setPetName] = useState("");
    const [petNameError, setPetNameError] = useState("");
    const [petDescription, setPetDescription] = useState("");
    const [petDescriptionError, setPetDescriptionError] = useState("");

    const [automobiles, setAutomobiles] = useState([]);
    const [automobileYear, setAutomobileYear] = useState("");
    const [automobileYearError, setAutomobileYearError] = useState("");
    const [automobileMake, setAutomobileMake] = useState("");
    const [automobileMakeError, setAutomobileMakeError] = useState("");
    const [automobileModel, setAutomobileModel] = useState("");
    const [automobileModelError, setAutomobileModelError] = useState("");
    const [automobileLicense, setAutomobileLicense] = useState("");
    const [automobileLicenseError, setAutomobileLicenseError] = useState("");
    const [automobileState, setAutomobileState] = useState("");
    const [automobileStateError, setAutomobileStateError] = useState("");

    const [FieldsDisabled, setFieldsDisabled] = useState(false);
    const [SubmittedStatus, setSubmittedStatus] = useState(false);
    const [Modify, setModify] = useState(false);

    const pageLoad = async () => {
        await getTenantLeaseApplication(params.applicationId).then((res) => {
            let data = res?.data?.data;
            if (data) {
                setAdults(data?.occupants?.adults);
                setMinors(data?.occupants?.minors);
                setPets(data?.occupants?.pets);
                setAutomobiles(data?.automobiles);
            }
            if (data?.status == "APPLICANT_SUBMITTED") {
                setFieldsDisabled(true);
                setSubmittedStatus(true);
            }
            if (data?.status == "APPLICANT_WITHDRAWN") {
                setFieldsDisabled(false);
                setModify(true);
            }
        }).catch(err => { console.log(err) });
    }

    useEffect(() => {
        pageLoad();
    }, []);

    const renderAdults = () => {
        const editAdultName = (index, value) => {
            setAdults((prevAdults) =>
                prevAdults.map((adult, i) =>
                    i === index ? { ...adult, name: value } : adult
                )
            );
        }
        const editAdultRelationship = (index, relation) => {
            setAdults((prevAdults) =>
                prevAdults.map((adult, i) =>
                    i === index ? { ...adult, relationship: relation } : adult
                )
            );
        }
        return adults.map((value, index) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }} key={index}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label="Name"
                                placeholder="Enter name"
                                margin="dense"
                                variant="outlined"
                                fullWidth={true}
                                value={value.name}
                                onChange={e => { editAdultName(index, e.target.value) }}
                                disabled={FieldsDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label="Relation"
                                placeholder="Eg Father.."
                                margin="dense"
                                variant="outlined"
                                fullWidth={true}
                                value={value.relationship}
                                onChange={e => { editAdultRelationship(index, e.target.value) }}
                                disabled={FieldsDisabled}
                            />
                        </Grid>
                    </Grid>
                    <div onClick={() => { handleRemoveAdultClick(index) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><DeleteIcon color="error" /></div>
                </div>
            );
        });
    }

    const handleAddAdultClick = () => {
        let error = false;

        if (adultName === "") {
            setAdultNameError("Name cannot be empty");
            error = true;
        } else {
            setAdultNameError("");
        }
        if (adultRelation === "") {
            setAdultRelationError("Relation cannot be empty");
            error = true;
        } else {
            setAdultRelationError("");
        }

        if (error) return;

        let newAdults = [...adults];
        newAdults.push({
            name: adultName,
            relationship: adultRelation,
        })
        setAdults(newAdults);
        setAdultName("");
        setAdultRelation("");
    }

    const handleRemoveAdultClick = (index) => {
        let newAdults = adults.filter((value, i) => {
            return index !== i;
        });
        setAdults(newAdults);
    }

    const renderMinors = () => {
        const editMinorName = (index, value) => {
            setMinors((prevMinors) =>
                prevMinors.map((minor, i) =>
                    i === index ? { ...minor, name: value } : minor
                )
            );
        }
        const editMinorDob = (index, date) => {
            setMinors((prevMinors) =>
                prevMinors.map((minor, i) =>
                    i === index ? { ...minor, dateOfBirth: date } : minor
                )
            );
        }
        return minors.map((value, index) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }} key={index}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label="Name"
                                placeholder="Enter name"
                                margin="dense"
                                variant="outlined"
                                fullWidth={true}
                                value={value?.name}
                                onChange={e => { editMinorName(index, e.target.value) }}
                                disabled={FieldsDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={["day", "month", "year"]}
                                    label="Date of Birth"
                                    value={value?.dateOfBirth}
                                    onChange={newValue => { editMinorDob(index, newValue) }}
                                    renderInput={(params) => <TextField {...params} margin="dense" disabled={FieldsDisabled} fullWidth={true} />} />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <div onClick={() => { handleRemoveMinorClick(index) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><DeleteIcon color="error" /></div>
                </div>
            );
        });
    }

    const handleAddMinorClick = () => {
        let error = false;

        if (minorName === "") {
            setMinorNameError("Name cannot be empty");
            error = true;
        } else {
            setMinorNameError("");
        }
        if (!minorDOB) {
            setMinorDOBError("Invalid date of birth");
            error = true;
        } else {
            setMinorDOBError("");
        }

        if (error) return;

        let newMinors = [...minors];
        newMinors.push({
            name: minorName,
            dateOfBirth: minorDOB,
        })
        setMinors(newMinors);
        setMinorName("");
        setMinorDOB(null);
    }

    const handleRemoveMinorClick = (index) => {
        let newMinors = minors.filter((value, i) => {
            return index !== i;
        });
        setMinors(newMinors);
    }

    const renderPets = () => {
        const editPetName = (index, value) => {
            setPets((prevPets) =>
                prevPets.map((pet, i) =>
                    i === index ? { ...pet, name: value } : pet
                )
            );
        }
        const editPetDesc = (index, desc) => {
            setPets((prevPets) =>
                prevPets.map((pet, i) =>
                    i === index ? { ...pet, description: desc } : pet
                )
            );
        }
        return pets.map((value, index) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }} key={index}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label="Pet Name"
                                placeholder="Enter name of pet"
                                margin="dense"
                                variant="outlined"
                                fullWidth={true}
                                value={value.name}
                                onChange={e => { editPetName(index, e.target.value) }}
                                disabled={FieldsDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label="Pet Description"
                                placeholder="Eg German shepherd"
                                margin="dense"
                                variant="outlined"
                                fullWidth={true}
                                value={value.description}
                                onChange={e => { editPetDesc(index, e.target.value) }}
                                disabled={FieldsDisabled}
                            />
                        </Grid>
                    </Grid>
                    <div onClick={() => { handleRemovePetClick(index) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><DeleteIcon color="error" /></div>
                </div>
            );
        });
    }

    const handleAddPetClick = () => {
        let error = false;

        if (petName === "") {
            setPetNameError("Name cannot be empty");
            error = false;
        } else {
            setPetNameError("");
        }
        if (petDescription === "") {
            setPetDescriptionError("Description cannot be empty");
            error = true;
        } else {
            setPetDescriptionError("");
        }

        if (error) return;

        let newPets = [...pets];
        newPets.push({
            name: petName,
            description: petDescription,
        })
        setPets(newPets);
        setPetName("");
        setPetDescription("");
    }

    const handleRemovePetClick = (index) => {
        let newPets = pets.filter((value, i) => {
            return index !== i;
        });
        setPets(newPets);
    }

    const renderAutomobiles = () => {
        const editManufacture = (index, value) => {
            setAutomobiles((prevAutomobiles) =>
                prevAutomobiles.map((automobile, i) =>
                    i === index ? { ...automobile, manufacturer: value } : automobile
                )
            );
        }
        const editmodel = (index, value) => {
            setAutomobiles((prevAutomobiles) =>
                prevAutomobiles.map((automobile, i) =>
                    i === index ? { ...automobile, model: value } : automobile
                )
            );
        }
        const edityear = (index, value) => {
            setAutomobiles((prevAutomobiles) =>
                prevAutomobiles.map((automobile, i) =>
                    i === index ? { ...automobile, year: value } : automobile
                )
            );
        }
        const editLicense = (index, value) => {
            setAutomobiles((prevAutomobiles) =>
                prevAutomobiles.map((automobile, i) =>
                    i === index ? { ...automobile, license: value } : automobile
                )
            );
        }
        const editState = (index, value) => {
            setAutomobiles((prevAutomobiles) =>
                prevAutomobiles.map((automobile, i) =>
                    i === index ? { ...automobile, state: value } : automobile
                )
            );
        }
        return automobiles.map((value, index) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }} key={index}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card fullWidth={true} sx={{ p: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <TextField
                                            label="Make"
                                            placeholder="Eg Ford"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={value.manufacturer}
                                            onChange={e => { editManufacture(index, e.target.value) }}
                                            disabled={FieldsDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <TextField
                                            label="Model"
                                            placeholder="Eg Mustang"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={value.model}
                                            onChange={e => { editmodel(index, e.target.value) }}
                                            disabled={FieldsDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <TextField
                                            label="Year"
                                            placeholder="Enter Year"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={value.year}
                                            onChange={e => { edityear(index, e.target.value) }}
                                            disabled={FieldsDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <TextField
                                                    label="License Plate"
                                                    placeholder="Eg 44-1146N"
                                                    margin="dense"
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    value={value.license}
                                                    onChange={e => { editLicense(index, e.target.value) }}
                                                    disabled={FieldsDisabled}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <FormControl
                                                    fullWidth={true}
                                                    margin="dense"
                                                    disabled={FieldsDisabled}>
                                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                    <Select
                                                        label={"State"}
                                                        fullWidth={true}
                                                        MenuProps={MenuProps}
                                                        variant="outlined"
                                                        value={value.state}
                                                        onChange={e => { editState(index, e.target.value) }}
                                                    >
                                                        <MenuItem value={""}>Select</MenuItem>
                                                        {renderStateOptions()}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <div onClick={() => { handleRemoveAutomobileClick(index) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}><DeleteIcon color="error" /></div>
                </div>
            );
        });
    }

    const handleAddAutomobileClick = () => {
        let error = false;

        if (automobileMake === "") {
            setAutomobileMakeError("Make cannot be empty");
            error = true;
        } else {
            setAutomobileMakeError("");
        }
        if (automobileModel === "") {
            setAutomobileModelError("Model cannot be empty");
            error = true;
        } else {
            setAutomobileModelError("");
        }
        if (automobileYear === "") {
            setAutomobileYearError("Year cannot be empty");
            error = true;
        } else {
            setAutomobileYearError("");
        }
        if (automobileLicense === "") {
            setAutomobileLicenseError("License cannot be empty");
            error = true;
        } else {
            setAutomobileLicenseError("");
        }
        if (automobileState === "") {
            setAutomobileStateError("State cannot be empty");
            error = true;
        } else {
            setAutomobileStateError("");
        }

        if (error) return;

        let newAutomobiles = [...automobiles];
        newAutomobiles.push({
            manufacturer: automobileMake,
            model: automobileModel,
            year: automobileYear,
            license: automobileLicense,
            state: automobileState,
        })
        setAutomobiles(newAutomobiles);
        setAutomobileMake("");
        setAutomobileModel("");
        setAutomobileYear("");
        setAutomobileLicense("");
        setAutomobileState("");
    }

    const handleRemoveAutomobileClick = (index) => {
        let newAutomobiles = automobiles.filter((value, i) => {
            return index !== i;
        });
        setAutomobiles(newAutomobiles);
    }
    const handleModify = () => {
        const res = withDrawApplication(params.applicationId).then((response) => {
            window.location.reload();
        }).catch((err) => { toast.error(err.response.data.error.message) });
    };


    const handleOccupantsSubmit = async () => {
        let data = { adults, minors, pets, automobiles };

        await editTenantLeaseApplication(params.applicationId, data).then(response => {
            toast.success("Updated Occupant details");
            pageLoad();
            navigate(`/tenant/lease-applications/${params.applicationId}/residence`);
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const renderStateOptions = () => {
        return (
            usStates.map((item) => (
                // <>
                //     <option value={""} key="select">Select</option>
                //     {usStates.map((item) => (
                //         <option key={item} value={item}>
                //             {item}
                //         </option>
                //     ))}
                // </>
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))

        );
    };

    return (
        // <div className="lease-forms-item occupants-form">
        //     <h1 className="form-header" id="form-header">Occupants</h1>
        //     {/* <TenantLeaseApplicationEditPage/> */}
        //     <div className="form-body">
        //         <div className="form-section">
        //             <h2 className="form-section-header">Adults</h2>
        //             <div className="adults-list">
        //                 {renderAdults()}
        //             </div>
        //             <div className="adults-form">
        //                 <div className="form-row">
        //                     <div className="form-item">
        //                         <span className="form-item-label">Name</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="text"
        //                             value={adultName}
        //                             onChange={e => { setAdultName(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {adultNameError &&
        //                             <span className="form-item-error">
        //                                 {adultNameError}
        //                             </span>
        //                         }
        //                     </div>
        //                     <div className="form-item">
        //                         <span className="form-item-label">Relation</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="text"
        //                             value={adultRelation}
        //                             onChange={e => { setAdultRelation(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {adultRelationError &&
        //                             <span className="form-item-error">
        //                                 {adultRelationError}
        //                             </span>
        //                         }
        //                     </div>
        //                 </div>
        //                 <div className="form-row adults-submit-row">
        //                     <button
        //                         className="item-add-button"
        //                         onClick={handleAddAdultClick}
        //                         disabled={FieldsDisabled}
        //                     >
        //                         Add
        //                     </button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="form-section">
        //             <h2 className="form-section-header">Minors</h2>
        //             <div className="minors-list">
        //                 {renderMinors()}
        //             </div>
        //             <div className="minors-form">
        //                 <div className="form-row">
        //                     <div className="form-item">
        //                         <span className="form-item-label">Name</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="text"
        //                             value={minorName}
        //                             onChange={e => { setMinorName(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {minorNameError &&
        //                             <span className="form-item-error">
        //                                 {minorNameError}
        //                             </span>
        //                         }
        //                     </div>
        //                     <div className="form-item">
        //                         <span className="form-item-label">Date of birth</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="date"
        //                             value={minorDOB}
        //                             onChange={e => { setMinorDOB(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {minorDOBError &&
        //                             <span className="form-item-error">
        //                                 {minorDOBError}
        //                             </span>
        //                         }
        //                     </div>
        //                 </div>
        //                 <div className="form-row minors-submit-row">
        //                     <button
        //                         className="item-add-button"
        //                         onClick={handleAddMinorClick}
        //                         disabled={FieldsDisabled}
        //                     >
        //                         Add
        //                     </button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="form-section">
        //             <h2 className="form-section-header">Pets</h2>
        //             <div className="pets-list">
        //                 {renderPets()}
        //             </div>
        //             <div className="pets-form">
        //                 <div className="form-row">
        //                     <div className="form-item">
        //                         <span className="form-item-label">Name</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="text"
        //                             value={petName}
        //                             onChange={e => { setPetName(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {petNameError &&
        //                             <span className="form-item-error">
        //                                 {petNameError}
        //                             </span>
        //                         }
        //                     </div>
        //                     <div className="form-item">
        //                         <span className="form-item-label">Description</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="text"
        //                             value={petDescription}
        //                             onChange={e => { setPetDescription(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {petDescriptionError &&
        //                             <span className="form-item-error">
        //                                 {petDescriptionError}
        //                             </span>
        //                         }
        //                     </div>
        //                 </div>
        //                 <div className="form-row pets-submit-row">
        //                     <button
        //                         className="button item-add-button"
        //                         onClick={handleAddPetClick}
        //                         disabled={FieldsDisabled}
        //                     >
        //                         Add
        //                     </button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="form-section">
        //             <h2 className="form-section-header">Automobiles</h2>
        //             <div className="automobiles-list">
        //                 {renderAutomobiles()}
        //             </div>
        //             <div className="automobiles-form">
        //                 <div className="form-row">
        //                     <div className="form-item">
        //                         <span className="form-item-label">Make</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="text"
        //                             value={automobileMake}
        //                             onChange={e => { setAutomobileMake(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {automobileMakeError &&
        //                             <span className="form-item-error">
        //                                 {automobileMakeError}
        //                             </span>
        //                         }
        //                     </div>
        //                     <div className="form-item">
        //                         <span className="form-item-label">Model</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="text"
        //                             value={automobileModel}
        //                             onChange={e => { setAutomobileModel(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {automobileModelError &&
        //                             <span className="form-item-error">
        //                                 {automobileModelError}
        //                             </span>
        //                         }
        //                     </div>
        //                     <div className="form-item">
        //                         <span className="form-item-label">Year</span>
        //                         <input
        //                             className="form-item-input"
        //                             type="text"
        //                             value={automobileYear}
        //                             onChange={e => { setAutomobileYear(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {automobileYearError &&
        //                             <span className="form-item-error">
        //                                 {automobileYearError}
        //                             </span>
        //                         }
        //                     </div>
        //                 </div>
        //                 <div className="form-row">
        //                     <div className="form-item">
        //                         <span className="form-item-label">License plate</span>
        //                         <input
        //                             className="form-item-input"
        //                             placeholder="Eg 44-1146N"
        //                             type="text"
        //                             value={automobileLicense}
        //                             onChange={e => { setAutomobileLicense(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         />
        //                         {automobileLicenseError &&
        //                             <span className="form-item-error">
        //                                 {automobileLicenseError}
        //                             </span>
        //                         }
        //                     </div>
        //                     <div className="form-item">
        //                         <span className="form-item-label">State</span>
        //                         <select
        //                             className="form-item-input"
        //                             value={automobileState}
        //                             onChange={e => { setAutomobileState(e.target.value) }}
        //                             disabled={FieldsDisabled}
        //                         >
        //                             {renderStateOptions()}
        //                         </select>
        //                         {automobileStateError &&
        //                             <span className="form-item-error">
        //                                 {automobileStateError}
        //                             </span>
        //                         }
        //                     </div>
        //                 </div>
        //                 <div className="form-row automobiles-submit-row">
        //                     <button
        //                         className="button item-add-button"
        //                         onClick={handleAddAutomobileClick}
        //                         disabled={FieldsDisabled}
        //                     >
        //                         Add
        //                     </button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>

        //     <div className="form-footer">
        //         {Modify === true ? (
        //             <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
        //                 <Button
        //                     variant="contained"
        //                     color="success"
        //                     sx={{ fontSize: 15 }}
        //                     onClick={handleOccupantsSubmit}
        //                 >SAVE</Button>
        //                 <Button
        //                     variant="contained"
        //                     color="warning"
        //                     sx={{ fontSize: 15 }}
        //                     onClick={handleOccupantsSubmit}
        //                     endIcon={<FaStepForward />}
        //                 >RESIDENCE</Button>
        //                 <Button
        //                     variant="contained"
        //                     color="info"
        //                     sx={{ fontSize: 15 }}
        //                     onClick={() => { setCurrentForm(formNames.agreementForm) }}
        //                     endIcon={<FaStepForward />}
        //                 >GO TO SUBMIT</Button>
        //             </div>
        //         ) : (
        //             <>
        //                 {SubmittedStatus === true ? (
        //                     <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
        //                         <Button
        //                             variant="contained"
        //                             color="warning"
        //                             sx={{ fontSize: 15 }}
        //                             onClick={handleOccupantsSubmit}
        //                             endIcon={<FaStepForward />}
        //                         >RESIDENCE</Button>
        //                         <Button
        //                             variant="contained"
        //                             color="warning"
        //                             sx={{ fontSize: 15 }}
        //                             onClick={(e) => handleModify(e)}
        //                         >MODIFY</Button>
        //                     </div>
        //                 ) : (
        //                     <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
        //                         <Button
        //                             variant="contained"
        //                             color="success"
        //                             sx={{ fontSize: 15 }}
        //                             onClick={handleOccupantsSubmit}
        //                         >SAVE</Button>
        //                         <Button
        //                             variant="contained"
        //                             color="warning"
        //                             sx={{ fontSize: 15 }}
        //                             onClick={handleOccupantsSubmit}
        //                             endIcon={<FaStepForward />}
        //                         >RESIDENCE</Button>
        //                     </div>
        //                 )}
        //             </>
        //         )}
        //     </div>
        //     <ToastContainer
        //         position="top-center"
        //         autoClose={2000}
        //         hideProgressBar={false}
        //         newestOnTop={false}
        //         closeOnClick
        //         rtl={false}
        //         pauseOnFocusLoss
        //         draggable
        //         pauseOnHover
        //         theme="colored"
        //     />
        // </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: "100%", maxWidth: "1350px", }}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TenantLeaseApplicationEditPage />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <h3 className="subHeader">Adults</h3>
                                {renderAdults()}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    error={adultNameError ? true : false}
                                    helperText={adultNameError && adultNameError}
                                    label="Name"
                                    placeholder="Enter name"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={adultName}
                                    onChange={e => { setAdultName(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    error={adultRelationError ? true : false}
                                    helperText={adultRelationError && adultRelationError}
                                    label="Relation"
                                    placeholder="Eg Father.."
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={adultRelation}
                                    onChange={e => { setAdultRelation(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                                    <Button
                                        variant="contained"
                                        color='warning'
                                        sx={{ fontSize: 15 }}
                                        onClick={handleAddAdultClick}
                                        disabled={FieldsDisabled}>
                                        Add
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <h3 className="subHeader">Minors</h3>
                                {renderMinors()}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    error={minorNameError ? true : false}
                                    helperText={minorNameError && minorNameError}
                                    label="Name"
                                    placeholder="Enter name"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={minorName}
                                    onChange={e => { setMinorName(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        views={["day", "month", "year"]}
                                        label="Date of Birth"
                                        value={minorDOB}
                                        onChange={newValue => { setMinorDOB(newValue) }}
                                        renderInput={(params) => <TextField {...params} margin="dense" error={minorDOBError ? true : false} helperText={minorDOBError && minorDOBError} disabled={FieldsDisabled} fullWidth={true} />} />
                                </LocalizationProvider>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                                    <Button
                                        variant="contained"
                                        color='warning'
                                        sx={{ fontSize: 15 }}
                                        onClick={handleAddMinorClick}
                                        disabled={FieldsDisabled}>
                                        Add
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <h3 className="subHeader">Pets</h3>
                                {renderPets()}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    error={petNameError ? true : false}
                                    helperText={petNameError && petNameError}
                                    label="Pet Name"
                                    placeholder="Enter name of pet"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={petName}
                                    onChange={e => { setPetName(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    error={petDescriptionError ? true : false}
                                    helperText={petDescriptionError && petDescriptionError}
                                    label="Pet Description"
                                    placeholder="Eg German shepherd"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={petDescription}
                                    onChange={e => { setPetDescription(e.target.value) }}
                                    disabled={FieldsDisabled}
                                />
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                                    <Button
                                        variant="contained"
                                        color='warning'
                                        sx={{ fontSize: 15 }}
                                        onClick={handleAddPetClick}
                                        disabled={FieldsDisabled}>
                                        Add
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <h3 className="subHeader">Automobiles</h3>
                                {renderAutomobiles()}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Card fullWidth={true} sx={{ p: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={4}>
                                            <TextField
                                                error={automobileMakeError ? true : false}
                                                helperText={automobileMakeError && automobileMakeError}
                                                label="Make"
                                                placeholder="Eg Ford"
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth={true}
                                                value={automobileMake}
                                                onChange={e => { setAutomobileMake(e.target.value) }}
                                                disabled={FieldsDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4}>
                                            <TextField
                                                error={automobileModelError ? true : false}
                                                helperText={automobileModelError && automobileModelError}
                                                label="Model"
                                                placeholder="Eg Mustang"
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth={true}
                                                value={automobileModel}
                                                onChange={e => { setAutomobileModel(e.target.value) }}
                                                disabled={FieldsDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4}>
                                            <TextField
                                                error={automobileYearError ? true : false}
                                                helperText={automobileYearError && automobileYearError}
                                                label="Year"
                                                placeholder="Enter Year"
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth={true}
                                                value={automobileYear}
                                                onChange={e => { setAutomobileYear(e.target.value) }}
                                                disabled={FieldsDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                                    <TextField
                                                        error={automobileLicenseError ? true : false}
                                                        helperText={automobileLicenseError && automobileLicenseError}
                                                        label="License Plate"
                                                        placeholder="Eg 44-1146N"
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        value={automobileLicense}
                                                        onChange={e => { setAutomobileLicense(e.target.value) }}
                                                        disabled={FieldsDisabled}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                                    <FormControl
                                                        fullWidth={true}
                                                        error={automobileStateError ? true : false}
                                                        margin="dense"
                                                        disabled={FieldsDisabled}>
                                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                        <Select
                                                            label={"State"}
                                                            fullWidth={true}
                                                            MenuProps={MenuProps}
                                                            variant="outlined"
                                                            value={automobileState}
                                                            onChange={e => { setAutomobileState(e.target.value) }}
                                                        >
                                                            <MenuItem value={""}>Select</MenuItem>
                                                            {renderStateOptions()}
                                                        </Select>
                                                        {automobileStateError && <FormHelperText>{automobileStateError}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                                                <Button
                                                    variant="contained"
                                                    color='warning'
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleAddAutomobileClick}
                                                    disabled={FieldsDisabled}>
                                                    Add
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                {Modify === true ? (
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleOccupantsSubmit}
                                        >SAVE</Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            sx={{ fontSize: 15 }}
                                            onClick={handleOccupantsSubmit}
                                            endIcon={<FaStepForward />}
                                        >RESIDENCE</Button>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            sx={{ fontSize: 15 }}
                                            onClick={() => { navigate(`/tenant/lease-applications/${params.applicationId}/agreement`); }}
                                            endIcon={<FaStepForward />}
                                        >GO TO SUBMIT</Button>
                                    </div>
                                ) : (
                                    <>
                                        {SubmittedStatus === true ? (
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleOccupantsSubmit}
                                                    endIcon={<FaStepForward />}
                                                >RESIDENCE</Button>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={(e) => handleModify(e)}
                                                >MODIFY</Button>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'flex-end', gap: 10 }}>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleOccupantsSubmit}
                                                >SAVE</Button>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{ fontSize: 15 }}
                                                    onClick={handleOccupantsSubmit}
                                                    endIcon={<FaStepForward />}
                                                >RESIDENCE</Button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default OccupantsForm;