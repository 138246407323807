import { CardContent, Dialog, FormControl, Icon, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import { getManagerPropertyGroups, getManagerPropertiesOverview, postManagerPropertyGroups, patchManagerPropertyGroups, deleteManagerPropertyGroups, putManagerPropertyGroups, postArchivegroup, postUnArchivegroup } from '../../../../services';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import ComplexServiceRequestTabs from './complexserviceRequestTab';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#ff805d",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#bbdefb",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "#e3f2fd",
    },
}));

function EnhancedTableHead() {

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell className='tableCell-maxWidth' align={'left'} sx={{ fontSize: "large", color: "white" }}>
                    Property Address
                </StyledTableCell>
                <StyledTableCell className='tableCell-maxWidth' align={'center'} sx={{ fontSize: "large", color: "white" }}>
                    Complex Groups
                </StyledTableCell>
                <StyledTableCell className='tableCell-maxWidth' align={'center'} sx={{ fontSize: "large", color: "white" }}>
                    Actions
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    rowCount: PropTypes.number.isRequired,
};

const ComplexEdit = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");

    const [propertyOverview, setPropertyOverview] = useState([]);
    const [propertyGroups, setPropertyGroups] = useState([]);
    const [addGroupCondition, setAddGroupCondition] = useState(false);
    const [editGroupCondition, setEditGroupCondition] = useState(false);
    const [editName, setEditName] = useState("")
    const [overIds, setOverIds] = useState([]);
    const [data, setdata] = useState([]);
    const [search, setSearch] = useState("");
    const [archive, setArchive] = useState(false);
    const [groupId, setGroupId] = useState("");

    useEffect(() => {
        pageLoad();
    }, []);

    const pageLoad = () => {
        let over = [...propertyOverview];
        over.length = 0;
        let grps = [...propertyGroups];
        grps.length = 0;
        let overId = [...overIds];
        overId.length = 0;

        getManagerPropertyGroups().then((res) => {
            let data = res.data;
            let getDetails = { ...data }
            getDetails.data.map((e, i) => {
                grps.push({
                    "id": e?.id,
                    "name": e?.name,
                    "properties": e?.properties,
                })

                e.properties.map((h, j) => {
                    overId.push(h);
                })
            })
            setdata(getDetails.data);
            setPropertyGroups(grps);
            setOverIds(overId);
        })
        getManagerPropertiesOverview().then((res) => {
            let data = res.data;
            let getDetails = { ...data }
            getDetails.data.map((e, i) => {
                over.push({
                    "address": e?.address.line1 + "," + e?.address.line2 + "," + e?.address.unitNumber + "," + e?.address.city + "," + e?.address.state + "-" + e?.address.zipCode,
                    "id": e?.id,
                })
            })
            setPropertyOverview(over);
        })
    }

    const addNewGroupClick = () => {
        setAddGroupCondition(true);
        setName("");
    }

    const addGroupClick = () => {
        let data = {
            name: name,
        }
        postManagerPropertyGroups(data).then((res) => {
            pageLoad();
            setAddGroupCondition(false);
            toast.success(`Group ${name} Added`)
        })
    }

    const editSaveClick = () => {
        let data = {
            name: editName,
        }
        patchManagerPropertyGroups(groupId, data).then((res) => {
            pageLoad();
            setGroupId("");
            setEditName("");
            setEditGroupCondition(false);
            toast.info("Property Group Edited")
        })
    }

    const deleteGroupClick = () => {
        deleteManagerPropertyGroups(groupId).then((res) => {
            pageLoad();
            setGroupId("");
            setEditName("");
            setEditGroupCondition(false);
            toast.info("Property Group Removed")
        })
    }

    const radioChange = (e, id) => {
        let data = e;
        propertyGroups.map((e, i) => {
            if (e.id !== id) {
                e.properties.forEach(h => {
                    if (h == data) {
                        e.properties.splice(e.properties.indexOf(data), 1)
                        return;
                    }
                })
            } else {
                e.properties.push(data);
            }
        })
        postGroups();
    }
    const postGroups = () => {
        let data = {
            groups: propertyGroups,
        }
        putManagerPropertyGroups(data).then((res) => {
            pageLoad();
            toast.success("Property Grouping Updated")
        })
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const editIconClick = (id, name) => {
        setEditGroupCondition(true);
        setGroupId(id);
        setEditName(name);
    }

    const archiveokClick = () => {
        let data = {
            archive: true
        }
        postArchivegroup(groupId, data).then((res) => {
            navigate(`/manager/home`);
            toast.success(`PropertyGroup archived successfully`)
        }).catch((err) => { toast.error(err.response.data.error.message) });
    }

    const archiveDialogCancel = () => {
        setArchive(false);
    }

    const openArchiveDialig = () => {
        setArchive(true);
    }
    return (
        <div style={{ padding: '30px' }}>
            <CardContent sx={{ pt: 0 }}>
                <ComplexServiceRequestTabs />
            </CardContent>
            <p style={{ fontSize: 22, textAlign: "center", fontWeight: "bold", marginBottom: "0px", marginTop: '30px' }}>Property Grouping</p>
            <CardContent sx={{ display: "flex", justifyContent: "right", pb: 0 }}>
                <Button style={{
                    fontSize: 15,
                    borderRadius: 20
                }} variant="contained" color='primary' onClick={addNewGroupClick}>
                    ADD NEW GROUP</Button>
            </CardContent>
            <Box sx={{ width: '100%' }}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                    <TextField
                        id="standard-basic"
                        fullWidth={true}
                        variant="standard"
                        sx={{ width: "30%" }}
                        label={<p style={{ fontSize: 17 }}>Search...</p>}
                        value={search}
                        onChange={(e) => { setSearch(e.target.value) }}
                        type="text"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Icon>
                                        <SearchIcon style={{ fontSize: 17 }} />
                                    </Icon>
                                </InputAdornment>
                            )
                        }}
                    /></div>
                <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
                    <TableContainer style={{ borderRadius: "3px" }}>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        >
                            <EnhancedTableHead
                                rowCount={propertyOverview.length}
                            />
                            <TableBody
                                sx={{
                                    "& .MuiTableRow-root:hover": {
                                        backgroundColor: "#DBE9FA"
                                    }
                                }}
                            >
                                {propertyOverview.filter(addr => addr.address.includes(search) || addr.address.toLowerCase().includes(search)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <StyledTableRow
                                                // hover
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ pl: 2, fontSize: 15 }}
                                                    className='tableCell-maxWidth'
                                                >
                                                    {row.address}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableCell-maxWidth' align="center">
                                                    <FormControl sx={{ minWidth: 120, backgroundColor: "#f5f5f5" }}>
                                                        <InputLabel id="demo-simple-select-label">{!overIds.includes(row?.id) && "Default"}{data.map((h) => (h?.properties.includes(row?.id) && h?.name))}</InputLabel>
                                                        <Select
                                                            label={!overIds.includes(row?.id) && "Default" || data.map((h) => (h?.properties.includes(row?.id) && h?.name))}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={!overIds.includes(row?.id) && "Default" || data.map((h) => (h?.properties.includes(row?.id) && h?.name))}
                                                            // onChange={handleChange}
                                                            MenuProps={MenuProps}
                                                            size="small"
                                                        >
                                                            <MenuItem value={"Default"} onClick={(e) => radioChange(row.id)}>
                                                                Default
                                                            </MenuItem>
                                                            {data.map((h, j) => (
                                                                <MenuItem value={h.name} key={j} onClick={(e) => radioChange(row.id, h.id)}>{h.name}</MenuItem>))}
                                                        </Select>
                                                    </FormControl>
                                                </StyledTableCell>
                                                <StyledTableCell className='tableCell-maxWidth' align="center">
                                                    {data.map((h) => (
                                                        h?.properties.includes(row?.id) &&
                                                        <Tooltip title={h.name} placement="top" arrow>
                                                            <EditIcon style={{ color: "#4a90e2", cursor: "pointer" }} onClick={() => { editIconClick(h.id, h.name) }} />
                                                        </Tooltip>))}
                                                    {!overIds.includes(row?.id) &&
                                                        <Tooltip title={"Default"} placement="top" arrow>
                                                            <EditIcon style={{ color: "#4a90e2", minWidth: "40px" }} />
                                                        </Tooltip>
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={propertyOverview.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
            <Dialog
                open={addGroupCondition}
                maxWidth="xs"
                onClose={() => setAddGroupCondition(false)}>
                <CardContent>
                    <CardContent sx={{ pl: 0, pt: 0 }}>
                        <p style={{ fontSize: 15, fontWeight: "bolder" }}>Add New Group</p>
                    </CardContent>
                    <TextField
                        placeholder="Enter Group Name"
                        fullWidth={true}
                        variant="outlined"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                    />
                    <div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                        <Button variant="outlined" color='success' onClick={addGroupClick}>
                            SAVE</Button>
                    </div>
                </CardContent>
            </Dialog>
            <Dialog
                open={editGroupCondition}
                maxWidth="xs"
                onClose={() => {
                    setEditGroupCondition(false);
                    setGroupId("");
                    setEditName("")
                }}>
                <CardContent>
                    <CardContent sx={{ pl: 0, pt: 0 }}>
                        <p style={{ fontSize: 15, fontWeight: "bolder" }}>Add Group Property Name</p>
                    </CardContent>
                    <TextField
                        placeholder="Group Property Name"
                        fullWidth={true}
                        variant="outlined"
                        value={editName}
                        onChange={(e) => { setEditName(e.target.value) }}
                    />
                    <div style={{ display: "flex", justifyContent: "right", marginTop: "20px", gap: 10 }}>
                        <Button variant="contained" color='primary' sx={{ fontSize: 14 }} onClick={editSaveClick}>UPDATE</Button>
                        <Button variant="contained" color='warning' sx={{ fontSize: 14 }} onClick={openArchiveDialig}>ARCHIVE</Button>
                        <Button variant="contained" color='error' sx={{ fontSize: 14 }} onClick={deleteGroupClick}>DELETE</Button>
                        <Button variant="contained" color="inherit" sx={{ fontSize: 14 }} onClick={() => { setEditGroupCondition(false); setGroupId(""); setEditName("") }}>CANCEL</Button>
                    </div>
                </CardContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={archive}
                onClose={() => setArchive(false)}
            >
                <CardContent>
                    <Typography variant="h6" component="h2">
                        {editName} Complex may have active leases. Please terminante leases as appropriate, otherwise system will continue to process those tenants.
                    </Typography>
                </CardContent>
                <CardContent sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                    <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={archiveokClick}>Ok</Button>
                    <Button color='error' sx={{ fontSize: 15 }} onClick={archiveDialogCancel} >CANCEL</Button>
                </CardContent>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
        </div>
    )
}

export default ComplexEdit;
