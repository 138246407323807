import React from 'react';
import { Tabs, Tab, Box } from "@mui/material";
import { useLocation, Link, useParams } from 'react-router-dom';

function UsersTab({ user }) {
    const params = useParams();
    const location = useLocation();
    const currentTab = location.pathname;

    return (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <p className='users-page-managers'>{user}</p>
            <Tabs
                variant="scrollable"
                aria-label="basic tabs example"
                value={currentTab}
            >
                <Tab
                    label={<h5 style={{ margin: "0px 30px" }}>Managers</h5>}
                    value={`/users/managers`}
                    to={`/users/managers`}
                    component={Link}
                />
                <Tab
                    label={<h5 style={{ margin: "0px" }}>Tenants</h5>}
                    value={`/users/tenants`}
                    to={`/users/tenants`}
                    component={Link}
                />
            </Tabs>
        </Box>
    )
}

export default UsersTab