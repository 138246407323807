import React, { useState } from "react";
import { Button, Card, CardContent, Container, Typography } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCurrentUser } from "../../services";
import { Requestemailverification } from "../../services";
import { useNavigate } from "react-router-dom";


function RequestEmailVerification() {
  const [emailSendButton, setemailSendButton] = useState(false);
  const [btnStatus, setbtnStatus] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    sentEmail({
      email: sessionStorage.getItem("email-verification"),
    });
  };
  const sentEmail = () => {
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("authToken")}` },
    };
    var res = getCurrentUser(config)
      .then((response) => {
        let responseData = response.data.data.email.verified;
        if (!responseData) {
          Requestemailverification({}, config)
            .then((response) => {
              if (response.data) {
                {
                  toast.success("Verification email has been sent");
                }
                setbtnStatus(true)
              } else {
                {
                  toast.error("Sorry please check your email id or login.");
                }
              }
            })
            .catch((err) => {
              {
                toast.error("Not able to send the email");
              }
            });
        } else {
          toast.success("Email is already verified please login");
          setTimeout(function () {
            navigate("/login");
          }, 2000);
        }
      });
  };

  return (
    <Container component="main" maxWidth="sm">
      <Card sx={{ borderRadius: 3, my: '25%', pt: 3, pb: 3 }}>
        <CardContent sx={{ px: 0 }}>
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: 22, textAlign: 'center', color: '#ff805d' }} variant="h4">
            Request Email Verification
          </Typography>
        </CardContent>
        <CardContent sx={{ px: 0 }}>
          <Typography sx={{ fontFamily: 'Arial', fontSize: '18px', textAlign: 'center' }} variant="h4">
            We have sent you an email to verify your<br></br>email address.
          </Typography>
        </CardContent>
        <CardContent sx={{ px: 0 }}>
          <Typography sx={{ fontFamily: 'Arial', fontSize: 14, textAlign: 'center' }} variant="h4">
            please check your inbox or span folder
          </Typography>
        </CardContent>
        {!emailSendButton ? (
          <CardContent sx={{ textAlign: 'center', px: 0, color: '#007ACC' }} onClick={() => setemailSendButton(true)}>
            <Button>Didn't receive an Email?</Button>
          </CardContent>
        ) : (
          <>
            {btnStatus === false ?
              <center><Button
                style={{ cursor: "pointer", background: "#ff805d" }}
                sx={{ fontWeight: 'bold', fontSize: 12, letterSpacing: 2, width: 1 / 2 }}
                variant="contained"
                onClick={() => handleSubmit()}
              >
                Request Verification Email
              </Button></center>
              :
              <div>
                <center><Button
                  style={{ backgroundColor: '#E0E0E0' }}
                  sx={{ fontWeight: 'bold', fontSize: 12, letterSpacing: 2, width: 1 / 2 }}
                  type="submit"
                  disabled={true}
                >
                  Request Verification Email
                </Button></center>
                <span className="bottom-content">
                  <center>Check your Inbox or Spam Folder</center>
                </span></div>
            }
          </>
        )}
      </Card>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Container>
  );
}

export default RequestEmailVerification;
